import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';

import messages from './SettingsOrders.intl';

const SettingsDocumentForm = props => {
    const { intl, handleFormSubmit, ordersSettings } = props;

    return (
        <AvForm onValidSubmit={handleFormSubmit}>
            <h5 className='title-area-form'>
                {intl.formatMessage(messages.orders)}
            </h5>
            <Row>
                <Col md={5}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.enableMultipleOrderItems,
                            )}
                            type='checkbox'
                            name='enableMultipleOrderItems'
                            id='enableMultipleOrderItems'
                            value={
                                ordersSettings?.enableMultipleOrderItems ||
                                false
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={7}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.enableAutomaticOrderAssociation,
                            )}
                            type='checkbox'
                            name='enableAutomaticOrderAssociation'
                            id='enableAutomaticOrderAssociation'
                            value={
                                ordersSettings?.enableAutomaticOrderAssociation ||
                                false
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={7}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.enableJobAutomaticOrderAssociation,
                            )}
                            type='checkbox'
                            name='enableJobAutomaticOrderAssociation'
                            id='enableJobAutomaticOrderAssociation'
                            value={
                                ordersSettings?.enableJobAutomaticOrderAssociation ||
                                false
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.listOnlyOrderFromTheDocumentTakerForAssociation,
                            )}
                            type='checkbox'
                            name='listOnlyOrderFromTheDocumentTakerForAssociation'
                            id='listOnlyOrderFromTheDocumentTakerForAssociation'
                            value={
                                ordersSettings?.listOnlyOrderFromTheDocumentTakerForAssociation ||
                                false
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.associateAllItemOrdersWhenOnlyOrderNumberAreFound,
                            )}
                            type='checkbox'
                            name='associateAllItemOrdersWhenOnlyOrderNumberAreFound'
                            id='associateAllItemOrdersWhenOnlyOrderNumberAreFound'
                            value={ordersSettings?.associateAllItemOrdersWhenOnlyOrderNumberAreFound}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <button
                        type='submit'
                        className='btn-submit'
                        disabled={props.loading}
                    >
                        {intl.formatMessage(messages.submitButton)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    );
};

export default injectIntl(SettingsDocumentForm);
