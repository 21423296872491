import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DynamicFilterModal from '../DynamicFilterModal/DynamicFilterModal';
import SearchBar from '../SearchBar/SearchBar';

import { Operators } from '../../models/FilterBuilder';
import { filterPropertyTypes } from '../../utils/enums';
import { toast } from 'react-toastify';

const EvtFilter = props => {
    const defaultFilter = {
        hideAddButton: false,
        property: '',
        operator: '',
        value: '',
        type: '',
        enums: [],
        id: 'firstFilter',
    };
    const history = useHistory();
    const location = useLocation();
    const [hideAddButton, setHideAddButton] = useState(
        props && !props.hideAddButton,
    );
    const [searchTerm, setSearchTerm] = useState('');
    const [showDynamicFilterModal, setShowDynamicFilterModal] = useState(false);
    const [filters, setFilters] = useState([defaultFilter]);

    useEffect(() => {
        const properties = props.properties || [];
        const URL = window.location.search;
        const URL_split = decodeURI(URL)
            .replaceAll('%3A', ':')
            .replace('?', '')
            .split('&');

        if (URL_split.some(item => item.includes('search='))) {
            let filters = [];
            URL_split.forEach((filter, index, arr) => {
                const [property, value] = filter
                    .replaceAll('+', ' ')
                    .split(/[[\]:=&?]/gi)
                    .filter(i => i);
                if (property === 'search' && !!value) setSearchTerm(value);
            });
            setFilters(filters);
        } else if (URL_split.some(item => item.includes('filter'))) {
            let filters = [];
            URL_split.forEach((filter, index, arr) => {
                const [property, operator, value] = filter
                    .replace(`documentType`, '')
                    .replace('filter', '')
                    .replaceAll('+', ' ')
                    .split(/[[\]:=&?]/gi)
                    .filter(i => i);

                const propertyData = properties.find(p => p.key === property);
                if (!!value) {
                    filters.push({
                        hideAddButton: false,
                        property: property,
                        operator: operator,
                        value: value,
                        type: propertyData?.type || 1,
                        enums: propertyData?.options || [],
                        id: index,
                    });
                }
            });
            setFilters(filters);
        }
    }, []);

    function handleDynamicFilterModalToogle() {
        const URL = window.location.search;

        //Quando não haver um filtro na URL, por exemplo, após restaurar, ele irá verificar e então retirar o último filtro.
        if (!URL.includes('filter[]')) {
            setFilters([defaultFilter]);
        }

        setShowDynamicFilterModal(!showDynamicFilterModal);
    }

    function handleSearchBarChange(newSearchTerm) {
        setSearchTerm(newSearchTerm);
    }

    function handleSearchBarSubmit(newSearchTerm) {
        setSearchTerm(newSearchTerm);

        const properties = props.properties || [];
        const filters = properties.map(p => {
            let value = newSearchTerm;

            if (p.options && Array.isArray(p.options)) {
                p.options.forEach(
                    item =>
                        typeof item._map === 'function' &&
                        (value = item._map((value || '').trim().toLowerCase())),
                );
            } else {
                typeof p._map === 'function' &&
                    (value = p._map((value || '').trim().toLowerCase()));
            }

            return {
                property: p.key,
                operator: Operators.like,
                value,
            };
        });

        if (!!newSearchTerm) {
            filters.push({
                operator: Operators.or,
            });

            setFilters([defaultFilter]);
            props.handleFiltersChange &&
                props.handleFiltersChange(filters, newSearchTerm);
        } else {
            setSearchTerm('');
            props.handleFiltersChange && props.handleFiltersChange([]);
            history.push(location.pathname);
        }
    }

    function notifyFiltersChange(filters) {
        setSearchTerm('');
        setShowDynamicFilterModal(!showDynamicFilterModal);
        props.handleFiltersChange && props.handleFiltersChange(filters);
    }

    const handleFormSubmit = (event, form) => {
        const properties = props.properties || [];

        const filters = Object.keys(form.filters).map(filter => {
            return form.filters[filter];
        });
        const noOperator = filters.find(f => f.operator === '');
        if (noOperator) {
            toast.error('Um ou mais filtros não tem o operador');
            return;
        }

        let array = [];

        let newFilters = filters.map(filter => {
            const filterProperty = properties.find(
                p => p.key === filter.property,
            );
            if (filter.valueFrom) {
                const valueFrom = new Date(
                    filter.valueFrom + 'T00:00:00Z',
                ).toISOString();

                filter.operator = 'ge';
                filter.value = valueFrom;

                array.push({ ...filter });
            }

            if (filter.valueTo) {
                const valueTo = new Date(
                    filter.valueTo + 'T23:59:59Z',
                ).toISOString();

                filter.operator = 'le';
                filter.value = valueTo;
            }

            if (typeof filterProperty._map === 'function') {
                filter.value = filterProperty._map(
                    (filter.value || '').trim().toLowerCase(),
                );
            }

            return filter;
        });

        newFilters = newFilters.concat(array);

        notifyFiltersChange(newFilters);
    };

    const handleResetFilter = () => {
        setFilters([defaultFilter]);
        notifyFiltersChange([]);
        history.push(location.pathname);
    };

    const handleAddRemoveFilter = ({ id, showAddButton }) => {
        if (showAddButton) {
            setFilters([
                ...filters,
                {
                    showAddButton: false,
                    property: '',
                    operator: '',
                    value: '',
                    type: '',
                    enums: [],
                    id: Math.random()
                        .toString(36)
                        .substr(2, 9),
                },
            ]);
        } else {
            const newFilters = filters.filter(f => {
                return f.id !== id;
            });

            setFilters(newFilters);
        }
    };

    const handleFieldChange = (e, id, field) => {
        const value = e.target.value;

        const property = props.properties.find(prop => prop.key === value);
        const canUpdateType = field === 'property';
        const canUpdateEnums =
            property && property.type === filterPropertyTypes.ENUM;
        const canUpdateSelect =
            property && property.type === filterPropertyTypes.SELECT;

        const newFilters = filters.filter(f => {
            if (f.id === id) {
                if (props.cleanUpFilter && canUpdateType) f['value'] = '';

                f[field] = value;
                canUpdateType && (f['type'] = property.type);
                canUpdateEnums && (f['enums'] = property.options);
                (canUpdateEnums || canUpdateSelect) && (f['operator'] = 'eq');
            }

            return f;
        });

        setFilters(newFilters);
    };

    return (
        <>
            {!props.hideSearchBar && (
                <SearchBar
                    searchTerm={searchTerm}
                    handleSearchChange={handleSearchBarChange}
                    handleSearchSubmit={handleSearchBarSubmit}
                    loading={props.loading}
                    colorVar={!!props.tabInside}
                />
            )}
            {hideAddButton && (
                <button
                    className={`new-btn small`}
                    onClick={handleDynamicFilterModalToogle}
                    disabled={props.loading}
                >
                    <FontAwesomeIcon icon='filter' />
                </button>
            )}
            <DynamicFilterModal
                filterEntityTitle={'Filtros'}
                filters={filters}
                isOpen={showDynamicFilterModal}
                handleToggle={handleDynamicFilterModalToogle}
                properties={props.properties}
                handleFormSubmit={handleFormSubmit}
                handleAddRemoveFilter={handleAddRemoveFilter}
                handleFieldChange={handleFieldChange}
                handleResetFilter={handleResetFilter}
            />
        </>
    );
};

EvtFilter.propTypes = {
    hideAddButton: PropTypes.bool,
    tabInside: PropTypes.bool,
    properties: PropTypes.array.isRequired,
    handleFiltersChange: PropTypes.func.isRequired,
    hideSearchBar: PropTypes.bool,
    loading: PropTypes.bool,
};

export default EvtFilter;
