import { defineMessages } from "react-intl";
import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,

  name: {
    id: "users.name",
    defaultMessage: "Nome"
  },

  email: {
    id: "users.email",
    defaultMessage: "Email"
  },

  role: {
    id: "users.role",
    defaultMessage: "Função"
  },

  company: {
    id: "users.company",
    defaultMessage: "Contratante"
  },

  password: {
    id: "users.password",
    defaultMessage: "Senha"
  },

  passwordDoesNotMatch: {
    id: "users.passwordDoesNotMatch",
    defaultMessage: "Senha diferente"
  },

  confirmPassword: {
    id: "users.confirmPassword",
    defaultMessage: "Confirmar Senha"
  },

  submitButton: {
    id: "global.submitButton",
    defaultMessage: "Enviar"
  },

  deleteButton: {
    id: "global.deleteButton",
    defaultMessage: "Excluir"
  },

  supplier: {
    id: "suppliers.supplierText",
    defaultMessage: "Fornecedor {entity}"
  },
  
  titleMap: {
    id: 'access.title',
    defaultMessage: 'Selecione o acesso desejado',
  },

  contractorName: {
    id: 'contractors.titleOnlyContractor',
    defaultMessage: 'Contratante',
  },

  supplierName: {
    id: 'suppliers.titleOnlySupplier',
    defaultMessage: 'Fornecedor',
  },

  roleName: {
    id: 'roles.name',
    defaultMessage: 'Perfil',
  },

  newAccess: {
    id: 'users.newAccess',
    defaultMessage: 'Novo acesso',
  },

  newCompany: {
    id: 'users.newCompany',
    defaultMessage: 'Adicionar filiais para revisão',
  },

  companiesForReport: {
    id: 'users.companiesForReport',
    defaultMessage: 'Filias para Revisão',
  },

  newPasswordButtonText: {
    id: 'users.newPasswordButtonText',
    defaultMessage: 'Nova senha'
  },
  blocked: {
    id: 'users.blocked',
    defaultMessage: 'Usuário bloqueado'
  },
  systemUser: {
    id: 'users.systemUser',
    defaultMessage: 'Usuário de sistema'
  },
});

export default messages;
