import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import * as actions from '../../store/actions/index';

import { Container } from 'reactstrap';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import messages from './DocumentFieldDetailsPage.intl';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import Tabs from '../../components/Tabs/Tabs';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import DocumentFieldDetailsForm from '../../components/DocumentFieldDetailsForm/DocumentFieldDetailsForm';

import { AuthContext } from '../../context/AuthContext';
class DocumentFieldDetailsPage extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            showModalConfirmation: false,
            tabIndex: this.props.location.state?.tabIndex || 0,
        };
        this.documentFieldId = props.match.params.id;
    }

    DOCUMENT_FIELD_DEFAULT = {
        name: '',
        contractorId: '',
        documentType: '',
        categoryType: '',
        mapItem: {
            boundingBox: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            },
            field: {
                name: '',
                type: '',
                key: '',
                keyXml: '',
                formGroup: 0,
                description: '',
                isSearchable: false,
                constraints: {
                    required: false,
                    minLength: 0,
                    maxLength: 0,
                    pattern: '',
                    rows: 0,
                },
                layout: {
                    classes: '',
                    xs: 0,
                    offsetXs: 0,
                    sm: 0,
                    offsetSm: 0,
                    md: 0,
                    offsetMd: 0,
                    lg: 0,
                    offsetLg: 0,
                    xl: 0,
                    offsetXl: 0,
                },
                mapFieldType: {
                    startsWith: '',
                    endsWith: '',
                    order: 0,
                    mapKeyReference: '',
                    hideMapInDocument: false,
                    type: 0,
                    removeFirstLine: false,
                    fitXWidth: false,
                },
            },
        },
        includeDocument: this.props.match.params.id === 'newdocumentfield',
        active: true,
    };

    componentDidMount() {
        if (
            this.documentFieldId !== 'newdocumentfield' &&
            this.documentFieldId !== 'newmapfield'
        ) {
            this.props.onInitDocumentFieldsDetails(this.documentFieldId);
        }
        this.props.onInitCategories();
        this.props.onInitDocumentFieldsContractors();
    }

    handleSubmit = (event, values) => {
        if (
            this.documentFieldId === 'newdocumentfield' ||
            this.documentFieldId === 'newmapfield'
        )
            this.props.onCreateDocumentField(values);
        else this.props.onUpdateDocumentField(values, this.documentFieldId);
    };

    handleFieldTypeChange = e => {
        this.props.onFieldTypeChange(e.target.value);
    };

    handleToggleModalDelete = () => {
        this.setState({
            showModalConfirmation: !this.state.showModalConfirmation,
        });
    };

    handleDeleteField = async () => {
        this.setState({
            showModalConfirmation: !this.state.showModalConfirmation,
        });
        await this.props.onDeleteDocumentField(this.documentFieldId);
    };

    render() {
        const historyPath = this.props.match.params.historyPath;
        const { intl } = this.props;

        const isNew =
            this.props.match.params.id === 'newdocumentfield' ||
            this.props.match.params.id === 'newmapfield';

        const field = isNew ? this.DOCUMENT_FIELD_DEFAULT : this.props.field;

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: `/${historyPath}`,
                            name: intl.formatMessage(messages.titleInvoice),
                            active: false,
                        },
                        {
                            path: `/${historyPath}/documentsconfigs`,
                            name: intl.formatMessage(messages.titleDocumentsConfigs),
                            active: false,
                        },
                        {
                            path: `/${historyPath}/documentsconfigs/documentfield/${
                                this.props.match.params.id
                            }`,
                            name: intl.formatMessage(messages.titleField, {
                                entity: `(${this.props.match.params.id})`,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                isNew
                                    ? messages.newField
                                    : this.props.location.state?.tabIndex === 0
                                    ? messages.titleDocumentField
                                    : messages.titleMapField,
                            )}
                            subtitle={`(${this.props.match.params.id})`}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content'>
                    <Loading loading={this.props.loadingList} />

                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={0}
                        tabs={[
                            {
                                name: intl.formatMessage(messages.fields),
                                component: (
                                    <AvForm
                                        onValidSubmit={
                                            this.handleFieldFormSubmit
                                        }
                                    >
                                        <DocumentFieldDetailsForm
                                            key={
                                                this.props.field &&
                                                this.props.field.documentFieldId
                                            }
                                            field={field}
                                            loading={this.props.loadingList}
                                            handleToggleModalDelete={
                                                this.handleToggleModalDelete
                                            }
                                            contractors={this.props.contractors}
                                            categories={this.props.categories}
                                            handleDeleteField={
                                                this.handleDeleteField
                                            }
                                            handleSubmit={this.handleSubmit}
                                            isNew={isNew}
                                        />
                                    </AvForm>
                                ),
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteField)}
                    isOpen={this.state.showModalConfirmation}
                    handleToggleModalDelete={this.handleToggleModalDelete}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    toggleFirstButton={this.handleDeleteField}
                    toggleSecondButton={this.handleToggleModalDelete}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        field: state.documentFieldsReducer.field,
        loadingList: state.documentFieldsReducer.loadingList,
        contractors: state.documentFieldsReducer.contractors,
        categories: state.categoryReducer.categories || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //Reducer of Map
        onUpdateMapItem: (mapId, mapItemId, values) =>
            dispatch(actions.updateMapItemDetails(mapId, mapItemId, values)),

        //Reducer of DocumentField
        onInitDocumentFieldsDetails: documentFieldId =>
            dispatch(actions.initDocumentFieldsDetails(documentFieldId)),
        onInitDocumentFieldsContractors: () =>
            dispatch(actions.getContractors()),
        onCreateDocumentField: field =>
            dispatch(actions.createDocumentField(field)),
        onUpdateDocumentField: (fieldUpdate, documentFieldId) =>
            dispatch(actions.updateDocumentField(fieldUpdate, documentFieldId)),
        onDeleteDocumentField: documentFieldId =>
            dispatch(actions.deleteDocumentField(documentFieldId)),
        onInitCategories: () => dispatch(actions.initCategories()),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(DocumentFieldDetailsPage)),
);
