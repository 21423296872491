import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card, CardHeader, CardBody, Collapse, Badge } from 'reactstrap';
import { useState } from 'react';

const DynamicPanel = props => {
    const { index, hasFields, isOpenCollapse, badgeStatus } = props;

    useEffect(() => {
        if (String(isOpenCollapse) === 'false') {
            setIsOpen(false);
        }
    }, [isOpenCollapse]);

    const [isOpen, setIsOpen] = useState(true);

    const handleIsOpen = () => setIsOpen(!isOpen);

    return (
        <Card tabIndex={0} style={{ marginBottom: '1rem' }} key={index}>
            <CardHeader data-event={props.index}>
                {props.headerTitle}

                {!!badgeStatus && (
                    <Badge
                        style={{
                            fontSize: '0.8rem',
                            color: '#ffffff',
                            display: 'inline-block',
                            width: '73px',
                            textAlign: 'center',
                            marginLeft: '10px',
                        }}
                        color={badgeStatus.color}
                        pill
                    >
                        {badgeStatus.title}
                    </Badge>
                )}
                {hasFields && (
                    <button
                        onClick={e => handleIsOpen()}
                        id={index}
                        style={{
                            margin: 0,
                            width: 35,
                            marginBottom: 5,
                        }}
                        type='button'
                    >
                        <FontAwesomeIcon
                            inverse
                            icon={`${isOpen ? 'chevron-up' : 'chevron-down'}`}
                        />
                    </button>
                )}
            </CardHeader>

            <Collapse isOpen={isOpen}>
                <CardBody>{props.children}</CardBody>
            </Collapse>
        </Card>
    );
};

export default injectIntl(DynamicPanel);
