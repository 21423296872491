import React, { useContext, useState, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import messages from './CteListPage.intl';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router';
import { Container } from 'reactstrap';

import { AuthContext } from '../../context/AuthContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ModalSupplier from '../../components/ModalSupplier/ModalSupplier';
import CteDocumentList from './pages/CteDocumentList';

function CteListPage(props) {
    const { intl } = props;
    const context = useContext(AuthContext);
    const history = useHistory();

    const [showSupplierPicker, setShowSupplierPicker] = useState(false);
    const [selectedSupplierId, setSelectedSupplierId] = useState('');
    const [selectedUploaderEmail, setUploaderEmail] = useState('');

    useEffect(() => {
        props.onCleanSupplierMaps();
    }, []);

    useEffect(() => {
        const origin = 'WEB_UI';
        if (selectedSupplierId !== '') {
            history.push({
                pathname: `/cteDocuments/new`,
                state: {
                    supplierId: selectedSupplierId,
                    uploaderEmail: selectedUploaderEmail,
                    origin: origin,
                },
            });
            setShowSupplierPicker(false);
        }
    }, [selectedSupplierId]);

    // #region Functions to ModalSupplier
    const handleSupplierPickerToggle = () =>
        setShowSupplierPicker(!showSupplierPicker);

    function handleSupplierPickerSubmit(e, values) {
        const supplierId = values.supplierId || '';

        setSelectedSupplierId(supplierId);
    }
    // #endregion

    // #region Functions to CteDocumentList
    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: { cursor: 'pointer' },
            onClick: e => history.push(`/cteDocuments/${rowInfo.original.id}`),
        };
    }

    function handleNewDocumentButtonClick() {
        if (
            context.hasClaim('role-level-full') ||
            context.hasClaim('role-level-contractor')
        ) {
            const uploaderEmail = context.emailAddress;
            setShowSupplierPicker(!showSupplierPicker);
            setUploaderEmail(uploaderEmail);
        } else if (context.hasClaim('role-level-supplier')) {
            const supplierId = context.supplierId;
            const uploaderEmail = context.emailAddress;

            setSelectedSupplierId(supplierId);
            setUploaderEmail(uploaderEmail);
            setShowSupplierPicker(false);
        }
    }
    // #endregion

    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/cteDocuments',
                        name: intl.formatMessage(messages.title),
                        active: true,
                    },
                ]}
            />
            <section className='content-middle'>
                <CteDocumentList
                    handleNewDocumentButtonClick={handleNewDocumentButtonClick}
                    handleGetTrProps={handleGetTrProps}
                    newDocumentButtonEnabled={context.hasClaim(
                        'cte-document-create',
                    )}
                />
            </section>
            <ModalSupplier
                handleSupplierPickerToggle={handleSupplierPickerToggle}
                handleSupplierPickerSubmit={handleSupplierPickerSubmit}
                showSupplierPicker={showSupplierPicker}
                showContractor={true}
                selectedSupplier={{}}
                selectedContractor={{}}
            />
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        maps: state.supplierReducer.maps || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // Modals
        onCleanSupplierMaps: () => dispatch(actions.cleanSupplierMaps()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(CteListPage)),
);
