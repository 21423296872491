import React, { Component } from 'react';
import { withRouter } from 'react-router';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import messages from './InvoicesTable.intl';

import EvtTable from '../../components/EvtTable/EvtTable';

class InvoicesTable extends Component {
    state = { filters: [] };

    render() {
        const {
            intl,
            documents,
            documentsCount,
            loadingOrderDocuments,
            handleEvTableStateChange,
            handleGetTrProps,
        } = this.props;

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>Número da nota</span>
                    </div>
                ),
                accessor: 'invoiceNumber',
                Cell: row => {
                    let invoiceNumberValue = row.value;
                    var formField = row.original?.form?.fields;
                    var docType = row.original.documentType;

                    if (docType.toUpperCase() === 'CTE') {
                        invoiceNumberValue = formField?.find(
                            x => x.key === '/cteProc/CTe/infCte/ide/nCT',
                        ).value;
                    } else if (docType.toUpperCase() === 'NFE') {
                        invoiceNumberValue = formField?.find(
                            x => x.key === '/nfeProc/NFe/infNFe/ide/nNF',
                        ).value;
                    }

                    return <p>{invoiceNumberValue || ''}</p>;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.contractors)}</span>
                    </div>
                ),
                accessor: 'contractorName',
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.suppliers)}</span>
                    </div>
                ),
                accessor: 'supplierName',
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>Status</span>
                    </div>
                ),
                id: 'status',
                sortMethod: (a, b) => {
                    const preference = {
                        'status-invoice status-success': 1,
                        'status-invoice status-pending': 2,
                        'status-invoice status-error': 3,
                    };

                    const A =
                        preference[a.props.className.toLowerCase()] ||
                        Number.MAX_SAFE_INTEGER;
                    const B =
                        preference[b.props.className.toLowerCase()] ||
                        Number.MAX_SAFE_INTEGER;

                    return A - B;
                },
                accessor: d => {
                    let classStatus = 'status-invoice ';

                    if (d.currentStep.status.toLowerCase() === 'pending') {
                        classStatus += 'status-pending';
                    } else if (
                        d.currentStep.status.toLowerCase() === 'success'
                    ) {
                        classStatus += 'status-success';
                    } else if (d.currentStep.status.toLowerCase() === 'error') {
                        classStatus += 'status-error';
                    }
                    return (
                        <div className={classStatus} title={'Status'}>
                            <span />
                            <p>{d.currentStep.text}</p>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdAt)}</span>
                    </div>
                ),
                accessor: 'createdAt',
                Cell: row => {
                    const date = new Date(row.value).toLocaleString(
                        navigator.language,
                    );
                    return (
                        <div className='customer-container'>
                            <div className=''>{date}</div>
                        </div>
                    );
                },
            },
        ];

        return (
            <section className='content-middle'>
                <EvtTable
                    fetchPage
                    filters={this.state.filters}
                    columns={columns}
                    data={documents}
                    length={documentsCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChange}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingOrderDocuments}
                    tabInside
                />
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        order: state.orderReducer.order,
        documents: state.orderReducer.documents,
        documentsCount: state.orderReducer.documentsCount || 0,
        loadingOrderDocuments: state.orderReducer.loadingOrderDocuments || 0,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnInitOrderDocuments: (orderId, query) =>
            dispatch(actions.initOrderDocuments(orderId, query)),
        OnInitOrderDocumentsCount: (orderId, query) =>
            dispatch(actions.initOrderDocumentsCount(orderId, query)),
    };
};

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(InvoicesTable),
    ),
);
