import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    newDocument: {
        id: 'invoices.newDocument',
        defaultMessage: 'Novo documento',
    },
    uploadMultipleFilesText: {
        id: 'maps.uploadMultipleFilesText',
        defaultMessage: 'Somente um arquivo disponível para upload',
    },
    imgFileSizeText: {
        id: 'maps.imgFileSizeText',
        defaultMessage: 'O arquivo deve ser menor do que {size}MB',
    },
    completedRequiredFieldsText: {
        id: 'maps.completedRequiredFieldsText',
        defaultMessage: 'concluído do campos obrigatórios',
    },

    cropImage: {
        id: 'maps.cropImage',
        defaultMessage: 'Cortar imagem',
    },

    formText: {
        id: 'invoices.formText',
        defaultMessage: 'Formulário',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    title: {
        id: 'invoices.title',
        defaultMessage: 'Documentos',
    },

    titleInvoice: {
        id: 'invoices.titleInvoice',
        defaultMessage: 'Nota Fiscal',
    },

    btnTransactions: {
        id: 'invoices.transactions',
        defaultMessage: 'Pagamentos',
    },

    cropSuccessMessage: {
        id: 'fileUploadForm.cropSuccessMessage',
        defaultMessage: 'A imagem foi recortada com sucesso.',
    },
    cropInstructions: {
        id: 'fileUploadForm.cropInstructions',
        defaultMessage:
            'Para recortar a imagem mova as bordas da área selecionada e clique em Confirmar.',
    },
    uploadXmlSuccessMessage: {
        id: 'fileUploadForm.uploadXmlSuccessMessage',
        defaultMessage: 'Xml adicionado com sucesso',
    },
    uploadXmlInstructions: {
        id: 'fileUploadForm.uploadXmlInstructions',
        defaultMessage: 'Confirma a entrada do documento no Portal?',
    },
    errorMaxSize: {
        id: 'fileUploadForm.errorMaxSize',
        defaultMessage: 'O arquivo ultrapassa o máximo de 50MB',
    },
    errorNoFiles: {
        id: 'fileUploadForm.errorNoFiles',
        defaultMessage: 'Não foi possível encontrar o arquivo de envio',
    },
    addOrderButton: {
        id: 'fileUploadForm.addOrderButton',
        defaultMessage: 'Vincular Pedido',
    },
    itemOrder: {
        id: 'documents.itemOrder',
        defaultMessage: 'Item do Pedido',
    },
    order: {
        id: 'documents.order',
        defaultMessage: 'Pedido',
    },
    searchItemOrders: {
        id: 'documents.searchItemOrders',
        defaultMessage: 'Escolha um Item',
    },
    linkItemInDocument: {
        id: 'fileUploadForm.linkItemInDocument',
        defaultMessage:
            '{entity} pedidos/itens serão associados automaticamente ao documento enviado.',
    },
});
