import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import messages from './SettingsDocumentForm.intl';
import { documentTypeCategories } from '../SettingsPage';

const SettingsDocumentFormNFS = props => {
    const {
        intl,
        handleFormSubmit,
        moduleSettings,
        documentType,
        title,
        titleNacional,
        allowedCategories,
        selectedAllowedCategories,
        handleAllowedCategoriesSelectChange,
        cities,
        nacionalCities,
    } = props;

    return (
        <AvForm
            onValidSubmit={(event, values) =>
                handleFormSubmit(event, {
                    ...values,
                    documentType: documentType,
                    hideMonitiring: false,
                })
            }
        >
            <div>
                <h5 className='title-area-form'>
                    {intl.formatMessage(messages.monitoringModule, {
                        entity: title,
                    })}
                </h5>
                <Row style={{ maxHeight: 70 }}>
                    <Col md={6}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.enableMonitoring,
                                    { entity: title },
                                )}
                                type='checkbox'
                                name='enableMonitoring'
                                id='enableMonitoring'
                                value={moduleSettings?.enableMonitoring}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <AvField
                            label={intl.formatMessage(
                                messages.receiveDocumentsOnlyAfterDate,
                            )}
                            type='date'
                            name='receiveDocumentsOnlyAfterDate'
                            id='receiveDocumentsOnlyAfterDate'
                            value={
                                moduleSettings?.receiveDocumentsOnlyAfterDate?.split(
                                    'T',
                                )[0]
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={6}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 30,
                        }}
                    >
                        <FormGroup>
                            <label className='text'>
                                {cities && cities.length > 0
                                    ? intl.formatMessage(
                                          messages.thereAreCities,
                                          { entity: cities.length },
                                      )
                                    : intl.formatMessage(messages.noCities)}
                            </label>
                        </FormGroup>
                        <button
                            type='button'
                            style={{
                                marginTop: 0,
                            }}
                            onClick={props.handleOpenCities}
                        >
                            {intl.formatMessage(messages.registerCities)}
                        </button>
                    </Col>
                </Row>
            </div>
            <div>
                <h5 className='title-area-form'>
                    {intl.formatMessage(messages.monitoringModule, {
                        entity: titleNacional,
                    })}
                </h5>
                <Row style={{ maxHeight: 70 }}>
                    <Col md={6}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.enableMonitoringNacional,
                                    { entity: title },
                                )}
                                type='checkbox'
                                name='enableMonitoringNacional'
                                id='enableMonitoringNacional'
                                value={moduleSettings?.enableMonitoringNacional}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={6}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 30,
                        }}
                    >
                        <FormGroup>
                            <label className='text'>
                                {nacionalCities && nacionalCities.length > 0
                                    ? intl.formatMessage(
                                          messages.thereAreCities,
                                          { entity: nacionalCities.length },
                                      )
                                    : intl.formatMessage(messages.noCities)}
                            </label>
                        </FormGroup>
                        <button
                            type='button'
                            style={{
                                marginTop: 0,
                            }}
                            onClick={props.handleOpenNacionalCities}
                        >
                            {intl.formatMessage(messages.registerCities)}
                        </button>
                    </Col>                 
                </Row>
            </div>

            <h5 className='title-area-form'>{title}</h5>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.documentSkipValidation,
                            )}
                            type='checkbox'
                            name='skipValidationStepWhenAllFieldsAreValid'
                            id='skipValidationStepWhenAllFieldsAreValid'
                            value={
                                moduleSettings?.skipValidationStepWhenAllFieldsAreValid
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <label>
                        {intl.formatMessage(messages.allowedCategories)}
                    </label>
                    <Select
                        id='categoryIdAllowed'
                        name='allowedCategories'
                        className='evt-select'
                        options={allowedCategories}
                        isMulti
                        placeholder={intl.formatMessage(
                            messages.selectPlaceholder,
                        )}
                        closeMenuOnSelect={false}
                        value={selectedAllowedCategories}
                        onChange={(e) => handleAllowedCategoriesSelectChange(e, documentTypeCategories.NFS)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.documentIsRequiredOrderNumber,
                            )}
                            type='checkbox'
                            name='isRequiredOrderNumberToSendDocuments'
                            id='isRequiredOrderNumberToSendDocuments'
                            value={
                                moduleSettings?.isRequiredOrderNumberToSendDocuments
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.blockInvoicesWithSameNumber,
                            )}
                            type='checkbox'
                            name='blockInvoicesWithSameNumber'
                            id='blockInvoicesWithSameNumber'
                            value={moduleSettings?.blockInvoicesWithSameNumber}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowIdentifySupplier,
                            )}
                            type='checkbox'
                            name='allowIdentifySupplier'
                            id='allowIdentifySupplier'
                            value={moduleSettings?.allowIdentifySupplier}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowDocumentWithoutMapForSupplier,
                            )}
                            type='checkbox'
                            name='allowDocumentWithoutMapForSupplier'
                            id='allowDocumentWithoutMapForSupplier'
                            value={
                                moduleSettings?.allowDocumentWithoutMapForSupplier
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.saveOriginalFile,
                            )}
                            type='checkbox'
                            name='saveOriginalFile'
                            id='saveOriginalFile'
                            value={moduleSettings?.saveOriginalFile}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.doNotSendToIntegrationDocumentsThatNotBelogsToCompapy,
                            )}
                            type='checkbox'
                            name='doNotSendToIntegrationDocumentsThatNotBelogsToCompapy'
                            id='doNotSendToIntegrationDocumentsThatNotBelogsToCompapy'
                            value={
                                moduleSettings?.doNotSendToIntegrationDocumentsThatNotBelogsToCompapy
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowCanceledDocumentsAsEventDocumentType,
                            )}
                            type='checkbox'
                            name='allowCanceledDocumentsAsEventDocumentType'
                            id='allowCanceledDocumentsAsEventDocumentType'
                            value={
                                moduleSettings?.allowCanceledDocumentsAsEventDocumentType
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.allowAutomaticSupplierCreation,
                                )}
                                type='checkbox'
                                name='allowAutomaticSupplierCreation'
                                id='allowAutomaticSupplierCreation'
                                value={
                                    moduleSettings?.allowAutomaticSupplierCreation
                                }
                            />
                        </FormGroup>
                    </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.blockInboundDocumentsIn24hours,
                            )}
                            type='checkbox'
                            name='blockInboundDocumentsIn24hours'
                            id='blockInboundDocumentsIn24hours'
                            value={
                                moduleSettings?.blockInboundDocumentsIn24hours
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.associateBillsFromEmail,
                            )}
                            type='checkbox'
                            name='associateBillsFromEmail'
                            id='associateBillsFromEmail'
                            value={
                                moduleSettings?.associateBillsFromEmail
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowSendEmailToSupplier,
                            )}
                            type='checkbox'
                            name='allowSendEmailToSupplier'
                            id='allowSendEmailToSupplier'
                            value={
                                moduleSettings?.allowSendEmailToSupplier
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.blockNonPDFDocuments,
                            )}
                            type='checkbox'
                            name='blockNonPDFDocuments'
                            id='blockNonPDFDocuments'
                            value={
                                moduleSettings?.blockNonPDFDocuments
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.hasIAMapping,
                            )}
                            type='checkbox'
                            name='hasIAMapping'
                            id='hasIAMapping'
                            value={
                                moduleSettings?.hasIAMapping
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <button
                        type='submit'
                        className='btn-submit'
                        disabled={props.loading}
                    >
                        {intl.formatMessage(messages.submitButton)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    );
};

export default injectIntl(SettingsDocumentFormNFS);
