import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { FormGroup, Row, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import FormComboBox from './components/FormComboBox';

import { filterPropertyTypes } from '../../utils/enums';
import { Operators } from '../../models/FilterBuilder';
import FormDatePicker from './components/FormDatePicker';

function DynamicFormFilterField(props) {
    const { property, filter } = props;

    const [defaultFilter, setDefaultFilter] = useState({});

    useEffect(() => {
        const newFilter = {
            hideAddButton: false,
            property: property.key,
            operator: Operators.like,
            value: filter?.value || '',
            valueFrom: filter?.valueFrom || '',
            valueTo: filter?.valueTo || '',
            type: property.type,
            enums: property.options || [],
            id: property.key,
        };
        setDefaultFilter(newFilter);
    }, []);

    let field = (
        <Col lg={property.lg || 4} md={property.md || 4} sm={property.sm || 12}>
            <FormGroup>
                <AvField
                    label={property.value}
                    type='text'
                    name={`filters[${defaultFilter.id}].value`}
                    id={`filters[${defaultFilter.id}].value`}
                    value={defaultFilter.value || ''}
                    errorMessage=' '
                />
            </FormGroup>
        </Col>
    );

    switch (property.type) {
        case filterPropertyTypes.DATE:
            field = (
                <FormDatePicker filter={defaultFilter} property={property} />
            );
            break;
        case filterPropertyTypes.ENUM:
            field = (
                <FormComboBox
                    filter={defaultFilter}
                    property={property}
                    enums={property.options || []}
                />
            );
            break;
        case filterPropertyTypes.TEXT:
        case filterPropertyTypes.NUMBER:
        default:
            break;
    }

    return field;
}

export default injectIntl(DynamicFormFilterField);
