import React, { useContext, useState, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import messages from './OtherListPage.intl';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router';
import { Container } from 'reactstrap';

import { AuthContext } from '../../context/AuthContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ModalSupplier from '../../components/ModalSupplier/ModalSupplier';
import OtherDocumentList from './pages/OtherList';
import ModalMap from '../../components/ModalMap/ModalMap';
import { toast } from 'react-toastify';

function OtherListPage(props) {
    const { intl, maps, onInitSupplierMaps } = props;

    const context = useContext(AuthContext);
    const history = useHistory();

    const [showSupplierPicker, setShowSupplierPicker] = useState(false);
    const [showMapPicker, setShowMapPicker] = useState(false);
    const [selectedSupplierId, setSelectedSupplierId] = useState('');
    const [selectedUploaderEmail, setUploaderEmail] = useState('');
    const [mapsCleaned, setMapsCleaned] = useState(false);

    useEffect(() => {
        props.onCleanSupplierMaps();
        setMapsCleaned(true);
    }, []);

    useEffect(() => {
        if (mapsCleaned && !!selectedSupplierId) {
            const origin = 'WEB_UI';
            const mapsCount = maps.length;

            if (mapsCount === 0) {
                history.push({
                    pathname: `/otherDocuments/new`,
                    state: {
                        supplierId: selectedSupplierId,
                        mapId: null,
                        uploaderEmail: selectedUploaderEmail,
                        origin: origin,
                        hideCrop,
                        pushUrl: '/otherDocuments',
                    },
                });
                setShowMapPicker(false);
                setShowSupplierPicker(false);
            } else if (mapsCount === 1) {
                var _maps = maps;
                var mapFull =
                    _maps && _maps.find(map => map.mapId === maps[0].mapId);
                var hideCrop = mapFull && mapFull.hideCrop;
                history.push({
                    pathname: `/otherDocuments/new`,
                    state: {
                        supplierId: selectedSupplierId,
                        mapId: maps[0].mapId,
                        uploaderEmail: selectedUploaderEmail,
                        origin: origin,
                        hideCrop,
                        pushUrl: '/otherDocuments',
                    },
                });

                setShowSupplierPicker(false);
                setSelectedSupplierId('');
            } else {
                setShowMapPicker(true);
                setShowSupplierPicker(false);
            }
        }
    }, [maps, mapsCleaned]);

    // #region Functions to ModalSupplier
    const handleSupplierPickerToggle = () =>
        setShowSupplierPicker(!showSupplierPicker);

    function handleSupplierPickerSubmit(e, values) {
        const supplierId = values.supplierId || '';

        onInitSupplierMaps(supplierId, '?skip=0&take=500');

        setSelectedSupplierId(supplierId);
    }
    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: { cursor: 'pointer' },
            onClick: e =>
                history.push(`/otherDocuments/${rowInfo.original.id}`),
        };
    }

    function handleNewDocumentButtonClick() {
        if (
            context.hasClaim('role-level-full') ||
            context.hasClaim('role-level-contractor')
        ) {
            const uploaderEmail = context.emailAddress;
            setShowSupplierPicker(!showSupplierPicker);
            setUploaderEmail(uploaderEmail);
        } else if (context.hasClaim('role-level-supplier')) {
            const supplierId = context.supplierId;
            const uploaderEmail = context.emailAddress;

            props.onInitSupplierMaps(supplierId, '?skip=0&take=500');

            setSelectedSupplierId(supplierId);
            setUploaderEmail(uploaderEmail);
            setShowSupplierPicker(false);
        }
    }
    // #endregion

    // #region Functions to ModalMap

    const handleMapPickerToggle = () => {
        setShowMapPicker(prev => !prev);
    };

    // #endregion
    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/otherDocuments',
                        name: intl.formatMessage(messages.title),
                        active: true,
                    },
                ]}
            />
            <section className='content-middle'>
                <OtherDocumentList
                    handleNewDocumentButtonClick={handleNewDocumentButtonClick}
                    handleGetTrProps={handleGetTrProps}
                    newDocumentButtonEnabled={context.hasClaim(
                        'document-create',
                    )}
                    hasFull={context.hasClaim('role-level-full')}
                />
            </section>
            <ModalSupplier
                handleSupplierPickerToggle={handleSupplierPickerToggle}
                handleSupplierPickerSubmit={handleSupplierPickerSubmit}
                showSupplierPicker={showSupplierPicker}
                showContractor={true}
                selectedSupplier={{}}
                selectedContractor={{}}
            />
            <ModalMap
                handleMapPickerToggle={handleMapPickerToggle}
                handleCleanSupplierPicker={() => setSelectedSupplierId('')}
                showMapPicker={showMapPicker}
                selectedSupplierId={selectedSupplierId}
                pathname='otherDocuments'
            />
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        maps: state.supplierReducer.maps || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitSupplierMaps: (supplierId, filter) =>
            dispatch(actions.initSupplierMaps(supplierId, filter)),
        onCleanSupplierMaps: () => dispatch(actions.cleanSupplierMaps()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(OtherListPage)),
);
