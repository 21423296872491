import React from 'react';
import { injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './FinancialVolumeCounterWidget.intl';
import ErrorWidget from '../../../components/ErrorBoundary/ErrorWidget';

const FinancialVolumeCounterWidget = props => {
    const { intl } = props;


    return (
        <ErrorWidget>
            <React.Fragment>
                <FontAwesomeIcon icon='dollar-sign' className='mr-5' size='4x' />
                <div>
                    <p className='text-center'>
                        {intl.formatMessage(messages.financialVolumeCounter)}
                    </p>
                    <h1>
                        R$
                        {props.data
                            ? props.data.toFixed(2).replace('.', ',')
                            : '0,00'}
                    </h1>
                </div>
            </React.Fragment>
        </ErrorWidget>
    );
};

export default injectIntl(FinancialVolumeCounterWidget);
