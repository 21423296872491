import React from 'react';
import ErrorIcon from '../../assets/icons/ErrorIcon';
import { Container } from 'reactstrap';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
  
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container fluid>
            <div style={{marginTop: "10rem", marginLeft: '-5rem'}}>
              <h4 style={{textAlign: 'center'}}>Sem dados disponíveis</h4>
              <h5 style={{textAlign: 'center'}}>Não existe dados disponíveis, aperte 
                <div style={{color: 'blue', display:'inline-block', marginLeft: '3px', marginRight: '3px'}}>
                  F5
                </div> 
                e atualize a página.
              </h5>
                <div style={{textAlign: 'center',display: 'inline-block', display: 'block'}}>
                  <a href="#">
                    <ErrorIcon>
                    </ErrorIcon>
                  </a>
                </div>
          </div>
        </Container>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;