import React, { useState, useEffect, useMemo, useContext } from 'react';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, withRouter } from 'react-router';

import messages from '../NfeListPage.intl';

import Header from '../../../components/Header/Header';
import EvtTable from '../../../components/EvtTable/EvtTable';
import EvtAlternativeFilter from '../../../components/EvtAlternativeFilter/EvtAlternativeFilter';
import {
    ColumnsNfeSummary,
    nfeSummaryProperties,
} from '../components/ColumnsNfe';

import { formatFilters } from '../../DocumentListPage/DocumentListPage';

function NfeSummaryList(props) {
    const {
        intl,
        onInitSummaryNfe,
        summaryNfe,
        summaryNfeCount,
        loadingNfeSummaryList,
        activeTab,
    } = props;
    const history = useHistory();

    const [query, setQuery] = useState('');
    const [filtersNfeSummary, setFiltersNfeSummary] = useState({
        filters: [],
        filterValue: '',
    });

    //Mount Page
    useEffect(() => {
        const URL = window.location.search;

        if (URL.includes('&search=')) {
            const filtersURL = formatFilters(URL);
            const value = filtersURL.shift().value;
            setFiltersNfeSummary({ ...filtersNfeSummary, filterValue: value });
        }
    }, []);

    //Update Page
    useEffect(() => {
        reloadNfeSummaryList();
    }, [query]);

    function reloadNfeSummaryList() {
        const queryIndex = query?.includes('&index=')
            ? query
            : query + `&index=${activeTab}`;

        if (query?.includes('take')) {
            onInitSummaryNfe(query, filtersNfeSummary.filterValue);

            history.push('/nfeDocuments' + queryIndex);
        }
    }

    const handleFiltersNfeSummaryChange = (newFiltersNfe, value) =>
        setFiltersNfeSummary({ filters: newFiltersNfe, filterValue: value });

    function handleEvTableStateChangeNfe(queryTable) {
        setQuery(queryTable);
    }

    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: { cursor: 'pointer' },
        };
    }

    const handleManifestAwarenessSummary = summary => {
        const id = summary.id;
        const summaryModel = {
            cNPJ: summary.cnpj,
            ContractorId: summary.contractorId,
            NFeKey: summary.nFeKey,
            justificative: '',
        };

        props.onInitAwarenessSummary(id, summaryModel);
    };

    const handleManifestUnawareSummary = summary => {
        const id = summary.id;
        const summaryModel = {
            cNPJ: summary.cnpj,
            ContractorId: summary.contractorId,
            NFeKey: summary.nFeKey,
            justificative: '',
        };

        props.onInitDefineUnknownSummary(id, summaryModel);
    };

    const handleSearchNfe = summary => {
        const summaryModel = {
            cNPJ: summary.cnpj,
            ContractorId: summary.contractorId,
            NFeKey: summary.nFeKey,
        };

        props.onInitSearchNFe(summaryModel);
    };

    return (
        <>
            <header className='table-screen'>
                <section className='title'>
                    <Header title={intl.formatMessage(messages.title)} />
                </section>
                <section className='btns-topo'>
                    <EvtAlternativeFilter
                        properties={nfeSummaryProperties(intl)}
                        handleFiltersChange={handleFiltersNfeSummaryChange}
                        loading={loadingNfeSummaryList}
                    />
                    <button
                        className={`new-btn small ${
                            loadingNfeSummaryList ? 'loading' : ''
                        }`}
                        onClick={() => reloadNfeSummaryList()}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                </section>
            </header>
            <section>
                <EvtTable
                    filters={filtersNfeSummary.filters}
                    columns={ColumnsNfeSummary(
                        summaryNfe,
                        intl,
                        handleManifestAwarenessSummary,
                        handleManifestUnawareSummary,
                        handleSearchNfe,
                    )}
                    data={summaryNfe}
                    length={summaryNfeCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeNfe}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingNfeSummaryList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: summaryNfeCount,
                    })}
                    search
                />
            </section>
        </>
    );
}

const mapStateToProps = state => {
    return {
        summaryNfe: state.NFeReducer.summaryNfe || [],
        summaryNfeCount: state.NFeReducer.summaryNfeCount || 0,
        loadingNfeSummaryList: state.NFeReducer.loadingNfeSummaryList || false,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //Filters
        onInitSummaryNfe: (filters, value) =>
            dispatch(actions.initSummaryNfe(filters, value)),

        //Dropdown Actions
        onInitAwarenessSummary: (summaryId, summaryModel) =>
            dispatch(actions.initAwarenessSummary(summaryId, summaryModel)),
        onInitDefineUnknownSummary: (summaryId, summaryModel) =>
            dispatch(actions.initDefineUnknownSummary(summaryId, summaryModel)),
        onInitSearchNFe: summaryModel =>
            dispatch(actions.initSearchNFe(summaryModel)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(NfeSummaryList)),
);
