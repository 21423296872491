import React from "react";

const LogIcon = ({
  width="26",
  height="30",
  viewBox="0 0 36 40",
  fill="none",
  style="",
  color="black"
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {style}
    xmlns="http://www.w3.org/2000/svg"
  >
  
    <path d="M6 26V16.6667" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 11.3333V2" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.6667 26V14" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.6667 8.66667V2" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M27.3334 26V19.3333" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M27.3334 14V2" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 16.6667H10" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.6667 8.66666H20.6667" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.3334 19.3333H31.3334" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default LogIcon;
