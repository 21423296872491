import React, { Component } from 'react';

const AuthContext = React.createContext();

class AuthProvider extends Component {
    render() {
        const { session } = this.props;
        return (
            <AuthContext.Provider value={session}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export { AuthContext, AuthProvider };
