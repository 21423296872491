import * as actionTypes from '../actions/actionTypes';

const initialState = {
    mailBoxesCount: 0,
    mailBoxes: [],
    mailBox: null,
    loadingList: false,
    loadingCities: false,
    moduleSettingsNFS: null,
    moduleSettingsNFE: null,
    moduleSettingsCTE: null,
    moduleSettingsOther: null,
    ordersSettings: null,
    inboundSettings: null,
    reportScheduled: null,
    notificationsSettings: null,
    notifications: null,
    inboundSettings: null,

    enabledCities: [],
    checkedCities: [],

    enabledNacionalCities: [],
    checkedNacionalCities: [],
};

// Reducers
const setLoadingListTrue = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setLoadingFalseError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const setLoadingFalseNoError = (state, _action) => {
    return {
        ...state,
        loadingList: false,
    };
};

//CRUD MailBox
const setMailBoxes = (state, { mailBoxes, mailBoxesCount }) => {
    return {
        ...state,
        mailBoxes,
        mailBoxesCount,
        loadingList: false,
        mailBox: null,
    };
};

const mailBoxSuccess = (state, { mailBox }) => {
    return {
        ...state,
        mailBox,
        loadingList: false,
    };
};

const setMailBoxDetailsNew = (state, action) => {
    return {
        ...state,
        mailBox: undefined,
        loadingList: false,
    };
};

const setEmailToNotifyRequest = (state, action) => {
    return {
        ...state,
        loadingList: true,
        checkedCities: [],
    };
};

const setEmailToNotify = (
    state,
    { settingsEmailToNotify, inboundSettings },
) => {
    const cities = inboundSettings?.nfs?.citiesToMonitor || [];
    const nacionalCities = inboundSettings?.nfs?.citiesToMonitorNacional || [];

    let checkedCities = [];
    let checkedNacionalCities = [];

    cities.forEach(val => {
        checkedCities[val] = true;
    });

    nacionalCities.forEach(val => {
        checkedNacionalCities[val] = true;
    });
    return {
        ...state,
        settingsEmailToNotify,
        inboundSettings,
        checkedCities,
        checkedNacionalCities,
        loadingList: false,
        moduleSettingsNFS: inboundSettings?.nfs,
        moduleSettingsNFE: inboundSettings?.nfe,
        moduleSettingsCTE: inboundSettings?.cte,
        moduleSettingsOther: inboundSettings?.other,
        ordersSettings: inboundSettings?.order,
        reportScheduled: inboundSettings?.reportScheduled,
        notificationsSettings: inboundSettings?.notifications,
    };
};

const setUpdateModuleSettings = (state, { moduleSettings, documentType }) => {
    switch (documentType) {
        case 'NFS':
            return {
                ...state,
                loadingList: false,
                moduleSettingsNFS: moduleSettings,
            };
        case 'NFE':
            return {
                ...state,
                loadingList: false,
                moduleSettingsNFE: moduleSettings,
            };
        case 'CTE':
            return {
                ...state,
                loadingList: false,
                moduleSettingsCTE: moduleSettings,
            };
        case 'Other':
            return {
                ...state,
                loadingList: false,
                moduleSettingsOther: moduleSettings,
            };
        default:
            return {
                ...state,
                loadingList: false,
            };
    }
};

const setUpdateEmailToNotify = (state, { settingsEmailToNotify }) => {
    return {
        ...state,
        loadingList: false,
        settingsEmailToNotify,
    };
};

const setCreateEmailToNotify = (state, { settingsEmailToNotify }) => {
    return {
        ...state,
        settingsEmailToNotify,
        loadingList: false,
    };
};

const setUpdateOrdersSettings = (state, { ordersSettings }) => {
    return {
        ...state,
        loadingList: false,
        ordersSettings,
    };
};

const setUpdateReport = (state, { reportScheduled }) => {
    return {
        ...state,
        reportScheduled: reportScheduled,
        loadingList: false,
    };
};

const setEnabledCities = (state, { enabledCities, error }) => {
    return {
        ...state,
        enabledCities,
        loadingCities: false,
        error: error || null,
    };
};

const setEnabledCitiesResquest = (state, action) => {
    return {
        ...state,
        enabledCities: [],
        loadingCities: true,
    };
};

const setEnabledNacionalCities = (state, { enabledNacionalCities, error }) => {
    return {
        ...state,
        enabledNacionalCities,
        loadingCities: false,
        error: error || null,
    };
};

const setEnabledNacionalCitiesResquest = (state, action) => {
    return {
        ...state,
        enabledNacionalCities: [],
        loadingCities: true,
    };
};

const setCheckedNacionalCities = (state, { checkedNacionalCities }) => {
    return {
        ...state,
        checkedNacionalCities,
    };
};

const setCheckedCities = (state, { checkedCities }) => {
    return {
        ...state,
        checkedCities,
    };
};

const setNotifications = (state, { notifications }) => {
    return {
        ...state,
        notifications,
        loadingList: false,
    };
};

const setNewNotifications = (state, { notificationsSettings }) => {
    return {
        ...state,
        notificationsSettings,
        loadingList: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //REQUEST
        case actionTypes.SETTINGS_EMAILS_GET_LIST_REQUEST:
        case actionTypes.SETTINGS_EMAILS_GET_COUNT_REQUEST:
        case actionTypes.SETTINGS_EMAIL_GET_DETAILS_REQUEST:
        case actionTypes.SETTINGS_EMAIL_PUT_REQUEST:
        case actionTypes.SETTINGS_EMAIL_DELETE_REQUEST:
        case actionTypes.SETTINGS_EMAIL_CREATE_REQUEST:
        case actionTypes.SETTINGS_EMAIL_NOTIFY_UPDATE_REQUEST:
        case actionTypes.SETTINGS_EMAIL_NOTIFY_CREATE_REQUEST:
        case actionTypes.SETTINGS_UPDATE_MODULE_REQUEST:
        case actionTypes.SETTINGS_ORDERS_PUT_REQUEST:
        case actionTypes.SETTINGS_CITIES_MONITOR_PUT_REQUEST:
        case actionTypes.SETTINGS_REPORTS_PUT_REQUEST:
        case actionTypes.NOTIFICATIONS_GET_REQUEST:
        case actionTypes.NOTIFICATIONS_PUT_REQUEST:
            return setLoadingListTrue(state, action);

        case actionTypes.SETTINGS_EMAIL_NOTIFY_REQUEST:
            return setEmailToNotifyRequest(state, action);

        // FAILURE
        case actionTypes.SETTINGS_EMAILS_GET_LIST_FAILURE:
        case actionTypes.SETTINGS_EMAILS_GET_COUNT_FAILURE:
        case actionTypes.SETTINGS_EMAIL_GET_DETAILS_FAILURE:
        case actionTypes.SETTINGS_EMAIL_PUT_FAILURE:
        case actionTypes.SETTINGS_EMAIL_DELETE_FAILURE:
        case actionTypes.SETTINGS_EMAIL_CREATE_FAILURE:
        case actionTypes.SETTINGS_EMAIL_NOTIFY_FAILURE:
        case actionTypes.SETTINGS_EMAIL_NOTIFY_UPDATE_FAILURE:
        case actionTypes.SETTINGS_EMAIL_NOTIFY_CREATE_FAILURE:
        case actionTypes.SETTINGS_UPDATE_MODULE_FAILURE:
        case actionTypes.SETTINGS_ORDERS_PUT_FAILURE:
        case actionTypes.SETTINGS_CITIES_MONITOR_PUT_FAILURE:
        case actionTypes.SETTINGS_REPORTS_PUT_FAILURE:
            return setLoadingFalseError(state, action);

        //LOADING FALSE NO ERROR
        case actionTypes.SETTINGS_EMAIL_DELETE_SUCCESS:
        case actionTypes.SETTINGS_CITIES_MONITOR_PUT_SUCCESS:
            return setLoadingFalseNoError(state, action);

        //CRUD MailBox
        case actionTypes.SETTINGS_EMAIL_GET_DETAILS_SUCCESS:
        case actionTypes.SETTINGS_EMAIL_CREATE_SUCCESS:
        case actionTypes.SETTINGS_EMAIL_PUT_SUCCESS:
            return mailBoxSuccess(state, action);

        case actionTypes.SETTINGS_UPDATE_MODULE_SUCCESS:
            return setUpdateModuleSettings(state, action);

        //LIST
        case actionTypes.SETTINGS_EMAILS_GET_LIST_SUCCESS:
            return setMailBoxes(state, action);

        case actionTypes.SETTINGS_EMAIL_GET_DETAILS_NEW:
            return setMailBoxDetailsNew(state, action);

        //CRUD EmailToNotify
        case actionTypes.SETTINGS_EMAIL_NOTIFY_SUCCESS:
            return setEmailToNotify(state, action);

        case actionTypes.SETTINGS_EMAIL_NOTIFY_UPDATE_SUCCESS:
            return setUpdateEmailToNotify(state, action);

        case actionTypes.SETTINGS_EMAIL_NOTIFY_CREATE_SUCCESS:
            return setCreateEmailToNotify(state, action);

        //OrdersSettings
        case actionTypes.SETTINGS_ORDERS_PUT_SUCCESS:
            return setUpdateOrdersSettings(state, action);

        //Report
        case actionTypes.SETTINGS_REPORTS_PUT_SUCCESS:
            return setUpdateReport(state, action);

        case actionTypes.ENABLED_CITIES_GET_LIST_REQUEST:
            return setEnabledCitiesResquest(state, action);

        case actionTypes.ENABLED_CITIES_GET_LIST_SUCCESS:
        case actionTypes.ENABLED_CITIES_GET_LIST_FAILURE:
            return setEnabledCities(state, action);

        case actionTypes.ENABLED_NACIONAL_CITIES_GET_LIST_REQUEST:
            return setEnabledNacionalCitiesResquest(state, action);

        case actionTypes.ENABLED_NACIONAL_CITIES_GET_LIST_SUCCESS:
        case actionTypes.ENABLED_NACIONAL_CITIES_GET_LIST_FAILURE:
            return setEnabledNacionalCities(state, action);

        case actionTypes.SETTINGS_SET_NACIONAL_CITIES_SUCCESS:
            return setCheckedNacionalCities(state, action);

        case actionTypes.SETTINGS_SET_CITIES_SUCCESS:
            return setCheckedCities(state, action);

        case actionTypes.NOTIFICATIONS_GET_SUCCESS:
            return setNotifications(state, action);

        case actionTypes.NOTIFICATIONS_PUT_SUCCESS:
            return setNewNotifications(state, action);

        default:
            return state;
    }
};

export default reducer;
