import React from 'react';
import { Badge } from 'reactstrap';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import { Operators } from '../../../models/FilterBuilder';
import {
    filterPropertyTypes,
    sources,
    statusDescription,
} from '../../../utils/enums';
import { sourceComponent } from '../../DocumentListPage/components/Columns';
import messages from '../OtherListPage.intl';

export const columnsOther = (documents, intl, hasFull = false) => {
    let columns = [];

    if (hasFull) {
        columns.push({
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.titleContractor, {
                            entity: '',
                        })}
                    </span>
                </div>
            ),
            accessor: 'contractor',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                var id = row.index + 1;
                return (
                    <div className='customer-container'>
                        <span className={`color-number color-number-${id}`}>
                            {row.value && row.value.substr(0, 1).toUpperCase()}
                        </span>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            maxWidth:
                250 +
                calculateColumnWidth(
                    documents,
                    'contractor',
                    intl.formatMessage(messages.titleContractor, {
                        entity: '',
                    }),
                ),
            minWidth:
                50 +
                calculateColumnWidth(
                    documents,
                    'contractor',
                    intl.formatMessage(messages.titleContractor, {
                        entity: '',
                    }),
                ),
        });
    }

    columns.push(
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.titleSource)}</span>
                </div>
            ),
            accessor: 'source',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return sourceComponent(row);
            },
            maxWidth: 75,
            minWidth: 75,
        },

        {
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.categoryType, {
                            entity: '',
                        })}
                    </span>
                </div>
            ),
            accessor: 'categoryType',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                let categoryValue = row.value == 0 ? '' : row.value;
                if (!!row.original.subCategory) {
                    categoryValue = `${categoryValue}(${
                        row.original.subCategory
                    })`;
                }
                return (
                    <div className='customer-container'>
                        <div className=''>{categoryValue}</div>
                    </div>
                );
            },
            minWidth:
                70 +
                calculateColumnWidth(
                    documents,
                    'categoryType',
                    intl.formatMessage(messages.categoryType, {
                        entity: '',
                    }),
                ),
            maxWidth:
                100 +
                calculateColumnWidth(
                    documents,
                    'categoryType',
                    intl.formatMessage(messages.categoryType, {
                        entity: '',
                    }),
                ),
        },

        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.titleInvoice)}</span>
                </div>
            ),
            accessor: 'documentNumber',
            style: {
                fontSize: 'small',
            },
            sortMethod: (a, b) => {
                const A = Number(a) || Number.MAX_SAFE_INTEGER;
                const B = Number(b) || Number.MAX_SAFE_INTEGER;
                return A - B;
            },
            Cell: row => <p>{row.value}</p>,
            minWidth:
                30 +
                calculateColumnWidth(
                    documents,
                    'documentNumber',
                    intl.formatMessage(messages.titleInvoice),
                ),
            maxWidth:
                100 +
                calculateColumnWidth(
                    documents,
                    'documentNumber',
                    intl.formatMessage(messages.titleInvoice),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.titleSupplier, {
                            entity: '',
                        })}
                    </span>
                </div>
            ),
            accessor: 'supplierName',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            minWidth:
                60 +
                calculateColumnWidth(
                    documents,
                    'supplierName',
                    intl.formatMessage(messages.titleSupplier, {
                        entity: '',
                    }),
                ),
            maxWidth:
                250 +
                calculateColumnWidth(
                    documents,
                    'supplierName',
                    intl.formatMessage(messages.titleSupplier, {
                        entity: '',
                    }),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.titleTaker, {
                            entity: '',
                        })}
                    </span>
                </div>
            ),
            accessor: 'contractorName',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            maxWidth:
                300 +
                calculateColumnWidth(
                    documents,
                    'contractorName',
                    intl.formatMessage(messages.titleTaker, {
                        entity: '',
                    }),
                ),
            minWidth:
                40 +
                calculateColumnWidth(
                    documents,
                    'contractorName',
                    intl.formatMessage(messages.titleTaker, {
                        entity: '',
                    }),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.createdAt)}</span>
                </div>
            ),
            accessor: 'createdAt',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                const date = new Date(row.value).toLocaleString(
                    navigator.language,
                );
                return (
                    <div className='customer-container'>
                        <div className=''>{date}</div>
                    </div>
                );
            },
            maxWidth:
                60 +
                calculateColumnWidth(
                    documents,
                    'createdAt',
                    intl.formatMessage(messages.createdAt),
                ),
        },

        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.status)}</span>
                </div>
            ),
            id: 'status',
            style: {
                fontSize: 'small',
            },
            sortMethod: (a, b) => {
                const preference = {
                    'status-invoice status-success': 1,
                    'status-invoice status-pending': 2,
                    'status-invoice status-error': 3,
                };

                const A =
                    preference[a.props.className.toLowerCase()] ||
                    Number.MAX_SAFE_INTEGER;
                const B =
                    preference[b.props.className.toLowerCase()] ||
                    Number.MAX_SAFE_INTEGER;

                return A - B;
            },
            accessor: d => {
                let color = '';

                if (d.status.toLowerCase() === 'pending') {
                    color += 'warning';
                } else if (d.status.toLowerCase() === 'success') {
                    color += 'success';
                } else if (d.status.toLowerCase() === 'error') {
                    color += 'danger';
                }
                return (
                    <Badge
                        style={{ fontSize: '0.8rem', color: '#ffffff' }}
                        color={color}
                        pill
                    >
                        {d.statusDescription}
                    </Badge>
                );
            },
            minWidth:
                130 +
                calculateColumnWidth(
                    documents,
                    'statusDescription',
                    intl.formatMessage(messages.status),
                ),
            maxWidth:
                150 +
                calculateColumnWidth(
                    documents,
                    'statusDescription',
                    intl.formatMessage(messages.status),
                ),
        },
    );

    return columns;
};

export const otherProperties = (
    intl,
    mapCategoriesArray,
    categories,
    hasFull = false,
) => {
    const filtersProperties = [];

    filtersProperties.push(
        {
            key: 'contractorName',
            value: intl.formatMessage(messages.titleTaker, { entity: '' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'contractorCNPJ',
            value: intl.formatMessage(messages.titleTaker, { entity: 'CNPJ' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
            maskValue: value => value.replace(/[^\d]+/g, ''),
        },
        {
            key: 'supplierName',
            value: intl.formatMessage(messages.titleProvider, { entity: '' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'supplierCNPJ',
            value: intl.formatMessage(messages.titleProvider, {
                entity: 'CNPJ',
            }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
            maskValue: value => value.replace(/[^\d]+/g, ''),
        },
        {
            key: 'documentNumber',
            value: intl.formatMessage(messages.invoiceNumberFilter),
            type: filterPropertyTypes.NUMBER,
            lg: 4,
        },
        {
            key: 'city',
            value: intl.formatMessage(messages.city),
            type: filterPropertyTypes.TEXT,
            lg: 4,
        },
    );

    if (hasFull) {
        filtersProperties.push({
            key: 'contractor',
            value: intl.formatMessage(messages.titleOnlyContractor, {
                entity: '',
            }),
            type: filterPropertyTypes.TEXT,
            lg: 4,
        });
    }

    filtersProperties.push(
        {
            key: 'categoryType',
            value: intl.formatMessage(messages.categoryType),
            type: filterPropertyTypes.ENUM,
            options: mapCategoriesArray(categories || []),
            lg: 4,
        },
        {
            key: 'subCategory',
            value: intl.formatMessage(messages.subCategory),
            type: filterPropertyTypes.TEXT,
            lg: 4,
        },
        {
            key: 'source',
            value: intl.formatMessage(messages.titleSource),
            type: filterPropertyTypes.ENUM,
            options: [
                {
                    key: sources.portal,
                    value: 'Portal',
                },
                {
                    key: sources.email,
                    value: 'Email',
                },
                {
                    key: sources.webService,
                    value: 'Integração',
                },
            ],
            lg: 4,
            operator: Operators.like,
        },
        {
            key: 'statusDescription',
            value: 'Status',
            type: filterPropertyTypes.ENUM,
            options: Object.values(statusDescription).map(s => {
                return {
                    key: s,
                    value: s,
                };
            }),
            lg: 4,
            operator: Operators.like,
        },
        {
            key: 'createdAt',
            value: intl.formatMessage(messages.integratedAtDocument),
            type: filterPropertyTypes.DATE,
        },
    );

    return filtersProperties;
};