import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import messages from './OrderDocumentLinkingPage.intl';

import { calculateColumnWidth } from '../../components/EvtTable/EvtTable';
import Checkbox from '../../components/CheckBox/CheckBox';

export const columnsOrdersToAssociate = (
    intl,
    ordersToAssociated,
    handleSelectOrderItem,
    selectedOrders,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.select)}</span>
            </div>
        ),
        accessor: 'checkbox',
        Cell: row => {
            const getIsChecked = selectedOrders.find(
                order => order === row.original?.orderItemId,
            )
            return (
                <div
                    className='customer-container'
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        id={'checkbox'}
                        checked={!!getIsChecked}
                        onChange={() => handleSelectOrderItem(row.original)}
                    />
                </div>
            );
        },

        minWidth: 110,
        maxWidth:
            110 +
            calculateColumnWidth(
                [],
                'checkbox',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.titleOrderCode)}</span>
            </div>
        ),
        accessor: 'orderCode',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                ordersToAssociated,
                'itemOrder',
                intl.formatMessage(messages.titleOrderCode),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                ordersToAssociated,
                'orderCode',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrder)}</span>
            </div>
        ),
        accessor: 'itemOrder',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                ordersToAssociated,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                ordersToAssociated,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.orderMeasure)}</span>
            </div>
        ),
        accessor: 'orderMeasure',
        style: {
            fontSize: 'small',
        },
        minWidth:
            50 +
            calculateColumnWidth(
                ordersToAssociated,
                'orderMeasure',
                intl.formatMessage(messages.orderMeasure),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrderDescription)}</span>
            </div>
        ),
        accessor: 'description',
        style: {
            fontSize: 'small',
        },
        minWidth:
            85 +
            calculateColumnWidth(
                ordersToAssociated,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
        maxWidth:
            85 +
            calculateColumnWidth(
                ordersToAssociated,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalGrossValue)}</span>
            </div>
        ),
        accessor: 'totalGrossValue',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                ordersToAssociated,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                ordersToAssociated,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.shippingDate)}</span>
            </div>
        ),
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            const [shippingDate, hour] =
                String(date) !== 'Invalid Date'
                    ? date?.replace(',', '').split(' ')
                    : '';
            return (
                <div className='customer-container'>
                    <div className=''>{shippingDate}</div>
                </div>
            );
        },
        accessor: 'shippingDate',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                ordersToAssociated,
                'shippingDate',
                intl.formatMessage(messages.shippingDate),
            ),
    },
];

export const columnsAssociatedOrders = (
    intl,
    ordersAssociated,
    hasAllowOrderDocumentClaim,
    handleToggleModalDelete,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.titleOrderCode)}</span>
            </div>
        ),
        accessor: 'orderCode',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                ordersAssociated,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                ordersAssociated,
                'orderCode',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrder)}</span>
            </div>
        ),
        accessor: 'itemOrder',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                ordersAssociated,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                ordersAssociated,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.orderMeasure)}</span>
            </div>
        ),
        accessor: 'orderMeasure',
        style: {
            fontSize: 'small',
        },
        minWidth:
            50 +
            calculateColumnWidth(
                ordersAssociated,
                'orderMeasure',
                intl.formatMessage(messages.orderMeasure),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrderDescription)}</span>
            </div>
        ),
        accessor: 'description',
        style: {
            fontSize: 'small',
        },
        minWidth:
            85 +
            calculateColumnWidth(
                ordersAssociated,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
        maxWidth:
            85 +
            calculateColumnWidth(
                ordersAssociated,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalGrossValue)}</span>
            </div>
        ),
        accessor: 'totalGrossValue',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                ordersAssociated,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                ordersAssociated,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.deleteButton)}</span>
            </div>
        ),
        accessor: 'delete',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <button
                        style={{ width: '100px' }}
                        className={
                            !hasAllowOrderDocumentClaim
                                ? 'btn-disable'
                                : 'btn-delete'
                        }
                        onClick={() => {
                            handleToggleModalDelete(row.original);
                        }}
                        disabled={!hasAllowOrderDocumentClaim}
                    >
                        <FontAwesomeIcon icon='trash-alt' />
                    </button>
                </div>
            );
        },
        maxWidth: 100,
    },
];

export const columnsNfeOrders = (
    intl,
    nfeOrders,
    hasAllowDelete,
    handleToggleModalDelete,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.nfeItemNumber)}</span>
            </div>
        ),
        accessor: 'nfeItemNumber',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                nfeOrders,
                'nfeItemNumber',
                intl.formatMessage(messages.nfeItemNumber),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                nfeOrders,
                'nfeItemNumber',
                intl.formatMessage(messages.nfeItemNumber),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.titleOrderCode)}</span>
            </div>
        ),
        accessor: 'orderCode',
        style: {
            fontSize: 'small',
        },

        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'orderCode',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.description)}</span>
            </div>
        ),
        accessor: 'orderName',
        style: {
            fontSize: 'small',
        },

        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'orderName',
                intl.formatMessage(messages.description),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrder)}</span>
            </div>
        ),
        accessor: 'itemOrder',
        style: {
            fontSize: 'small',
        },
        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.orderMeasure)}</span>
            </div>
        ),
        accessor: 'orderMeasure',
        style: {
            fontSize: 'small',
        },
        minWidth:
            50 +
            calculateColumnWidth(
                nfeOrders,
                'orderMeasure',
                intl.formatMessage(messages.orderMeasure),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrderDescription)}</span>
            </div>
        ),
        accessor: 'description',
        style: {
            fontSize: 'small',
        },
        minWidth:
            85 +
            calculateColumnWidth(
                nfeOrders,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
        maxWidth:
            90 +
            calculateColumnWidth(
                nfeOrders,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalGrossValue)}</span>
            </div>
        ),
        accessor: 'totalGrossValue',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                nfeOrders,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.deleteButton)}</span>
            </div>
        ),
        accessor: 'delete',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <button
                        style={{ width: '100px' }}
                        className={
                            !hasAllowDelete ? 'btn-disable' : 'btn-delete'
                        }
                        onClick={() => {
                            handleToggleModalDelete(row.original);
                        }}
                        disabled={!hasAllowDelete}
                    >
                        <FontAwesomeIcon icon='trash-alt' />
                    </button>
                </div>
            );
        },
        maxWidth: 100,
    },
];

export const columnsNfeItems = (
    intl,
    nfeItems,
    selectedNfeItem,
    handleSelectNfeItem,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.select)}</span>
            </div>
        ),
        accessor: 'checkbox',
        Cell: row => {
            return (
                <div
                    className='customer-container'
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        id={'checkbox'}
                        checked={selectedNfeItem?.id === row.original?.id}
                        onChange={() => handleSelectNfeItem(row.original)}
                    />
                </div>
            );
        },

        minWidth: 110,
        maxWidth:
            110 +
            calculateColumnWidth(
                [],
                'checkbox',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemNfeNumber)}</span>
            </div>
        ),
        accessor: 'nItemNumber',
        style: {
            fontSize: 'small',
        },
        minWidth: 60,
        minWidth: 80,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemNfeDescription)}</span>
            </div>
        ),
        accessor: 'prod.xProd',
        style: {
            fontSize: 'small',
        },
        minWidth:
            85 +
            calculateColumnWidth(
                nfeItems,
                'prod.xProd',
                intl.formatMessage(messages.itemNfeDescription),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ncm)}</span>
            </div>
        ),
        accessor: 'prod.ncm',
        style: {
            fontSize: 'small',
        },
        minWidth:
            50 +
            calculateColumnWidth(
                nfeItems,
                'prod.ncm',
                intl.formatMessage(messages.ncm),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.quantity)}</span>
            </div>
        ),
        accessor: 'prod.qCom',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const quantityValue = String(row.value);
            const value = quantityValue.includes('.')
                ? quantityValue.replace('.', ',')
                : quantityValue;
            return (
                <div className='customer-container'>
                    <div className=''>{`${value || 0}${
                        row.original.prod.uCom
                    }`}</div>
                </div>
            );
        },
        minWidth:
            65 +
            calculateColumnWidth(
                nfeItems,
                'prod.qCom',
                intl.formatMessage(messages.quantity),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.unitaryNetValue)}</span>
            </div>
        ),
        accessor: 'prod.vUnCom',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        minWidth:
            60 +
            calculateColumnWidth(
                nfeItems,
                'prod.vUnCom',
                intl.formatMessage(messages.unitaryNetValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalItemValue)}</span>
            </div>
        ),
        accessor: 'prod.vProd',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        minWidth:
            70 +
            calculateColumnWidth(
                nfeItems,
                'prod.vProd',
                intl.formatMessage(messages.totalItemValue),
            ),
    },
];
