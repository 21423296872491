import React, { useState, useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import CurrencyInput from 'react-currency-input-field';
import { Row, Col, FormGroup } from 'reactstrap';

import messages from './OrderItemsDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';

function OrderItemsDetailsForm(props) {
    const context = useContext(AuthContext);

    const { intl, isNew, orderItem } = props;
    const delivery = orderItem?.delivery;

    const canCreateOrderItem = context.hasClaim('order-create') && isNew;
    const canUpdateOrderItem = context.hasClaim('order-update') && !isNew;
    const canDeleteOrderItem = context.hasClaim('order-delete') && !isNew;
    const isReadOnly = !(canCreateOrderItem || canUpdateOrderItem);

    const [unitaryNetValue, setUnitaryNetValue] = useState('');
    const [unitaryGrossValue, setUnitaryGrossValue] = useState('');
    const [totalGrossValue, setTotalGrossValue] = useState('');
    const [totalNetValue, setTotalNetValue] = useState('');

    const [icmsValue, setIcmsValue] = useState('');
    const [ipiValue, setIpiValue] = useState('');
    const [issValue, setIssValue] = useState('');
    const [aliquotICMS, setAliquotICMS] = useState('');
    const [aliquotIPI, setAliquotIPI] = useState('');
    const [aliquotISS, setAliquotISS] = useState('');
    const [shippingDate, setShippingDate] = useState('');

    const [quantity, setQuantity] = useState('');

    const formatValueSubmit = (state, value) => {
        const formattedSubmit = !!state ? state.replace(',', '.') : value;
        return formattedSubmit;
    };

    useEffect(() => {
        const [newShippingDate, hour] = (orderItem?.shippingDate &&
            new Date(orderItem?.shippingDate).toISOString().split('T')) || [
            null,
            null,
        ];
        setShippingDate(newShippingDate);
    }, [orderItem]);

    return !!orderItem || isNew ? (
        <AvForm
            onValidSubmit={(event, values) => {
                props.handleSubmit(event, {
                    ...values,
                    quantity: formatValueSubmit(quantity, orderItem?.quantity),
                    unitaryNetValue: formatValueSubmit(
                        unitaryNetValue,
                        orderItem?.unitaryNetValue,
                    ),
                    unitaryGrossValue: formatValueSubmit(
                        unitaryGrossValue,
                        orderItem?.unitaryGrossValue,
                    ),
                    totalNetValue: formatValueSubmit(
                        totalNetValue,
                        orderItem?.totalNetValue,
                    ),
                    totalGrossValue: formatValueSubmit(
                        totalGrossValue,
                        orderItem?.totalGrossValue,
                    ),
                    aliquotICMS: formatValueSubmit(
                        aliquotICMS,
                        orderItem?.aliquotICMS,
                    ),
                    aliquotIPI: formatValueSubmit(
                        aliquotIPI,
                        orderItem?.aliquotIPI,
                    ),
                    aliquotISS: formatValueSubmit(
                        aliquotISS,
                        orderItem?.aliquotISS,
                    ),
                    icmsValue: formatValueSubmit(
                        icmsValue,
                        orderItem?.icmsValue,
                    ),
                    ipiValue: formatValueSubmit(ipiValue, orderItem?.ipiValue),
                    issValue: formatValueSubmit(issValue, orderItem?.issValue),
                });
            }}
        >
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.type)}
                            type='select'
                            name='type'
                            id='type'
                            value={orderItem?.type || ''}
                            disabled={isReadOnly}
                        >
                            <option />
                            <option value='1'>Material</option>
                            <option value='2'>Serviços</option>
                            <option value='3'>Frete</option>
                            <option value='99'>Outros</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.itemOrder)}
                            type='text'
                            name='itemOrder'
                            id='itemOrder'
                            value={orderItem?.itemOrder || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.measureItem)}
                            type='text'
                            name='measureItem'
                            id='measureItem'
                            value={orderItem?.measureItem || ''}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.description)}
                            type='text'
                            name='description'
                            id='description'
                            value={orderItem?.description || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(messages.isActive)}
                            type='checkbox'
                            name='isActive'
                            id='isActive'
                            value={orderItem?.isActive}
                            checked={orderItem?.isActive || true}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.shippingDate)}
                            type='date'
                            name='shippingDate'
                            id='shippingDate'
                            value={orderItem?.shippingDate ? shippingDate : ''}
                            disabled={isReadOnly}
                            validate={{ datetime: true }}
                            errorMessage=''
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cnpjTaker)}
                            type='text'
                            name='cnpjTaker'
                            id='cnpjTaker'
                            value={orderItem?.cnpjTaker || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.quantity)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.quantity}
                            allowNegativeValue={false}
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalsLimit={3}
                            decimalScale={3}
                            id='quantity'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setQuantity(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.measureUnity)}
                            type='text'
                            name='measureUnity'
                            id='measureUnity'
                            value={orderItem?.measureUnity || ''}
                            disabled={isReadOnly}
                            required
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.ncm)}
                            type='text'
                            name='ncm'
                            id='ncm'
                            value={orderItem?.ncm || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
            <Col md={3}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.unitaryGrossValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.unitaryGrossValue}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='unitaryGrossValue'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setUnitaryGrossValue(value == undefined ? '0,00' : value)}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.unitaryNetValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.unitaryNetValue}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='unitaryNetValue'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => {setUnitaryNetValue(value == undefined ? '0,00' : value)}} 
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.totalNetValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.totalNetValue}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='totalNetValue'
                            disabled={isReadOnly}
                            onValueChange={value => setTotalNetValue(value == undefined ? '0,00' : value)}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.totalGrossValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.totalGrossValue}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='totalGrossValue'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setTotalGrossValue(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.aliquotICMS)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.aliquotICMS}
                            allowNegativeValue={false}
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='aliquotICMS'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setAliquotICMS(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.aliquotIPI)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.aliquotIPI}
                            allowNegativeValue={false}
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='aliquotIPI'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setAliquotIPI(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.aliquotISS)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.aliquotISS}
                            allowNegativeValue={false}
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='aliquotISS'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setAliquotISS(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.icmsValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.icmsValue}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='icmsValue'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setIcmsValue(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.ipiValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.ipiValue}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='ipiValue'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setIpiValue(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.issValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={orderItem?.issValue}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalLimit={2}
                            decimalScale={2}
                            id='issValue'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setIssValue(value == undefined ? '0,00' : value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {/* Delivery */}
            <h5 className='title-area-form'>
                {intl.formatMessage(messages.deliveryInfo)}
            </h5>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.name)}
                            type='text'
                            name='delivery.name'
                            id='name'
                            value={delivery?.name || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cnpjDestiny)}
                            type='text'
                            name='delivery.cnpjDestiny'
                            id='cnpjDestiny'
                            value={delivery?.cnpjDestiny || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.address)}
                            type='text'
                            name='delivery.address'
                            id='address'
                            value={delivery?.address || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.number)}
                            type='text'
                            name='delivery.number'
                            id='number'
                            value={delivery?.number || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cep)}
                            type='text'
                            name='delivery.cep'
                            id='cep'
                            value={delivery?.cep || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.city)}
                            type='text'
                            name='delivery.city'
                            id='city'
                            value={delivery?.city || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.state)}
                            type='text'
                            name='delivery.state'
                            id='state'
                            value={delivery?.state || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
            </Row>
            {/* Buttons */}
            <Row className='mt-3'>
                <Col xs={12}>
                    {!isReadOnly && (
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    )}

                    {canDeleteOrderItem && (
                        <button
                            type='button'
                            className='btn-delete'
                            onClick={props.handleToggleModalDelete}
                        >
                            {intl.formatMessage(messages.deleteButton)}
                        </button>
                    )}
                </Col>
            </Row>
        </AvForm>
    ) : (
        <div />
    );
}

export default injectIntl(OrderItemsDetailsForm);
