import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    name: {
        id: 'suppliers.name',
        defaultMessage: 'Nome',
    },

    fieldsList: {
        id: 'documentFields.fieldsList',
        defaultMessage: 'Lista de Campos',
    },

    contractor: {
        id: 'documentFields.contractor',
        defaultMessage: 'Contratante',
    },
    documentType: {
        id: 'documentFields.documentType',
        defaultMessage: 'Tipo de Documento',
    },

    categoryType: {
        id: 'documentFields.categoryType',
        defaultMessage: 'Categoria do Documento',
    },

    isSearchable: {
        id: 'documentFields.isSearchable',
        defaultMessage: 'Pesquisável',
    },
    includeDocument: {
        id: 'documentFields.includeDocument',
        defaultMessage: 'Inclui em documento',
    },
    active: {
        id: 'documentFields.active',
        defaultMessage: 'Ativo',
    },

    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },

    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },

    generalTitle: {
        id: 'invoices.generalTitle',
        defaultMessage: 'Cabeçalho',
    },

    providerTitle: {
        id: 'invoices.providerTitle',
        defaultMessage: 'Prestador de Serviços',
    },

    borrowerTitle: {
        id: 'invoices.borrowerTitle',
        defaultMessage: 'Tomador de Serviços',
    },

    middlewareTitle: {
        id: 'invoices.middlewareTitle',
        defaultMessage: 'Intermediário de Serviços',
    },

    servicesTitle: {
        id: 'invoices.servicesTitle',
        defaultMessage: 'Serviços',
    },

    taxesTitle: {
        id: 'invoices.taxesTitle',
        defaultMessage: 'Valores/Impostos',
    },

    othersTitle: {
        id: 'invoices.othersTitle',
        defaultMessage: 'Outros',
    },
    partsTitle: {
        id: 'invoices.partsTitle',
        defaultMessage: 'Partes envolvidas',
    },
    documentValues: {
        id: 'invoices.documentValues',
        defaultMessage: 'Valores do documento',
    },
    municipalTaxes: {
        id: 'invoices.municipalTaxes',
        defaultMessage: 'Imposto municipal',
    },
    federalTaxes: {
        id: 'invoices.federalTaxes',
        defaultMessage: 'Imposto federal',
    },
    texts: {
        id: 'invoices.texts',
        defaultMessage: 'Texto do documento',
    },
});

export default messages;
