import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, FormGroup } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

import messages from './FieldDetailsForm.intl';

import EvtSelect from '../EvtSelect/EvtSelect';
import MapFieldTypeV2 from './components/MapFieldTypeV2';
import MapManualInfo from './components/MapManualInfo';

const FieldDetailsForm = props => {
    const {
        intl,
        selectedMap,
        mapFieldOcr,
        handleProcessFieldOcr,
        mapVersion,
    } = props;
    const { documentType, categoryType } = selectedMap;

    const [field, setField] = useState(props.field);

    const { constraints, layout } = field || {};
    const { mapFieldType, mapFieldTypeV2 } = props.field;

    function handleSelectedOption(data) {
        const dataField = data?.mapItem.field || props.field;

        setField(dataField);
    }

    return (
        <section style={{ textAlign: 'left' }}>
            {documentType !== 'OTHER' ? (
                <>
                    <Row>
                        <Col md={6}>
                            <EvtSelect
                                name='name'
                                label={intl.formatMessage(messages.name)}
                                url={
                                    '/Documents/DocumentFields?filter[includeDocument]=like:false' +
                                    `&filter[documentType]=eq:${documentType}` +
                                    `&filter[documentType]=eq:${categoryType ||
                                        ''}`
                                }
                                labelKey='name'
                                labelBrackets='mapItem.field.key'
                                valueKey='name'
                                handleSelectedOptionChange={props => {
                                    handleSelectedOption(props.data);
                                }}
                                key={field && field.name}
                                async
                                required
                                option={
                                    field && {
                                        selectedValueOption:
                                            field && field.name,
                                        selectedLabelOption:
                                            field &&
                                            field.name + ` [${field.key}]`,
                                    }
                                }
                            />
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.description,
                                    )}
                                    type='text'
                                    name='description'
                                    id='description'
                                    value={field?.description}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label='Key'
                                    type='text'
                                    name='key'
                                    id='key'
                                    value={field?.key}
                                    errorMessage=' '
                                    disabled={true}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label='keyXml'
                                    type='text'
                                    name='keyXml'
                                    id='keyXml'
                                    value={field?.keyXml || ''}
                                    disabled={true}
                                    errorMessage=' '
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    onChange={props.handleFieldTypeChange}
                                    label={intl.formatMessage(messages.type)}
                                    type='select'
                                    name='type'
                                    id='type'
                                    value={field?.type}
                                    errorMessage=' '
                                    required
                                >
                                    <option />
                                    <option value='Number'>Number</option>
                                    <option value='Money'>Money</option>
                                    <option value='Text'>Text</option>
                                    <option value='Date'>Date</option>
                                    <option value='Time'>Time</option>
                                    <option value='List'>List</option>
                                    <option value='textarea'>TextArea</option>
                                    <option value='boolean'>Boolean</option>
                                </AvField>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label='Form Group'
                                    type='select'
                                    name='formGroup'
                                    id='formGroup'
                                    value={field?.formGroup}
                                    errorMessage=' '
                                    required
                                >
                                    <option />
                                    <option value='1'>
                                        {intl.formatMessage(
                                            messages.generalTitle,
                                        )}
                                    </option>
                                    <option value='2'>
                                        {intl.formatMessage(
                                            messages.providerTitle,
                                        )}
                                    </option>
                                    <option value='3'>
                                        {intl.formatMessage(
                                            messages.borrowerTitle,
                                        )}
                                    </option>
                                    <option value='4'>
                                        {intl.formatMessage(
                                            messages.middlewareTitle,
                                        )}
                                    </option>
                                    <option value='5'>
                                        {intl.formatMessage(
                                            messages.servicesTitle,
                                        )}
                                    </option>
                                    <option value='6'>
                                        {intl.formatMessage(
                                            messages.taxesTitle,
                                        )}
                                    </option>
                                    <option value='7'>
                                        {intl.formatMessage(
                                            messages.othersTitle,
                                        )}
                                    </option>
                                <option value='8'>
                                    {intl.formatMessage(messages.partsTitle)}
                                </option>
                                <option value='9'>
                                    {intl.formatMessage(messages.documentValues)}
                                </option>
                                <option value='10'>
                                    {intl.formatMessage(messages.municipalTaxes)}
                                </option>
                                <option value='11'>
                                    {intl.formatMessage(messages.federalTaxes)}
                                </option>
                                <option value='12'>
                                    {intl.formatMessage(messages.texts)}
                                </option>
                                </AvField>
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            ) : (
                <MapManualInfo
                    field={field}
                    mapVersion={mapVersion}
                    selectedMap={selectedMap}
                    handleSelectedOption={handleSelectedOption}
                />
            )}
            {props.mapVersion === 0 ? (
                <>
                    <h5 className='title-area-form'>Map Type V1</h5>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.mapType)}
                                    type='select'
                                    name='mapFieldType.type'
                                    id='type'
                                    value={mapFieldType?.type}
                                    errorMessage=' '
                                >
                                    <option value='1'>
                                        {intl.formatMessage(messages.standard)}
                                    </option>
                                    <option value='2'>
                                        {intl.formatMessage(messages.fitBox)}
                                    </option>
                                    <option value='3'>
                                        {intl.formatMessage(
                                            messages.fitBoxWithRef,
                                        )}
                                    </option>
                                    <option value='4'>
                                        {intl.formatMessage(
                                            messages.dynamicBox,
                                        )}
                                    </option>
                                </AvField>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.startsWith,
                                    )}
                                    type='text'
                                    name='mapFieldType.startsWith'
                                    id='startsWith'
                                    value={mapFieldType?.startsWith}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.endsWith,
                                    )}
                                    type='text'
                                    name='mapFieldType.endsWith'
                                    id='endsWith'
                                    value={mapFieldType?.endsWith}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.order)}
                                    type='number'
                                    name='mapFieldType.order'
                                    id='order'
                                    value={mapFieldType?.order}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.mapKeyReference,
                                    )}
                                    type='text'
                                    name='mapFieldType.mapKeyReference'
                                    id='mapKeyReference'
                                    value={mapFieldType?.mapKeyReference}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup check className='required-field'>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.removeFirstLine,
                                    )}
                                    type='checkbox'
                                    name='mapFieldType.removeFirstLine'
                                    id='removeFirstLine'
                                    value={mapFieldType?.removeFirstLine}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup check className='required-field'>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.fitXWidth,
                                    )}
                                    type='checkbox'
                                    name='mapFieldType.fitXWidth'
                                    id='fitXWidth'
                                    value={mapFieldType?.fitXWidth}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup check className='required-field'>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.hideMapInDocument,
                                    )}
                                    type='checkbox'
                                    name='mapFieldType.hideMapInDocument'
                                    id='hideMapInDocument'
                                    value={mapFieldType?.hideMapInDocument}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            ) : (
                <MapFieldTypeV2
                    mapFieldTypeV2={mapFieldTypeV2}
                    mapFieldOcr={mapFieldOcr}
                    handleProcessFieldOcr={handleProcessFieldOcr}
                    hasIsRepeatingDocument={
                        selectedMap?.mapFilterDelimeter?.isRepeatingMap
                    }
                />
            )}
            <h5 className='title-area-form'>Constraints</h5>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label='Pattern'
                            type='text'
                            name='constraints.pattern'
                            id='pattern'
                            value={constraints?.pattern || ''}
                        />
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label='Min Length'
                            type='number'
                            name='constraints.minLength'
                            id='minLength'
                            value={constraints?.minLength || ''}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label='Max Length'
                            type='number'
                            name='constraints.maxLength'
                            id='maxLength'
                            value={constraints?.maxLength || ''}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label='Rows'
                            type='number'
                            name='constraints.rows'
                            id='rows'
                            value={constraints?.rows || ''}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(messages.required)}
                            type='checkbox'
                            name='constraints.required'
                            id='required'
                            value={constraints?.required || ''}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <div>
                <h5 className='title-area-form'>Layout</h5>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <AvField
                                label='Classes'
                                type='text'
                                name='layout.classes'
                                id='classes'
                                value={layout?.classes || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Xs'
                                type='number'
                                name='layout.xs'
                                id='xs'
                                value={layout?.xs || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetXs'
                                type='number'
                                name='layout.offsetXs'
                                id='offsetXs'
                                value={layout?.offsetXs || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Sm'
                                type='number'
                                name='layout.sm'
                                id='sm'
                                value={layout?.sm || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetSm'
                                type='number'
                                name='layout.offsetSm'
                                id='offsetSm'
                                value={layout?.offsetSm || ''}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Md'
                                type='number'
                                name='layout.md'
                                id='md'
                                value={layout?.md || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetMd'
                                type='number'
                                name='layout.offsetMd'
                                id='offsetMd'
                                value={layout?.offsetMd || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Lg'
                                type='number'
                                name='layout.lg'
                                id='lg'
                                value={layout?.lg || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetLg'
                                type='number'
                                name='layout.offsetLg'
                                id='offsetLg'
                                value={layout?.offsetLg || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Xl'
                                type='number'
                                name='layout.xl'
                                id='xl'
                                value={layout?.xl || ''}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetXl'
                                type='number'
                                name='layout.offsetXl'
                                id='offsetXl'
                                value={layout?.offsetXl || ''}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col xs={12}>
                    <button
                        type='submit'
                        className='btn-submit'
                        disabled={props.loading}
                    >
                        {intl.formatMessage(messages.submitButton)}
                    </button>
                    <button type='button' onClick={props.copyItem}>
                        {intl.formatMessage(messages.copy)}
                    </button>
                </Col>
            </Row>
        </section>
    );
};

export default injectIntl(FieldDetailsForm);
