import React from "react";

const OrderIcon = ({
  width="26",
  height="30",
  viewBox="0 0 36 40",
  fill="none",
  style=""
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {style}
    xmlns="http://www.w3.org/2000/svg">
  >
    <path d="M26 15.1519L10 5.92523" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M34 26.8852V12.663C33.9994 12.0395 33.8348 11.4271 33.5227 10.8873C33.2107 10.3475 32.7622 9.89922 32.2222 9.58746L19.7778 2.47635C19.2373 2.16429 18.6241 2 18 2C17.3759 2 16.7627 2.16429 16.2222 2.47635L3.77778 9.58746C3.2378 9.89922 2.7893 10.3475 2.47726 10.8873C2.16523 11.4271 2.00064 12.0395 2 12.663V26.8852C2.00064 27.5088 2.16523 28.1211 2.47726 28.661C2.7893 29.2008 3.2378 29.649 3.77778 29.9608L16.2222 37.0719C16.7627 37.384 17.3759 37.5483 18 37.5483C18.6241 37.5483 19.2373 37.384 19.7778 37.0719L32.2222 29.9608C32.7622 29.649 33.2107 29.2008 33.5227 28.661C33.8348 28.1211 33.9994 27.5088 34 26.8852Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.48001 10.8141L18 19.7919L33.52 10.8141" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 37.6941V19.7741" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>  </svg>
);

export default OrderIcon;
