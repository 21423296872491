import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    itemDatasTitle: {
        id: 'nfe.itemDatasTitle',
        defaultMessage: 'Dados do item',
    },
    purchaseOrderTitle: {
        id: 'nfe.purchaseOrderTitle',
        defaultMessage: 'Pedido de compras',
    },
    ICMSTitle: {
        id: 'nfe.ICMSTitle',
        defaultMessage: 'ICMS',
    },
    IPITitle: {
        id: 'nfe.IPITitle',
        defaultMessage: 'IPI',
    },
    importTaxTitle: {
        id: 'nfe.importTaxTitle',
        defaultMessage: 'Imposto de Importação',
    },
    PISTitle: {
        id: 'nfe.PISTitle',
        defaultMessage: 'PIS',
    },
    cofinsTitle: {
        id: 'nfe.cofinsTitle',
        defaultMessage: 'Cofins',
    },
    ISSQNTitle: {
        id: 'nfe.ISSQNTitle',
        defaultMessage: 'ISSQN',
    },

    //#region ItemDatas
    cProd: {
        id: 'nfe.cProd',
        defaultMessage: 'Código do produto',
    },
    xProd: {
        id: 'nfe.xProd',
        defaultMessage: 'Descrição do produto',
    },
    NCM: {
        id: 'nfe.NCM',
        defaultMessage: 'NCM',
    },
    CFOP: {
        id: 'nfe.CFOP',
        defaultMessage: 'CFOP',
    },
    uCom: {
        id: 'nfe.uCom',
        defaultMessage: 'Unidade comercial',
    },
    qCom: {
        id: 'nfe.qCom',
        defaultMessage: 'Quantidade comercial',
    },
    vUnCom: {
        id: 'nfe.vUnCom',
        defaultMessage: 'Valor unitário',
    },
    vProd: {
        id: 'nfe.vProd',
        defaultMessage: 'Valor total',
    },

    //#endregion ItemDatas

    //#region PurchaseOrder
    xPed: {
        id: 'nfe.xPed',
        defaultMessage: 'Número do pedido',
    },
    nItemPed: {
        id: 'nfe.nItemPed',
        defaultMessage: 'Item do pedido',
    },
    //#endregion PurchaseOrder

    //#region ICMS
    orig: {
        id: 'nfe.orig',
        defaultMessage: 'Origem da mercadoria',
    },
    IcmsCST: {
        id: 'nfe.IcmsCST',
        defaultMessage: 'Tributação do ICSM',
    },
    modBC: {
        id: 'nfe.orderItem',
        defaultMessage: 'Modalidade ICMS',
    },
    valueBC: {
        id: 'nfe.valueBC',
        defaultMessage: 'Valor da BC',
    },
    vBC: {
        id: 'nfe.vBC',
        defaultMessage: 'Base de cálculo',
    },
    pICMS: {
        id: 'nfe.pICMS',
        defaultMessage: 'Alíquota do imposto',
    },
    vICMS: {
        id: 'nfe.vICMS',
        defaultMessage: 'Valor do ICMS',
    },
    modBCST: {
        id: 'nfe.orderItem',
        defaultMessage: 'Modalidade ICMS ST',
    },
    pMVAST: {
        id: 'nfe.pMVAST',
        defaultMessage: 'Percentual valor Adicionado ICMS ST',
    },
    pRedBCST: {
        id: 'nfe.pRedBCST',
        defaultMessage: 'Percentual de redução de BC ICMS ST',
    },
    vBCST: {
        id: 'nfe.vBCST',
        defaultMessage: 'Valor da BC ICMS ST',
    },
    pICMSST: {
        id: 'nfe.pICMSST',
        defaultMessage: 'Alíquota do imposto ICMS ST',
    },
    vICMSST: {
        id: 'nfe.vICMSST',
        defaultMessage: 'Valor do ICMS ST',
    },
    vICMSDeson: {
        id: 'nfe.vICMSDeson',
        defaultMessage: 'Valor do ICMS',
    },
    pRedBC: {
        id: 'nfe.pRedBC',
        defaultMessage: 'Percentual da redução de BC',
    },
    pDif: {
        id: 'nfe.pDif',
        defaultMessage: 'Percentual de diferimento',
    },
    vICMSDif: {
        id: 'nfe.vICMSDif',
        defaultMessage: 'Valor do ICMS diferido',
    },
    vICMSOp: {
        id: 'nfe.vICMSOp',
        defaultMessage: 'Valor do ICMS da Operação',
    },
    vBCSTRet: {
        id: 'nfe.vBCSTRet',
        defaultMessage: 'Valor do BC do ICMS ST retido',
    },
    vICMSSTRet: {
        id: 'nfe.vICMSSTRet',
        defaultMessage: 'Valor do ICMS ST retido',
    },
    //#endregion ICMS

    //#region IPI
    CST: {
        id: 'nfe.CST',
        defaultMessage: 'Código de situação tributária',
    },
    pIPI: {
        id: 'nfe.pIPI',
        defaultMessage: 'Alíquota',
    },
    qUnid: {
        id: 'nfe.qUnid',
        defaultMessage: 'Quantidade',
    },
    vUnid: {
        id: 'nfe.vUnid',
        defaultMessage: 'Valor',
    },
    vIPI: {
        id: 'nfe.vIPI',
        defaultMessage: 'Valor do IPI',
    },
    //#endregion IPI

    //#region importTax
    VDespAdu: {
        id: 'nfe.VDespAdu',
        defaultMessage: 'Despesas aduaneiras',
    },
    VII: {
        id: 'nfe.VII',
        defaultMessage: 'Imposto de importação',
    },
    VIOF: {
        id: 'nfe.VIOF',
        defaultMessage: 'IOF',
    },
    //#endregion importTax

    //#region PIS
    vPIS: {
        id: 'nfe.vPIS',
        defaultMessage: 'Valor do PIS',
    },
    qBCProd: {
        id: 'nfe.qBCProd',
        defaultMessage: 'Quantidade vendida',
    },
    vAliqProdPis: {
        id: 'nfe.vAliqProd',
        defaultMessage: 'Alíquota do PIS(R$)',
    },
    //#endregion PIS

    //#region Cofins
    vCOFINS: {
        id: 'nfe.vCOFINS',
        defaultMessage: 'Valor do COFINS',
    },
    vAliqProdCofins: {
        id: 'nfe.vAliqProd',
        defaultMessage: 'Alíquota do COFINS(R$)',
    },
    //#endregion Cofins

    //#region ISSQN
    vAliq: {
        id: 'nfe.vAliq',
        defaultMessage: 'Alíquota(%)',
    },
    vISSQN: {
        id: 'nfe.vISSQN',
        defaultMessage: 'Valor do ISSQN',
    },
    cMunFG: {
        id: 'nfe.cMunFG',
        defaultMessage: 'Código do município de Ocorrência',
    },
    vDeducao: {
        id: 'nfe.vDeducao',
        defaultMessage: 'Valor de dedução',
    },
    vDescIncond: {
        id: 'nfe.vDescIncond',
        defaultMessage: 'Valor desconto incondicionado',
    },
    vDescCond: {
        id: 'nfe.vDescCond',
        defaultMessage: 'Valor desconto condicionado',
    },
    vISSRet: {
        id: 'nfe.vISSRet',
        defaultMessage: 'Valor retenção de ISS',
    },
    cServico: {
        id: 'nfe.cServico',
        defaultMessage: 'Código do serviço prestado',
    },
    //#endregion ISSQN
});

export default messages;
