import React from 'react';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import messages from '../MapListPage.intl';
import DropdownListButton from '../../../components/DropdownListButton/DropdownListButton';

export const MapListColumns = (
    intl,
    maps,
    canInactivateMap,
    hasLevelFull,
    handleCopyMapButtonClick,
    handleExportMapButtonClick,
    handleEditMapButtonClick,
    handleDeactivateMapToogleModal,
    handleAssociateClick,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.documentType)}</span>
            </div>
        ),
        accessor: 'documentType',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        minWidth:
            40 +
            calculateColumnWidth(
                maps,
                'documentType',
                intl.formatMessage(messages.documentType),
            ),
        maxWidth:
            80 +
            calculateColumnWidth(
                maps,
                'documentType',
                intl.formatMessage(messages.documentType),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.mapVersion)}</span>
            </div>
        ),
        accessor: 'mapVersion',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{`V${row.value + 1}`}</div>
                </div>
            );
        },
        minWidth:
            40 +
            calculateColumnWidth(
                maps,
                'mapVersion',
                intl.formatMessage(messages.mapVersion),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.name)}</span>
            </div>
        ),
        accessor: 'name',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        minWidth:
            40 +
            calculateColumnWidth(
                maps,
                'name',
                intl.formatMessage(messages.name),
            ),
        maxWidth:
            100 +
            calculateColumnWidth(
                maps,
                'name',
                intl.formatMessage(messages.name),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.contractor)}</span>
            </div>
        ),
        accessor: 'contractorName',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        minWidth:
            60 +
            calculateColumnWidth(
                maps,
                'contractorName',
                intl.formatMessage(messages.contractor),
            ),
        maxWidth:
            100 +
            calculateColumnWidth(
                maps,
                'contractorName',
                intl.formatMessage(messages.contractor),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.city)}</span>
            </div>
        ),
        accessor: 'city',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        minWidth:
            40 +
            calculateColumnWidth(
                maps,
                'city',
                intl.formatMessage(messages.city),
            ),
        maxWidth:
            80 +
            calculateColumnWidth(
                maps,
                'city',
                intl.formatMessage(messages.city),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.uf)}</span>
            </div>
        ),
        accessor: 'uf',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        minWidth:
            40 +
            calculateColumnWidth(maps, 'uf', intl.formatMessage(messages.uf)),
        maxWidth:
            70 +
            calculateColumnWidth(maps, 'uf', intl.formatMessage(messages.uf)),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ibge)}</span>
            </div>
        ),
        accessor: 'ibgeCode',
        Cell: row => {
            let rowValue = row.value;
            if (String(row.value).trim() === '0') {
                rowValue = '';
            }
            return (
                <div className='customer-container'>
                    <div className=''>{rowValue}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        minWidth:
            45 +
            calculateColumnWidth(
                maps,
                'ibgeCode',
                intl.formatMessage(messages.ibge),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.categoryType)}</span>
            </div>
        ),
        accessor: 'categoryType',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            let categoryValue = row.value == 0 ? '' : row.value;
            if (!!row.original.subCategory) {
                categoryValue = `${categoryValue}(${row.original.subCategory})`;
            }
            return (
                <div className='customer-container'>
                    <div className=''>{categoryValue}</div>
                </div>
            );
        },
        minWidth:
            50 +
            calculateColumnWidth(
                maps,
                'categoryType',
                intl.formatMessage(messages.categoryType),
            ),
        maxWidth:
            150 +
            calculateColumnWidth(
                maps,
                'categoryType',
                intl.formatMessage(messages.categoryType),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.createdAt)}</span>
            </div>
        ),
        accessor: 'createdAt',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return new Date(row.value).toLocaleString(navigator.language);
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                maps,
                'createdAt',
                intl.formatMessage(messages.createdAt),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.updatedAt)}</span>
            </div>
        ),
        accessor: 'updatedAt',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return row.value
                ? new Date(row.value).toLocaleString(navigator.language)
                : '';
        },
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.active)}</span>
            </div>
        ),
        accessor: 'active',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return row.value
                ? intl.formatMessage(messages.yesText)
                : intl.formatMessage(messages.noText);
        },
        minWidth:
            30 +
            calculateColumnWidth(
                maps,
                'active',
                intl.formatMessage(messages.active),
            ),
        maxWidth:
            40 +
            calculateColumnWidth(
                maps,
                'active',
                intl.formatMessage(messages.active),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span />
            </div>
        ),
        Cell: row => {
            const mapIsActive = row.original.active ? true : false;
            const dropdownItems = [
                {
                    name: intl.formatMessage(messages.titleEdit),
                    onClick: e => handleEditMapButtonClick(e, row),
                },
                {
                    name: intl.formatMessage(messages.export),
                    onClick: e => handleExportMapButtonClick(e, row),
                },
                {
                    name: intl.formatMessage(messages.copy),
                    onClick: e => handleCopyMapButtonClick(e, row),
                },
                {
                    name: intl.formatMessage(messages.deactivateMap),
                    onClick: e => handleDeactivateMapToogleModal(e, row),
                    disabled: !canInactivateMap,
                },
                {
                    name: intl.formatMessage(messages.associate),
                    onClick: e => handleAssociateClick(e, row),
                    disabled: !(
                        row.original.documentType === 'NFS' && hasLevelFull
                    ),
                },
            ];
            return mapIsActive ? (
                <DropdownListButton items={dropdownItems} />
            ) : (
                <p />
            );
        },
        sortable: false,
        maxWidth: 60,
        style: {
            display: 'flex',
            justifyContent: 'center',
            fontSize: 'small',
        },
    },
];
