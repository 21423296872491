import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import * as actions from '../../../store/actions/index';
import DynamicDataTable from '../../../templates/DynamicDataTable/DynamicDataTable';
import globalMessages from '../../../utils/globalMessages';
import { useLinkingOrders } from '../../../context/LinkingOrdersContext';
import SearchBar from '../../../components/SearchBar/SearchBar';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { columnsOrdersToAssociate } from '../constants';

function OrdersToAssociateTable({
    // Props
    intl,
    docType,
    CNPJTakerAlt,
    hideCheckFromTaker,

    // Redux actions
    onInitOrderItemsBySupplier,
    onUpdateAssociatedOrders,
    onInitAddNfeOrderItems,

    // Redux state
    orderItemsBySupplier,
    nfeOrders,
    cteOrders,
    listOrderFromDocumentTaker = false,
    loadingItemsBySupplierList,
    loadingModalNfeOrders,
    loadingModalOrders,
}) {
    const {
        selectedOrders,
        setSelectedOrders,
        selectedDocument,
        handleSelectOrderItem,
        selectedNfeItem,
        documentId,
    } = useLinkingOrders();

    const [newDataOrderItems, setNewDataOrderItems] = useState({
        data: orderItemsBySupplier,
        count: orderItemsBySupplier?.length,
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [listFromDocumentTaker, setListFromDocumentTaker] = useState(null);
    const [loadingOrders, setLoadingOrders] = useState(false);

    const disabledTable = docType === 'NFE' && !selectedNfeItem;

    useEffect(() => {
        switch (docType) {
            case 'NFE':
                setLoadingOrders(
                    !selectedNfeItem &&
                        (loadingItemsBySupplierList || loadingModalNfeOrders),
                );
                break;
            default:
                setLoadingOrders(
                    loadingItemsBySupplierList || loadingModalOrders,
                );
                break;
        }

        setLoadingOrders(
            docType === 'NFE'
                ? !selectedNfeItem && loadingItemsBySupplierList
                : loadingItemsBySupplierList,
        );
    }, [loadingItemsBySupplierList, loadingModalNfeOrders]);

    useEffect(() => {
        if (docType === 'NFE' && selectedNfeItem && selectedDocument) {
            reloadOrders();
        }
    }, [selectedNfeItem, selectedDocument]);

    useEffect(() => {
        if (!!selectedDocument) {
            reloadOrders();
        }
        setSelectedOrders([]);
        setSearchTerm('');
    }, [selectedDocument]);

    useEffect(() => {
        setNewDataOrderItems({
            data: orderItemsBySupplier,
            count: orderItemsBySupplier?.length,
        });
    }, [orderItemsBySupplier]);

    useEffect(() => setListFromDocumentTaker(listOrderFromDocumentTaker), [
        listOrderFromDocumentTaker,
    ]);

    useEffect(() => {
        let newOrders = [];

        if (listFromDocumentTaker) {
            newOrders = filterDataToTaker(orderItemsBySupplier, CNPJTakerAlt);
            setNewDataOrderItems({ data: newOrders, count: newOrders?.length });
        } else {
            setNewDataOrderItems({
                data: orderItemsBySupplier,
                count: orderItemsBySupplier?.length,
            });
        }
    }, [listFromDocumentTaker, orderItemsBySupplier]);

    const reloadOrders = () => {
        let dataOrders = undefined;

        switch (docType) {
            case 'NFE':
                dataOrders = nfeOrders;
                break;
            case 'CTE':
                dataOrders = cteOrders;
                break;
            default:
                break;
        }

        onInitOrderItemsBySupplier(
            selectedDocument.supplierId,
            '?sort=-createdAt&skip=0&take=100&page=0',
            dataOrders,
        );
    };

    const handleSearchBarChange = newSearchTerm => setSearchTerm(newSearchTerm);

    function handleAddItemSubmit() {
        if (selectedOrders.length === 0) {
            toast.error(`Nenhum item foi selecionado`);
            return;
        }

        const orderItems = selectedOrders.join(',');

        const nfeOrderItems = formatToNfeOrderItem(
            selectedOrders,
            selectedNfeItem,
        );

        switch (docType) {
            case 'NFE':
                onInitAddNfeOrderItems(documentId, nfeOrderItems);
                break;

            default:
                onUpdateAssociatedOrders(documentId, orderItems, docType);
                break;
        }
    }

    function handleSearchBarSubmit(newSearchTerm) {
        if (!newSearchTerm) {
            if (listFromDocumentTaker) {
                const newOrders = filterDataToTaker(
                    orderItemsBySupplier,
                    CNPJTakerAlt,
                );
                setNewDataOrderItems({
                    data: newOrders,
                    count: newOrders?.length,
                });
            } else {
                setNewDataOrderItems({
                    data: orderItemsBySupplier,
                    count: orderItemsBySupplier?.length,
                });
            }
            return;
        }
        setSearchTerm(newSearchTerm);

        const newOrders = newDataOrderItems?.data.filter(item => {
            const termLowerCase = String(newSearchTerm.toLowerCase()).trim();

            const formatShippingDate = new Date(
                item?.shippingDate,
            ).toLocaleString(navigator.language);
            const formatTotalGrossValue = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(String(item?.totalGrossValue));

            return (
                item?.orderCode?.toLowerCase().includes(termLowerCase) ||
                item?.orderName?.toLowerCase().includes(termLowerCase) ||
                item?.itemOrder?.toLowerCase().includes(termLowerCase) ||
                item?.orderMeasure?.toLowerCase().includes(termLowerCase) ||
                item?.description?.toLowerCase().includes(termLowerCase) ||
                formatTotalGrossValue?.toLowerCase().includes(termLowerCase) ||
                formatShippingDate.toLowerCase().includes(termLowerCase)
            );
        });

        setNewDataOrderItems({ data: newOrders, count: newOrders?.length });
    }

    function filterDataToTaker(orderItemsBySupplier = [], CNPJTakerAlt) {
        let newData = [];
        const CNPJTaker =
            CNPJTakerAlt ||
            selectedDocument?.form?.fields
                ?.find(x => x.key.toLowerCase() === 'contractordocumentnumber1' || x.key === '/nfeProc/NFe/infNFe/dest/CNPJ')
                ?.value?.replace(/[^0-9]/g, '');

        if (CNPJTaker?.length === 14 || CNPJTaker?.length === 11) {
            newData = orderItemsBySupplier.filter(item => {
                const termLowerCase = String(CNPJTaker)
                    ?.toLowerCase()
                    ?.trim();
                return item?.cnpjTaker
                    ?.replace(/[^0-9]/g, '')
                    ?.toLowerCase()
                    .trim()
                    .includes(termLowerCase);
            });
        }
        return newData;
    }

    const formatToNfeOrderItem = (orderIds, nfeItem) => {
        if (orderIds == null || nfeItem == null) return null;

        let orderItems = [];
        const nfeItemId = nfeItem.id;
        const nfeItemNumber = nfeItem.nItemNumber;

        orderIds.forEach(orderId => {
            orderItems.push({ orderItemId: orderId, nfeItemId, nfeItemNumber });
        });

        return orderItems;
    };

    const enableCheckBox = listOrderFromDocumentTaker && !hideCheckFromTaker;

    return (
        <DynamicDataTable
            headerTitle='Itens de pedidos para associação'
            componentId={'orderItemsToAssociate'}
            loading={loadingOrders}
            headerTitleSize='24px'
            tableColumns={columnsOrdersToAssociate(
                intl,
                orderItemsBySupplier,
                handleSelectOrderItem,
                selectedOrders || [],
            )}
            tableData={newDataOrderItems?.data || []}
            tableDataLength={newDataOrderItems?.count || 0}
            tablePageSize={5}
            tableFrontPagination
            hideNewButton
            tableTabInside
            tableFetchPage
            additionalHeaderComponent={
                <header>
                    <div className='btns-topo btns-float-right'>
                        <SearchBar
                            searchTerm={searchTerm}
                            handleSearchChange={handleSearchBarChange}
                            handleSearchSubmit={handleSearchBarSubmit}
                            loading={loadingOrders}
                            colorVar
                        />
                    </div>
                </header>
            }
            additionalFooterComponent={
                <div style={{ marginTop: '16px' }}>
                    <CheckBox
                        style={{
                            fontSize: '17px',
                            visibility:
                                !enableCheckBox
                                    ? 'hidden'
                                    : 'visible',
                        }}
                        label='Somente pedidos do tomador do documento.'
                        id='ListOnlyOrderFromTheDocumentTakerForAssociate'
                        checked={listFromDocumentTaker}
                        value={listFromDocumentTaker}
                        onChange={() => {
                            setListFromDocumentTaker(prev => !prev);
                        }}
                        disabled={disabledTable}
                    />
                    <button
                        className={`btn-submit ${disabledTable &&
                            'btn-disable'}`}
                        onClick={handleAddItemSubmit}
                        style={{ float: 'right', marginRight: 10 }}
                    >
                        {intl.formatMessage(globalMessages.confirmButton)}
                    </button>
                </div>
            }
        />
    );
}

const mapStateToProps = state => ({
    nfeOrders: state.NFeReducer.nfeOrders || [],
    cteOrders: state.cteReducer.cteOrders || [],
    orderItemsBySupplier: state.orderReducer.orderItemsBySupplier || [],
    listOrderFromDocumentTaker:
        state.orderReducer.listOnlyOrderFromTheDocumentTakerForAssociation,
    loadingModalNfeOrders: state.NFeReducer.loadingModalNfeOrders,
    loadingModalOrders: state.documentReducer.loadingModalOrders,
    loadingItemsBySupplierList: state.orderReducer.loadingItemsBySupplierList,
});

const mapDispatchToProps = dispatch => ({
    onUpdateAssociatedOrders: (
        documentId,
        orderItemId,
        docType,
        functionDispatch,
    ) =>
        dispatch(
            actions.updateOrdersDocument(
                documentId,
                orderItemId,
                docType,
                functionDispatch,
            ),
        ),
    onInitAddNfeOrderItems: (documentId, nfeOrderItems, isContinueModal) =>
        dispatch(
            actions.initAddNfeOrderItems(
                documentId,
                nfeOrderItems,
                isContinueModal,
            ),
        ),
    onInitOrderItemsBySupplier: (supplierId, filter) =>
        dispatch(actions.initOrderItemsBySupplier(supplierId, filter)),
});

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(OrdersToAssociateTable),
);
