import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';
import messages from './CompanyDetailsPage.intl';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import CompanyDetailsForm from '../../components/CompanyDetailsForm/CompanyDetailsForm';
import { AuthContext } from '../../context/AuthContext';

function CompanyDetailsPage(props) {
    const context = useContext(AuthContext);

    const [activeTab, setActiveTab] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    useEffect(() => {
        const companyId = props.match.params.companyid || '';

        props.onInitCompanyDetails(companyId);
        props.onInitStates();
        props.onInitCities('SP');
    }, []);

    useEffect(() => {
        const state = props.company?.state || '';

        if (props.company) {
            props.onInitCities(state);
        }
    }, [props.company]);

    function handleTabClick(e, index) {
        if (activeTab !== index) {
            setActiveTab(index);
        }
    }

    function handleSubmit(event, values) {
        if (props.loadingDetails) return;
        const contractorId = props.match.params.id;
        const companyId = props.company?.companyId;
        const payload = { ...values, contractorId: contractorId };

        if (props.match?.params.companyid === 'new') {
            props.onCreateCompany(contractorId, payload);
        } else {
            props.onUpdateCompany(contractorId, companyId, payload);
        }
    }

    function handleToggleModal() {
        setShowModal(!showModal);
    }

    async function handleToggleDelete(event, values) {
        const companyId = props.company?.companyId;
        const contractorId = props.match.params.id;

        setShowModal(!showModal);

        await props.onDeleteCompany(contractorId, companyId);
    }

    function handleStateChange(state) {
        const stateOption = props.states.find(s => s.uf === state.value);
        props.onInitCities(state.value);

        setSelectedState({
            selectedLabelOption: stateOption.name,
            selectedValueOption: stateOption.uf,
        });

        setSelectedCity({
            selectedLabelOption: '',
            selectedValueOption: '',
        });
    }

    function handleCityChange(city) {
        setSelectedCity({
            selectedLabelOption: city.label,
            selectedValueOption: city.value,
        });
    }
    const { intl, loadingDetails, states, cities, company } = props;

    const isNew = props.match.params.companyid === 'new';

    const canCreateCompany = context.hasClaim('company-create') && isNew;

    const canDeleteCompany = context.hasClaim('company-delete') && !isNew;

    const canUpdateCompany = context.hasClaim('company-update') && !isNew;

    const readOnly = !(canCreateCompany || canUpdateCompany);

    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/contractors',
                        name: intl.formatMessage(messages.title),
                        active: false,
                    },
                    {
                        path: `/contractors/${props.match.params.id}`,
                        name: intl.formatMessage(messages.titleContractor, {
                            entity:
                                props.contractor &&
                                props.contractor.displayName,
                        }),
                        active: false,
                    },
                    {
                        path: `/contractors/${
                            props.match.params.id
                        }/companies/${props.match.params.companyid}`,
                        name: intl.formatMessage(messages.titleCompany, {
                            entity: props.company && props.company.name,
                        }),
                        active: true,
                    },
                ]}
            />
            <header>
                <section className='title'>
                    <Header
                        title={intl.formatMessage(messages.titleCompany, {
                            entity: '',
                        })}
                    />
                </section>
            </header>
            <section className='content-middle bg-content'>
                <Loading loading={loadingDetails} />
                <Tabs
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    tabs={[
                        {
                            name: intl.formatMessage(messages.registrationInfo),
                            component: (
                                <CompanyDetailsForm
                                    company={company}
                                    handleSubmit={handleSubmit}
                                    handleDelete={handleToggleModal}
                                    states={states}
                                    cities={cities}
                                    handleStateChange={handleStateChange}
                                    handleCityChange={handleCityChange}
                                    readOnly={readOnly}
                                    canDeleteCompany={canDeleteCompany}
                                    selectedCity={selectedCity}
                                    selectedState={selectedState}
                                />
                            ),
                        },
                    ]}
                />
            </section>
            <Modal
                title={intl.formatMessage(messages.deleteCompany)}
                isOpen={showModal}
                handleToggle={handleToggleModal}
                type={modalTypes.ERROR}
                hasFooter={true}
                firstButtonText={intl.formatMessage(messages.yesText)}
                secondButtonText={intl.formatMessage(messages.noText)}
                toggleFirstButton={handleToggleDelete}
                toggleSecondButton={handleToggleModal}
            >
                {intl.formatMessage(messages.confirmDeleteText)}
            </Modal>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        contractor: state.contractorReducer.contractor,
        company: state.companyReducer.company,
        loadingDetails: state.companyReducer.loadingDetails,
        states: state.stateReducer.states,
        cities: state.stateReducer.cities || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitCompanyDetails: companyId =>
            dispatch(actions.initCompanyDetails(companyId)),
        onCreateCompany: (contractorId, values) =>
            dispatch(actions.createCompany(contractorId, values)),
        onUpdateCompany: (contractorId, companyId, values) =>
            dispatch(actions.updateCompany(contractorId, companyId, values)),
        onDeleteCompany: (contractorId, companyId) =>
            dispatch(actions.deleteCompany(contractorId, companyId)),

        onInitStates: () => dispatch(actions.initStates()),
        onInitCities: uf => dispatch(actions.initCities(uf)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(CompanyDetailsPage),
);
