import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

import { Alert } from 'reactstrap';
import { Responsive, WidthProvider } from 'react-grid-layout';

import Widget from './Widget/Widget';
import Header from '../../components/Header/Header';

import { injectIntl } from 'react-intl';
import messages from './Dashboard.intl';

import { AuthContext } from '../../context/AuthContext';

const ResponsiveGridLayout = WidthProvider(Responsive);

class Dashboard extends Component {
    static contextType = AuthContext; 

    constructor(props) {
        super(props);

        this.state = {
            mode: 'static',
            widgets: [],
        };

        this.handleGridLayoutChange = this.handleGridLayoutChange.bind(this);
    }

    componentDidMount() {
        if(this.context.hasClaim('widget-view')){
            this.props.onInitWidgets();
        }
    }

    componentDidUpdate(prevProps) {
        const widgets = this.props.widgets;

        if (prevProps.widgets !== this.props.widgets) {
            const localStorageLayout = this.getFromLocalStorage('layout');
            const originalLayout =
                JSON.parse(JSON.stringify(localStorageLayout || '')) || [];

            const newWidgets = widgets.map((w, i) => {
                const layout = originalLayout.find(l => l.i === w.widgetId);
                let widgetLayout = layout || w.layout;

                widgetLayout = widgetLayout || {
                    i: w.widgetId,
                    ...w.layout,
                };

                return {
                    ...w,
                    layout: {
                        ...widgetLayout,
                        isDraggable: false,
                        isResizable: false,
                    },
                };
            });

            this.setState({
                widgets: newWidgets,
            });
        }
    }

    saveToLocalStorage(key, value) {
        if (global.localStorage) {
            global.localStorage.setItem(
                'DASHBOARD',
                JSON.stringify({
                    [key]: value,
                }),
            );
        }
    }

    getFromLocalStorage(key) {
        let ls = {};
        if (global.localStorage) {
            try {
                ls =
                    JSON.parse(
                        global.localStorage.getItem('DASHBOARD'),
                    ) || {};
            } catch (e) {
                return null;
            }
        }
        return ls[key];
    }

    handleGridLayoutChange = e => {
        const layout = e.map(l => {
            let id = '';
            const split = l.i.split('_');
            if (split.length > 0) {
                id = split[0];
            }

            return {
                ...l,
                i: id,
            };
        });

        this.saveToLocalStorage('layout', layout);
    };

    handleEditSaveDashboardBtnClick = e => {
        const { mode } = this.state;

        if (mode === 'static') {
            const widgets = this.state.widgets.map(w => {
                return {
                    ...w,
                    layout: {
                        ...w.layout,
                        isDraggable: true,
                        isResizable: true,
                    },
                };
            });

            this.setState({
                mode: 'edit',
                widgets,
            });
        } else {
            const localStorageLayout = this.getFromLocalStorage('layout');
            const originalLayout = JSON.parse(
                JSON.stringify(localStorageLayout),
            );

            const widgets = this.state.widgets.map(w => {
                const layout = originalLayout.find(l => l.i === w.widgetId);
                const widgetLayout = layout || w.layout;

                return {
                    ...w,
                    layout: {
                        ...widgetLayout,
                        isDraggable: false,
                        isResizable: false,
                    },
                };
            });

            this.setState({
                mode: 'static',
                widgets,
            });
        }
    };

    render() {
        const { intl } = this.props;
        const { widgets, mode } = this.state;
        const readOnly = mode === 'edit';

        const layout = widgets.map(w => {
            return {
                i: w.widgetId,
                ...w.layout,
            };
        });

        return (
            <div>
                <Header
                    title={intl.formatMessage(messages.headerTitle)}
                />
                <button
                    className='dashboard-edit-button'
                    onClick={this.handleEditSaveDashboardBtnClick}
                >
                    {mode === 'static'
                        ? intl.formatMessage(messages.headerButtonEdit)
                        : intl.formatMessage(messages.headerButtonSave)}
                </button>
                {mode === 'edit' && (
                    <Alert color='warning' style={{ fontSize: '16px' }}>
                        {intl.formatMessage(messages.editModeAlertText)}
                    </Alert>
                )}
                <ResponsiveGridLayout
                    compactType={'vertical'}
                    layout={layout}
                    onLayoutChange={this.handleGridLayoutChange}
                    className='dashboard-grid'
                >
                    {widgets.map(widget => {
                        let classWidget = 'widget ';
                        if (widget.class !== '') {
                            classWidget += widget.class + '-widget';
                        }

                        return (
                            <div
                                key={`${widget.widgetId}_${mode}`}
                                data-grid={widget.layout}
                                className={classWidget}
                            >
                                <Widget {...widget} readOnly={readOnly} />
                            </div>
                        );
                    })}
                </ResponsiveGridLayout>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        widgets: state.widgetReducer.widgets,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitWidgets: () => dispatch(actions.initWidgets()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(Dashboard));
