import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    //orders
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
    itemsSubtitle: {
        id: 'invoices.itemsSubtitle',
        defaultMessage: ' {length} itens encontrados',
    },
    newOrdersButton: {
        id: 'documents.newOrdersButton',
        defaultMessage: '+ Pedido',
    },
    associatedOrderDocuments: {
        id: 'documents.associatedOrderDocuments',
        defaultMessage: 'Itens Associados',
    },
    searchItemOrders: {
        id: 'documents.searchItemOrders',
        defaultMessage: 'Escolha um Item',
    },
    itemOrder: {
        id: 'documents.itemOrder',
        defaultMessage: 'Item do Pedido',
    },
    order: {
        id: 'documents.order',
        defaultMessage: 'Pedido',
    },
    description: {
        id: 'documents.description',
        defaultMessage: 'Descrição',
    },
    titleOrderCode: {
        id: 'documents.titleOrderCode',
        defaultMessage: 'Pedido',
    },
    itemOrderDescription: {
        id: 'documents.itemOrderDescription',
        defaultMessage: 'Descrição do Item',
    },
    totalGrossValue: {
        id: 'documents.totalGrossValue',
        defaultMessage: 'Valor bruto',
    },
    removeOrderItem: {
        id: 'orderItem.removeOrderItem',
        defaultMessage: 'Remover Item do Pedido',
    },
    orderMeasure: {
        id: 'orderItem.orderMeasure',
        defaultMessage: 'Medição',
    },
    errorNoExistOrderItems: {
        id: 'orderItem.errorNoExistOrderItems',
        defaultMessage:
            'Não existem itens de pedido cadastrados para o fornecedor do documento',
    },
    noItemSelected: {
        id: 'orders.noItemSelected',
        defaultMessage: 'Nenhum item foi selecionado',
    },
    shippingDate: {
        id: 'orders.shippingDate',
        defaultMessage: 'Data de remessa',
    },

    onlyOrderFromTheDocumentTaker: {
        id: 'orders.shippingDate',
        defaultMessage: 'Exibir somente pedidos do tomador do documento.',
    },
});
