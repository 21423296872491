import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const PISForm = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const nfePIS = nfeItem?.imposto?.pis?.pisObject;

    return (
        <>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.CST)}
                            type='text'
                            name='cstPisDescription'
                            id='cstPisDescription'
                            value={
                                `${nfePIS?.cst} - ${
                                    nfePIS?.cstPisDescription
                                }` || ''
                            }
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vBC)}
                            type='text'
                            name='vBC'
                            id='vBC'
                            value={nfePIS?.vBC || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vAliq)}
                            type='text'
                            name='pPIS'
                            id='pPIS'
                            value={nfePIS?.pPIS || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vPIS)}
                            type='text'
                            name='vPIS'
                            id='vPIS'
                            value={nfePIS?.vPIS || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.qBCProd)}
                            type='text'
                            name='qBCProd'
                            id='qBCProd'
                            value={nfePIS?.qBCProd || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vAliqProdPis)}
                            type='text'
                            name='vAliqProd'
                            id='vAliqProd'
                            value={nfePIS?.vAliqProd || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(PISForm);
