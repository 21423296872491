import React, { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Row, Col, FormGroup, Badge } from 'reactstrap';

import messages from './TransactionDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';

import EvtSelect from '../EvtSelect/EvtSelect';
import CurrencyInput from 'react-currency-input-field';

function TransactionDetailsForm(props) {
    const { intl, isNew, transaction } = props;
    const context = useContext(AuthContext);

    const [contractorId, setContractorId] = useState(null);
    const [showContractorsSelect, setShowContractorsSelect] = useState(false);

    const [showSuppliersSelect, setShowSuppliersSelect] = useState(false);
    const [transactionValue, setTransactionValue] = useState(undefined);

    const canCreateTransaction =
        context.hasClaim('transaction-create') && isNew;
    const canUpdateTransaction =
        context.hasClaim('transaction-update') && !isNew;

    useEffect(() => {
        const { contractorId } = context;

        if (context.claims.length === 0) return;

        if (context.hasClaim('role-level-full')) {
            setShowContractorsSelect(true);
            setShowSuppliersSelect(true);
        } else if (context.hasClaim('role-level-contractor')) {
            setShowContractorsSelect(false);
            setContractorId(contractorId);
            setShowSuppliersSelect(true);
        } else if (context.hasClaim('role-level-supplier')) {
            setShowContractorsSelect(false);
            setContractorId(contractorId);
            setShowSuppliersSelect(false);
        }
    }, [context]);

    useEffect(() => {
        if (transaction?.transactionId) {
            setContractorId(transaction?.contractorId);
        }
    }, [transaction]);

    const isReadOnly = !(canCreateTransaction || canUpdateTransaction);

    const date = new Date(transaction?.dueDate);

    const dueDate =
        date &&
        date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10 ? '0' : '') +
            (date.getMonth() + 1) +
            '-' +
            (date.getDate() < 10 ? '0' : '') +
            date.getDate();
            
    const now = new Date();
    const isExpired = date < now;
    
    var contractorsOption = new Map();
    contractorsOption.set(
        transaction?.contractorId,
        transaction?.contractorName,
    );

    var suppliersOption = new Map();
    suppliersOption.set(transaction?.supplierId, transaction?.supplierName);

    function currencyFormat(state, value) {
        const newValue = !!state ? state.replace(',', '.') : value;
        return newValue;
    }

    return !!transaction || isNew ? (
        <AvForm
            onValidSubmit={(e, values) => {
                props.handleSubmit(e, {
                    ...values,
                    value: currencyFormat(transactionValue, transaction?.value),
                });
            }}
        >
            {transaction?.transactionId && (
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <FormGroup className='mb-5'>
                            <h2>
                                <Badge
                                    pill
                                    className='method-image'
                                    style = {{color: '#ffffff'}}
                                    color={
                                        transaction?.isPaid
                                            ? 'success'
                                                : isExpired 
                                                ? 'danger' 
                                                : 'warning'
                                    }
                                >
                                    {intl.formatMessage(
                                        transaction?.isPaid
                                            ? messages.paid
                                                : isExpired 
                                                ? messages.overdue 
                                                : messages.pending,
                                    )}
                                </Badge>
                            </h2>
                        </FormGroup>
                    </Col>
                </Row>
            )}
            <Row>
                <Col lg={3} md={3} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.documentId)}
                            type='text'
                            name='documentId'
                            id='documentId'
                            value={transaction?.documentId || ''}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>

                <Col lg={3} md={3} sm={12}>
                    <FormGroup check className=' py-3 px-5 required-field'>
                        <AvField
                            label={intl.formatMessage(messages.isAvailable)}
                            type='checkbox'
                            name='isAvailable'
                            id='isAvailable'
                            value={transaction?.isAvailable || ''}
                            checked={transaction?.isAvailable}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={4} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.erpDocumentCode)}
                            type='text'
                            name='erpDocumentCode'
                            id='erpDocumentCode'
                            value={transaction?.erpDocumentCode || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.erpDocumentItemCode,
                            )}
                            type='text'
                            name='erpDocumentItemCode'
                            id='erpDocumentItemCode'
                            value={transaction?.erpDocumentItemCode || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>

                <Col lg={4} md={4} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.referenceCode)}
                            type='text'
                            name='referenceCode'
                            id='referenceCode'
                            value={transaction?.referenceCode || ''}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={4} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.dueDate)}
                            type='date'
                            name='dueDate'
                            id='dueDate'
                            value={dueDate}
                            validate={{ datetime: true }}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>

                <Col lg={4} md={4} sm={12}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.value)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={isNew ? undefined : transaction?.value}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalScale={2}
                            decimalsLimit={2}
                            id='value'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setTransactionValue(value)}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.currency)}
                            type='text'
                            name='currency'
                            id='currency'
                            value={transaction?.currency || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    {showContractorsSelect && (
                        <EvtSelect
                            name='contractorId'
                            label={intl.formatMessage(messages.company)}
                            options={contractorsOption}
                            url='/Contractors'
                            labelKey='displayName'
                            valueKey='contractorId'
                            handleSelectedOptionChange={
                                props.handleContractorsChange
                            }
                            key={transaction?.contractorId}
                            async
                            required
                            option={
                                transaction && {
                                    selectedValueOption:
                                        transaction?.contractorId,
                                    selectedLabelOption:
                                        transaction?.contractorName,
                                }
                            }
                        />
                    )}
                </Col>

                <Col md={6}>
                    {showSuppliersSelect &&
                        (!isReadOnly ? (
                            <EvtSelect
                                name='supplierId'
                                label={intl.formatMessage(messages.supplier)}
                                options={suppliersOption}
                                url={
                                    props.selectedContractor
                                        ? `/Contractors/${
                                              props.selectedContractor
                                                  .selectedValueOption
                                          }/Suppliers`
                                        : `/Contractors/${contractorId}/Suppliers`
                                }
                                handleSelectedOptionChange={
                                    props.handleSuppliersChange
                                }
                                labelKey='displayName'
                                labelKey2='documentNumber1'
                                valueKey='supplierId'
                                key={
                                    props.selectedContractor &&
                                    props.selectedContractor.selectedValueOption
                                }
                                async
                                required
                                option={
                                    transaction && {
                                        selectedValueOption: props.selectedSupplier
                                            ? props.selectedSupplier
                                                  .selectedValueOption
                                            : transaction &&
                                              transaction.supplierId,
                                        selectedLabelOption: props.selectedSupplier
                                            ? props.selectedSupplier
                                                  .selectedLabelOption
                                            : transaction &&
                                              transaction.supplierName,
                                    }
                                }
                            />
                        ) : (
                            <FormGroup>
                                <AvField
                                    type='text'
                                    name='supplierId'
                                    id='supplierId'
                                    disabled={isReadOnly}
                                    label={intl.formatMessage(
                                        messages.supplier,
                                    )}
                                    value={transaction?.supplierName}
                                    errorMessage=' '
                                    required
                                />
                            </FormGroup>
                        ))}
                </Col>
            </Row>

            <Row className='mt-3'>
                <Col xs={12}>
                    {!isReadOnly && (
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    )}
                </Col>
            </Row>
        </AvForm>
    ) : (
        <div />
    );
}

export default injectIntl(TransactionDetailsForm);
