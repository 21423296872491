import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'contractors.title',
        defaultMessage: 'Contratantes',
    },

    titleContractor: {
        id: 'contractors.titleContractor',
        defaultMessage: 'Contratante {entity}',
    },

    subtitle: {
        id: 'contractors.subtitle',
        defaultMessage: ' contratante(s) encontrado(s)',
    },

    name: {
        id: 'contractors.name',
        defaultMessage: 'Nome',
    },

    cnpj: {
        id: 'contractors.cnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    email: {
        id: 'contractors.email',
        defaultMessage: 'E-mail',
    },

    city: {
        id: 'contractors.city',
        defaultMessage: 'Cidade',
    },

    state: {
        id: 'contractors.state',
        defaultMessage: 'Estado',
    },

    companyName: {
        id: 'detailsTab.companyName',
        defaultMessage: 'Razão Social',
    },

    displayName: {
        id: 'detailsTab.displayName',
        defaultMessage: 'Nome Fantasia',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    titleOnlyContractor: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },
});

export default messages;
