import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    title: {
        id: 'maps.title',
        defaultMessage: 'Mapeamentos',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    titleOnlyMap: {
        id: 'maps.titleOnlyMap',
        defaultMessage: 'Mapeamento',
    },

    subtitle: {
        id: 'maps.subtitle',
        defaultMessage: '{length} mapeamento(s) encontrado(s)',
    },

    code: {
        id: 'maps.code',
        defaultMessage: 'Código',
    },

    ibge: {
        id: 'maps.ibge',
        defaultMessage: 'IBGE',
    },

    city: {
        id: 'maps.city',
        defaultMessage: 'Cidade',
    },

    uf: {
        id: 'maps.uf',
        defaultMessage: 'Estado',
    },

    createdAt: {
        id: 'maps.createdAt',
        defaultMessage: 'Criado em',
    },

    updatedAt: {
        id: 'maps.updatedAt',
        defaultMessage: 'Atualizado em',
    },
    selectCity: {
        id: 'maps.selectCity',
        defaultMessage: 'Selecione a Cidade',
    },
    name: {
        id: 'suppliers.name',
        defaultMessage: 'Nome',
    },
    contractor: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },
    inactivatedMap: {
        id: 'maps.inactivatedMap',
        defaultMessage: 'Mapeamento inativado',
    },
    documentType: {
        id: 'maps.documentType',
        defaultMessage: 'Tipo documento',
    },
    fileType: {
        id: 'maps.fileType',
        defaultMessage: 'Tipo arquivo',
    },
    categoryType: {
        id: 'maps.categoryType',
        defaultMessage: 'Categoria',
    },
    subCategory: {
        id: 'maps.subCategory',
        defaultMessage: 'SubCategoria',
    },
    childMapId: {
        id: 'maps.childMapId',
        defaultMessage: 'Mapeamento filho',
    },
    mapVersion: {
        id: 'maps.mapVersion',
        defaultMessage: 'Versão',
    },
    titleEdit: {
        id: 'home.titleEdit',
        defaultMessage: 'Editar',
    },
    deactivateMap: {
        id: 'maps.deactivateMap',
        defaultMessage: 'Inativar',
    },
    associate: {
        id: 'maps.associate',
        defaultMessage: 'Associar',
    },
});
