import React from 'react';
import messages from '../../containers/DocumentListPage/DocumentListPage.intl';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Modal from '../Modal/Modal';
import SupplierPicker from '../SupplierPicker/SupplierPicker';
import Loading from '../Loading/Loading';

const ModalSupplier = ({
    intl,
    /* reducer */
    loadingList,
    loadingMaps,
    loadingSupplier,
    loadingContractor,
    contractors,
    suppliers,
    /* states */
    showSupplierPicker,
    selectedSupplier,
    selectedContractor,
    showContractor,
    /* functions */
    handleSupplierPickerSubmit,
    handleSupplierPickerToggle,
}) => {
    return (
        <Modal
            title={intl.formatMessage(messages.selectSupplier)}
            isOpen={showSupplierPicker}
            handleToggle={handleSupplierPickerToggle}
            size={'lg'}
        >
            <Loading loading={loadingMaps} />
            <SupplierPicker
                contractors={contractors}
                suppliers={suppliers}
                loadingData={loadingSupplier || loadingContractor}
                showContractor={showContractor}
                handleSupplierPickerSubmit={handleSupplierPickerSubmit}
                selectedSupplier={selectedSupplier}
                selectedContractor={selectedContractor}
                loading={loadingList}
            />
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        loadingList: state.documentReducer.loadingList,
        loadingMaps: state.supplierReducer.loadingMaps,
        loadingSupplier: state.supplierReducer.loadingList,
        loadingContractor: state.contractorReducer.loadingList,

        contractors: state.contractorReducer.contractors,
        suppliers: state.supplierReducer.suppliers,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalSupplier),
);
