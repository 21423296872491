import React, { useEffect, useState } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';

import messages from './SettingsReport.intl';

const SettingsDocumentForm = props => {
    const { intl, handleFormSubmit, reportSettings, scheduledReports } = props;
    const [enableReports, setEnableReports] = useState({});

    //inicializando o Enable reports
    useEffect(() => {
        const initialEnableReports = scheduledReports.reduce(
            (current, report) => {
                const isEnable = Boolean(
                    reportSettings?.find(r => r.type === report.key),
                );
                current[report.type] = isEnable;

                return current;
            },
            {},
        );

        setEnableReports(initialEnableReports);
    }, [scheduledReports]);

    const recurrenceOptions = [
        { label: intl.formatMessage(messages.daily), value: 0 },
        { label: intl.formatMessage(messages.weekly), value: 1 },
        { label: intl.formatMessage(messages.monthly), value: 2 }]

    function ReorganizeValues(values) {
        const reports = Object.entries(values);
        const reportsArray = [];
        reports.forEach(rep => {
            let [key, value] = rep;
            if (value?.isEnable) {
                delete value.isEnable;
                const reportScheduled = scheduledReports?.find(r => {
                    return r.type === key;
                });
                value = {
                    ...value,
                    reportName: reportScheduled.name,
                    type: reportScheduled.key,
                    recurrenceType: Number(value.recurrence)
                };
                reportsArray.push(value);
            }
        });

        const newValues = { reportScheduled: reportsArray };
        return newValues;
    }

    return scheduledReports ? (
        <AvForm
            onValidSubmit={(event, values) => {
                const newValues = ReorganizeValues(values);
                handleFormSubmit(event, newValues);
            }}
        >
            <h5 className='title-area-form'>
                {intl.formatMessage(messages.reportScheduled)}
            </h5>
            {scheduledReports?.map(({ type: key, ...report }) => {
                const settingReport = reportSettings?.find(r => {
                    return r.type === report.key;
                });

                return (
                    <Row style={{ minHeight: 80 }} key={report.type}>
                        <Col md={4}>
                            <FormGroup check className='required-field'>
                                <AvField
                                    label={report?.name || ''}
                                    type='checkbox'
                                    name={`${key}.isEnable`}
                                    id={`${key}.isEnable`}
                                    value={!!settingReport || false}
                                    onChange={() => {
                                        setEnableReports(prev => {
                                            const reports = Object.assign(
                                                {},
                                                prev,
                                            );
                                            reports[key] = !reports[key];
                                            return reports;
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <AvField
                                    type='select'
                                    label={intl.formatMessage(messages.recurrence)}
                                    name={`${key}.recurrence`}
                                    id={`${key}.recurrence`}
                                    value={
                                        enableReports[key]
                                            ? settingReport?.recurrenceType
                                            : 0
                                    }
                                    disabled={!enableReports[key]}>
                                    {recurrenceOptions.map(rec => <option value={Number(rec.value)}>{rec.label}</option>)}
                                </AvField>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.Emails)}
                                    type='text'
                                    name={`${key}.emails`}
                                    id={`${key}.emails`}
                                    value={
                                        enableReports[key]
                                            ? settingReport?.emails
                                            : ''
                                    }
                                    disabled={!enableReports[key]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                );
            })}
            <Row>
                <Col xs={12}>
                    <button
                        type='submit'
                        className='btn-submit'
                        disabled={props.loading}
                    >
                        {intl.formatMessage(messages.submitButton)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    ) : (
        <div />
    );
};

export default injectIntl(SettingsDocumentForm);
