import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';

import messages from './SupplierDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';

import EvtSelect from '../EvtSelect/EvtSelect';

const categoryOptions = [
    {
        label: 'Padrão',
        value: 999999,
    }
];

function SupplierDetailsForm(props) {
    const context = useContext(AuthContext);
    const { intl, isNew, states, cities } = props;

    const canCreateSupplier = context.hasClaim('supplier-create') && isNew;
    const canDeleteSupplier = context.hasClaim('supplier-delete') && !isNew;
    const canUpdateSupplier = context.hasClaim('supplier-update') && !isNew;

    let supplier = props.supplier || {};
    let address = supplier?.address || {};

    let convertedDocumentNumber1 = '';
    if (supplier?.documentNumber1) {
        convertedDocumentNumber1 = supplier?.documentNumber1.replace(
            /^(\d{3})(\d{3})(\d{3})(\d{2})/,
            '$1.$2.$3-$4',
        );

        if (supplier?.documentNumber1.length === 14) {
            convertedDocumentNumber1 = supplier?.documentNumber1.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                '$1.$2.$3/$4-$5',
            );
        }
    }

    const state = address && states?.find(s => s.uf === address.state);
    const city = address && state?.cities.find(s => s.name === address.city);

    const categories = categoryOptions.concat(props.categories?.map((item, _) => {
        return {
            label: item.name,
            value: parseInt(item.code)
        }
    })).sort((item, next) => -(item.value - next.value));

    const category = categories.find(item => item.value == supplier.categoryMap);

    const statesOptions =
        states &&
        states.map(state => {
            return {
                label: state?.name,
                value: state?.uf,
            };
        });

    const citiesOption =
        cities &&
        cities.map(city => {
            return {
                label: city?.name,
                value: city?.name,
            };
        });

    const isReadOnly = !(canCreateSupplier || canUpdateSupplier);

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.companyName)}
                            type='text'
                            name='companyName'
                            id='name'
                            value={supplier?.companyName || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cnpj)}
                            type='text'
                            name='documentNumber1'
                            id='documentNumber1'
                            value={convertedDocumentNumber1 || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                            validate={{
                                pattern: {
                                    value:
                                        '^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}|[0-9]{11}|[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}|[0-9]{11}|[0-9]{14})$',
                                    errorMessage: intl.formatMessage(
                                        messages.invalidCNPJ,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.municipalRegistration,
                            )}
                            type='text'
                            name='municipalRegistration'
                            id='municipalRegistration'
                            disabled={isReadOnly}
                            value={supplier?.municipalRegistration || ''}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.displayName)}
                            type='text'
                            name='displayName'
                            id='displayName'
                            value={supplier?.displayName || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.email)}
                            type='email'
                            name='emailAddress'
                            id='email'
                            value={supplier?.emailAddress || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.postalCode)}
                            type='text'
                            name='address.postalCode'
                            id='postalCode'
                            disabled={isReadOnly}
                            value={address?.postalCode || ''}
                            validate={{
                                required: { value: false },
                                pattern: {
                                    value: '^\\d{5}-?\\d{3}$',
                                    errorMessage: intl.formatMessage(
                                        messages.invalidPostalCode,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.street)}
                            type='text'
                            name='address.street'
                            id='street'
                            value={address?.street || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.number)}
                            type='number'
                            name='address.number'
                            id='number'
                            value={address?.number || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                            validate={{
                                pattern: {
                                    value: '^[0-9]+$',
                                    errorMessage: ' ',
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.complement)}
                            type='text'
                            name='address.complement'
                            id='complement'
                            value={address?.complement || ''}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.neighborhood)}
                            type='text'
                            name='address.neighborhood'
                            id='neighborhood'
                            value={address?.neighborhood || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={4} className='text-left'>
                    {!isReadOnly ? (
                        <EvtSelect
                            label={intl.formatMessage(messages.state)}
                            name='address.state'
                            options={statesOptions}
                            handleSelectedOptionChange={props.handleStateChange}
                            option={
                                supplier && {
                                    selectedValueOption: props.selectedState
                                        ? props.selectedState
                                            .selectedValueOption
                                        : state?.uf,
                                    selectedLabelOption: props.selectedState
                                        ? props.selectedState
                                            .selectedLabelOption
                                        : state?.name,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.state)}
                                type='text'
                                name='address.state'
                                id='state'
                                disabled={isReadOnly}
                                value={state?.name || ''}
                                errorMessage=' '
                            />
                        </FormGroup>
                    )}
                </Col>
                <Col xs={12} md={4} lg={4} className='text-left'>
                    {!isReadOnly ? (
                        <EvtSelect
                            label={intl.formatMessage(messages.city)}
                            name='address.city'
                            options={citiesOption}
                            handleSelectedOptionChange={props.handleCityChange}
                            option={
                                supplier && {
                                    selectedValueOption: props.selectedCity
                                        ? props.selectedCity.selectedValueOption
                                        : city && city.name,
                                    selectedLabelOption: props.selectedCity
                                        ? props.selectedCity.selectedLabelOption
                                        : city && city.name,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.city)}
                                type='text'
                                name='address.city'
                                id='state'
                                disabled={isReadOnly}
                                value={city?.name || ''}
                                errorMessage=' '
                            />
                        </FormGroup>
                    )}
                </Col>
            </Row>

            <Row>
                <Col md={5}>
                    {!isReadOnly ? (
                        <EvtSelect
                            name='contractorId'
                            label={intl.formatMessage(messages.company)}
                            url='/Contractors'
                            labelKey='displayName'
                            valueKey='contractorId'
                            handleSelectedOptionChange={
                                props.handleContractorChange
                            }
                            key={supplier && supplier?.contractorId}
                            async
                            required
                            option={
                                supplier && {
                                    selectedValueOption:
                                        supplier && supplier?.contractorId,
                                    selectedLabelOption:
                                        supplier && supplier?.contractorName,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                type='text'
                                name='contractorId'
                                id='contractorId'
                                disabled={isReadOnly}
                                label={intl.formatMessage(messages.company)}
                                value={supplier?.contractorName || ''}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    )}
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <AvField
                            type='text'
                            name='erpcode'
                            id='erpcode'
                            disabled={isReadOnly}
                            label={intl.formatMessage(messages.erpcode)}
                            value={supplier?.erpCode || ''}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup
                        check
                        className='required-field'
                        style={{ paddingBottom: 40 }}
                    >
                        <AvField
                            label={intl.formatMessage(messages.disabled)}
                            type='checkbox'
                            name='disabled'
                            id='disabled'
                            disabled={isReadOnly}
                            value={supplier?.disabled || false}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={isNew ? 4 : 5}>
                    <FormGroup
                        check
                        className='required-field'
                        style={{ paddingBottom: 40 }}
                    >
                        <AvField
                            label={intl.formatMessage(
                                messages.notRequiredOrderToSendDocuments,
                            )}
                            type='checkbox'
                            name='notRequiredOrderToSendDocuments'
                            id='notRequiredOrderToSendDocuments'
                            disabled={isReadOnly}
                            value={
                                supplier?.notRequiredOrderToSendDocuments ||
                                false
                            }
                        />
                    </FormGroup>
                </Col>
                <Col xs={isNew ? 4 : 5}>
                    <FormGroup
                        check
                        className='required-field'
                        style={{ paddingBottom: 40 }}
                    >
                        <AvField
                            label={intl.formatMessage(messages.customLayout)}
                            type='checkbox'
                            name='hasCustomLayout'
                            id='hasCustomLayout'
                            disabled={isReadOnly}
                            value={supplier?.hasCustomLayout || false}
                        />
                    </FormGroup>
                </Col>
                <Col xs={isNew ? 4 : 3}>
                    {isNew && (
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.associateMap,
                                )}
                                type='checkbox'
                                name='associateMap'
                                id='associateMap'
                                value={false}
                            />
                        </FormGroup>
                    )}
                </Col>
            </Row>
            <Row>
                <Col md={4} className='text-left'>
                    {!isReadOnly ? (
                        <EvtSelect
                            label={intl.formatMessage(messages.categoryMap)}
                            name='categoryMap'
                            options={categories}
                            key={category?.label}
                            required={false}
                            handleSelectedOptionChange={props.handleCategoryMapChange}
                            option={
                                supplier && {
                                    selectedValueOption: props.selectedCategoryMap
                                        ? props.selectedCategoryMap
                                            .selectedValueOption
                                        : category?.value,
                                    selectedLabelOption: props.selectedCategoryMap
                                        ? props.selectedCategoryMap.selectedLabelOption
                                        : category?.label,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.categoryMap)}
                                type='text'
                                name='categoryMap'
                                id='categoryMap'
                                disabled={isReadOnly}
                                value={category?.label}
                                errorMessage=' '
                            />
                        </FormGroup>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {!isReadOnly && (
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    )}

                    {canDeleteSupplier && (
                        <button
                            type='button'
                            className='btn-delete'
                            onClick={props.handleToggleModalDelete}
                        >
                            {intl.formatMessage(messages.deleteButton)}
                        </button>
                    )}
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(SupplierDetailsForm);
