import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';
import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import TransactionDetailsForm from '../../components/TransactionDetailsForm/TransactionDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './TransactionDetailsPage.intl';

import { AuthContext } from '../../context/AuthContext';

class TransactionDetailsPage extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
        };
    }

    componentDidMount() {
        this.props.onInitTransactionDetails(this.props.match.params.id);       
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,   
            });
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;

        values.dueDate = new Date(values.dueDate+'T23:59:59');

        if (!values.contractorId) {
            const { contractorId } = this.context;
            values.contractorId = contractorId;
        }

        if (this.props.match.params.id === 'new') {
            this.props.onCreateTransaction(values);
        } else {
            this.props.onUpdateTransaction(this.props.match.params.id, values);
        }
    };

    handleContractorsChange = selectedContractor => {
        this.setState({
            selectedContractor :{
                selectedLabelOption: selectedContractor.label,
                selectedValueOption: selectedContractor.value,
            }
        });

        this.setState({
            selectedSupplier :{
                selectedLabelOption: "",
                selectedValueOption: "",
            }
        });
    };
    
    handleSuppliersChange = selectedSupplier => {
        this.setState({
            selectedSupplier :{
                selectedLabelOption: selectedSupplier.label,
                selectedValueOption: selectedSupplier.value,
            }
        });
    };

    render() {

        const { intl, loadingDetails } = this.props;

        const isNew = this.props.match.params.id === "new" ? true : false;

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/transactions',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/transactions/:id',
                            name: intl.formatMessage(
                                messages.titleTransaction,
                                {
                                    entity:
                                        this.props.transaction &&
                                        this.props.transaction.transactionId,
                                },
                            ),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                messages.titleTransaction,
                                { entity: '' },
                            )}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content orderDetails'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={[
                            {
                                name: intl.formatMessage(
                                    messages.registrationInfo,
                                ),
                                component: (
                                    <TransactionDetailsForm
                                        handleSubmit = {this.handleSubmit}
                                        transaction = {this.props.transaction}
                                        isNew = {isNew}
                                        handleContractorsChange = {this.handleContractorsChange}
                                        handleSuppliersChange = {this.handleSuppliersChange}
                                        selectedSupplier={this.state.selectedSupplier}
                                        selectedContractor={this.state.selectedContractor}
                                    />
                                ),
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingDetails: state.transactionReducer.loadingDetails,
        transaction: state.transactionReducer.transaction,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitTransactionDetails: transactionId =>
            dispatch(actions.initTransactionDetails(transactionId)),
        onUpdateTransaction: (transactionId, values) =>
            dispatch(actions.updateTransaction(transactionId, values)),
        onCreateTransaction: values =>
            dispatch(actions.createTransaction(values)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TransactionDetailsPage),
);
