import React from "react";
import { Badge } from 'reactstrap';
import { calculateColumnWidth } from "../../../components/EvtTable/EvtTable";
import { messages } from "../PendingFilesList.intl";
import DropdownListButton from "../../../components/DropdownListButton/DropdownListButton";
import { filterPropertyTypes } from "../../../utils/enums";

export const pendingFilesColumns = (files, intl, handleDownloadFile) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.createdAt)}</span>
            </div>
        ),
        accessor: 'createdAt',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        maxWidth:
            400 +
            calculateColumnWidth(
                files,
                'createdAt',
                intl.formatMessage(messages.createdAt),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                files,
                'createdAt',
                intl.formatMessage(messages.createdAt),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.fileName)}</span>
            </div>
        ),
        accessor: 'fileName',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            400 +
            calculateColumnWidth(
                files,
                'fileName',
                intl.formatMessage(messages.fileName),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                files,
                'fileName',
                intl.formatMessage(messages.fileName),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.sendedBy)}</span>
            </div>
        ),
        accessor: 'sendedBy',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            400 +
            calculateColumnWidth(
                files,
                'sendedBy',
                intl.formatMessage(messages.sendedBy),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                files,
                'sendedBy',
                intl.formatMessage(messages.sendedBy),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.rejectionReason)}</span>
            </div>
        ),
        accessor: 'rejectionReason',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            400 +
            calculateColumnWidth(
                files,
                'rejectionReason',
                intl.formatMessage(messages.rejectionReason),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                files,
                'rejectionReason',
                intl.formatMessage(messages.rejectionReason),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        accessor: 'status',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const { original } = row;
            
            let color = '';
            let status;
            if (original.status === 'Sended'){
                status = intl.formatMessage(messages.sended)
                color += 'success'
            }
            else if (original.status === 'Pending'){
                status = intl.formatMessage(messages.pending)
                color += 'warning'
            }
            else if (original.status === 'Rejected'){
                status = intl.formatMessage(messages.rejected)
                color += 'danger'
            }

            return (
                <div>
                  <Badge 
                  style={{ fontSize: '0.8rem', color: '#ffffff', display: 'inline-block', width: '73px', textAlign: 'center' }}
                  color={color} 
                  pill
                  >
                    {status}
                </Badge>
                </div>
            );
        },
        maxWidth:
            400 +
            calculateColumnWidth(
                files,
                'status',
                intl.formatMessage(messages.status),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                files,
                'status',
                intl.formatMessage(messages.status),
            ),
    },
    {
        accessor: 'fileId',
        style: {
            display: 'flex',
            justifyContent: 'center',
            fontSize: 'small',
        },
        Cell: row => {
            const items = [
                {
                    name: intl.formatMessage(messages.downloadLink),
                    onClick: () => handleDownloadFile(row.value),
                },
            ];

            return <DropdownListButton items={items} />;
        },
        sortable: false,
        maxWidth: 100,
        style: {
            display: 'flex',
            justifyContent: 'center',
            fontSize: 'small',
        },
    },
];

export const pendingFilesProperties = intl => [
    {
        key: 'fileName',
        value: intl.formatMessage(messages.fileName),
        type: filterPropertyTypes.TEXT,
        lg: 6,
    },
    {
        key: 'sendedBy',
        value: intl.formatMessage(messages.sendedBy),
        type: filterPropertyTypes.TEXT,
        lg: 6,
    },
    {
        key: 'rejectionReason',
        value: intl.formatMessage(messages.rejectionReason),
        type: filterPropertyTypes.TEXT,
        lg: 6,
    },
    {
        key: 'createdAt',
        value: intl.formatMessage(messages.dateAndTime),
        type: filterPropertyTypes.DATE,
        lg: 6,
    },
    {
        key: 'status',
        value: 'Status',
        type: filterPropertyTypes.ENUM,
        options: [
            {
                key: 'pending',
                value: 'Pendente',
            },
            {
                key: 'sended',
                value: 'Enviado',
            },
            {
                key: 'rejected',
                value: 'Rejeitado',
            },
        ],
        lg: 4,
    },
]
