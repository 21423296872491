import React, { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';

import messages from './NfeItemDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';

import ItemDatasForm from './components/ItemDatasForm';
import PurchaseOrderForm from './components/PurchaseOrderForm';
import ICMSForm from './components/ICMSForm';
import IPIForm from './components/IPIForm';
import ImportTaxForm from './components/ImportTaxForm';
import PISForm from './components/PISForm';
import CofinsForm from './components/CofinsForm';
import ISSQNForm from './components/ISSQNForm';

import Tabs from '../Tabs/Tabs';

function NfeItemDetailsForm(props) {
    const { intl, nfeItem, loading } = props;

    const context = useContext(AuthContext);

    const canCreateNfeItem = context.hasClaim('supplier-create');
    const canUpdateNfeItem = context.hasClaim('supplier-update');
    const isReadOnly = /* !(canCreateNfeItem || canUpdateNfeItem) */ true;

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (e, index) => {
        if (activeTab !== index) setActiveTab(index);
    };

    return (
        <AvForm onValidSubmit={(e, values) => {}}>
            <section className='content-middle'>
                <Tabs
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    tabs={[
                        {
                            name: intl.formatMessage(messages.itemDatasTitle),
                            component: (
                                <ItemDatasForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                        {
                            name: intl.formatMessage(
                                messages.purchaseOrderTitle,
                            ),
                            component: (
                                <PurchaseOrderForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                        {
                            name: intl.formatMessage(messages.ICMSTitle),
                            component: (
                                <ICMSForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                        {
                            name: intl.formatMessage(messages.IPITitle),
                            component: (
                                <IPIForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                        {
                            name: intl.formatMessage(messages.importTaxTitle),
                            component: (
                                <ImportTaxForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                        {
                            name: intl.formatMessage(messages.PISTitle),
                            component: (
                                <PISForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                        {
                            name: intl.formatMessage(messages.cofinsTitle),
                            component: (
                                <CofinsForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                        {
                            name: intl.formatMessage(messages.ISSQNTitle),
                            component: (
                                <ISSQNForm
                                    nfeItem={nfeItem}
                                    loading={loading}
                                    isReadOnly={isReadOnly}
                                />
                            ),
                        },
                    ]}
                />
            </section>
        </AvForm>
    );
}

export default injectIntl(NfeItemDetailsForm);
