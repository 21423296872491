import { CustomInput } from "reactstrap";
import React from "react";

const Switch = ({id, label, checked, onChange, style}) => {
    return (
        <div style={style}>
            <CustomInput
                type='switch'
                id={id}
                label={label}
                checked={checked}
                onChange={onChange}
            />
        </div>
    );
}

export default Switch