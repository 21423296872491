import React, { Component } from 'react';
import { Badge } from 'reactstrap';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './TransactionListPage.intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal, { modalTypes } from '../../components/Modal/Modal';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';

class TransactionListPage extends Component {
    static contextType = AuthContext;

    state = {
        showDraweeRiskModal: false,
        showAcceptDraweeRiskModal: false,
        draweeRiskValue: 0,
        currency: null,
        totalValue: 0,
        filters: [],
        query: '',
    };

    reloadTransactionsList = () => {
        const { query } = this.state;
        const location = this.props.location || {};
        const state = location.state || {};

        this.props.history.push('/transactions' + query);

        if (state.documentId) {
            this.props.onInitTransactionsCount(query, state.documentId);
            this.props.onInitTransactions(query, state.documentId);
        } else {
            this.props.onInitTransactionsCount(query);
            this.props.onInitTransactions(query);
        }
    };

    handleDraweeRiskSubmit = () => {
        this.setState({
            showAcceptDraweeRiskModal: !this.state.showAcceptDraweeRiskModal,
        });
    };

    handleDraweeRiskAcceptModal = () => {
        this.setState({
            showAcceptDraweeRiskModal: !this.state.showAcceptDraweeRiskModal,
        });
    };

    handleDraweeRiskAcceptSubmit = () => {
        this.setState({
            showAcceptDraweeRiskModal: !this.state.showAcceptDraweeRiskModal,
            showDraweeRiskModal: !this.state.showDraweeRiskModal,
        });
    };

    monthDiff = dateTo => {
        let dueDate = new Date(dateTo);

        const today = new Date();
        today.setDate(today.getDate() + 1);

        let diff = dueDate.getMonth() - today.getMonth();
        if (diff <= 0) {
            diff = 1;
        }

        return diff + 12 * (dueDate.getFullYear() - today.getFullYear());
    };

    handleDraweeRiskModal = () => {
        this.setState({
            showDraweeRiskModal: !this.state.showDraweeRiskModal,
        });
    };

    handleNewClick = () => {
        this.props.history.push(`/transactions/new`);
    };

    handleRefreshButtonClick = () => {
        this.reloadTransactionsList();
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
        this.props.history.push(`/transactions` + this.state.query);
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadTransactionsList();
            },
        );
    };

    handleDownloadFileClick = (e, fileId) => {
        const url = `${window.API_URI}/Transactions/${fileId}/File` || {};
        window.open(url, 'Download');
        e.stopPropagation();
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        const { intl } = this.props;

        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => {
                const { original } = rowInfo;
                if (
                    e.target.nodeName === 'BUTTON' ||
                    e.target.nodeName === 'path'
                ) {
                    const value = original.value;
                    const dueDate = original.dueDate;

                    let result = this.monthDiff(dueDate) * (1 - 0.015) * value;

                    result = result.toFixed(2).replace('.', ',');
                    const amount = value.toFixed(2).replace('.', ',');

                    this.setState({
                        showDraweeRiskModal: !this.state.showDraweeRiskModal,
                        draweeRiskValue: result,
                        currency: original.currency,
                        totalValue: amount,
                    });
                } else {
                    this.props.history.push(
                        `/transactions/${original.transactionId}`,
                    );
                }
            },

            className: !rowInfo.original.isAvailable ? 'disabled-row' : '',
            title: !rowInfo.original.isAvailable
                ? intl.formatMessage(messages.unavailableTransaction)
                : '',
        };
    };

    render() {
        const {
            intl,
            loadingList,
            transactions,
            transactionsCount,
        } = this.props;

        const { filters } = this.state;
        const newTransactionButtonEnabled = this.context.hasClaim(
            'transaction-create',
        );
        const antecipatePaymentButtonEnabled = this.context.hasClaim(
            'transaction-antecipate-payment',
        );

        const properties = [
            {
                key: 'supplierName',
                value: intl.formatMessage(messages.supplierName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'referenceCode',
                value: intl.formatMessage(messages.referenceCode),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'erpDocumentCode',
                value: intl.formatMessage(messages.erpDocumentCode),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'erpDocumentItemCode',
                value: intl.formatMessage(messages.erpDocumentItemCode),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'currency',
                value: intl.formatMessage(messages.currency),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'dueDate',
                value: intl.formatMessage(messages.dueDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'value',
                value: intl.formatMessage(messages.value),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'isPaid',
                value: 'Status',
                type: filterPropertyTypes.ENUM,
                options: [
                    {
                        key: true,
                        _map: text =>
                            text.replace(
                                intl.formatMessage(messages.paid).toLowerCase(),
                                true,
                            ),
                        value: intl.formatMessage(messages.paid),
                    },
                    {
                        key: false,
                        _map: text =>
                            text.replace(
                                intl
                                    .formatMessage(messages.pending)
                                    .toLowerCase(),
                                false,
                            ),
                        value: intl.formatMessage(messages.pending),
                    },
                ],
            },
        ];
        
        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.supplierName)}</span>
                    </div>
                ),
                accessor: 'supplierName',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    200 +
                    calculateColumnWidth(
                        transactions,
                        'supplierName',
                        intl.formatMessage(messages.supplierName),
                    ),
                minWidth:
                    70 +
                    calculateColumnWidth(
                        transactions,
                        'supplierName',
                        intl.formatMessage(messages.supplierName),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.referenceCode)}
                        </span>
                    </div>
                ),
                accessor: 'referenceCode',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    200 +
                    calculateColumnWidth(
                        transactions,
                        'referenceCode',
                        intl.formatMessage(messages.referenceCode),
                    ),
                minWidth:
                    80 +
                    calculateColumnWidth(
                        transactions,
                        'referenceCode',
                        intl.formatMessage(messages.referenceCode),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.erpDocumentCode)}
                        </span>
                    </div>
                ),
                accessor: 'erpDocumentCode',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    200 +
                    calculateColumnWidth(
                        transactions,
                        'erpDocumentCode',
                        intl.formatMessage(messages.erpDocumentCode),
                    ),
                minWidth:
                    80 +
                    calculateColumnWidth(
                        transactions,
                        'erpDocumentCode',
                        intl.formatMessage(messages.erpDocumentCode),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.erpDocumentItemCode)}
                        </span>
                    </div>
                ),
                accessor: 'erpDocumentItemCode',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    200 +
                    calculateColumnWidth(
                        transactions,
                        'erpDocumentItemCode',
                        intl.formatMessage(messages.erpDocumentItemCode),
                    ),
                minWidth:
                    80 +
                    calculateColumnWidth(
                        transactions,
                        'erpDocumentItemCode',
                        intl.formatMessage(messages.erpDocumentItemCode),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.value)}</span>
                    </div>
                ),
                accessor: 'value',
                style: {
                    fontSize: 'small',
                },
                Cell: props =>
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),
                maxWidth:
                    400 +
                    calculateColumnWidth(
                        transactions,
                        'value',
                        intl.formatMessage(messages.value),
                    ),
                minWidth:
                    80 +
                    calculateColumnWidth(
                        transactions,
                        'value',
                        intl.formatMessage(messages.value),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.currency)}</span>
                    </div>
                ),
                accessor: 'currency',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    90 +
                    calculateColumnWidth(
                        transactions,
                        'currency',
                        intl.formatMessage(messages.currency),
                    ),
                minWidth:
                    42 +
                    calculateColumnWidth(
                        transactions,
                        'currency',
                        intl.formatMessage(messages.currency),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.dueDate)}</span>
                    </div>
                ),
                accessor: 'dueDate',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    return new Date(row.value).toLocaleDateString(
                        navigator.language,
                    );
                },
                maxWidth:
                    100 +
                    calculateColumnWidth(
                        transactions,
                        'dueDate',
                        intl.formatMessage(messages.dueDate),
                    ),
                minWidth: calculateColumnWidth(
                    transactions,
                    'dueDate',
                    intl.formatMessage(messages.dueDate),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.status)}</span>
                    </div>
                ),
                accessor: 'isPaid',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    60 +
                    calculateColumnWidth(
                        transactions,
                        'isPaid',
                        intl.formatMessage(messages.status),
                    ),
                maxWidth:
                    150 +
                    calculateColumnWidth(
                        transactions,
                        'isPaid',
                        intl.formatMessage(messages.status),
                    ),
                Cell: row => {
                    const { original } = row;
                    const dueDate = new Date(original.dueDate);
                    const now = new Date();
                    const isExpired = dueDate < now;
                    
                    let color = '';
                    let status;
                    if (row.value){
                        status = intl.formatMessage(messages.paid)
                        color += 'success'
                    }
                    else {
                        if (isExpired){
                            status = intl.formatMessage(messages.overdue)
                            color += 'danger'
                        }
                        else{
                            status = intl.formatMessage(messages.pending)
                            color += 'warning'
                        }
                    }
                    return (
                        <div>
                          <Badge 
                          style={{ fontSize: '0.8rem', color: '#ffffff', display: 'inline-block', width: '73px', textAlign: 'center' }}
                          color={color} 
                          pill
                          >
                            {status}
                        </Badge>
                        </div>
                    );
                }
            },
        ];

        columns.push({
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.paymentVoucher)}</span>
                </div>
            ),
            accessor: 'paymentVoucherFileId',
            style: {
                display: 'flex',
                justifyContent: 'center',
            },

            Cell: row => {
                const { original } = row;
                const paymentVoucherFileId = original.paymentVoucherFileId;
                const transactionId = original.transactionId;

                return paymentVoucherFileId ? (
                    <span
                        title={intl.formatMessage(
                            messages.paymentVoucherFileId,
                        )}
                        onClick={
                            paymentVoucherFileId
                                ? e =>
                                      this.handleDownloadFileClick(
                                          e,
                                          transactionId,
                                      )
                                : false
                        }
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            stroke-linejoin='round'
                            color={'#444444'}
                            class='feather feather-download-cloud'
                        >
                            <polyline points='8 17 12 21 16 17' />
                            <line x1='12' y1='12' x2='12' y2='21' />
                            <path d='M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29' />
                        </svg>
                    </span>
                ) : (
                    ''
                );
            },
            minWidth:
                50 +
                calculateColumnWidth(
                    transactions,
                    '',
                    intl.formatMessage(messages.paymentVoucher),
                ),
        });

        if (antecipatePaymentButtonEnabled) {
            columns.push({
                accessor: 'isAvailable',
                Cell: row => {
                    const { original } = row;

                    const isAvailable = original.isAvailable;
                    const isPaid = original.isPaid;

                    const dueDate = new Date(original.dueDate);
                    const now = new Date();

                    const isExpired = dueDate < now;

                    return (
                        isAvailable &&
                        !isPaid &&
                        !isExpired && (
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                {intl.formatMessage(messages.anticipate)}
                            </button>
                        )
                    );
                },
                style: {
                    textAlign: 'center',
                },
                sortable: false,
                minWidth: 30,
            });
        }

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/transactions',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                this.props.transactionsCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                        {newTransactionButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleTransaction,
                                        {
                                            entity: '',
                                        },
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={transactions}
                        length={transactionsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'dueDate',
                                desc: true,
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.anticipate)}
                    isOpen={this.state.showDraweeRiskModal}
                    handleToggle={this.handleDraweeRiskModal}
                    hasFooter={true}
                    firstButtonText={intl.formatMessage(messages.cancelButton)}
                    secondButtonText={intl.formatMessage(messages.accept)}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    toggleFirstButton={this.handleDraweeRiskModal}
                    toggleSecondButton={this.handleDraweeRiskSubmit}
                >
                    {intl.formatMessage(messages.totalTransaction) +
                        this.state.totalValue +
                        ' ' +
                        this.state.currency}
                    <br />
                    {intl.formatMessage(messages.interestByMonth, {
                        value: '1,5',
                    })}
                    <br />
                    <br />
                    {intl.formatMessage(messages.anticipateAmount) +
                        this.state.draweeRiskValue +
                        ' ' +
                        this.state.currency}
                </Modal>
                <Modal
                    title={intl.formatMessage(messages.warning)}
                    isOpen={this.state.showAcceptDraweeRiskModal}
                    handleToggle={this.handleDraweeRiskSubmit}
                    hasFooter={true}
                    type={modalTypes.WARNING}
                    firstButtonText={intl.formatMessage(messages.noText)}
                    secondButtonText={intl.formatMessage(messages.yesText)}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    toggleFirstButton={this.handleDraweeRiskSubmit}
                    toggleSecondButton={this.handleDraweeRiskAcceptSubmit}
                >
                    {intl.formatMessage(messages.confirmAnticipate)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactionReducer.transactions || [],
        transactionsCount: state.transactionReducer.transactionsCount || 0,
        loadingList: state.transactionReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitTransactions: (filter, documentId) =>
            dispatch(actions.initTransactions(filter, documentId)),
        onInitTransactionsCount: (filter, documentId) =>
            dispatch(actions.initTransactionsCount(filter, documentId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TransactionListPage),
);
