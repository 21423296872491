import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as actions from '../../store/actions/index';

import { Container, Col } from 'reactstrap';
import messages from './MapItemDetailsPage.intl';

import { mapActionConditions } from '../../utils/enums';
import Modal from '../../components/Modal/Modal';
import MapActionItemForm from './MapActionItemForm/MapActionItemForm';
import Header from '../../components/Header/Header';
import MapActionActiveList from '../../components/MapActionActiveList/MapActionActiveList';
import MapActionAvailableList from '../../components/MapActionAvailableList/MapActionAvailableList';
import Loading from '../../components/Loading/Loading';
import Tabs from '../../components/Tabs/Tabs';
import FieldsDetailsForm from '../../components/FieldDetailsForm/FieldDetailsForm';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';

function MapItemDetailsPage(props) {
    const {
        intl,
        mapId,
        mapItemId,
        handleToggleModal,
        selectedMapItem,
        selectedMap,
        loadingMapItemDetails,
        loadingDetails,
        mapVersion,
        mapFieldOcr,
    } = props;

    const [conditionValue, setConditionValue] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [conditionRequired, setConditionRequired] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [draggingMapAction, setDraggingMapAction] = useState(null);

    useEffect(() => {
        props.onInitMapItemDetails(mapId, mapItemId);
    }, []);

    function handleNewMapActionClick(e, selectedType) {
        setIsActionModalOpen(true);
        setSelectedAction({
            type: selectedType,
            parameters: [],
        });
    }

    function handleActionFormSubmit(e, values) {
        if (
            values.condition.type === '' ||
            parseInt(values.condition.type) === 0
        ) {
            values.condition = null;
        }

        if (values.mapActionId === '') {
            const order = selectedMapItem.mapActions.length + 1;

            values.mapActionId = Math.random()
                .toString(36)
                .substr(2, 9);
            values.order = order;

            props.onSaveMapAction(values);
        } else {
            const mapActionFound = selectedMapItem.mapActions.find(
                ma => ma.mapActionId === values.mapActionId,
            );

            values.order = mapActionFound.order;

            props.onUpdateMapAction(values);
        }
        setIsActionModalOpen(false);
        setSelectedAction(null);
        setConditionRequired(false);
        setConditionValue('');
    }

    function handleActionModalCancelButtonClick(e) {
        e.preventDefault();

        setIsActionModalOpen(false);
        setSelectedAction(null);
        setConditionRequired(false);
        setConditionValue('');
    }

    function handleEditActionClick(e, selectedAction) {
        setSelectedAction(selectedAction);
        setIsActionModalOpen(true);
    }

    function handleDeleteAction(e, selectedAction) {
        props.onDeleteMapAction(selectedAction.mapActionId);
    }

    function onConditionChange(e) {
        const condition = e.target.value;

        switch (Number(condition)) {
            case mapActionConditions.REGEX:
                setConditionRequired(true);
                setConditionValue('Expression');
                break;
            case mapActionConditions.MIN_LENGTH:
                setConditionRequired(true);
                setConditionValue('MinLength');
                break;
            case mapActionConditions.MAX_LENGTH:
                setConditionRequired(true);
                setConditionValue('MaxLength');
                break;
            default:
                setConditionRequired(false);
                setConditionValue('');
        }
    }

    function handleTabClick(e, index) {
        activeTab !== index && setActiveTab(index);
    }

    function handleFieldFormSubmit(event, values) {
        let updateMapItem = selectedMapItem;

        if (values) {
            updateMapItem = {
                ...selectedMapItem,
                field: { ...values },
            };
        }

        props.onUpdateMapItem(mapId, mapItemId, updateMapItem);
        handleToggleModal();
    }

    function handleProcessFieldOcr(values) {
        props.processMapFieldOcr(mapId, values);
    }

    function handleFieldTypeChange(e) {
        props.onFieldTypeChange(e.target.value);
    }

    function handleCopyItem() {
        let newMapItem = selectedMapItem;

        delete newMapItem.mapItemId;
        newMapItem.boundingBox.x += 10;
        newMapItem.boundingBox.y += 10;

        props.onCopyMapItem(mapId, newMapItem);
        handleToggleModal();
    }

    function handleDragStart(e, mapActionId, index) {
        const target = e.target;
        target.style.backgroundColor = '#d9eeec';
        target.style.cursor = 'move';

        setDraggingMapAction({ mapActionId, index });
    }

    function handleDragEnd(e) {
        const target = e.target;
        target.style.backgroundColor = '';
        target.style.cursor = 'default';

        setDraggingMapAction(null);
    }

    function handleDragOver(e, mapActionId, index) {
        const { mapActions } = selectedMapItem;

        if (mapActionId === draggingMapAction.mapActionId) return;

        const mapAction = mapActions.find(
            ma => ma.mapActionId === draggingMapAction.mapActionId,
        );
        const currentMapAction = mapActions.find(
            ma => ma.mapActionId === mapActionId,
        );

        const newMapActions = [
            ...mapActions.filter(
                ma =>
                    ma.mapActionId !== draggingMapAction.mapActionId &&
                    ma.mapActionId !== mapActionId,
            ),
            {
                ...mapAction,
                order: currentMapAction.order,
            },
            {
                ...currentMapAction,
                order: mapAction.order,
            },
        ];

        props.onSaveMapActions(newMapActions);
    }
    const sortedMapActions =
        selectedMapItem &&
        selectedMapItem.mapActions.sort((a, b) => {
            return a.order - b.order;
        });

    return (
        <Container fluid style={{ marginBottom: 0 }}>
            <header>
                <section className='title'>
                    <Header
                        title={intl.formatMessage(messages.titleMapItem, {
                            entity: '',
                        })}
                        subtitle={`(${mapItemId})`}
                    />
                </section>
            </header>
            <section className='content-middle bg-content'>
                <Loading loading={loadingMapItemDetails} />

                <Tabs
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    tabs={[
                        {
                            name: intl.formatMessage(messages.fields),
                            component: selectedMapItem && (
                                <AvForm onValidSubmit={handleFieldFormSubmit}>
                                    <FieldsDetailsForm
                                        key={selectedMapItem.field.fieldId}
                                        field={selectedMapItem.field}
                                        selectedMap={selectedMap}
                                        mapFieldType={
                                            selectedMapItem.mapFieldType
                                        }
                                        loading={loadingDetails}
                                        handleFieldTypeChange={
                                            handleFieldTypeChange
                                        }
                                        copyItem={handleCopyItem}
                                        mapVersion={mapVersion}
                                        mapFieldOcr={mapFieldOcr}
                                        handleProcessFieldOcr={
                                            handleProcessFieldOcr
                                        }
                                    />
                                </AvForm>
                            ),
                        },
                        {
                            name: intl.formatMessage(messages.actions, {
                                entity: '',
                            }),
                            component: (
                                <section className='content-middle row'>
                                    <Col xs={12} md={6}>
                                        <h3 className='text-center'>
                                            {' '}
                                            {intl.formatMessage(
                                                messages.available,
                                            )}
                                        </h3>
                                        <br />
                                        <MapActionAvailableList
                                            handleNewMapActionClick={
                                                handleNewMapActionClick
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <h3 className='text-center'>
                                            {intl.formatMessage(
                                                messages.active,
                                            )}
                                        </h3>
                                        <br />

                                        <MapActionActiveList
                                            mapActions={sortedMapActions}
                                            handleEditActionClick={
                                                handleEditActionClick
                                            }
                                            handleDeleteAction={
                                                handleDeleteAction
                                            }
                                            handleDragStart={handleDragStart}
                                            handleDragEnd={handleDragEnd}
                                            handleDragOver={handleDragOver}
                                        />
                                    </Col>

                                    <Col
                                        xs={12}
                                        md={{ size: 'auto', offset: 8 }}
                                    >
                                        <button
                                            style={{
                                                float: 'right',
                                                marginLeft: '15px',
                                            }}
                                            type='button'
                                            className='btn-submit'
                                            onClick={handleFieldFormSubmit}
                                        >
                                            {intl.formatMessage(
                                                messages.submitButton,
                                            )}
                                        </button>
                                        <button
                                            type='button'
                                            onClick={handleCopyItem}
                                        >
                                            {intl.formatMessage(messages.copy)}
                                        </button>
                                    </Col>
                                </section>
                            ),
                        },
                    ]}
                />
            </section>
            <Modal noHeader={true} isOpen={isActionModalOpen}>
                {selectedAction && (
                    <MapActionItemForm
                        selectedAction={selectedAction}
                        handleFormSubmit={handleActionFormSubmit}
                        onConditionChange={onConditionChange}
                        conditionRequired={conditionRequired}
                        conditionValue={conditionValue}
                        handleActionModalCancelButtonClick={
                            handleActionModalCancelButtonClick
                        }
                    />
                )}
            </Modal>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        selectedMap: state.mapReducer.selectedMap,
        mapFieldOcr: state.mapReducer.mapFieldOcr,
        selectedMapItem: state.mapReducer.selectedMapItem,
        loadingMapItemDetails: state.mapReducer.loadingMapItemDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSaveMapAction: values => dispatch(actions.saveMapAction(values)),
        onSaveMapActions: mapActions =>
            dispatch(actions.saveMapActions(mapActions)),
        onDeleteMapAction: mapActionId =>
            dispatch(actions.deleteMapAction(mapActionId)),
        onUpdateMapItem: (mapId, mapItemId, values) =>
            dispatch(actions.updateMapItemDetails(mapId, mapItemId, values)),
        onInitMapItemDetails: (mapId, mapItemId) =>
            dispatch(actions.initMapItemDetails(mapId, mapItemId)),
        onFieldTypeChange: type => dispatch(actions.setFieldTypePattern(type)),
        onCopyMapItem: (mapId, newMapItem) =>
            dispatch(actions.copyMapItem(mapId, newMapItem)),
        onUpdateMapAction: values => dispatch(actions.updateMapAction(values)),
        processMapFieldOcr: (mapId, values) =>
            dispatch(actions.processMapFieldOcr(mapId, values)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(MapItemDetailsPage));
