import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'transactions.title',
        defaultMessage: 'Pagamentos',
    },

    titleTransaction: {
        id: 'transactions.titleTransaction',
        defaultMessage: 'Pagamento {entity}',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
