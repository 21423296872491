import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';
import messages from './PendingFilesDetailsPage.intl';

import Loading from '../../components/Loading/Loading';
import DetailsHeader from '../../components/DetailsHeader/DetailsHeader';
import PendingFileForm from './components/PendingFileForm';

function PendingFileDetailsPage(props) {
    const { intl, pendingFile, loadingDetails } = props;
    const params = useParams();
    const pendingFileId = params.id;

    useEffect(() => {
        props.onInitPendingFilesDetails(pendingFileId);
    }, []);

    const handleFormSubmit = (e, values) => {
        const payload = {
            supplierId: values.supplierId,
            mapId: values.mapId,
        };

        props.onCreateDocumentByPendingFile(pendingFile?.id, payload);
    };

    const handleRejectPendingFile = () => {
        props.onRejectPendingFile(pendingFile?.id);
    };

    return (
        <Container fluid>
            <DetailsHeader
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/pendingFiles',
                        name: intl.formatMessage(messages.pendingFiles),
                        active: false,
                    },
                    {
                        path: '/pendingFiles/:id',
                        name:
                            intl.formatMessage(messages.pendingFiles) +
                            ` - ${pendingFileId}`,
                        active: true,
                    },
                ]}
                headerTitle={intl.formatMessage(messages.pendingFiles)}
                headerClassName='row'
            />
            <Loading loading={loadingDetails} />
            <PendingFileForm
                handleFormSubmit={handleFormSubmit}
                handleRejectPendingFile={handleRejectPendingFile}
                loading={loadingDetails}
                pendingFile={pendingFile}
                readOnly={false}
            />
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        pendingFile: state.pendingFilesReducer.pendingFile,
        loadingDetails: state.pendingFilesReducer.loadingDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitPendingFilesDetails: fileId =>
            dispatch(actions.initPendingFilesDetails(fileId)),
        onCreateDocumentByPendingFile: (pendingFileId, payload) =>
            dispatch(
                actions.createDocumentByPendingFile(pendingFileId, payload),
            ),
        onRejectPendingFile: (pendingFileId, payload) =>
            dispatch(actions.rejectPendingFile(pendingFileId, payload)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(PendingFileDetailsPage),
);
