import React from 'react';
import messages from '../RoleListPage.intl';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import { filterPropertyTypes } from '../../../utils/enums';
import Axios from 'axios';

export const rolesColumns = (intl, roles) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.name)}</span>
            </div>
        ),
        accessor: 'name',
        style: {
            fontSize: 'small',
        },
        minWidth:
            200 +
            calculateColumnWidth(
                roles,
                'name',
                intl.formatMessage(messages.name),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.company)}</span>
            </div>
        ),
        accessor: 'contractorName',
        style: {
            fontSize: 'small',
        },
        minWidth:
            200 +
            calculateColumnWidth(
                roles,
                'contractorName',
                intl.formatMessage(messages.company),
            ),
    },
];

export const rolesProperties = intl => [
    {
        key: 'name',
        value: intl.formatMessage(messages.name),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'contractorId',
        value: intl.formatMessage(messages.company),
        type: filterPropertyTypes.SELECT,
        getDetails: (setSelectItem, filterValue) => {
            Axios.get(`/Contractors/${filterValue}`)
                .then(response => {
                    setSelectItem({
                        label: response.data.displayName,
                        value: filterValue,
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        urlToRequest: `/Contractors`,
        labelKey: 'displayName',
    },
    {
        key: 'contractorName',
        value: intl.formatMessage(messages.companyName),
        type: filterPropertyTypes.TEXT,
    },
];
