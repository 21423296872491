import React, { Component } from 'react';

import { Layer, Stage, Image, Rect, Transformer } from 'react-konva';

class ImageCropper extends Component {
    state = {
        width: 0,
        height: 0,
        box: null,
    };

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.src !== prevProps.src) {
            this.loadImage();
        }
    }

    loadImage = () => {
        const image = new window.Image();

        image.onload = () => {
            if (this.imageNode) {
                const box = {
                    key: 'cropBox',
                    x: 0,
                    y: 0,
                    width: image.width,
                    height: image.height,
                };

                this.setState(
                    {
                        box,
                        width: image.width,
                        height: image.height,
                    },
                    () => {
                        this.props.handleCropChange && this.props.handleCropChange(box);

                        this.imageNode.image(image);
                        this.stageNode.getStage().batchDraw();

                        const stage = this.transformerNode.getStage();
                        const selectedRect = stage.findOne('.cropBox');

                        if (selectedRect === this.transformerNode.node()) {
                            return;
                        }

                        if (selectedRect) {
                            this.transformerNode.attachTo(selectedRect);
                        } else {
                            this.transformerNode.detach();
                        }

                        this.transformerNode.getLayer().batchDraw();
                    },
                );
            }
        };

        image.src = this.props.src;
    };

    handleStageMouseDown = e => {
        const { box } = this.state;

        if (e.target.className === 'Rect') {
            if (box) {
                const stage = this.transformerNode.getStage();
                const selectedRect = stage.findOne('.' + box.key);

                if (selectedRect === this.transformerNode.node()) {
                    return;
                }

                if (selectedRect) {
                    this.transformerNode.attachTo(selectedRect);
                } else {
                    this.transformerNode.detach();
                }

                this.transformerNode.getLayer().batchDraw();
            }
        }
    };

    handleRectChange = e => {

        const imageHeight = this.imageNode.height();
        const imageWidth = this.imageNode.width();

        const rect = e.target;

        const scaleX = rect.scaleX();
        const scaleY = rect.scaleY();

        const x = rect.x();
        const y = rect.y();

        const width = rect.width() * scaleX;
        const height = rect.height() * scaleY;
        
        const box = {
            ...this.state.box,
            x: x < 0 ? 0 : Math.round(x),
            y: y < 0 ? 0 : Math.round(y),
            width: width > imageWidth ? Math.round(imageWidth): Math.round(width),
            height: height > imageHeight  ? Math.round(imageHeight) : Math.round(height),
        };

        rect.x(box.x);
        rect.y(box.y);

        rect.scaleX(1);
        rect.scaleY(1);

        this.setState(
            {
                box,
            },
            () => {
                this.props.handleCropChange && this.props.handleCropChange(box);
            },
        );
    };

    render() {
        const { width, height, box } = this.state;
        return (
            <div
                ref={node => (this.divNode = node)}
                style={{
                    width: '100%',
                    overflow: 'auto',
                }}
            >
                <Stage
                    ref={node => (this.stageNode = node)}
                    width={width +30}
                    height={height +30}
                    onMouseDown={this.handleStageMouseDown}
                    onMouseMove={this.handleMouseMove}     
                    offsetX = {-15}       
                    offsetY = {-15}             
                >
                    <Layer>
                        <Image ref={node => (this.imageNode = node)} />
                        {box && !this.props.hideCrop &&(
                            <Rect
                                name={box.key}
                                key={box.key}
                                x={box.x}
                                y={box.y}
                                width={box.width}
                                height={box.height}
                                fill='blue'
                                opacity={0.1}
                                stroke='black'
                                dash={[20, 10]}
                                onDragEnd={this.handleRectChange}
                                onTransformEnd={this.handleRectChange}
                                draggable={false}
                            />
                        )}
                        <Transformer
                            ref={node => (this.transformerNode = node)}
                            rotateEnabled={false}
                        />
                    </Layer>
                </Stage>
            </div>
        );
    }
}

export default ImageCropper;
