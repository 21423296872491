import React from 'react';

const PortalFileIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M19.76 20.92H14'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M17.84 19L19.76 20.92L17.84 22.84'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M19 16L19.0029 7.82626C19.0029 7.29565 18.7919 6.78681 18.4167 6.41167L15.5875 3.58249C15.2123 3.20724 14.7035 2.99625 14.1729 2.99625H6.9979C5.89287 2.99625 4.99707 3.89205 4.99707 4.99708V19.0029C4.99707 20.1079 5.89287 21.0037 6.9979 21.0037H10.5'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default PortalFileIcon;
