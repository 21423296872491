import * as actionTypes from '../actions/actionTypes';

const initialState = {
    orders: [],
    order: null,
    documents: [],
    documentsCount: [],
    loadingOrderDocuments: false,
    loadingDetails: true,
    loadingList: true,
    orderItems: [],
    orderItemsCount: 0,
    orderItemsBySupplier: [],
    orderItemsBySupplierCount: 0,
    listOnlyOrderFromTheDocumentTakerForAssociation: false,
    orderItem: null,
    loadingItemsDetails: true,
    loadingItemsList: true,
    loadingItemsBySupplierList: true,

    tabIndex: 0,
};

const setOrders = (state, action) => {
    return {
        ...state,
        orders: action.orders,
        loadingList: false,
    };
};

const setOrdersCount = (state, action) => {
    return {
        ...state,
        ordersCount: action.ordersCount,
    };
};

const setOrdersRequest = (state, action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setOrderDetails = (state, action) => {
    return {
        ...state,
        order: action.order,
        loadingDetails: false,
    };
};

const setOrderDetailsPost = (state, action) => {
    return {
        ...state,
        order: action.order,
        loadingDetails: false,
        orders: [],
    };
};

const setOrderLoadingFalse = (state, action) => {
    return {
        ...state,
        loadingDetails: false,
    };
};

const setOrderLoadingTrue = (state, action) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setOrderDetailsNew = (state, action) => {
    return {
        ...state,
        order: undefined,
        loadingDetails: false,
    };
};

const setOrderDetailsRequest = (state, action) => {
    return {
        ...state,
        order: undefined,
        loadingDetails: true,
    };
};

const setOrderDocumentsRequest = (state, action) => {
    return {
        ...state,
        loadingOrderDocuments: true,
    };
};

const setOrderDocuments = (state, action) => {
    return {
        ...state,
        loadingOrderDocuments: false,
        documents: action.documents || [],
    };
};

const setOrderDocumentsCount = (state, action) => {
    return {
        ...state,
        documentsCount: action.documentsCount,
    };
};

//OrderItem

const setOrderItemsRequest = (state, action) => {
    return {
        ...state,
        loadingItemsList: true,
    };
};

const setOrderItemsBySupplierRequest = (state, action) => {
    return {
        ...state,
        loadingItemsBySupplierList: true,
    };
};

const setOrderItemLoadingTrue = (state, _action) => {
    return {
        ...state,
        loadingItemDetails: true,
    };
};

const setOrderItemLoadingError = (state, { error }) => {
    return {
        ...state,
        error: error || '',
        loadingItemDetails: false,
    };
};

const setOrderItems = (state, { orderItems }) => {
    return {
        ...state,
        orderItems,
        loadingItemsList: false,
    };
};

const setOrderItemsCount = (state, { orderItemsCount }) => {
    return {
        ...state,
        orderItemsCount,
    };
};

const setOrderItemsBySupplier = (
    state,
    {
        orderItemsBySupplier,
        orderItemsBySupplierCount,
        listOnlyOrderFromTheDocumentTakerForAssociation,
    },
) => {
    return {
        ...state,
        orderItemsBySupplier,
        orderItemsBySupplierCount,
        listOnlyOrderFromTheDocumentTakerForAssociation,
        loadingItemsBySupplierList: false,
    };
};

const setOrderItemDetailsNew = (state, action) => {
    return {
        ...state,
        orderItem: undefined,
        loadingItemDetails: false,
    };
};

const setOrderItemDetails = (state, { orderItem }) => {
    return {
        ...state,
        orderItem,
        loadingItemDetails: false,
    };
};

const setOrderItemDetailsRequest = (state, action) => {
    return {
        ...state,
        orderItem: null,
        loadingItemDetails: true,
    };
};

const setTabOrderIndex = (state, { tabIndex }) => {
    return {
        ...state,
        tabIndex,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //ORDERS
        case actionTypes.ORDERS_GET_LIST_REQUEST:
            return setOrdersRequest(state, action);

        case actionTypes.ORDERS_GET_LIST_FAILURE:
        case actionTypes.ORDERS_GET_LIST_SUCCESS:
            return setOrders(state, action);

        case actionTypes.ORDER_PUT_SUCCESS:
        case actionTypes.ORDER_GET_DETAILS_FAILURE:
        case actionTypes.ORDER_GET_DETAILS_SUCCESS:
            return setOrderDetails(state, action);

        case actionTypes.ORDER_POST_SUCCESS:
        case actionTypes.ORDER_POST_FAILURE:
            return setOrderDetailsPost(state, action);

        case actionTypes.ORDER_PUT_REQUEST:
            return setOrderLoadingTrue(state, action);

        case actionTypes.ORDER_PUT_FAILURE:
            return setOrderLoadingFalse(state, action);

        case actionTypes.ORDER_POST_REQUEST:
        case actionTypes.ORDER_GET_DETAILS_REQUEST:
            return setOrderDetailsRequest(state, action);

        case actionTypes.ORDER_DOCUMENTS_GET_DETAILS_REQUEST:
            return setOrderDocumentsRequest(state, action);

        case actionTypes.ORDER_DOCUMENTS_GET_DETAILS_SUCCESS:
        case actionTypes.ORDER_DOCUMENTS_GET_DETAILS_FAILURE:
            return setOrderDocuments(state, action);

        case actionTypes.ORDER_DOCUMENTS_GET_COUNT_SUCCESS:
            return setOrderDocumentsCount(state, action);

        case actionTypes.ORDER_GET_DETAILS_NEW:
            return setOrderDetailsNew(state, action);

        case actionTypes.ORDERS_GET_COUNT_SUCCESS:
            return setOrdersCount(state, action);

        //OrderItems
        case actionTypes.ORDER_ITEM_PUT_REQUEST:
        case actionTypes.ORDER_ITEM_POST_REQUEST:
        case actionTypes.ORDER_ITEM_DELETE_REQUEST:
            return setOrderItemLoadingTrue(state, action);

        case actionTypes.ORDER_ITEM_PUT_FAILURE:
        case actionTypes.ORDER_ITEM_DELETE_FAILURE:
        case actionTypes.ORDER_ITEM_POST_FAILURE:
        case actionTypes.ORDER_ITEM_GET_DETAILS_FAILURE:
        case actionTypes.ORDERS_ITEM_GET_LIST_FAILURE:
        case actionTypes.ORDERS_ITEM_GET_COUNT_FAILURE:
            return setOrderItemLoadingError(state, action);

        //DETAILS,POST,PUT
        case actionTypes.ORDER_ITEM_GET_DETAILS_SUCCESS:
        case actionTypes.ORDER_ITEM_POST_SUCCESS:
        case actionTypes.ORDER_ITEM_PUT_SUCCESS:
            return setOrderItemDetails(state, action);

        case actionTypes.ORDER_ITEM_GET_DETAILS_NEW:
            return setOrderItemDetailsNew(state, action);

        //LIST
        case actionTypes.ORDERS_BY_SUPPLIER_GET_LIST_REQUEST:
            return setOrderItemsBySupplierRequest(state, action);

        case actionTypes.ORDERS_ITEM_GET_LIST_REQUEST:
        case actionTypes.ORDERS_ITEM_GET_COUNT_REQUEST:
            return setOrderItemsRequest(state, action);

        case actionTypes.ORDERS_ITEM_GET_LIST_SUCCESS:
            return setOrderItems(state, action);

        case actionTypes.ORDERS_ITEM_GET_COUNT_SUCCESS:
            return setOrderItemsCount(state, action);

        case actionTypes.ORDERS_BY_SUPPLIER_GET_LIST_SUCCESS:
            return setOrderItemsBySupplier(state, action);

        case actionTypes.ORDER_ITEM_GET_DETAILS_REQUEST:
            return setOrderItemDetailsRequest(state, action);

        //DELETE
        case actionTypes.ORDER_ITEM_DELETE_SUCCESS:
            return setOrderItemDetails(state, action);

        //SET TAB INDEX
        case actionTypes.SET_TAB_ORDER_INDEX:
            return setTabOrderIndex(state, action);

        default:
            return state;
    }
};

export default reducer;
