import React from 'react';
import { Badge } from 'reactstrap';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import { Operators } from '../../../models/FilterBuilder';
import {
    filterPropertyTypes,
    sources,
    statusDescription,
} from '../../../utils/enums';
import { sourceComponent } from '../../DocumentListPage/components/Columns';
import messages from '../CteListPage.intl';

export const columnsCte = (documents, intl) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.titleSource)}</span>
            </div>
        ),
        accessor: 'source',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return sourceComponent(row);
        },
        maxWidth: 75,
        minWidth: 75,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.invoiceNumber)}</span>
            </div>
        ),
        accessor: 'secundaryDocumentNumber',
        style: {
            fontSize: 'small',
        },
        sortMethod: (a, b) => {
            const A = Number(a) || Number.MAX_SAFE_INTEGER;
            const B = Number(b) || Number.MAX_SAFE_INTEGER;
            return A - B;
        },
        Cell: row => <p>{row.value}</p>,
        minWidth:
            30 +
            calculateColumnWidth(
                documents,
                'secundaryDocumentNumber',
                intl.formatMessage(messages.invoiceNumber),
            ),
        maxWidth:
            70 +
            calculateColumnWidth(
                documents,
                'secundaryDocumentNumber',
                intl.formatMessage(messages.invoiceNumber),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.accessKey)}</span>
            </div>
        ),
        accessor: 'documentNumber',
        style: {
            fontSize: 'small',
        },
        sortMethod: (a, b) => {
            const A = Number(a) || Number.MAX_SAFE_INTEGER;
            const B = Number(b) || Number.MAX_SAFE_INTEGER;
            return A - B;
        },
        Cell: row => <p>{row.value}</p>,
        minWidth:
            80 +
            calculateColumnWidth(
                documents,
                'documentNumber',
                intl.formatMessage(messages.accessKey),
            ),
        maxWidth:
            600 +
            calculateColumnWidth(
                documents,
                'documentNumber',
                intl.formatMessage(messages.accessKey),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.issuer)}</span>
            </div>
        ),
        accessor: 'supplierName',
        style: {
            fontSize: 'small',
        },
        sortMethod: (a, b) => {
            const A = Number(a) || Number.MAX_SAFE_INTEGER;
            const B = Number(b) || Number.MAX_SAFE_INTEGER;
            return A - B;
        },
        Cell: row => <p>{row.value}</p>,
        minWidth:
            40 +
            calculateColumnWidth(
                documents,
                'supplierName',
                intl.formatMessage(messages.issuer),
            ),
        maxWidth:
            600 +
            calculateColumnWidth(
                documents,
                'supplierName',
                intl.formatMessage(messages.issuer),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.sender)}</span>
            </div>
        ),
        accessor: 'senderName',
        style: {
            fontSize: 'small',
        },
        sortMethod: (a, b) => {
            const A = Number(a) || Number.MAX_SAFE_INTEGER;
            const B = Number(b) || Number.MAX_SAFE_INTEGER;
            return A - B;
        },
        Cell: row => <p>{row.value}</p>,
        minWidth:
            30 +
            calculateColumnWidth(
                documents,
                'senderName',
                intl.formatMessage(messages.sender),
            ),
        maxWidth:
            150 +
            calculateColumnWidth(
                documents,
                'senderName',
                intl.formatMessage(messages.sender),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.recipient)}</span>
            </div>
        ),
        accessor: 'contractorName',
        style: {
            fontSize: 'small',
        },
        sortMethod: (a, b) => {
            const A = Number(a) || Number.MAX_SAFE_INTEGER;
            const B = Number(b) || Number.MAX_SAFE_INTEGER;
            return A - B;
        },
        Cell: row => <p>{row.value}</p>,
        minWidth:
            20 +
            calculateColumnWidth(
                documents,
                'contractorName',
                intl.formatMessage(messages.recipient),
            ),
        maxWidth:
            300 +
            calculateColumnWidth(
                documents,
                'contractorName',
                intl.formatMessage(messages.recipient),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.emissionDate)}</span>
            </div>
        ),
        accessor: 'emissionDate',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>
                        {date.toUpperCase() !== 'INVALID DATE'
                            ? date
                            : row.value}
                    </div>
                </div>
            );
        },
        minWidth:
            20 +
            calculateColumnWidth(
                documents,
                'emissionDate',
                intl.formatMessage(messages.emissionDate),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.integratedAtDocument)}</span>
            </div>
        ),
        accessor: 'createdAt',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        minWidth:
            40 +
            calculateColumnWidth(
                documents,
                'createdAt',
                intl.formatMessage(messages.integratedAtDocument),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        id: 'status',
        style: {
            fontSize: 'small',
        },
        sortMethod: (a, b) => {
            const preference = {
                'status-invoice status-success': 1,
                'status-invoice status-pending': 2,
                'status-invoice status-error': 3,
            };

            const A =
                preference[a.props.className.toLowerCase()] ||
                Number.MAX_SAFE_INTEGER;
            const B =
                preference[b.props.className.toLowerCase()] ||
                Number.MAX_SAFE_INTEGER;

            return A - B;
        },
        accessor: d => {
            let color = '';

            if (d.status.toLowerCase() === 'pending') {
                color += 'warning';
            } else if (d.status.toLowerCase() === 'success') {
                color += 'success';
            } else if (d.status.toLowerCase() === 'error') {
                color += 'danger';
            }

            var message = d.statusDescription
                .split('-')[0]
                .split(':')[0]
                .trim();

            return (
                <Badge
                    style={{ fontSize: '0.8rem', color: '#ffffff' }}
                    color={color}
                    pill
                >
                    {message}
                </Badge>
            );
        },
        minWidth:
            200 +
            calculateColumnWidth(
                documents,
                'statusDescription',
                intl.formatMessage(messages.status),
            ),
    },
];

export const cteProperties = intl => {
    const filtersProperties = [];

    filtersProperties.push(
        {
            key: 'contractorName',
            value: intl.formatMessage(messages.recipient),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'contractorCNPJ',
            value: intl.formatMessage(messages.recipient) + ' CNPJ',
            type: filterPropertyTypes.TEXT,
            lg: 6,
            maskValue: value => value.replace(/[^\d]+/g, ''),
        },
        {
            key: 'supplierName',
            value: intl.formatMessage(messages.issuer),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'supplierCNPJ',
            value: intl.formatMessage(messages.issuer) + ' CNPJ',
            type: filterPropertyTypes.TEXT,
            lg: 6,
            maskValue: value => value.replace(/[^\d]+/g, ''),
        },
        {
            key: 'senderName',
            value: intl.formatMessage(messages.sender),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'senderCNPJ',
            value: intl.formatMessage(messages.sender) + ' CNPJ',
            type: filterPropertyTypes.TEXT,
            lg: 6,
            maskValue: value => value.replace(/[^\d]+/g, ''),
        },
        {
            key: 'secundaryDocumentNumber',
            value: intl.formatMessage(messages.cteNumber),
            type: filterPropertyTypes.TEXT,
            lg: 4,
        },
        {
            key: 'documentNumber',
            value: intl.formatMessage(messages.accessKey),
            type: filterPropertyTypes.NUMBER,
            lg: 4,
        },
        {
            key: 'city',
            value: intl.formatMessage(messages.city),
            type: filterPropertyTypes.TEXT,
            lg: 4,
        },
        {
            key: 'source',
            value: intl.formatMessage(messages.titleSource),
            type: filterPropertyTypes.ENUM,
            options: [
                {
                    key: sources.portal,
                    value: 'Portal',
                },
                {
                    key: sources.email,
                    value: 'Email',
                },
                {
                    key: sources.webService,
                    value: 'Integração',
                },
            ],
            lg: 4,
            operator: Operators.like,
        },
        {
            key: 'statusDescription',
            value: 'Status',
            type: filterPropertyTypes.ENUM,
            options: Object.values(statusDescription).map(s => {
                return {
                    key: s,
                    value: s,
                };
            }),
            lg: 4,
            operator: Operators.like,
        },
        {
            key: 'createdAt',
            value: intl.formatMessage(messages.createdAt),
            type: filterPropertyTypes.DATE,
        },
    );

    return filtersProperties;
};
