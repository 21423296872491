import React from 'react';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from '../ReportListPage.intl';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import { filterPropertyTypes } from '../../../utils/enums';
import DropdownListButton from '../../../components/DropdownListButton/DropdownListButton';

export const generatedReportsColumns = (
    intl,
    generatedReports,
    handleDownloadFile,
) => {
    const enumStatus = {
        pending: 'Pending',
        success: 'Success',
    };
    return [
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.name)}</span>
                </div>
            ),
            accessor: 'name',
            style: {
                fontSize: 'small',
            },
            minWidth:
                40 +
                calculateColumnWidth(
                    generatedReports,
                    'name',
                    intl.formatMessage(messages.name),
                ),
            maxWidth: 500
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.user)}</span>
                </div>
            ),
            accessor: 'userName',
            style: {
                fontSize: 'small',
            },
            minWidth:
                20 +
                calculateColumnWidth(
                    generatedReports,
                    'userName',
                    intl.formatMessage(messages.user),
                ),
            maxWidth: 350
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.createdAt)}</span>
                </div>
            ),
            accessor: 'createdAt',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                const date = new Date(row.value).toLocaleString(
                    navigator.language,
                );
                return (
                    <div className='customer-container'>
                        <div className=''>{date}</div>
                    </div>
                );
            },
            maxWidth:
                220 +
                calculateColumnWidth(
                    generatedReports,
                    'createdAt',
                    intl.formatMessage(messages.createdAt),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.status)}</span>
                </div>
            ),
            accessor: 'status',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                let statusValue;
                let color = '';
                let message = '';
                let width = '';
                switch (row.value.toUpperCase()) {
                    case enumStatus.pending.toUpperCase():
                        color += 'warning';
                        width = '143px'
                        statusValue = (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {intl.formatMessage(messages.inProcess)}
                                <FontAwesomeIcon
                                    style={{ margin: '5' }}
                                    icon='spinner'
                                    size='1,5x'
                                    pulse
                                />
                            </div>
                        );
                        break;
                    case enumStatus.success.toUpperCase():
                        statusValue = intl.formatMessage(messages.success);
                        color += 'success'
                        width = '86px'
                        break;
                    default:
                        statusValue = intl.formatMessage(messages.fail);
                        color += 'danger'
                        width = '86px'
                        message = <p>Falha ao gerar relatório</p>
                        break;
                }
                return (
                    <div>
                    <Badge style={{ fontSize: '0.8rem', color: '#ffffff', display: 'inline-block', width: width, textAlign: 'center' }} 
                    color={color} 
                    pill
                    >
                        {statusValue}
                    </Badge>
                        {message}
                    </div>
                );
            },
            maxWidth: 220,
            minWidth: 165
                 
        },
        {
            accessor: 'reportId',
            style: {
                display: 'flex',
                justifyContent: 'center',
                fontSize: 'small',
            },
            Cell: row => {
                let downloadButton = <div />;

                const items = [
                    {
                        name: intl.formatMessage(messages.downloadLink),
                        onClick: () => handleDownloadFile(row.value),
                    },
                ];

                switch (row.original.status.toUpperCase()) {
                    case enumStatus.pending.toUpperCase():
                        downloadButton = <div />;
                        break;

                    case enumStatus.success.toUpperCase():
                        downloadButton = <DropdownListButton items={items}/>;
                        break;
                }
                return <>{downloadButton}</>;
            },
            sortable: false,
            maxWidth: 50,
            style: {
                display: 'flex',
                justifyContent: 'center',
                fontSize: 'small',
            },
        },
    ];
};

export const generatedReportsProperties = intl => [
    {
        key: 'name',
        value: intl.formatMessage(messages.name),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'userName',
        value: intl.formatMessage(messages.user),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'createdAt',
        value: intl.formatMessage(messages.createdAt),
        type: filterPropertyTypes.DATE,
    },
    {
        key: 'status',
        value: intl.formatMessage(messages.status),
        type: filterPropertyTypes.ENUM,
        options: [
            {
                key: 'success',
                value: intl.formatMessage(messages.success),
            },
            {
                key: 'fail',
                value: intl.formatMessage(messages.fail),
            },
            {
                key: 'pending',
                value: intl.formatMessage(messages.inProcess),
            },
        ],
    },
];

//Reports
export const reportsColumns = (intl, reports, handleClickButtonGenerate) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.name)}</span>
            </div>
        ),
        accessor: 'reportName',
        style: {
            fontSize: 'small',
        },
        minWidth:
            40 +
            calculateColumnWidth(
                reports,
                'reportName',
                intl.formatMessage(messages.name),
            ),
    },
    {
        accessor: 'filters',
        style: {
            display: 'flex',
            fontSize: 'small',
        },
        Cell: row => {
            const items = [
                {
                    name: intl.formatMessage(messages.generate),
                    onClick: () => handleClickButtonGenerate(row.original),
                },
            ];
            return <DropdownListButton items={items} />;
        },
        sortable: false,
        maxWidth: 80,
    },
];

export const reportsProperties = intl => [
    {
        key: 'name',
        value: intl.formatMessage(messages.name),
        type: filterPropertyTypes.TEXT,
    },
];
