import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const ItemDatasForm = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const { prod } = nfeItem;

    return (
        <>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cProd)}
                            type='text'
                            name='cProd'
                            id='cProd'
                            value={prod?.cProd || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.xProd)}
                            type='text'
                            name='xProd'
                            id='xProd'
                            value={prod?.xProd || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.NCM)}
                            type='text'
                            name='ncm'
                            id='ncm'
                            value={prod?.ncm || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.CFOP)}
                            type='text'
                            name='cfop'
                            id='cfop'
                            value={prod?.cfop || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.uCom)}
                            type='text'
                            name='uCom'
                            id='uCom'
                            value={prod?.uCom || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.qCom)}
                            type='text'
                            name='qCom'
                            id='qCom'
                            value={prod?.qCom || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vUnCom)}
                            type='text'
                            name='vUnCom'
                            id='vUnCom'
                            value={prod?.vUnCom || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vProd)}
                            type='text'
                            name='vProd'
                            id='vProd'
                            value={prod?.vProd || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(ItemDatasForm);
