import React from 'react';
import { injectIntl } from 'react-intl';
import messages from '../FieldDetailsForm.intl';

import { Col, FormGroup, Row } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import EvtSelect from '../../EvtSelect/EvtSelect';

const MapFieldTypeV2 = props => {
    const { intl, field ,mapVersion, selectedMap, handleSelectedOption} = props;
    const { documentType, categoryType } = selectedMap;

    return (
        <>
            <Row>
                <Col md={6}>
                    <EvtSelect
                        name='name'
                        label={intl.formatMessage(messages.fieldItem)}
                        url={
                            '/Documents/DocumentFields?filter[includeDocument]=like:false' +
                            `&filter[documentType]=eq:${documentType}` +
                            `&filter[documentType]=eq:${categoryType || ''}`
                        }
                        labelKey='name'
                        labelBrackets='mapItem.field.key'
                        valueKey='name'
                        handleSelectedOptionChange={props => {
                            handleSelectedOption(props.data);
                        }}
                        key={field && field.name}
                        async
                        required
                        option={
                            field && {
                                selectedValueOption: field && field.name,
                                selectedLabelOption:
                                    field && field.name + ` [${field.key}]`,
                            }
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <AvField
                        label={intl.formatMessage(messages.name)}
                        type='text'
                        name='name'
                        id='name'
                        value={field?.name}
                        required
                    />
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.description)}
                            type='text'
                            name='description'
                            id='description'
                            value={field?.description}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label='Key'
                            type='text'
                            name='key'
                            id='key'
                            value={field?.key}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label='keyXml'
                            type='text'
                            name='keyXml'
                            id='keyXml'
                            value={field?.keyXml || ''}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            onChange={props.handleFieldTypeChange}
                            label={intl.formatMessage(messages.type)}
                            type='select'
                            name='type'
                            id='type'
                            value={field?.type}
                            errorMessage=' '
                            required
                        >
                            <option />
                            <option value='Number'>Number</option>
                            <option value='Money'>Money</option>
                            <option value='Text'>Text</option>
                            <option value='Date'>Date</option>
                            <option value='Time'>Time</option>
                            <option value='List'>List</option>
                            <option value='textarea'>TextArea</option>
                            <option value='boolean'>Boolean</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label='Form Group'
                            type='select'
                            name='formGroup'
                            id='formGroup'
                            value={field?.formGroup}
                            errorMessage=' '
                            required
                        >
                            <option />
                            <option value='1'>
                                {intl.formatMessage(messages.generalTitle)}
                            </option>
                            <option value='2'>
                                {intl.formatMessage(messages.providerTitle)}
                            </option>
                            <option value='3'>
                                {intl.formatMessage(messages.borrowerTitle)}
                            </option>
                            <option value='4'>
                                {intl.formatMessage(messages.middlewareTitle)}
                            </option>
                            <option value='5'>
                                {intl.formatMessage(messages.servicesTitle)}
                            </option>
                            <option value='6'>
                                {intl.formatMessage(messages.taxesTitle)}
                            </option>
                            <option value='7'>
                                {intl.formatMessage(messages.othersTitle)}
                            </option>
                            <option value='8'>
                                {intl.formatMessage(messages.partsTitle)}
                            </option>
                            <option value='9'>
                                {intl.formatMessage(messages.documentValues)}
                            </option>
                            <option value='10'>
                                {intl.formatMessage(messages.municipalTaxes)}
                            </option>
                            <option value='11'>
                                {intl.formatMessage(messages.federalTaxes)}
                            </option>
                            <option value='12'>
                                {intl.formatMessage(messages.texts)}
                            </option>
                        </AvField>
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(MapFieldTypeV2);
