import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    title: {
        id: 'settings.title',
        defaultMessage: 'Configurações',
    },
    account: {
        id: 'settings.account',
        defaultMessage: 'Conta',
    },
    documentsUpload: {
        id: 'settings.documentsUpload',
        defaultMessage: 'Entrada de Documentos',
    },
    nfs: {
        id: 'settings.nfs',
        defaultMessage: 'NFS-e',
    },
    nfsNacional: {
        id: 'settings.nfsNacional',
        defaultMessage: 'NFS-e Nacional',
    },
    nfe: {
        id: 'settings.nfe',
        defaultMessage: 'NF-e',
    },
    cte: {
        id: 'settings.cte',
        defaultMessage: 'CT-e',
    },
    other: {
        id: 'settings.other',
        defaultMessage: 'Outros Pagamentos',
    },
    orders: {
        id: 'settings.orders',
        defaultMessage: 'Pedidos',
    },
    registerCities: {
        id: 'settings.registerCities',
        defaultMessage: 'Cadastrar Prefeituras',
    },
    reportScheduled: {
        id: 'settings.reportScheduled',
        defaultMessage: 'Relatórios Agendados',
    },
    notifications: {
        id: 'settings.notifications',
        defaultMessage: 'Central de Notificações',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});
