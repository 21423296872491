export const Operators = {
    equalTo: 'eq',
    notEqualTo: 'ne',
    greaterThan: 'gt',
    greaterThanOrEqualTo: 'ge',
    lessThan: 'lt',
    lessThanOrEqualTo: 'le',
    like: 'like',
    sort: 'sort',
    skip: 'skip',
    take: 'take',
    page: 'page',
    index: 'index',
    or: 'or',
    search: 'search',
};

export const SortDirection = {
    asc: 'asc',
    desc: 'desc',
};

export default class FilterBuilder {
    filters = [];

    filter = (property, operator, value) => {
        this.filters.push({
            property,
            operator,
            value,
        });

        return this;
    };

    build = () => {
        let query = '?';

        this.filters.map(filter => {
            const { property, operator, value } = filter;

            if (query.length > 1) {
                query += '&';
            }

            if (operator === Operators.sort) {
                const ascOrDesc = value === SortDirection.asc ? '' : '-';
                query += Operators.sort + '=' + ascOrDesc + property;
            } else if (
                operator === Operators.skip ||
                operator === Operators.take ||
                operator === Operators.page ||
                operator === Operators.search ||
                operator === Operators.index
            ) {
                query += operator + '=' + value;
            } else if (operator === Operators.or) {
                query += operator + '=true';
            } else {
                query +=
                    'filter[' + property + ']=' + operator + ':' + value + '';
            }

            return query;
        });

        return query;
    };
}
