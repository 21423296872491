import React, { useEffect } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import messages from './SettingsDocumentForm.intl';
import { documentTypeCategories } from '../SettingsPage';

const SettingsDocumentFormOther = props => {
    const {
        intl,
        handleFormSubmit,
        moduleSettings,
        documentType,
        title,
        allowedCategories,
        selectedAllowedCategories,
        handleAllowedCategoriesSelectChange,
    } = props;

    return (
        <AvForm
            onValidSubmit={(event, values) =>
                handleFormSubmit(event, {
                    ...values,
                    documentType: documentType,
                    hideMonitiring: true,
                })
            }
        >
            <h5 className='title-area-form'>{title}</h5>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.documentSkipValidation,
                            )}
                            type='checkbox'
                            name='skipValidationStepWhenAllFieldsAreValid'
                            id='skipValidationStepWhenAllFieldsAreValid'
                            value={
                                moduleSettings?.skipValidationStepWhenAllFieldsAreValid
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <label>
                        {intl.formatMessage(messages.allowedCategories)}
                    </label>
                    <Select
                        id='categoryIdAllowed'
                        name='allowedCategories'
                        className='evt-select'
                        options={allowedCategories}
                        isMulti
                        placeholder={intl.formatMessage(
                            messages.selectPlaceholder,
                        )}
                        closeMenuOnSelect={false}
                        value={selectedAllowedCategories}
                        onChange={(e) => handleAllowedCategoriesSelectChange(e, documentTypeCategories.Others)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.documentIsRequiredOrderNumber,
                            )}
                            type='checkbox'
                            name='isRequiredOrderNumberToSendDocuments'
                            id='isRequiredOrderNumberToSendDocuments'
                            value={
                                moduleSettings?.isRequiredOrderNumberToSendDocuments
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowPdfAnnotation,
                            )}
                            type='checkbox'
                            name='allowPdfAnnotation'
                            id='allowPdfAnnotation'
                            value={moduleSettings?.allowPdfAnnotation}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.saveOriginalFile,
                            )}
                            type='checkbox'
                            name='saveOriginalFile'
                            id='saveOriginalFile'
                            value={moduleSettings?.saveOriginalFile}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.doNotSendToIntegrationDocumentsThatNotBelogsToCompapy,
                            )}
                            type='checkbox'
                            name='doNotSendToIntegrationDocumentsThatNotBelogsToCompapy'
                            id='doNotSendToIntegrationDocumentsThatNotBelogsToCompapy'
                            value={
                                moduleSettings?.doNotSendToIntegrationDocumentsThatNotBelogsToCompapy
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowIdentifySupplierOther,
                            )}
                            type='checkbox'
                            name='allowIdentifySupplier'
                            id='allowIdentifySupplier'
                            value={moduleSettings?.allowIdentifySupplier}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowSendEmailToSupplier,
                            )}
                            type='checkbox'
                            name='allowSendEmailToSupplier'
                            id='allowSendEmailToSupplier'
                            value={moduleSettings?.allowSendEmailToSupplier}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.blockNonPDFDocuments,
                            )}
                            type='checkbox'
                            name='blockNonPDFDocuments'
                            id='blockNonPDFDocuments'
                            value={moduleSettings?.blockNonPDFDocuments}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <button
                        type='submit'
                        className='btn-submit'
                        disabled={props.loading}
                    >
                        {intl.formatMessage(messages.submitButton)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    );
};

export default injectIntl(SettingsDocumentFormOther);
