import React from 'react';
import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export const messages = defineMessages({
    ...globalMessages,
    fileName: {
        id: 'pendingFiles.fileName',
        defaultMessage: 'Nome do arquivo',
    },
    sendedBy: {
        id: 'pendingFiles.sendedBy',
        defaultMessage: 'Enviado por',
    },
    rejectionReason: {
        id: 'pendingFiles.rejectionReason',
        defaultMessage: 'Motivo de rejeição',
    },
    pendingFilesFound: {
        id: 'pendingFiles.pendingFilesFound',
        defaultMessage: '{length} Arquivo(s) pendente(s) encontrado(s)',
    },
    pending: {
        id: 'pendingFiles.pending',
        defaultMessage: 'Pendente',
    },
    sended: {
        id: 'pendingFiles.sended',
        defaultMessage: 'Enviado',
    },
    rejected: {
        id: 'pendingFiles.rejected',
        defaultMessage: 'Rejeitado',
    },
});

export default messages;
