import React from 'react';
import messages from '../UserDetailsForm.intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const accessesColumns = (
    intl,
    handleRemoveAccessButtonClick,
    isReadOnly,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.contractorName)}</span>
            </div>
        ),
        accessor: 'contractorName',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.supplierName)}</span>
            </div>
        ),
        accessor: 'supplierName',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.roleName)}</span>
            </div>
        ),
        accessor: 'roleName',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.deleteButton)}</span>
            </div>
        ),
        accessor: 'delete',
        Cell: row => {
            return (
                <div className='customer-container'>
                    {!isReadOnly && (
                        <button
                            style={{ width: '100px' }}
                            className='btn-delete'
                            onClick={() =>
                                handleRemoveAccessButtonClick(row.original)
                            }
                        >
                            <FontAwesomeIcon icon='trash-alt' />
                        </button>
                    )}
                </div>
            );
        },
    },
];

export const companiesColumns = (
    intl,
    handleRemoveCompanyButtonClick,
    isReadOnly,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.companyName)}</span>
            </div>
        ),
        accessor: 'companyName',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.companyCNPJ)}</span>
            </div>
        ),
        accessor: 'companyCNPJ',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.deleteButton)}</span>
            </div>
        ),
        accessor: 'delete',
        Cell: row => {
            return (
                <div className='customer-container'>
                    {!isReadOnly && (
                        <button
                            style={{ width: '100px' }}
                            className='btn-delete'
                            onClick={() =>
                                handleRemoveCompanyButtonClick(row.original)
                            }
                        >
                            <FontAwesomeIcon icon='trash-alt' />
                        </button>
                    )}
                </div>
            );
        },
    },
];
