import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { Container, Row, Col } from 'reactstrap';

import Viewer from '../../components/Viewer/Viewer';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import Loading from '../../components/Loading/Loading';
import FileUploadForm from '../../components/FileUploadForm/FileUploadForm';
import { AuthContext } from '../../context/AuthContext';
import Tabs from '../../components/Tabs/Tabs';
import { injectIntl } from 'react-intl';
import messages from './MapDetailsPage.intl';
import MapFields from './pages/MapFields';
import MapFormFields from './pages/MapFormFields';

import { toast } from 'react-toastify';
import MapOptions from './pages/MapOptions';

class MapDetailsPage extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            dropzoneActive: false,
            showUpdateFileModal: false,
            showHideCropMapModal: false,

            boxes: [],
            selectedMapItem: null,
            showDeleteMapItemConfirmationModal: false,
            activeTab: 0,
        };

        this.scrollAreaRef = React.createRef();
    }

    componentDidMount() {
        const mapId = this.props.match.params.id;
        this.props.onInitMapDetails(mapId);
        this.props.onClearOcrResults();

        const boxes = this.getBoxes();

        this.setState({
            ...this.state,
            boxes,
        });
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.selectedMap &&
                this.props.selectedMap !== prevProps.selectedMap) ||
            (this.props.mapOcrResult &&
                this.props.mapOcrResult !== prevProps.mapOcrResult)
        ) {
            const boxes = this.getBoxes();

            this.setState({
                ...this.state,
                boxes,
            });
        }
    }

    handleBoxChange = changedBox => {
        const mapItemById = this.props.selectedMap.mapItems.find(
            mi => mi.mapItemId === changedBox.key,
        );
        const mapItemByKey = this.props.selectedMap.mapItems.find(
            mi => mi.field.key === changedBox.key,
        );
        let mapItem = mapItemById || mapItemByKey;

        mapItem.boundingBox = {
            x: Math.round(changedBox.x),
            y: Math.round(changedBox.y),
            width: Math.round(changedBox.width),
            height: Math.round(changedBox.height),
        };

        this.props.onChangeMapItem(this.props.selectedMap, mapItem);
    };

    handleNewBoxClick = (newBox, mapVersion) => {
        const { selectedMap, intl } = this.props;
        const index = selectedMap.mapItems.length + 1;

        let newMapItem = {
            field: {
                name: intl.formatMessage(messages.newField) + ` ${index}`,
                type: 'Text',
                key: 'defaultKeyItem',
                formGroup: 7,
                layout: {
                    classes: '',
                },
                constraints: {
                    pattern: '',
                    required: false,
                },
            },
            boundingBox: {
                x: newBox.x,
                y: newBox.y,
                width: newBox.width,
                height: newBox.height,
            },
        };

        if (mapVersion === 0) {
            const mapFieldType = {
                mapFieldType: {
                    endsWith: '',
                    fitXWidth: false,
                    hideMapInDocument: false,
                    mapKeyReference: '',
                    order: 0,
                    removeFirstLine: false,
                    startsWith: '',
                    type: 1,
                },
            };

            newMapItem.field = { ...newMapItem.field, ...mapFieldType };
        } else {
            const fieldTypeV2 = {
                term: '',
                orientation: '0',
                pixelAdjust: '0',
            };

            const mapFieldTypeV2 = {
                mapFieldTypeV2: {
                    isManual: false,
                    startAt: { ...fieldTypeV2 },
                    xBegin: { ...fieldTypeV2 },
                    xEnd: { ...fieldTypeV2 },
                    yBegin: { ...fieldTypeV2 },
                    yEnd: { ...fieldTypeV2 },
                },
            };

            newMapItem.field = { ...newMapItem.field, ...mapFieldTypeV2 };
        }

        this.props.onAddMapItem(this.props.selectedMap.mapId, newMapItem);
    };

    handleBoxSelectionChange = selectedBox => {
        if (!selectedBox) {
            this.setState(
                {
                    selectedMapItem: null,
                },
                () => {
                    const boxes = this.getBoxes();

                    this.setState({
                        boxes,
                    });
                },
            );
        } else {
            const mapItemById = this.props.selectedMap.mapItems.find(
                mi => mi.mapItemId === selectedBox.key,
            );
            const mapItemByKey = this.props.selectedMap.mapItems.find(
                mi => mi.field.key === selectedBox.key,
            );
            let mapItem = mapItemById || mapItemByKey;

            const key = mapItem.mapItemId;
            const bb = mapItem.boundingBox
                ? {
                      key,
                      ...mapItem.boundingBox,
                  }
                : {
                      key,
                      x: 0,
                      y: 0,
                      width: 0,
                      height: 0,
                  };

            const boxes = [bb];

            this.setState({
                ...this.state,
                selectedMapItem: mapItem,
                boxes,
            });
        }
    };

    handleFieldChange = (e, mapItemId) => {
        const value = e.target.value;

        const mapItem = this.props.selectedMap.mapItems.find(
            mi => mi.mapItemId === mapItemId,
        );

        if (mapItem.field.name === value) {
            this.setState(
                {
                    selectedMapItem: null,
                },
                () => {
                    const boxes = this.getBoxes();

                    this.setState({
                        boxes,
                    });
                },
            );

            return;
        }

        const updatedMapItem = {
            ...mapItem,
            field: {
                ...mapItem.field,
                name: value,
            },
        };

        this.setState(
            {
                ...this.state,
                selectedMapItem: updatedMapItem,
            },
            () => {
                this.props.onChangeMapItem(
                    this.props.selectedMap,
                    updatedMapItem,
                );
            },
        );
    };

    handleRemoveItemClick = (e, mapItemId) => {
        const mapItem = this.props.selectedMap.mapItems.find(
            mi => mi.mapItemId === mapItemId,
        );

        this.setState({
            selectedMapItem: mapItem,
            showDeleteMapItemConfirmationModal: !this.state
                .showDeleteMapItemConfirmationModal,
        });
    };

    handleHideCropMapToogleModal = () => {
        this.setState({
            showHideCropMapModal: !this.state.showHideCropMapModal,
        });
    };

    handleDeleteMapItemConfirmationModalToogle = () => {
        const state = !this.state.showDeleteMapItemConfirmationModal;

        this.setState({
            showDeleteMapItemConfirmationModal: state,
            selectedMapItem: !state ? null : this.state.selectedMapItem,
        });
    };

    handleUpdateFileToogleModal = () => {
        this.setState({
            showUpdateFileModal: !this.state.showUpdateFileModal,
        });
    };

    handleBtnDeactivateCropMapClick = () => {
        const { hideCrop } = this.props.selectedMap || {};

        this.setState({
            showHideCropMapModal: !this.state.showHideCropMapModal,
        });

        this.props.onUpdateCropMap(this.props.selectedMap, !hideCrop);
    };

    handleDeleteMapItemConfirmButtonClick = () => {
        const { mapItemId } = this.state.selectedMapItem;

        this.setState(
            {
                selectedMapItem: null,
                showDeleteMapItemConfirmationModal: !this.state
                    .showDeleteMapItemConfirmationModal,
            },
            () => {
                this.props.onRemoveMapItem(this.props.selectedMap, mapItemId);
            },
        );
    };

    handleMapItemFocus = (e, key) => {
        const mapItemById = this.props.selectedMap?.mapItems.find(
            mi => mi.mapItemId === key,
        );
        const mapItemByKey = this.props.selectedMap?.mapItems.find(
            mi => mi.field.key === key,
        );
        let mapItem = mapItemById || mapItemByKey;

        const bb = mapItem?.boundingBox
            ? {
                  key,
                  ...mapItem.boundingBox,
              }
            : {
                  key,
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0,
              };

        const boxes = [bb];
        this.setState({
            ...this.state,
            selectedMapItem: mapItem,
            boxes,
        });
    };

    handleFieldFocus = (e, key, fieldFocus) => {
        const field = this.props.mapOcrResult?.find(
            ff =>
                ff.key === key &&
                ff.boundingBox.page === fieldFocus.boundingBox.page &&
                ff.boundingBox.x === fieldFocus.boundingBox.x &&
                ff.boundingBox.y === fieldFocus.boundingBox.y,
        );

        const mapItem = {
            boundingBox: field.boundingBox,
            field,
            mapItemId: field.fieldId,
        };

        const bb = field?.boundingBox
            ? {
                  key,
                  ...field.boundingBox,
              }
            : {
                  key,
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0,
              };

        const boxes = [bb];

        this.setState({
            ...this.state,
            selectedMapItem: mapItem,
            boxes,
        });
    };

    getBoxes = () => {
        const hasOcrResult = this.props.mapOcrResult?.length >= 1;
        const mapFields = hasOcrResult
            ? this.props.mapOcrResult
            : this.props.selectedMap?.mapItems;

        if (!this.state.selectedMapItem) {
            return mapFields?.map(mapItem => {
                const keyId = mapItem.mapItemId;

                return mapItem
                    ? {
                          key: keyId,
                          ...mapItem.boundingBox,
                      }
                    : {
                          key: keyId,
                          x: 0,
                          y: 0,
                          width: 0,
                          height: 0,
                          page: 1,
                      };
            });
        } else {
            return [
                {
                    key: this.state.selectedMapItem.mapItemId,
                    ...this.state.selectedMapItem.boundingBox,
                },
            ];
        }
    };

    handleFormSubmitMapOptions = (event, values) => {
        let mapDelimeterValues = {};
        if (values) {
            mapDelimeterValues = {
                isRepeatingMap: values.isRepeatingMap,
                start: values.start,
                end: values.end,
            };
        }

        this.props.onUpdateMapFilterDelimeter(
            this.props.selectedMap?.mapId,
            mapDelimeterValues,
        );
    };

    handleUploadFile = file => {
        this.props.onUploadMapFile(this.props.selectedMap, file);
    };

    handleReUploadFile = file => {
        this.setState(
            {
                showUpdateFileModal: false,
            },
            () => {
                this.props.onUploadMapFile(this.props.selectedMap, file);
            },
        );
    };

    handleSelectedMapItemCleanupClick = () => {
        this.setState(
            {
                selectedMapItem: null,
            },
            () => {
                const boxes = this.getBoxes();

                this.setState({
                    boxes,
                });
            },
        );
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({ activeTab: index });
        }
    };

    handleTestOcr = () => {
        const mapId = this.props.match.params.id;
        this.props.onInitMapOcrTest(mapId);
    };

    render() {
        const { intl } = this.props;
        const { activeTab } = this.state;

        const canInactivateMap = this.context.hasClaim('map-delete');
        const canUploadFile = this.context.hasClaim('map-file-update');
        const hasLevelFull = this.context.hasClaim('role-level-full');
        const isLevelFullAndNfs =
            this.props.selectedMap.documentType === 'NFS' && hasLevelFull;

        const {
            hideCrop,
            city,
            documentType,
            fileType,
            categoryType,
            subCategory,
            childMapId,
        } = this.props.selectedMap || {};

        const childMapText = childMapId
            ? `${intl.formatMessage(messages.childMapId)}: ${childMapId} `
            : '';

        const titleMap =
            `${city} ` +
            `${intl.formatMessage(messages.documentType)}: ${documentType}  ` +
            `${intl.formatMessage(messages.fileType)}: ${fileType} ` + 
            `${categoryType ? `${intl.formatMessage(messages.categoryType)}: ${categoryType} ` : ''} ` +
            `${subCategory ? `${intl.formatMessage(messages.subCategory)}: ${subCategory} ` : ''} ` +
            childMapText;

        const boxes = this.state.boxes
            ? this.state.boxes.map(box => {
                  return {
                      key: box.key,
                      x: box.x,
                      y: box.y,
                      width: box.width,
                      height: box.height,
                      page: box?.page || 1,
                  };
              })
            : [];

        const tabs = [];

        tabs.push({
            name: intl.formatMessage(messages.mapsFields),
            component: (
                <MapFields
                    scrollAreaRef={this.scrollAreaRef}
                    selectedMap={this.props.selectedMap}
                    fields={this.props.fields}
                    selectedMapItem={this.state.selectedMapItem}
                    handleFieldChange={this.handleFieldChange}
                    handleFieldFocus={this.handleMapItemFocus}
                    handleRemoveItemClick={this.handleRemoveItemClick}
                    handleSelectedMapItemCleanupClick={
                        this.handleSelectedMapItemCleanupClick
                    }
                />
            ),
        });

        tabs.push({
            name: intl.formatMessage(messages.mapExample),
            component: (
                <MapFormFields
                    scrollAreaRef={this.scrollAreaRef}
                    formFields={this.props.mapOcrResult}
                    loadingDetails={this.props.loadingViewer}
                    handleDynamicFieldFocus={this.handleFieldFocus}
                    handleDynamicFieldBlur={
                        this.handleSelectedMapItemCleanupClick
                    }
                />
            ),
        });

        tabs.push({
            name: intl.formatMessage(messages.mapOptions),
            component: (
                <MapOptions
                    handleFormSubmit={this.handleFormSubmitMapOptions}
                    mapFilterDelimeter={
                        this.props.selectedMap?.mapFilterDelimeter
                    }
                />
            ),
        });

        return (
            <Container fluid>
                <React.Fragment>
                    <Row>
                        <Col xs={8}>
                            <Breadcrumb
                                routes={[
                                    {
                                        path: '/home',
                                        name: intl.formatMessage(messages.home),
                                        active: false,
                                    },
                                    {
                                        path: '/maps',
                                        name: intl.formatMessage(
                                            messages.title,
                                        ),
                                        active: false,
                                    },
                                    {
                                        path: '/maps/:id',
                                        name: intl.formatMessage(
                                            messages.titleMap,
                                            {
                                                entity: city,
                                            },
                                        ),
                                        active: true,
                                    },
                                ]}
                            />
                            <header>
                                <section className='title'>
                                    <Header
                                        title={intl.formatMessage(
                                            messages.title,
                                        )}
                                        subtitle={intl.formatMessage(
                                            messages.titleMap,
                                            {
                                                entity: titleMap,
                                            },
                                        )}
                                    />
                                </section>
                            </header>
                        </Col>
                    </Row>

                    <section className='map-nota row'>
                        <section className=' col-6'>
                            {this.props.selectedMap.fileId ? (
                                <Viewer
                                    readOnly={false}
                                    url={`${window.API_URI}/Maps/${
                                        this.props.selectedMap.mapId
                                    }/File`}
                                    urlDownload={`${window.API_URI}/Maps/${
                                        this.props.selectedMap.mapId
                                    }/File`}
                                    loading={this.props.loadingViewer}
                                    boxes={boxes}
                                    handleBoxChange={this.handleBoxChange}
                                    handleNewBoxClick={this.handleNewBoxClick}
                                    handleBoxSelectionChange={
                                        this.handleBoxSelectionChange
                                    }
                                    handleRemoveBoxKeyPress={
                                        this.handleRemoveItemClick
                                    }
                                    className={'image-map-full'}
                                    mapVersion={
                                        this.props.selectedMap.mapVersion || 0
                                    }
                                    handleNewMapItem={this.handleNewBoxClick}
                                    selectedItem={this.state.selectedMapItem}
                                />
                            ) : (
                                <FileUploadForm
                                    loading={this.props.loadingViewer}
                                    handleUploadFile={this.handleUploadFile}
                                    linkDocument
                                    uploaderClassName={'upload-card-map'}
                                />
                            )}
                        </section>
                        <section className='fields-maps col-6'>
                            <Loading loading={this.props.loadingViewer} />
                            <Row className='mb-3'>
                                {canUploadFile && (
                                    <Col xs={3}>
                                        <button
                                            className='btn-info'
                                            onClick={
                                                this.handleUpdateFileToogleModal
                                            }
                                        >
                                            {intl.formatMessage(
                                                messages.updateMapFile,
                                            )}
                                        </button>
                                    </Col>
                                )}

                                {canInactivateMap && (
                                    <Col xs={3}>
                                        <button
                                            className={
                                                !hideCrop
                                                    ? 'btn-danger'
                                                    : 'btn-info'
                                            }
                                            onClick={
                                                this
                                                    .handleHideCropMapToogleModal
                                            }
                                        >
                                            {!hideCrop
                                                ? 'Esconder crop'
                                                : 'Mostrar crop'}
                                        </button>
                                    </Col>
                                )}
                                <Col xs={3}>
                                    <button
                                        className='btn-info'
                                        onClick={this.handleTestOcr}
                                        disabled={
                                            !this.props.selectedMap.fileId
                                        }
                                    >
                                        {intl.formatMessage(messages.ocrTest)}
                                    </button>
                                </Col>
                            </Row>
                            <section className='content-middle'>
                                <Tabs
                                    handleTabClick={this.handleTabClick}
                                    activeTab={activeTab}
                                    tabs={tabs}
                                />
                            </section>
                        </section>
                    </section>

                    <Modal
                        key='hideCropMapModal'
                        title={
                            hideCrop
                                ? intl.formatMessage(messages.activateMap)
                                : intl.formatMessage(messages.deactivateMap) +
                                  ' crop ' +
                                  this.props.selectedMap.city
                        }
                        isOpen={this.state.showHideCropMapModal}
                        handleToggle={this.handleHideCropMapToogleModal}
                        type={modalTypes.ERROR}
                        hasFooter={true}
                        firstButtonText={intl.formatMessage(messages.yesText)}
                        secondButtonText={intl.formatMessage(messages.noText)}
                        secondButtonClass='delete'
                        toggleFirstButton={this.handleBtnDeactivateCropMapClick}
                        toggleSecondButton={this.handleHideCropMapToogleModal}
                    >
                        {hideCrop
                            ? intl.formatMessage(messages.confirmActiveCropText)
                            : intl.formatMessage(
                                  messages.confirmDeactiveCropText,
                              )}
                    </Modal>
                    <Modal
                        key='uploadNewFileModal'
                        title={'Upload ' + this.props.selectedMap.city}
                        isOpen={this.state.showUpdateFileModal}
                        handleToggle={this.handleUpdateFileToogleModal}
                        hasFooter={false}
                    >
                        <FileUploadForm
                            loading={this.props.loadingViewer}
                            handleUploadFile={this.handleReUploadFile}
                            linkDocument
                        />
                    </Modal>
                    <Modal
                        key='deleteMapItemConfirmationModal'
                        title={intl.formatMessage(
                            messages.deleteMapItemConfirmatinTitle,
                        )}
                        isOpen={this.state.showDeleteMapItemConfirmationModal}
                        handleToggle={
                            this.handleDeleteMapItemConfirmationModalToogle
                        }
                        type={modalTypes.ERROR}
                        hasFooter={true}
                        firstButtonText={intl.formatMessage(messages.yesText)}
                        secondButtonText={intl.formatMessage(messages.noText)}
                        secondButtonClass='delete'
                        toggleFirstButton={
                            this.handleDeleteMapItemConfirmButtonClick
                        }
                        toggleSecondButton={
                            this.handleDeleteMapItemConfirmationModalToogle
                        }
                    >
                        {this.state.selectedMapItem &&
                            intl.formatMessage(
                                messages.deleteMapItemConfirmationMessage,
                            ) +
                                ' ("' +
                                this.state.selectedMapItem.field.name +
                                '").'}
                    </Modal>
                </React.Fragment>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedMap: state.mapReducer.selectedMap || {},
        loadingViewer: state.mapReducer.loadingViewer,
        mapOcrResult: state.mapReducer.mapOcrResult,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitMapDetails: mapId => dispatch(actions.initMapDetails(mapId)),
        onUploadMapFile: (map, file) =>
            dispatch(actions.uploadMapFile(map, file)),
        onChangeMapItem: (selectedMap, updatedMapItem) =>
            dispatch(actions.updateMapItem(selectedMap, updatedMapItem)),
        onAddMapItem: (mapId, newMapItem) =>
            dispatch(actions.addMapItem(mapId, newMapItem)),
        onRemoveMapItem: (mapId, mapItemId) =>
            dispatch(actions.removeMapItem(mapId, mapItemId)),
        onDeleteMap: mapId => dispatch(actions.deleteMap(mapId)),
        onUpdateMap: map => dispatch(actions.updateMap(map)),
        onUpdateCropMap: (map, hideCrop) =>
            dispatch(actions.updateCropMap(map, hideCrop)),
        onInitMapOcrTest: mapId => dispatch(actions.initMapOcrTest(mapId)),
        onUpdateMapFilterDelimeter: (mapId, values) =>
            dispatch(actions.updateMapFilterDelimeter(mapId, values)),

        onClearOcrResults: () => dispatch(actions.clearOcrResults()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MapDetailsPage),
);
