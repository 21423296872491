import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    title: {
        id: 'nfe.title',
        defaultMessage: 'Documentos',
    },
    titleNfe: {
        id: 'nfe.titleNfe',
        defaultMessage: 'NF-e',
    },

    itemOrder: {
        id: 'nfe.itemOrder',
        defaultMessage: 'Número do item de pedido',
    },
    itemOrderDescription: {
        id: 'nfe.itemOrderDescription',
        defaultMessage: 'Descrição do Item de pedido',
    },
    itemNfeNumber: {
        id: 'nfe.itemNfeNumber',
        defaultMessage: 'Número do item de nfe',
    },
    itemNfeDescription: {
        id: 'nfe.itemNfeDescription',
        defaultMessage: 'Descrição do Item de nfe',
    },
    ncm: {
        id: 'nfe.ncm',
        defaultMessage: 'NCM',
    },
    quantity: {
        id: 'nfe.quantity',
        defaultMessage: 'Quantidade',
    },
    unitaryNetValue: {
        id: 'nfe.unitaryNetValue',
        defaultMessage: 'Valor Unitário',
    },
    totalGrossValue: {
        id: 'nfe.totalGrossValue',
        defaultMessage: 'Valor total do item',
    },
    associatedItems: {
        id: 'nfe.associatedItems',
        defaultMessage: 'Itens Associados',
    },
    nfeItemsTitle: {
        id: 'nfe.nfeItemsTitle',
        defaultMessage: 'Itens NFE',
    },
    itemsSubtitle: {
        id: 'nfe.itemsSubtitle',
        defaultMessage: ' {length} itens encontrados',
    },
    //orders
    newOrdersButton: {
        id: 'nfe.newOrdersButton',
        defaultMessage: '+ Pedido',
    },
    associatedOrderDocuments: {
        id: 'nfe.associatedOrderDocuments',
        defaultMessage: 'Itens Associados',
    },
    searchItemOrders: {
        id: 'nfe.searchItemOrders',
        defaultMessage: 'Escolha um Item',
    },
    nfeItemNumber: {
        id: 'nfe.nfeItemNumber',
        defaultMessage: 'Item do Nfe',
    },
    order: {
        id: 'nfe.order',
        defaultMessage: 'Pedido',
    },
    description: {
        id: 'nfe.description',
        defaultMessage: 'Descrição do Pedido',
    },
    titleOrderCode: {
        id: 'nfe.titleOrderCode',
        defaultMessage: 'Pedido',
    },
    nfeOrderTotalGrossValue: {
        id: 'nfe.totalGrossValue',
        defaultMessage: 'Valor bruto',
    },
    removeOrderItem: {
        id: 'orderItem.removeOrderItem',
        defaultMessage: 'Remover Item do Pedido',
    },
    orderMeasure: {
        id: 'orderItem.orderMeasure',
        defaultMessage: 'Medição do pedido',
    },
    errorNoExistOrderItems: {
        id: 'orderItem.errorNoExistOrderItems',
        defaultMessage:
            'Não existem itens de pedido cadastrados para o fornecedor do documento',
    },
    noItemSelected: {
        id: 'orders.noItemSelected',
        defaultMessage: 'Nenhum item foi selecionado',
    },
    shippingDate: {
        id: 'orders.shippingDate',
        defaultMessage: 'Data de remessa',
    },
    onlyOrderFromTheDocumentTaker: {
        id: 'orders.shippingDate',
        defaultMessage: 'Somente pedidos do tomador do documento.',
    },
    associatedOrderItems: {
        id: 'orders.associatedOrderItems',
        defaultMessage: 'Pedidos Associados',
    },
});

export default messages;
