// Dependencies
import React from 'react';
import { injectIntl } from 'react-intl';
import messages from '../../containers/LoginPage/LoginPage.intl';

// Components
import { AvForm, AvField } from 'availity-reactstrap-validation';

export const ForgotPasswordForm = props => {

	const {
		intl,
		contractorColor,
		handleForgotPasswordReturnButtonClick,
	} = props;

	const handleSubmit = (e, values) => {
		props.handleForgotPasswordSubmit(e, values);
		values.forgotPasswordEmail = null; // clear field
	}

	return (
		<AvForm onValidSubmit={handleSubmit}>

			<AvField
				label={intl.formatMessage(messages.email)}
				type='email'
				name='forgotPasswordEmail'
				id='forgotPasswordEmail'
				errorMessage={intl.formatMessage(messages.invalidEmail)}
				required
			/>

			<div className='btns-login mt-5'>
				<button
					style={{ backgroundColor: contractorColor }}
					type='submit'
				>
					{intl.formatMessage(messages.forgotPasswordSent)}
				</button>
				<button
					style={{ color: contractorColor }}
					className='btn-link btnForgotPassword'
					type='button'
					onClick={handleForgotPasswordReturnButtonClick}
				>
					{intl.formatMessage(messages.returnButton)}
				</button>
			</div>

		</AvForm>
	);
}

export default injectIntl(ForgotPasswordForm);
