import axios from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import * as actionTypes from './actionTypes';

export const initCompanies = (contractorId, filter) => {
    return dispatch => {
        dispatch({
            type: actionTypes.COMPANY_GET_LIST_REQUEST,
        });

        axios
            .get(`/Contractor/${contractorId}/Company` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.COMPANY_GET_LIST_SUCCESS,
                    companies: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.COMPANY_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initCompaniesCount = (contractorId, filter) => {
    return dispatch => {
        dispatch({
            type: actionTypes.COMPANY_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Contractor/${contractorId}/Company/Count` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.COMPANY_GET_COUNT_SUCCESS,
                    companiesCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.COMPANY_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initCompanyDetails = companyId => {
    if (companyId === 'new') {
        return dispatch => {
            dispatch({
                type: actionTypes.COMPANY_GET_DETAILS_SUCCESS,
                company: {},
            });
        };
    } else {
        return dispatch => {
            dispatch({
                type: actionTypes.COMPANY_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Contractor/Company/${companyId}`)
                .then(response => {
                    dispatch({
                        type: actionTypes.COMPANY_GET_DETAILS_SUCCESS,
                        company: response.data,
                    });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.COMPANY_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        };
    }
};

export const createCompany = (contractorId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.COMPANY_CREATE_REQUEST,
        });

        axios
            .post(`/Contractor/Company`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.COMPANY_CREATE_SUCCESS,
                });

                toast.success('Filial criada com sucesso');
                dispatch(push(`/contractors/${contractorId}?index=3`));
            })
            .catch(error => {
                error.response.data.errorCode = 101;
                dispatch({
                    type: actionTypes.COMPANY_CREATE_FAILURE,
                    error,
                });
            });
    };
};

export const updateCompany = (contractorId, companyId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.COMPANY_UPDATE_REQUEST,
        });

        axios
            .put(`/Contractor/Company/${companyId}`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.COMPANY_UPDATE_SUCCESS,
                });

                toast.success('Filial alterada com sucesso');
                dispatch(push(`/contractors/${contractorId}?index=3`));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.COMPANY_UPDATE_FAILURE,
                    error,
                });
            });
    };
};

export const deleteCompany = (contractorId, companyId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.COMPANY_DELETE_REQUEST,
        });

        axios
            .delete(`/Contractor/Company/${companyId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.COMPANY_DELETE_SUCCESS,
                });

                toast.success('Filial excluída com sucesso');
                dispatch(push(`/contractors/${contractorId}?index=3`));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.COMPANY_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const importCertificate = (companyId, payload, contractorId, filter) => {
    const file = new File([payload.file], 'certificate.pfx', {
        type: 'application/x-pkcs12',
    });

    const data = new FormData();
    data.append('file', file);
    data.append('password', payload.password);

    return dispatch => {
        dispatch({
            type: actionTypes.IMPORT_COMPANY_CERTIFICATE_REQUEST,
        });

        axios
            .put(`/Contractor/Company/${companyId}/ImportCertificate`, data)
            .then(response => {
                dispatch({
                    type: actionTypes.IMPORT_COMPANY_CERTIFICATE_SUCCESS,
                });

                toast.success('Certificado importado com sucesso');
                initCompanies(contractorId, filter)(dispatch);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.IMPORT_COMPANY_CERTIFICATE_FAILURE,
                    error,
                });
            });
    };
};
