import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    description: {
        id: 'global.description',
        defaultMessage: 'Descrição',
    },
    isAvailable: {
        id: 'global.isAvailable',
        defaultMessage: 'Disponível',
    },
    orderName: {
        id: 'orders.orderName',
        defaultMessage: 'Número do Pedido',
    },
    measureItem: {
        id: 'orders.measureItem',
        defaultMessage: 'Medição',
    },
    totalGrossValue: {
        id: 'orders.totalGrossValue',
        defaultMessage: 'Valor Bruto Total',
    },
    currency: {
        id: 'orders.currency',
        defaultMessage: 'Moeda',
    },
    company: {
        id: 'roles.company',
        defaultMessage: 'Contratante',
    },
    supplier: {
        id: 'suppliers.titleOnlySupplier',
        defaultMessage: 'Fornecedor',
    },
    partnerSupplier: {
        id: 'orders.partnerSupplier',
        defaultMessage: 'Fornecedor Parceiro',
    },
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },
    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
    requesterName: {
        id: 'global.requesterName',
        defaultMessage: 'Nome',
    },
    requesterEmail: {
        id: 'global.requesterEmail',
        defaultMessage: 'Email',
    },
    buyerName: {
        id: 'global.buyerName',
        defaultMessage: 'Nome',
    },
    buyerEmail: {
        id: 'global.buyerEmail',
        defaultMessage: 'Email',
    }
});

export default messages;
