import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { FormGroup, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

const FormComboBox = props => {
    const { filter, enums, property } = props;

    return (
        <Col lg={property.lg || 4} md={property.md || 4} sm={property.sm || 12}>
            <FormGroup>
                <AvField
                    type='select'
                    name={`filters[${filter.id}].value`}
                    id={`filters[${filter.id}].value`}
                    label={property.value}
                    value={filter.value}
                >
                    <option value=''>-</option>
                    {enums &&
                        enums.map(en => {
                            return (
                                <option key={en.key} value={en.key}>
                                    {en.value}
                                </option>
                            );
                        })}
                </AvField>
            </FormGroup>
        </Col>
    );
};

export default memo(injectIntl(FormComboBox));
