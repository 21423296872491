import React from "react";
import { NavLink as ReactNavLink } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const breadcrumb = props => {
	return (
		<Breadcrumb className="breadcrumb">
			{props.routes.map(route => (
				<BreadcrumbItem active={route.active} key={route.path}>
					{route.active ? (
						route.name
					) : (
						<ReactNavLink to={route.path}>{route.name}</ReactNavLink>
					)}
				</BreadcrumbItem>
			))}
		</Breadcrumb>
	);
};

export default breadcrumb;
