import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    title: {
        id: 'maps.title',
        defaultMessage: 'Mapeamentos',
    },

    code: {
        id: 'maps.code',
        defaultMessage: 'Código',
    },

    categoryType: {
        id: 'maps.categoryType',
        defaultMessage: 'Categoria',
    },

    ibge: {
        id: 'maps.ibge',
        defaultMessage: 'IBGE',
    },

    city: {
        id: 'maps.city',
        defaultMessage: 'Cidade',
    },

    uf: {
        id: 'maps.uf',
        defaultMessage: 'Estado',
    },

    createdAt: {
        id: 'maps.createdAt',
        defaultMessage: 'Criado em',
    },

    updatedAt: {
        id: 'maps.updatedAt',
        defaultMessage: 'Atualizado em',
    },

    fileType: {
        id: 'maps.fileType',
        defaultMessage: 'Tipo arquivo',
    },
    documentType: {
        id: 'maps.documentType',
        defaultMessage: 'Tipo documento',
    },
    name: {
        id: 'suppliers.name',
        defaultMessage: 'Nome',
    },
    contractor: {
        id: 'maps.contractor',
        defaultMessage: 'Contratante',
    },
    active: {
        id: 'maps.active',
        defaultMessage: 'Ativo',
    },
    titleOnlyMap: {
        id: 'maps.titleOnlyMap',
        defaultMessage: 'Mapeamento',
    },
});

export default messages;
