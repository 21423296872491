import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import * as actions from '../../../store/actions/index';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from '../../../components/Header/Header';
import EvtFilter from '../../../components/EvtFilter/EvtFilter';
import EvtTable from '../../../components/EvtTable/EvtTable';

import {
    generatedReportsColumns,
    generatedReportsProperties,
} from '../components/Columns';
import messages from '../ReportListPage.intl';

function GeneratedReportsList(props) {
    const {
        intl,
        //state
        loadingListGenerated,
        generatedReports,
        generatedReportsCount,
        //props
        activeTab,
        handleDownloadFile,
        handleGetTrProps,
    } = props;

    const [filters, setFilters] = useState([]);
    const [query, setQuery] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const pendingReport = generatedReports.find(
                x => x.status === 'Pending',
            );

            if (pendingReport)
                props.onInitGeneratedReportsNewStatus(
                    pendingReport.reportId,
                    generatedReports,
                );
        }, 5000);

        return () => clearInterval(interval);
    }, [generatedReports]);

    const history = useHistory();

    const reloadGeneratedReportsList = stateQuery => {
        const query = stateQuery.includes('&index=')
            ? stateQuery
            : stateQuery + `&index=${activeTab}`;
        history.push(`/reports` + query);

        props.onInitGeneratedReports(stateQuery);
        props.onInitGeneratedReportsCount(stateQuery);
    };

    const handleRefreshButtonClick = () => {
        reloadGeneratedReportsList(query);
    };

    const handleEvTableStateChange = stateQuery => {
        setQuery(stateQuery);
        reloadGeneratedReportsList(stateQuery);
    };

    const handleFiltersChange = filters => {
        setFilters(filters);
        history.push(`/reports` + query);
    };

    return (
        <Container fluid>
            <header className='table-screen'>
                <section className='title'>
                    <Header
                        title={intl.formatMessage(
                            messages.generatedReportsTitle,
                        )}
                        subtitle={intl.formatMessage(
                            messages.generatedReportsSubtitle,
                            {
                                entity: generatedReportsCount,
                            },
                        )}
                    />
                </section>
                <section className='btns-topo'>
                    <EvtFilter
                        properties={generatedReportsProperties(intl)}
                        handleFiltersChange={handleFiltersChange}
                        loading={loadingListGenerated}
                    />
                    <button
                        className={`new-btn small ${
                            loadingListGenerated ? 'loading' : ''
                        }`}
                        onClick={handleRefreshButtonClick}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                </section>
            </header>
            <section className='content-middle'>
                <EvtTable
                    filters={filters}
                    columns={generatedReportsColumns(
                        intl,
                        generatedReports,
                        handleDownloadFile,
                    )}
                    data={generatedReports}
                    length={generatedReportsCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChange}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingListGenerated}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                />
            </section>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        generatedReports: state.reportReducer.generatedReports || [],
        generatedReportsCount: state.reportReducer.generatedReportsCount || 0,
        loadingListGenerated: state.reportReducer.loadingListGenerated,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitGeneratedReports: filter =>
            dispatch(actions.initGeneratedReports(filter)),
        onInitGeneratedReportsNewStatus: reportId =>
            dispatch(actions.initCheckGeneratedReportStatus(reportId)),
        onInitGeneratedReportsCount: filter =>
            dispatch(actions.initGeneratedReportsCount(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(GeneratedReportsList),
);
