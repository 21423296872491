import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';

export const LinkingOrdersContext = createContext();

export const LinkingOrdersProvider = ({ children }) => {
    const authContext = useContext(AuthContext);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedNfeItem, setSelectedNfeItem] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [hasAllowOrderDocumentAdd, setHasAllowOrderDocumentAdd] = useState(
        authContext.hasClaim('document-order-integration-update'),
    );
    const [
        hasAllowNFEOrderDocumentAdd,
        setHasAllowNFEOrderDocumentAdd,
    ] = useState(authContext.hasClaim('nfe-document-order-integration-update'));
    const [
        hasAllowCTEOrderDocumentAdd,
        setHasAllowCTEOrderDocumentAdd,
    ] = useState(authContext.hasClaim('cte-document-order-integration-update'));
    const [orderItemToRemove, setOrderItemToRemove] = useState(null);

    useEffect(() => {
        const currentStep = selectedDocument?.currentStep;
        const isRevisionPending =
            currentStep?.order === 3 && currentStep?.status === 'pending';

        const addOrderClaim = 'document-order-integration-update';
        const addNFEOrderClaim = 'nfe-document-order-integration-update';
        const addCTEOrderClaim = 'cte-document-order-integration-update';

        const getAddOrderAllow = claim =>
            authContext.hasClaim(claim) || isRevisionPending;

        switch (selectedDocument?.documentType) {
            case 'NFE':
                setHasAllowNFEOrderDocumentAdd(
                    getAddOrderAllow(addNFEOrderClaim),
                );
                break;
            case 'CTE':
                setHasAllowCTEOrderDocumentAdd(
                    getAddOrderAllow(addCTEOrderClaim),
                );
                break;
            default:
                setHasAllowOrderDocumentAdd(getAddOrderAllow(addOrderClaim));
                break;
        }
    }, [selectedDocument]);

    const documentId =
        selectedDocument?.documentId ||
        window.location.pathname
            .replace('/documents/', '')
            .replace('/nfeDocuments/', '')
            .replace('/cteDocuments/', '')
            .replace('/otherDocuments/', '')
            .replace('/orders', '');

    function handleSelectOrderItem(item) {
        const isSelected = selectedOrders.find(it => it === item?.orderItemId);

        if (isSelected) {
            const filteredOrders = selectedOrders.filter(
                it => it !== item?.orderItemId,
            );

            setSelectedOrders(filteredOrders);
        } else {
            setSelectedOrders([...selectedOrders, item?.orderItemId]);
        }
    }

    return (
        <LinkingOrdersContext.Provider
            value={{
                documentId,
                //States
                hasAllowOrderDocumentAdd,
                hasAllowNFEOrderDocumentAdd,
                hasAllowCTEOrderDocumentAdd,
                selectedOrders,
                setSelectedOrders,
                selectedDocument,
                setSelectedDocument,
                orderItemToRemove,
                setOrderItemToRemove,
                handleSelectOrderItem,
                selectedNfeItem,
                setSelectedNfeItem,
            }}
        >
            {children}
        </LinkingOrdersContext.Provider>
    );
};

export const useLinkingOrders = () => {
    const context = useContext(LinkingOrdersContext);
    if (!context) {
        throw new Error(
            'useLinkingOrders deve ser usado dentro de um LinkingOrdersProvider',
        );
    }
    return context;
};
