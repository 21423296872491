import React from 'react';

import {
    ListGroup,
    ListGroupItem
} from 'reactstrap';

import { mapActionTypes } from "../../utils/enums";

import messages from "./MapActionAvailableList.intl";
import { injectIntl } from "react-intl";

const availableActions = [
    {
        type: mapActionTypes.INSERT,
        active: false
    },
    {
        type: mapActionTypes.PAD_LEFT,
        active: false
    },
    {
        type: mapActionTypes.PAD_RIGHT,
        active: false
    },
    {
        type: mapActionTypes.REMOVE,
        active: false
    },
    {
        type: mapActionTypes.REPLACE,
        active: false
    },
    {
        type: mapActionTypes.TRIM,
        active: false
    },
    {
        type: mapActionTypes.TRIM_START,
        active: false
    },
    {
        type: mapActionTypes.TRIM_END,
        active: false
    },
    {
        type: mapActionTypes.KEEP,
        active: false
    }
];

const mapActionAvailableList = (props) => {
    const { intl } = props;
    return (
        <ListGroup>
            {availableActions.map((action, index) => {
                let actionTitle = "";
                let actionDescription = "";

                switch (action.type) {
                    case mapActionTypes.INSERT:
                        actionTitle = intl.formatMessage(messages.insert);
                        actionDescription = intl.formatMessage(messages.insertDescription);
                        break;
                    case mapActionTypes.PAD_LEFT:
                        actionTitle = intl.formatMessage(messages.padLeft);
                        actionDescription = intl.formatMessage(messages.padLeftDescription);
                        break;
                    case mapActionTypes.PAD_RIGHT:
                        actionTitle = intl.formatMessage(messages.padRight);
                        actionDescription = intl.formatMessage(messages.padRightDescription);
                        break;
                    case mapActionTypes.REMOVE:
                        actionTitle = intl.formatMessage(messages.remove);
                        actionDescription = intl.formatMessage(messages.removeDescription);
                        break;
                    case mapActionTypes.REPLACE:
                        actionTitle = intl.formatMessage(messages.replace);
                        actionDescription = intl.formatMessage(messages.replaceDescription);
                        break;  
                    case mapActionTypes.TRIM:
                        actionTitle = intl.formatMessage(messages.trim);
                        actionDescription = intl.formatMessage(messages.trimDescription);
                        break;
                    case mapActionTypes.TRIM_START:
                        actionTitle = intl.formatMessage(messages.trimStart);
                        actionDescription = intl.formatMessage(messages.trimStartDescription);
                        break;
                    case mapActionTypes.TRIM_END:
                        actionTitle = intl.formatMessage(messages.trimEnd);
                        actionDescription = intl.formatMessage(messages.trimEndDescription);
                        break;
                    case mapActionTypes.KEEP:
                        actionTitle = intl.formatMessage(messages.keep);
                        actionDescription = intl.formatMessage(messages.keepDescription);
                        break;
                    default:
		                break;
                }

                return (
                    <React.Fragment key={index}>
                        <ListGroupItem
                            className="list-actions"
                            key={action.type}
                            onClick={e => props.handleNewMapActionClick(e, action.type)}                                                        
                            style={{ cursor: "pointer" }}>
                            <div>
                                <h5>{actionTitle}</h5>                                
                                <p className='text-muted'>
                                    {actionDescription}
                                </p>
                            </div>
                        </ListGroupItem>
                        <br />
                    </React.Fragment>
                );
            })}
        </ListGroup>
    );
}

export default injectIntl(mapActionAvailableList);
