import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { Row } from 'reactstrap';

import * as actions from '../../../store/actions/index';
import { columnsItems } from './Columns';
import messages from '../NfeDetailsPage.intl';
import EvtTable from '../../../components/EvtTable/EvtTable';
import Modal from '../../../components/Modal/Modal';

const ModalNfeItems = props => {
    const {
        intl,
        documentId,
        isOpen,
        handleToggle,
        handleOpenNfeItemsDetails,
        nfeItems,
        nfeItemsCount,
        loadingNfeItems,
    } = props;

    const reloadOrdersDocument = query => {
        props.onInitNfeItems(documentId, query);
        props.onInitNfeItemsCount(documentId, query);
    };

    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: { cursor: 'pointer' },
            onClick: e => handleOpenNfeItemsDetails(rowInfo.original),
        };
    }

    return (
        <>
            <Modal
                title={intl.formatMessage(messages.associatedItems)}
                isOpen={isOpen}
                handleToggle={handleToggle}
                size={'xxl'}
            >
                <Row>
                    <EvtTable
                        fetchPage
                        columns={columnsItems(intl, nfeItems)}
                        data={nfeItems}
                        length={nfeItemsCount}
                        pageSize={10}
                        handleStateChange={reloadOrdersDocument}
                        handleGetTrProps={handleGetTrProps}
                        loading={loadingNfeItems}
                        defaultSorted={[
                            {
                                id: 'itemOrder',
                                desc: true,
                            },
                        ]}
                        countHeaderText={intl.formatMessage(
                            messages.itemsSubtitle,
                            {
                                length: nfeItemsCount,
                            },
                        )}
                        tabInside
                    />
                </Row>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {
        nfeItems: state.NFeReducer.nfeItems || [],
        nfeItemsCount: state.NFeReducer.nfeItemsCount || 0,
        loadingNfeItems: state.NFeReducer.loadingNfeItems,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitNfeItems: (documentId, filter) =>
            dispatch(actions.initNfeItems(documentId, filter)),
        onInitNfeItemsCount: (documentId, filter) =>
            dispatch(actions.initNfeItemsCount(documentId, filter)),
    };
};

ModalNfeItems.propTypes = {
    selectedDocument: PropTypes.object,
    documentId: PropTypes.string,
    isOpen: PropTypes.bool,
    handleToggle: PropTypes.func,
    handleOpenNfeItemsDetails: PropTypes.func,
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalNfeItems),
);
