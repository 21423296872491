import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const modalTypes = {
    INFO: 'MODAL_INFO',
    SUCCESS: 'MODAL_SUCCESS',
    ERROR: 'MODAL_ERROR',
    WARNING: 'MODAL_WARNING',
};

const modal = props => {
    return (
        <Modal
            isOpen={props.isOpen}
            toggle={props.handleToggle}
            backdrop={props.backdrop}
            keyboard={props.keyboard}
            size={props.size}
            modalClassName={props.modalClassName}
            backdropClassName={props.backdropClassName}
            scrollable={props.scrollable}
        >
            {!props.noHeader && (
                <ModalHeader toggle={props.handleToggle}>
                    {props.title}
                </ModalHeader>
            )}
            {!!props.modalOutBody && <div>{props.modalOutBody}</div>}

            <ModalBody className={props.modalBodyClassName}>
                {props.children}
            </ModalBody>
            {!!props.modalFooter && (
                <ModalFooter>{props.modalFooter}</ModalFooter>
            )}
            {props.hasFooter && (
                <ModalFooter>
                    <button
                        className={props.firstButtonClass}
                        onClick={props.toggleFirstButton}
                        disabled={props.loading}
                    >
                        {props.firstButtonText}
                    </button>
                    <button
                        className={props.secondButtonClass}
                        onClick={props.toggleSecondButton}
                        disabled={props.loading}
                    >
                        {props.secondButtonText}
                    </button>
                </ModalFooter>
            )}
        </Modal>
    );
};

modal.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    handleToggle: PropTypes.func,
    handleModalOkButtonClick: PropTypes.func,
    handleModalCancelButtonClick: PropTypes.func,
};

export default modal;
