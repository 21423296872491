import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import { getAppVersion } from '../../utils/getVersion';

export const initUsers = (filter = '') => {
    return dispatch => {
        dispatch({
            type: actionTypes.USERS_GET_LIST_REQUEST,
        });

        axios
            .get('/Users' + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.USERS_GET_LIST_SUCCESS,
                    users: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.USERS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initUsersCount = filter => {
    return async dispatch => {
        dispatch({
            type: actionTypes.USERS_GET_COUNT_REQUEST,
        });

        await axios
            .get('/Users/Count' + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.USERS_GET_COUNT_SUCCESS,
                    usersCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.USERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initUserDetails = userId => {
    if (userId === 'new') {
        return dispatch => {
            dispatch({
                type: actionTypes.USER_GET_DETAILS_NEW,
            });
        };
    } else {
        return (dispatch, getState) => {
            dispatch({
                type: actionTypes.USER_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Users/${userId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.USER_GET_DETAILS_SUCCESS,
                        user: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.USER_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        };
    }
};

export const createUser = values => {
    return dispatch => {
        dispatch({
            type: actionTypes.USER_POST_REQUEST,
        });

        axios
            .post(`/Users`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.USER_POST_SUCCESS,
                });
                toast.success('Usuário criado com sucesso');
                dispatch(push('/users'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.USER_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateUser = (userId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.USER_PUT_REQUEST,
        });

        axios
            .put(`/Users/${userId}`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.USER_PUT_SUCCESS,
                });
                toast.success('Usuário alterado com sucesso');
                dispatch(push('/users'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.USER_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteUser = userId => {
    return dispatch => {
        dispatch({
            type: actionTypes.USER_DELETE_REQUEST,
        });

        axios
            .delete(`/Users/${userId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.USER_DELETE_SUCCESS,
                });
                toast.success('Usuário desativado com sucesso');
                dispatch(push('/users'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.USER_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const addUserAccess = newAccess => {
    return (dispatch, getState) => {
        var accesses = [];

        if (getState().userReducer.accesses) {
            accesses = getState().userReducer.accesses;
        }

        accesses.push(newAccess);

        dispatch({
            type: actionTypes.USER_ADD_ACCESS,
            accesses: accesses,
        });
    };
};

export const removeUserAccess = removeAccess => {
    return (dispatch, getState) => {
        var accesses = [];

        if (getState().userReducer.accesses) {
            accesses = getState().userReducer.accesses;
        }

        const acessUpdate = [];

        for (var i = 0, len = accesses.length; i < len; i++) {
            if (
                accesses[i].roleId !== removeAccess.roleId ||
                accesses[i].supplierId !== removeAccess.supplierId ||
                accesses[i].contractorId !== removeAccess.contractorId
            ) {
                acessUpdate.push(accesses[i]);
            }
        }

        dispatch({
            type: actionTypes.USER_ADD_ACCESS,
            accesses: acessUpdate,
        });
    };
};

export const sendNewPassword = user => {
    return dispatch => {
        dispatch({
            type: actionTypes.USER_SEND_PASSWORD_REQUEST,
        });

        const { emailAddress } = user;

        return axios
            .post(`/Users/${user.userId}/ResetPasswordRequests`, {
                emailAddress: emailAddress,
                clientType: 'Web',
                clientVersion: getAppVersion(),
            })
            .then(() => {
                dispatch({
                    type: actionTypes.USER_SEND_PASSWORD_SUCCESS,
                });

                toast.success(
                    'E-mail de alteração de senha enviado com sucesso.',
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.USER_SEND_PASSWORD_FAILURE,
                    error,
                });
            });
    };
};
