import React from 'react';

const ErrorIcon = () => (
    <svg
        width='290'
        height='229'
        viewBox='0 0 290 229'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            opacity='0.1'
            d='M179.077 65.107C163.494 60.3513 149.867 51.2884 137.042 41.9329C124.217 32.5775 111.688 22.6642 97.1196 15.6251C87.7497 11.0975 76.5282 7.80323 67.1654 10.9287C58.1483 13.9501 53.3342 22.0203 49.8318 29.774C47.1955 35.6081 44.8861 41.8742 46.0965 48.3221C46.939 52.7994 49.4237 57.0384 50.7434 61.4649C55.3354 76.8638 45.2173 91.625 32.9466 102.172C27.1929 107.119 20.8435 111.616 15.9275 117.199C11.0115 122.782 7.55291 129.789 8.5807 137.123C9.59858 144.387 14.8817 151.043 21.1018 156.399C33.734 167.28 50.2852 173.859 66.655 179.462C102.882 191.856 140.265 200.711 177.553 209.534C191.353 212.802 205.212 216.078 219.245 217.583C227.038 218.418 235.256 218.631 241.888 215.608C250.304 211.77 254.591 202.603 251.881 194.239C247.337 180.205 226.717 171.497 224.733 157.214C223.646 149.352 228.814 142.306 234.902 136.918C247.961 125.355 266.839 117.2 271.962 101.36C275.481 90.48 270.954 77.4261 260.775 69.1033C250.107 60.3801 233.093 56.5592 221.718 63.2184C209.999 70.0625 192.837 69.3092 179.077 65.107Z'
            fill='#2C8DFF'
        />
        <g clip-path='url(#clip0)'>
            <path
                d='M156.843 34.6039L53.6868 56.7961L84.2682 199.702L187.424 177.51L156.843 34.6039Z'
                fill='#E0E0E0'
            />
            <g opacity='0.5'>
                <path
                    opacity='0.5'
                    d='M155.618 37.395L55.9481 58.8372L85.4958 196.913L185.165 175.47L155.618 37.395Z'
                    fill='url(#paint0_linear)'
                />
            </g>

            <path
                d='M154.565 38.6843L57.4331 59.5806L86.328 194.605L183.46 173.709L154.565 38.6843Z'
                fill='#FAFAFA'
            />
            <path
                d='M128.967 38.0684L80.7809 48.4348L83.2854 60.1381L131.471 49.7717L128.967 38.0684Z'
                fill='url(#paint1_linear)'
            />
            <path
                d='M102.426 31.8208C98.706 32.622 96.3522 36.3666 97.1706 40.1838C97.989 44.001 101.666 46.4507 105.379 45.6494C109.092 44.8482 111.453 41.1036 110.634 37.2864C109.816 33.4692 106.146 31.0196 102.426 31.8208ZM104.761 42.7375C103.975 42.9255 103.15 42.8745 102.393 42.5911C101.636 42.3076 100.98 41.8045 100.509 41.1459C100.038 40.4873 99.773 39.703 99.7482 38.8929C99.7234 38.0828 99.9397 37.2837 100.37 36.5974C100.799 35.911 101.423 35.3686 102.162 35.0391C102.9 34.7097 103.72 34.6081 104.516 34.7475C105.313 34.8868 106.049 35.2607 106.633 35.8215C107.217 36.3823 107.62 37.1046 107.793 37.8964C107.912 38.4139 107.928 38.95 107.84 39.4738C107.752 39.9977 107.562 40.4989 107.28 40.9487C106.998 41.3986 106.631 41.7882 106.198 42.0951C105.766 42.4021 105.278 42.6204 104.761 42.7375Z'
                fill='url(#paint2_linear)'
            />
            <path
                d='M128.352 37.6757L81.1809 47.8237L83.575 59.0111L130.746 48.8631L128.352 37.6757Z'
                fill='#2C8DFF'
            />
            <path
                d='M102.426 31.821C101.122 32.1015 99.9308 32.7635 99.0021 33.7231C98.0735 34.6827 97.4495 35.8969 97.209 37.212C96.9686 38.5272 97.1225 39.8843 97.6513 41.1118C98.1801 42.3393 99.06 43.382 100.18 44.1081C101.3 44.8341 102.609 45.211 103.942 45.1909C105.276 45.1709 106.573 44.7548 107.671 43.9954C108.769 43.2359 109.617 42.1672 110.109 40.9244C110.601 39.6816 110.714 38.3204 110.434 37.0131C110.248 36.1449 109.894 35.322 109.391 34.5912C108.888 33.8604 108.246 33.2361 107.503 32.754C106.759 32.2719 105.928 31.9413 105.057 31.7812C104.186 31.6211 103.292 31.6346 102.426 31.821ZM104.657 42.2536C103.904 42.4155 103.121 42.35 102.405 42.0654C101.69 41.7808 101.075 41.2898 100.637 40.6546C100.2 40.0194 99.9608 39.2685 99.9493 38.4968C99.9378 37.7251 100.155 36.9673 100.573 36.3193C100.991 35.6713 101.591 35.1621 102.298 34.8562C103.005 34.5502 103.786 34.4613 104.543 34.6006C105.3 34.7399 105.999 35.1012 106.551 35.6387C107.103 36.1763 107.484 36.866 107.646 37.6206C107.862 38.6324 107.669 39.6888 107.108 40.5576C106.548 41.4265 105.666 42.0365 104.657 42.2536Z'
                fill='#2C8DFF'
            />
            <path
                d='M204.763 52.2988H99.2588V198.457H204.763V52.2988Z'
                fill='#E0E0E0'
            />

            <g opacity='0.5'>
                <path
                    opacity='0.5'
                    d='M202.981 54.77H101.043V195.988H202.981V54.77Z'
                    fill='url(#paint3_linear)'
                />
            </g>

            <path d='M201.343 56H102V194.098H201.343V56Z' fill='white' />
            <path
                d='M176.782 49.8225H127.5V61.7922H176.782V49.8225Z'
                fill='url(#paint4_linear)'
            />
            <path
                d='M152.141 38.1387C148.336 38.1387 145.251 41.3048 145.251 45.2116C145.251 49.1184 148.336 52.2844 152.141 52.2844C155.946 52.2844 159.031 49.1184 159.031 45.2116C159.031 41.3048 155.946 38.1387 152.141 38.1387ZM152.141 49.2926C151.332 49.3119 150.536 49.089 149.854 48.6524C149.172 48.2158 148.635 47.5854 148.313 46.8416C147.99 46.0978 147.895 45.2743 148.041 44.4764C148.187 43.6785 148.567 42.9423 149.132 42.3618C149.697 41.7813 150.422 41.3829 151.214 41.2174C152.006 41.052 152.83 41.1269 153.579 41.4328C154.328 41.7386 154.97 42.2614 155.422 42.9345C155.873 43.6076 156.115 44.4003 156.115 45.2116C156.123 46.2778 155.709 47.3037 154.964 48.0648C154.219 48.826 153.204 49.2605 152.141 49.2733V49.2926Z'
                fill='url(#paint5_linear)'
            />
            <path
                d='M176.263 49.3093H128.019V60.7513H176.263V49.3093Z'
                fill='#2C8DFF'
            />
            <path
                d='M152.141 38.1387C150.807 38.1387 149.504 38.5352 148.395 39.278C147.286 40.0209 146.422 41.0768 145.912 42.3121C145.401 43.5475 145.268 44.9068 145.528 46.2182C145.788 47.5297 146.43 48.7343 147.373 49.6798C148.316 50.6253 149.518 51.2692 150.826 51.53C152.134 51.7909 153.489 51.657 154.721 51.1453C155.954 50.6336 157.007 49.7671 157.747 48.6553C158.488 47.5435 158.884 46.2364 158.884 44.8993C158.884 43.1063 158.173 41.3867 156.909 40.1188C155.644 38.851 153.929 38.1387 152.141 38.1387ZM152.141 48.8013C151.372 48.8003 150.62 48.5709 149.981 48.142C149.342 47.7131 148.844 47.1039 148.55 46.3914C148.256 45.6789 148.179 44.895 148.329 44.1387C148.479 43.3823 148.849 42.6875 149.392 42.1419C149.936 41.5963 150.628 41.2244 151.383 41.0732C152.137 40.9219 152.919 40.9981 153.63 41.2921C154.341 41.5861 154.949 42.0847 155.377 42.725C155.806 43.3653 156.036 44.1185 156.038 44.8896C156.037 45.4022 155.936 45.9098 155.74 46.3832C155.544 46.8567 155.257 47.2867 154.895 47.6489C154.533 48.011 154.104 48.2981 153.631 48.4938C153.159 48.6894 152.652 48.7898 152.141 48.7892V48.8013Z'
                fill='#2C8DFF'
            />

            <g opacity='0.5'>
                <path
                    opacity='0.5'
                    d='M41.5941 202.408H40.6864V207.571H41.5941V202.408Z'
                    fill='#47E6B1'
                />
                <path
                    opacity='0.5'
                    d='M43.7162 205.446V204.535H38.5668V205.446H43.7162Z'
                    fill='#47E6B1'
                />
            </g>

            <g opacity='0.5'>
                <path
                    opacity='0.5'
                    d='M146.905 24H145.997V29.1631H146.905V24Z'
                    fill='#47E6B1'
                />
                <path
                    opacity='0.5'
                    d='M149.027 27.0354V26.1252H143.877V27.0354H149.027Z'
                    fill='#47E6B1'
                />
            </g>

            <path
                opacity='0.5'
                d='M223.151 211.003C222.901 210.894 222.699 210.696 222.585 210.447C222.471 210.199 222.451 209.917 222.531 209.655C222.544 209.615 222.552 209.573 222.555 209.531C222.562 209.411 222.531 209.292 222.465 209.192C222.4 209.091 222.304 209.015 222.191 208.973C222.079 208.931 221.956 208.927 221.841 208.961C221.726 208.995 221.625 209.065 221.553 209.161C221.528 209.195 221.507 209.231 221.49 209.27C221.381 209.521 221.184 209.723 220.936 209.838C220.688 209.952 220.407 209.972 220.146 209.892C220.106 209.879 220.064 209.871 220.022 209.868C219.903 209.86 219.784 209.892 219.684 209.958C219.584 210.023 219.507 210.12 219.466 210.232C219.424 210.345 219.42 210.468 219.454 210.583C219.488 210.699 219.557 210.8 219.653 210.872C219.687 210.897 219.723 210.919 219.762 210.935C220.012 211.044 220.214 211.242 220.328 211.491C220.443 211.739 220.462 212.021 220.382 212.283C220.369 212.323 220.361 212.365 220.358 212.407C220.351 212.527 220.382 212.646 220.448 212.746C220.513 212.847 220.609 212.924 220.722 212.965C220.834 213.007 220.957 213.011 221.072 212.977C221.187 212.943 221.288 212.873 221.36 212.777C221.385 212.743 221.406 212.707 221.423 212.668C221.532 212.417 221.729 212.215 221.977 212.1C222.225 211.986 222.506 211.966 222.767 212.046C222.807 212.059 222.849 212.067 222.891 212.07C223.01 212.078 223.129 212.046 223.229 211.98C223.329 211.915 223.406 211.818 223.447 211.706C223.489 211.593 223.493 211.47 223.459 211.355C223.425 211.239 223.356 211.138 223.26 211.066C223.226 211.041 223.19 211.02 223.151 211.003Z'
                fill='#4D8AF0'
            />
            <path
                opacity='0.5'
                d='M69.9944 161.367C69.744 161.258 69.5424 161.06 69.428 160.811C69.3136 160.563 69.2944 160.281 69.3739 160.019C69.3868 159.979 69.3949 159.937 69.3981 159.895C69.4053 159.775 69.3738 159.656 69.3083 159.556C69.2428 159.455 69.1467 159.379 69.0343 159.337C68.9219 159.295 68.7992 159.291 68.6843 159.325C68.5693 159.359 68.4683 159.429 68.3962 159.525C68.3711 159.559 68.35 159.595 68.3334 159.634C68.2244 159.885 68.0272 160.087 67.7793 160.202C67.5313 160.316 67.25 160.336 66.9888 160.256C66.9488 160.243 66.9075 160.235 66.8656 160.232C66.7461 160.225 66.6274 160.256 66.5271 160.322C66.4268 160.387 66.3503 160.484 66.3088 160.596C66.2674 160.709 66.2633 160.832 66.297 160.947C66.3308 161.063 66.4006 161.164 66.4963 161.236C66.5299 161.261 66.5663 161.283 66.6049 161.299C66.8553 161.408 67.0568 161.606 67.1712 161.855C67.2856 162.103 67.3049 162.386 67.2253 162.647C67.2125 162.687 67.2044 162.729 67.2012 162.771C67.194 162.891 67.2255 163.01 67.291 163.11C67.3565 163.211 67.4526 163.288 67.565 163.329C67.6773 163.371 67.8001 163.375 67.915 163.341C68.0299 163.307 68.1309 163.237 68.2031 163.141C68.2282 163.107 68.2492 163.071 68.2658 163.032C68.3748 162.781 68.5721 162.579 68.82 162.464C69.068 162.35 69.3493 162.33 69.6105 162.41C69.6505 162.423 69.6918 162.431 69.7337 162.434C69.8532 162.442 69.9719 162.41 70.0722 162.344C70.1725 162.279 70.249 162.182 70.2904 162.07C70.3319 161.957 70.336 161.834 70.3023 161.719C70.2685 161.603 70.1986 161.502 70.103 161.43C70.0694 161.405 70.0329 161.384 69.9944 161.367Z'
                fill='#FDD835'
            />
            <path
                opacity='0.5'
                d='M197.561 40.5034C197.311 40.3942 197.109 40.1964 196.995 39.9478C196.88 39.6992 196.861 39.4171 196.941 39.1552C196.954 39.1151 196.962 39.0737 196.965 39.0317C196.972 38.9119 196.941 38.7928 196.875 38.6923C196.81 38.5918 196.713 38.515 196.601 38.4735C196.489 38.4319 196.366 38.4278 196.251 38.4616C196.136 38.4955 196.035 38.5655 195.963 38.6614C195.938 38.6951 195.917 38.7317 195.9 38.7703C195.791 39.0214 195.594 39.2235 195.346 39.3382C195.098 39.4529 194.817 39.4722 194.556 39.3924C194.516 39.3795 194.474 39.3714 194.432 39.3682C194.313 39.361 194.194 39.3925 194.094 39.4582C193.994 39.5239 193.917 39.6202 193.876 39.7329C193.834 39.8456 193.83 39.9687 193.864 40.0839C193.898 40.1991 193.967 40.3004 194.063 40.3727C194.097 40.3979 194.133 40.419 194.172 40.4357C194.422 40.5449 194.624 40.7427 194.738 40.9913C194.852 41.2399 194.872 41.522 194.792 41.7839C194.779 41.824 194.771 41.8654 194.768 41.9074C194.761 42.0273 194.792 42.1463 194.858 42.2468C194.923 42.3474 195.019 42.4241 195.132 42.4656C195.244 42.5072 195.367 42.5113 195.482 42.4775C195.597 42.4436 195.698 42.3736 195.77 42.2777C195.795 42.244 195.816 42.2074 195.833 42.1688C195.942 41.9178 196.139 41.7157 196.387 41.6009C196.635 41.4862 196.916 41.4669 197.177 41.5467C197.217 41.5596 197.259 41.5677 197.3 41.5709C197.42 41.5781 197.539 41.5466 197.639 41.4809C197.739 41.4152 197.816 41.3189 197.857 41.2062C197.899 41.0935 197.903 40.9704 197.869 40.8552C197.835 40.74 197.765 40.6387 197.67 40.5664C197.636 40.5412 197.6 40.5201 197.561 40.5034Z'
                fill='#FDD835'
            />
            <path
                opacity='0.5'
                d='M233.185 101.942C234.189 101.942 235.002 101.126 235.002 100.12C235.002 99.1129 234.189 98.2969 233.185 98.2969C232.181 98.2969 231.367 99.1129 231.367 100.12C231.367 101.126 232.181 101.942 233.185 101.942Z'
                fill='#F55F44'
            />
            <path
                opacity='0.5'
                d='M92.702 206.554C93.706 206.554 94.5199 205.738 94.5199 204.731C94.5199 203.725 93.706 202.909 92.702 202.909C91.698 202.909 90.8842 203.725 90.8842 204.731C90.8842 205.738 91.698 206.554 92.702 206.554Z'
                fill='#F55F44'
            />
            <path
                opacity='0.5'
                d='M214.516 141.339C215.52 141.339 216.334 140.523 216.334 139.517C216.334 138.51 215.52 137.694 214.516 137.694C213.512 137.694 212.698 138.51 212.698 139.517C212.698 140.523 213.512 141.339 214.516 141.339Z'
                fill='#F55F44'
            />
            <path
                opacity='0.5'
                d='M58.037 49.1449C59.041 49.1449 59.8548 48.3288 59.8548 47.3222C59.8548 46.3156 59.041 45.4995 58.037 45.4995C57.033 45.4995 56.2191 46.3156 56.2191 47.3222C56.2191 48.3288 57.033 49.1449 58.037 49.1449Z'
                fill='#4D8AF0'
            />
            <path
                opacity='0.5'
                d='M38.8179 98.8656C39.8218 98.8656 40.6357 98.0495 40.6357 97.0429C40.6357 96.0363 39.8218 95.2202 38.8179 95.2202C37.8139 95.2202 37 96.0363 37 97.0429C37 98.0495 37.8139 98.8656 38.8179 98.8656Z'
                fill='#47E6B1'
            />
        </g>
        AQUI BOTAO EM BAIXO
        <path
            d='M143 130.907V125.998H147.909'
            stroke='#E0E0E0'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M161 117.816V122.725H156.091'
            stroke='#E0E0E0'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M145.054 121.907C145.469 120.735 146.174 119.686 147.104 118.86C148.033 118.033 149.157 117.456 150.37 117.182C151.584 116.907 152.847 116.944 154.042 117.29C155.236 117.636 156.324 118.278 157.204 119.158L161 122.725M143 125.998L146.796 129.565C147.676 130.445 148.764 131.088 149.958 131.433C151.153 131.779 152.416 131.816 153.63 131.542C154.843 131.267 155.967 130.69 156.896 129.864C157.826 129.037 158.531 127.989 158.946 126.816'
            stroke='#E0E0E0'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <defs>
            <linearGradient
                id='paint0_linear'
                x1='135.329'
                y1='186.192'
                x2='105.632'
                y2='48.1486'
                gradientUnits='userSpaceOnUse'
            >
                <stop stop-color='#808080' stop-opacity='0.25' />
                <stop offset='0.54' stop-color='#808080' stop-opacity='0.12' />
                <stop offset='1' stop-color='#808080' stop-opacity='0.1' />
            </linearGradient>

            <linearGradient
                id='paint1_linear'
                x1='26546'
                y1='-3437.14'
                x2='26421.6'
                y2='-4015.73'
                gradientUnits='userSpaceOnUse'
            >
                <stop stop-color='#808080' stop-opacity='0.25' />
                <stop offset='0.54' stop-color='#808080' stop-opacity='0.12' />
                <stop offset='1' stop-color='#808080' stop-opacity='0.1' />
            </linearGradient>

            <linearGradient
                id='paint2_linear'
                x1='7777.71'
                y1='418.786'
                x2='7604.06'
                y2='-388.402'
                gradientUnits='userSpaceOnUse'
            >
                <stop stop-color='#808080' stop-opacity='0.25' />
                <stop offset='0.54' stop-color='#808080' stop-opacity='0.12' />
                <stop offset='1' stop-color='#808080' stop-opacity='0.1' />
            </linearGradient>

            <linearGradient
                id='paint3_linear'
                x1='48664.3'
                y1='100394'
                x2='48664.3'
                y2='18006.4'
                gradientUnits='userSpaceOnUse'
            >
                <stop stop-color='#808080' stop-opacity='0.25' />
                <stop offset='0.54' stop-color='#808080' stop-opacity='0.12' />
                <stop offset='1' stop-color='#808080' stop-opacity='0.1' />
            </linearGradient>

            <linearGradient
                id='paint4_linear'
                x1='23632.4'
                y1='1918.65'
                x2='23632.4'
                y2='1326.75'
                gradientUnits='userSpaceOnUse'
            >
                <stop stop-color='#808080' stop-opacity='0.25' />
                <stop offset='0.54' stop-color='#808080' stop-opacity='0.12' />
                <stop offset='1' stop-color='#808080' stop-opacity='0.1' />
            </linearGradient>

            <linearGradient
                id='paint5_linear'
                x1='9334.61'
                y1='2533.31'
                x2='9334.61'
                y2='1706.63'
                gradientUnits='userSpaceOnUse'
            >
                <stop stop-color='#808080' stop-opacity='0.25' />
                <stop offset='0.54' stop-color='#808080' stop-opacity='0.12' />
                <stop offset='1' stop-color='#808080' stop-opacity='0.1' />
            </linearGradient>

            <clipPath id='clip0'>
                <rect
                    width='198'
                    height='189'
                    fill='white'
                    transform='translate(37 24)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default ErrorIcon;
