import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    searchItemOrders: {
        id: 'documents.searchItemOrders',
        defaultMessage: 'Escolha um Item',
    },
    itemOrder: {
        id: 'documents.itemOrder',
        defaultMessage: 'Item do Pedido',
    },
    titleOrderCode: {
        id: 'documents.titleOrderCode',
        defaultMessage: 'Pedido',
    },
    itemOrderDescription: {
        id: 'documents.itemOrderDescription',
        defaultMessage: 'Descrição do Item',
    },
    totalGrossValue: {
        id: 'documents.totalGrossValue',
        defaultMessage: 'Valor bruto',
    },
    orderMeasure: {
        id: 'orderItem.orderMeasure',
        defaultMessage: 'Medição',
    },
    shippingDate: {
        id: 'orders.shippingDate',
        defaultMessage: 'Data de remessa',
    },
    nfeItemNumber: {
        id: 'nfe.nfeItemNumber',
        defaultMessage: 'Item do Nfe',
    },
    description: {
        id: 'nfe.description',
        defaultMessage: 'Descrição do Pedido',
    },

    itemNfeNumber: {
        id: 'nfe.itemNfeNumber',
        defaultMessage: 'Número do item',
    },
    itemNfeDescription: {
        id: 'nfe.itemNfeDescription',
        defaultMessage: 'Descrição do Item de nfe',
    },
    ncm: {
        id: 'nfe.ncm',
        defaultMessage: 'NCM',
    },
    quantity: {
        id: 'nfe.quantity',
        defaultMessage: 'Quantidade',
    },
    unitaryNetValue: {
        id: 'nfe.unitaryNetValue',
        defaultMessage: 'Valor Unitário',
    },
    totalItemValue: {
        id: 'nfe.totalItemValue',
        defaultMessage: 'Valor total do item',
    },
});
