export const viewerModeTypes = {
    POINTER: 1,
    GRAB: 2,
    ADD: 3,
    MOVE: 4,
};

export const formGroupTypes = {
    GENERAL: 1,
    PROVIDER: 2,
    BORROWER: 3,
    MIDDLEWARE: 4,
    SERVICES: 5,
    TAXES: 6,
    OTHERS: 7,
    PARTS : 8,
    DOCUMENT : 9,  
    MUNICIPALTAXES : 10,
    FEDERALTAXES : 11, 
    TEXTS : 12,
};

export const mapActionTypes = {
    INSERT: 1,
    PAD_LEFT: 2,
    PAD_RIGHT: 3,
    REMOVE: 4,
    REPLACE: 5,
    TRIM: 6,
    TRIM_START: 7,
    TRIM_END: 8,
    KEEP: 9,
};

export const mapActionConditions = {
    REGEX: 1,
    MIN_LENGTH: 2,
    MAX_LENGTH: 3,
};

export const feedbackCodes = {
    SUCCESS: 100,
    MAP_NOT_FOUND: 200,
    EXTERNAL_COMMUNICATION_FAILED: 201,
    FATAL_ERROR: 202,
};

export const auditLogTypes = {
    ADD: 1,
    UPDATE: 2,
    DELETE: 3,
    ERROR: 4,
};

export const technicalLogTypes = {
    DEBUG: 1,
    INFO: 2,
    WARNING: 3,
    ERROR: 4,
    FATAL: 5,
};

export const filterPropertyTypes = {
    TEXT: 1,
    NUMBER: 2,
    DATE: 3,
    ENUM: 4,
    SELECT: 5,
};

export const categoryTypes = {
    NFEE_MOD_6: 1,
    NFEE_MOD_AGRU_6: 2,
    NFSC_MOD_21: 3,
    NFST_MOD_22: 4,
    NFST_MOD_AGRU_22: 5,
    DOC_SEM_NF: 6,
    CTE_MOD_67: 7,
    BOLETO: 8,
};

export const sources = {
    portal: 'WEB_UI',
    email: 'EMAIL_SERVICE',
    webService: 'WEBSERVICE',
};

export const statusDescription = {
    filePending: 'Aguardando arquivo',
    processPending: 'Aguardando processamento',
    revisionPending: 'Aguardando revisão',
    integrationPending: 'Aguardando integração',
    integrationSuccess: 'Integrado com sucesso',
    centralPending: 'Aguardando central receiver',
    payPending: 'Pagamento Pendente',
    inProcessPending: 'Em processamento',
    documentRevised: 'Documento revisado',
    successWritten: 'Escriturado com sucesso',
    successProcess: 'Processado com Sucesso',
    payed: 'Pagamento Realizado',
    errorSearchImage: 'Erro ao buscar imagem',
    communicationFailed: 'Falha na comunicação',
    processError: 'Falha no processamento',
    canceled: 'Cancelado pelo Emissor',
    error: 'Cancelado',
    documentRejected: 'Documento Rejeitado',
    Duplicidade: 'Duplicidade',
    divergentEnterprise: 'Empresa Divergente',
    documentReversed: 'Documento foi estornado',
    integrationSuccess: 'Integrado com sucesso',
    integrated: 'Integrado',
    canceledLowReading: 'Cancelado automaticamente por baixa leitura de OCR',
    noBorrowerBranch: 'Nenhum Centro/Filial encontrado para o CNPJ do Tomador',
};

export const DocumentTypes = {
    NFS: 'NFS',
    NFE: 'NFE',
    CTE: 'CTE',
    OTHER: 'OTHER',
};
