import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Container } from 'reactstrap';
import { useHistory, withRouter } from 'react-router';
import * as actions from '../../store/actions/index';

import globalMessages from '../../utils/globalMessages';

import { useLinkingOrders } from '../../context/LinkingOrdersContext';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import ModalRemoveOrderItem from './components/ModalRemoveOrderItem';
import OrdersToAssociateTable from './components/OrdersToAssociateTable';
import AssociatedOrdersTable from './components/AssociatedOrdersTable';
import { connect } from 'react-redux';
import NfeItemAssociationTable from './components/NfeItemAssociationTable';

function OrderDocumentLinkingPage({
    intl,
    documentSearched,
    nfeDocument,
    cteDocument,
    onInitDocumentDetails,
    onInitDocumentNfeDetails,
    onInitDocumentCteDetails,
}) {
    const {
        documentId,
        setOrderItemToRemove,
        selectDocument,
        setSelectedDocument,
        selectedNfeItem,
    } = useLinkingOrders();

    const history = useHistory();
    const [showModalRemoveItem, setShowModalRemoveItem] = useState(false);

    const currentPath = history.location.pathname.split('/')[1];

    const hiddenOrdersToAssociated = docType === 'NFE' && !selectedNfeItem;

    const getDocTypeByUrl = () => {
        switch (currentPath.toLowerCase()) {
            case 'nfedocuments':
                return 'NFE';

            case 'ctedocuments':
                return 'CTE';

            case 'otherdocuments':
                return 'OTHER';

            default:
                return 'NFS';
        }
    };

    const docType = getDocTypeByUrl();

    useEffect(() => {
        let getDocumentDetails = () => onInitDocumentDetails(documentId);
        let documentByDocType = documentSearched;

        switch (docType) {
            case 'NFE':
                documentByDocType = nfeDocument;
                getDocumentDetails = () => onInitDocumentNfeDetails(documentId);
                break;
            case 'CTE':
                documentByDocType = cteDocument;
                getDocumentDetails = () => onInitDocumentCteDetails(documentId);
                break;
            default:
                break;
        }

        if (!selectDocument && !!documentByDocType) {
            setSelectedDocument(documentByDocType);
        } else if (!selectDocument) {
            getDocumentDetails();
        }
    }, [selectDocument, documentSearched, nfeDocument, cteDocument]);

    const handleToggleModalRemoveItem = () =>
        setShowModalRemoveItem(prev => !prev);

    const handleDeleteItemEvent = (orderItem = null) => {
        setShowModalRemoveItem(true);
        !!orderItem && setOrderItemToRemove(orderItem);
    };

    const getCNPJTaker = () => {
        if (docType !== 'CTE') return;
        const formFields = cteDocument?.form?.fields;
        const TomaTag = formFields?.find(
            x => x.key === '/cteProc/CTe/infCte/ide/toma3/toma',
        )?.value;

        let keyCNPJTaker = '';
        switch (String(TomaTag).trim()) {
            case '0':
                keyCNPJTaker = '/cteProc/CTe/infCte/rem/CNPJ';
                break;
            case '1':
                keyCNPJTaker = '/cteProc/CTe/infCte/exped/CNPJ';
                break;
            case '2':
                keyCNPJTaker = '/cteProc/CTe/infCte/receb/CNPJ';
                break;
            case '3':
                keyCNPJTaker = '/cteProc/CTe/infCte/dest/CNPJ';
                break;
            default:
                keyCNPJTaker = '/cteProc/CTe/infCte/toma/CNPJ';
                break;
        }

        const hasCTeOS =
            formFields?.find(x => x.key.includes('/cteOSProc/CTeOS')) &&
            'CTeOS';

        if (hasCTeOS === 'CTeOS') {
            keyCNPJTaker = keyCNPJTaker?.replace(
                '/cteProc/CTe',
                '/cteOSProc/CTeOS',
            );
        }

        const CNPJTakerValue = formFields
            ?.find(x => x.key === keyCNPJTaker)
            ?.value?.replace(/[^0-9]/g, '');

        return CNPJTakerValue;
    };

    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(globalMessages.home),
                        active: false,
                    },
                    {
                        path: `/${currentPath}`,
                        name: 'Documentos',
                        active: false,
                    },
                    {
                        path: `/${currentPath}/${documentId}`,
                        name: `Documento ${documentId}`,
                        active: false,
                    },
                    {
                        path: `/${currentPath}/${documentId}/orders`,
                        name: 'Pedidos do Documento',
                        active: true,
                    },
                ]}
            />
            <header>
                <section className='title'>
                    <Header title={'Pedidos do documento'} />
                </section>
            </header>

            {docType === 'NFE' && (
                <>
                    <NfeItemAssociationTable />
                </>
            )}

            {!hiddenOrdersToAssociated && (
                <OrdersToAssociateTable
                    docType={
                        docType === 'NFS' || docType === 'OTHER'
                            ? 'Documents'
                            : docType
                    }
                    CNPJTakerAlt={docType === 'CTE' && getCNPJTaker()}
                />
            )}

            <AssociatedOrdersTable
                docType={docType}
                handleDeleteItemEvent={handleDeleteItemEvent}
            />

            <button
                type='button'
                onClick={() => history.push(`/${currentPath}/${documentId}`)}
                style={{
                    float: 'right',
                    marginRight: 10,
                    width: '200px',
                    marginBottom: 20,
                }}
            >
                Retornar ao documento
            </button>

            <ModalRemoveOrderItem
                handleToggleModal={handleToggleModalRemoveItem}
                isOpen={showModalRemoveItem}
                docType={
                    docType === 'NFS' || docType === 'OTHER'
                        ? 'Documents'
                        : docType
                }
            />
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        documentSearched: state.documentReducer.selectedDocument,
        nfeDocument: state.NFeReducer.nfeDocument,
        cteDocument: state.cteReducer.cteDocument,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitDocumentDetails: documentId =>
            dispatch(actions.initDocumentDetails(documentId)),
        onInitDocumentNfeDetails: nfeDocumentId =>
            dispatch(actions.initDocumentsNfeDetails(nfeDocumentId)),
        onInitDocumentCteDetails: cteDocumentId =>
            dispatch(actions.initDocumentsCteDetails(cteDocumentId)),
    };
};

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(OrderDocumentLinkingPage),
    ),
);
