import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import messages from './SettingsEmailListPage.intl';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import * as actions from '../../store/actions/index';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import { AuthContext } from '../../context/AuthContext';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';

class SettingsEmailListPage extends Component {
    static contextType = AuthContext;
    state = {
        filters: [],
        query: '',
    };

    reloadMailBoxesList() {
        if (this.context.hasClaim('setting-view')) {
            const { query } = this.state;
            const contractorId = this.props.session.contractorId;

            this.props.history.push('/settings/settingsmailboxes' + query);

            this.props.onInitMailBox(query, contractorId);
        }
    }

    handleFiltersChange = filters => {
        this.setState({
            filters: filters,
        });
    };

    handleNewEmailButtonClick = () => {
        this.props.history.push(`/settings/settingsmailboxes/newmailbox`);
    };

    handleGetTrProps = (_state, rowInfo, _column, _instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: _e => this.props.onGetMailBox(rowInfo.original.setupId),
        };
    };

    handleRefreshSettingsInboundButtonClick = () => {
        this.reloadMailBoxesList();
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadMailBoxesList();
            },
        );
    };

    render() {
        const { intl, loadingList, mailBoxes, mailBoxesCount } = this.props;

        const { filters } = this.state;

        const newEmailButtonEnabled = this.context.hasClaim('setting-create');

        const properties = [
            {
                key: 'setupName',
                value: intl.formatMessage(messages.setupName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'smtpServerHost',
                value: intl.formatMessage(messages.smtpHost),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'imapServerHost',
                value: intl.formatMessage(messages.imapHost),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'url',
                value: intl.formatMessage(messages.url),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'active',
                value: intl.formatMessage(messages.active),
                type: filterPropertyTypes.ENUM,
                options: [
                    { key: 'true', value: 'Ativo' },
                    { key: 'false', value: 'Desativado' },
                ],
            },
        ];

        const columnsEmails = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.setupName)}</span>
                    </div>
                ),
                accessor: 'setupName',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                maxWidth:
                    250 +
                    calculateColumnWidth(
                        mailBoxes,
                        'setupName',
                        intl.formatMessage(messages.setupName),
                    ),
                minWidth:
                    75 +
                    calculateColumnWidth(
                        mailBoxes,
                        'setupName',
                        intl.formatMessage(messages.setupName),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'email',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                maxWidth:
                    200 +
                    calculateColumnWidth(
                        mailBoxes,
                        'email',
                        intl.formatMessage(messages.email),
                    ),
                minWidth:
                    80 +
                    calculateColumnWidth(
                        mailBoxes,
                        'email',
                        intl.formatMessage(messages.email),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.host)}</span>
                    </div>
                ),
                accessor: 'smtpServerHost',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>
                                {row.original.smtpServerHost ||
                                    row.original.imapServerHost}
                            </div>
                        </div>
                    );
                },
                maxWidth:
                    250 +
                    calculateColumnWidth(
                        mailBoxes,
                        'smtpServerHost',
                        intl.formatMessage(messages.smtpHost),
                    ),
                minWidth:
                    60 +
                    calculateColumnWidth(
                        mailBoxes,
                        'smtpServerHost',
                        intl.formatMessage(messages.smtpHost),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.server)}</span>
                    </div>
                ),
                accessor: 'imapServerHost',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>
                                {row.original.smtpServerHost ? 'SMTP' : 'IMAP'}
                            </div>
                        </div>
                    );
                },
                maxWidth: 100 /* +
                    calculateColumnWidth(
                        mailBoxes,
                        'imapServerHost',
                        intl.formatMessage(messages.server),
                    )*/,
                minWidth: 80 /* +
                    calculateColumnWidth(   
                        mailBoxes,
                        'imapServerHost',
                        intl.formatMessage(messages.server),
                    ) */,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.url)}</span>
                    </div>
                ),
                accessor: 'url',
                Cell: row => (
                    <>
                        <p>{row.value}</p>
                    </>
                ),
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    100 +
                    calculateColumnWidth(
                        mailBoxes,
                        'url',
                        intl.formatMessage(messages.url),
                    ),
                minWidth:
                    35 +
                    calculateColumnWidth(
                        mailBoxes,
                        'url',
                        intl.formatMessage(messages.url),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.active)}</span>
                    </div>
                ),
                accessor: 'active',
                Cell: row => <p>{row.value ? 'Ativo' : 'Desativado'}</p>,
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    80 +
                    calculateColumnWidth(
                        mailBoxes,
                        'active',
                        intl.formatMessage(messages.active),
                    ),
                minWidth:
                    80 +
                    calculateColumnWidth(
                        mailBoxes,
                        'active',
                        intl.formatMessage(messages.active),
                    ),
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/settings',
                            name: intl.formatMessage(messages.titleSettings),
                            active: false,
                        },
                        {
                            path: '/settings/settingsmailboxes',
                            name: intl.formatMessage(messages.titleMailBoxes),
                            active: true,
                        },
                    ]}
                />

                <section className='content-middle'>
                    <div>
                        <header className='table-screen'>
                            <section className='title'>
                                <Header
                                    title={intl.formatMessage(
                                        messages.titleMailBoxes,
                                    )}
                                />
                            </section>
                            <section className='btns-topo'>
                                <EvtFilter
                                    properties={properties}
                                    handleFiltersChange={
                                        this.handleFiltersChange
                                    }
                                    loading={loadingList}
                                    hideSearchBar
                                    hideAddButton
                                />
                                <button
                                    className={`new-btn small ${
                                        this.props.loadingList ? 'loading' : ''
                                    }`}
                                    onClick={
                                        this
                                            .handleRefreshSettingsInboundButtonClick
                                    }
                                >
                                    <FontAwesomeIcon icon='sync' size='1x' />
                                </button>
                                {newEmailButtonEnabled && (
                                    <button
                                        className='new-btn'
                                        onClick={this.handleNewEmailButtonClick}
                                    >
                                        {intl.formatMessage(
                                            messages.newButtonText,
                                            {
                                                entity: intl.formatMessage(
                                                    messages.mailBox,
                                                ),
                                            },
                                        )}
                                    </button>
                                )}
                            </section>
                        </header>
                        <section>
                            <EvtTable
                                filters={filters}
                                columns={columnsEmails}
                                data={mailBoxes}
                                length={mailBoxesCount}
                                pageSize={10}
                                handleStateChange={
                                    this.handleEvTableStateChange
                                }
                                handleGetTrProps={this.handleGetTrProps}
                                loading={loadingList}
                                defaultSorted={[
                                    {
                                        id: 'createdAt',
                                        desc: true,
                                    },
                                ]}
                                countHeaderText={intl.formatMessage(
                                    messages.subtitle,
                                    { length: mailBoxesCount },
                                )}
                            />
                        </section>
                    </div>
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        mailBoxes: state.settingsReducer.mailBoxes || [],
        mailBoxesCount: state.settingsReducer.mailBoxesCount || 0,
        loadingList: state.settingsReducer.loadingList,
        session: state.authenticationReducer.session,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitMailBox: (filter, contractorId) =>
            dispatch(actions.initMailBox(filter, contractorId)),
        onGetMailBox: setupId => dispatch(actions.getMailBox(setupId)),
    };
};
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SettingsEmailListPage),
);
