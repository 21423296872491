import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as actions from '../../../store/actions/index';
import { AuthContext } from '../../../context/AuthContext';
import messages from '../UserDetailsPage.intl';

import { AvForm } from 'availity-reactstrap-validation';
import { Col, Container, Row } from 'reactstrap';
import EvtSelect from '../../../components/EvtSelect/EvtSelect';
import Modal from '../../../components/Modal/Modal';

function ModalAddUserAccess(props) {
    const {
        intl,
        showNewAccessModal,
        handleNewAccessModalToogle,
        contractorId,
    } = props;

    const context = useContext(AuthContext);

    const [showContractorComboBox, setShowContractorComboBox] = useState(false);
    const [showSupplierComboBox, setShowSupplierComboBox] = useState(false);
    const [showRoleComboBox, setShowRoleComboBox] = useState(false);

    useEffect(() => {
        if (showNewAccessModal) {
            const { contractor, supplierId } = context;
            const selectedContractor = {
                contractorId: contractor.contractorId,
                displayName: contractor.displayName,
            };

            if (context.hasClaim('role-level-full')) {
                setShowContractorComboBox(true);
                setShowRoleComboBox(false);
                setShowSupplierComboBox(false);
            } else if (context.hasClaim('role-level-contractor')) {
                setShowContractorComboBox(false);
                setShowRoleComboBox(true);
                setShowSupplierComboBox(true);

                props.onSetSelectedContractor(selectedContractor);
            } else if (context.hasClaim('role-level-supplier')) {
                setShowContractorComboBox(false);
                setShowRoleComboBox(true);
                setShowSupplierComboBox(false);

                props.onSetSelectedContractor(selectedContractor);
                props.onSetSelectedSupplier({ supplierId: supplierId });
            }
        }
    }, [showNewAccessModal]);

    function handleContractorChange(selectedContractor) {
        if (selectedContractor) {
            props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });

            setShowRoleComboBox(true);
            setShowSupplierComboBox(false);
        }

        if (!context.hasClaim('role-level-supplier')) {
            props.onSetSelectedSupplier({
                supplierId: '',
                displayName: '',
            });
        }
    }

    function handleRoleChange(selectedRole) {
        if (selectedRole) {
            props.onSetSelectedRole({
                roleId: selectedRole.value,
                name: selectedRole.label,
            });

            setShowSupplierComboBox(true);

            if (!context.hasClaim('role-level-supplier')) {
                props.onSetSelectedSupplier({
                    supplierId: '',
                    displayName: '',
                });
            }
        }
    }

    function handleSupplierChange(selectedSupplier) {
        if (selectedSupplier) {
            props.onSetSelectedSupplier({
                supplierId: selectedSupplier.value,
                displayName: selectedSupplier.label,
            });
        }
    }

    function handleAddAccessModalSubmit(event, values) {
        const { accesses, contractor, role, supplier } = props;
        const { contractorId } = contractor || {};
        const { supplierId } = supplier || {};

        if (context.hasClaim('role-level-contractor')) {
            values = {
                ...values,
            };
        } else if (context.hasClaim('role-level-supplier')) {
            values = {
                ...values,
                contractorId,
                supplierId,
            };
        }

        const found =
            accesses &&
            accesses.find(
                m =>
                    m.roleId === values.roleId &&
                    m.supplierId === values.supplierId &&
                    m.contractorId === values.contractorId,
            );

        if (found) {
            toast.error(intl.formatMessage(messages.userAccessAlreadyAdded));
            return;
        }

        const access = {
            contractorId: contractor?.contractorId,
            contractorName: contractor?.displayName,
            roleId: role?.roleId,
            roleName: role?.name,
            supplierId: supplier?.supplierId,
            supplierName: supplier?.displayName,
        };

        props.onAddUserAccess(access);

        setShowRoleComboBox(false);
        setShowSupplierComboBox(false);

        handleNewAccessModalToogle();
    }

    return (
        <Modal
            title={intl.formatMessage(messages.modalTitle)}
            isOpen={showNewAccessModal}
            handleToggle={handleNewAccessModalToogle}
        >
            <AvForm onValidSubmit={handleAddAccessModalSubmit}>
                <Container fluid>
                    <Row>
                        {showContractorComboBox && (
                            <Col xs={12} className='text-left mt-3'>
                                <EvtSelect
                                    label={intl.formatMessage(messages.company)}
                                    name='contractorId'
                                    url='/Contractors'
                                    labelKey='displayName'
                                    valueKey='contractorId'
                                    async
                                    required
                                    handleSelectedOptionChange={
                                        handleContractorChange
                                    }
                                />
                            </Col>
                        )}

                        {showRoleComboBox && (
                            <Col xs={12} className='text-left mt-3'>
                                <EvtSelect
                                    label={intl.formatMessage(messages.role)}
                                    name='roleId'
                                    url={
                                        contractorId &&
                                        `/Contractors/${contractorId}/Roles`
                                    }
                                    labelKey='name'
                                    valueKey='roleId'
                                    async
                                    required
                                    key={contractorId + 'roleId'}
                                    handleSelectedOptionChange={
                                        handleRoleChange
                                    }
                                />
                            </Col>
                        )}

                        {showSupplierComboBox && (
                            <Col xs={12} className='text-left mt-3'>
                                <EvtSelect
                                    name='supplierId'
                                    label={intl.formatMessage(
                                        messages.supplier,
                                    )}
                                    url={
                                        contractorId &&
                                        `/Contractors/${contractorId}/Suppliers`
                                    }
                                    labelKey='displayName'
                                    labelKey2='documentNumber1'
                                    valueKey='supplierId'
                                    async
                                    required={false}
                                    key={contractorId + 'supplierId'}
                                    handleSelectedOptionChange={
                                        handleSupplierChange
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12}>
                            <button className='btn-submit'>
                                {intl.formatMessage(messages.create)}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        accesses: state.userReducer.accesses || [],
        contractor: state.contractorReducer.contractor,
        supplier: state.supplierReducer.supplier,
        role: state.roleReducer.role,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddUserAccess: newAccess =>
            dispatch(actions.addUserAccess(newAccess)),
        onSetSelectedContractor: selectedContractor =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
        onSetSelectedRole: selectedRole =>
            dispatch(actions.setSelectedRole(selectedRole)),
        onSetSelectedSupplier: selectedSupplier =>
            dispatch(actions.setSelectedSupplier(selectedSupplier)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalAddUserAccess),
);
