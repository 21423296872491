//  Login
export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_FAILURE = 'SESSION_FAILURE';
export const SESSION_FAILURE_PASSWORD = 'SESSION_FAILURE_PASSWORD';

export const SET_SESSION_ACCESS_SELECTION = 'SET_SESSION_ACCESS_SELECTION';
export const SESSION_ACCESS_SELECTION_REQUEST =
    'SESSION_ACCESS_SELECTION_REQUEST';
export const SESSION_ACCESS_SELECTION_SUCCESS =
    'SESSION_ACCESS_SELECTION_SUCCESS';
export const SESSION_ACCESS_SELECTION_FAILURE =
    'SESSION_ACCESS_SELECTION_FAILURE';

export const GET_CURRENT_SESSION_REQUEST = 'GET_CURRENT_SESSION_REQUEST';
export const GET_CURRENT_SESSION_SUCCESS = 'GET_CURRENT_SESSION_SUCCESS';
export const GET_CURRENT_SESSION_FAILURE = 'GET_CURRENT_SESSION_FAILURE';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_CONFIRMATION_REQUEST =
    'FORGOT_PASSWORD_CONFIRMATION_REQUEST';
export const FORGOT_PASSWORD_CONFIRMATION_SUCCESS =
    'FORGOT_PASSWORD_CONFIRMATION_SUCCESS';
export const FORGOT_PASSWORD_CONFIRMATION_FAILURE =
    'FORGOT_PASSWORD_CONFIRMATION_FAILURE';

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE';

//  Contractor's  Page
export const CONTRACTORS_GET_LIST_REQUEST = 'CONTRACTORS_GET_LIST_REQUEST';
export const CONTRACTORS_GET_LIST_SUCCESS = 'CONTRACTORS_GET_LIST_SUCCESS';
export const CONTRACTORS_GET_LIST_FAILURE = 'CONTRACTORS_GET_LIST_FAILURE';

export const CONTRACTORS_GET_COUNT_REQUEST = 'CONTRACTORS_GET_COUNT_REQUEST';
export const CONTRACTORS_GET_COUNT_SUCCESS = 'CONTRACTORS_GET_COUNT_SUCCESS';
export const CONTRACTORS_GET_COUNT_FAILURE = 'CONTRACTORS_GET_COUNT_FAILURE';

export const CONTRACTOR_GET_DETAILS_REQUEST = 'CONTRACTOR_GET_DETAILS_REQUEST';
export const CONTRACTOR_GET_DETAILS_SUCCESS = 'CONTRACTOR_GET_DETAILS_SUCCESS';
export const CONTRACTOR_GET_DETAILS_FAILURE = 'CONTRACTOR_GET_DETAILS_FAILURE';

export const CONTRACTOR_GET_DETAILS_NEW = 'CONTRACTOR_GET_DETAILS_NEW';

export const CONTRACTOR_POST_REQUEST = 'CONTRACTOR_POST_REQUEST';
export const CONTRACTOR_POST_SUCCESS = 'CONTRACTOR_POST_SUCCESS';
export const CONTRACTOR_POST_FAILURE = 'CONTRACTOR_POST_FAILURE';

export const CONTRACTOR_PUT_REQUEST = 'CONTRACTOR_PUT_REQUEST';
export const CONTRACTOR_PUT_SUCCESS = 'CONTRACTOR_PUT_SUCCESS';
export const CONTRACTOR_PUT_FAILURE = 'CONTRACTOR_PUT_FAILURE';

export const CONTRACTOR_DELETE_REQUEST = 'CONTRACTOR_DELETE_REQUEST';
export const CONTRACTOR_DELETE_SUCCESS = 'CONTRACTOR_DELETE_SUCCESS';
export const CONTRACTOR_DELETE_FAILURE = 'CONTRACTOR_DELETE_FAILURE';

export const CONTRACTOR_UPLOAD_NORMAL_LOGO_REQUEST =
    'CONTRACTOR_UPLOAD_NORMAL_LOGO_REQUEST';
export const CONTRACTOR_UPLOAD_NORMAL_LOGO_SUCCESS =
    'CONTRACTOR_UPLOAD_NORMAL_LOGO_SUCCESS';
export const CONTRACTOR_UPLOAD_NORMAL_LOGO_FAILURE =
    'CONTRACTOR_UPLOAD_NORMAL_LOGO_FAILURE';

export const CONTRACTOR_UPLOAD_SMALL_LOGO_REQUEST =
    'CONTRACTOR_UPLOAD_SMALL_LOGO_REQUEST';
export const CONTRACTOR_UPLOAD_SMALL_LOGO_SUCCESS =
    'CONTRACTOR_UPLOAD_SMALL_LOGO_SUCCESS';
export const CONTRACTOR_UPLOAD_SMALL_LOGO_FAILURE =
    'CONTRACTOR_UPLOAD_SMALL_LOGO_FAILURE';

export const CONTRACTOR_UPLOAD_ATTACHMENT_REQUEST =
    'CONTRACTOR_UPLOAD_ATTACHMENT_REQUEST';
export const CONTRACTOR_UPLOAD_ATTACHMENT_SUCCESS =
    'CONTRACTOR_UPLOAD_ATTACHMENT_SUCCESS';
export const CONTRACTOR_UPLOAD_ATTACHMENT_FAILURE =
    'CONTRACTOR_UPLOAD_ATTACHMENT_FAILURE';

//Company's Page
export const COMPANY_GET_LIST_REQUEST = 'COMPANY_GET_LIST_REQUEST';
export const COMPANY_GET_LIST_SUCCESS = 'COMPANY_GET_LIST_SUCCESS';
export const COMPANY_GET_LIST_FAILURE = 'COMPANY_GET_LIST_FAILURE';

export const COMPANY_GET_COUNT_REQUEST = 'COMPANY_GET_COUNT_REQUEST';
export const COMPANY_GET_COUNT_SUCCESS = 'COMPANY_GET_COUNT_SUCCESS';
export const COMPANY_GET_COUNT_FAILURE = 'COMPANY_GET_COUNT_FAILURE';

export const COMPANY_GET_DETAILS_REQUEST = 'COMPANY_GET_DETAILS_REQUEST';
export const COMPANY_GET_DETAILS_SUCCESS = 'COMPANY_GET_DETAILS_SUCCESS';
export const COMPANY_GET_DETAILS_FAILURE = 'COMPANY_GET_DETAILS_FAILURE';

export const COMPANY_GET_DETAILS_NEW = 'COMPANY_GET_DETAILS_NEW';

export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST';
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS';
export const COMPANY_CREATE_FAILURE = 'COMPANY_CREATE_FAILURE';

export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_FAILURE = 'COMPANY_UPDATE_FAILURE';

export const COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAILURE = 'COMPANY_DELETE_FAILURE';

export const IMPORT_COMPANY_CERTIFICATE_REQUEST =
    'IMPORT_COMPANY_CERTIFICATE_REQUEST';
export const IMPORT_COMPANY_CERTIFICATE_SUCCESS =
    'IMPORT_COMPANY_CERTIFICATE_SUCCESS';
export const IMPORT_COMPANY_CERTIFICATE_FAILURE =
    'IMPORT_COMPANY_CERTIFICATE_FAILURE';

//  Supplier's Page
export const SUPPLIERS_GET_COUNT_REQUEST = 'SUPPLIERS_GET_COUNT_REQUEST';
export const SUPPLIERS_GET_COUNT_SUCCESS = 'SUPPLIERS_GET_COUNT_SUCCESS';
export const SUPPLIERS_GET_COUNT_FAILURE = 'SUPPLIERS_GET_COUNT_FAILURE';

export const SUPPLIERS_GET_LIST_REQUEST = 'SUPPLIERS_GET_LIST_REQUEST';
export const SUPPLIERS_GET_LIST_SUCCESS = 'SUPPLIERS_GET_LIST_SUCCESS';
export const SUPPLIERS_GET_LIST_FAILURE = 'SUPPLIERS_GET_LIST_FAILURE';

export const SUPPLIER_GET_MAPS_COUNT_REQUEST =
    'SUPPLIER_GET_MAPS_COUNT_REQUEST';
export const SUPPLIER_GET_MAPS_COUNT_SUCCESS =
    'SUPPLIER_GET_MAPS_COUNT_SUCCESS';
export const SUPPLIER_GET_MAPS_COUNT_FAILURE =
    'SUPPLIER_GET_MAPS_COUNT_FAILURE';

export const SUPPLIER_GET_MAPS_REQUEST = 'SUPPLIER_GET_MAPS_REQUEST';
export const SUPPLIER_GET_MAPS_SUCCESS = 'SUPPLIER_GET_MAPS_SUCCESS';
export const SUPPLIER_GET_MAPS_FAILURE = 'SUPPLIER_GET_MAPS_FAILURE';

export const SUPPLIER_GET_DETAILS_REQUEST = 'SUPPLIER_GET_DETAILS_REQUEST';
export const SUPPLIER_GET_DETAILS_SUCCESS = 'SUPPLIER_GET_DETAILS_SUCCESS';
export const SUPPLIER_GET_DETAILS_FAILURE = 'SUPPLIER_GET_DETAILS_FAILURE';

export const SUPPLIER_GET_DETAILS_NEW = 'SUPPLIER_GET_DETAILS_NEW';

export const SUPPLIER_POST_REQUEST = 'SUPPLIER_POST_REQUEST';
export const SUPPLIER_POST_SUCCESS = 'SUPPLIER_POST_SUCCESS';
export const SUPPLIER_POST_FAILURE = 'SUPPLIER_POST_FAILURE';

export const SUPPLIER_PUT_REQUEST = 'SUPPLIER_PUT_REQUEST';
export const SUPPLIER_PUT_SUCCESS = 'SUPPLIER_PUT_SUCCESS';
export const SUPPLIER_PUT_FAILURE = 'SUPPLIER_PUT_FAILURE';

export const SUPPLIER_PUT_MAP_REQUEST = 'SUPPLIER_PUT_MAP_REQUEST';
export const SUPPLIER_PUT_MAP_SUCCESS = 'SUPPLIER_PUT_MAP_SUCCESS';
export const SUPPLIER_PUT_MAP_FAILURE = 'SUPPLIER_PUT_MAP_FAILURE';

export const SUPPLIER_DELETE_REQUEST = 'SUPPLIER_DELETE_REQUEST';
export const SUPPLIER_DELETE_SUCCESS = 'SUPPLIER_DELETE_SUCCESS';
export const SUPPLIER_DELETE_FAILURE = 'SUPPLIER_DELETE_FAILURE';

export const SUPPLIER_ADD_MAP = 'SUPPLIER_ADD_MAP';
export const SUPPLIER_UPDATE_INPUT = 'SUPPLIER_UPDATE_INPUT';
export const SUPPLIER_DELETE_MAP = 'SUPPLIER_DELETE_MAP';

export const SUPPLIERS_GET_ORDERS_REQUEST = 'SUPPLIERS_GET_ORDERS_REQUEST';
export const SUPPLIERS_GET_ORDERS_SUCCESS = 'SUPPLIERS_GET_ORDERS_SUCCESS';
export const SUPPLIERS_GET_ORDERS_FAILURE = 'SUPPLIERS_GET_ORDERS_FAILURE';

export const CLEAN_SUPPLIER_MAPS = 'CLEAN_SUPPLIER_MAPS';

//  Orders  Page
export const ORDERS_GET_LIST_REQUEST = 'ORDERS_GET_LIST_REQUEST';
export const ORDERS_GET_LIST_SUCCESS = 'ORDERS_GET_LIST_SUCCESS';
export const ORDERS_GET_LIST_FAILURE = 'ORDERS_GET_LIST_FAILURE';

export const ORDERS_GET_COUNT_REQUEST = 'ORDERS_GET_COUNT_REQUEST';
export const ORDERS_GET_COUNT_SUCCESS = 'ORDERS_GET_COUNT_SUCCESS';
export const ORDERS_GET_COUNT_FAILURE = 'ORDERS_GET_COUNT_FAILURE';

export const ORDER_GET_DETAILS_REQUEST = 'ORDER_GET_DETAILS_REQUEST';
export const ORDER_GET_DETAILS_SUCCESS = 'ORDER_GET_DETAILS_SUCCESS';
export const ORDER_GET_DETAILS_FAILURE = 'ORDER_GET_DETAILS_FAILURE';

export const ORDER_POST_REQUEST = 'ORDER_POST_REQUEST';
export const ORDER_POST_SUCCESS = 'ORDER_POST_SUCCESS';
export const ORDER_POST_FAILURE = 'ORDER_POST_FAILURE';

export const ORDER_DOCUMENTS_GET_DETAILS_REQUEST =
    'ORDER_DOCUMENTS_GET_DETAILS_REQUEST';
export const ORDER_DOCUMENTS_GET_DETAILS_SUCCESS =
    'ORDER_DOCUMENTS_GET_DETAILS_SUCCESS';
export const ORDER_DOCUMENTS_GET_DETAILS_FAILURE =
    'ORDER_DOCUMENTS_GET_DETAILS_FAILURE';
export const ORDER_DOCUMENTS_GET_DETAILS_NEW =
    'ORDER_DOCUMENTS_GET_DETAILS_NEW';

export const ORDER_DOCUMENTS_GET_COUNT_REQUEST =
    'ORDER_DOCUMENTS_GET_COUNT_REQUEST';
export const ORDER_DOCUMENTS_GET_COUNT_SUCCESS =
    'ORDER_DOCUMENTS_GET_COUNT_SUCCESS';
export const ORDER_DOCUMENTS_GET_COUNT_FAILURE =
    'ORDER_DOCUMENTS_GET_COUNT_FAILURE';

export const ASSOCIATED_DOCUMENTS_GET_LIST_REQUEST =
    'ASSOCIATED_DOCUMENTS_GET_LIST_REQUEST';
export const ASSOCIATED_DOCUMENTS_GET_LIST_SUCCESS =
    'ASSOCIATED_DOCUMENTS_GET_LIST_SUCCESS';
export const ASSOCIATED_DOCUMENTS_GET_LIST_FAILURE =
    'ASSOCIATED_DOCUMENTS_GET_LIST_FAILURE';

export const ASSOCIATED_DOCUMENTS_GET_COUNT_REQUEST =
    'ASSOCIATED_DOCUMENTS_GET_COUNT_REQUEST';
export const ASSOCIATED_DOCUMENTS_GET_COUNT_SUCCESS =
    'ASSOCIATED_DOCUMENTS_GET_COUNT_SUCCESS';
export const ASSOCIATED_DOCUMENTS_GET_COUNT_FAILURE =
    'ASSOCIATED_DOCUMENTS_GET_COUNT_FAILURE';

export const PARENT_CHILD_VALUES_GET_REQUEST =
    'PARENT_CHILD_VALUES_GET_REQUEST';
export const PARENT_CHILD_VALUES_GET_SUCCESS =
    'PARENT_CHILD_VALUES_GET_SUCCESS';
export const PARENT_CHILD_VALUES_GET_FAILURE =
    'PARENT_CHILD_VALUES_GET_FAILURE';

export const GET_FILTER_DOCUMENTS_TYPE = 'GET_FILTER_DOCUMENTS_TYPE';
export const INVOICE_GET_FILTER_DOCUMENTS_TYPE =
    'INVOICE_GET_FILTER_DOCUMENTS_TYPE';

export const TIMELINE_DOCUMENT_GET_LIST_REQUEST =
    'TIMELINE_DOCUMENT_GET_LIST_REQUEST';
export const TIMELINE_DOCUMENT_GET_LIST_SUCCESS =
    'TIMELINE_DOCUMENT_GET_LIST_SUCCESS';
export const TIMELINE_DOCUMENT_GET_LIST_FAILURE =
    'TIMELINE_DOCUMENT_GET_LIST_FAILURE';
    
export const ORDER_GET_DETAILS_NEW = 'ORDER_GET_DETAILS_NEW';

export const ORDER_PUT_REQUEST = 'ORDER_PUT_REQUEST';
export const ORDER_PUT_SUCCESS = 'ORDER_PUT_SUCCESS';
export const ORDER_PUT_FAILURE = 'ORDER_PUT_FAILURE';

export const ORDER_ITEM_GET_DETAILS_NEW = 'ORDER_ITEM_GET_DETAILS_NEW';

export const ORDERS_ITEM_GET_LIST_REQUEST = 'ORDERS_ITEM_GET_LIST_REQUEST';
export const ORDERS_ITEM_GET_LIST_SUCCESS = 'ORDERS_ITEM_GET_LIST_SUCCESS';
export const ORDERS_ITEM_GET_LIST_FAILURE = 'ORDERS_ITEM_GET_LIST_FAILURE';

export const ORDERS_ITEM_GET_COUNT_REQUEST = 'ORDERS_ITEM_GET_COUNT_REQUEST';
export const ORDERS_ITEM_GET_COUNT_SUCCESS = 'ORDERS_ITEM_GET_COUNT_SUCCESS';
export const ORDERS_ITEM_GET_COUNT_FAILURE = 'ORDERS_ITEM_GET_COUNT_FAILURE';

export const ORDER_ITEM_GET_DETAILS_REQUEST = 'ORDER_ITEM_GET_DETAILS_REQUEST';
export const ORDER_ITEM_GET_DETAILS_SUCCESS = 'ORDER_ITEM_GET_DETAILS_SUCCESS';
export const ORDER_ITEM_GET_DETAILS_FAILURE = 'ORDER_ITEM_GET_DETAILS_FAILURE';

export const ORDER_ITEM_POST_REQUEST = 'ORDER_ITEM_POST_REQUEST';
export const ORDER_ITEM_POST_SUCCESS = 'ORDER_ITEM_POST_SUCCESS';
export const ORDER_ITEM_POST_FAILURE = 'ORDER_ITEM_POST_FAILURE';

export const ORDER_ITEM_PUT_REQUEST = 'ORDER_ITEM_PUT_REQUEST';
export const ORDER_ITEM_PUT_SUCCESS = 'ORDER_ITEM_PUT_SUCCESS';
export const ORDER_ITEM_PUT_FAILURE = 'ORDER_ITEM_PUT_FAILURE';

export const ORDER_ITEM_DELETE_REQUEST = 'ORDER_ITEM_DELETE_REQUEST';
export const ORDER_ITEM_DELETE_SUCCESS = 'ORDER_ITEM_DELETE_SUCCESS';
export const ORDER_ITEM_DELETE_FAILURE = 'ORDER_ITEM_DELETE_FAILURE';

export const ORDERS_BY_SUPPLIER_GET_LIST_REQUEST =
    'ORDERS_BY_SUPPLIER_GET_LIST_REQUEST';
export const ORDERS_BY_SUPPLIER_GET_LIST_SUCCESS =
    'ORDERS_BY_SUPPLIER_GET_LIST_SUCCESS';
export const ORDERS_BY_SUPPLIER_GET_LIST_FAILURE =
    'ORDERS_BY_SUPPLIER_GET_LIST_FAILURE';

export const SET_TAB_ORDER_INDEX = 'SET_TAB_ORDER_INDEX';

//  Document Fields Page

//Get Document & Map Fields
export const DOCUMENT_FIELDS_GET_LIST_REQUEST =
    'DOCUMENT_FIELDS_GET_LIST_REQUEST';
export const DOCUMENT_FIELDS_GET_LIST_SUCCESS =
    'DOCUMENT_FIELDS_GET_LIST_SUCCESS';
export const DOCUMENT_FIELDS_GET_LIST_FAILURE =
    'DOCUMENT_FIELDS_GET_LIST_FAILURE';

export const DOCUMENT_FIELDS_GET_COUNT_REQUEST =
    'DOCUMENT_FIELDS_GET_COUNT_REQUEST';
export const DOCUMENT_FIELDS_GET_COUNT_SUCCESS =
    'DOCUMENT_FIELDS_GET_COUNT_SUCCESS';
export const DOCUMENT_FIELDS_GET_COUNT_FAILURE =
    'DOCUMENT_FIELDS_GET_COUNT_FAILURE';

export const DOCUMENT_FIELDS_GET_DETAILS_REQUEST =
    'DOCUMENT_FIELDS_GET_DETAILS_REQUEST';
export const DOCUMENT_FIELDS_GET_DETAILS_SUCCESS =
    'DOCUMENT_FIELDS_GET_DETAILS_SUCCESS';
export const DOCUMENT_FIELDS_GET_DETAILS_FAILURE =
    'DOCUMENT_FIELDS_GET_DETAILS_FAILURE';

//Post Document & Map Fields
export const DOCUMENT_FIELDS_POST_REQUEST = 'DOCUMENT_FIELDS_POST_REQUEST';
export const DOCUMENT_FIELDS_POST_SUCCESS = 'DOCUMENT_FIELDS_POST_SUCCESS';
export const DOCUMENT_FIELDS_POST_FAILURE = 'DOCUMENT_FIELDS_POST_FAILURE';

export const DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_REQUEST =
    'DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_REQUEST';
export const DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_SUCCESS =
    'DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_SUCCESS';
export const DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_FAILURE =
    'DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_FAILURE';

export const DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_NEW =
    'DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_NEW';

//Create,Delete & Update Document and Map Fields
export const DOCUMENT_FIELDS_CREATE_REQUEST = 'DOCUMENT_FIELDS_CREATE_REQUEST';
export const DOCUMENT_FIELDS_CREATE_SUCCESS = 'DOCUMENT_FIELDS_CREATE_SUCCESS';
export const DOCUMENT_FIELDS_CREATE_FAILURE = 'DOCUMENT_FIELDS_CREATE_FAILURE';

export const DOCUMENT_FIELDS_PUT_REQUEST = 'DOCUMENT_FIELDS_PUT_REQUEST';
export const DOCUMENT_FIELDS_PUT_SUCCESS = 'DOCUMENT_FIELDS_PUT_SUCCESS';
export const DOCUMENT_FIELDS_PUT_FAILURE = 'DOCUMENT_FIELDS_PUT_FAILURE';

export const DOCUMENT_FIELDS_DELETE_REQUEST = 'DOCUMENT_FIELDS_DELETE_REQUEST';
export const DOCUMENT_FIELDS_DELETE_SUCCESS = 'DOCUMENT_FIELDS_DELETE_SUCCESS';
export const DOCUMENT_FIELDS_DELETE_FAILURE = 'DOCUMENT_FIELDS_DELETE_FAILURE';

export const DOCUMENT_FIELDS_CONTRACTORS_REQUEST =
    'DOCUMENT_FIELDS_CONTRACTORS_REQUEST';
export const DOCUMENT_FIELDS_CONTRACTORS_SUCCESS =
    'DOCUMENT_FIELDS_CONTRACTORS_SUCCESS';
export const DOCUMENT_FIELDS_CONTRACTORS_FAILURE =
    'DOCUMENT_FIELDS_CONTRACTORS_FAILURE';

export const DOCUMENT_RETURN_STEP_REQUEST = 'DOCUMENT_RETURN_STEP_REQUEST';
export const DOCUMENT_RETURN_STEP_SUCCESS = 'DOCUMENT_RETURN_STEP_SUCCESS';
export const DOCUMENT_RETURN_STEP_FAILURE = 'DOCUMENT_RETURN_STEP_FAILURE';

//  Invoice List Page

export const INVOICES_CTE_GET_LIST_REQUEST = 'INVOICES_CTE_GET_LIST_REQUEST';
export const INVOICES_CTE_GET_LIST_SUCCESS = 'INVOICES_CTE_GET_LIST_SUCCESS';
export const INVOICES_CTE_GET_LIST_FAILURE = 'INVOICES_CTE_GET_LIST_FAILURE';

export const INVOICE_CTE_GET_COUNT_REQUEST = 'INVOICE_CTE_GET_COUNT_REQUEST';
export const INVOICE_CTE_GET_COUNT_SUCCESS = 'INVOICE_CTE_GET_COUNT_SUCCESS';
export const INVOICE_CTE_GET_COUNT_FAILURE = 'INVOICE_CTE_GET_COUNT_FAILURE';

export const INVOICES_OTHER_GET_LIST_REQUEST =
    'INVOICES_OTHER_GET_LIST_REQUEST';
export const INVOICES_OTHER_GET_LIST_SUCCESS =
    'INVOICES_OTHER_GET_LIST_SUCCESS';
export const INVOICES_OTHER_GET_LIST_FAILURE =
    'INVOICES_OTHER_GET_LIST_FAILURE';

export const INVOICE_OTHER_GET_COUNT_REQUEST =
    'INVOICE_OTHER_GET_COUNT_REQUEST';
export const INVOICE_OTHER_GET_COUNT_SUCCESS =
    'INVOICE_OTHER_GET_COUNT_SUCCESS';
export const INVOICE_OTHER_GET_COUNT_FAILURE =
    'INVOICE_OTHER_GET_COUNT_FAILURE';

export const INVOICES_SEARCH_GET_LIST_REQUEST =
    'INVOICES_SEARCH_GET_LIST_REQUEST';
export const INVOICES_SEARCH_GET_LIST_SUCCESS =
    'INVOICES_SEARCH_GET_LIST_SUCCESS';
export const INVOICES_SEARCH_GET_LIST_FAILURE =
    'INVOICES_SEARCH_GET_LIST_FAILURE';

export const INVOICES_SEARCH_GET_COUNT_REQUEST =
    'INVOICES_SEARCH_GET_COUNT_REQUEST';
export const INVOICES_SEARCH_GET_COUNT_SUCCESS =
    'INVOICES_SEARCH_GET_COUNT_SUCCESS';
export const INVOICES_SEARCH_GET_COUNT_FAILURE =
    'INVOICES_SEARCH_GET_COUNT_FAILURE';

export const INVOICES_GET_CTE_SEARCH_REQUEST =
    'INVOICES_GET_CTE_SEARCH_REQUEST';
export const INVOICES_GET_CTE_SEARCH_SUCCESS =
    'INVOICES_GET_CTE_SEARCH_SUCCESS';
export const INVOICES_GET_CTE_SEARCH_FAILURE =
    'INVOICES_GET_CTE_SEARCH_FAILURE';

export const INVOICES_GET_LIST_REQUEST = 'INVOICES_GET_LIST_REQUEST';
export const INVOICES_GET_LIST_SUCCESS = 'INVOICES_GET_LIST_SUCCESS';
export const INVOICES_GET_LIST_FAILURE = 'INVOICES_GET_LIST_FAILURE';

export const INVOICE_GET_COUNT_REQUEST = 'INVOICE_GET_COUNT_REQUEST';
export const INVOICE_GET_COUNT_SUCCESS = 'INVOICE_GET_COUNT_SUCCESS';
export const INVOICE_GET_COUNT_FAILURE = 'INVOICE_GET_COUNT_FAILURE';

export const INVOICE_REPORT_CANCEL_SUCCESS = 'INVOICE_REPORT_CANCEL_SUCCESS';
export const INVOICE_REPORT_DOCUMENT_CANCEL = 'INVOICE_REPORT_DOCUMENT_CANCEL';

export const INVOICES_REPORT_GET_LIST_REQUEST =
    'INVOICES_REPORT_GET_LIST_REQUEST';
export const INVOICES_REPORT_GET_LIST_SUCCESS =
    'INVOICES_REPORT_GET_LIST_SUCCESS';
export const INVOICES_REPORT_GET_LIST_FAILURE =
    'INVOICES_REPORT_GET_LIST_FAILURE';

export const INVOICE_UPLOAD_FILE_REQUEST = 'INVOICE_UPLOAD_FILE_REQUEST';
export const INVOICE_UPLOAD_FILE_SUCCESS = 'INVOICE_UPLOAD_FILE_SUCCESS';
export const INVOICE_UPLOAD_FILE_FAILURE = 'INVOICE_UPLOAD_FILE_FAILURE';

export const INVOICE_GET_DETAILS_REQUEST = 'INVOICE_GET_DETAILS_REQUEST';
export const INVOICE_GET_DETAILS_SUCCESS = 'INVOICE_GET_DETAILS_SUCCESS';
export const INVOICE_GET_DETAILS_FAILURE = 'INVOICE_GET_DETAILS_FAILURE';

export const INVOICE_UPLOAD_DOCUMENT_REQUEST =
    'INVOICE_UPLOAD_DOCUMENT_REQUEST';
export const INVOICE_UPLOAD_DOCUMENT_SUCCESS =
    'INVOICE_UPLOAD_DOCUMENT_SUCCESS';
export const INVOICE_UPLOAD_DOCUMENT_FAILURE =
    'INVOICE_UPLOAD_DOCUMENT_FAILURE';

export const INVOICE_PUT_REQUEST = 'INVOICE_PUT_REQUEST';
export const INVOICE_PUT_SUCCESS = 'INVOICE_PUT_SUCCESS';
export const INVOICE_PUT_FAILURE = 'INVOICE_PUT_FAILURE';

export const CLEAN_VALIDATION_ERRORS = 'CLEAN_VALIDATION_ERRORS';

export const INVOICE_CANCEL_DOCUMENT_REQUEST =
    'INVOICE_CANCEL_DOCUMENT_REQUEST';
export const INVOICE_CANCEL_DOCUMENT_SUCCESS =
    'INVOICE_CANCEL_DOCUMENT_SUCCESS';
export const INVOICE_CANCEL_DOCUMENT_FAILURE =
    'INVOICE_CANCEL_DOCUMENT_FAILURE';

export const INVOICE_INFORM_CANCEL_ISSUER_REQUEST =
    'INVOICE_INFORM_CANCEL_ISSUER_REQUEST';
export const INVOICE_INFORM_CANCEL_ISSUER_SUCCESS =
    'INVOICE_INFORM_CANCEL_ISSUER_SUCCESS';
export const INVOICE_INFORM_CANCEL_ISSUER_FAILURE =
    'INVOICE_INFORM_CANCEL_ISSUER_FAILURE';

//NFe Documents
export const NFE_DOCUMENTS_GET_LIST_REQUEST = 'NFE_DOCUMENTS_GET_LIST_REQUEST';
export const NFE_DOCUMENTS_GET_LIST_SUCCESS = 'NFE_DOCUMENTS_GET_LIST_SUCCESS';
export const NFE_DOCUMENTS_GET_LIST_FAILURE = 'NFE_DOCUMENTS_GET_LIST_FAILURE';

export const NFE_DOCUMENT_CREATE_REQUEST = 'NFE_DOCUMENT_CREATE_REQUEST';
export const NFE_DOCUMENT_CREATE_SUCCESS = 'NFE_DOCUMENT_CREATE_SUCCESS';
export const NFE_DOCUMENT_CREATE_FAILURE = 'NFE_DOCUMENT_CREATE_FAILURE';

export const NFE_DOCUMENTS_GET_DETAILS_REQUEST =
    'NFE_DOCUMENTS_GET_DETAILS_REQUEST';
export const NFE_DOCUMENTS_GET_DETAILS_SUCCESS =
    'NFE_DOCUMENTS_GET_DETAILS_SUCCESS';
export const NFE_DOCUMENTS_GET_DETAILS_FAILURE =
    'NFE_DOCUMENTS_GET_DETAILS_FAILURE';

export const NFE_ITEMS_GET_LIST_REQUEST = 'NFE_ITEMS_GET_LIST_REQUEST';
export const NFE_ITEMS_GET_LIST_SUCCESS = 'NFE_ITEMS_GET_LIST_SUCCESS';
export const NFE_ITEMS_GET_LIST_FAILURE = 'NFE_ITEMS_GET_LIST_FAILURE';

export const NFE_ITEMS_GET_COUNT_REQUEST = 'NFE_ITEMS_GET_COUNT_REQUEST';
export const NFE_ITEMS_GET_COUNT_SUCCESS = 'NFE_ITEMS_GET_COUNT_SUCCESS';
export const NFE_ITEMS_GET_COUNT_FAILURE = 'NFE_ITEMS_GET_COUNT_FAILURE';

export const NFE_ITEMS_GET_DETAILS_REQUEST = 'NFE_ITEMS_GET_DETAILS_REQUEST';
export const NFE_ITEMS_GET_DETAILS_SUCCESS = 'NFE_ITEMS_GET_DETAILS_SUCCESS';
export const NFE_ITEMS_GET_DETAILS_FAILURE = 'NFE_ITEMS_GET_DETAILS_FAILURE';

export const NFE_ORDERS_GET_LIST_REQUEST = 'NFE_ORDERS_GET_REQUEST';
export const NFE_ORDERS_GET_LIST_SUCCESS = 'NFE_ORDERS_GET_SUCCESS';
export const NFE_ORDERS_GET_LIST_FAILURE = 'NFE_ORDERS_GET_FAILURE';

export const NFE_ORDERS_GET_COUNT_REQUEST = 'NFE_ORDERS_GET_COUNT_REQUEST';
export const NFE_ORDERS_GET_COUNT_SUCCESS = 'NFE_ORDERS_GET_COUNT_SUCCESS';
export const NFE_ORDERS_GET_COUNT_FAILURE = 'NFE_ORDERS_GET_COUNT_FAILURE';

export const NFE_SUMMARY_GET_LIST_REQUEST = 'NFE_SUMMARY_GET_LIST_REQUEST';
export const NFE_SUMMARY_GET_LIST_SUCCESS = 'NFE_SUMMARY_GET_LIST_SUCCESS';
export const NFE_SUMMARY_GET_LIST_FAILURE = 'NFE_SUMMARY_GET_LIST_FAILURE';

export const NFE_MANIFEST_ACTION_REQUEST = 'NFE_MANIFEST_ACTION_REQUEST';
export const NFE_MANIFEST_ACTION_SUCCESS = 'NFE_MANIFEST_ACTION_SUCCESS';
export const NFE_MANIFEST_ACTION_FAILURE = 'NFE_MANIFEST_ACTION_FAILURE';

export const NFE_ORDERS_UPDATE_REQUEST = 'NFE_ORDERS_UPDATE_REQUEST';
export const NFE_ORDERS_UPDATE_SUCCESS = 'NFE_ORDERS_UPDATE_SUCCESS';
export const NFE_ORDERS_UPDATE_FAILURE = 'NFE_ORDERS_UPDATE_FAILURE';

// Cte Documents
export const CTE_DOCUMENTS_GET_LIST_REQUEST = 'CTE_DOCUMENTS_GET_LIST_REQUEST';
export const CTE_DOCUMENTS_GET_LIST_SUCCESS = 'CTE_DOCUMENTS_GET_LIST_SUCCESS';
export const CTE_DOCUMENTS_GET_LIST_FAILURE = 'CTE_DOCUMENTS_GET_LIST_FAILURE';

export const CTE_DOCUMENT_CREATE_REQUEST = 'CTE_DOCUMENT_CREATE_REQUEST';
export const CTE_DOCUMENT_CREATE_SUCCESS = 'CTE_DOCUMENT_CREATE_SUCCESS';
export const CTE_DOCUMENT_CREATE_FAILURE = 'CTE_DOCUMENT_CREATE_FAILURE';

export const CTE_DOCUMENTS_GET_DETAILS_REQUEST =
    'CTE_DOCUMENTS_GET_DETAILS_REQUEST';
export const CTE_DOCUMENTS_GET_DETAILS_SUCCESS =
    'CTE_DOCUMENTS_GET_DETAILS_SUCCESS';
export const CTE_DOCUMENTS_GET_DETAILS_FAILURE =
    'CTE_DOCUMENTS_GET_DETAILS_FAILURE';

export const CTE_ORDERS_GET_LIST_REQUEST = 'CTE_ORDERS_GET_REQUEST';
export const CTE_ORDERS_GET_LIST_SUCCESS = 'CTE_ORDERS_GET_SUCCESS';
export const CTE_ORDERS_GET_LIST_FAILURE = 'CTE_ORDERS_GET_FAILURE';

export const CTE_ORDERS_GET_COUNT_REQUEST = 'CTE_ORDERS_GET_COUNT_REQUEST';
export const CTE_ORDERS_GET_COUNT_SUCCESS = 'CTE_ORDERS_GET_COUNT_SUCCESS';
export const CTE_ORDERS_GET_COUNT_FAILURE = 'CTE_ORDERS_GET_COUNT_FAILURE';

export const CTE_ORDERS_UPDATE_REQUEST = 'CTE_ORDERS_UPDATE_REQUEST';
export const CTE_ORDERS_UPDATE_SUCCESS = 'CTE_ORDERS_UPDATE_SUCCESS';
export const CTE_ORDERS_UPDATE_FAILURE = 'CTE_ORDERS_UPDATE_FAILURE';

//OrdersDocument
export const DOCUMENT_ORDERS_GET_REQUEST = 'DOCUMENT_ORDERS_GET_REQUEST';
export const DOCUMENT_ORDERS_GET_SUCCESS = 'DOCUMENT_ORDERS_GET_SUCCESS';
export const DOCUMENT_ORDERS_GET_FAILURE = 'DOCUMENT_ORDERS_GET_FAILURE';

export const DOCUMENT_ORDERS_GET_COUNT_REQUEST =
    'DOCUMENT_ORDERS_GET_COUNT_REQUEST';
export const DOCUMENT_ORDERS_GET_COUNT_SUCCESS =
    'DOCUMENT_ORDERS_GET_COUNT_SUCCESS';
export const DOCUMENT_ORDERS_GET_COUNT_FAILURE =
    'DOCUMENT_ORDERS_GET_COUNT_FAILURE';

export const DOCUMENT_ORDERS_PUT_REQUEST = 'DOCUMENT_ORDERS_PUT_REQUEST';
export const DOCUMENT_ORDERS_PUT_SUCCESS = 'DOCUMENT_ORDERS_PUT_SUCCESS';
export const DOCUMENT_ORDERS_PUT_FAILURE = 'DOCUMENT_ORDERS_PUT_FAILURE';

export const DOCUMENT_ORDERS_DELETE_REQUEST = 'DOCUMENT_ORDERS_DELETE_REQUEST';
export const DOCUMENT_ORDERS_DELETE_SUCCESS = 'DOCUMENT_ORDERS_DELETE_SUCCESS';
export const DOCUMENT_ORDERS_DELETE_FAILURE = 'DOCUMENT_ORDERS_DELETE_FAILURE';

//  Transaction's Page
export const TRANSACTIONS_GET_LIST_REQUEST = 'TRANSACTIONS_GET_LIST_REQUEST';
export const TRANSACTIONS_GET_LIST_SUCCESS = 'TRANSACTIONS_GET_LIST_SUCCESS';
export const TRANSACTIONS_GET_LIST_FAILURE = 'TRANSACTIONS_GET_LIST_FAILURE';

export const TRANSACTIONS_GET_COUNT_REQUEST = 'TRANSACTIONS_GET_COUNT_REQUEST';
export const TRANSACTIONS_GET_COUNT_SUCCESS = 'TRANSACTIONS_GET_COUNT_SUCCESS';
export const TRANSACTIONS_GET_COUNT_FAILURE = 'TRANSACTIONS_GET_COUNT_FAILURE';

export const TRANSACTION_GET_DETAILS_REQUEST =
    'TRANSACTION_GET_DETAILS_REQUEST';
export const TRANSACTION_GET_DETAILS_SUCCESS =
    'TRANSACTION_GET_DETAILS_SUCCESS';
export const TRANSACTION_GET_DETAILS_FAILURE =
    'TRANSACTION_GET_DETAILS_FAILURE';

export const TRANSACTION_GET_DETAILS_NEW = 'TRANSACTION_GET_DETAILS_NEW';

export const TRANSACTION_POST_REQUEST = 'TRANSACTION_POST_REQUEST';
export const TRANSACTION_POST_SUCCESS = 'TRANSACTION_POST_SUCCESS';
export const TRANSACTION_POST_FAILURE = 'TRANSACTION_POST_FAILURE';

export const TRANSACTION_PUT_REQUEST = 'TRANSACTION_PUT_REQUEST';
export const TRANSACTION_PUT_SUCCESS = 'TRANSACTION_PUT_SUCCESS';
export const TRANSACTION_PUT_FAILURE = 'TRANSACTION_PUT_FAILURE';

export const TRANSACTION_DELETE_REQUEST = 'TRANSACTION_DELETE_REQUEST';
export const TRANSACTION_DELETE_SUCCESS = 'TRANSACTION_DELETE_SUCCESS';
export const TRANSACTION_DELETE_FAILURE = 'TRANSACTION_DELETE_FAILURE';

// Fields List Page
export const FIELDS_GET_LIST_REQUEST = 'FIELDS_GET_LIST_REQUEST';
export const FIELDS_GET_LIST_SUCCESS = 'FIELDS_GET_LIST_SUCCESS';
export const FIELDS_GET_LIST_FAILURE = 'FIELDS_GET_LIST_FAILURE';

export const FIELDS_GET_COUNT_REQUEST = 'FIELDS_GET_COUNT_REQUEST';
export const FIELDS_GET_COUNT_SUCCESS = 'FIELDS_GET_COUNT_SUCCESS';
export const FIELDS_GET_COUNT_FAILURE = 'FIELDS_GET_COUNT_FAILURE';

export const FIELD_GET_DETAILS_REQUEST = 'FIELD_GET_DETAILS_REQUEST';
export const FIELD_GET_DETAILS_SUCCESS = 'FIELD_GET_DETAILS_SUCCESS';
export const FIELD_GET_DETAILS_FAILURE = 'FIELD_GET_DETAILS_FAILURE';

export const FIELD_GET_DETAILS_NEW = 'FIELD_GET_DETAILS_NEW';

export const FIELD_POST_REQUEST = 'FIELD_POST_REQUEST';
export const FIELD_POST_SUCCESS = 'FIELD_POST_SUCCESS';
export const FIELD_POST_FAILURE = 'FIELD_POST_FAILURE';

export const FIELD_PUT_REQUEST = 'FIELD_PUT_REQUEST';
export const FIELD_PUT_SUCCESS = 'FIELD_PUT_SUCCESS';
export const FIELD_PUT_FAILURE = 'FIELD_PUT_FAILURE';

export const FIELD_DELETE_REQUEST = 'FIELD_DELETE_REQUEST';
export const FIELD_DELETE_SUCCESS = 'FIELD_DELETE_SUCCESS';
export const FIELD_DELETE_FAILURE = 'FIELD_DELETE_FAILURE';

//  User's  Page
export const USERS_GET_LIST_REQUEST = 'USERS_GET_LIST_REQUEST';
export const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
export const USERS_GET_LIST_FAILURE = 'USERS_GET_LIST_FAILURE';

export const USERS_GET_COUNT_REQUEST = 'USERS_GET_COUNT_REQUEST';
export const USERS_GET_COUNT_SUCCESS = 'USERS_GET_COUNT_SUCCESS';
export const USERS_GET_COUNT_FAILURE = 'USERS_GET_COUNT_FAILURE';

export const USER_GET_DETAILS_REQUEST = 'USER_GET_DETAILS_REQUEST';
export const USER_GET_DETAILS_SUCCESS = 'USER_GET_DETAILS_SUCCESS';
export const USER_GET_DETAILS_FAILURE = 'USER_GET_DETAILS_FAILURE';

export const USER_GET_DETAILS_NEW = 'USER_GET_DETAILS_NEW';

export const USER_POST_REQUEST = 'USER_POST_REQUEST';
export const USER_POST_SUCCESS = 'USER_POST_SUCCESS';
export const USER_POST_FAILURE = 'USER_POST_FAILURE';

export const USER_PUT_REQUEST = 'USER_PUT_REQUEST';
export const USER_PUT_SUCCESS = 'USER_PUT_SUCCESS';
export const USER_PUT_FAILURE = 'USER_PUT_FAILURE';

export const USER_ADD_ACCESS = 'USER_ADD_ACCESS';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';

export const USER_SEND_PASSWORD_REQUEST = 'USER_SEND_PASSWORD_REQUEST';
export const USER_SEND_PASSWORD_SUCCESS = 'USER_SEND_PASSWORD_SUCCESS';
export const USER_SEND_PASSWORD_FAILURE = 'USER_SEND_PASSWORD_FAILURE';

// Roles
export const ROLE_GET_DETAILS_NEW = 'ROLE_GET_DETAILS_NEW';
export const ROLES_GET_LIST_REQUEST = 'ROLES_GET_LIST_REQUEST';
export const ROLES_GET_LIST_SUCCESS = 'ROLES_GET_LIST_SUCCESS';
export const ROLES_GET_LIST_FAILURE = 'ROLES_GET_LIST_FAILURE';

export const ROLES_CONTRACTOR_GET_LIST_REQUEST =
    'ROLES_CONTRACTOR_GET_LIST_REQUEST';
export const ROLES_CONTRACTOR_GET_LIST_SUCCESS =
    'ROLES_CONTRACTOR_GET_LIST_SUCCESS';
export const ROLES_CONTRACTOR_GET_LIST_FAILURE =
    'ROLES_CONTRACTOR_GET_LIST_FAILURE';

export const ROLES_GET_DETAILS_REQUEST = 'ROLES_GET_DETAILS_REQUEST';
export const ROLES_GET_DETAILS_SUCCESS = 'ROLES_GET_DETAILS_SUCCESS';
export const ROLES_GET_DETAILS_FAILURE = 'ROLES_GET_DETAILS_FAILURE';
export const ROLES_SET_CLAIMS_SUCCESS = 'ROLES_SET_CLAIMS_SUCCESS';
export const ROLES_SET_ROLES_SUCCESS = 'ROLES_SET_ROLES_SUCCESS';
export const ROLE_POST_REQUEST = 'ROLE_POST_REQUEST';
export const ROLE_POST_SUCCESS = 'ROLE_POST_SUCCESS';
export const ROLE_POST_FAILURE = 'ROLE_POST_FAILURE';
export const ROLE_PUT_REQUEST = 'ROLE_PUT_REQUEST';
export const ROLE_PUT_SUCCESS = 'ROLE_PUT_SUCCESS';
export const ROLE_PUT_FAILURE = 'ROLE_PUT_FAILURE';
export const ROLE_CLEANUP = 'ROLE_CLEANUP';
export const ROLES_SET_ROLE_LEVELS_SUCCESS = 'ROLES_SET_ROLE_LEVELS_SUCCESS';
export const ROLES_SET_ACCESS_SUCCESS = 'ROLES_SET_ACCESS_SUCCESS';
export const ROLES_CLEANUP_ROLE_LEVELS_SUCCESS =
    'ROLES_CLEANUP_ROLE_LEVELS_SUCCESS';
export const ROLE_SET_REQUEST = 'ROLE_SET_REQUEST';
export const ROLE_SET_SUCCESS = 'ROLE_SET_SUCCESS';

export const ROLES_GET_COUNT_REQUEST = 'ROLES_GET_COUNT_REQUEST';
export const ROLES_GET_COUNT_SUCCESS = 'ROLES_GET_COUNT_SUCCESS';
export const ROLES_GET_COUNT_FAILURE = 'ROLES_GET_COUNT_FAILURE';

// Maps Page
export const MAPS_GET_LIST_REQUEST = 'MAPS_GET_LIST_REQUEST';
export const MAPS_GET_LIST_SUCCESS = 'MAPS_GET_LIST_SUCCESS';
export const MAPS_GET_LIST_FAILURE = 'MAPS_GET_LIST_FAILURE';

export const MAPS_GET_COUNT_REQUEST = 'MAPS_GET_COUNT_REQUEST';
export const MAPS_GET_COUNT_SUCCESS = 'MAPS_GET_COUNT_SUCCESS';
export const MAPS_GET_COUNT_FAILURE = 'MAPS_GET_COUNT_FAILURE';

export const MAP_GET_DETAILS_REQUEST = 'MAP_GET_DETAILS_REQUEST';
export const MAP_GET_DETAILS_SUCCESS = 'MAP_GET_DETAILS_SUCCESS';
export const MAP_GET_DETAILS_FAILURE = 'MAP_GET_DETAILS_FAILURE';

export const MAP_GET_OCR_RESULT_REQUEST = 'MAP_GET_OCR_RESULT_REQUEST';
export const MAP_GET_OCR_RESULT_SUCCESS = 'MAP_GET_OCR_RESULT_SUCCESS';
export const MAP_GET_OCR_RESULT_FAILURE = 'MAP_GET_OCR_RESULT_FAILURE';

export const MAP_UPLOAD_FILE_REQUEST = 'MAP_UPLOAD_FILE_REQUEST';
export const MAP_UPLOAD_FILE_SUCCESS = 'MAP_UPLOAD_FILE_SUCCESS';
export const MAP_UPLOAD_FILE_FAILURE = 'MAP_UPLOAD_FILE_FAILURE';

export const MAP_CREATE_REQUEST = 'MAP_CREATE_REQUEST';
export const MAP_CREATE_SUCCESS = 'MAP_CREATE_SUCCESS';
export const MAP_CREATE_FAILURE = 'MAP_CREATE_FAILURE';

export const MAP_UPDATE_REQUEST = 'MAP_UPDATE_REQUEST';
export const MAP_UPDATE_SUCCESS = 'MAP_UPDATE_SUCCESS';
export const MAP_UPDATE_FAILURE = 'MAP_UPDATE_FAILURE';

export const MAP_UPDATE_FROM_LIST_REQUEST = 'MAP_UPDATE_FROM_LIST_REQUEST';
export const MAP_UPDATE_FROM_LIST_SUCCESS = 'MAP_UPDATE_FROM_LIST_SUCCESS';
export const MAP_UPDATE_FROM_LIST_FAILURE = 'MAP_UPDATE_FROM_LIST_FAILURE';

export const MAP_DELETE_REQUEST = 'MAP_DELETE_REQUEST';
export const MAP_DELETE_SUCCESS = 'MAP_DELETE_SUCCESS';
export const MAP_DELETE_FAILURE = 'MAP_DELETE_FAILURE';

export const MAP_ITEM_ADD_REQUEST = 'MAP_ITEM_ADD_REQUEST';
export const MAP_ITEM_ADD_SUCCESS = 'MAP_ITEM_ADD_SUCCESS';
export const MAP_ITEM_ADD_FAILURE = 'MAP_ITEM_ADD_FAILURE';

export const MAP_ITEM_COPY_REQUEST = 'MAP_ITEM_COPY_REQUEST';
export const MAP_ITEM_COPY_SUCCESS = 'MAP_ITEM_COPY_SUCCESS';
export const MAP_ITEM_COPY_FAILURE = 'MAP_ITEM_COPY_FAILURE';

export const MAP_ITEM_UPDATE_REQUEST = 'MAP_ITEM_UPDATE_REQUEST';
export const MAP_ITEM_UPDATE_SUCCESS = 'MAP_ITEM_UPDATE_SUCCESS';
export const MAP_ITEM_UPDATE_FAILURE = 'MAP_ITEM_UPDATE_FAILURE';

export const MAP_ITEM_GET_DETAILS_REQUEST = 'MAP_ITEM_GET_DETAILS_REQUEST';
export const MAP_ITEM_GET_DETAILS_SUCCESS = 'MAP_ITEM_GET_DETAILS_SUCCESS';
export const MAP_ITEM_GET_DETAILS_FAILURE = 'MAP_ITEM_GET_DETAILS_FAILURE';

export const MAP_ITEM_REMOVE_REQUEST = 'MAP_ITEM_REMOVE_REQUEST';
export const MAP_ITEM_REMOVE_SUCCESS = 'MAP_ITEM_REMOVE_SUCCESS';
export const MAP_ITEM_REMOVE_FAILURE = 'MAP_ITEM_REMOVE_FAILURE';

export const MAP_ITEM_FIELD_UPDATE_REQUEST = 'MAP_ITEM_FIELD_UPDATE_REQUEST';
export const MAP_ITEM_FIELD_UPDATE_SUCCESS = 'MAP_ITEM_FIELD_UPDATE_SUCCESS';
export const MAP_ITEM_FIELD_UPDATE_FAILURE = 'MAP_ITEM_FIELD_UPDATE_FAILURE';

export const MAP_ITEM_OCR_FIELD_REQUEST = 'MAP_ITEM_OCR_FIELD_REQUEST';
export const MAP_ITEM_OCR_FIELD_SUCCESS = 'MAP_ITEM_OCR_FIELD_SUCCESS';
export const MAP_ITEM_OCR_FIELD_FAILURE = 'MAP_ITEM_OCR_FIELD_FAILURE';

export const MAP_ITEM_DETAILS_UPDATE_REQUEST =
    'MAP_ITEM_DETAILS_UPDATE_REQUEST';
export const MAP_ITEM_DETAILS_UPDATE_SUCCESS =
    'MAP_ITEM_DETAILS_UPDATE_SUCCESS';
export const MAP_ITEM_DETAILS_UPDATE_FAILURE =
    'MAP_ITEM_DETAILS_UPDATE_FAILURE';

export const MAP_ITEM_FIELD_TYPE_CHANGE = 'MAP_ITEM_FIELD_TYPE_CHANGE';

export const MAP_ACTION_SAVE_REQUEST = 'MAP_ACTION_SAVE_REQUEST';
export const MAP_ACTION_SAVE_SUCCESS = 'MAP_ACTION_SAVE_SUCCESS';
export const MAP_ACTION_SAVE_FAILURE = 'MAP_ACTION_SAVE_FAILURE';

export const MAP_ACTION_DELETE_REQUEST = 'MAP_ACTION_DELETE_REQUEST';
export const MAP_ACTION_DELETE_SUCCESS = 'MAP_ACTION_DELETE_SUCCESS';
export const MAP_ACTION_DELETE_FAILURE = 'MAP_ACTION_DELETE_FAILURE';

export const MAP_ACTION_COPY_REQUEST = 'MAP_ACTION_COPY_REQUEST';
export const MAP_ACTION_COPY_SUCCESS = 'MAP_ACTION_COPY_SUCCESS';
export const MAP_ACTION_COPY_FAILURE = 'MAP_ACTION_COPY_FAILURE';

export const MAP_ADD_ACTION = 'MAP_ADD_ACTION';
export const MAP_UPDATE_ACTION = 'MAP_UPDATE_ACTION';
export const MAP_DELETE_ACTION = 'MAP_DELETE_ACTION';

export const MAP_ASSOCIATE_REQUEST = 'MAP_ASSOCIATE_REQUEST';
export const MAP_ASSOCIATE_SUCCESS = 'MAP_ASSOCIATE_SUCCESS';
export const MAP_ASSOCIATE_FAILURE = 'MAP_ASSOCIATE_FAILURE';

export const MAP_UPDATE_MAP_FILTER_DELIMETER_REQUEST =
    'MAP_UPDATE_MAP_FILTER_DELIMETER_REQUEST';
export const MAP_UPDATE_MAP_FILTER_DELIMETER_SUCCESS =
    'MAP_UPDATE_MAP_FILTER_DELIMETER_SUCCESS';
export const MAP_UPDATE_MAP_FILTER_DELIMETER_FAILURE =
    'MAP_UPDATE_MAP_FILTER_DELIMETER_FAILURE';

//Reports
export const REPORTS_GET_LIST_REQUEST = 'REPORTS_GET_LIST_REQUEST';
export const REPORTS_GET_LIST_SUCCESS = 'REPORTS_GET_LIST_SUCCESS';
export const REPORTS_GET_LIST_FAILURE = 'REPORTS_GET_LIST_FAILURE';

export const GENERATED_REPORTS_GET_LIST_REQUEST =
    'GENERATED_REPORTS_GET_LIST_REQUEST';
export const GENERATED_REPORTS_GET_LIST_SUCCESS =
    'GENERATED_REPORTS_GET_LIST_SUCCESS';
export const GENERATED_REPORTS_GET_LIST_FAILURE =
    'GENERATED_REPORTS_GET_LIST_FAILURE';

export const GENERATED_REPORT_GET_DETAILS_REQUEST =
    'GENERATED_REPORT_GET_DETAILS_REQUEST';
export const GENERATED_REPORT_GET_DETAILS_SUCCESS =
    'GENERATED_REPORT_GET_DETAILS_SUCCESS';
export const GENERATED_REPORT_GET_DETAILS_FAILURE =
    'GENERATED_REPORT_GET_DETAILS_FAILURE';

export const GENERATED_REPORTS_GET_COUNT_REQUEST =
    'GENERATED_REPORTS_GET_COUNT_REQUEST';
export const GENERATED_REPORTS_GET_COUNT_SUCCESS =
    'GENERATED_REPORTS_GET_COUNT_SUCCESS';
export const GENERATED_REPORTS_GET_COUNT_FAILURE =
    'GENERATED_REPORTS_GET_COUNT_FAILURE';

export const GENERATE_REPORT_GET_REQUEST = 'GENERATE_REPORT_GET_REQUEST';
export const GENERATE_REPORT_GET_SUCCESS = 'GENERATE_REPORT_GET_SUCCESS';
export const GENERATE_REPORT_GET_FAILURE = 'GENERATE_REPORT_GET_FAILURE';

export const REPORTS_SCHEDULED_GET_REQUEST = 'REPORTS_SCHEDULED_GET_REQUEST';
export const REPORTS_SCHEDULED_GET_SUCCESS = 'REPORTS_SCHEDULED_GET_SUCCESS';
export const REPORTS_SCHEDULED_GET_FAILURE = 'REPORTS_SCHEDULED_GET_FAILURE';

// Notifications
export const NOTIFICATIONS_GET_REQUEST = 'NOTIFICATIONS_GET_REQUEST';
export const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS';
export const NOTIFICATIONS_GET_FAILURE = 'NOTIFICATIONS_GET_FAILURE';

export const NOTIFICATIONS_PUT_REQUEST = 'NOTIFICATIONS_PUT_REQUEST';
export const NOTIFICATIONS_PUT_SUCCESS = 'NOTIFICATIONS_PUT_SUCCESS';
export const NOTIFICATIONS_PUT_FAILURE = 'NOTIFICATIONS_PUT_FAILURE';

export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS';

// States
export const STATES_GET_LIST_REQUEST = 'STATES_GET_LIST_REQUEST';
export const STATES_GET_LIST_SUCCESS = 'STATES_GET_LIST_SUCCESS';
export const STATES_GET_LIST_FAILURE = 'STATES_GET_LIST_FAILURE';

// Cities
export const CITIES_GET_LIST_REQUEST = 'CITIES_GET_LIST_REQUEST';
export const CITIES_GET_LIST_SUCCESS = 'CITIES_GET_LIST_SUCCESS';
export const CITIES_GET_LIST_FAILURE = 'CITIES_GET_LIST_FAILURE';

export const ENABLED_CITIES_GET_LIST_REQUEST =
    'ENABLED_CITIES_GET_LIST_REQUEST';
export const ENABLED_CITIES_GET_LIST_SUCCESS =
    'ENABLED_CITIES_GET_LIST_SUCCESS';
export const ENABLED_CITIES_GET_LIST_FAILURE =
    'ENABLED_CITIES_GET_LIST_FAILURE';

export const ENABLED_NACIONAL_CITIES_GET_LIST_REQUEST =
    'ENABLED_NACIONAL_CITIES_GET_LIST_REQUEST';
export const ENABLED_NACIONAL_CITIES_GET_LIST_SUCCESS =
    'ENABLED_NACIONAL_CITIES_GET_LIST_SUCCESS';
export const ENABLED_NACIONAL_CITIES_GET_LIST_FAILURE =
    'ENABLED_NACIONAL_CITIES_GET_LIST_FAILURE';

// Files
export const FILES_CREATE_REQUEST = 'FILES_CREATE_REQUEST';
export const FILES_CREATE_SUCCESS = 'FILES_CREATE_SUCCESS';
export const FILES_CREATE_FAILURE = 'FILES_CREATE_FAILURE';
export const FILES_GET_REQUEST = 'FILES_GET_REQUEST';
export const FILES_GET_SUCCESS = 'FILES_GET_SUCCESS';
export const FILES_GET_FAILURE = 'FILES_GET_FAILURE';

// Settings
export const SETTINGS_CHANGE_PASSWORD_REQUEST =
    'SETTINGS_CHANGE_PASSWORD_REQUEST';
export const SETTINGS_CHANGE_PASSWORD_SUCCESS =
    'SETTINGS_CHANGE_PASSWORD_SUCCESS';
export const SETTINGS_CHANGE_PASSWORD_FAILURE =
    'SETTINGS_CHANGE_PASSWORD_FAILURE';

export const SETTINGS_EMAILS_GET_LIST_REQUEST =
    'SETTINGS_EMAILS_GET_LIST_REQUEST';
export const SETTINGS_EMAILS_GET_LIST_SUCCESS =
    'SETTINGS_EMAILS_GET_LIST_SUCCESS';
export const SETTINGS_EMAILS_GET_LIST_FAILURE =
    'SETTINGS_EMAILS_GET_LIST_FAILURE';

export const SETTINGS_EMAILS_GET_COUNT_REQUEST =
    'SETTINGS_EMAILS_GET_COUNT_REQUEST';
export const SETTINGS_EMAILS_GET_COUNT_SUCCESS =
    'SETTINGS_EMAILS_GET_COUNT_SUCCESS';
export const SETTINGS_EMAILS_GET_COUNT_FAILURE =
    'SETTINGS_EMAILS_GET_COUNT_FAILURE';

export const SETTINGS_EMAIL_GET_DETAILS_REQUEST =
    'SETTINGS_EMAIL_GET_DETAILS_REQUEST';
export const SETTINGS_EMAIL_GET_DETAILS_SUCCESS =
    'SETTINGS_EMAIL_GET_DETAILS_SUCCESS';
export const SETTINGS_EMAIL_GET_DETAILS_FAILURE =
    'SETTINGS_EMAIL_GET_DETAILS_FAILURE';

export const SETTINGS_EMAIL_GET_DETAILS_NEW = 'SETTINGS_EMAIL_GET_DETAILS_NEW';

export const SETTINGS_ORDERS_PUT_REQUEST = 'SETTINGS_ORDERS_PUT_REQUEST';
export const SETTINGS_ORDERS_PUT_SUCCESS = 'SETTINGS_ORDERS_PUT_SUCCESS';
export const SETTINGS_ORDERS_PUT_FAILURE = 'SETTINGS_ORDERS_PUT_FAILURE';

export const SETTINGS_CITIES_MONITOR_PUT_REQUEST =
    'SETTINGS_CITIES_MONITOR_PUT_REQUEST';
export const SETTINGS_CITIES_MONITOR_PUT_SUCCESS =
    'SETTINGS_CITIES_MONITOR_PUT_SUCCESS';
export const SETTINGS_CITIES_MONITOR_PUT_FAILURE =
    'SETTINGS_CITIES_MONITOR_PUT_FAILURE';

export const SETTINGS_NACIONAL_CITIES_MONITOR_PUT_REQUEST =
    'SETTINGS_NACIONAL_CITIES_MONITOR_PUT_REQUEST';
export const SETTINGS_NACIONAL_CITIES_MONITOR_PUT_SUCCESS =
    'SETTINGS_NACIONAL_CITIES_MONITOR_PUT_SUCCESS';
export const SETTINGS_NACIONAL_CITIES_MONITOR_PUT_FAILURE =
    'SETTINGS_NACIONAL_CITIES_MONITOR_PUT_FAILURE';

export const SETTINGS_REPORTS_PUT_REQUEST = 'SETTINGS_REPORTS_PUT_REQUEST';
export const SETTINGS_REPORTS_PUT_SUCCESS = 'SETTINGS_REPORTS_PUT_SUCCESS';
export const SETTINGS_REPORTS_PUT_FAILURE = 'SETTINGS_REPORTS_PUT_FAILURE';

//Create,Delete & Update Emails
export const SETTINGS_EMAIL_CREATE_REQUEST = 'SETTINGS_EMAIL_CREATE_REQUEST';
export const SETTINGS_EMAIL_CREATE_SUCCESS = 'SETTINGS_EMAIL_CREATE_SUCCESS';
export const SETTINGS_EMAIL_CREATE_FAILURE = 'SETTINGS_EMAIL_CREATE_FAILURE';

export const SETTINGS_EMAIL_PUT_REQUEST = 'SETTINGS_EMAIL_PUT_REQUEST';
export const SETTINGS_EMAIL_PUT_SUCCESS = 'SETTINGS_EMAIL_PUT_SUCCESS';
export const SETTINGS_EMAIL_PUT_FAILURE = 'SETTINGS_EMAIL_PUT_FAILURE';

export const SETTINGS_EMAIL_DELETE_REQUEST = 'SETTINGS_EMAIL_DELETE_REQUEST';
export const SETTINGS_EMAIL_DELETE_SUCCESS = 'SETTINGS_EMAIL_DELETE_SUCCESS';
export const SETTINGS_EMAIL_DELETE_FAILURE = 'SETTINGS_EMAIL_DELETE_FAILURE';

export const SETTINGS_EMAIL_NOTIFY_REQUEST = 'SETTINGS_EMAIL_NOTIFY_REQUEST';
export const SETTINGS_EMAIL_NOTIFY_SUCCESS = 'SETTINGS_EMAIL_NOTIFY_SUCCESS';
export const SETTINGS_EMAIL_NOTIFY_FAILURE = 'SETTINGS_EMAIL_NOTIFY_FAILURE';

export const SETTINGS_EMAIL_NOTIFY_UPDATE_REQUEST =
    'SETTINGS_EMAIL_NOTIFY_UPDATE_REQUEST';
export const SETTINGS_EMAIL_NOTIFY_UPDATE_SUCCESS =
    'SETTINGS_EMAIL_NOTIFY_UPDATE_SUCCESS';
export const SETTINGS_EMAIL_NOTIFY_UPDATE_FAILURE =
    'SETTINGS_EMAIL_NOTIFY_UPDATE_FAILURE';

export const SETTINGS_EMAIL_NOTIFY_CREATE_REQUEST =
    'SETTINGS_EMAIL_NOTIFY_CREATE_REQUEST';
export const SETTINGS_EMAIL_NOTIFY_CREATE_SUCCESS =
    'SETTINGS_EMAIL_NOTIFY_CREATE_SUCCESS';
export const SETTINGS_EMAIL_NOTIFY_CREATE_FAILURE =
    'SETTINGS_EMAIL_NOTIFY_CREATE_FAILURE';

export const SETTINGS_MODULE_REQUEST = 'SETTINGS_MODULE_REQUEST';
export const SETTINGS_MODULE_SUCCESS = 'SETTINGS_MODULE_SUCCESS';
export const SETTINGS_MODULE_FAILURE = 'SETTINGS_MODULE_FAILURE';

export const SETTINGS_UPDATE_MODULE_REQUEST = 'SETTINGS_UPDATE_MODULE_REQUEST';
export const SETTINGS_UPDATE_MODULE_SUCCESS = 'SETTINGS_UPDATE_MODULE_SUCCESS';
export const SETTINGS_UPDATE_MODULE_FAILURE = 'SETTINGS_UPDATE_MODULE_FAILURE';

export const SETTINGS_SET_CITIES_SUCCESS = 'SETTINGS_SET_CITIES_SUCCESS';

export const SETTINGS_SET_NACIONAL_CITIES_SUCCESS =
    'SETTINGS_SET_NACIONAL_CITIES_SUCCESS';

// Roles
export const CLAIMS_GET_LIST_REQUEST = 'CLAIMS_GET_LIST_REQUEST';
export const CLAIMS_GET_LIST_SUCCESS = 'CLAIMS_GET_LIST_SUCCESS';
export const CLAIMS_GET_LIST_FAILURE = 'CLAIMS_GET_LIST_FAILURE';

// FILTER EVERYTHING
export const FILTER_EVERYTHING = 'FILTER_EVERYTHING';

// Widgets
export const WIDGETS_GET_REQUEST = 'WIDGETS_GET_REQUEST';
export const WIDGETS_GET_SUCCESS = 'WIDGETS_GET_SUCCESS';
export const WIDGETS_GET_FAILURE = 'WIDGETS_GET_FAILURE';

export const WIDGET_GET_REQUEST = 'WIDGET_GET_REQUEST';
export const WIDGET_GET_SUCCESS = 'WIDGET_GET_SUCCESS';
export const WIDGET_GET_FAILURE = 'WIDGET_GET_FAILURE';

export const GET_USER_CONTRACTOR_LIST_REQUEST =
    'GET_USER_CONTRACTOR_LIST_REQUEST';
export const GET_USER_CONTRACTOR_LIST_SUCCESS =
    'GET_USER_CONTRACTOR_LIST_SUCCESS';
export const GET_USER_CONTRACTOR_LIST_FAILURE =
    'GET_USER_CONTRACTOR_LIST_FAILURE';

// OCR OPERATION RESULTS
export const OCR_OPERATION_RESULT_REQUEST = 'OCR_OPERATION_RESULT_REQUEST';
export const OCR_OPERATION_RESULT_SUCCESS = 'OCR_OPERATION_RESULT_SUCCESS';
export const OCR_OPERATION_RESULT_FAILURE = 'OCR_OPERATION_RESULT_FAILURE';

//lOGS
export const AUDIT_LOGS_GET_LIST_REQUEST = 'AUDIT_LOGS_GET_LIST_REQUEST';
export const AUDIT_LOGS_GET_LIST_SUCCESS = 'AUDIT_LOGS_GET_LIST_SUCCESS';
export const AUDIT_LOGS_GET_LIST_FAILURE = 'AUDIT_LOGS_GET_LIST_FAILURE';

export const TECHNICAL_LOGS_GET_LIST_REQUEST =
    'TECHNICAL_LOGS_GET_LIST_REQUEST';
export const TECHNICAL_LOGS_GET_LIST_SUCCESS =
    'TECHNICAL_LOGS_GET_LIST_SUCCESS';
export const TECHNICAL_LOGS_GET_LIST_FAILURE =
    'TECHNICAL_LOGS_GET_LIST_FAILURE';

export const AUDIT_LOGS_GET_COUNT_REQUEST = 'AUDIT_LOGS_GET_COUNT_REQUEST';
export const AUDIT_LOGS_GET_COUNT_SUCCESS = 'AUDIT_LOGS_GET_COUNT_SUCCESS';
export const AUDIT_LOGS_GET_COUNT_FAILURE = 'AUDIT_LOGS_GET_COUNT_FAILURE';

export const TECHNICAL_LOGS_GET_COUNT_REQUEST =
    'TECHNICAL_LOGS_GET_COUNT_REQUEST';
export const TECHNICAL_LOGS_GET_COUNT_SUCCESS =
    'TECHNICAL_LOGS_GET_COUNT_SUCCESS';
export const TECHNICAL_LOGS_GET_COUNT_FAILURE =
    'TECHNICAL_LOGS_GET_COUNT_FAILURE';

//Categories
export const CATEGORIES_GET_SUCCESS = 'CATEGORIES_GET_SUCCESS';
export const CATEGORIES_GET_FAILURE = 'CATEGORIES_GET_FAILURE';
export const CATEGORIES_GET_REQUEST = 'CATEGORIES_GET_REQUEST';

export const CATEGORIES_GET_LIST_SUCCESS = 'CATEGORIES_GET_LIST_SUCCESS';
export const CATEGORIES_GET_LIST_FAILURE = 'CATEGORIES_GET_LIST_FAILURE';
export const CATEGORIES_GET_LIST_REQUEST = 'CATEGORIES_GET_LIST_REQUEST';

export const CATEGORIES_GET_COUNT_SUCCESS = 'CATEGORIES_GET_COUNT_SUCCESS';
export const CATEGORIES_GET_COUNT_FAILURE = 'CATEGORIES_GET_COUNT_FAILURE';
export const CATEGORIES_GET_COUNT_REQUEST = 'CATEGORIES_GET_COUNT_REQUEST';

export const CATEGORY_GET_DETAILS_SUCCESS = 'CATEGORY_GET_DETAILS_SUCCESS';
export const CATEGORY_GET_DETAILS_FAILURE = 'CATEGORY_GET_DETAILS_FAILURE';
export const CATEGORY_GET_DETAILS_REQUEST = 'CATEGORY_GET_DETAILS_REQUEST';

export const CATEGORIES_CREATE_SUCCESS = 'CATEGORIES_CREATE_SUCCESS';
export const CATEGORIES_CREATE_FAILURE = 'CATEGORIES_CREATE_FAILURE';
export const CATEGORIES_CREATE_REQUEST = 'CATEGORIES_CREATE_REQUEST';

export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS';
export const CATEGORIES_UPDATE_FAILURE = 'CATEGORIES_UPDATE_FAILURE';
export const CATEGORIES_UPDATE_REQUEST = 'CATEGORIES_UPDATE_REQUEST';

export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS';
export const CATEGORIES_DELETE_FAILURE = 'CATEGORIES_DELETE_FAILURE';
export const CATEGORIES_DELETE_REQUEST = 'CATEGORIES_DELETE_REQUEST';

// CFOP
export const CFOPS_GET_SUCCESS = 'CFOPS_GET_SUCCESS';
export const CFOPS_GET_FAILURE = 'CFOPS_GET_FAILURE';
export const CFOPS_GET_REQUEST = 'CFOPS_GET_REQUEST';

//Contractor
export const CONTRACTOR_SET_REQUEST = 'CONTRACTOR_SET_REQUEST';
export const CONTRACTOR_SET_SUCCESS = 'CONTRACTOR_SET_SUCCESS';
export const CONTRACTOR_SET_FAILURE = 'CONTRACTOR_SET_FAILURE';

export const SET_TAB_CONTRACTOR_INDEX = 'SET_TAB_CONTRACTOR_INDEX';

export const SUPPLIER_SET_REQUEST = 'SUPPLIER_SET_REQUEST';
export const SUPPLIER_SET_SUCCESS = 'SUPPLIER_SET_SUCCESS';
export const SUPPLIER_SET_FAILURE = 'SUPPLIER_SET_FAILURE';

export const CONTRACTORS_GET_LAYOUT_REQUEST = 'CONTRACTORS_GET_LAYOUT_REQUEST';
export const CONTRACTORS_GET_LAYOUT_SUCCESS = 'CONTRACTORS_GET_LAYOUT_SUCCESS';
export const CONTRACTORS_GET_LAYOUT_FAILURE = 'CONTRACTORS_GET_LAYOUT_FAILURE';

export const INVOICE_GET_XML_ZIP_FAILURE = 'INVOICE_GET_XML_ZIP_FAILURE';
export const INVOICE_GET_XML_ZIP_SUCCESS = 'INVOICE_GET_XML_ZIP_SUCCESS';
export const INVOICE_GET_XML_ZIP_REQUEST = 'INVOICE_GET_XML_ZIP_REQUEST';
export const INVOICE_UPDATE_ACTIVE_TAB = 'INVOICE_UPDATE_ACTIVE_TAB';
export const INVOICE_GET_ACTIVE_TAB = 'INVOICE_GET_ACTIVE_TAB';
export const INVOICE_GET_REPORT_CANCEL_REQUEST =
    'INVOICE_GET_REPORT_CANCEL_REQUEST';

export const TABNAME_SET_REQUEST = 'TABNAME_SET_REQUEST';
export const TABNAME_SET_SUCCESS = 'TABNAME_SET_SUCCESS';

export const QUERY_SET_REQUEST = 'QUERY_SET_REQUEST';
export const QUERY_SET_SUCCESS = 'QUERY_SET_SUCCESS';

//Integration's Page

export const INTEGRATIONS_NFS_GET_LIST_REQUEST =
    'INTEGRATIONS_NFS_GET_LIST_REQUEST';
export const INTEGRATIONS_NFS_GET_LIST_SUCCESS =
    'INTEGRATIONS_NFS_GET_LIST_SUCCESS';
export const INTEGRATIONS_NFS_GET_LIST_FAILURE =
    'INTEGRATIONS_NFS_GET_LIST_FAILURE';

export const INTEGRATIONS_NFS_GET_COUNT_REQUEST =
    'INTEGRATIONS_NFS_GET_COUNT_REQUEST';
export const INTEGRATIONS_NFS_GET_COUNT_SUCCESS =
    'INTEGRATIONS_NFS_GET_COUNT_SUCCESS';
export const INTEGRATIONS_NFS_GET_COUNT_FAILURE =
    'INTEGRATIONS_NFS_GET_COUNT_FAILURE';

export const INTEGRATIONS_TAB_SET_REQUEST = 'INTEGRATIONS_TAB_SET_REQUEST';
export const INTEGRATIONS_TAB_SET_SUCCESS = 'INTEGRATIONS_TAB_SET_SUCCESS';

export const INTEGRATIONS_GET_DETAILS_REQUEST =
    'INTEGRATIONS_GET_DETAILS_REQUEST';
export const INTEGRATIONS_GET_DETAILS_SUCCESS =
    'INTEGRATIONS_GET_DETAILS_SUCCESS';
export const INTEGRATIONS_GET_DETAILS_FAILURE =
    'INTEGRATIONS_GET_DETAILS_FAILURE';

export const INTEGRATIONS_LOGS_GET_LIST_REQUEST =
    'INTEGRATIONS_LOGS_GET_LIST_REQUEST';
export const INTEGRATIONS_LOGS_GET_LIST_SUCCESS =
    'INTEGRATIONS_LOGS_GET_LIST_SUCCESS';
export const INTEGRATIONS_LOGS_GET_LIST_FAILURE =
    'INTEGRATIONS_LOGS_GET_LIST_FAILURE';

export const INTEGRATIONS_LOGS_GET_COUNT_REQUEST =
    'INTEGRATIONS_LOGS_GET_COUNT_REQUEST';
export const INTEGRATIONS_LOGS_GET_COUNT_SUCCESS =
    'INTEGRATIONS_LOGS_GET_COUNT_SUCCESS';
export const INTEGRATIONS_LOGS_GET_COUNT_FAILURE =
    'INTEGRATIONS_LOGS_GET_COUNT_FAILURE';

export const INTEGRATIONS_CTE_GET_LIST_REQUEST =
    'INTEGRATIONS_CTE_GET_LIST_REQUEST';
export const INTEGRATIONS_CTE_GET_LIST_SUCCESS =
    'INTEGRATIONS_CTE_GET_LIST_SUCCESS';
export const INTEGRATIONS_CTE_GET_LIST_FAILURE =
    'INTEGRATIONS_CTE_GET_LIST_FAILURE';

export const INTEGRATIONS_CTE_GET_COUNT_REQUEST =
    'INTEGRATIONS_CTE_GET_COUNT_REQUEST';
export const INTEGRATIONS_CTE_GET_COUNT_SUCCESS =
    'INTEGRATIONS_CTE_GET_COUNT_SUCCESS';
export const INTEGRATIONS_CTE_GET_COUNT_FAILURE =
    'INTEGRATIONS_CTE_GET_COUNT_FAILURE';

export const INTEGRATIONS_NFE_GET_LIST_REQUEST =
    'INTEGRATIONS_NFE_GET_LIST_REQUEST';
export const INTEGRATIONS_NFE_GET_LIST_SUCCESS =
    'INTEGRATIONS_NFE_GET_LIST_SUCCESS';
export const INTEGRATIONS_NFE_GET_LIST_FAILURE =
    'INTEGRATIONS_NFE_GET_LIST_FAILURE';

export const INTEGRATIONS_NFE_GET_COUNT_REQUEST =
    'INTEGRATIONS_NFE_GET_COUNT_REQUEST';
export const INTEGRATIONS_NFE_GET_COUNT_SUCCESS =
    'INTEGRATIONS_NFE_GET_COUNT_SUCCESS';
export const INTEGRATIONS_NFE_GET_COUNT_FAILURE =
    'INTEGRATIONS_NFE_GET_COUNT_FAILURE';

export const INTEGRATIONS_NATIONAL_NFSE_GET_LIST_REQUEST =
    'INTEGRATIONS_NATIONAL_NFSE_GET_LIST_REQUEST';
export const INTEGRATIONS_NATIONAL_NFSE_GET_LIST_SUCCESS =
    'INTEGRATIONS_NATIONAL_NFSE_GET_LIST_SUCCESS';
export const INTEGRATIONS_NATIONAL_NFSE_GET_LIST_FAILURE =
    'INTEGRATIONS_NATIONAL_NFSE_GET_LIST_FAILURE';

export const PENDING_FILES_GET_LIST_REQUEST = 'PENDING_FILES_GET_LIST_REQUEST';
export const PENDING_FILES_GET_LIST_SUCCESS = 'PENDING_FILES_GET_LIST_SUCCESS';
export const PENDING_FILES_GET_LIST_FAILURE = 'PENDING_FILES_GET_LIST_FAILURE';

export const PENDING_FILES_GET_DETAILS_REQUEST =
    'PENDING_FILES_GET_DETAILS_REQUEST';
export const PENDING_FILES_GET_DETAILS_SUCCESS =
    'PENDING_FILES_GET_DETAILS_SUCCESS';
export const PENDING_FILES_GET_DETAILS_FAILURE =
    'PENDING_FILES_GET_DETAILS_FAILURE';

export const PENDING_FILES_ACTION_REQUEST =
    'PENDING_FILES_ACTION_REQUEST';
export const PENDING_FILES_ACTION_SUCCESS =
    'PENDING_FILES_ACTION_SUCCESS';
export const PENDING_FILES_ACTION_FAILURE =
    'PENDING_FILES_ACTION_FAILURE';
