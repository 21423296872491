import React from 'react';
const NFSeIcon = ({
    width = '33',
    height = '30',
    viewBox = '0 0 33 30',
    fill = 'none',
}) => (
    <svg
        width={width}
        height={height}
        viewBox={viewBox}
        style={{ marginLeft: '-5px', marginRight: '25px' }}
        fill={fill}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M26.2969 13H3.60938C3.32461 13 3.09375 13.2239 3.09375 13.5V20.5C3.09375 20.7761 3.32461 21 3.60938 21H12.7216H17.5751H26.2969C26.5816 21 26.8125 20.7761 26.8125 20.5V13.5C26.8125 13.2239 26.5816 13 26.2969 13Z'
            fill='black'
            stroke='black'
            stroke-width='2'
        />
        <path
            d='M8.20419 14.1818V20H7.14168L4.61043 16.3381H4.56782V20H3.33771V14.1818H4.41725L6.92862 17.8409H6.97975V14.1818H8.20419Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M9.22052 20V14.1818H13.0728V15.196H10.4506V16.5824H12.8171V17.5966H10.4506V20H9.22052Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M17.0565 15.8551C17.0337 15.6259 16.9362 15.4479 16.7638 15.321C16.5915 15.1941 16.3576 15.1307 16.0621 15.1307C15.8614 15.1307 15.6919 15.1591 15.5536 15.2159C15.4154 15.2708 15.3093 15.3475 15.2354 15.446C15.1635 15.5445 15.1275 15.6562 15.1275 15.7812C15.1237 15.8854 15.1455 15.9763 15.1928 16.054C15.2421 16.1316 15.3093 16.1989 15.3945 16.2557C15.4798 16.3106 15.5782 16.3589 15.69 16.4006C15.8017 16.4403 15.921 16.4744 16.0479 16.5028L16.5707 16.6278C16.8245 16.6847 17.0574 16.7604 17.2695 16.8551C17.4816 16.9498 17.6654 17.0663 17.8207 17.2045C17.976 17.3428 18.0962 17.5057 18.1815 17.6932C18.2686 17.8807 18.3131 18.0956 18.315 18.3381C18.3131 18.6941 18.2222 19.0028 18.0423 19.2642C17.8642 19.5237 17.6066 19.7254 17.2695 19.8693C16.9343 20.0114 16.5299 20.0824 16.0565 20.0824C15.5868 20.0824 15.1777 20.0104 14.8292 19.8665C14.4826 19.7225 14.2118 19.5095 14.0167 19.2273C13.8235 18.9432 13.7222 18.5918 13.7127 18.1733H14.903C14.9163 18.3684 14.9722 18.5312 15.0707 18.6619C15.171 18.7907 15.3046 18.8882 15.4712 18.9545C15.6398 19.0189 15.8301 19.0511 16.0423 19.0511C16.2506 19.0511 16.4315 19.0208 16.5849 18.9602C16.7402 18.8996 16.8604 18.8153 16.9457 18.7074C17.0309 18.5994 17.0735 18.4754 17.0735 18.3352C17.0735 18.2045 17.0347 18.0947 16.957 18.0057C16.8813 17.9167 16.7695 17.8409 16.6218 17.7784C16.476 17.7159 16.297 17.6591 16.0849 17.6079L15.4513 17.4489C14.9608 17.3295 14.5735 17.143 14.2894 16.8892C14.0053 16.6354 13.8642 16.2936 13.8661 15.8636C13.8642 15.5114 13.958 15.2036 14.1474 14.9403C14.3387 14.6771 14.601 14.4716 14.9343 14.3239C15.2676 14.1761 15.6464 14.1023 16.0707 14.1023C16.5025 14.1023 16.8794 14.1761 17.2013 14.3239C17.5252 14.4716 17.7771 14.6771 17.957 14.9403C18.1369 15.2036 18.2298 15.5085 18.2354 15.8551H17.0565Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M21.8384 17.1307V18.0909H19.185V17.1307H21.8384Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M24.8583 20.0852C24.4094 20.0852 24.0231 19.9943 23.6992 19.8125C23.3772 19.6288 23.1291 19.3693 22.9549 19.0341C22.7807 18.697 22.6935 18.2983 22.6935 17.8381C22.6935 17.3892 22.7807 16.9953 22.9549 16.6562C23.1291 16.3172 23.3744 16.053 23.6907 15.8636C24.0089 15.6742 24.382 15.5795 24.81 15.5795C25.0979 15.5795 25.3659 15.6259 25.614 15.7187C25.864 15.8097 26.0818 15.947 26.2674 16.1307C26.4549 16.3144 26.6007 16.5454 26.7049 16.8239C26.8091 17.1004 26.8611 17.4242 26.8611 17.7954V18.1278H23.1765V17.3778H25.7219C25.7219 17.2036 25.6841 17.0492 25.6083 16.9148C25.5325 16.7803 25.4274 16.6752 25.293 16.5994C25.1604 16.5218 25.006 16.4829 24.8299 16.4829C24.6462 16.4829 24.4833 16.5256 24.3413 16.6108C24.2011 16.6941 24.0913 16.8068 24.0117 16.9489C23.9322 17.089 23.8914 17.2453 23.8896 17.4176V18.1307C23.8896 18.3466 23.9293 18.5331 24.0089 18.6903C24.0903 18.8475 24.2049 18.9687 24.3526 19.054C24.5004 19.1392 24.6755 19.1818 24.8782 19.1818C25.0127 19.1818 25.1358 19.1629 25.2475 19.125C25.3593 19.0871 25.4549 19.0303 25.5344 18.9545C25.614 18.8788 25.6746 18.786 25.7163 18.6761L26.8356 18.75C26.7788 19.0189 26.6623 19.2538 26.4861 19.4545C26.3119 19.6534 26.0865 19.8087 25.81 19.9204C25.5354 20.0303 25.2182 20.0852 24.8583 20.0852Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M9.69376 23.6V27.4C9.69335 27.5572 9.72487 27.7129 9.78654 27.8583C9.8482 28.0037 9.93879 28.1359 10.0531 28.2473C10.1675 28.3587 10.3033 28.4472 10.4529 28.5077C10.6025 28.5683 10.763 28.5996 10.9251 28.6H28.2563C28.4184 28.6004 28.5789 28.5698 28.7289 28.51C28.8788 28.4502 29.0152 28.3624 29.13 28.2515C29.2449 28.1407 29.3362 28.0089 29.3986 27.8638C29.4611 27.7188 29.4933 27.5632 29.4938 27.406V27.4V9.4H23.7188C23.4999 9.4 23.2901 9.31571 23.1354 9.16569C22.9807 9.01566 22.8938 8.81217 22.8938 8.6V3H10.9313C10.6041 3 10.2903 3.1256 10.0584 3.34935C9.82649 3.5731 9.69539 3.87678 9.69376 4.194V10.4045'
            stroke='black'
            stroke-width='2.15'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M29.1328 8.25L24.0848 3.35C23.9696 3.23866 23.8329 3.15044 23.6824 3.09039C23.5321 3.03033 23.3709 2.99962 23.2083 3H22.8937V8.6C22.8937 8.81218 22.9807 9.01566 23.1354 9.16569C23.29 9.31572 23.4999 9.4 23.7187 9.4H29.4937V9.095C29.4937 8.93807 29.4619 8.78268 29.3999 8.63769C29.338 8.49271 29.2473 8.36097 29.1328 8.25Z'
            stroke='black'
            stroke-width='2.15'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default NFSeIcon;
