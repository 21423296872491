import React, { useState } from 'react';
import messages from '../MapDetailsPage.intl';
import { injectIntl } from 'react-intl';

import { Col, Container, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import Modal from '../../../components/Modal/Modal';
import EvtSelect from '../../../components/EvtSelect/EvtSelect';
import { useEffect } from 'react';

const ModalAssociateMap = props => {
    const {
        intl,
        map,
        showAssociateMapModal,
        showAssociateConfirmationModal,
        handleToggleModalAssociateMap,
        handleAssociateMapSubmit,
        handleToggleConfirmationModalAssociate,
    } = props;

    useEffect(() => {
        setAssociateValues({
            contractorId: map.contractorId,
            mapId: map.mapId,
        });
    }, [map]);

    const [associateValues, setAssociateValues] = useState({
        contractorId: map.contractorId,
        mapId: map.mapId,
    });

    const handleSubmitAssociateModal = (e, values) => {
        setAssociateValues({ ...values });
        handleToggleConfirmationModalAssociate();
    };

    const handleConfirmAssociate = () => {
        handleAssociateMapSubmit(Event, associateValues);
        handleToggleConfirmationModalAssociate();
        handleToggleModalAssociateMap(true);
    };

    return (
        <>
            <Modal
                title={intl.formatMessage(messages.modalAssociateTitle)}
                isOpen={showAssociateMapModal}
                handleToggle={handleToggleModalAssociateMap}
            >
                <AvForm
                    onValidSubmit={(e, values) => {
                        handleSubmitAssociateModal(e, {
                            ...values,
                            mapId: map.mapId,
                        });
                    }}
                >
                    <Container fluid>
                        <Row>
                            <Col xs={12} className='text-left mt-3'>
                                <EvtSelect
                                    label={intl.formatMessage(
                                        messages.contractor,
                                    )}
                                    name='contractorId'
                                    url='/Contractors'
                                    labelKey='displayName'
                                    labelKey2='documentNumber1'
                                    valueKey='contractorId'
                                    async
                                    required
                                />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col xs={12}>
                                <button className='btn-submit'>
                                    {intl.formatMessage(messages.associate)}
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </AvForm>
            </Modal>
            <Modal
                title={intl.formatMessage(messages.confirmAssociate)}
                isOpen={showAssociateConfirmationModal}
                handleToggle={handleToggleConfirmationModalAssociate}
                hasFooter={true}
                firstButtonClass='delete'
                secondButtonClass='btn-submit'
                firstButtonText={intl.formatMessage(messages.noText)}
                secondButtonText={intl.formatMessage(messages.yesText)}
                toggleFirstButton={handleToggleConfirmationModalAssociate}
                toggleSecondButton={handleConfirmAssociate}
            >
                <p>Atenção:</p>
                {intl.formatMessage(messages.associateMapWarning)}
            </Modal>
        </>
    );
};

export default injectIntl(ModalAssociateMap);
