import React, { useContext } from 'react';
import { useHistory, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';

import globalMessages from '../../utils/globalMessages';
import { AuthContext } from '../../context/AuthContext';

import DropdownListButton from '../DropdownListButton/DropdownListButton';
import ButtonBadge from '../ButtonBadge/ButtonBadge';
import { useEffect } from 'react';

const ActionsButtons = props => {
    const {
        intl,
        dropdownItems,
        selectedDocument,
        loading,
        canUpdateForm,
        disableTransactionButton,
        onClickAttachmentsButton,
        documentsAttachmentCount,
        onClickOrdersButton,
        ordersCount,
        onCLickItemsNfe,
        nfeItemsCount,
        hideSubmitButtonClaim,
    } = props;

    const history = useHistory();
    const context = useContext(AuthContext);

    const hideSubmitButton =
        hideSubmitButtonClaim ||
        context.hasClaim('document-hide-submit-button');
    const isAttachment = !!selectedDocument?.isAttachment;
    const transactionButtonEnabled =
        context.hasClaim('transaction-view') &&
        selectedDocument?.currentStep?.status !== 'error' &&
        selectedDocument?.currentStep?.order > 3;

    const getDisableOfItems = items => {
        const disableArray = [];
        items.forEach(item => {
            if (item.disabled !== undefined && item.disabled !== null) {
                disableArray.push(!!item.disabled);
            }
        });

        if (disableArray.filter(x => x === false).length >= 1) {
            return false;
        } else {
            return true;
        }
    };

    const handlePaymentsButtonClick = e => {
        history.push({
            pathname: `/transactions`,
            state: { documentId: selectedDocument?.documentId },
        });
    };

    return (
        <Row
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ display: 'flex' }}>
                <Col xs={{ size: 2.4 }}>
                    {!isAttachment && (
                        <ButtonBadge count={documentsAttachmentCount || 0} />
                    )}
                    <button
                        type='button'
                        disabled={loading || isAttachment}
                        className={`btn-info ${isAttachment && 'btn-disable'}`}
                        onClick={onClickAttachmentsButton}
                    >
                        {intl.formatMessage(globalMessages.attachmentButton)}
                    </button>
                </Col>
                {onClickOrdersButton && (
                    <Col xs={{ size: 2.4 }}>
                        <ButtonBadge count={ordersCount || 0} />
                        <button
                            type='button'
                            disabled={loading}
                            className='btn-info'
                            onClick={onClickOrdersButton}
                        >
                            {intl.formatMessage(globalMessages.orders)}
                        </button>
                    </Col>
                )}
                {onCLickItemsNfe && (
                    <Col xs={{ size: 2.4 }}>
                        <ButtonBadge count={nfeItemsCount || 0} />
                        <button
                            type='button'
                            disabled={loading}
                            className='btn-info'
                            onClick={onCLickItemsNfe}
                        >
                            {intl.formatMessage(globalMessages.items, {
                                entity: 'da NF-e',
                            })}
                        </button>
                    </Col>
                )}

                <Col xs={{ size: 2.4 }}>
                    {!disableTransactionButton && transactionButtonEnabled && (
                        <button
                            type='button'
                            disabled={loading}
                            className='btn-info'
                            onClick={handlePaymentsButtonClick}
                        >
                            {intl.formatMessage(globalMessages.transactions)}
                        </button>
                    )}
                </Col>
            </div>

            <Col xs={{ size: 2.4 }} style={{ display: 'flex' }}>
                {canUpdateForm && !hideSubmitButton && (
                    <button
                        type='submit'
                        disabled={loading}
                        className='btn-submit'
                    >
                        {intl.formatMessage(globalMessages.submitButton)}
                    </button>
                )}

                {dropdownItems && (
                    <DropdownListButton
                        items={dropdownItems}
                        disable={getDisableOfItems(dropdownItems)}
                        className='document-dropdown'
                    />
                )}
            </Col>
        </Row>
    );
};

ActionsButtons.propTypes = {
    selectedDocument: PropTypes.object,
    dropdownItems: PropTypes.array,
    loading: PropTypes.bool,
    canUpdateForm: PropTypes.bool,
    disableTransactionButton: PropTypes.bool,
    disableOrderButton: PropTypes.bool,
    documentsAttachmentCount: PropTypes.number,
    ordersCount: PropTypes.number,
    itemsNfeCount: PropTypes.number,
    onClickAttachmentsButton: PropTypes.func,
    onClickOrdersButton: PropTypes.func,
    onCLickItemsNfe: PropTypes.func,
};

export default injectIntl(withRouter(ActionsButtons));
