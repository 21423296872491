import { formGroupTypes } from '../../../utils/enums';
import messages from '../../DynamicTemplateForm/DynamicTemplateForm.intl';

export const cteTemplate = (ICMSType, TomaType, TomaTag) => {
    let template = [
        //#region Cabeçalho
        {
            key: 'viewDocumentId',
            name: 'ID do documento',
            formGroup: 1,
            size: 12,
        },
        {
            key: '/cteProc/protCTe/infProt/chCTe',
            name: 'Chave de acesso',
            formGroup: 1,
            size: 8,
        },
        {
            key: '/cteProc/CTe/infCte/ide/serie',
            name: 'Série',
            formGroup: 1,
            size: 4,
        },
        {
            key: '/cteProc/CTe/infCte/ide/nCT',
            name: 'Número do CT-e',
            formGroup: 1,
            size: 6,
        },

        {
            key: '/cteProc/CTe/infCte/ide/dhEmi',
            name: 'Data emissão',
            formGroup: 1,
            size: 6,
        },
        //#endregion Cabeçalho

        //#region Emitente
        {
            key: '/cteProc/CTe/infCte/emit/xNome',
            name: 'Razão social',
            formGroup: 2,
            size: 8,
        },
        {
            key: '/cteProc/CTe/infCte/emit/enderEmit/UF',
            name: 'UF Emitente',
            formGroup: 2,
            size: 4,
        },
        {
            key: '/cteProc/CTe/infCte/emit/CNPJ',
            name: 'CNPJ',
            formGroup: 2,
            size: 12,
        },
        //#endregion Emitente

        //#region Remetente
        {
            key: '/cteProc/CTe/infCte/rem/xNome',
            name: 'Razão social',
            formGroup: 3,
            size: 8,
        },
        {
            key: '/cteProc/CTe/infCte/rem/enderReme/UF',
            name: 'UF Remetente',
            formGroup: 3,
            size: 4,
        },
        {
            key: '/cteProc/CTe/infCte/rem/CNPJ',
            name: 'CNPJ',
            formGroup: 3,
            size: 12,
        },
        //#endregion Remetente

        //#region Expedidor
        {
            key: '/cteProc/CTe/infCte/exped/xNome',
            name: 'Razão social',
            formGroup: 4,
            size: 8,
        },
        {
            key: '/cteProc/CTe/infCte/exped/enderExped/UF',
            name: 'UF Expedidor',
            formGroup: 4,
            size: 4,
        },
        {
            key: '/cteProc/CTe/infCte/exped/CNPJ',
            name: 'CNPJ',
            formGroup: 4,
            size: 12,
        },
        //#endregion Expedidor

        //#region Recebedor
        {
            key: '/cteProc/CTe/infCte/receb/xNome',
            name: 'Razão social',
            formGroup: 5,
            size: 8,
        },
        {
            key: '/cteProc/CTe/infCte/receb/enderReceb/UF',
            name: 'UF Recebedor',
            formGroup: 5,
            size: 4,
        },
        {
            key: '/cteProc/CTe/infCte/receb/CNPJ',
            name: 'CNPJ',
            formGroup: 5,
            size: 12,
        },
        //#endregion Recebedor

        //#region Destinatário
        {
            key: '/cteProc/CTe/infCte/dest/xNome',
            name: 'Razão social',
            formGroup: 6,
            size: 8,
        },
        {
            key: '/cteProc/CTe/infCte/dest/enderDest/UF',
            name: 'UF Destinatário',
            formGroup: 6,
            size: 4,
        },
        {
            key: '/cteProc/CTe/infCte/dest/CNPJ',
            name: 'CNPJ',
            formGroup: 6,
            size: 12,
        },
        //#endregion Destinatário

        //#region Valores/Impostos
        {
            key: '/cteProc/CTe/infCte/vPrest/vTPrest',
            name: 'Valor Total da Prestação do Serviço',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/vPrest/vRec',
            name: 'Valor a Receber',
            formGroup: 8,
            size: 6,
        },
        //#endregion Valores/Impostos
    ];

    //#region Tomador
    let toma3Template = (xNome, CNPJ, uf, value) => [
        {
            key: '/cteProc/CTe/infCte/ide/toma3/toma',
            name: 'Toma',
            formGroup: 7,
            size: 12,
            addValueInField: value,
        },
        {
            key: 'toma3/xNome',
            name: 'Razão social',
            formGroup: 7,
            size: 8,
            valueKey: xNome,
        },
        {
            key: 'toma3/UF',
            name: 'UF Tomador',
            formGroup: 7,
            size: 4,
            valueKey: uf,
        },
        {
            key: 'toma3/CNPJ',
            name: 'CNPJ',
            formGroup: 7,
            size: 12,
            valueKey: CNPJ,
        },
    ];

    let toma4Template = [
        {
            key: '/cteProc/CTe/infCte/ide/toma4/toma',
            name: 'Toma',
            formGroup: 7,
            size: 12,
            addValueInField: ' - Outros',
        },
        {
            key: '/cteProc/CTe/infCte/ide/toma4/xNome',
            name: 'Razão Social',
            formGroup: 7,
            size: 8,
        },
        {
            key: '/cteProc/CTe/infCte/ide/toma4/enderToma/UF',
            name: 'UF Tomador',
            formGroup: 7,
            size: 4,
        },
        {
            key: '/cteProc/CTe/infCte/ide/toma4/CNPJ',
            name: 'CNPJ',
            formGroup: 7,
            size: 12,
        },
    ];

    let tomaCTeOsTemplate = [
        {
            key: '/cteOSProc/CTeOS/infCte/toma',
            name: 'Toma',
            formGroup: 7,
            size: 12,
            valueKey: '/cteProc/CTe/infCte/ide/toma3/toma',
        },
        {
            key: '/cteOSProc/CTeOS/infCte/toma/xNome',
            name: 'Razão social',
            formGroup: 7,
            size: 8,
        },
        {
            key: '/cteOSProc/CTeOS/infCte/toma/enderToma/UF',
            name: 'UF Tomador',
            formGroup: 7,
            size: 4,
        },
        {
            key: '/cteOSProc/CTeOS/infCte/toma/CNPJ',
            name: 'CNPJ',
            formGroup: 7,
            size: 12,
        },
    ];

    switch (String(TomaTag).trim()) {
        case '0':
            toma3Template = toma3Template(
                '/cteProc/CTe/infCte/rem/xNome',
                '/cteProc/CTe/infCte/rem/CNPJ',
                '/cteProc/CTe/infCte/rem/enderReme/UF',
                ' - Remetente',
            );
            break;
        case '1':
            toma3Template = toma3Template(
                '/cteProc/CTe/infCte/exped/xNome',
                '/cteProc/CTe/infCte/exped/CNPJ',
                '/cteProc/CTe/infCte/exped/enderExped/UF',
                ' - Expedidor',
            );
            break;
        case '2':
            toma3Template = toma3Template(
                '/cteProc/CTe/infCte/receb/xNome',
                '/cteProc/CTe/infCte/receb/CNPJ',
                '/cteProc/CTe/infCte/receb/enderReceb/UF',
                ' - Recebedor',
            );
            break;
        case '3':
            toma3Template = toma3Template(
                '/cteProc/CTe/infCte/dest/xNome',
                '/cteProc/CTe/infCte/dest/CNPJ',
                '/cteProc/CTe/infCte/dest/enderDest/UF',
                ' - Destinatário',
            );
            break;
        default:
            template = template.concat(toma4Template);
            break;
    }

    template = template.concat(toma3Template);

    if (TomaType === 'CTeOS') {
        template = template.concat(tomaCTeOsTemplate);
    }

    //#endregion Tomador

    // #region ICMSTemplates
    let ICMSTemplate00 = [
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS00/CST',
            name: 'Código da Situação Tributária (CST)',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS00/vBC',
            name: 'Valor Base ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS00/pICMS',
            name: 'Aliquota ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS00/vICMS',
            name: 'Valor do ICMS',
            formGroup: 8,
            size: 6,
        },
    ];

    let ICMSTemplate20 = [
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS20/CST',
            name: 'Código da Situação Tributária (CST)',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS20/vBC',
            name: 'Valor Base ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS20/pICMS',
            name: 'Aliquota ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS20/vICMS',
            name: 'Valor do ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS20/pRedBC',
            name: 'Percentual de redução da BC',
            formGroup: 8,
            size: 6,
        },
    ];

    let ICMSTemplate45 = [
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS45/CST',
            name: 'Código da Situação Tributária (CST)',
            formGroup: 8,
            size: 6,
        },
    ];

    let ICMSTemplate60 = [
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS60/CST',
            name: 'Código da Situação Tributária (CST)',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS60/vBCSTRet',
            name: 'Valor da BC ICMS Retido',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS60/vICMSSTRet',
            name: 'Valor do ICMS Retido',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS60/pICMSSTRet',
            name: 'Aliquota ICMS Retido',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS60/vCred',
            name: 'Valor do crédito Presumido',
            formGroup: 8,
            size: 6,
        },
    ];

    let ICMSTemplate90 = [
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS90/CST',
            name: 'Código da Situação Tributária (CST)',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS90/vBC',
            name: 'Valor Base ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS90/pICMS',
            name: 'Aliquota ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS90/vICMS',
            name: 'Valor do ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS90/pRedBC',
            name: 'Percentual de redução da BC',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMS90/vCred',
            name: 'Valor do crédito Presumido',
            formGroup: 8,
            size: 6,
        },
    ];

    let ICMSTemplateOutraUF = [
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMSOutraUF/CST',
            name: 'Código da Situação Tributária (CST)',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMSOutraUF/vBCOutraUF',
            name: 'Valor Base ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMSOutraUF/pICMSOutraUF',
            name: 'Aliquota ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMSOutraUF/vICMSOutraUF',
            name: 'Valor do ICMS',
            formGroup: 8,
            size: 6,
        },
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMSOutraUF/pRedBCOutraUF',
            name: 'Percentual de redução da BC',
            formGroup: 8,
            size: 6,
        },
    ];

    let ICMSTemplateSN = [
        {
            key: '/cteProc/CTe/infCte/imp/ICMS/ICMSSN/CST',
            name: 'Código da Situação Tributária (CST)',
            formGroup: 8,
            size: 6,
        },
    ];

    switch (ICMSType?.toUpperCase()) {
        case 'ICMS00':
            template = template.concat(ICMSTemplate00);
            break;

        case 'ICMS20':
            template = template.concat(ICMSTemplate20);
            break;

        case 'ICMS45':
            template = template.concat(ICMSTemplate45);
            break;

        case 'ICMS60':
            template = template.concat(ICMSTemplate60);
            break;

        case 'ICMS90':
            template = template.concat(ICMSTemplate90);
            break;

        case 'ICMSOUTRAUF':
            template = template.concat(ICMSTemplateOutraUF);
            break;

        case 'ICMSSN':
            template = template.concat(ICMSTemplateSN);
            break;

        default:
            template = template.concat(
                ICMSTemplate00,
                ICMSTemplate20,
                ICMSTemplate45,
                ICMSTemplate60,
                ICMSTemplate90,
                ICMSTemplateOutraUF,
                ICMSTemplateSN,
            );
            break;
    }
    // #endregion ICMSTemplates

    if (TomaType === 'CTeOS') {
        template = template.map(field => {
            const fKey = field?.key
                ?.replace('/cteProc/CTe', '/cteOSProc/CTeOS')
                .replace('/cteProc/protCTe', '/cteOSProc/protCTe');
            return { ...field, key: fKey };
        });
    }

    return template;
};

export const cteFormGroupTemplate = intl => {
    return [
        {
            formGroup: 1,
            headerTitle: intl.formatMessage(messages.generalTitle),
            type: formGroupTypes.GENERAL,
        },
        {
            formGroup: 2,
            headerTitle: intl.formatMessage(messages.issuer),
        },
        {
            formGroup: 3,
            headerTitle: intl.formatMessage(messages.sender),
        },
        {
            formGroup: 4,
            headerTitle: intl.formatMessage(messages.dispatcher),
        },
        {
            formGroup: 5,
            headerTitle: intl.formatMessage(messages.receiver),
        },
        {
            formGroup: 6,
            headerTitle: intl.formatMessage(messages.recipient),
        },
        {
            formGroup: 7,
            headerTitle: intl.formatMessage(messages.borrowerTitle),
        },
        {
            formGroup: 8,
            headerTitle: intl.formatMessage(messages.taxesTitle),
        },
        {
            formGroup: 9,
            headerTitle: intl.formatMessage(messages.othersTitle),
            type: formGroupTypes.OTHERS,
        },
    ];
};
