import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const ImportTaxForm = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const nfeII = nfeItem?.imposto?.ii;

    return (
        <>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vBC)}
                            type='text'
                            name='vBC'
                            id='vBC'
                            value={nfeII?.vBC || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.VDespAdu)}
                            type='text'
                            name='vDespAdu'
                            id='vDespAdu'
                            value={nfeII?.vDespAdu || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.VII)}
                            type='text'
                            name='vII'
                            id='vII'
                            value={nfeII?.vII || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.VIOF)}
                            type='text'
                            name='vIOF'
                            id='vIOF'
                            value={nfeII?.vIOF || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(ImportTaxForm);
