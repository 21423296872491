import React, { useState } from 'react';

import * as actions from '../../../store/actions/index';

import { Container, Row, Col } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import { connect } from 'react-redux';
import messages from '../DocumentListPage.intl';

import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';

import Modal from '../../../components/Modal/Modal';
import Loading from '../../../components/Loading/Loading';

const ModalReportXml = ({
    intl,
    /* reducer */
    onInitDocumentsXmlReportZip,
    loadingListDownloadFile,
}) => {
    const [showXmlReportPicker, setShowXmlReportPicker] = useState(false);

    const handleXmlReportPickerSubmit = (e, values) => {
        var beginData = new Date(values.beginDate);
        var endDate = new Date(values.endDate);
        var diff = endDate.getTime() - beginData.getTime();
        var diffDays = diff / (1000 * 3600 * 24);

        if (diffDays > 31) {
            toast.error(intl.formatMessage(messages.invalidinterval));
            return;
        }

        onInitDocumentsXmlReportZip(values.beginDate, values.endDate);
    };

    return (
        <Modal
            title={intl.formatMessage(messages.selectDate)}
            isOpen={showXmlReportPicker}
            handleToggle={() => setShowXmlReportPicker(!showXmlReportPicker)}
            size={'lg'}
        >
            <Loading loading={loadingListDownloadFile} />
            <AvForm onValidSubmit={handleXmlReportPickerSubmit}>
                <Container fluid>
                    <Row>
                        <Col xs={6}>
                            <AvField
                                name='beginDate'
                                label={intl.formatMessage(messages.from)}
                                type='date'
                                errorMessage=' '
                                validate={{
                                    required: { value: true },
                                }}
                            />
                        </Col>
                        <Col xs={6}>
                            <AvField
                                name='endDate'
                                label={intl.formatMessage(messages.to)}
                                type='date'
                                errorMessage=' '
                                validate={{
                                    required: { value: true },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12}>
                            <button className='btn-submit'>
                                {intl.formatMessage(messages.confirmButton)}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </Modal>
    );
};
const mapStateToProps = state => {
    return {
        loadingListDownloadFile: state.documentReducer.loadingListDownloadFile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onInitDocumentsXmlReportZip: (beginDate, endDate) =>
            dispatch(actions.initDocumentsXmlReportZip(beginDate, endDate)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalReportXml),
);
