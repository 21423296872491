import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import Header from '../../components/Header/Header';
import { AuthContext } from '../../context/AuthContext';
import * as actions from '../../store/actions/index';
import { filterPropertyTypes } from '../../utils/enums';
import ModalUploadCertificate from '../ModalUploadCertificate/ModalUploadCertificate';
import messages from './CompaniesTable.intl';

function CompaniesTable(props) {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const params = useParams();

    const [filters, setFilters] = useState([]);
    const [query, setQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState();
    const [showModalUpload, setShowModalUpload] = useState(false);

    useEffect(() => {
        if (Boolean(query)) {
            reloadCompaniesList();
        }
    }, [query]);

    function reloadCompaniesList() {
        const contractorId = params.id;

        props.OnInitCompanies(contractorId, query);
        props.OnInitCompaniesCount(contractorId, query);
    }

    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => {
                setSelectedRow(rowInfo.original);
                const nodeName = e.target.nodeName;
                if (
                    nodeName === 'BUTTON' ||
                    nodeName === 'path' ||
                    nodeName === 'svg'
                ) {
                    return;
                }

                history.push(`/contractors/${params.id}?index=3`);
                history.push(
                    `/contractors/${rowInfo.original.contractorId}/companies/${
                        rowInfo.original.companyId
                    }`,
                );
            },
        };
    }

    function handleCertificateModal() {
        setShowModalUpload(!showModalUpload);
    }

    async function handleImportCertificateSubmit(event, values) {
        const { companyId } = selectedRow;
        const contractorId = params.id;

        if (!values.file) {
            toast.error(intl.formatMessage(messages.choiceFile));
            return;
        }
        await props.OnImportCertificate(companyId, values, contractorId, query);
        handleCertificateModal();
    }

    function handleNewClick() {
        history.push(`/contractors/${params.id}?index=3`);
        history.push(`/contractors/${params.id}/companies/new`);
    }

    function handleRefreshButtonClick() {
        reloadCompaniesList();
    }

    function handleFiltersChange(filters) {
        setFilters(filters);
    }

    function handleEvTableStateChange(query) {
        setQuery(query);
    }

    const { intl, loadingList, companies, companiesCount } = props;
    const newCompanyButtonEnabled = authContext.hasClaim('company-create');

    const properties = [
        {
            key: 'name',
            value: intl.formatMessage(messages.name),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'cnpjFilial',
            value: intl.formatMessage(messages.CNPJCompany),
            type: filterPropertyTypes.TEXT,
            _map: value => {
                const newValue = value.replace(/[^a-zA-Z0-9]/g, '')
                return newValue;
            },
        },
        {
            key: 'cnpjMatriz',
            value: intl.formatMessage(messages.CNPJMatriz),
            type: filterPropertyTypes.TEXT,
            _map: value => {
                const newValue = value.replace(/[^a-zA-Z0-9]/g, '')
                return newValue;
            },
        },
        {
            key: 'state',
            value: intl.formatMessage(messages.state),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'city',
            value: intl.formatMessage(messages.city),
            type: filterPropertyTypes.TEXT,
        },
    ];

    const columns = useMemo(() => {
        return [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'name',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                maxWidth:
                    150 +
                    calculateColumnWidth(
                        companies,
                        'name',
                        intl.formatMessage(messages.name),
                    ),
                minWidth:
                    40 +
                    calculateColumnWidth(
                        companies,
                        'name',
                        intl.formatMessage(messages.name),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.CNPJCompany)}</span>
                    </div>
                ),
                accessor: 'cnpjFilial',
                style: {
                    fontSize: 'small',
                },
                Cell: ({ value }) => value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,'$1.$2.$3/$4-$5'),

                maxWidth:
                    80 +
                    calculateColumnWidth(
                        companies,
                        'cnpjFilial',
                        intl.formatMessage(messages.CNPJCompany),
                    ),
                minWidth:
                    70 +
                    calculateColumnWidth(
                        companies,
                        'cnpjFilial',
                        intl.formatMessage(messages.CNPJCompany),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.CNPJMatriz)}</span>
                    </div>
                ),
                accessor: 'cnpjMatriz',
                style: {
                    fontSize: 'small',
                },
                Cell: ({ value }) => value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,'$1.$2.$3/$4-$5'),
                maxWidth:
                    80 +
                    calculateColumnWidth(
                        companies,
                        'cnpjMatriz',
                        intl.formatMessage(messages.CNPJMatriz),
                    ),
                minWidth:
                    70 +
                    calculateColumnWidth(
                        companies,
                        'cnpjMatriz',
                        intl.formatMessage(messages.CNPJMatriz),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.state)}</span>
                    </div>
                ),
                accessor: 'state',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    40 +
                    calculateColumnWidth(
                        companies,
                        'state',
                        intl.formatMessage(messages.state),
                    ),
                minWidth:
                    30 +
                    calculateColumnWidth(
                        companies,
                        'state',
                        intl.formatMessage(messages.state),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.city)}</span>
                    </div>
                ),
                accessor: 'city',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    130 +
                    calculateColumnWidth(
                        companies,
                        'city',
                        intl.formatMessage(messages.city),
                    ),
                minWidth:
                    40 +
                    calculateColumnWidth(
                        companies,
                        'city',
                        intl.formatMessage(messages.city),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.certificateStatus)}
                        </span>
                    </div>
                ),
                accessor: 'expirationDate',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    let classStatus = 'status-certificate ';
                    let message = '';
                    const today = new Date();

                    const dateTime = new Date(row.value);
                    const dateTimePending = new Date(row.value);
                    const dateNow = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate(),
                    );
                    dateTimePending.setDate(dateTimePending.getDate() - 30);

                    if (date !== 'Invalid Date') {
                        if (dateTime.getTime() <= dateNow.getTime()) {
                            classStatus += 'status-error';
                            message = 'Vencido';
                        } else if (
                            dateTimePending.getTime() <= dateNow.getTime()
                        ) {
                            classStatus += 'status-expiring';
                            message = 'Próximo do vencimento';
                        } else {
                            classStatus += 'status-success';
                            message = 'Válido';
                        }
                    }

                    const date = `Validade: ${dateTime.getDate()}/${dateTime.getMonth() +
                        1}/${dateTime.getFullYear()}`;
                    return (
                        <div className={classStatus}>
                            {dateTime.toLocaleString(navigator.language) !==
                            'Invalid Date' ? (
                                <>
                                    <p className='message-certificate'>
                                        {message}
                                    </p>
                                    <p>{date}</p>
                                </>
                            ) : (
                                <p />
                            )}
                        </div>
                    );
                },
                maxWidth:
                    240 +
                    calculateColumnWidth(
                        companies,
                        'expirationDate',
                        intl.formatMessage(messages.certificateStatus),
                    ),
                minWidth:
                    200 +
                    calculateColumnWidth(
                        companies,
                        'expirationDate',
                        intl.formatMessage(messages.certificateStatus),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.importCertificate)}
                        </span>
                    </div>
                ),
                Cell: row => {
                    return (
                        <button
                            style={{
                                width: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={e => {
                                handleCertificateModal(e, row.original);
                            }}
                        >
                            <FontAwesomeIcon icon='upload' />
                        </button>
                    );
                },
                sortable: false,
                maxWidth: 200,
                minWidth: 200,
                style: {
                    fontSize: 'small',
                },
            },
        ];
    }, [companies]);

    return (
        <div>
            <header className='table-screen'>
                <section className='title'>
                    <Header
                        title={intl.formatMessage(messages.companyTitle)}
                        subtitle={
                            props.companiesCount +
                            intl.formatMessage(messages.subtitleCompany)
                        }
                    />
                </section>
                <section className='btns-topo'>
                    <EvtFilter
                        properties={properties}
                        handleFiltersChange={handleFiltersChange}
                        loading={loadingList}
                        tabInside
                    />
                    <button
                        className={`new-btn small ${
                            props.loadingList ? 'loading' : ''
                        }`}
                        onClick={handleRefreshButtonClick}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                    {newCompanyButtonEnabled && (
                        <button className='new-btn' onClick={handleNewClick}>
                            {intl.formatMessage(messages.newCompany)}
                        </button>
                    )}
                </section>
            </header>
            <section className='content-middle'>
                <EvtTable
                    fetchPage
                    filters={filters}
                    columns={columns}
                    data={companies}
                    length={companiesCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChange}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingList}
                    tabInside
                />
            </section>
            <ModalUploadCertificate
                showModal={showModalUpload}
                handleSubmit={handleImportCertificateSubmit}
                handleShowModal={handleCertificateModal}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        companies: state.companyReducer.companies,
        companiesCount: state.companyReducer.companiesCount || 0,
        loadingList: state.companyReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnInitCompanies: (contractorId, query) =>
            dispatch(actions.initCompanies(contractorId, query)),
        OnInitCompaniesCount: (contractorId, query) =>
            dispatch(actions.initCompaniesCount(contractorId, query)),
        OnImportCertificate: (companyId, payload, contractorId, filter) =>
            dispatch(
                actions.importCertificate(
                    companyId,
                    payload,
                    contractorId,
                    filter,
                ),
            ),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(CompaniesTable),
);
