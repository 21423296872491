import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useState } from 'react';

import { injectIntl } from 'react-intl';
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import messages from '../MapDetailsPage.intl';

function MapOptions(props) {
    const { intl, handleFormSubmit, mapFilterDelimeter } = props;

    return (
        <Card style={{ marginBottom: '1rem', border: 0 }}>
            <CardBody>
                <AvForm onValidSubmit={handleFormSubmit}>
                    <Row>
                        <Col md={6}>
                            <FormGroup
                                check
                                className='required-field'
                                style={{
                                    height: 70,
                                    top: -20,
                                }}
                            >
                                <AvField
                                    label={intl.formatMessage(
                                        messages.isRepeatingMap,
                                    )}
                                    type='checkbox'
                                    name='isRepeatingMap'
                                    id='isRepeatingMap'
                                    value={mapFilterDelimeter?.isRepeatingMap}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.start)}
                                    type='text'
                                    name='start.term'
                                    id='term'
                                    value={
                                        mapFilterDelimeter?.start?.term || ''
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.orientation,
                                    )}
                                    type='select'
                                    name='start.orientation'
                                    id='orientation'
                                    value={
                                        mapFilterDelimeter?.start
                                            ?.orientation || '0'
                                    }
                                >
                                    <option value='0'>Top</option>
                                    <option value='1'>Bottom</option>
                                </AvField>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.pixelAdjust,
                                    )}
                                    type='number'
                                    name='start.pixelAdjust'
                                    id='pixelAdjust'
                                    value={
                                        mapFilterDelimeter?.start
                                            ?.pixelAdjust || '0'
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.end)}
                                    type='text'
                                    name='end.term'
                                    id='term'
                                    value={mapFilterDelimeter?.end?.term || ''}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.orientation,
                                    )}
                                    type='select'
                                    name='end.orientation'
                                    id='orientation'
                                    value={
                                        mapFilterDelimeter?.end?.orientation ||
                                        '0'
                                    }
                                >
                                    <option value='0'>Top</option>
                                    <option value='1'>Bottom</option>
                                </AvField>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.pixelAdjust,
                                    )}
                                    type='number'
                                    name='end.pixelAdjust'
                                    id='pixelAdjust'
                                    value={
                                        mapFilterDelimeter?.end?.pixelAdjust ||
                                        '0'
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <button type='submit' className='btn-submit'>
                                {intl.formatMessage(messages.submitButton)}
                            </button>
                        </Col>
                    </Row>
                </AvForm>
            </CardBody>
        </Card>
    );
}

export default injectIntl(MapOptions);
