import React, { useEffect, useState, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';
import messages from './NfeDetailsPage.intl';

import DetailsHeader from '../../components/DetailsHeader/DetailsHeader';
import Loading from '../../components/Loading/Loading';
import NfeDetailsForm from '../../components/NfeDetailsForm/NfeDetailsForm';
import FileUploadForm from '../../components/FileUploadForm/FileUploadForm';
import AssociatedDocumentsModal from '../../components/AssociatedDocumentsModal/AssociatedDocumentsModal';
import ModalNfeItems from './components/ModalNfeItems';
import ModalNfeItemDetails from '../../components/ModalNfeItemDetails/ModalNfeItemDetails';
import { useHistory, withRouter } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import { DocumentTypes } from '../../utils/enums';

function NfeDetailsPage(props) {
    const {
        intl,
        nfeDocument,
        documentHistory,
        loadingNfeDetails,
        associatedDocumentsCount,
        nfeItemsCount,
        nfeOrdersCount,
        validationErrors,
        maps,
    } = props;
    const context = useContext(AuthContext);
    const history = useHistory();

    const nfeDocumentId = props?.match?.params?.id;

    const currentStep = nfeDocument?.currentStep;
    const showNfeDetails =
        nfeDocumentId !== 'new' &&
        !(currentStep?.order === 1 && currentStep?.status === 'pending');

    const [showAssociatedDocModal, setShowAssociatedDocModal] = useState(false);
    const [showNfeItemsModal, setShowNfeItemsModal] = useState(false);

    const [showNfeItemDetailsModal, setShowNfeItemDetailsModal] = useState(
        false,
    );
    const [nfeItemDetails, setNfeItemDetails] = useState('');

    useEffect(() => {
        const queryFilter = '?sort=-createdAt&skip=0&take=10&page=0';

        if (nfeDocumentId !== 'new') {
            props.onInitDocumentsNfeDetails(nfeDocumentId);
            props.onInitdocumentHistory(nfeDocumentId);
            props.onInitNfeItemsCount(nfeDocumentId, queryFilter);
            props.onInitNfeOrdersCount(nfeDocumentId, queryFilter);
        }

        props.onCleanValidationErrors();
    }, []);

    useEffect(() => {
        if (!!nfeDocument && nfeDocument?.documentType !== 'NFE') {
            history.push(`/documents/${nfeDocument.documentId}`);
        }
    }, [nfeDocument]);

    useEffect(() => () => props.onCleanNfeDocument(), []);

    const handleFormSubmit = (event, values, isDraft) => {
        if (loadingNfeDetails) return;

        const formFields = {
            formFields: values,
        };

        props.onUpdateNfeDocument(
            nfeDocument?.documentId,
            formFields,
            formFields.formFields?.orderId || nfeDocument?.orderId,
            isDraft,
            nfeDocument?.isAttachment,
            'NFe',
        );
    };

    const handleSubmitCancel = () => {
        const currentStep = nfeDocument.currentStep || {};

        const values = {
            steps: [
                {
                    order: currentStep.order,
                    status: 'error',
                    text: 'Cancelado',
                },
            ],
        };

        props.onNfeCancelDocument(nfeDocumentId, values);
    };

    const handleUploadFile = (file, orderItemsList) => {
        const { supplierId, origin, parentDocumentId, isRawFile } =
            props.location.state || {};

        const payload = {
            file,
            supplierId,
            origin,
            uploaderEmail: context.emailAddress,
            parentDocumentId,
        };

        if (isRawFile) {
            props.onInitDocumentNfeCreate({ ...payload, isRawFile });
            return;
        } else {
            props.onInitDocumentNfeCreate(payload);
        }
    };
    const handleToggleAssociatedDocModal = () =>
        setShowAssociatedDocModal(!showAssociatedDocModal);

    const handleToggleItemsModal = () =>
        setShowNfeItemsModal(!showNfeItemsModal);

    const handleRedirectToOrders = () => {
        history.push(`/nfeDocuments/${nfeDocumentId}/orders`);
    };

    const handleOpenNfeItemsDetails = nfeItem => {
        setNfeItemDetails(nfeItem);
        handleToggleNfeItemDetailsModal();
    };

    const handleToggleNfeItemDetailsModal = () =>
        setShowNfeItemDetailsModal(!showNfeItemDetailsModal);

    return (
        <Container fluid>
            <DetailsHeader
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/nfeDocuments',
                        name: intl.formatMessage(messages.title),
                        active: false,
                    },
                    {
                        path: '/nfe/:id',
                        name: intl.formatMessage(messages.titleNfe, {
                            entity: nfeDocumentId,
                        }),
                        active: true,
                    },
                ]}
                headerTitle={intl.formatMessage(messages.titleNfe)}
                headerClassName='row'
            />
            <Loading loading={loadingNfeDetails} />
            {showNfeDetails ? (
                <NfeDetailsForm
                    handleFormSubmit={handleFormSubmit}
                    handleSubmitCancel={handleSubmitCancel}
                    loading={loadingNfeDetails}
                    associatedDocumentsCount={associatedDocumentsCount}
                    nfeDocument={nfeDocument}
                    documentHistory={documentHistory}
                    validationErrors={validationErrors}
                    handleToggleAssociatedDocModal={
                        handleToggleAssociatedDocModal
                    }
                    nfeItemsCount={nfeItemsCount}
                    nfeOrdersCount={nfeOrdersCount}
                    handleToggleItemsModal={handleToggleItemsModal}
                    handleRedirectToOrders={handleRedirectToOrders}
                />
            ) : (
                <FileUploadForm
                    loading={loadingNfeDetails}
                    handleUploadFile={handleUploadFile}
                    selectedDocument={nfeDocument}
                    isDocument={false}
                    documentType={'NFE'}
                />
            )}
            <AssociatedDocumentsModal
                showAssociatedDocModal={showAssociatedDocModal}
                setShowAssociatedDocModal={setShowAssociatedDocModal}
                handleToggleAssociatedDocModal={handleToggleAssociatedDocModal}
                loadingModal={loadingNfeDetails}
                selectedDocument={nfeDocument}
                pushUrl={`/nfeDocuments/${nfeDocumentId}`}
                queryId={nfeDocumentId}
                docType={DocumentTypes.NFE}
            />
            <ModalNfeItems
                documentId={nfeDocumentId}
                isOpen={showNfeItemsModal}
                handleToggle={handleToggleItemsModal}
                handleOpenNfeItemsDetails={handleOpenNfeItemsDetails}
            />
            <ModalNfeItemDetails
                nfeItemDetails={nfeItemDetails}
                isOpen={showNfeItemDetailsModal}
                handleToggle={handleToggleNfeItemDetailsModal}
            />
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        nfeDocument: state.NFeReducer.nfeDocument || null,
        loadingNfeDetails: state.NFeReducer.loadingNfeDetails,
        nfeItemsCount: state.NFeReducer.nfeItemsCount || 0,
        nfeOrdersCount: state.NFeReducer.nfeOrdersCount || 0,

        //documents
        associatedDocumentsCount:
            state.documentReducer.associatedDocumentsCount || 0,
        validationErrors: state.documentReducer.validationErrors,
        documentHistory: state.documentReducer.documentHistory || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitDocumentNfeCreate: payload =>
            dispatch(actions.initDocumentNfeCreate(payload)),
        onInitDocumentsNfeDetails: (contractorId, file) =>
            dispatch(actions.initDocumentsNfeDetails(contractorId, file)),
        onUpdateNfeDocument: (
            documentId,
            values,
            orderId,
            isDraft,
            isAttachment,
            documentType,
        ) =>
            dispatch(
                actions.updateDocumentFormFields(
                    documentId,
                    values,
                    orderId,
                    isDraft,
                    isAttachment,
                    documentType,
                ),
            ),
        onInitAssociatedDocumentsCount: documentId =>
            dispatch(actions.initAssociatedDocumentsCount(documentId)),
        onGetParentChildValues: documentId =>
            dispatch(actions.getParentChildValues(documentId)),
        onInitNfeItemsCount: (documentId, filter) =>
            dispatch(actions.initNfeItemsCount(documentId, filter)),
        onInitNfeOrdersCount: (documentId, filter) =>
            dispatch(actions.initNfeOrdersCount(documentId, filter)),
        onCleanValidationErrors: () =>
            dispatch(actions.cleanValidationErrors()),

        onNfeCancelDocument: (documentId, values) =>
            dispatch(actions.cancelDocument(documentId, values)),

        onCleanNfeDocument: () => dispatch(actions.cleanNfeDocument()),
        onInitdocumentHistory: documentId =>
            dispatch(actions.initdocumentHistory(documentId)),
    };
};

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(NfeDetailsPage),
    ),
);
