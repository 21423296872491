import React, { useState, useEffect, useContext } from 'react';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './CteDetailsPage.intl';

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router';

import DetailsHeader from '../../components/DetailsHeader/DetailsHeader';
import FileUploadForm from '../../components/FileUploadForm/FileUploadForm';
import Loading from '../../components/Loading/Loading';
import CteDetailsForm from '../../components/CteDetailsForm/CteDetailsForm';
import AssociatedDocumentsModal from '../../components/AssociatedDocumentsModal/AssociatedDocumentsModal';
import ModalCteOrders from './components/ModalCteOrders';
import { AuthContext } from '../../context/AuthContext';
import { DocumentTypes } from '../../utils/enums';

function CteDetailsPage(props) {
    const {
        intl,
        cteDocument,
        loadingCteDetails,
        cteOrdersCount,
        associatedDocumentsCount,
        documentHistory,
        validationErrors,
    } = props;
    const cteDocumentId = props?.match?.params?.id;
    const context = useContext(AuthContext);
    const history = useHistory();

    const [showAssociatedDocModal, setShowAssociatedDocModal] = useState(false);
    const [showOrdersModal, setShowOrdersModal] = useState(false);

    const currentStep = cteDocument?.currentStep;
    const showCteDetails =
        cteDocumentId !== 'new' &&
        !(currentStep?.order === 1 && currentStep?.status === 'pending');

    useEffect(() => {
        if (cteDocumentId !== 'new') {
            props.onInitCteDetails(cteDocumentId);
            props.onInitdocumentHistory(cteDocumentId);
        }
        props.onCleanValidationErrors();
    }, []);

    useEffect(() => () => props.onCleanCteDocument(), []);

    useEffect(() => {
        if (!!cteDocument && cteDocument?.documentType !== 'CTE') {
            history.push(`/documents/${cteDocument.documentId}`);
        }
    }, [cteDocument]);

    const handleUploadFile = (file, orderItemsList) => {
        const { supplierId, origin, parentDocumentId, isRawFile } =
            props.location.state || {};

        const payload = {
            file,
            supplierId,
            origin,
            uploaderEmail: context.emailAddress,
            parentDocumentId,
        };

        if (isRawFile) {
            props.onInitDocumentCteCreate({ ...payload, isRawFile });
            return;
        } else {
            props.onInitDocumentCteCreate(payload);
        }
    };

    // #region Functions to CteDetailsForm
    const handleFormSubmit = (e, values, isDraft) => {
        if (loadingCteDetails) return;
        const formFields = {
            formFields: values,
        };

        props.onUpdateCteDocument(
            cteDocument?.documentId,
            formFields,
            formFields.formFields?.orderId || cteDocument?.orderId,
            isDraft,
            cteDocument?.isAttachment,
            'CTe',
        );
    };

    const handleToggleAssociatedDocModal = () =>
        setShowAssociatedDocModal(!showAssociatedDocModal);

    const handleRedirectToOrders = () => {
        history.push(`/cteDocuments/${cteDocumentId}/orders`);
    };

    // #endregion Functions to CteDetailsForm

    return (
        <Container fluid>
            <DetailsHeader
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/cteDocuments',
                        name: intl.formatMessage(messages.invoicesTitle),
                        active: false,
                    },
                    {
                        path: '/cteDocuments/:id',
                        name: intl.formatMessage(messages.titleCte, {
                            entity: cteDocumentId,
                        }),
                        active: true,
                    },
                ]}
                headerTitle={intl.formatMessage(messages.titleCte)}
                headerClassName='row'
            />
            <Loading loading={loadingCteDetails} />
            {showCteDetails ? (
                <CteDetailsForm
                    handleFormSubmit={handleFormSubmit}
                    loading={loadingCteDetails}
                    associatedDocumentsCount={associatedDocumentsCount}
                    cteDocument={cteDocument}
                    documentHistory={documentHistory}
                    validationErrors={validationErrors}
                    handleToggleAssociatedDocModal={
                        handleToggleAssociatedDocModal
                    }
                    cteOrdersCount={cteOrdersCount}
                    handleRedirectToOrders={handleRedirectToOrders}
                />
            ) : (
                <FileUploadForm
                    loading={loadingCteDetails}
                    handleUploadFile={handleUploadFile}
                    selectedDocument={cteDocument}
                    isDocument={false}
                    documentType={'CTE'}
                />
            )}
            <AssociatedDocumentsModal
                showAssociatedDocModal={showAssociatedDocModal}
                setShowAssociatedDocModal={setShowAssociatedDocModal}
                handleToggleAssociatedDocModal={handleToggleAssociatedDocModal}
                loadingModal={loadingCteDetails}
                selectedDocument={cteDocument}
                pushUrl={`/cteDocuments/${cteDocumentId}`}
                queryId={cteDocumentId}
                docType={DocumentTypes.CTE}
            />
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        cteDocument: state.cteReducer.cteDocument || null,
        loadingCteDetails: state.cteReducer.loadingCteDetails || false,
        cteOrdersCount: state.cteReducer.cteOrdersCount || 0,

        //documents
        associatedDocumentsCount:
            state.documentReducer.associatedDocumentsCount || 0,
        validationErrors: state.documentReducer.validationErrors,
        documentHistory: state.documentReducer.documentHistory || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitCteDetails: cteDocumentId =>
            dispatch(actions.initDocumentsCteDetails(cteDocumentId)),
        onCleanValidationErrors: () =>
            dispatch(actions.cleanValidationErrors()),
        onInitDocumentCteCreate: payload =>
            dispatch(actions.initDocumentCteCreate(payload)),
        onUpdateCteDocument: (
            cteDocumentId,
            values,
            orderId,
            isDraft,
            isAttachment,
            documentType,
        ) =>
            dispatch(
                actions.updateDocumentFormFields(
                    cteDocumentId,
                    values,
                    orderId,
                    isDraft,
                    isAttachment,
                    documentType,
                ),
            ),
        onCleanCteDocument: () => dispatch(actions.cleanCteDocument()),
        onInitdocumentHistory: documentId =>
            dispatch(actions.initdocumentHistory(documentId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(CteDetailsPage),
);
