import React from "react";
import { injectIntl } from "react-intl";

import { FormGroup, Col } from "reactstrap";

import { AvField } from "availity-reactstrap-validation";

import messages from "../../MapItemDetailsPage.intl";

const removeMapActionItemForm = props => {
  const { intl } = props;

  return (
    <React.Fragment>
      <Col xs={12}>
        <FormGroup>
          <AvField
            label={intl.formatMessage(messages.text)}
            type="text"
            name="parameters.Value"
            value={props.parameters.Value}
            errorMessage=" "
            required
          />
        </FormGroup>
      </Col>
    </React.Fragment>
  );
};

export default injectIntl(removeMapActionItemForm);
