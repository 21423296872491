import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { Container, Row, Col } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import messages from '../CompaniesTable/CompaniesTable.intl';
import Modal from '../Modal/Modal';
import Uploader from '../Uploader/Uploader';

const ModalUploadCertificate = ({
    intl,
    /* reducer */
    showModal,
    /* functions */
    handleShowModal,
    handleSubmit,
}) => {
    const [files, setFiles] = useState([]);
    const [dropzoneActive, setDropzoneActive] = useState(false);

    function handleUploaderDrop(newFiles) {
        setFiles(newFiles);
        setDropzoneActive(false);
    }

    function handleUploaderDragEnter() {
        setDropzoneActive(true);
    }

    function handleUploaderDragLeave() {
        setDropzoneActive(false);
    }
    return (
        <Modal
            title={intl.formatMessage(messages.updateCertificate)}
            isOpen={showModal}
            handleToggle={() => {
                handleShowModal();
                setFiles([]);
            }}
        >
            <AvForm
                onValidSubmit={(event, values) => {
                    handleSubmit(event, {
                        ...values,
                        file: files[0],
                    });
                    setFiles([]);
                }}
            >
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Uploader
                                files={files}
                                dropzoneActive={dropzoneActive}
                                onDrop={handleUploaderDrop}
                                onDragEnter={handleUploaderDragEnter}
                                onDragLeave={handleUploaderDragLeave}
                                height='100px'
                                fileType='application/x-pkcs12'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <AvField
                                label={intl.formatMessage(
                                    messages.password,
                                )}
                                type='password'
                                name='password'
                                id='password'
                                value={''}
                                errorMessage='Senha é obrigatória'
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12}>
                            <button className='btn-submit'>
                                {intl.formatMessage(messages.confirmButton)}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </Modal>
    );
};

export default injectIntl(ModalUploadCertificate);
