import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    name: {
        id: 'suppliers.name',
        defaultMessage: 'Nome',
    },

    fieldItem: {
        id: 'suppliers.field',
        defaultMessage: 'Item',
    },

    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },

    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },

    generalTitle: {
        id: 'invoices.generalTitle',
        defaultMessage: 'Cabeçalho',
    },

    providerTitle: {
        id: 'invoices.providerTitle',
        defaultMessage: 'Prestador de Serviços',
    },

    borrowerTitle: {
        id: 'invoices.borrowerTitle',
        defaultMessage: 'Tomador de Serviços',
    },

    middlewareTitle: {
        id: 'invoices.middlewareTitle',
        defaultMessage: 'Intermediário de Serviços',
    },

    servicesTitle: {
        id: 'invoices.servicesTitle',
        defaultMessage: 'Serviços',
    },

    taxesTitle: {
        id: 'invoices.taxesTitle',
        defaultMessage: 'Valores/Impostos',
    },

    othersTitle: {
        id: 'invoices.othersTitle',
        defaultMessage: 'Outros',
    },
    standard: {
        id: 'global.standard',
        defaultMessage: 'Normal',
    },
    fitBox: {
        id: 'global.fitBox',
        defaultMessage: 'Tamanho dinamico',
    },
    fitBoxWithRef: {
        id: 'global.fitBoxWithRef',
        defaultMessage: 'Tamanho dinamico + refêrencia Y',
    },
    dynamicBox: {
        id: 'global.dynamicBox',
        defaultMessage: 'Tamanho fixo + referência Y',
    },
    mapType: {
        id: 'global.mapType',
        defaultMessage: 'Tipo mapeamento',
    },
    startsWith: {
        id: 'global.startsWith',
        defaultMessage: 'Começa com (Regex)',
    },
    endsWith: {
        id: 'global.endsWith',
        defaultMessage: 'Termina com (Regex)',
    },
    order: {
        id: 'global.order',
        defaultMessage: 'Ordem',
    },
    mapKeyReference: {
        id: 'global.mapKeyReference',
        defaultMessage: 'Referência Y (Key)',
    },
    removeFirstLine: {
        id: 'global.removeFirstLine',
        defaultMessage: 'Remover 1ª linha',
    },
    fitXWidth: {
        id: 'global.fitXWidth',
        defaultMessage: 'Ajustar X e comprimento',
    },
    hideMapInDocument: {
        id: 'global.hideMapInDocument',
        defaultMessage: 'Não mostrar no documento',
    },

    pixelAdjust: {
        id: 'mapItem.pixelAdjust',
        defaultMessage: 'Ajuste por pixel',
    },
    orientation: {
        id: 'mapItem.orientation',
        defaultMessage: 'Orientação',
    },
    startAt: {
        id: 'mapItem.startAt',
        defaultMessage: 'Começa em',
    },
    begin: {
        id: 'mapItem.begin',
        defaultMessage: 'Começo {entity}',
    },
    end: {
        id: 'mapItem.end',
        defaultMessage: 'Fim {entity}',
    },
    isManual: {
        id: 'mapItem.isManual',
        defaultMessage: 'Campo de preenchimento manual',
    },
    testButton: {
        id: 'mapItem.testButton',
        defaultMessage: 'Testar',
    },
    searchable: {
        id: 'mapItem.searchable',
        defaultMessage: 'Pesquisável',
    },
    isToLookOnFullDocument: {
        id: 'mapItem.searchable',
        defaultMessage: 'Procurar campo no documento completo',
    },
});

export default messages;
