import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const ISSQNForm = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const nfeISSQN = nfeItem?.imposto?.issqn;

    return (
        <>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cServico)}
                            type='text'
                            name='cServico'
                            id='cServico'
                            value={nfeISSQN?.cServico || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vBC)}
                            type='text'
                            name='vBC'
                            id='vBC'
                            value={nfeISSQN?.vBC || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vAliq)}
                            type='text'
                            name='vAliq'
                            id='vAliq'
                            value={nfeISSQN?.vAliq || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vISSQN)}
                            type='text'
                            name='vISSQN'
                            id='vISSQN'
                            value={nfeISSQN?.vISSQN || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cMunFG)}
                            type='text'
                            name='cMunFG'
                            id='cMunFG'
                            value={nfeISSQN?.cMunFG || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vDeducao)}
                            type='text'
                            name='vDeducao'
                            id='vDeducao'
                            value={nfeISSQN?.vDeducao || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vDescCond)}
                            type='text'
                            name='vDescCond'
                            id='vDescCond'
                            value={nfeISSQN?.vDescCond || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vISSRet)}
                            type='text'
                            name='vISSRet'
                            id='vISSRet'
                            value={nfeISSQN?.vISSRet || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vDescIncond)}
                            type='text'
                            name='vDescIncond'
                            id='vDescIncond'
                            value={nfeISSQN?.vDescIncond || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(ISSQNForm);
