import React, { useState } from 'react';

import { injectIntl } from 'react-intl';
import { Col } from 'reactstrap';
import MapList from '../../../components/MapList/MapList';
import Modal from '../../../components/Modal/Modal';
import MapItemDetailsPage from '../../MapItemDetailsPage/MapItemDetailsPage';

function MapsFields(props) {
    const {
        selectedMap,
        fields,
        selectedMapItem,
        handleFieldChange,
        handleFieldFocus,
        handleRemoveItemClick,
        handleSelectedMapItemCleanupClick,
        scrollAreaRef,
    } = props;

    const [showItemDetails, setShowItemDetails] = useState(false);
    const [mapItemId, setMapItemId] = useState(false);

    function handleItemDetails(mapItemId) {
        setShowItemDetails(true);
        setMapItemId(mapItemId);
    }

    function handleToggleItemDetails() {
        setShowItemDetails(!showItemDetails);
    }

    return (
        <section>
            <div ref={scrollAreaRef} className='fields-maps-items row'>
                <Col xs={12}>
                    <MapList
                        mapId={selectedMap.mapId}
                        mapItems={selectedMap.mapItems}
                        selectedMapItem={selectedMapItem}
                        fields={fields}
                        handleFieldChange={handleFieldChange}
                        handleFieldFocus={handleFieldFocus}
                        handleRemoveItemClick={handleRemoveItemClick}
                        handleSelectedMapItemCleanupClick={
                            handleSelectedMapItemCleanupClick
                        }
                        handleItemDetails={handleItemDetails}
                    />
                </Col>
            </div>
            <Modal
                title={'Detalhes do Item'}
                isOpen={showItemDetails}
                handleToggle={handleToggleItemDetails}
                size={'xl-right-side'}
                backdrop={false}
                modalClassName={'modal-right-side'}
            >
                <MapItemDetailsPage
                    mapItemId={mapItemId}
                    mapId={selectedMap.mapId}
                    handleToggleModal={handleToggleItemDetails}
                    mapVersion={selectedMap.mapVersion}
                />
            </Modal>
        </section>
    );
}

export default injectIntl(MapsFields);
