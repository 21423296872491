import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import { useLinkingOrders } from '../../../context/LinkingOrdersContext';
import DynamicDataTable from '../../../templates/DynamicDataTable/DynamicDataTable';

import SearchBar from '../../../components/SearchBar/SearchBar';
import CheckBox from '../../../components/CheckBox/CheckBox';
import { columnsNfeItems } from '../constants';

function NfeItemAssociationTable({
    intl,
    //Orders
    nfeOrders,
    //Redux State
    nfeItems,
    nfeItemsCount,
    loadingNfeItems,
    onInitNfeItems,
    onInitNfeItemsCount,
}) {
    const {
        documentId,
        selectedNfeItem,
        setSelectedNfeItem,
    } = useLinkingOrders();

    const [searchTerm, setSearchTerm] = useState('');
    const [listNfeWithoutAssociate, setListNfeWithoutAssociate] = useState(
        true,
    );
    const [dataNfeItems, setDataNfeItems] = useState({
        data: nfeItems,
        count: nfeItemsCount,
    });

    useEffect(() => {
        const queryItems = '?sort=-createdAt&skip=0&take=1000&page=0';

        onInitNfeItems(documentId, queryItems);
        onInitNfeItemsCount(documentId, queryItems);
    }, [documentId]);

    useEffect(() => {
        let newNfeItems = [];
        if (listNfeWithoutAssociate)
            newNfeItems = filterDataToNfeWithoutAssociate(nfeItems);

        if (listNfeWithoutAssociate) {
            setDataNfeItems({
                data: newNfeItems,
                count: newNfeItems?.length,
            });
        } else {
            setDataNfeItems({
                data: nfeItems,
                count: nfeItemsCount,
            });
        }
    }, [listNfeWithoutAssociate, nfeItems, nfeItemsCount]);

    //#region Functions
    const filterDataToNfeWithoutAssociate = nfeItems => {
        let newData = [];
        let nfeItemsWithOrder = [];

        nfeOrders.forEach(order => {
            nfeItemsWithOrder.push(order.nfeItemId);
        });

        newData = nfeItems.filter(item => {
            const nfeItemWithOrder = nfeOrders.find(
                x => x.nfeItemId === item.id,
            );

            return !nfeItemWithOrder;
        });

        return newData;
    };

    const handleSelectNfeItem = nfeItem => {
        if (nfeItem === null) return;

        if (selectedNfeItem?.id === nfeItem?.id) {
            setSelectedNfeItem(null);
        } else {
            setSelectedNfeItem(nfeItem);
        }
    };

    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: { cursor: 'pointer' },
            onClick: e => handleSelectNfeItem(rowInfo.original || null),
        };
    }

    function handleSearchBarSubmit(newSearchTerm) {
        if (!newSearchTerm) {
            let dataItems = nfeItems;

            if (listNfeWithoutAssociate) {
                dataItems = filterDataToNfeWithoutAssociate(dataItems);
            }

            setDataNfeItems({ data: dataItems, count: dataItems?.length });
            return;
        }

        setSearchTerm(newSearchTerm);

        let newOrders = nfeItems.filter(item => {
            const termLowerCase = String(newSearchTerm.toLowerCase()).trim();

            const formatQuantity = `${item?.prod?.qCom || 0}${
                item?.prod?.uCom
            }`;

            const formatUnitaryNetValue = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(String(item?.prod?.vUnCom));

            const formatTotalGrossValue = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(String(item?.prod?.vProd));

            const compare = (itemToCompare, compareItem) =>
                String(itemToCompare)
                    ?.toLowerCase()
                    .includes(compareItem);

            return (
                compare(item?.nItemNumber, termLowerCase) ||
                compare(item?.prod?.xProd, termLowerCase) ||
                compare(item?.prod?.ncm, termLowerCase) ||
                compare(formatUnitaryNetValue, termLowerCase) ||
                compare(formatTotalGrossValue, termLowerCase) ||
                compare(formatQuantity, termLowerCase)
            );
        });

        if (listNfeWithoutAssociate) {
            newOrders = filterDataToNfeWithoutAssociate(newOrders);
        }

        setDataNfeItems({ data: newOrders, count: newOrders?.length });
    }
    //#endregion Functions

    return (
        <>
            <DynamicDataTable
                headerTitle='Itens de NFE para associação'
                headerSubTitle='Selecione um item de Nfe para associar a(os) pedido(s)'
                componentId={'nfeItemAssociationToOrders'}
                loading={loadingNfeItems}
                headerTitleSize='24px'
                tableColumns={columnsNfeItems(
                    intl,
                    nfeItems,
                    selectedNfeItem,
                    handleSelectNfeItem,
                )}
                tableData={dataNfeItems?.data || []}
                tableDataLength={dataNfeItems?.count || 0}
                tablePageSize={5}
                tableHandleGetTrProps={handleGetTrProps}
                tableFrontPagination
                hideNewButton
                tableTabInside
                tableFetchPage
                additionalHeaderComponent={
                    <header>
                        <div
                            className='btns-topo'
                            style={{
                                float: 'right',
                                marginRight: 20,
                                flexDirection: 'column',
                            }}
                        >
                            <SearchBar
                                searchTerm={searchTerm}
                                handleSearchChange={newSearchTerm =>
                                    setSearchTerm(newSearchTerm)
                                }
                                handleSearchSubmit={handleSearchBarSubmit}
                                loading={loadingNfeItems}
                                colorVar
                            />
                        </div>
                    </header>
                }
                additionalFooterComponent={
                    <CheckBox
                        style={{ fontSize: '17px' }}
                        label={
                            'Somente itens de nfe que não tenha pedido associado'
                        }
                        id='listNfeWithoutAssociate'
                        checked={listNfeWithoutAssociate}
                        value={listNfeWithoutAssociate}
                        onChange={() => {
                            setListNfeWithoutAssociate(
                                !listNfeWithoutAssociate,
                            );
                        }}
                        disabled={false}
                    />
                }
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        nfeOrders: state.NFeReducer.nfeOrders || [],
        nfeItems: state.NFeReducer.nfeItems || [],
        nfeItemsCount: state.NFeReducer.nfeItemsCount || 0,
        loadingNfeItems: state.NFeReducer.loadingNfeItems,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitNfeItems: (documentId, filter) =>
            dispatch(actions.initNfeItems(documentId, filter)),
        onInitNfeItemsCount: (documentId, filter) =>
            dispatch(actions.initNfeItemsCount(documentId, filter)),
        onCleanIsSuccessCloseModal: () =>
            dispatch(actions.cleanIsSuccessCloseModal()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(NfeItemAssociationTable),
);
