import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    titleDocumentsConfigs: {
        id: 'documentFields.titleDocumentsConfigs',
        defaultMessage: 'Configurações de Documentos',
    },
    newField: {
        id: 'documentFields.newField',
        defaultMessage: 'Novo Campo',
    },
    titleMap: {
        id: 'documentFields.titleMap',
        defaultMessage: 'Campos de Mapeamentos',
    },
    titleDocumentField: {
        id: 'documentFields.titleDocumentField',
        defaultMessage: 'Campo do Documento',
    },
    titleMapField: {
        id: 'documentFields.titleMapField',
        defaultMessage: 'Campo de Mapeamento',
    },
    titleInvoice: {
        id: 'invoice.title',
        defaultMessage: 'Documentos',
    },

    titleField: {
        id: 'documentFields.titleField',
        defaultMessage: 'Campo {entity}',
    },

    deleteField: {
        id: 'documentFields.deleteField',
        defaultMessage: 'Excluir Campo',
    },
});
