import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    newAttachmentButton: {
        id: 'invoices.newAttachmentButton',
        defaultMessage: '+ Documento Vinculado',
    },
    newBoletoAttachment: {
        id: 'invoices.newBoletoAttachment',
        defaultMessage: '+ Boleto',
    },
    newRawFile: {
        id: 'invoices.newRawFile',
        defaultMessage: '+ Arquivo',
    },
    associatedDocuments: {
        id: 'invoices.associatedDocuments',
        defaultMessage: 'Documentos associados',
    },
    childValues: {
        id: 'invoices.childValues',
        defaultMessage:
            'Valor documento pai {entity1}, Valor somatório dos documentos filhos {entity2}',
    },
    subtitle: {
        id: 'invoices.subtitle',
        defaultMessage: ' {length} documentos encontrados',
    },

    //Columns 
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
    docType: {
        id: 'invoices.docType',
        defaultMessage: 'Tipo',
    },
    titleInvoice: {
        id: 'invoices.titleInvoice',
        defaultMessage: 'Nota Fiscal',
    },
    createdAt: {
        id: 'invoices.createdAt',
        defaultMessage: 'Criado Em',
    },
    categoryType: {
        id: 'invoices.categoryType',
        defaultMessage: 'Categoria',
    },
    totalValue: {
        id: 'maps.totalValue',
        defaultMessage: 'Valor total',
    },
    status: {
        id: 'documents.status',
        defaultMessage: 'Status integração',
    },
    
    fileDownload: {
        id: 'documents.fileDownload',
        defaultMessage: 'Download do arquivo',
    },
});
