import React, { useMemo } from 'react';

import { injectIntl } from 'react-intl';
import messages from './CompanyDetailsForm.intl';

import { Row, Col, FormGroup } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import EvtSelect from '../EvtSelect/EvtSelect';

function CompanyDetailsForm(props) {
    const { intl, states, cities, readOnly, canDeleteCompany, company } = props;
    const state = useMemo(() => {
        return states?.find(s => s.uf === company.state);
    }, [states]);
    const city = useMemo(() => {
        return state?.cities.find(s => s.name === company.city);
    }, [state]);

    const statesOptions = useMemo(() => {
        return states?.map(state => {
            return {
                label: state.name,
                value: state.uf,
            };
        });
    }, [states]);

    const citiesOption = useMemo(() => {
        return cities?.map(city => {
            return {
                label: city.name,
                value: city.name,
            };
        });
    }, [cities]);

    const CNPJValidate = {
        pattern: {
            value:
                '^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}|[0-9]{11}|[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}|[0-9]{11}|[0-9]{14})$',
            errorMessage: intl.formatMessage(messages.invalidCNPJ),
        },
    };
    const cnpjMatriz1 = company.cnpjMatriz || '';
    const cnpjMatriz = cnpjMatriz1.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,'$1.$2.$3/$4-$5')
    
    const cnpjFilial1 = company.cnpjFilial || '';
    const cnpjFilial = cnpjFilial1.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,'$1.$2.$3/$4-$5')

    return (
        <AvForm
            onValidSubmit={(e, values) => {
                props.handleSubmit(e, {
                    ...values,
                    expirationDate: company?.expirationDate || null,
                });
            }}
        >
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.name)}
                            type='text'
                            name='name'
                            id='name'
                            value={company.name || ''}
                            errorMessage=' '
                            disabled={readOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.CNPJCompany)}
                            type='text'
                            name='cnpjFilial'
                            id='cnpjFilial'
                            value={cnpjFilial}
                            errorMessage=''
                            disabled={readOnly}
                            required
                            validate={CNPJValidate}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.CNPJMatriz)}
                            type='text'
                            name='cnpjMatriz'
                            id='cnpjMatriz'
                            value={cnpjMatriz}
                            errorMessage=' '
                            disabled={readOnly}
                            validate={CNPJValidate}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.municipalRegistration,
                            )}
                            type='text'
                            name='municipalRegistration'
                            id='municipalRegistration'
                            value={company.municipalRegistration || ''}
                            errorMessage=' '
                            disabled={readOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className='text-left'>
                    {!readOnly ? (
                        <EvtSelect
                            label={intl.formatMessage(messages.state)}
                            name='state'
                            options={statesOptions}
                            handleSelectedOptionChange={props.handleStateChange}
                            option={
                                company && {
                                    selectedValueOption: props.selectedState
                                        ? props.selectedState
                                              .selectedValueOption
                                        : state?.uf,
                                    selectedLabelOption: props.selectedState
                                        ? props.selectedState
                                              .selectedLabelOption
                                        : state?.name,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.state)}
                                type='text'
                                name='state'
                                id='state'
                                disabled={readOnly}
                                value={state?.name}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    )}
                </Col>
                <Col xs={12} md={6} className='text-left'>
                    {!readOnly ? (
                        <EvtSelect
                            label={intl.formatMessage(messages.city)}
                            name='city'
                            options={citiesOption}
                            handleSelectedOptionChange={props.handleCityChange}
                            key={state?.name}
                            option={
                                company && {
                                    selectedValueOption: props.selectedCity
                                        ? props.selectedCity.selectedValueOption
                                        : city?.name,
                                    selectedLabelOption: props.selectedCity
                                        ? props.selectedCity.selectedLabelOption
                                        : city?.name,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.city)}
                                type='text'
                                name='city'
                                id='state'
                                disabled={readOnly}
                                value={city?.name}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    )}
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    <br />
                    <div className='mt-3'>
                        {!readOnly && (
                            <button
                                type='submit'
                                className='btn-submit'
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.saveButton)}
                            </button>
                        )}
                        {canDeleteCompany && (
                            <button
                                type='button'
                                className='btn-delete'
                                onClick={props.handleDelete}
                            >
                                {intl.formatMessage(messages.deleteButton)}
                            </button>
                        )}
                    </div>
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(CompanyDetailsForm);
