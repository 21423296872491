import React from 'react';
import Axios from 'axios';

import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import DropdownListButton from '../../../components/DropdownListButton/DropdownListButton';
import { filterPropertyTypes } from '../../../utils/enums';
import messages from '../DocumentsConfigsListPage.intl';

// #region Columns
export const columnsDocumentFields = (intl, fields) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.contractorName, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'contractorName',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                fields,
                'contractorName',
                intl.formatMessage(messages.contractorName, {
                    entity: '',
                }),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                fields,
                'contractorName',
                intl.formatMessage(messages.contractorName, {
                    entity: '',
                }),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.name, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'name',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            var id = row.index + 1;
            return (
                <div className='customer-container'>
                    <span className={`color-number color-number-${id}`}>
                        {row.value && row.value.substr(0, 1).toUpperCase()}
                    </span>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                fields,
                'name',
                intl.formatMessage(messages.name, {
                    entity: '',
                }),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                fields,
                'name',
                intl.formatMessage(messages.name, {
                    entity: '',
                }),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.documentType, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'documentType',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                fields,
                'documentType',
                intl.formatMessage(messages.documentType, {
                    entity: '',
                }),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                fields,
                'documentType',
                intl.formatMessage(messages.documentType, {
                    entity: '',
                }),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.categoryType)}</span>
            </div>
        ),
        accessor: 'categoryType',
        Cell: row => <p>{row.value}</p>,
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                fields,
                'categoryType',
                intl.formatMessage(messages.categoryType),
            ),
        minWidth:
            35 +
            calculateColumnWidth(
                fields,
                'categoryType',
                intl.formatMessage(messages.categoryType),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.activeCustom)}</span>
            </div>
        ),
        accessor: 'active',
        Cell: row => <p>{row.value ? 'Ativo' : 'Desativado'}</p>,
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                fields,
                'active',
                intl.formatMessage(messages.activeCustom),
            ),
        minWidth:
            35 +
            calculateColumnWidth(
                fields,
                'active',
                intl.formatMessage(messages.activeCustom),
            ),
    },
];

export const columnsMapFields = (intl, fields) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.name, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'name',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            var id = row.index + 1;
            return (
                <div className='customer-container'>
                    <span className={`color-number color-number-${id}`}>
                        {row.value && row.value.substr(0, 1).toUpperCase()}
                    </span>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                fields,
                'name',
                intl.formatMessage(messages.name, {
                    entity: '',
                }),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                fields,
                'name',
                intl.formatMessage(messages.name, {
                    entity: '',
                }),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.key, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'mapItem.field.key',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                fields,
                'mapItem.field.key',
                intl.formatMessage(messages.key, {
                    entity: '',
                }),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                fields,
                'mapItem.field.key',
                intl.formatMessage(messages.key, {
                    entity: '',
                }),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.documentType, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'documentType',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                fields,
                'documentType',
                intl.formatMessage(messages.documentType, {
                    entity: '',
                }),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                fields,
                'documentType',
                intl.formatMessage(messages.documentType, {
                    entity: '',
                }),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.categoryType)}</span>
            </div>
        ),
        accessor: 'categoryType',
        Cell: row => <p>{row.value}</p>,
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                fields,
                'categoryType',
                intl.formatMessage(messages.categoryType),
            ),
        minWidth:
            35 +
            calculateColumnWidth(
                fields,
                'categoryType',
                intl.formatMessage(messages.categoryType),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.activeCustom)}</span>
            </div>
        ),
        accessor: 'active',
        Cell: row => <p>{row.value ? 'Ativo' : 'Desativado'}</p>,
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                fields,
                'active',
                intl.formatMessage(messages.activeCustom),
            ),
        minWidth:
            35 +
            calculateColumnWidth(
                fields,
                'active',
                intl.formatMessage(messages.activeCustom),
            ),
    },
];

export const columnsCategory = (intl, handleDeleteCategory) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.name, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'name',
        Cell: row => {
            var id = row.index + 1;
            return (
                <div className='customer-container'>
                    {row.value && (
                        <span className={`color-number color-number-${id}`}>
                            {row.value.substr(0, 1).toUpperCase()}
                        </span>
                    )}
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth: 300,
        minWidth: 60,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.code, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'code',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth: 300,
        minWidth: 60,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.createdAt, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'createdAt',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth: 300,
        minWidth: 60,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.activeCustom, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'active',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value ? 'Ativo' : 'Desativado'}</div>
                </div>
            );
        },
        maxWidth: 300,
        minWidth: 60,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.contractorName, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'contractorName',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        maxWidth: 300,
        minWidth: 60,
    },
    {
        accessor: 'dropdownActions',
        style: {
            display: 'flex',
            justifyContent: 'end',
        },
        Cell: row => {
            return (
                <DropdownListButton
                    items={[
                        //Delete
                        {
                            name: intl.formatMessage(messages.deleteButton),
                            onClick: () => handleDeleteCategory(row.original),
                        },
                    ]}
                />
            );
        },
        maxWidth: 50,
    },
];
// #endregion Columns

// #region Properties
export const propertiesDocumentFields = (
    intl,
    mapCategoriesArray,
    categories,
) => [
    {
        key: 'contractorName',
        value: intl.formatMessage(messages.contractorName),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'name',
        value: intl.formatMessage(messages.name),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'documentType',
        value: intl.formatMessage(messages.documentType),
        type: filterPropertyTypes.ENUM,
        options: [
            { key: 'NFE', value: 'NFE' },
            { key: 'NFS', value: 'NFS' },
            { key: 'CTE', value: 'CTE' },
            { key: 'OTHER', value: 'OTHER' },
        ],
    },
    {
        key: 'categoryType',
        value: intl.formatMessage(messages.categoryType),
        type: filterPropertyTypes.ENUM,
        options: mapCategoriesArray(categories || []),
    },
    {
        key: 'active',
        value: intl.formatMessage(messages.activeCustom),
        type: filterPropertyTypes.ENUM,
        options: [
            { key: 'true', value: 'Ativo' },
            { key: 'false', value: 'Desativado' },
        ],
    },
];

export const propertiesMapFields = (intl, mapCategoriesArray, categories) => [
    {
        key: 'name',
        value: intl.formatMessage(messages.name),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'mapItem.field.key',
        value: intl.formatMessage(messages.key),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'documentType',
        value: intl.formatMessage(messages.documentType),
        type: filterPropertyTypes.ENUM,
        options: [
            { key: 'NFE', value: 'NFE' },
            { key: 'NFS', value: 'NFS' },
            { key: 'CTE', value: 'CTE' },
            { key: 'OTHER', value: 'OTHER' },
        ],
    },
    {
        key: 'categoryType',
        value: intl.formatMessage(messages.categoryType),
        type: filterPropertyTypes.ENUM,
        options: mapCategoriesArray(categories || []),
    },
    {
        key: 'active',
        value: intl.formatMessage(messages.activeCustom),
        type: filterPropertyTypes.ENUM,
        options: [
            { key: 'true', value: 'Ativo' },
            { key: 'false', value: 'Desativado' },
        ],
    },
];

export const propertiesCategory = intl => [
    {
        key: 'contractorId',
        value: intl.formatMessage(messages.contractorName),
        type: filterPropertyTypes.SELECT,
        getDetails: (setSelectItem, filterValue) => {
            Axios.get(`/Contractors/${filterValue}`)
                .then(response => {
                    setSelectItem({
                        label: response.data.displayName,
                        value: filterValue,
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        urlToRequest: `/Contractors`,
        labelKey: 'displayName',
    },
    {
        key: 'name',
        value: intl.formatMessage(messages.name),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'code',
        value: intl.formatMessage(messages.code),
        type: filterPropertyTypes.TEXT,
    },
    {
        key: 'createdAt',
        value: intl.formatMessage(messages.createdAt),
        type: filterPropertyTypes.DATE,
    },
    {
        key: 'active',
        value: intl.formatMessage(messages.activeCustom),
        type: filterPropertyTypes.ENUM,
        options: [
            { key: 'true', value: 'Ativo' },
            { key: 'false', value: 'Desativado' },
        ],
    },
];
// #endregion Columns
