import * as actionTypes from '../actions/actionTypes';

const initialState = {
    categories: [],
    loading: false,

    categoriesList: [],
    categoriesListCount: 0,
    loadingList: false,

    category: null,
    loadingDetails: false,
};

const setCategories = (state, action) => {
    return {
        ...state,
        categories: action.categories,
        loading: false,
    };
};

//CategoriesList
const setCategoriesListRequest = (state, action) => {
    return {
        ...state,
        categoriesList: [],
        loadingList: true,
    };
};

const setCategoriesList = (state, action) => {
    const { categoriesList, categoriesListCount, error } = action;

    let newState = {
        ...state,
        categoriesList,
        categoriesListCount,
    };

    if (error) newState = { ...newState, error };

    return {
        ...newState,
        loadingList: false,
    };
};

//CategoriesDetails
const setCategoryDetailsRequest = (state, action) => {
    return {
        ...state,
        category: null,
        loadingDetails: true,
    };
};

const setCategoryDetails = (state, { category, error }) => {
    let newState = {
        ...state,
        category,
    };

    if (error) newState = { ...newState, error };

    return {
        ...newState,
        loadingDetails: false,
    };
};

//CategoryUpdate, Delete and Create
const setLoadingDetailsTrue = (state, action) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setLoadingDetailsFalse = (state, { error }) => {
    let newState = {
        ...state,
    };

    if (error) newState = { ...newState, error };

    return {
        ...newState,
        loadingDetails: false,
    };
};

//Delete
const setDeleteRequest = (state, action) => {
    return {
        ...state,
        loadingList: true,
        loadingDetails: true,
    };
};

const setDeleteSuccessFailure = (state, { error }) => {
    let newState = {
        ...state,
    };

    if (error) newState = { ...newState, error };

    return {
        ...newState,
        loadingList: false,
        loadingDetails: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CATEGORIES_GET_SUCCESS:
            return setCategories(state, action);

        //CategoriesList
        case actionTypes.CATEGORIES_GET_LIST_REQUEST:
            return setCategoriesListRequest(state, action);
        case actionTypes.CATEGORIES_GET_LIST_SUCCESS:
        case actionTypes.CATEGORIES_GET_LIST_FAILURE:
            return setCategoriesList(state, action);

        //CategoryDetails
        case actionTypes.CATEGORY_GET_DETAILS_REQUEST:
            return setCategoryDetailsRequest(state, action);
        case actionTypes.CATEGORY_GET_DETAILS_SUCCESS:
        case actionTypes.CATEGORY_GET_DETAILS_FAILURE:
            return setCategoryDetails(state, action);

        //CategoryUpdate, Delete and Create
        case actionTypes.CATEGORIES_UPDATE_REQUEST:
        case actionTypes.CATEGORIES_CREATE_REQUEST:
            return setLoadingDetailsTrue(state, action);
        case actionTypes.CATEGORIES_UPDATE_SUCCESS:
        case actionTypes.CATEGORIES_UPDATE_FAILURE:
        case actionTypes.CATEGORIES_CREATE_SUCCESS:
        case actionTypes.CATEGORIES_CREATE_FAILURE:
            return setLoadingDetailsFalse(state, action);

        //DeleteInTable
        case actionTypes.CATEGORIES_DELETE_REQUEST:
            return setDeleteRequest(state, action);
        case actionTypes.CATEGORIES_DELETE_SUCCESS:
        case actionTypes.CATEGORIES_DELETE_FAILURE:
            return setDeleteSuccessFailure(state, action);

        default:
            return state;
    }
};

export default reducer;
