import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    notifyEmail: {
        id: 'settings.notifyEmail',
        defaultMessage:
            ' Notificar o seguinte email quando usuário não cadastrado enviar um documento:',
    },
    notifyEmailWhenError: {
        id: 'settings.notifyEmailWhenError',
        defaultMessage:
            ' Notificar o seguinte e-mail quanto ocorrer um erro no processamento do documento:',
    },
    allowSendDocumentsWithNoMap: {
        id: 'settings.allowSendDocumentsWithNoMap',
        defaultMessage: 'Permitir recepção de documentos sem mapeamentos associados',
    },
    defaultMapToUse: {
        id: 'settings.defaultMapToUse',
        defaultMessage: 'Mapeamento Padrão',
    },
    defaultSupplierToUse: {
        id: 'settings.defaultSupplierToUse',
        defaultMessage: 'Fornecedor Padrão',
    },
    permissions: {
        id: 'settings.permissions',
        defaultMessage: 'Permissões',
    },
    thereAreNoEmails: {
        id: 'settings.thereAreNoEmails',
        defaultMessage:
            'Você tem {entity} caixas de email cadastradas. Cadastrar mais caixas?',
    },
    configureEmail: {
        id: 'settings.configureEmail',
        defaultMessage: 'Você não tem caixas de email cadastradas.',
    },
    buttonConfigureEmails: {
        id: 'settings.buttonConfigureEmails',
        defaultMessage: 'Acessar caixas de email',
    },
    newPasswordLabelText: {
        id: 'settings.newPasswordLabelText',
        defaultMessage: 'Nova senha',
    },
    email: {
        id: 'settings.email',
        defaultMessage: 'Email',
    },
    emailManager: {
        id: 'settings.emailManager',
        defaultMessage: 'Monitoramento caixa de email',
    },
    //email domain config
    configEmailDomain: {
        id: 'settings.configEmailDomain',
        defaultMessage: 'Configuração de domínios',
    },
    mailsFromDomains: {
        id: 'settings.mailsFromDomains',
        defaultMessage: 'Permitir recepção dos documento sem usuário cadastrado para o seguintes domínios: ',
    },
    domains: {
        id: 'settings.domains',
        defaultMessage: 'Domínios',
    },
    usersDomains: {
        id: 'settings.usersDomains',
        defaultMessage: 'Usuários',
    },  
    skipValidationStepWhenAllFieldsAreValid: {
        id: 'settings.skipValidationStepWhenAllFieldsAreValid',
        defaultMessage: 'Pular etapa de revisão em documentos enviados por e-mail',
    },
    autoCancelLowRateOCRDocuments: {
        id: 'settings.autoCancelLowRateOCRDocuments',
        defaultMessage: 'Cancelar automaticamente documentos com baixo índice de leitura',
    },
    saveFilesRejectedByIntegrationProccess: {
        id: 'settings.saveFilesRejectedByIntegrationProccess',
        defineMessage: 'Salvar arquivos rejeitados via integração'
    }    
});

export default messages;
