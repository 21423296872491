import React from 'react';
import { injectIntl } from 'react-intl';

import { mapActionTypes } from '../../../utils/enums';
import messages from '../MapItemDetailsPage.intl';

import { Container, Row } from 'reactstrap';

import { AvField, AvForm } from 'availity-reactstrap-validation';

import InsertMapActionItemForm from './InsertMapActionItemForm/InsertMapActionItemForm';
import PadLeftMapActionItemForm from './PadLeftMapActionItemForm/PadLeftMapActionItemForm';
import PadRightMapActionItemForm from './PadRightMapActionItemForm/PadRightMapActionItemForm';
import RemoveMapActionItemForm from './RemoveMapActionItemForm/RemoveMapActionItemForm';
import ReplaceMapActionItemForm from './ReplaceMapActionItemForm/ReplaceMapActionItemForm';
import ConditionMapActionItemForm from './ConditionMapActionItemForm/ConditionMapActionItemForm';
import KeepMapActionItemForm from './KeepMapActionItemForm/KeepMapActionItemForm';

const mapActionItemForm = props => {
    const { intl } = props;
    const mapActionId = props.selectedAction.mapActionId;
    const type = props.selectedAction.type;

    const parameters = props.selectedAction.parameters;
    const condition = props.selectedAction.condition;

    let specificForm = '';
    let typeName = '';
    switch (type) {
        case mapActionTypes.INSERT:
            typeName = intl.formatMessage(messages.insert);
            specificForm = (
                <InsertMapActionItemForm type={type} parameters={parameters} />
            );
            break;
        case mapActionTypes.PAD_LEFT:
            typeName = intl.formatMessage(messages.padLeft);
            specificForm = (
                <PadLeftMapActionItemForm type={type} parameters={parameters} />
            );
            break;
        case mapActionTypes.PAD_RIGHT:
            typeName = intl.formatMessage(messages.padRight);
            specificForm = (
                <PadRightMapActionItemForm
                    type={type}
                    parameters={parameters}
                />
            );
            break;
        case mapActionTypes.REMOVE:
            typeName = intl.formatMessage(messages.remove);
            specificForm = (
                <RemoveMapActionItemForm type={type} parameters={parameters} />
            );
            break;
        case mapActionTypes.REPLACE:
            typeName = intl.formatMessage(messages.replace);
            specificForm = (
                <ReplaceMapActionItemForm type={type} parameters={parameters} />
            );
            break;
        case mapActionTypes.TRIM:
            typeName = intl.formatMessage(messages.trim);
            break;
        case mapActionTypes.TRIM_START:
            typeName = intl.formatMessage(messages.trim);
            break;
        case mapActionTypes.TRIM_END:
            typeName = intl.formatMessage(messages.trim);
            specificForm = <div />;
            break;
        case mapActionTypes.KEEP:
            typeName = intl.formatMessage(messages.keep);
            specificForm = (
                <KeepMapActionItemForm type={type} parameters={parameters} />
            );
            break;
        default:
            specificForm = intl.formatMessage(messages.formNotSupported);
            break;
    }

    return (
        <Container>
            <AvForm onValidSubmit={props.handleFormSubmit}>
                <Row>
                    <AvField type='hidden' name='type' value={type} />
                    <AvField
                        type='hidden'
                        name='mapActionId'
                        value={mapActionId}
                    />
                    <header className='title-form-modal'>
                        <strong>{intl.formatMessage(messages.type)}: </strong>
                        {typeName}
                    </header>

                    {specificForm}
                </Row>
                <Row>
                    <ConditionMapActionItemForm
                        onConditionChange={props.onConditionChange}
                        conditionRequired={props.conditionRequired}
                        conditionValue={props.conditionValue}
                        condition={condition}
                    />
                </Row>
                <button className='btn-submit'>
                    {intl.formatMessage(messages.saveButton)}
                </button>
                <button
                    className='ml-1 btn-delete'
                    onClick={props.handleActionModalCancelButtonClick}
                >
                    {intl.formatMessage(messages.cancelButton)}
                </button>
            </AvForm>
        </Container>
    );
};

export default injectIntl(mapActionItemForm);
