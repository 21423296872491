import * as actionTypes from '../actions/actionTypes';

const initialState = {
    roles: [],
    role: null,
    checkedClaims: [],
    checkedRoleLevel: [],
    loadingList: true,
    loadingDetails: true,
    rolesCount: 0,
};

const setRolesRequest = (state, action) => {
    return {
        ...state,
        roles: [],
        loadingList: true,
    };
};

const setRoles = (state, action) => {
    return {
        ...state,
        roles: action.roles,
        loadingList: false,
    };
};

const setRolesCount = (state, action) => {
    return {
        ...state,
        rolesCount: action.rolesCount,
    };
};

const setRolesDetailsRequest = (state, action) => {
    let checkedClaims = [];
    let checkedRoleLevel = [];

    return {
        ...state,
        role: null,
        checkedClaims,
        checkedRoleLevel,
        loadingDetails: true,
    };
};

const setRolesDetails = (state, action) => {
    const role = action.role || {};

    const roles =
        role.claims &&
        role.claims.filter(
            m =>
                m.value !== 'role-level-contractor' &&
                m.value !== 'role-level-full' &&
                m.value !== 'role-level-supplier',
        );

    const levels =
        role.claims &&
        role.claims.filter(
            m =>
                m.value === 'role-level-contractor' ||
                m.value === 'role-level-full' ||
                m.value === 'role-level-supplier',
        );

    let checkedClaims = [];
    let checkedRoleLevel = [];

    roles.forEach(val => {
        checkedClaims[val.value] = true;
    });

    levels.forEach(val => {
        checkedRoleLevel[val.value] = true;
    });

    return {
        ...state,
        role: action.role,
        checkedClaims,
        checkedRoleLevel,
        loadingDetails: false,
    };
};

const setRolesDetailsPost = (state, action) => {
    return {
        roles: [],
        loadingDetails: false,
    };
};

const setRoleDetailsNew = (state, action) => {
    return {
        ...state,
        role: null,
        loadingDetails: false,
    };
};

const setRole = (state, action) => {
    return {
        ...state,
        role: action.role,
        loadingDetails: false,
    };
};

const setCheckedClaims = (state, action) => {
    return {
        ...state,
        checkedClaims: action.checkedClaims,
    };
};

const setCheckedRoleLevel = (state, action) => {
    return {
        ...state,
        checkedRoleLevel: action.checkedRoleLevel,
    };
};

const cleanUpCheckedRoleLevels = (state, action) => {
    return {
        ...state,
        checkedRoleLevel: [],
    };
};

const cleanUpRoleDetails = (state, action) => {
    return {
        ...state,
        role: null,
        checkedClaims: [],
        checkedRoleLevel: [],
        loadingList: false,
        loadingDetails: false,
    };
};

const setRolesContractor = (state, action) => {
    return {
        ...state,
        rolesContractor: action.rolesContractor,
        loadingList: false,
    };
};

const setRolesContractorRequest = (state, action) => {
    return {
        ...state,
        rolesContractor: action.rolesContractor,
        loadingList: true,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ROLES_GET_COUNT_SUCCESS:
            return setRolesCount(state, action);

        case actionTypes.ROLES_GET_LIST_REQUEST:
            return setRolesRequest(state, action);
        case actionTypes.ROLES_GET_LIST_FAILURE:
        case actionTypes.ROLES_GET_LIST_SUCCESS:
            return setRoles(state, action);

        case actionTypes.ROLES_GET_DETAILS_REQUEST:
            return setRolesDetailsRequest(state, action);
        case actionTypes.ROLES_GET_DETAILS_SUCCESS:
            return setRolesDetails(state, action);
        case actionTypes.ROLE_GET_DETAILS_NEW:
            return setRoleDetailsNew(state, action);

        case actionTypes.ROLE_POST_SUCCESS:
            return setRolesDetailsPost(state, action);

        case actionTypes.ROLES_SET_CLAIMS_SUCCESS:
            return setCheckedClaims(state, action);

        case actionTypes.ROLE_CLEANUP:
            return cleanUpRoleDetails(state, action);
        case actionTypes.ROLES_SET_ROLES_SUCCESS:
            return setCheckedRoleLevel(state, action);

        case actionTypes.ROLES_CLEANUP_ROLE_LEVELS_SUCCESS:
            return cleanUpCheckedRoleLevels(state, action);
        case actionTypes.ROLES_SET_ROLE_LEVELS_SUCCESS:
            return setCheckedRoleLevel(state, action);
        case actionTypes.ROLE_SET_SUCCESS:
            return setRole(state, action);

        case actionTypes.ROLES_CONTRACTOR_GET_LIST_REQUEST:
            return setRolesContractorRequest(state, action);
        case actionTypes.ROLES_CONTRACTOR_GET_LIST_SUCCESS:
            return setRolesContractor(state, action);

        default:
            return state;
    }
};

export default reducer;
