import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import InvoicesTable from '../../components/InvoicesTable/InvoicesTable';
import OrderDetailsForm from '../../components/OrderDetailsForm/OrderDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './OrderDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';
import OrderItemsTable from '../../components/OrderItemsTable/OrderItemsTable';

class OrderDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
        query: '',
        selectedPartnersSuppliers: [],
    };

    componentDidMount() {
        const orderId = this.props.match.params.id;
        this.props.onInitOrderDetails(orderId);
        if (orderId !== 'new') {
            const { tabIndex } = this.props;
            tabIndex &&
                this.setState({
                    activeTab: Number(tabIndex),
                });

            this.props.onInitOrderItems(orderId, '?skip=0&take=10&page=0');
            this.props.onInitOrderItemsCount(orderId, '?skip=0&take=10&page=0');
        }
    }

    componentDidUpdate(prevprop) {
        if (prevprop.order !== this.props.order) {
            if (this.context.hasClaim('document-view') && this.props.order) {
                this.props.onInitOrderDocuments(
                    this.props.order.orderId,
                    '?skip=0&take=10&page=0',
                );
                this.props.onInitOrderDocumentsCount(
                    this.props.order.orderId,
                    '?skip=0&take=10&page=0',
                );
            }

            this.setState({
                selectedPartnersSuppliers:
                    this.props.order?.partnersSuppliers || [],
            });
        }
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
            this.props.onSetTabIndex(index);
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;

        if (!values.partnersSuppliers) {
            values.partnersSuppliers = this.state.selectedPartnersSuppliers;
        }

        if (!values.contractorId) {
            const { contractorId } = this.context;
            values.contractorId = contractorId;
        }

        if (this.props.match.params.id === 'new') {
            this.props.onCreateOrder(values);
        } else {
            this.props.onUpdateOrder(this.props.match.params.id, values);
        }
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleToggleDelete = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
        this.props.onDeleteOrder(this.props.order.orderId);
    };

    handleRowClick = (e, id) => {
        this.props.history.push(`/documents/${id}`);
    };

    handleNewClick = (e, id) => {
        this.props.history.push({
            pathname: `/documents`,
            state: {
                orderId: this.props.match.params.id,
                supplierId: this.props.order.supplierId,
            },
        });
    };

    handleContractorsChange = selectedContractor => {
        this.setState({
            selectedContractor: {
                selectedLabelOption: selectedContractor.label,
                selectedValueOption: selectedContractor.value,
            },
        });

        this.setState({
            selectedSupplier: {
                selectedLabelOption: '',
                selectedValueOption: '',
            },
        });

        this.setState({
            selectedPartnersSuppliers: [],
        });
    };

    handleSuppliersChange = selectedSupplier => {
        this.setState({
            selectedSupplier: {
                selectedLabelOption: selectedSupplier.label,
                selectedValueOption: selectedSupplier.value,
            },
        });
    };

    handlePartnerSuppliersChange = selectedOptions => {
        this.setState({
            selectedPartnersSuppliers: selectedOptions,
        });
    };

    reloadInvoicesList = () => {
        const orderId = this.props.order.orderId;
        const { query } = this.state;

        this.props.onInitOrderDocuments(orderId, query);
        this.props.onInitOrderDocumentsCount(orderId, query);
    };

    handleGetInvoiceTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => {
                if (rowInfo.original.documentType == 'NFE') {
                    this.props.history.push(
                        `/nfeDocuments/${rowInfo.original.documentId}`,
                    );
                } else {
                    this.props.history.push(
                        `/documents/${rowInfo.original.documentId}`,
                    );
                }
            },
        };
    };

    handleInvoicesStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadInvoicesList();
            },
        );
    };

    render() {
        const {
            intl,
            loadingDetails,
            documents,
            documentsCount,
            loadingOrderDocuments,
            session,
        } = this.props;

        const isNew = this.props.match.params.id === 'new' ? true : false;

        const canCreateDocument =
            this.context.hasClaim('document-create') &&
            !isNew &&
            this.props.order &&
            this.props.order.isAvailable;

        const canViewDocuments = this.context.hasClaim('document-view')
            ? isNew
                ? false
                : true
            : false;

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/orders',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/orders/:id',
                            name: intl.formatMessage(messages.titleOrder, {
                                entity:
                                    this.props.order &&
                                    this.props.order.orderDescription,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleOrder, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content orderDetails'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={[
                            {
                                name: intl.formatMessage(messages.orderInfo),
                                component: (
                                    <OrderDetailsForm
                                        order={this.props.order}
                                        handleSubmit={this.handleSubmit}
                                        handleContractorsChange={
                                            this.handleContractorsChange
                                        }
                                        handleSuppliersChange={
                                            this.handleSuppliersChange
                                        }
                                        handlePartnerSuppliersChange={
                                            this.handlePartnerSuppliersChange
                                        }
                                        handleDelete={this.handleToggleModal}
                                        isNew={isNew}
                                        loading={this.props.loadingDetails}
                                        selectedSupplier={
                                            this.state.selectedSupplier
                                        }
                                        selectedPartnersSuppliers={
                                            this.state.selectedPartnersSuppliers
                                        }
                                        selectedContractor={
                                            this.state.selectedContractor
                                        }
                                        sessionContractorId={
                                            session?.contractorId
                                        }
                                    />
                                ),
                            },
                            {
                                name: intl.formatMessage(messages.orderItems),
                                disabled: !canViewDocuments,
                                component: this.props.order &&
                                    !!this.props.orderItems && (
                                        <OrderItemsTable />
                                    ),
                            },
                            {
                                name: intl.formatMessage(messages.invoices),
                                disabled: !canViewDocuments,
                                component: this.props.order && (
                                    <InvoicesTable
                                        documents={documents}
                                        documentsCount={documentsCount}
                                        loadingOrderDocuments={
                                            loadingOrderDocuments
                                        }
                                        handleEvTableStateChange={
                                            this.handleInvoicesStateChange
                                        }
                                        handleGetTrProps={
                                            this.handleGetInvoiceTrProps
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteOrder)}
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText='Sim'
                    secondButtonText='Não'
                    toggleFirstButton={this.handleToggleDelete}
                    toggleSecondButton={this.handleToggleModal}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        orderItems: state.orderReducer.orderItems,
        order: state.orderReducer.order,
        documents: state.orderReducer.documents || [],
        documentsCount: state.orderReducer.documentsCount || [],
        loadingOrderDocuments: state.orderReducer.loadingOrderDocuments,
        loadingDetails: state.orderReducer.loadingDetails,
        tabIndex: state.orderReducer.tabIndex,
        session: state.authenticationReducer.session,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitOrderDetails: orderId =>
            dispatch(actions.initOrderDetails(orderId)),
        onUpdateOrder: (orderId, values) =>
            dispatch(actions.updateOrder(orderId, values)),
        onCreateOrder: values => dispatch(actions.createOrder(values)),
        onInitOrderItems: (orderId, query) =>
            dispatch(actions.initOrderItems(orderId, query)),
        onInitOrderItemsCount: (orderId, query) =>
            dispatch(actions.initOrderItemsCount(orderId, query)),
        onInitOrderDocumentsCount: (orderId, query) =>
            dispatch(actions.initOrderDocumentsCount(orderId, query)),
        onInitOrderDocuments: (orderId, query) =>
            dispatch(actions.initOrderDocuments(orderId, query)),
        onSetTabIndex: tabIndex => dispatch(actions.setTabOrderIndex(tabIndex)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(OrderDetailsPage),
);
