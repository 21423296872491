import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CompaniesTable from '../../components/CompaniesTable/CompaniesTable';
import ContractorDetailsForm from '../../components/ContractorDetailsForm/ContractorDetailsForm';
import ContractorFileForm from '../../components/ContractorFileForm/ContractorFileForm';
import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import SuppliersTable from '../../components/SuppliersTable/SuppliersTable';
import Tabs from '../../components/Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import * as actions from '../../store/actions/index';
import messages from './ContractorDetailsPage.intl';

class ContractorDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
        cities: [],
        selectedState: null,
        selectedCity: null,
        contractorId: '',
    };

    componentDidMount() {
        const contractorId = this.props.match.params.id;

        this.props.onInitContractorDetails(contractorId);
        this.props.onInitStates();
        this.props.onInitCities();

        if (contractorId !== 'new') {
            const { tabIndex } = this.props;

            tabIndex &&
                this.setState({
                    activeTab: Number(tabIndex),
                });

            this.setState({ contractorId });

            this.props.history.push(`${contractorId}`);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contractor !== this.props.contractor) {
            if (this.props.contractor)
                this.props.onInitCities(
                    this.props.contractor &&
                        this.props.contractor.address.state,
                );
        }
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
            this.props.onSetTabIndex(index);
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;

        const contractor = this.props.contractor || {};
        const payload = {
            ...values,
        };

        if (this.props.match.params.id === 'new') {
            this.props.onCreateContractor(payload);
        } else {
            this.props.onUpdateContractor(contractor.contractorId, payload);
        }
    };

    handleNewLogoFileSaveButtonClick = file => {
        const { contractorId } = this.props.contractor;
        this.props.onUploadContractorNormalLogo(contractorId, file);
    };

    handleNewSmallLogoSaveButtonClick = file => {
        const { contractorId } = this.props.contractor;
        this.props.onUploadContractorSmallLogo(contractorId, file);
    };

    handleNewAttachmentSaveButtonClick = file => {
        const { contractorId } = this.props.contractor;
        this.props.onUploadContractorAttachment(contractorId, file);
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleToggleDelete = async (event, values) => {
        this.setState({
            showModal: !this.state.showModal,
        });

        await this.props.onDeleteContractor(this.props.contractor.contractorId);
    };

    handleStateChange = selectedState => {
        const state = this.props.states.find(s => s.uf === selectedState.value);
        this.props.onInitCities(selectedState.value);

        this.setState({
            selectedState: {
                selectedLabelOption: state.name,
                selectedValueOption: state.uf,
            },
        });

        this.setState({
            selectedCity: {
                selectedLabelOption: '',
                selectedValueOption: '',
            },
        });
    };

    handleCityChange = selectedCity => {
        this.setState({
            selectedCity: {
                selectedLabelOption: selectedCity.label,
                selectedValueOption: selectedCity.value,
            },
        });
    };

    render() {
        const {
            intl,
            loadingDetails,
            loadingNormalLogo,
            loadingSmallLogo,
            loadingAttachment,
            states,
            cities,
        } = this.props;

        const contractor = this.props.contractor || {};
        const isNew = this.props.match.params.id === 'new' ? true : false;

        const hasClaimToViewFiles =
            this.context.hasClaim('contractor-logo-view') ||
            this.context.hasClaim('contractor-small-logo-view') ||
            this.context.hasClaim('contractor-attachment-view');
        const canViewContractorFiles = hasClaimToViewFiles && !isNew;
        const canViewSuppliers =
            this.context.hasClaim('supplier-view') && !isNew;
        const canViewCompanies =
            this.context.hasClaim('company-view') && !isNew;
        const canCreateContractor =
            this.context.hasClaim('contractor-create') && isNew;
        const canDeleteContractor =
            this.context.hasClaim('contractor-delete') && !isNew;
        const canUpdateContractor =
            this.context.hasClaim('contractor-update') && !isNew;

        const readOnly = !(canCreateContractor || canUpdateContractor);

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/contractors',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/contractors/:id',
                            name: intl.formatMessage(messages.titleContractor, {
                                entity:
                                    this.props.contractor &&
                                    this.props.contractor.displayName,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                messages.titleContractor,
                                {
                                    entity: '',
                                },
                            )}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={[
                            {
                                name: intl.formatMessage(
                                    messages.registrationInfo,
                                ),
                                component: (
                                    <ContractorDetailsForm
                                        key={'contractorsTab'}
                                        contractor={contractor}
                                        handleSubmit={this.handleSubmit}
                                        handleDelete={this.handleToggleModal}
                                        states={states}
                                        cities={cities}
                                        handleStateChange={
                                            this.handleStateChange
                                        }
                                        handleCityChange={this.handleCityChange}
                                        readOnly={readOnly}
                                        canDeleteContractor={
                                            canDeleteContractor
                                        }
                                        selectedCity={this.state.selectedCity}
                                        selectedState={this.state.selectedState}
                                    />
                                ),
                            },
                            {
                                name: 'Arquivos',
                                disabled: !canViewContractorFiles,
                                component: (
                                    <ContractorFileForm
                                        key={'filesTab'}
                                        contractor={contractor}
                                        handleNewLogoFileSaveButtonClick={
                                            this
                                                .handleNewLogoFileSaveButtonClick
                                        }
                                        handleNewSmallLogoSaveButtonClick={
                                            this
                                                .handleNewSmallLogoSaveButtonClick
                                        }
                                        handleNewAttachmentSaveButtonClick={
                                            this
                                                .handleNewAttachmentSaveButtonClick
                                        }
                                        loading={loadingDetails}
                                        loadingNormalLogo={loadingNormalLogo}
                                        loadingSmallLogo={loadingSmallLogo}
                                        loadingAttachment={loadingAttachment}
                                    />
                                ),
                            },
                            {
                                name: intl.formatMessage(messages.suppliers),
                                disabled: !canViewSuppliers,
                                component: (
                                    <SuppliersTable
                                        key={'suppliersTab'}
                                        contractorId={contractor.contractorId}
                                    />
                                ),
                            },
                            {
                                name: intl.formatMessage(messages.companies),
                                disabled: !canViewCompanies,
                                component: true && <CompaniesTable />,
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteContractor)}
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    toggleFirstButton={this.handleToggleDelete}
                    toggleSecondButton={this.handleToggleModal}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        contractor: state.contractorReducer.contractor,
        loadingDetails: state.contractorReducer.loadingDetails,
        states: state.stateReducer.states,
        cities: state.stateReducer.cities,
        file: state.fileReducer.file,
        normalLogoFileId: state.contractorReducer.normalLogoFileId,
        smallLogoFileId: state.contractorReducer.smallLogoFileId,
        attachmentFileId: state.contractorReducer.attachmentFileId,
        loadingNormalLogo: state.contractorReducer.loadingNormalLogo,
        loadingSmallLogo: state.contractorReducer.loadingSmallLogo,
        loadingAttachment: state.contractorReducer.loadingAttachment,

        tabIndex: state.contractorReducer.tabIndex,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitContractorDetails: contractorId =>
            dispatch(actions.initContractorDetails(contractorId)),
        onCreateContractor: values =>
            dispatch(actions.createContractor(values)),
        onUpdateContractor: (contractorId, values) =>
            dispatch(actions.updateContractor(contractorId, values)),
        onDeleteContractor: values =>
            dispatch(actions.deleteContractor(values)),
        onInitStates: () => dispatch(actions.initStates()),
        onInitCities: uf => dispatch(actions.initCities(uf)),
        onUploadContractorNormalLogo: (contractorId, file) =>
            dispatch(actions.uploadContractorNormalLogo(contractorId, file)),
        onUploadContractorSmallLogo: (contractorId, file) =>
            dispatch(actions.uploadContractorSmallLogo(contractorId, file)),
        onUploadContractorAttachment: (contractorId, file) =>
            dispatch(actions.uploadContractorAttachment(contractorId, file)),
        onSetTabIndex: tabIndex =>
            dispatch(actions.setTabContractorIndex(tabIndex)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ContractorDetailsPage),
);
