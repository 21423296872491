import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';

export const initCfops = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.CFOPS_GET_REQUEST,
        });

        axios
            .get('/Cfop')
            .then(response => {
                dispatch({
                    type: actionTypes.CFOPS_GET_SUCCESS,
                    cfops: response.data.result,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CFOPS_GET_FAILURE,
                    error,
                });
                error?.response?.data &&
                    toast.error(
                        'Ocorreu um erro ao carregar a lista de CFOPs. ' +
                        error.response.data,
                    );
            });
    };
};
