import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    title: {
        id: 'invoices.title',
        defaultMessage: 'Documentos',
    },
    nfeTab: {
        id: 'invoices.nfeTab',
        defaultMessage: 'NF-e',
    },
    nfeSummaryTab: {
        id: 'invoices.nfeSummaryTab',
        defaultMessage: 'Resumos',
    },
    onlySummaryPending: {
        id: 'invoices.onlySummaryPending',
        defaultMessage: 'Filtrar somente os Resumos pendentes',
    },
    titleInvoice: {
        id: 'invoices.titleInvoice',
        defaultMessage: 'Nota Fiscal',
    },
    invoiceNumber: {
        id: 'invoices.invoiceNumber',
        defaultMessage: 'Número',
    },
    invoiceNumberFilter: {
        id: 'invoices.invoiceNumberFilter',
        defaultMessage: 'Número do Documento',
    },
    subtitle: {
        id: 'invoices.subtitle',
        defaultMessage: ' {length} documentos encontrados',
    },
    newButtonText: {
        id: 'nfe.newButtonText',
        defaultMessage: 'Nova NF-E',
    },
    titleContractor: {
        id: 'contractors.titleContractor',
        defaultMessage: 'Contratante {entity}',
    },
    titleOnlyContractor: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
    titleSource: {
        id: 'documents.titleSource',
        defaultMessage: 'Origem',
    },
    integratedAtDocument: {
        id: 'cte.integratedAtDocument',
        defaultMessage: 'Integrado Em',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    canUploadError: {
        id: 'invoices.canUploadError',
        defaultMessage:
            'Documento não vinculado ao fornecedor, entre em contato com o suporte.',
    },

    success: {
        id: 'documents.success',
        defaultMessage: 'Sucesso',
    },
    error: {
        id: 'documents.error',
        defaultMessage: 'Erro',
    },
    pending: {
        id: 'documents.pending',
        defaultMessage: 'Pendente',
    },
    city: {
        id: 'invoices.city',
        defaultMessage: 'Cidade',
    },
    titleTaker: {
        id: 'contractors.titleTaker',
        defaultMessage: 'Tomador {entity}',
    },
    titleProvider: {
        id: 'suppliers.titleProvider',
        defaultMessage: 'Prestador {entity}',
    },
    nfeKey: {
        id: 'invoices.nfeKey',
        defaultMessage: 'Chave NFe',
    },
    supplierCNPJ: {
        id: 'suppliers.supplierCNPJ',
        defaultMessage: 'CNPJ do Fornecedor',
    },
    emissionDate: {
        id: 'invoices.emissionDate',
        defaultMessage: 'Data de Emissão',
    },

    awareOperation: {
        id: 'invoices.awareOperation',
        defaultMessage: 'Ciente da operação',
    },
    unknown: {
        id: 'invoices.unknown',
        defaultMessage: 'Desconhecido',
    },
    manifestUnknownSummary: {
        id: 'invoices.manifestUnknownSummary',
        defaultMessage: 'Manifestar Desconhecimento',
    },
    manifestAwarenessSummary: {
        id: 'invoices.manifestAwarenessSummary',
        defaultMessage: 'Manifestar Ciência',
    },
    searchNFe: {
        id: 'nfe.searchNFe',
        defaultMessage: 'Buscar NF-e',
    },
    nfeDocumentId: {
        id: 'invoices.nfeDocumentId',
        defaultMessage: 'Id Nfe',
    },
    nfeDocument: {
        id: 'invoices.nfeDocument',
        defaultMessage: 'NF-e',
    },
});

export default messages;
