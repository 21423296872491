import React, { Component } from 'react';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import messages from './IntegrationListPage.intl';

import { Container } from 'reactstrap';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { AuthContext } from '../../context/AuthContext';
import Tabs from '../../components/Tabs/Tabs';

import NFSeListIntegration from './pages/NFSeListIntegration';
import DocumentLogsIntegration from './pages/DocumentLogsIntegration';
import CteListIntegration from './pages/CteListIntegration';
import NFeListIntegration from './pages/NFeListIntegration';
import NFSeNacionalListIntegration from './pages/NFSeNacionalListIntegration';

class IntegrationListPage extends Component {
    static contextType = AuthContext;

    filterFields = [];

    state = {
        activeTab: 0,
    };

    componentDidMount() {
        const index =
            this.props?.tabIndex ||
            new URLSearchParams(window.location.search).get('index') ||
            0;

        this.setState({ activeTab: Number(index) });
    }

    handleNewDocumentButtonClick = () => {
        this.props.setTabIndexIntegration(this.state.activeTab);
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }

        this.props.history.push(
            `integrations?sort=-createdAt&skip=0&take=10&page=0`,
        );

        this.props.setTabIndexIntegration(index);
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => {},
        };
    };

    render() {
        const { activeTab } = this.state;
        const { intl } = this.props;

        const tabs = [];

        const integrationViewEnabled = this.context.hasClaim(
            'integration-view',
        );

        integrationViewEnabled &&
            tabs.push({
                name: intl.formatMessage(messages.documentsLogs),
                component: (
                    <DocumentLogsIntegration
                        handleGetTrProps={this.handleGetTrProps}
                        handleNewDocumentButtonClick={() => {}}
                        activeTab={activeTab}
                    />
                ),
            });

        integrationViewEnabled &&
            tabs.push({
                name: intl.formatMessage(messages.tabNfse),
                component: (
                    <NFSeListIntegration
                        handleGetTrProps={this.handleGetTrProps}
                        handleNewDocumentButtonClick={
                            this.handleNewDocumentButtonClick
                        }
                        activeTab={activeTab}
                    />
                ),
            });

        integrationViewEnabled &&
            tabs.push({
                name: intl.formatMessage(messages.tabNfe),
                component: (
                    <NFeListIntegration
                        handleGetTrProps={this.handleGetTrProps}
                        handleNewDocumentButtonClick={
                            this.handleNewDocumentButtonClick
                        }
                        activeTab={activeTab}
                    />
                ),
            });
            
        integrationViewEnabled &&
            tabs.push({
                name: intl.formatMessage(messages.tabCte),
                component: (
                    <CteListIntegration
                        handleGetTrProps={this.handleGetTrProps}
                        handleNewDocumentButtonClick={
                            this.handleNewDocumentButtonClick
                        }
                        activeTab={activeTab}
                    />
                ),
            });

        integrationViewEnabled &&
            tabs.push({
                name: intl.formatMessage(messages.tabNFSeNacional),
                component: (
                    <NFSeNacionalListIntegration
                        handleGetTrProps={this.handleGetTrProps}
                        handleNewDocumentButtonClick={() => {}}
                        activeTab={activeTab}
                    />
                ),
            });
        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/integrations',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <section className='content-middle'>
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={activeTab}
                        tabs={tabs}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        tabIndex: state.integrationReducer.tabIndex,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTabIndexIntegration: tabIndex =>
            dispatch(actions.setTabIndexIntegration(tabIndex)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(IntegrationListPage),
);
