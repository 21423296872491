import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    notificationCenter: {
        id: 'settings.notificationCenter',
        defaultMessage: 'Central de Notificações',
    },
    emails: {
        id: 'settings.enableMultipleOrders',
        defaultMessage: 'Emails',
    },
});

export default messages;
