import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import * as actions from '../../store/actions/index';

import { Container } from 'reactstrap';
import messages from './SettingsMailBoxDetailsPage.intl';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import Tabs from '../../components/Tabs/Tabs';
import Modal, { modalTypes } from '../../components/Modal/Modal';

import { AuthContext } from '../../context/AuthContext';
import SettingsDetailsForm from '../../components/SettingsMailBoxDetailsForm/SettingsMailBoxDetailsForm';
class SettingsMailBoxDetailsPage extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            showModalConfirmation: false,
        };
        this.setupId = props.match.params.id;
    }

    INBOUND_DEFAULT = {
        clientId: '',
        clientName: '',
        setupName: '',
        smtpServerHost: '',
        smtpServerPort: 0,
        imapServerHost: '',
        imapServerPort: 0,
        userName: '',
        password: '',
        url: '',
        active: true,
        email: '',
        EmailFromOriginalMailBox: false,
    };

    componentDidMount() {
        if (this.setupId !== 'newmailbox') {
            this.props.onInitMailBoxDetails(this.setupId);
        }
    }

    handleSubmit = (event, values) => {
        const contractorId = this.props.session.contractorId;
        const contractorName = this.props.session.contractor.displayName;

        const payload = {
            ...this.INBOUND_DEFAULT,
            ...values,
            clientId: contractorId,
            clientName: contractorName,
        };

        if (this.setupId === 'newmailbox') this.props.onCreateMailBox(payload);
        else {
            this.props.onUpdateMailBox(payload, this.setupId);
        }
    };

    handleToggleModalDelete = () => {
        this.setState({
            showModalConfirmation: !this.state.showModalConfirmation,
        });
    };

    handleDeleteField = async () => {
        this.setState({
            showModalConfirmation: !this.state.showModalConfirmation,
        });
        await this.props.onDeleteMailBox(this.setupId);
    };

    render() {
        const { intl } = this.props;

        const isNew = this.props.match.params.id === 'newmailbox';
        const mailBox = isNew ? this.INBOUND_DEFAULT : this.props.mailBox;

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/settings',
                            name: intl.formatMessage(messages.titleSettings),
                            active: false,
                        },
                        {
                            path: '/settings/settingsmailboxes',
                            name: intl.formatMessage(messages.titleInbound),
                            active: false,
                        },
                        {
                            path: `/settings/settingsmailboxes/${
                                this.props.match.params.id
                            }`,
                            name: intl.formatMessage(messages.Inbound, {
                                entity: `(${this.props.match.params.id})`,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                isNew
                                    ? messages.newMailBox
                                    : messages.titleOnlyInbound,
                            )}
                            subtitle={`(${this.props.match.params.id})`}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content'>
                    <Loading loading={this.props.loadingList} />

                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={0}
                        tabs={[
                            {
                                name: intl.formatMessage(
                                    messages.titleOnlyInbound,
                                ),
                                component: (
                                    <SettingsDetailsForm
                                        mailBox={mailBox}
                                        loading={this.props.loadingList}
                                        handleToggleModalDelete={
                                            this.handleToggleModalDelete
                                        }
                                        handleDeleteField={
                                            this.handleDeleteField
                                        }
                                        handleSubmit={this.handleSubmit}
                                        isNew={isNew}
                                    />
                                ),
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteInbound)}
                    isOpen={this.state.showModalConfirmation}
                    handleToggleModalDelete={this.handleToggleModalDelete}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    toggleFirstButton={this.handleDeleteField}
                    toggleSecondButton={this.handleToggleModalDelete}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mailBox: state.settingsReducer.mailBox,
        loadingList: state.settingsReducer.loadingList,
        session: state.authenticationReducer.session,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitMailBoxDetails: setupId =>
            dispatch(actions.initMailBoxDetails(setupId)),
        onCreateMailBox: mailBoxModel =>
            dispatch(actions.createMailBox(mailBoxModel)),
        onUpdateMailBox: (mailBoxUpdate, setupId) =>
            dispatch(actions.updateMailBox(mailBoxUpdate, setupId)),
        onDeleteMailBox: setupId => dispatch(actions.deleteMailBox(setupId)),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(SettingsMailBoxDetailsPage)),
);
