export default `
/* breadcrumb */
nav.breadcrumb ol.breadcrumb .breadcrumb-item:not(.active) {
  color: $primary-color !important;
}

nav.breadcrumb ol.breadcrumb .breadcrumb-item:not(.active) a {
  color: $primary-color !important;
}

/* buttons */
button, button.btn {
  background-color: $primary-color;
}

button:hover, button.btn:hover {
  background-color: $primary-color;
}

button.btn-link, button.btn.btn-link {
  color: $primary-color;
}

button.new-btn {
  background-color: $primary-color !important;
}

button.new-btn:hover {
  background-color: $primary-color;
}

button.search-btn:hover {
  color: $primary-color !important;
}

.custom-dropdown .btn-secondary span  {
  color: $primary-color !important;
}

/* content */
.content-middle .nav-tabs .nav-item .nav-link:hover {
  color: $primary-color;
  border-bottom-color: $primary-color;
}

.content-middle .nav-tabs .nav-item .nav-link.active {
  color: $primary-color;
  border-bottom-color: $primary-color;
}

/* form */
form div.form-group input[type=text]:focus,
form div.form-group input[type=text]:-webkit-autofill:focus,
form div.form-group input[type=password]:focus,
form div.form-group input[type=password]:-webkit-autofill:focus {
  border-bottom-color: $primary-color;
}
form div.form-group select:focus {
  border-bottom-color: $primary-color;
}

form > div:not(.form-group) a {
  color: $primary-color !important;
}

/* list-actions */
.list-actions.no-border .link-action-register {
  color: $primary-color;
}
.list-actions:not(.list-actions-active):hover {
  background-color: $primary-color;
  border-color: $primary-color;
}
.list-actions {
  border-color: $primary-color;
}
.list-actions > div .title-group-list > strong {
  color: $primary-color;
}
.list-actions button {
  border-color: $primary-color;
  color: $primary-color;
}
.list-actions button:hover {
  background-color: $primary-color;
  border-color: $primary-color;
}

/* menu */
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover,
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected {
  color: $primary-color; 
  border-left-color: $primary-color;
}
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover, i
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected i {
  color: $primary-color;
}
.menu.small .area-menu footer p a {
  color: $primary-color;
}
.btns-login .text-center label a {
  color: #065DFF !important;
}
.menu:not(.small) .area-menu #menus .menu-system #welcome-user span {
  color: $primary-color;
}
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover,
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected {
  color: $primary-color; 
  border-left-color: $primary-color;
}

.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover svg path,
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected svg path, 
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover svg circle, 
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected svg circle {
  stroke: $primary-color; 
}

.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover svg path,
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected svg path, 
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover svg circle, 
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected svg circle {
stroke: $primary-color; 
}

.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover i,
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected i {
  color: $primary-color;
}

.menu:not(.small) .area-menu footer p a {
  color: $primary-color;
}

/* dashboard */
.dashboardGrid .widget.medium-widget svg {
  background: $primary-color;
}
.dashboardGrid .pie {
  border-color: $primary-color;
}
.dashboardGrid .c100 > span {
  color: $primary-color;
}

/* react-table */
.ReactTable .pagination-bottom .Table__prevPageWrapper .Table__pageButton,
.ReactTable .pagination-bottom .Table__nextPageWrapper .Table__pageButton {
  border-color: $primary-color;
  color: $primary-color;
}
.ReactTable .pagination-bottom .Table__prevPageWrapper .Table__pageButton:hover,
.ReactTable .pagination-bottom .Table__nextPageWrapper .Table__pageButton:hover {
  border-color: $primary-color;
  background-color: $primary-color;
}
.ReactTable .pagination-bottom .Table__visiblePagesWrapper {
  border-color: $primary-color;
}

.ReactTable .pagination-bottom .Table__pageButton:hover {
  border-color: $primary-color;
  color: $primary-color;
}
.ReactTable .pagination-bottom .Table__pageButton--active {
  border-color: $primary-color;
  background-color: $primary-color;
  color: $primary-color;
}
.ReactTable .pagination-bottom .Table__pageButton:hover {
	background-color: $primary-colorCC; /* it has 'CC' here in order to get a little bit transparent */
}

/* maps */
.map-nota .image-map .btns-map .btns-top-map button {
  border-color: $primary-color;
  color: $primary-color;
}
.map-nota .image-map .btns-map .btns-top-map button:hover {
  border-color: $primary-color;
  color: $primary-color;
}
.map-nota .image-map .btns-map .btns-top-map button.active {
  background-color: $primary-color;
  border-color: $primary-color;
}
.map-nota .fields-maps .cards-fields .card .collapse .card-body .row .form-group input:focus,
.map-nota .fields-maps .cards-fields .card .collapsing .card-body .row .form-group input:focus {
  border-color: inherit !important;
}

/* roles */
.roles-form form div .tab-content .tab-pane fieldset {
  border-color: inherit !important;
}
.roles-form form div .tab-content .tab-pane fieldset .form-control .form-check.form-check-inline input[type=radio]:checked + label:before {
  background: inherit !important;
  border-color: inherit !important;
}
.roles-form form div .tab-content .tab-pane fieldset .form-control .form-check.form-check-inline input[type=checkbox]:checked + label:before {
  background: inherit !important;
  border-color: inherit !important;
}`;
