import React, { Component } from 'react';

import { injectIntl } from 'react-intl';
import messages from './SupplierListPage.intl';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { AuthContext } from '../../context/AuthContext';
import { filterPropertyTypes } from '../../utils/enums';
import EvtTable, {
    buildFilterOr,
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';

class SupplierListPage extends Component {
    static contextType = AuthContext;

    state = {
        shouldShowAllSuppliers: true,
        shouldSearch: false,
        query: '',
        filters: [],
    };

    properties = () => [
        {
            key: 'displayName',
            value: this.props.intl.formatMessage(messages.displayName),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'documentNumber1',
            value: this.props.intl.formatMessage(messages.cnpj),
            type: filterPropertyTypes.NUMBER,
            _map: value => {
                const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
                return newValue;
            },
        },
        {
            key: 'address.city',
            value: this.props.intl.formatMessage(messages.city),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'address.state',
            value: this.props.intl.formatMessage(messages.state),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'updatedAt',
            value: this.props.intl.formatMessage(messages.integratedAt),
            type: filterPropertyTypes.DATE,
        },
        {
            key: 'disabled',
            value: this.props.intl.formatMessage(messages.activity),
            type: filterPropertyTypes.ENUM,
            options: [
                { key: false, value: 'Ativo' },
                { key: true, value: 'Inativo' },
            ],
        },
    ];

    reloadSuppliersList = () => {
        const { query } = this.state;
        let filterQuery = query;

        if (query.includes('search=')) {
            const value = new URLSearchParams(query).get('search');
            filterQuery = buildFilterOr(value, this.properties());
        }

        this.props.history.push('/suppliers' + query);

        this.props.onInitSuppliers(filterQuery);
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.history.push(
                    `/suppliers/${rowInfo.original.supplierId}`,
                ),
            className: rowInfo.original.disabled ? 'disabled-row' : '',
        };
    };

    handleNewClick = () => {
        this.props.history.push(`/suppliers/new`);
    };

    handleRefreshButtonClick = () => {
        this.reloadSuppliersList();
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
        this.props.history.push(`/suppliers` + this.state.query);
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadSuppliersList();
            },
        );
    };

    render() {
        const { intl, loadingList, suppliers, suppliersCount } = this.props;
        const { filters } = this.state;

        const filterByActive =
            !!this.state.filters?.find(p => p.property === 'disabled') || false;

        const newSupplierButtonEnabled = this.context.hasClaim(
            'supplier-create',
        );

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.displayName)}</span>
                    </div>
                ),
                accessor: 'displayName',
                Cell: row => {
                    var id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    230 +
                    calculateColumnWidth(
                        suppliers,
                        'displayName',
                        intl.formatMessage(messages.displayName),
                    ),
                minWidth:
                    90 +
                    calculateColumnWidth(
                        suppliers,
                        'displayName',
                        intl.formatMessage(messages.displayName),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.cnpj)}</span>
                    </div>
                ),
                accessor: 'documentNumber1',
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>
                                {row.value.length === 14
                                    ? row.value.replace(
                                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                          '$1.$2.$3/$4-$5',
                                      )
                                    : row.value.replace(
                                          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                                          '$1.$2.$3-$4',
                                      )}
                            </div>
                        </div>
                    );
                },
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    80 +
                    calculateColumnWidth(
                        suppliers,
                        'documentNumber1',
                        intl.formatMessage(messages.cnpj),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.city)}</span>
                    </div>
                ),
                accessor: 'address.city',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    100 +
                    calculateColumnWidth(
                        suppliers,
                        'address.city',
                        intl.formatMessage(messages.city),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.state)}</span>
                    </div>
                ),
                accessor: 'address.state',
                style: {
                    fontSize: 'small',
                },
                maxWidth: 80,
                minWidth:
                    30 +
                    calculateColumnWidth(
                        suppliers,
                        'address.state',
                        intl.formatMessage(messages.state),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.integratedAt)}</span>
                    </div>
                ),
                accessor: 'updatedAt',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    const date = new Date(row.value).toLocaleString(navigator.language);
                    return (
                        <div className='customer-container'>
                            <div className=''>{date}</div>
                        </div>
                    );
                },
                minWidth:
                    100 +
                    calculateColumnWidth(
                        suppliers,
                        'updatedAt',
                        intl.formatMessage(messages.integratedAt),
                    ),
                maxWidth:
                    140 +
                    calculateColumnWidth(
                        suppliers,
                        'updatedAt',
                        intl.formatMessage(messages.integratedAt),
                    ),
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/suppliers',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                this.props.suppliersCount +
                                intl.formatMessage(messages.subtitle, {
                                    entity: filterByActive
                                        ? 'encontrado(s)'
                                        : 'ativo(s)',
                                })
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={this.properties()}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newSupplierButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlySupplier,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={suppliers}
                        length={suppliersCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                        search
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        suppliers: state.supplierReducer.suppliers || [],
        suppliersCount: state.supplierReducer.suppliersCount || 0,
        loadingList: state.supplierReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitSuppliers: filter => dispatch(actions.initSuppliers(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SupplierListPage),
);
