import React from "react";

const ProfileIcon = ({
  width="26",
  height="30",
  viewBox="0 0 36 40",
  fill="none",
  style=""
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {style}
    xmlns="http://www.w3.org/2000/svg">
  >
    <path d="M18 2L2 10L18 18L34 10L18 2Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 26L18 34L34 26" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 18L18 26L34 18" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);

export default ProfileIcon;
