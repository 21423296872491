import React, { useEffect } from 'react';
import * as actions from '../../store/actions/index';

import { connect } from 'react-redux';
import { useState } from 'react';

import { injectIntl } from 'react-intl';
import { columnsEvents, columnsLogs } from './Columns';
import messages from './DocumentViewer.intl';

import Modal from '../Modal/Modal';
import EvtTable from '../EvtTable/EvtTable';
import Viewer from '../Viewer/Viewer';
import { DocumentTypes } from '../../utils/enums';

export const getBoxes = fields => {
    return fields.map((field, index) => {
        if (!field.boundingBox) return null;

        if (field.hideMapInDocument) return null;

        return field
            ? {
                key: `box${index}`,
                x: field.boundingBox.x,
                y: field.boundingBox.y,
                width: field.boundingBox.width,
                height: field.boundingBox.height,
                page: field.boundingBox?.page || 1,
            }
            : {
                key: `box${index}`,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                page: 1,
            };
    });
};

function DocumentViewer(props) {
    const {
        intl,
        selectedDocument,
        documentHistory,
        boxes,
        ocrOperationResult,
        selectedItem,
        docType,
    } = props;
    const documentHistoryLength = documentHistory?.length || 0;
    const [showEventsModal, setShowEventsModal] = useState(false);
    const [documentTypeUrl, setDocumentTypeUrl] = useState(null);

    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [documentHistoryList, setdocumentHistoryList] = useState([]);

    useEffect(() => {
        let newDocType = 'Documents';

        switch (docType?.toUpperCase()) {
            case DocumentTypes.CTE:
                newDocType = 'CTe';
                break;
            case DocumentTypes.NFE:
                newDocType = 'NFe';
                break;
            default:
                newDocType = 'Documents';
                break;
        }

        setDocumentTypeUrl(newDocType);
    }, [docType]);

    const events = selectedDocument?.events || [];
    const words = ocrOperationResult?.lines?.flatMap(x => x.words);
    let verboseBoxes =
        words &&
        words.map((word, index) => {
            return {
                ...word.boundingBox,
                key: `bb${index}`,
            };
        });

    const urlDownload = `${window.API_URI}/${documentTypeUrl}/${selectedDocument?.documentId
        }/File`;

    const handleShowVerboseBoxesChecked = () => {
        selectedDocument &&
            props.onInitOcrOperationResult(selectedDocument.fileId);
    };

    const handleToggleEventsModal = () => setShowEventsModal(!showEventsModal);
    const handleToggleHistoryModal = () => setShowHistoryModal(!showHistoryModal);

    const handleHistoryPage = (query) => {
        const documentHistoryList = paginateHistory(query);
        setdocumentHistoryList(documentHistoryList);
    };

    const paginateHistory = query => {
        const currentPage = parseInt(query.split('&')[2].substring(5));

        const pageIndex = currentPage === 0 ? currentPage : currentPage * 10;
        const pageSize = 10 * (currentPage + 1);

        const documentHistoryList = documentHistory.slice(pageIndex, pageSize);
        return documentHistoryList;
    }

    return (
        <>
            {documentTypeUrl && (
                <>
                    <Viewer
                        readOnly={true}
                        url={urlDownload}
                        urlDownload={urlDownload}
                        urlXml={`${window.API_URI}/${documentTypeUrl}/${
                            selectedDocument?.documentId
                        }/Xml`}
                        boxes={boxes}
                        verboseBoxes={verboseBoxes}
                        handleShowVerboseBoxesChecked={
                            handleShowVerboseBoxesChecked
                        }
                        hasEvents={true}
                        handleEventsClick={handleToggleEventsModal}
                        handleHistoryClick={handleToggleHistoryModal}
                        selectedItem={selectedItem}
                        mapInfo={selectedDocument?.map}
                    />
                    <Modal
                        title={intl.formatMessage(messages.events)}
                        isOpen={showEventsModal}
                        handleToggle={handleToggleEventsModal}
                        size={'xl'}
                    >
                        <EvtTable
                            columns={columnsEvents(intl)}
                            data={events}
                            countHeaderText={`${events?.length ||
                                0} evento(s) econtrado(s)`}
                            alignCountToLeft={true}
                            pageSize={10}
                        />
                    </Modal>
                    <Modal
                        title={intl.formatMessage(messages.history)}
                        isOpen={showHistoryModal}
                        handleToggle={handleToggleHistoryModal}
                        size={'xl'}
                    >
                        <EvtTable
                            title={"history"}
                            columns={columnsLogs(intl)}
                            data={documentHistoryList}
                            length={documentHistoryLength}
                            countHeaderText={`${documentHistoryLength} ocorrência(s) encontrada(s)`}
                            handleStateChange={handleHistoryPage}
                            alignCountToLeft={true}
                            pageSize={10}
                            fetchPage
                        />
                    </Modal>
                </>
            )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        ocrOperationResult: state.ocrOperationResultReducer.ocrOperationResult,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitOcrOperationResult: fileId =>
            dispatch(actions.initOcrOperationResults(fileId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DocumentViewer),
);
