import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import messages from '../FieldDetailsForm.intl';
import { injectIntl } from 'react-intl';
import { useState } from 'react';
import { useCallback } from 'react';

const MapFieldTypeV2 = props => {
    const {
        intl,
        mapFieldTypeV2,
        mapFieldOcr,
        handleProcessFieldOcr,
        hasIsRepeatingDocument,
    } = props;
    const inicialState = {
        startAt: {
            term: mapFieldTypeV2?.startAt.term || '',
            orientation: mapFieldTypeV2?.startAt.orientation || 0,
            pixelAdjust: mapFieldTypeV2?.startAt.pixelAdjust || 0,
        },
        yBegin: {
            term: mapFieldTypeV2?.yBegin.term || '',
            orientation: mapFieldTypeV2?.yBegin.orientation || 0,
            pixelAdjust: mapFieldTypeV2?.yBegin.pixelAdjust || 0,
        },
        yEnd: {
            term: mapFieldTypeV2?.yEnd.term || '',
            orientation: mapFieldTypeV2?.yEnd.orientation || 0,
            pixelAdjust: mapFieldTypeV2?.yEnd.pixelAdjust || 0,
        },
        xBegin: {
            term: mapFieldTypeV2?.xBegin.term || '',
            orientation: mapFieldTypeV2?.xBegin.orientation || 0,
            pixelAdjust: mapFieldTypeV2?.xBegin.pixelAdjust || 0,
        },
        xEnd: {
            term: mapFieldTypeV2?.xEnd.term || '',
            orientation: mapFieldTypeV2?.xEnd.orientation || 0,
            pixelAdjust: mapFieldTypeV2?.xEnd.pixelAdjust || 0,
        },
        isManual: mapFieldTypeV2?.isManual || '',
        isToLookOnFullDocument: mapFieldTypeV2?.isToLookOnFullDocument || '',
    };

    const [fields, setFields] = useState(inicialState);

    const handleChangeFields = useCallback((key, name) => {
        return e => {
            setFields(prev => {
                const updateValue = Object.assign({}, prev);
                if (updateValue[key]) {
                    if (name) updateValue[key][name] = e.currentTarget.value;
                    else updateValue[key] = e.currentTarget.value;
                }

                return updateValue;
            });
        };
    }, []);

    function handleSubmitProcess() {
        handleProcessFieldOcr(fields);
    }

    function resetFields(e) {
        const isActive = e.currentTarget.value === 'false';

        if (isActive) {
            setFields({
                startAt: { term: '', orientation: 0, pixelAdjust: 0 },
                yBegin: { term: '', orientation: 0, pixelAdjust: 0 },
                yEnd: { term: '', orientation: 0, pixelAdjust: 0 },
                xBegin: { term: '', orientation: 0, pixelAdjust: 0 },
                xEnd: { term: '', orientation: 0, pixelAdjust: 0 },
            });
        } else {
            setFields(inicialState);
        }
    }

    return (
        <>
            <h5 className='title-area-form'>Map Type</h5>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.startAt)}
                            type='text'
                            name='mapFieldTypeV2.startAt.term'
                            id='term'
                            value={fields?.startAt?.term || ''}
                            onChange={handleChangeFields('startAt', 'term')}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.orientation)}
                            type='select'
                            name='mapFieldTypeV2.startAt.orientation'
                            id='orientation'
                            value={fields?.startAt?.orientation || '0'}
                            onChange={handleChangeFields(
                                'startAt',
                                'orientation',
                            )}
                        >
                            <option value='0'>Top</option>
                            <option value='1'>Bottom</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pixelAdjust)}
                            type='number'
                            name='mapFieldTypeV2.startAt.pixelAdjust'
                            id='pixelAdjust'
                            value={fields?.startAt?.pixelAdjust || '0'}
                            onChange={handleChangeFields(
                                'startAt',
                                'pixelAdjust',
                            )}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.begin, {
                                entity: 'Y',
                            })}
                            type='text'
                            name='mapFieldTypeV2.yBegin.term'
                            id='term'
                            value={fields?.yBegin?.term || ''}
                            onChange={handleChangeFields('yBegin', 'term')}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.orientation)}
                            type='select'
                            name='mapFieldTypeV2.yBegin.orientation'
                            id='orientation'
                            value={fields?.yBegin?.orientation || '0'}
                            onChange={handleChangeFields(
                                'yBegin',
                                'orientation',
                            )}
                        >
                            <option value='0'>Top</option>
                            <option value='1'>Bottom</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pixelAdjust)}
                            type='number'
                            name='mapFieldTypeV2.yBegin.pixelAdjust'
                            id='pixelAdjust'
                            value={fields?.yBegin?.pixelAdjust || '0'}
                            onChange={handleChangeFields(
                                'yBegin',
                                'pixelAdjust',
                            )}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.end, {
                                entity: 'Y',
                            })}
                            type='text'
                            name='mapFieldTypeV2.yEnd.term'
                            id='term'
                            value={fields?.yEnd?.term || ''}
                            onChange={handleChangeFields('yEnd', 'term')}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.orientation)}
                            type='select'
                            name='mapFieldTypeV2.yEnd.orientation'
                            id='orientation'
                            value={fields?.yEnd?.orientation || '0'}
                            onChange={handleChangeFields('yEnd', 'orientation')}
                        >
                            <option value='0'>Top</option>
                            <option value='1'>Bottom</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pixelAdjust)}
                            type='number'
                            name='mapFieldTypeV2.yEnd.pixelAdjust'
                            id='pixelAdjust'
                            value={fields?.yEnd?.pixelAdjust || '0'}
                            onChange={handleChangeFields('yEnd', 'pixelAdjust')}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.begin, {
                                entity: 'X',
                            })}
                            type='text'
                            name='mapFieldTypeV2.xBegin.term'
                            id='term'
                            value={fields?.xBegin?.term || ''}
                            onChange={handleChangeFields('xBegin', 'term')}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.orientation)}
                            type='select'
                            name='mapFieldTypeV2.xBegin.orientation'
                            id='orientation'
                            value={fields?.xBegin?.orientation || '0'}
                            onChange={handleChangeFields(
                                'xBegin',
                                'orientation',
                            )}
                        >
                            <option value='0'>Left</option>
                            <option value='1'>Right</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pixelAdjust)}
                            type='number'
                            name='mapFieldTypeV2.xBegin.pixelAdjust'
                            id='pixelAdjust'
                            value={fields?.xBegin?.pixelAdjust || '0'}
                            onChange={handleChangeFields(
                                'xBegin',
                                'pixelAdjust',
                            )}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.end, {
                                entity: 'X',
                            })}
                            type='text'
                            name='mapFieldTypeV2.xEnd.term'
                            id='term'
                            value={fields?.xEnd?.term || ''}
                            onChange={handleChangeFields('xEnd', 'term')}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.orientation)}
                            type='select'
                            name='mapFieldTypeV2.xEnd.orientation'
                            id='orientation'
                            value={fields?.xEnd?.orientation || '0'}
                            onChange={handleChangeFields('xEnd', 'orientation')}
                        >
                            <option value='0'>Left</option>
                            <option value='1'>Right</option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pixelAdjust)}
                            type='number'
                            name='mapFieldTypeV2.xEnd.pixelAdjust'
                            id='pixelAdjust'
                            value={fields?.xEnd?.pixelAdjust || '0'}
                            onChange={handleChangeFields('xEnd', 'pixelAdjust')}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup
                        check
                        className='required-field'
                        style={{
                            height: 70,
                            top: -20,
                        }}
                    >
                        <AvField
                            label={intl.formatMessage(messages.isManual)}
                            type='checkbox'
                            name='mapFieldTypeV2.isManual'
                            id='isManual'
                            value={fields?.isManual}
                            onClick={resetFields}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <button
                        type='button'
                        className='btn'
                        onClick={handleSubmitProcess}
                    >
                        {intl.formatMessage(messages.testButton)}
                    </button>
                </Col>
            </Row>
            {hasIsRepeatingDocument && (
                <Row>
                    <Col md={6}>
                        <FormGroup
                            check
                            className='required-field'
                            style={{
                                height: 70,
                                top: -20,
                            }}
                        >
                            <AvField
                                label={intl.formatMessage(
                                    messages.isToLookOnFullDocument,
                                )}
                                type='checkbox'
                                name='mapFieldTypeV2.isToLookOnFullDocument'
                                id='isToLookOnFullDocument'
                                value={fields?.isToLookOnFullDocument}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            )}
            {mapFieldOcr && (
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <AvField
                                label={'Resultado'}
                                type='textarea'
                                rows={4}
                                name='mapFieldOcr'
                                disabled={true}
                                value={mapFieldOcr?.OcrResult || 'testing'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default injectIntl(MapFieldTypeV2);
