import React from 'react';
import { injectIntl } from 'react-intl';

import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapActionTypes } from '../../utils/enums';
import messages from './MapActionActiveList.intl';

function MapActionActiveList(props) {
    const { intl } = props;

    return (
        <ListGroup style={{cursor: 'grab'}}>
            {props.mapActions && props.mapActions.length > 0 ? (
                props.mapActions.map((action, index) => {
                    return (
                        <ListGroupItem
                            key={action.mapActionId}
                            className='list-actions list-actions-active'
                            onDragStart={e =>
                                props.handleDragStart(e, action.mapActionId, index)
                            }
                            onDragEnd={e =>
                                props.handleDragEnd(e, action.mapActionId)
                            }
                            onDragOver={e =>
                                props.handleDragOver(e, action.mapActionId, index)
                            }
                            draggable
                        >
                            <Row>
                                <Col xs={12} md={8}>
                                    <div>
                                        <p>
                                            <strong>
                                                {intl.formatMessage(
                                                    messages.order,
                                                )}
                                                :{' '}
                                            </strong>
                                            <span>{action.order}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            <strong>
                                                {intl.formatMessage(
                                                    messages.type,
                                                )}
                                                :{' '}
                                            </strong>
                                            <span>
                                                {action.type ===
                                                mapActionTypes.INSERT
                                                    ? intl.formatMessage(
                                                          messages.insert,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.PAD_LEFT
                                                    ? intl.formatMessage(
                                                          messages.padLeft,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.PAD_RIGHT
                                                    ? intl.formatMessage(
                                                          messages.padRight,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.KEEP
                                                    ? intl.formatMessage(
                                                          messages.keep,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.REMOVE
                                                    ? intl.formatMessage(
                                                          messages.remove,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.REPLACE
                                                    ? intl.formatMessage(
                                                          messages.replace,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.TRIM
                                                    ? intl.formatMessage(
                                                          messages.trim,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.TRIM_START
                                                    ? intl.formatMessage(
                                                          messages.trimStart,
                                                      )
                                                    : action.type ===
                                                      mapActionTypes.TRIM_END
                                                    ? intl.formatMessage(
                                                          messages.trimEnd,
                                                      )
                                                    : ''}
                                            </span>
                                        </p>
                                    </div>
                                    {action.parameters && (
                                        <div>
                                            <p className='title-group-list'>
                                                <strong>
                                                    {intl.formatMessage(
                                                        messages.parameters,
                                                    )}{' '}
                                                </strong>
                                                {Object.keys(
                                                    action.parameters,
                                                ).map(key => (
                                                    <p key={key}>
                                                        -{' '}
                                                        <strong>{key}: </strong>
                                                        <span>
                                                            {action.parameters[
                                                                key
                                                            ] === true
                                                                ? 'True'
                                                                : action
                                                                      .parameters[
                                                                      key
                                                                  ] === false
                                                                ? 'False'
                                                                : action
                                                                      .parameters[
                                                                      key
                                                                  ]}
                                                        </span>
                                                    </p>
                                                ))}
                                            </p>
                                        </div>
                                    )}

                                    {action.condition && (
                                        <div>
                                            <p className='title-group-list'>
                                                <strong>
                                                    {intl.formatMessage(
                                                        messages.condition,
                                                    )}{' '}
                                                </strong>
                                                {Object.keys(
                                                    action.condition.parameters,
                                                ).map(key => (
                                                    <p key={key}>
                                                        -{' '}
                                                        <strong>{key}: </strong>
                                                        <span>
                                                            {
                                                                action.condition
                                                                    .parameters[
                                                                    key
                                                                ]
                                                            }
                                                        </span>
                                                    </p>
                                                ))}
                                            </p>
                                        </div>
                                    )}
                                </Col>
                                <Col xs={12} md={4}>
                                    <button
                                        className='float-right ml-2 delete'
                                        onClick={e =>
                                            props.handleDeleteAction(e, action)
                                        }
                                    >
                                        <FontAwesomeIcon icon='trash-alt' />
                                    </button>
                                    <button
                                        className='float-right'
                                        onClick={e =>
                                            props.handleEditActionClick(
                                                e,
                                                action,
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon='edit' />
                                    </button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    );
                })
            ) : (
                <ListGroupItem>
                    <p>{intl.formatMessage(messages.noAction)}</p>
                </ListGroupItem>
            )}
        </ListGroup>
    );
}

export default injectIntl(MapActionActiveList);
