import React, { useState, useEffect, useContext } from 'react';

import { injectIntl } from 'react-intl';
import messages from '../CteListPage.intl';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router';

import { AuthContext } from '../../../context/AuthContext';
import EvtTable from '../../../components/EvtTable/EvtTable';
import { formatFilters } from '../../DocumentListPage/DocumentListPage';

import { columnsCte, cteProperties } from '../components/ColumnsCte';
import HeaderTable from '../../../components/HeaderTable/HeaderTable';

function CteDocumentList(props) {
    const {
        intl,
        hasFull,
        newDocumentButtonEnabled,
        handleNewDocumentButtonClick,
        handleGetTrProps,
        activeTab,
        // mapStateProp
        documentsCte,
        documentsCteCount,
        loadingCteList,
    } = props;
    const context = useContext(AuthContext);
    const history = useHistory();

    const [query, setQuery] = useState('');
    const [filtersCte, setFiltersCte] = useState({
        filters: [],
        filterValue: '',
    });

    //Mount Page
    useEffect(() => {
        const URL = window.location.search;

        if (URL.includes('&search=')) {
            const filtersURL = formatFilters(URL);
            const value = filtersURL.shift().value;
            setFiltersCte({ ...filtersCte, filterValue: value });
        }
    }, []);

    //Update Page
    useEffect(() => {
        reloadCteList();
    }, [query]);

    //#region Function to Component
    function reloadCteList() {
        if (query?.includes('take')) {
            props.onInitDocumentsCte(query, filtersCte.filterValue);

            history.push('/cteDocuments' + query);
        }
    }

    const handleFiltersCteChange = (newFiltersCte, value) =>
        setFiltersCte({ filters: newFiltersCte, filterValue: value });

    function handleEvTableStateChangeCte(queryTable) {
        setQuery(queryTable);
    }
    //#endregion Function to Component

    return (
        <>
            <HeaderTable
                title={intl.formatMessage(messages.invoicesTitle)}
                properties={cteProperties(intl)}
                handleFiltersChange={handleFiltersCteChange}
                loading={loadingCteList}
                onClickReload={() => reloadCteList()}
                onClickNewButton={handleNewDocumentButtonClick}
                newButtonEnabled={newDocumentButtonEnabled}
                titleNewButton={intl.formatMessage(messages.title)}
                isAlternativeFilter
                documentFieldButtonEnabled
            />
            <section>
                <EvtTable
                    filters={filtersCte.filters}
                    columns={columnsCte(documentsCte, intl)}
                    data={documentsCte}
                    length={documentsCteCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeCte}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingCteList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: documentsCteCount,
                    })}
                    search
                />
            </section>
        </>
    );
}

const mapStateToProps = state => {
    return {
        documentsCte: state.cteReducer.documentsCte || [],
        documentsCteCount: state.cteReducer.documentsCteCount || 0,
        loadingCteList: state.cteReducer.loadingCteList || false,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //Filters
        onInitDocumentsCte: (filters, value) =>
            dispatch(actions.initDocumentsCte(filters, value)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(CteDocumentList)),
);
