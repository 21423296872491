import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    sendInfoHeader: {
        id: 'pendingFile.sendInfoHeader',
        defaultMessage: 'Informações para Envio',
    },
    detailsHeader: {
        id: 'pendingFile.detailsHeader',
        defaultMessage: 'Detalhes do Arquivo',
    },
    fileName: {
        id: 'pendingFile.fileName',
        defaultMessage: 'Nome do Arquivo',
    },
    sendedBy: {
        id: 'pendingFile.sendedBy',
        defaultMessage: 'Enviado Por',
    },
    source: {
        id: 'pendingFile.source',
        defaultMessage: 'Enviada para',
    },
    rejectionReason: {
        id: 'pendingFile.rejectionReason',
        defaultMessage: 'Motivo de Rejeição',
    },
    reject: {
        id: 'pendingFile.reject',
        defaultMessage: 'Rejeitar',
    },
});

export default messages;
