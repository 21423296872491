import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

import DynamicDataTable from '../../../templates/DynamicDataTable/DynamicDataTable';
import { useLinkingOrders } from '../../../context/LinkingOrdersContext';
import { columnsAssociatedOrders, columnsNfeOrders } from '../constants';

function AssociatedOrdersTable({
    intl,
    docType,
    //Redux Actions
    onGetAssociatedOrders,
    onGetAssociatedOrdersCount,
    onInitNfeOrders,
    onInitNfeOrdersCount,
    onInitCteOrders,
    onInitCteOrdersCount,
    //Redux State
    associatedOrders,
    associatedOrdersCount,
    nfeOrders,
    nfeOrdersCount,
    cteOrders,
    cteOrdersCount,
    loadingAssociatedOrders,
    loadingNfeOrders,
    loadingCteOrders,
    handleDeleteItemEvent,
}) {
    const [queryState, setQueryState] = useState(
        '?sort=-createdAt&skip=0&take=100&page=0',
    );

    const {
        hasAllowOrderDocumentAdd,
        hasAllowNFEOrderDocumentAdd,
        hasAllowCTEOrderDocumentAdd,
        documentId,
    } = useLinkingOrders();

    const [ordersColumns, setOrdersColumns] = useState([]);
    const [ordersDocument, setOrdersDocument] = useState({
        data: [],
        count: 0,
        loading: false,
    });

    useEffect(() => {
        switch (docType) {
            case 'NFE':
                setOrdersColumns(
                    columnsNfeOrders(
                        intl,
                        nfeOrders,
                        hasAllowNFEOrderDocumentAdd,
                        handleDeleteItemEvent,
                    ),
                );
                break;
            case 'CTE':
                setOrdersColumns(
                    columnsAssociatedOrders(
                        intl,
                        cteOrders,
                        hasAllowCTEOrderDocumentAdd,
                        handleDeleteItemEvent,
                    ),
                );
                break;
            default:
                setOrdersColumns(
                    columnsAssociatedOrders(
                        intl,
                        associatedOrders,
                        hasAllowOrderDocumentAdd,
                        handleDeleteItemEvent,
                    ),
                );
                break;
        }
    }, [
        intl,
        nfeOrders,
        hasAllowNFEOrderDocumentAdd,

        cteOrders,
        hasAllowCTEOrderDocumentAdd,

        associatedOrders,
        hasAllowOrderDocumentAdd,

        handleDeleteItemEvent,
    ]);

    useEffect(() => {
        switch (docType) {
            case 'NFE':
                setOrdersDocument({
                    data: nfeOrders,
                    count: nfeOrdersCount,
                    loading: loadingNfeOrders,
                });
                break;
            case 'CTE':
                setOrdersDocument({
                    data: cteOrders,
                    count: cteOrdersCount,
                    loading: loadingCteOrders,
                });
                break;
            default:
                setOrdersDocument({
                    data: associatedOrders,
                    count: associatedOrdersCount,
                    loading: loadingAssociatedOrders,
                });
                break;
        }
    }, [
        nfeOrders,
        nfeOrdersCount,
        loadingNfeOrders,

        cteOrders,
        cteOrdersCount,
        loadingCteOrders,

        associatedOrders,
        associatedOrdersCount,
        loadingAssociatedOrders,
    ]);

    const getReloadItems = (query = '') => {
        switch (docType) {
            case 'NFE':
                return reloadAssociatedOrdersNFE(query);
            case 'CTE':
                return reloadAssociatedOrdersCTE(query);
            default:
                return reloadAssociatedOrdersNFSAndOther(query);
        }
    };

    //#region NFS and OTHER
    const reloadAssociatedOrdersNFSAndOther = (query = '') => {
        onGetAssociatedOrders(documentId, query);
        onGetAssociatedOrdersCount(documentId);
    };

    const reloadAssociatedOrdersNFE = (query = '') => {
        onInitNfeOrders(documentId, query);
        onInitNfeOrdersCount(documentId);
    };

    const reloadAssociatedOrdersCTE = (query = '') => {
        onInitCteOrders(documentId, query);
        onInitCteOrdersCount(documentId);
    };
    //#endregion NFS and OTHER

    const handleStateChangeAssociatedOrders = query => {
        if (query !== queryState) {
            setQueryState(query);
            getReloadItems(query);
        }
    };

    return (
        <div style={{marginTop: '60px'}}>
            <DynamicDataTable
                headerTitle='Itens de pedidos associados a nota'
                componentId={'associatedOrderItems'}
                loading={ordersDocument.loading}
                headerTitleSize='24px'
                tableColumns={ordersColumns}
                tableData={ordersDocument.data || []}
                tableDataLength={ordersDocument.count || 0}
                tablePageSize={5}
                tableHandleStateChange={handleStateChangeAssociatedOrders}
                tableDefaultSort={[
                    {
                        id: 'itemOrder',
                        desc: true,
                    },
                ]}
                hideNewButton
                tableTabInside
                tableFetchPage
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        associatedOrders: state.documentReducer.ordersDocument || [],
        associatedOrdersCount: state.documentReducer.ordersDocumentCount || 0,
        nfeOrders: state.NFeReducer.nfeOrders || [],
        nfeOrdersCount: state.NFeReducer.nfeOrdersCount || 0,
        cteOrders: state.cteReducer.cteOrders || [],
        cteOrdersCount: state.cteReducer.cteOrdersCount || 0,
        loadingAssociatedOrders: state.documentReducer.loadingModalOrders,
        loadingNfeOrders: state.NFeReducer.loadingNfeOrders,
        loadingCteOrders: state.cteReducer.loadingCteOrders,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //NFS and OTHER
        onGetAssociatedOrders: (documentId, filter) =>
            dispatch(actions.getOrdersDocument(documentId, filter)),
        onGetAssociatedOrdersCount: documentId =>
            dispatch(actions.getOrdersDocumentCount(documentId)),
        //NFE
        onInitNfeOrders: (documentId, query) =>
            dispatch(actions.initNfeOrders(documentId, query)),
        onInitNfeOrdersCount: documentId =>
            dispatch(actions.initNfeOrdersCount(documentId)),
        //CTE
        onInitCteOrders: (documentId, query) =>
            dispatch(actions.initCteOrders(documentId, query)),
        onInitCteOrdersCount: documentId =>
            dispatch(actions.initCteOrdersCount(documentId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(AssociatedOrdersTable),
);
