import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { calculateColumnWidth } from '../../EvtTable/EvtTable';
import messages from '../DynamicModalOrdersDocuments.intl';
import Checkbox from '../../CheckBox/CheckBox';

export const columnsOrdersDocuments = (
    intl,
    ordersDocument,
    hasAllowOrderDocumentClaim,
    handleToggleModalDelete,
    checkbox,
    handleSelectOrderItem,
    selectedOrders,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.select)}</span>
            </div>
        ),
        accessor: 'checkbox',
        show: checkbox,
        Cell: row => {
            return (
                <div
                    className='customer-container'
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        //value={item.value}
                        id={'checkbox'}
                        checked={selectedOrders.find(
                            order => order === row.original?.orderItemId,
                        )}
                        onChange={() => handleSelectOrderItem(row.original)}
                    />
                </div>
            );
        },

        minWidth: 110,
        maxWidth:
            110 +
            calculateColumnWidth(
                [],
                'checkbox',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.titleOrderCode)}</span>
            </div>
        ),
        accessor: 'orderCode',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                ordersDocument,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                ordersDocument,
                'orderCode',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrder)}</span>
            </div>
        ),
        accessor: 'itemOrder',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                ordersDocument,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                ordersDocument,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.orderMeasure)}</span>
            </div>
        ),
        accessor: 'orderMeasure',
        style: {
            fontSize: 'small',
        },
        minWidth:
            50 +
            calculateColumnWidth(
                ordersDocument,
                'orderMeasure',
                intl.formatMessage(messages.orderMeasure),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrderDescription)}</span>
            </div>
        ),
        accessor: 'description',
        style: {
            fontSize: 'small',
        },
        minWidth:
            85 +
            calculateColumnWidth(
                ordersDocument,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
        maxWidth:
            85 +
            calculateColumnWidth(
                ordersDocument,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalGrossValue)}</span>
            </div>
        ),
        accessor: 'totalGrossValue',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                ordersDocument,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                ordersDocument,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.shippingDate)}</span>
            </div>
        ),
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            const [shippingDate, hour] =
                String(date) !== 'Invalid Date'
                    ? date?.replace(',', '').split(' ')
                    : '';
            return (
                <div className='customer-container'>
                    <div className=''>{shippingDate}</div>
                </div>
            );
        },
        accessor: 'shippingDate',
        show: checkbox,
        style: {
            fontSize: 'small',
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                ordersDocument,
                'shippingDate',
                intl.formatMessage(messages.shippingDate),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.deleteButton)}</span>
            </div>
        ),
        accessor: 'delete',
        show: !checkbox,
        Cell: row => {
            return (
                <div className='customer-container'>
                    <button
                        style={{ width: '100px' }}
                        className={
                            !hasAllowOrderDocumentClaim
                                ? 'btn-disable'
                                : 'btn-delete'
                        }
                        onClick={() => {
                            handleToggleModalDelete(row.original);
                        }}
                        disabled={!hasAllowOrderDocumentClaim}
                    >
                        <FontAwesomeIcon icon='trash-alt' />
                    </button>
                </div>
            );
        },
        maxWidth: 100,
    },
];
