import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from '../DocumentDetailsPage.intl';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Col, Container, FormGroup, Row } from 'reactstrap';

import Modal from '../../../components/Modal/Modal';
import Loading from '../../../components/Loading/Loading';
import EvtSelect from '../../../components/EvtSelect/EvtSelect';
import { AuthContext } from '../../../context/AuthContext';
import { useContext } from 'react';

const ModalMapPickerDetails = props => {
    const {
        intl,
        handleSupplierMapPickerToogle,
        showMapPicker,
        handleMapPickerSubmit,
        maps,
        selectedDocument,
        document,
        noMap,
    } = props;

    const context = useContext(AuthContext);
    const hasSupplierLevel = context.hasClaim('role-level-supplier');

    const [defaultValue, setDefaultValue] = useState(null);
    const [selectSupplierId, setSelectedSupplierId] = useState(null);

    const documentToSupplier = selectedDocument || document;

    useEffect(() => {
        if (hasSupplierLevel) {
            setSelectedSupplierId(documentToSupplier?.supplierId);
        } else {
            setSelectedSupplierId(null);
        }
        setDefaultValue(null);
    }, [showMapPicker]);

    useEffect(() => {
        if (maps?.length === 1) {
            setDefaultValue(maps[0]?.mapId);
        }
    }, [maps]);

    useEffect(() => {
        if (!noMap && selectSupplierId) {
            handleInitSuppliers();
        }
    }, [selectSupplierId]);

    const handleInitSuppliers = () => {
        props.onInitSupplierMaps(selectSupplierId);
    };

    function handleSupplierChange(values) {
        setSelectedSupplierId(values.value);
    }

    function handleValidSubmit(e, values) {
        if (hasSupplierLevel) {
            handleMapPickerSubmit(e, {
                ...values,
                supplierId: selectSupplierId,
            });
        } else {
            handleMapPickerSubmit(e, values);
        }
    }

    return (
        <Modal
            title={intl.formatMessage(messages.selectType)}
            isOpen={showMapPicker}
            handleToggle={handleSupplierMapPickerToogle}
        >
            <Loading loading={props.loadingMaps} />
            <AvForm onValidSubmit={handleValidSubmit}>
                <Container fluid>
                    {documentToSupplier && !hasSupplierLevel && (
                        <Row>
                            <Col md={12} className={'text-left'}>
                                <EvtSelect
                                    label={intl.formatMessage(
                                        messages.titleSupplier,
                                        {
                                            entity: '',
                                        },
                                    )}
                                    name='supplierId'
                                    url={`/Contractors/${
                                        documentToSupplier?.contractorId
                                    }/Suppliers`}
                                    labelKey='displayName'
                                    labelKey2='documentNumber1'
                                    valueKey='supplierId'
                                    async
                                    required={false}
                                    handleSelectedOptionChange={
                                        handleSupplierChange
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                    {!noMap && selectSupplierId && (
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <AvField
                                        name='mapId'
                                        label={intl.formatMessage(
                                            messages.labelMaps,
                                        )}
                                        type='select'
                                        errorMessage=' '
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={defaultValue || ''}
                                    >
                                        <option value=''>-</option>
                                        {maps &&
                                            maps.map(map => {
                                                const mapName = map.name
                                                    ? `${map.name}`
                                                    : '';
                                                const mapCity = map.city
                                                    ? ` - ${map.city}`
                                                    : '';
                                                const mapUf = map.uf
                                                    ? ` - ${map.uf}`
                                                    : '';
                                                const categoryName = `${
                                                    map.categoryType
                                                }${
                                                    !!map.subCategory
                                                        ? `(${map.subCategory})`
                                                        : ''
                                                }`;

                                                const categoryText =
                                                    map.categoryType !== "0" &&
                                                    map.categoryType
                                                        ? ` [${categoryName}]`
                                                        : '';

                                                const optionName =
                                                    mapName +
                                                    mapCity +
                                                    mapUf +
                                                    categoryText;

                                                return (
                                                    <option
                                                        key={map.mapId}
                                                        value={map.mapId}
                                                    >
                                                        {optionName}
                                                    </option>
                                                );
                                            })}
                                    </AvField>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    <Row className='mt-2'>
                        <Col xs={12}>
                            <button className='btn-submit'>
                                {intl.formatMessage(messages.confirmButton)}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        maps: state.supplierReducer.maps,
        selectedDocument: state.documentReducer.selectedDocument,
        loadingMaps: state.supplierReducer.loadingMaps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onInitSupplierMaps: (supplierId, filter) =>
            dispatch(actions.initSupplierMaps(supplierId, filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalMapPickerDetails),
);
