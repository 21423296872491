import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    contractor: {
        id: 'category.contractor',
        defaultMessage: 'Contratante',
    },
    name: {
        id: 'category.name',
        defaultMessage: 'Nome',
    },
    code: {
        id: 'category.code',
        defaultMessage: 'Código',
    },
    active: {
        id: 'category.active',
        defaultMessage: 'Ativo',
    },
    subcategories: {
        id: 'category.subcategories',
        defaultMessage: 'Subcategorias',
    },
});
