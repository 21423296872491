import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EvtTable from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import Header from '../../components/Header/Header';

const ContentInside = ({ hasInside, children }) => (
    <>
        {hasInside ? (
            <section
                className={`content-middle bg-content dynamic-container`}
                style={{ margin: '16px' }}
            >
                <div className='tab-content mt-3 low-margin'>{children}</div>
            </section>
        ) : (
            children
        )}
    </>
);

function DynamicDataTable({
    componentId = '',
    componentClassName = '',
    loading = false,
    handleRefreshButtonClick,
    //Header
    headerTitle,
    headerTitleSize,
    headerSubTitle,
    //EvtFilter
    filterProperties,
    filterHideAddButton = false,
    handleFiltersChange,
    //NewButton
    hideNewButton = false,
    hideCounter = false,
    handleNewClick = () => {},
    newButtonTitle = '',
    //EvtTable
    tableColumns = [],
    tableData = [],
    tableDataLength = 0,
    tablePageSize = 10,
    tableHandleStateChange = query => {},
    tableHandleGetTrProps = undefined,
    tableTabInside = false,
    tableFetchPage = false,
    tableFrontPagination = false,
    tableDefaultSort,
    disabled,
    additionalHeaderComponent,
    additionalFooterComponent,
}) {
    const getCount = () => {
        switch (tableDataLength) {
            case 0:
                return `nenhum registro encontrado`;
            case 1:
                return `${tableDataLength} registro encontrado`;
            default:
                return `${tableDataLength} registros encontrados`;
        }
    };

    return (
        <div id={componentId} className={componentClassName}>
            <ContentInside hasInside={tableTabInside}>
                {disabled && <div className='disabled-shadow' />}
                <header className='table-screen dynamic'>
                    <section className='title'>
                        {headerTitle && (
                            <Header
                                title={headerTitle}
                                headerTitleSize={headerTitleSize}
                                subtitle={headerSubTitle || undefined}
                            />
                        )}
                        {!hideCounter && (
                            <p className='text-muted subtitle-page'>
                                {getCount()}
                            </p>
                        )}
                    </section>
                    <section className='btns-topo'>
                        {handleFiltersChange && filterProperties && (
                            <EvtFilter
                                properties={filterProperties}
                                handleFiltersChange={handleFiltersChange}
                                loading={loading}
                                tabInside={tableTabInside}
                                hideAddButton={filterHideAddButton}
                            />
                        )}
                        {handleRefreshButtonClick && (
                            <button
                                className={`new-btn small ${
                                    loading ? 'loading' : ''
                                }`}
                                onClick={handleRefreshButtonClick}
                            >
                                <FontAwesomeIcon icon='sync' size='1x' />
                            </button>
                        )}
                        {!hideNewButton && (
                            <button
                                className='new-btn'
                                onClick={handleNewClick}
                            >
                                {newButtonTitle}
                            </button>
                        )}
                    </section>
                    {additionalHeaderComponent && (
                        <section>{additionalHeaderComponent}</section>
                    )}
                </header>
                <section className={`content-middle `}>
                    <EvtTable
                        columns={tableColumns}
                        data={tableData}
                        length={tableDataLength}
                        pageSize={tablePageSize}
                        handleStateChange={tableHandleStateChange}
                        handleGetTrProps={tableHandleGetTrProps}
                        loading={disabled ? false : loading}
                        defaultSorted={
                            tableDefaultSort || [
                                {
                                    id: 'createdAt',
                                    desc: true,
                                },
                            ]
                        }
                        frontPagination={tableFrontPagination}
                        tabInside={tableTabInside}
                        fetchPage={tableFetchPage}
                    />
                </section>
                {additionalFooterComponent && (
                    <section>{additionalFooterComponent}</section>
                )}
            </ContentInside>
        </div>
    );
}

export default DynamicDataTable;
