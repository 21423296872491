import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import Cookies from 'js-cookie';

import { CardImg } from 'reactstrap';

import PoweredBy from '../../components/PoweredBy/PoweredBy';
import LoginForm from '../../components/LoginForm/LoginForm';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import ChooseContractorForm from '../../components/ChooseContractorForm/ChooseContractorForm';

import * as actions from '../../store/actions/index';
import logo from '../../assets/images/logo.png';
import Loading from '../../components/Loading/Loading';

// Content Types
const LOGIN = 'LOGIN';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const CHOOSE_CONTRACTOR = 'CHOOSE_CONTRACTOR';

class LoginPage extends Component {
    state = {
        emailAddress: null,
        sessionId: null,
        userId: null,
    };

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);

        if (parsed.changePassword) {
            this.setState({
                contentType: CHANGE_PASSWORD,
                emailAddress: parsed.emailAddress,
                sessionId: parsed.sessionId,
                userId: parsed.userId,
            });
        } else {
            this.setState({ contentType: LOGIN });
        }

        const domain = window.location.host.split('.').shift();

        if (domain.toLowerCase() !== 'app') {
            this.props.onInitLayoutByContractor(domain);
        }

        const sessionId = Cookies.get('SessionId');
        if (sessionId) {
            this.props.history.push('/home');
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.session && prevProps.session.user) !==
            (this.props.session && this.props.session.user)
        ) {
            if (this.props.session && this.props.session.user.changePassword) {
                this.handleChangePasswordFormToogle();
            } else if (this.props.session) {
                this.handleAccessFormToogle();
            }
        }
    }

    handleBackClickToogle = () => {
        this.setState({ contentType: LOGIN });
        this.props.onPush('/login');
    };

    handleChangePasswordFormToogle = () => {
        this.setState({ contentType: CHANGE_PASSWORD });
    };

    handleAccessFormToogle = () => {
        this.setState({ contentType: CHOOSE_CONTRACTOR });
    };

    handleForgotPasswordToogle = () => {
        this.setState({ contentType: FORGOT_PASSWORD });
    };

    handleAccessFormSubmit = (e, values) => {
        const session = this.props.session || {};

        this.props.onAccessChange({
            ...values,
            session: session,
        });
    };

    handleLoginFormSubmit = (e, values) => {
        const contractorId =
            this.props.contractorlayout &&
            this.props.contractorlayout.contractorId;

        this.props.onLogin(values.email, values.password, contractorId);
    };

    handleChangePasswordFormSubmit = (e, values) => {
        const { sessionId, userId, emailAddress } = this.state;

        this.props.onChangePassword({
            ...values,
            emailAddress,
            sessionId,
            userId,
        });

        this.setState({ contentType: LOGIN });
    };

    handleForgotPasswordSubmit = (e, values) => {
        this.props.onForgotPasswordSubmit(values.forgotPasswordEmail);
        this.handleForgotPasswordToogle();
        this.setState({ contentType: LOGIN });
    };

    handleForgotPasswordReturnButtonClick = e => {
        this.handleBackClickToogle();
        this.setState({ contentType: LOGIN });
    };

    render() {
        const {
            intl,
            loading,
            contractorlayout,
            loadingContractorLayout,
            session,
        } = this.props;

        const { user } = session || {};
        const { accesses } = user || {};

        const loadingLoginPage = loading || loadingContractorLayout;
        const { contentType, byteArray, primaryColor, contractorId } =
            contractorlayout || {};

        const contractorLogo =
            contentType &&
            byteArray &&
            `data: ${contentType} ;base64,${byteArray}`;
        const image = contractorLogo || logo;

        const contentForType = type => {
            switch (type) {
                case LOGIN:
                    return (
                        <LoginForm
                            intl={intl}
                            contractorColor={primaryColor}
                            contractorId={contractorId}
                            onLoginWithGoogle={this.props.onLoginWithGoogle}
                            handleLoginFormSubmit={this.handleLoginFormSubmit}
                            handleForgotPasswordToogle={
                                this.handleForgotPasswordToogle
                            }
                        />
                    );
                case CHANGE_PASSWORD:
                    return (
                        <ChangePasswordForm
                            contractorColor={primaryColor}
                            handleBackClickToogle={this.handleBackClickToogle}
                            handleChangePasswordFormSubmit={
                                this.handleChangePasswordFormSubmit
                            }
                        />
                    );
                case FORGOT_PASSWORD:
                    return (
                        <ForgotPasswordForm
                            intl={intl}
                            contractorColor={primaryColor}
                            handleForgotPasswordSubmit={
                                this.handleForgotPasswordSubmit
                            }
                            handleForgotPasswordReturnButtonClick={
                                this.handleForgotPasswordReturnButtonClick
                            }
                        />
                    );
                case CHOOSE_CONTRACTOR:
                    return (
                        <ChooseContractorForm
                            loading={loading}
                            accesses={accesses}
                            contractorColor={primaryColor}
                            handleBackClickToogle={this.handleBackClickToogle}
                            handleAccessFormSubmit={this.handleAccessFormSubmit}
                        />
                    );
                default:
                    return '';
            }
        };

        return (
            <div className='login'>
                <Loading loading={loadingLoginPage} />
                {!loadingContractorLayout && (
                    <div className={`login-card`}>
                        <section className={`login-card-login`}>
                            <CardImg top src={image} className='login-logo' />
                            {contentForType(this.state.contentType)}
                        </section>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        session: state.authenticationReducer.session,
        accesses: state.authenticationReducer.accesses,
        loading: state.authenticationReducer.loading,
        contractorlayout: state.contractorReducer.contractorlayout,
        loadingContractorLayout:
            state.contractorReducer.loadingContractorLayout,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPush: path => dispatch(push(path)),
        onLogin: (email, password, contractorId) =>
            dispatch(actions.login(email, password, contractorId)),
        onLogout: () => dispatch(actions.logout()),

        onAccessChange: payload => dispatch(actions.setAccess(payload)),
        onChangePassword: payload => dispatch(actions.changePassword(payload)),
        onForgotPasswordSubmit: email =>
            dispatch(actions.forgotPassword(email)),
        onInitLayoutByContractor: domain =>
            dispatch(actions.initLayoutByContractor(domain)),
        onLoginWithGoogle: (token, contractorId) =>
            dispatch(actions.loginByGoogle(token, contractorId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(LoginPage),
);
