import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const ConfinsForm = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const nfeCOFINS = nfeItem?.imposto?.cofins?.cofinsObject;

    return (
        <>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.CST)}
                            type='text'
                            name='cstCofinsDescription'
                            id='cstCofinsDescription'
                            value={
                                `${nfeCOFINS?.cst} - ${
                                    nfeCOFINS?.cstCofinsDescription
                                }` || ''
                            }
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vBC)}
                            type='text'
                            name='vBC'
                            id='vBC'
                            value={nfeCOFINS?.vBC || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vAliq)}
                            type='text'
                            name='pCOFINS'
                            id='pCOFINS'
                            value={nfeCOFINS?.pCOFINS || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vCOFINS)}
                            type='text'
                            name='vCOFINS'
                            id='vCOFINS'
                            value={nfeCOFINS?.vCOFINS || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.qBCProd)}
                            type='text'
                            name='qBCProd'
                            id='qBCProd'
                            value={nfeCOFINS?.qBCProd || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vAliqProdCofins)}
                            type='text'
                            name='vAliqProd'
                            id='vAliqProd'
                            value={nfeCOFINS?.vAliqProd || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(ConfinsForm);
