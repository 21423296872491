import * as actionTypes from './actionTypes';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';

export const initPendingFiles = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.PENDING_FILES_GET_LIST_REQUEST,
        });
        axios
            .get(`/PendingFile${filter}`)
            .then(response => {
                dispatch({
                    type: actionTypes.PENDING_FILES_GET_LIST_SUCCESS,
                    pendingFiles: response.data.result,
                    pendingFilesCount: response.data.count,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.PENDING_FILES_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initPendingFilesDetails = pendingFileId => {
    return dispatch => {
        dispatch({
            type: actionTypes.PENDING_FILES_GET_DETAILS_REQUEST,
        });
        axios
            .get(`/PendingFile/${pendingFileId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.PENDING_FILES_GET_DETAILS_SUCCESS,
                    pendingFile: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.PENDING_FILES_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const createDocumentByPendingFile = (pendingFileId, payload) => {
    return dispatch => {
        dispatch({
            type: actionTypes.PENDING_FILES_ACTION_REQUEST,
        });
        axios
            .post(`/PendingFile/${pendingFileId}/CreateDocument`, payload)
            .then(response => {
                dispatch({
                    type: actionTypes.PENDING_FILES_ACTION_SUCCESS,
                });

                toast.success('Envio realizado com sucesso');
                dispatch(push('/pendingFiles?'));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.PENDING_FILES_ACTION_FAILURE,
                    error,
                });
            });
    };
};

export const rejectPendingFile = (pendingFileId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.PENDING_FILES_ACTION_REQUEST,
        });
        axios
            .post(`/PendingFile/${pendingFileId}/Reject`)
            .then(response => {
                dispatch({
                    type: actionTypes.PENDING_FILES_ACTION_SUCCESS,
                });

                toast.success('Arquivo rejeitado com sucesso');
                dispatch(push('/pendingFiles?'));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.PENDING_FILES_ACTION_FAILURE,
                    error,
                });
            });
    };
};
