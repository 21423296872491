import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    events: {
        id: 'invoices.events',
        defaultMessage: 'Eventos',
    },
    eventDescription: {
        id: 'invoices.eventDescription',
        defaultMessage: 'Evento',
    },
    date: {
        id: 'invoices.date',
        defaultMessage: 'Data',
    },
    status: {
        id: 'invoices.status',
        defaultMessage: 'Status integração',
    },
    protocolNumber: {
        id: 'invoices.protocolNumber',
        defaultMessage: 'Número do Protocolo',
    },
    history: {
        id: 'documents.history',
        defaultMessage: 'Histórico',
    },
    logDescription: {
        id: 'documents.logDescription',
        defaultMessage: 'Descrição',
    },
    user: {
        id: 'documents.user',
        defaultMessage: 'Usuário',
    },
    createdAt: {
        id: 'documents.createdAt',
        defaultMessage: 'Registrado em:',
    },
});
