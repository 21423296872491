import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';

import * as actions from '../../store/actions/index';

import { isHttpsAuth0 } from '../../utils/getAuth0Config';
import Loading from '../Loading/Loading';
import Sidebar from '../Sidebar/Sidebar';

import layoutCSS from './Layout.css';
import GlobalContextProvider from '../../utils/GlobalContextProvider';

class Layout extends Component {
    constructor(props) {
        super(props);

        const sideBarState = localStorage.getItem('SIDE_BAR_STATE');

        this.state = {
            isMenuCollapsed: sideBarState === 'true',
        };

        this.hasBootstrappedCSS = false;
        this.toogleCollapseMenu = this.toogleCollapseMenu.bind(this);
    }

    componentDidMount() {
        this.props.onInitCurrentSession();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.session !== this.props.session) {
            const { user } = this.props.session || {};

            if (
                !user ||
                (user && user.accesses && user.accesses.length === 0)
            ) {
                this.handleLogout();
            }
        }
    }

    handleLogout = () => {
        const isHttpsAuth0Protocol = isHttpsAuth0();

        this.props.onLogout();
        isHttpsAuth0Protocol &&
            this.props.auth0.logout({
                logoutParams: {
                    returnTo: `${window.location.origin}/login`,
                },
            });
    };

    toogleCollapseMenu = () => {
        localStorage.setItem('SIDE_BAR_STATE', !this.state.isMenuCollapsed);

        this.setState({
            ...this.state,
            isMenuCollapsed: !this.state.isMenuCollapsed,
        });
    };

    bootstrapCSSandLogoIfNeeded() {
        if (this.hasBootstrappedCSS) return;

        const session = this.props.session || {};
        const contractor = session.contractor || {};
        // replace all '$primary-color' for the contractor's primary color
        this.bootstrapCSS(
            layoutCSS.split('$primary-color').join(contractor.primaryColor),
        );
    }

    bootstrapCSS(css) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = css;
        document.getElementsByTagName('head')[0].appendChild(style);
        this.hasBootstrappedCSS = true;
    }

    render() {
        const { session, loading } = this.props;

        const user = (session && session.user) || {};
        const contractor = session && session.contractor;

        let logoUrl = '';
        let logoMiniUrl = '';

        if (contractor) {
            logoUrl = `${window.API_URI}/Files/${contractor.normalLogoFileId}`;
            logoMiniUrl = `${window.API_URI}/Files/${
                contractor.smallLogoFileId
            }`;
            this.bootstrapCSSandLogoIfNeeded();
        }

        return loading || !session ? (
            <Loading loading={true} />
        ) : (
            <section className='system'>
                <Sidebar
                    handleLogoutClick={this.handleLogout}
                    logoSrc={logoUrl}
                    logoMiniSrc={logoMiniUrl}
                    isMenuCollapsed={this.state.isMenuCollapsed}
                    username={user.name}
                    hasClaim={session.hasClaim}
                    collapseMenu={this.toogleCollapseMenu}
                />
                <main
                    className={`main-content ${
                        this.state.isMenuCollapsed ? 'small' : ''
                    }`}
                >
                    <GlobalContextProvider session={session}>
                        {this.props.children}
                    </GlobalContextProvider>
                </main>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.authenticationReducer.loading,
        session: state.authenticationReducer.session,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        onInitCurrentSession: () => dispatch(actions.getCurrentSession()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withAuth0(Layout));
