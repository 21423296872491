import { defineMessages } from "react-intl";

import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,

  title: {
    id: "users.title",
    defaultMessage: "Usuários"
  },

  subtitle: {
    id: "users.subtitle",
    defaultMessage: " {length} usuário(s) encontrado(s)"
  },

  titleOnlyUser: {
    id: "users.titleOnlyUser",
    defaultMessage: "Usuário"
  },

  name: {
    id: "users.name",
    defaultMessage: "Nome"
  },

  email: {
    id: "users.email",
    defaultMessage: "Email"
  },

  function: {
    id: "users.role",
    defaultMessage: "Função"
  },

  password: {
    id: "users.password",
    defaultMessage: "Senha"
  },

  role: {
    id: "users.role",
    defaultMessage: "Função"
  },

  company: {
    id: "users.company",
    defaultMessage: "Contratante"
  },

  home: {
    id: "home.title",
    defaultMessage: "Início"
  },

  supplier: {
    id: "suppliers.titleOnlySupplier",
    defaultMessage: "Fornecedor"
  }
});

export default messages;
