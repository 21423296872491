import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, withRouter } from 'react-router';

import { injectIntl } from 'react-intl';
import messages from './FileUploadForm.intl';
import { toast } from 'react-toastify';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Button } from 'reactstrap';

import Loading from '../../components/Loading/Loading';
import UploadOrderForm from './components/UploadOrderForm';
import ModalImage from './components/ModalImage';
import ModalFile from './components/ModalFile';

function FileUploadForm(props) {
    const [files, setFiles] = useState([]);
    const [dropzoneActive, setDropzoneActive] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalFile, setShowModalFile] = useState(false);
    const [cropFileUrl, setCropFileUrl] = useState([]);
    const [crop, setCrop] = useState(null);
    const [loadingCrop, setLoadingCrop] = useState(false);
    const [orderItemsList, setOrderItemsList] = useState(null);

    const location = useLocation();

    const { intl, loading, isDocument, documentType } = props;
    const maxSize = 50000000;
    let isRawFile = location?.state?.isRawFile;

    useEffect(() => {
        files.forEach(file => {
            if (!file || isRawFile) return;

            switch (file.type) {
                case 'image/gif':
                case 'image/jpeg':
                case 'image/png':
                case 'image/tiff':
                    const imageFileReader = new FileReader();
                    imageFileReader.onload = e => {
                        if (!isRawFile) {
                            setCropFileUrl(e.target.result);
                            setShowModal(true);
                        }
                    };

                    imageFileReader.readAsDataURL(file);
                    break;

                case 'application/pdf':
                case 'text/xml':
                case 'application/xml':
                    if (!isRawFile) {
                        setCropFileUrl(file);
                        setShowModalFile(true);
                        setCrop(null);
                    }
                    break;

                default:
                    break;
            }
        });
    }, [files]);

    function dataUrltoBlob(dataUrl) {
        try {
            const byteString = atob(dataUrl.split(',')[1]);
            const mimeString = dataUrl
                .split(',')[0]
                .split(':')[1]
                .split(';')[0];

            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ab], { type: mimeString });
        } catch (e) {
            return null;
        }
    }

    function distinctArray(arr, value) {
        const seenValues = new Set();
        const distinctElements = [];

        arr.forEach(item => {
            const itemValue = item[value];

            if (!seenValues.has(itemValue)) {
                seenValues.add(itemValue);
                distinctElements.push(item);
            }
        });

        return distinctElements;
    }

    function handleUploaderDrop(newFiles) {
        newFiles.forEach(file => {
            if (file?.size > maxSize) {
                toast.error(intl.formatMessage(messages.errorMaxSize));
                return;
            }
        });

        if (isRawFile) {
            const distinctFiles = distinctArray(files.concat(newFiles), 'path');

            setFiles(distinctFiles);
        } else {
            setFiles(newFiles);
        }
        setDropzoneActive(false);
    }

    function handleCropChange(crop) {
        if (crop.width === 0 && crop.height === 0) return;

        setCrop(crop);
    }

    function handleUploaderDragEnter() {
        setDropzoneActive(true);
    }

    function handleUploaderDragLeave() {
        setDropzoneActive(false);
    }

    function handleCropModalToggle() {
        setShowModal(!showModal);
    }

    async function handleXmlConfirmUploadClick(event) {
        event && event.preventDefault();

        const fileUrl = cropFileUrl || '';
        setShowModalFile(!showModalFile);

        if (isRawFile) {
            props.handleUploadFile(files, []);
        } else {
            props.handleUploadFile(fileUrl, orderItemsList);
        }
    }

    function handleXmlCancelUploadClick() {
        setShowModalFile(!showModalFile);
    }

    async function handleCropConfirmClick(event) {
        event && event.preventDefault();

        if (files < 1) {
            toast.error(intl.formatMessage(messages.errorNoFiles));
            return;
        }

        if (isRawFile) {
            props.handleUploadFile(files, []);
            setShowModal(false);
            setLoadingCrop(false);
        } else if (crop) {
            const fileUrl = cropFileUrl || '';
            const fileImg = files[0];
            const file = new File([dataUrltoBlob(fileUrl)], `${fileImg.path}`, {
                type: 'image/jpg',
            });

            const data = new FormData();
            data.append('file', file);
            data.append('x', crop.x);
            data.append('y', crop.y);
            data.append('width', crop.width);
            data.append('height', crop.height);

            setLoadingCrop(true);
            axios
                .post('/Files/Crop', data, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: response.headers['content-type'],
                    });
                    props.handleUploadFile(blob, orderItemsList);
                    setShowModal(false);
                    setLoadingCrop(false);
                })
                .catch(error => {
                    toast.error('Something went wrong..');
                    setLoadingCrop(false);
                });
        }
    }

    function handleLinkSubmit(e, values) {
        const { url } = values;

        axios
            .get('/Proxy?url=' + url, { responseType: 'arraybuffer' })
            .then(response => {
                const type = response.headers['content-type'];
                if (
                    type.indexOf('image/') !== -1 ||
                    type.indexOf('application/pdf') !== -1
                ) {
                    let blob = new Blob([response.data], {
                        type,
                    });
                    setFiles([blob]);
                } else {
                    toast.error('O tipo do arquivo não é suportado.');
                }
            })
            .catch(() => {
                toast.error('Something went wrong..');
            });
    }
    const displayLinkDocument =
        documentType !== 'NFE' && documentType !== 'CTE';

    const hasLink = !props.linkDocument && displayLinkDocument && !isRawFile;
    return (
        <>
            <Loading loading={loading} />
            <section className='content-middle'>
                {hasLink && (
                    <>
                        <AvForm onValidSubmit={handleLinkSubmit}>
                            <Row>
                                <Col xs={10}>
                                    <AvField
                                        label='Link'
                                        placeholder='Digite ou cole o link do documento...'
                                        type='text'
                                        name='url'
                                        id='url'
                                    />
                                </Col>
                                <Col xs={2} className='mt-4'>
                                    <Button className='btn-submit'>
                                        Confirmar Link
                                    </Button>
                                </Col>
                            </Row>
                        </AvForm>

                        <h4>ou</h4>
                    </>
                )}
                <UploadOrderForm
                    files={files}
                    setFiles={setFiles}
                    dropzoneActive={dropzoneActive}
                    handleUploaderDrop={handleUploaderDrop}
                    handleUploaderDragEnter={handleUploaderDragEnter}
                    handleUploaderDragLeave={handleUploaderDragLeave}
                    handleCropConfirmClick={handleCropConfirmClick}
                    selectedDocument={props.selectedDocument}
                    setOrderItemsList={setOrderItemsList}
                    orderItemsList={orderItemsList}
                    isDocument={isDocument}
                    documentType={isRawFile ? 'RAW_FILE' : documentType}
                    uploaderClassName={props.uploaderClassName}
                />
            </section>
            <ModalImage
                showModal={showModal}
                loadingCrop={loadingCrop}
                handleCropModalToggle={handleCropModalToggle}
                handleCropConfirmClick={handleCropConfirmClick}
                cropFileUrl={cropFileUrl}
                handleCropChange={handleCropChange}
                loading={props.loading}
            />
            <ModalFile
                showModalFile={showModalFile}
                loadingCrop={loadingCrop}
                handleXmlCancelUploadClick={handleXmlCancelUploadClick}
                handleXmlConfirmUploadClick={handleXmlConfirmUploadClick}
                loading={props.loading}
            />
        </>
    );
}

export default withRouter(injectIntl(FileUploadForm));
