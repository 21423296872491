import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    sendDocumentConfirmText: {
        id: 'nfe.sendDocumentConfirmText',
        defaultMessage:
            'Após o envio do documento as informações não poderão mais ser alteradas. Deseja realizar essa operação?',
    },
    confirmSend: {
        id: 'nfe.confirmSend',
        defaultMessage: 'Confirmar Envio',
    },

    justification: {
        id: 'documents.justification',
        defaultMessage: 'Motivo:',
    },
    closeModal: {
        id: 'documents.closeModal',
        defaultMessage: 'Fechar',
    },
    canJustificationError: {
        id: 'documents.canJustificationError',
        defaultMessage: 'Excedido o limite máximo de 600 caracteres.',
    },
});
