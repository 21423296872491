import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as actions from '../../store/actions/index';

import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import Tabs from '../../components/Tabs/Tabs';
import SettingsAccountForm from '../../components/SettingsAccountForm/SettingsAccountForm';
import SettingsEmailNotifyForm from '../../components/SettingsEmailNotifyForm/SettingsEmailNotifyForm';

import messages from './SettingsPage.intl';
import { AuthContext } from '../../context/AuthContext';
import SettingsOrders from '../../components/SettingsOrders/SettingsOrders';
import SettingsNotifications from '../../components/SettingsNotifications/SettingsNotifications';
import SettingsDocumentFormNFS from './pages/SettingsDocumentFormNFS';
import SettingsDocumentFormNFE from './pages/SettingsDocumentFormNFE';
import SettingsDocumentFormCTE from './pages/SettingsDocumentFormCTE';
import SettingsDocumentFormOther from './pages/SettingsDocumentFormOther';
import ModalCities from '../../components/ModalCities/ModalCities';
import SettingsReport from '../../components/SettingsReport/SettingsReport';

export const documentTypeCategories = {
    NFS: "NFS",
    NFE: "NFE",
    CTE: "CTE",
    Others: "Others"
}

class SettingsPage extends Component {
    static contextType = AuthContext;
    state = {
        showModalCities: false,
        showModalNacionalCities: false,
        selectedAllowedCategoriesNFS: [],
        selectedAllowedCategoriesNFE: [],
        selectedAllowedCategoriesCTE: [],
        selectedAllowedCFOPsNFE: [],
        selectedAllowedCFOPsCTE: [],
        selectedAllowedCategoriesOTHER: [],
        selectedNfeEnvironment: null,
        activeTab: 0,
        citiesToMonitor: null,
        nacionalCitiesToMonitor: null,
        selectedSupplier: {
            label: '',
            value: '',
        },
        selectedMap: {
            label: '',
            value: '',
        },
        selectedUser: {
            label: '',
            value: '',
        },
    };

    componentDidMount() {
        const contractorId = this.props.session.contractorId;
        const mustViewReport = this.context.hasClaim('report-view');

        if (this.context.hasClaim('setting-view')) {
            const filter = '?sort=-createdAt&skip=0&take=10&page=0&index=0';

            this.props.onGetEmailToNotify(contractorId);
            this.props.onInitMailBox(filter, contractorId);
            this.props.onInitCategories(contractorId);
            this.props.onInitCFOPs();
            mustViewReport && this.props.onInitScheduledReports();
            this.props.onInitNotifications();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            moduleSettingsNFE,
            moduleSettingsCTE,
            moduleSettingsNFS,
            moduleSettingsOther,
        } = this.props;

        if (
            moduleSettingsCTE &&
            moduleSettingsCTE !== prevProps.moduleSettingsCTE
        ) {
            const selectedAllowedCategoriesCTE = categoriesFormatted(moduleSettingsCTE.allowedCategories);
            const selectedAllowedCFOPsCTE = cfopsFormatted(moduleSettingsCTE.cfopAllowedToIntegrationWhithoutOrders);

            this.setState({
                selectedAllowedCategoriesCTE,
                selectedAllowedCFOPsCTE
            });
        }

        if (
            moduleSettingsNFE &&
            moduleSettingsNFE !== prevProps.moduleSettingsNFE
        ) {
            let valueNfeEnvironment = 0;

            switch (moduleSettingsNFE.environment) {
                case '1':
                    valueNfeEnvironment = { label: 'Produção', value: 1 };
                    break;
                default:
                    valueNfeEnvironment = { label: 'Homologação', value: 2 };
                    break;
            }

            const selectedNfeEnvironment = this.state.selectedNfeEnvironment || valueNfeEnvironment;

            const selectedAllowedCategoriesNFE = categoriesFormatted(moduleSettingsNFE.allowedCategories);
            const selectedAllowedCFOPsNFE = cfopsFormatted(moduleSettingsNFE.cfopAllowedToIntegrationWhithoutOrders);
            
            this.setState({
                selectedAllowedCategoriesNFE,
                selectedAllowedCFOPsNFE,
                selectedNfeEnvironment
            });
        }

        if (
            moduleSettingsNFS &&
            moduleSettingsNFS !== prevProps.moduleSettingsNFS
        ) {
            const selectedAllowedCategoriesNFS = categoriesFormatted(moduleSettingsNFS.allowedCategories);

            this.setState({
                selectedAllowedCategoriesNFS: selectedAllowedCategoriesNFS,
            });
        }

        if (
            moduleSettingsOther &&
            moduleSettingsOther !== prevProps.moduleSettingsOther
        ) {
            const selectedAllowedCategoriesOTHER = categoriesFormatted(moduleSettingsOther.allowedCategories);

            this.setState({
                selectedAllowedCategoriesOTHER: selectedAllowedCategoriesOTHER,
            });
        }

        function categoriesFormatted(categories) {
            const result = categories.map(
                category => {
                    return { label: category.name, value: category.code };
                },
            );

            return result;
        }

        function cfopsFormatted(cfops) {
            const result = cfops?.map(
                cfop => {
                    return { label: `${cfop.code} - ${cfop.description}`, value: cfop.code };
                },
            );

            return result;
        }
    }

    handleChangePasswordFormSubmit = (e, values) => {
        this.props.onChangePassword(
            values.newPassword,
            values.confirmNewPassword,
        );
    };

    handleAllowedCategoriesSelectChange = (e, documentType) => {
        switch (documentType) {
            case documentTypeCategories.NFS:
                this.setState({
                    selectedAllowedCategoriesNFS: e,
                });
                break;
            case documentTypeCategories.NFE:
                this.setState({
                    selectedAllowedCategoriesNFE: e,
                });
                break;
            case documentTypeCategories.CTE:
                this.setState({
                    selectedAllowedCategoriesCTE: e,
                });
                break;
            case documentTypeCategories.Others:
                this.setState({
                    selectedAllowedCategoriesOTHER: e,
                });
                break;
            default:
                break;
        }
    };

    handleAllowedCFOPsSelectChange = (e, documentType) => {
        switch (documentType) {
            case documentTypeCategories.NFE:
                this.setState({
                    selectedAllowedCFOPsNFE: e,
                });
                break;
            case documentTypeCategories.CTE:
                this.setState({
                    selectedAllowedCFOPsCTE: e,
                });
                break;
            default:
                break;
        }
    };

    handleSelectedNfeEnvironment = value => {
        this.setState({
            selectedNfeEnvironment: value,
        });
    };

    handleFormSubmit = (event, values) => {
        const { settingsEmailToNotify, inboundSettings } = this.props;
        const { selectedSupplier, selectedMap, selectedUser } = this.state;

        let finalValues = {
            ...values,
            userName: selectedUser.label || settingsEmailToNotify.userName,
        };

        finalValues = values.allowSendDocumentsWithNoMap
            ? {
                ...finalValues,
                defaultSupplierNameToUse:
                    selectedSupplier?.label ||
                    settingsEmailToNotify?.defaultSupplierNameToUse,
                defaultMapNameToUse:
                    selectedMap?.label ||
                    settingsEmailToNotify?.defaultMapNameToUse,
            }
            : {
                ...finalValues,
                defaultSupplierToUse: '',
                defaultSupplierNameToUse: '',
                defaultMapToUse: '',
                defaultMapNameToUse: '',
            };

        if (!!settingsEmailToNotify) {
            this.props.onUpdateEmailToNotify(
                finalValues,
                inboundSettings.settingId,
            );
        } else {
            this.props.onCreateEmailToNotify(finalValues);
        }
    };

    handleSupplierChange = selectedSupplier => {
        this.setState({
            selectedSupplier: {
                label: selectedSupplier.label,
                value: selectedSupplier.value,
            },
        });

        this.setState({
            selectedMap: {
                label: '',
                value: '',
            },
        });
    };

    handleUserChange = selectedUser => {
        this.setState({
            selectedUser: {
                label: selectedUser.label,
                value: selectedUser.value,
            },
        });
    };

    handleMapChange = selectedMap => {
        this.setState({
            selectedMap: {
                label: selectedMap.label,
                value: selectedMap.value,
            },
        });
    };

    handleModuleSettingsSubmit = (event, values) => {
        const { inboundSettings } = this.props;

        const documentType = values.documentType;
        const settingId = inboundSettings.settingId;

        // #region AllowedCategories
        const allowedCategoriesNFS = categoriesFormatted(this.state.selectedAllowedCategoriesNFS);
        const allowedCategoriesNFE = categoriesFormatted(this.state.selectedAllowedCategoriesNFE);
        const allowedCategoriesCTE = categoriesFormatted(this.state.selectedAllowedCategoriesCTE);
        const allowedCategoriesOTHER = categoriesFormatted(this.state.selectedAllowedCategoriesOTHER);

        function categoriesFormatted(categories) {
            const result = categories && categories.map(
                category => {
                    return { code: category.value, name: category.label };
                },
            );

            return result;
        }
        // #endregion

        // #region AllowedCFOP
        const allowedCFOPNFE = cfopsFormatted(this.state.selectedAllowedCFOPsNFE);
        const allowedCFOPCTE = cfopsFormatted(this.state.selectedAllowedCFOPsCTE);

        function cfopsFormatted(cfops) {
            const result = cfops && cfops?.map(
                cfop => {
                    return { code: cfop.value, description: cfop.label };
                },
            );

            return result;
        }
        // #endregion

        const finalValues = values.hideMonitiring
            ? {
                ...values,
                AllowAutomaticSupplierCreation: false,
                EnableMonitoring: false,
                documentNumbersAllowedToGetNfs: '',
            }
            : {
                ...values,
            };

        const receiveDocumentsOnlyAfterDate =
            !!values.receiveDocumentsOnlyAfterDate
                ? new Date(
                      values.receiveDocumentsOnlyAfterDate + 'T00:00:00Z',
                  ).toISOString()
                : null;

        switch (documentType) {
            case 'NFS':
                finalValues.allowedCategories = allowedCategoriesNFS;
                finalValues.citiesToMonitor =
                    this.state.citiesToMonitor ||
                    this.props.moduleSettingsNFS.citiesToMonitor;
                finalValues.citiesToMonitorNacional = this.state.nacionalCitiesToMonitor ||
                    this.props.moduleSettingsNFS.citiesToMonitorNacional;
                finalValues.receiveDocumentsOnlyAfterDate = receiveDocumentsOnlyAfterDate;
                break;
            case 'NFE':
                finalValues.allowedCategories = allowedCategoriesNFE;
                finalValues.CFOPAllowedToIntegrationWhithoutOrders = allowedCFOPNFE;
                finalValues.environment =
                    this.state.selectedNfeEnvironment?.value || 2;
                finalValues.receiveDocumentsOnlyAfterDate = receiveDocumentsOnlyAfterDate;
                break;
            case 'CTE':
                finalValues.allowedCategories = allowedCategoriesCTE;
                finalValues.CFOPAllowedToIntegrationWhithoutOrders = allowedCFOPCTE;
                finalValues.receiveDocumentsOnlyAfterDate = receiveDocumentsOnlyAfterDate;
                break;
            case 'Other':
                finalValues.allowedCategories = allowedCategoriesOTHER;
                break;
        }

        delete values.documentType;
        delete values.hideMonitiring;
        delete finalValues.documentType;
        delete finalValues.hideMonitiring;

        this.props.onUpdateModuleSettings(finalValues, settingId, documentType);
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleOrdersSubmit = (event, values) => {
        const settingId = this.props.inboundSettings.settingId;

        this.props.onUpdateOrdersSettings(settingId, values);
    };

    handleToggleModalCities = () => {
        if (!this.state.showModalCities) {
            this.props.onGetEnabledCities();
            this.props.onInitStates();
        }
        this.setState({
            showModalCities: !this.state.showModalCities,
        });
    };

    handleCitiesSubmit = (e, values) => {
        const settingId = this.props.inboundSettings.settingId;
        const contractorId = this.props.inboundSettings.contractorId;
        const cities = [];

        for (var key in this.props.checkedCities) {
            cities.push(key);
        }
        this.setState({
            citiesToMonitor: cities,
            showModalCities: false,
        });

        this.props.onUpdateCitiesToMonitor(settingId, cities, contractorId);
    };

    handleCityChange = e => {
        const value = this.props.checkedCities[e.target.id];

        if (value) {
            this.props.onChangeSelectedCities(e.target.id, false);
        } else {
            this.props.onChangeSelectedCities(e.target.id, true);
        }
    };

    handleChangeAllCities = (cities) => {
        this.props.onChangeAllCities(cities)
    };

    handleChangeAllCitiesNacional = (cities) => {
        this.props.onChangeAllCitiesNacional(cities)
    };

    handleToggleModalNacionalCities = () => {
        if (!this.state.showModalNacionalCities) {
            this.props.onGetEnabledNacionalCities();
            this.props.onInitStates();
        }
        this.setState({
            showModalNacionalCities: !this.state.showModalNacionalCities,
        });
    };

    handleNacionalCitiesSubmit = (e, values) => {
        const settingId = this.props.inboundSettings.settingId;
        const contractorId = this.props.inboundSettings.contractorId;
        const cities = [];

        for (var key in this.props.checkedNacionalCities) {
            cities.push(key);
        }
        this.setState({
            citiesToMonitor: cities,
            showModalNacionalCities: false,
        });

        this.props.onUpdateNacionalCitiesToMonitor(settingId, cities, contractorId);
    };

    handleNacionalCityChange = e => {
        const value = this.props.checkedNacionalCities[e.target.id];

        if (value) {
            this.props.onChangeSelectedNacionalCities(e.target.id, false);
        } else {
            this.props.onChangeSelectedNacionalCities(e.target.id, true);
        }
    };

    handleReportsSettingsSubmit = (event, values) => {
        const settingId = this.props.inboundSettings.settingId;

        this.props.onUpdateReportsSettings(settingId, values);
    };

    handleNotificationsSettingsSubmit = (values) => {
        const settingId = this.props.inboundSettings.settingId;
        this.props.onChangeEnabledNotifications(settingId, values);
    };

    render() {
        const {
            intl,
            loadingList,
            settingsEmailToNotify,
            inboundSettings,
            moduleSettingsNFS,
            moduleSettingsNFE,
            moduleSettingsCTE,
            moduleSettingsOther,
            ordersSettings,
            notificationsSettings,
            notifications,
        } = this.props;

        const {
            selectedAllowedCategoriesNFS,
            selectedAllowedCategoriesNFE,
            selectedAllowedCategoriesCTE,
            selectedAllowedCategoriesOTHER,
            selectedAllowedCFOPsNFE,
            selectedAllowedCFOPsCTE,
        } = this.state;

        const mustViewSettingsEmailForm = this.context.hasClaim('setting-view');
        const mustViewDocumentForm = this.context.hasClaim('setting-view-aba');
        const mustViewReport = this.context.hasClaim('report-view');
        const allowedCategories = this.props.categories.map(category => {
            return { label: category.name, value: category.code };
        });
        const allowedCFOPs = this.props.cfops?.map(cfop => {
            return { label: `${cfop.code} - ${cfop.description}`, value: cfop.code };
        });

        let tabs = [];

        if (mustViewSettingsEmailForm) {
            tabs.push({
                name: intl.formatMessage(messages.documentsUpload),
                component: (
                    <SettingsEmailNotifyForm
                        handleFormSubmit={this.handleFormSubmit}
                        settingsEmailToNotify={settingsEmailToNotify}
                        inboundSettings={inboundSettings}
                        sessionContractor={this.props.session.contractor}
                        mailBoxes={this.props.mailBoxes}
                        handleSupplierChange={this.handleSupplierChange}
                        handleUserChange={this.handleUserChange}
                        handleMapChange={this.handleMapChange}
                        selectedSupplier={this.state.selectedSupplier}
                    />
                ),
            });

            if (mustViewDocumentForm) {
                tabs.push({
                    name: intl.formatMessage(messages.nfs),
                    component: (
                        <SettingsDocumentFormNFS
                            handleFormSubmit={this.handleModuleSettingsSubmit}
                            documentType={'NFS'}
                            title={intl.formatMessage(messages.nfs)}
                            titleNacional={intl.formatMessage(messages.nfsNacional)}
                            moduleSettings={moduleSettingsNFS}
                            allowedCategories={allowedCategories}
                            selectedAllowedCategories={
                                selectedAllowedCategoriesNFS
                            }
                            handleAllowedCategoriesSelectChange={
                                this.handleAllowedCategoriesSelectChange
                            }
                            handleOpenCities={this.handleToggleModalCities}
                            handleOpenNacionalCities={this.handleToggleModalNacionalCities}
                            cities={moduleSettingsNFS?.citiesToMonitor}
                            nacionalCities={moduleSettingsNFS?.citiesToMonitorNacional}
                        />
                    ),
                });
                tabs.push({
                    name: intl.formatMessage(messages.nfe),
                    component: (
                        <SettingsDocumentFormNFE
                            handleFormSubmit={this.handleModuleSettingsSubmit}
                            documentType={'NFE'}
                            title={intl.formatMessage(messages.nfe)}
                            moduleSettings={moduleSettingsNFE}
                            allowedCategories={allowedCategories}
                            selectedAllowedCategories={
                                selectedAllowedCategoriesNFE
                            }
                            handleAllowedCategoriesSelectChange={
                                this.handleAllowedCategoriesSelectChange
                            }
                            allowedCFOPs={allowedCFOPs}
                            selectedAllowedCFOPs={
                                selectedAllowedCFOPsNFE
                            }
                            handleAllowedCFOPsSelectChange={
                                this.handleAllowedCFOPsSelectChange
                            }
                            selectedNfeEnvironment={
                                this.state.selectedNfeEnvironment
                            }
                            handleSelectedNfeEnvironment={
                                this.handleSelectedNfeEnvironment
                            }
                        />
                    ),
                });
                tabs.push({
                    name: intl.formatMessage(messages.cte),
                    component: (
                        <SettingsDocumentFormCTE
                            handleFormSubmit={this.handleModuleSettingsSubmit}
                            documentType={'CTE'}
                            title={intl.formatMessage(messages.cte)}
                            moduleSettings={moduleSettingsCTE}
                            allowedCategories={allowedCategories}
                            selectedAllowedCategories={
                                selectedAllowedCategoriesCTE
                            }
                            handleAllowedCategoriesSelectChange={
                                this.handleAllowedCategoriesSelectChange
                            }
                            allowedCFOPs={allowedCFOPs}
                            selectedAllowedCFOPs={
                                selectedAllowedCFOPsCTE
                            }
                            handleAllowedCFOPsSelectChange={
                                this.handleAllowedCFOPsSelectChange
                            }
                        />
                    ),
                });
                tabs.push({
                    name: intl.formatMessage(messages.other),
                    component: (
                        <SettingsDocumentFormOther
                            handleFormSubmit={this.handleModuleSettingsSubmit}
                            documentType={'Other'}
                            title={intl.formatMessage(messages.other)}
                            moduleSettings={moduleSettingsOther}
                            allowedCategories={allowedCategories}
                            selectedAllowedCategories={
                                selectedAllowedCategoriesOTHER
                            }
                            handleAllowedCategoriesSelectChange={
                                this.handleAllowedCategoriesSelectChange
                            }
                        />
                    ),
                });
            }

            tabs.push({
                name: intl.formatMessage(messages.orders),
                component: (
                    <SettingsOrders
                        handleFormSubmit={this.handleOrdersSubmit}
                        ordersSettings={ordersSettings}
                    />
                ),
            });

            mustViewReport &&
                tabs.push({
                    name: intl.formatMessage(messages.reportScheduled),
                    component: (
                        <SettingsReport
                            handleFormSubmit={this.handleReportsSettingsSubmit}
                            scheduledReports={this.props.scheduledReports}
                            reportSettings={this.props.reportScheduled}
                        />
                    ),
                });
        }

        tabs.push({
            name: intl.formatMessage(messages.notifications),
            component: (
                <SettingsNotifications
                    handleFormSubmit={this.handleNotificationsSettingsSubmit}
                    notificationSettings={notificationsSettings}
                    notifications={notifications}
                />
            ),
        });

        tabs.push({
            name: intl.formatMessage(messages.account),
            component: (
                <SettingsAccountForm
                    handleChangePasswordFormSubmit={
                        this.handleChangePasswordFormSubmit
                    }
                />
            ),
        });

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: 'Dashboard',
                            active: false,
                        },
                        {
                            path: '/settings',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header title={intl.formatMessage(messages.title)} />
                    </section>
                </header>
                <section className='content-middle bg-content mt-2'>
                    <Loading loading={loadingList} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={tabs}
                    />
                </section>
                <ModalCities
                    isOpen={this.state.showModalCities}
                    modalTitle={intl.formatMessage(messages.registerCities)}
                    handleToggle={this.handleToggleModalCities}
                    handleFormSubmit={this.handleCitiesSubmit}
                    states={this.props.states}
                    enabledCities={this.props.enabledCities}
                    checkedCities={this.props.checkedCities}
                    handleCityChange={this.handleCityChange}
                    handleChangeAllCities={this.handleChangeAllCities}
                    loadingCities={this.props.loadingCities}
                />
                <ModalCities
                    isOpen={this.state.showModalNacionalCities}
                    modalTitle={intl.formatMessage(messages.registerCities)}
                    handleToggle={this.handleToggleModalNacionalCities}
                    handleFormSubmit={this.handleNacionalCitiesSubmit}
                    states={this.props.states}
                    enabledCities={this.props.enabledNacionalCities}
                    checkedCities={this.props.checkedNacionalCities}
                    handleCityChange={this.handleNacionalCityChange}
                    handleChangeAllCities={this.handleChangeAllCitiesNacional}
                    loadingCities={this.props.loadingCities}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        settingsEmailToNotify: state.settingsReducer.settingsEmailToNotify,
        inboundSettings: state.settingsReducer.inboundSettings,
        ordersSettings: state.settingsReducer.ordersSettings,
        notificationsSettings:
            state.settingsReducer.notificationsSettings || [],
        notifications: state.settingsReducer.notifications || [],
        mailBoxes: state.settingsReducer.mailBoxesCount || 0,
        scheduledReports: state.reportReducer.scheduledReports || [],
        loadingList: state.settingsReducer.loadingList,
        enabledCities: state.settingsReducer.enabledCities || [],
        checkedCities: state.settingsReducer.checkedCities || [],
        enabledNacionalCities: state.settingsReducer.enabledNacionalCities || [],
        checkedNacionalCities: state.settingsReducer.checkedNacionalCities || [],
        loadingCities: state.settingsReducer.loadingCities,
        //modules
        moduleSettingsNFS: state.settingsReducer.moduleSettingsNFS,
        moduleSettingsNFE: state.settingsReducer.moduleSettingsNFE,
        moduleSettingsCTE: state.settingsReducer.moduleSettingsCTE,
        moduleSettingsOther: state.settingsReducer.moduleSettingsOther,
        states: state.stateReducer.states,
        reportScheduled: state.settingsReducer.reportScheduled,
        //session
        session: state.authenticationReducer.session,
        categories: state.categoryReducer.categories || {},
        cfops: state.cfopReducer.cfopsList || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePassword: (newPassword, confirmNewPassword) =>
            dispatch(
                actions.changePassword({
                    newPassword,
                    confirmNewPassword,
                }),
            ),
        onChangeSelectedCities: (city, checked) =>
            dispatch(actions.changeSelectedCities(city, checked)),

        onChangeSelectedNacionalCities: (city, checked) =>
            dispatch(actions.changeSelectedNacionalCities(city, checked)),

        onChangeAllCities: (cities) =>
            dispatch(actions.setCheckedCities(cities)),

        onChangeAllCitiesNacional: (cities) =>
            dispatch(actions.setCheckedCitiesNacional(cities)),

        //EmailToNotify
        onGetEmailToNotify: contractorId =>
            dispatch(actions.getEmailToNotify(contractorId)),
        onUpdateEmailToNotify: (emailToNotifyUpdate, settingId) =>
            dispatch(
                actions.updateEmailToNotify(emailToNotifyUpdate, settingId),
            ),
        onCreateEmailToNotify: emailToNotify =>
            dispatch(actions.createEmailToNotify(emailToNotify)),
        onInitMailBox: (filter, contractorId) =>
            dispatch(actions.initMailBox(filter, contractorId)),

        // ModuleSettings
        onUpdateModuleSettings: (moduleSettings, settingId, documentType) =>
            dispatch(
                actions.updateModuleSettings(
                    moduleSettings,
                    settingId,
                    documentType,
                ),
            ),

        //SettingsOrders
        onUpdateOrdersSettings: (settingId, values) =>
            dispatch(actions.updateOrdersSettings(settingId, values)),
        onUpdateCitiesToMonitor: (settingId, citiesToMonitor, contractorId) =>
            dispatch(actions.updateCitiesToMonitor(settingId, citiesToMonitor, contractorId)),

        onUpdateNacionalCitiesToMonitor: (settingId, citiesToMonitor, contractorId) =>
            dispatch(actions.updateCitiesToMonitorNacional(settingId, citiesToMonitor, contractorId)),
        onGetEnabledCities: () => dispatch(actions.getEnabledCities()),
        onGetEnabledNacionalCities: () => dispatch(actions.getEnabledCitiesNacional()),
        onInitStates: () => dispatch(actions.initStates()),

        //SettingsReports
        onUpdateReportsSettings: (settingId, values) =>
            dispatch(actions.updateReportsSettings(settingId, values)),

        onChangeEnabledNotifications: (settingId, values) =>
            dispatch(actions.updateNotifications(settingId, values)),
        onInitScheduledReports: () => dispatch(actions.initScheduledReports()),
        onInitNotifications: () => dispatch(actions.initNotifications()),

        onInitCategories: contractorId =>
            dispatch(actions.initCategories(contractorId)),

        onInitCFOPs: _ => dispatch(actions.initCfops()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(SettingsPage));
