import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    suppliersTitle: {
        id: "suppliers.title",
        defaultMessage: "Fornecedores"
    },

    contractorsTitle: {
        id: "contractors.title",
        defaultMessage: "Contratantes"
    }

});