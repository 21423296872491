import React from 'react';
// import PropTypes from "prop-types";
import { injectIntl } from 'react-intl';
import Dropzone, { useDropzone } from 'react-dropzone';

import { Row, Col, Card } from 'reactstrap';

import messages from './Uploader.intl';
import uploadImage from '../../assets/images/upload.png';
import Loading from '../../components/Loading/Loading';

import './Uploader.css';
import { toast } from 'react-toastify';

const uploader = props => {
    const { intl, multiple = false, files } = props;

    const dropzoneRef = React.createRef();

    const handleDragReject = () => {
        toast.error(intl.formatMessage(messages.rejectFile));
    };

    const handleDragEnter = e => {
        if (e.target.classList.contains('card')) {
            e.target.classList.add('drag-active');
        }
    };

    const handleDragLeave = e => {
        if (e.target.classList.contains('card')) {
            e.target.classList.remove('drag-active');
        }
    };

    let cardClasses = ['mt-3', 'upload-card', props.className];

    if (props.isLoading) {
        cardClasses.push('loading');
    }

    return (
        <>
            <Dropzone
                style={{ position: 'relative' }}
                accept={props.fileType}
                ref={dropzoneRef}
                onDrop={props.onDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDropRejected={handleDragReject}
                multiple={multiple}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <Card className={cardClasses.join(' ')}>
                            <Row
                                className='mt-auto text-center'
                                style={{
                                    display: props.isLoading ? 'none' : '',
                                }}
                            >
                                <Col xs={12}>
                                    <img src={uploadImage} alt='upload' />
                                    {files.length > 0 ? (
                                        files.map((file, index) => {
                                            if (index > 4) {
                                                return;
                                            }

                                            return (
                                                <p
                                                    className='upload-text'
                                                    key={index}
                                                >
                                                    {file.name} - {file.size}{' '}
                                                    bytes
                                                </p>
                                            );
                                        })
                                    ) : (
                                        <p className='upload-text'>
                                            {intl.formatMessage(
                                                messages.dropFile,
                                            )}
                                        </p>
                                    )}
                                    {files.length > 5 && (
                                        <p className='upload-text'>
                                            e mais outros {files.length - 5}{' '}
                                            arquivos
                                        </p>
                                    )}
                                </Col>
                            </Row>
                            <Row
                                className='mb-auto text-center'
                                style={{
                                    display: props.isLoading ? 'none' : '',
                                }}
                            >
                                <Col xs={12}>
                                    <input {...getInputProps()} />
                                </Col>
                            </Row>
                            <Row
                                className='text-center loading-text mb-auto mt-auto'
                                style={{
                                    display: !props.isLoading ? 'none' : '',
                                }}
                            >
                                <Col xs={12}>
                                    <Loading loading={props.isLoading} />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                )}
            </Dropzone>
        </>
    );
};

export default injectIntl(uploader);
