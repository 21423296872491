import React, { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import CurrencyInput from 'react-currency-input-field';
import { Row, Col, FormGroup } from 'reactstrap';

import messages from './OrderDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';
import EvtSelect from '../EvtSelect/EvtSelect';

function OrderDetailsForm(props) {
    const context = useContext(AuthContext);

    const {
        intl,
        isNew,
        order,
        sessionContractorId,
        selectedPartnersSuppliers,
    } = props;

    const canCreateOrder = context.hasClaim('order-create') && isNew;
    const canUpdateOrder = context.hasClaim('order-update') && !isNew;
    const isReadOnly = !(canCreateOrder || canUpdateOrder);

    const [showContractorsSelect, setShowContractorsSelect] = useState(false);
    const [showSuppliersSelect, setShowSuppliersSelect] = useState(false);
    const [orderValue, setOrderValue] = useState(undefined);
    const [requesters, setRequesters] = useState([
        { name: '', email: '', id: '0' },
    ]);

    useEffect(() => {
        const orderRequester = order?.requesters || [];

        if (orderRequester.length > 0) {
            setRequesters(
                orderRequester.map((requester, index) => {
                    return {
                        ...requester,
                        id: index,
                    };
                }),
            );
        }
    }, [order]);

    useEffect(() => {
        if (context.claims.length === 0) return;

        if (context.hasClaim('role-level-full')) {
            setShowContractorsSelect(true);
            setShowSuppliersSelect(true);
        } else if (context.hasClaim('role-level-contractor')) {
            setShowContractorsSelect(false);
            setShowSuppliersSelect(true);
        } else if (context.hasClaim('role-level-supplier')) {
            setShowContractorsSelect(false);
            setShowSuppliersSelect(false);
        }
    }, [context]);

    var contractorsOption = new Map();
    contractorsOption.set(
        order && order?.contractorId,
        order && order?.contractorName,
    );

    var suppliersOption = new Map();
    suppliersOption.set(
        order && order?.supplierId,
        order && order?.supplierName,
    );

    function currencyFormat(state, value) {
        const newValue = !!state ? state.replace(',', '.') : value;
        return newValue;
    }

    const removeRequester = itemId => {
        setRequesters(requesters.filter(requester => requester.id !== itemId));
    };

    const addRequester = () => {
        setRequesters([
            ...requesters,
            { name: '', email: '', id: requesters.length },
        ]);
    };

    const handleRequesterChange = (event, id, field) => {
        const value = event.target.value || '';

        let requesterIndex = requesters.findIndex(
            requester => requester.id === id,
        );

        setRequesters(prev => {
            switch (field) {
                case 'name':
                    prev[requesterIndex].name = value;
                    break;
                case 'email':
                    prev[requesterIndex].email = value;
                    break;
                default:
                    break;
            }

            return prev;
        });
    };

    return !!order || isNew ? (
        <AvForm
            onValidSubmit={(event, values) => {
                props.handleSubmit(event, {
                    ...values,
                    requesters,
                    value: currencyFormat(orderValue, order?.value),
                });
            }}
        >
            <Row>
                <Col lg={6} md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.description)}
                            type='text'
                            name='description'
                            id='description'
                            value={order?.description || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup check className=' py-3 px-5 required-field'>
                        <AvField
                            label={intl.formatMessage(messages.isAvailable)}
                            type='checkbox'
                            name='isAvailable'
                            id='isAvailable'
                            value={order?.isAvailable}
                            checked={order?.isAvailable}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={3} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.orderName)}
                            type='text'
                            name='erpOrderCode'
                            id='erpOrderCode'
                            value={order?.erpOrderCode || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.measureItem)}
                            type='text'
                            name='erpServiceSheetCode'
                            id='erpServiceSheetCode'
                            value={order?.erpServiceSheetCode || ''}
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <FormGroup>
                        <label for='value'>
                            {intl.formatMessage(messages.totalGrossValue)}
                        </label>
                        <CurrencyInput
                            className='is-touched is-pristine av-valid form-control'
                            defaultValue={isNew ? undefined : order?.value}
                            allowNegativeValue={false}
                            prefix='R$'
                            decimalSeparator=','
                            groupSeparator='.'
                            decimalScale={2}
                            decimalsLimit={2}
                            type='text'
                            name='value'
                            id='value'
                            disabled={isReadOnly}
                            errorMessage=' '
                            onValueChange={value => setOrderValue(value)}
                        />
                    </FormGroup>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.currency)}
                            type='text'
                            name='currency'
                            id='currency'
                            value={order?.currency || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    {showSuppliersSelect &&
                        (!isReadOnly ? (
                            <EvtSelect
                                name='supplierId'
                                label={intl.formatMessage(messages.supplier)}
                                options={suppliersOption}
                                url={
                                    props.selectedContractor
                                        ? `/Contractors/${
                                              props.selectedContractor
                                                  .selectedValueOption
                                          }/Suppliers`
                                        : `/Contractors/${order?.contractorId ||
                                              sessionContractorId}/Suppliers`
                                }
                                handleSelectedOptionChange={
                                    props.handleSuppliersChange
                                }
                                labelKey='displayName'
                                labelKey2='documentNumber1'
                                valueKey='supplierId'
                                key={
                                    props.selectedContractor &&
                                    props.selectedContractor.selectedValueOption
                                }
                                async
                                required
                                option={
                                    order && {
                                        selectedValueOption: props.selectedSupplier
                                            ? props.selectedSupplier
                                                  .selectedValueOption
                                            : order && order?.supplierId,
                                        selectedLabelOption: props.selectedSupplier
                                            ? props.selectedSupplier
                                                  .selectedLabelOption
                                            : order &&
                                              order?.supplierName +
                                                  (order?.supplierCNPJ &&
                                                      ` - ${
                                                          order?.supplierCNPJ
                                                      }`),
                                    }
                                }
                            />
                        ) : (
                            <FormGroup>
                                <AvField
                                    type='text'
                                    name='supplierId'
                                    id='supplierId'
                                    disabled={isReadOnly}
                                    label={intl.formatMessage(
                                        messages.supplier,
                                    )}
                                    value={
                                        order?.supplierName
                                            ? order?.supplierName +
                                              (order?.supplierCNPJ &&
                                                  ` - ${order?.supplierCNPJ}`)
                                            : ''
                                    }
                                    errorMessage=' '
                                    required
                                />
                            </FormGroup>
                        ))}
                </Col>
                <Col md={6}>
                    <EvtSelect
                        name='partnersSuppliers'
                        label={intl.formatMessage(messages.partnerSupplier)}
                        options={suppliersOption}
                        url={
                            props.selectedContractor
                                ? `/Contractors/${
                                      props.selectedContractor
                                          .selectedValueOption
                                  }/Suppliers`
                                : `/Contractors/${order?.contractorId ||
                                      sessionContractorId}/Suppliers`
                        }
                        handleSelectedOptionChange={
                            props.handlePartnerSuppliersChange
                        }
                        labelKey='displayName'
                        labelKey2='documentNumber1'
                        valueKey='supplierId'
                        key={
                            props.selectedContractor &&
                            props.selectedContractor.selectedValueOption
                        }
                        async
                        isMulti
                        disabled={isReadOnly}
                        required={false}
                        option={
                            selectedPartnersSuppliers && {
                                isMultiValues: selectedPartnersSuppliers || [],
                            }
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    {showContractorsSelect &&
                        (!isReadOnly ? (
                            <EvtSelect
                                name='contractorId'
                                label={intl.formatMessage(messages.company)}
                                options={contractorsOption}
                                url='/Contractors'
                                labelKey='displayName'
                                valueKey='contractorId'
                                handleSelectedOptionChange={
                                    props.handleContractorsChange
                                }
                                key={order && order?.contractorId}
                                async
                                required
                                option={
                                    order && {
                                        selectedValueOption:
                                            order && order?.contractorId,
                                        selectedLabelOption:
                                            order && order?.contractorName,
                                    }
                                }
                            />
                        ) : (
                            <FormGroup>
                                <AvField
                                    type='text'
                                    name='contractorId'
                                    id='contractorId'
                                    disabled={isReadOnly}
                                    label={intl.formatMessage(messages.company)}
                                    value={order?.contractorName}
                                    errorMessage=' '
                                    required
                                />
                            </FormGroup>
                        ))}
                </Col>
            </Row>
            <h5 className='title-area-form' style={{ marginTop: 50 }}>
                {' '}
                Contato do Requisitante
            </h5>
            {requesters.map(requester => (
                <Row>
                    <Col lg={1} md={1}>
                        <button
                            type='button'
                            className='removeRequesterButton'
                            onClick={() => removeRequester(requester?.id)}
                        >
                            -
                        </button>
                    </Col>
                    <Col lg={5} md={5}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.requesterName,
                                )}
                                type='text'
                                name='requesterName'
                                id={requester.id + '-name'}
                                value={requester?.name || ''}
                                onChange={event =>
                                    handleRequesterChange(
                                        event,
                                        requester.id,
                                        'name',
                                    )
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.requesterEmail,
                                )}
                                type='text'
                                name='requesterEmail'
                                id={requester.id + '-email'}
                                value={requester?.email || ''}
                                onChange={event =>
                                    handleRequesterChange(
                                        event,
                                        requester.id,
                                        'email',
                                    )
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            ))}
            <button type='button' onClick={() => addRequester()}>
                +
            </button>
            <Row />
            <h5 className='title-area-form' style={{ marginTop: 50 }}>
                Contato do Comprador
            </h5>
            <Row>
                <Col lg={6} md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.buyerName)}
                            type='text'
                            name='buyerName'
                            id='buyerName'
                            value={order?.buyerName || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6} md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.buyerEmail)}
                            type='text'
                            name='buyerEmail'
                            id='buyerEmail'
                            value={order?.buyerEmail || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    {!isReadOnly && (
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    )}
                </Col>
            </Row>
        </AvForm>
    ) : (
        <div />
    );
}

export default injectIntl(OrderDetailsForm);
