import axios from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import * as actionTypes from './actionTypes';

export const initOrders = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDERS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Orders${filter}`)
            .then(response => {
                dispatch({
                    type: actionTypes.ORDERS_GET_LIST_SUCCESS,
                    orders: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDERS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initOrdersCount = filter => {
    return async dispatch => {
        dispatch({
            type: actionTypes.ORDERS_GET_COUNT_REQUEST,
        });
        await axios
            .get(`/Orders/Count${filter}`)
            .then(response => {
                dispatch({
                    type: actionTypes.ORDERS_GET_COUNT_SUCCESS,
                    ordersCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initOrderDetails = orderId => {
    return (dispatch, getState) => {
        if (orderId === 'new') {
            dispatch({
                type: actionTypes.ORDER_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.ORDER_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Orders/${orderId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.ORDER_GET_DETAILS_SUCCESS,
                        order: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.ORDER_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const initOrderDocuments = (orderId, query) => {
    return dispatch => {
        if (orderId === 'new') {
            dispatch({
                type: actionTypes.ORDER_DOCUMENTS_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.ORDER_DOCUMENTS_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Orders/${orderId}/Documents` + query)
                .then(function(response) {
                    response.data &&
                        response.data.forEach(document => {
                            const form = document.form || {};
                            const fields = form.fields || [];

                            const numbers = fields.filter(
                                field => field.key === 'invoiceNumber',
                            );

                            if (numbers.length > 0) {
                                document.invoiceNumber = numbers[0].value || '';
                            } else {
                                document.invoiceNumber = '';
                            }
                        });

                    dispatch({
                        type: actionTypes.ORDER_DOCUMENTS_GET_DETAILS_SUCCESS,
                        documents: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.ORDER_DOCUMENTS_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const initOrderDocumentsCount = (orderId, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDER_DOCUMENTS_GET_COUNT_REQUEST,
        });
        axios
            .get(`/Orders/${orderId}/Documents/Count` + query)
            .then(function(response) {
                dispatch({
                    type: actionTypes.ORDER_DOCUMENTS_GET_COUNT_SUCCESS,
                    documentsCount: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.ORDER_DOCUMENTS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const createOrder = values => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDER_POST_REQUEST,
        });

        axios
            .post(`/Orders`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.ORDER_POST_SUCCESS,
                    orderId: response.data,
                });

                toast.success('Pedido criado com sucesso');
                dispatch(push('/orders'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.ORDER_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateOrder = (orderId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDER_PUT_REQUEST,
        });

        axios
            .put(`/Orders/${orderId}`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.ORDER_PUT_SUCCESS,
                });
                toast.success('Pedido alterado com sucesso');
                dispatch(push('/orders'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.ORDER_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const initOrderItems = (orderId, filter) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDERS_ITEM_GET_LIST_REQUEST,
        });

        axios
            .get(`/Orders/${orderId}/Items` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.ORDERS_ITEM_GET_LIST_SUCCESS,
                    orderItems: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDERS_ITEM_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initOrderItemsActive = (orderId, filter) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDERS_ITEM_GET_LIST_REQUEST,
        });

        axios
            .get(`/Orders/${orderId}/Items/Active` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.ORDERS_ITEM_GET_LIST_SUCCESS,
                    orderItems: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDERS_ITEM_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initOrderItemsCount = (orderId, filter) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDERS_ITEM_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Orders/${orderId}/Items/Count` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.ORDERS_ITEM_GET_COUNT_SUCCESS,
                    orderItemsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDERS_ITEM_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initOrderItemDetails = (orderId, orderItemId) => {
    return dispatch => {
        if (orderItemId === 'new') {
            dispatch({
                type: actionTypes.ORDER_ITEM_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.ORDER_ITEM_GET_DETAILS_REQUEST,
            });
            axios
                .get(`/Orders/${orderId}/Items/${orderItemId}`)
                .then(response => {
                    dispatch({
                        type: actionTypes.ORDER_ITEM_GET_DETAILS_SUCCESS,
                        orderItem: response.data,
                    });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.ORDER_ITEM_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const createOrderItem = (orderId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDER_ITEM_POST_REQUEST,
        });

        axios
            .post(`/Orders/${orderId}/Items`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.ORDER_ITEM_POST_SUCCESS,
                });
                toast.success('Item criado com sucesso');
                dispatch(push(`/orders/${orderId}`));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDER_ITEM_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateOrderItem = (orderId, orderItemId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDER_ITEM_PUT_REQUEST,
        });

        axios
            .put(`/Orders/${orderId}/Items/${orderItemId}`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.ORDER_ITEM_PUT_SUCCESS,
                });
                toast.success('Item alterado com sucesso');
                dispatch(push(`/orders/${orderId}`));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDER_ITEM_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteOrderItem = (orderId, orderItemId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ORDER_ITEM_PUT_REQUEST,
        });

        axios
            .delete(`/Orders/${orderId}/Items/${orderItemId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.ORDER_ITEM_PUT_SUCCESS,
                });
                toast.success('Item excluído com sucesso');
                dispatch(push(`/orders/${orderId}`));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDER_ITEM_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const initOrderItemsBySupplier = (
    supplierId,
    filter,
    ordersToFilter,
) => {
    return (dispatch, getState) => {
        if (supplierId === null) return;

        dispatch({
            type: actionTypes.ORDERS_BY_SUPPLIER_GET_LIST_REQUEST,
        });

        axios
            .get(`/Suppliers/${supplierId}/OrderItems` + `${filter}`)
            .then(response => {
                const {
                    count,
                    orderItems,
                    listOnlyOrderFromTheDocumentTakerForAssociation,
                } = response.data;

                const documentOrders =
                    ordersToFilter || getState().documentReducer.ordersDocument;

                const orderItemsBySupplier = orderItems.filter(
                    ({ orderItemId: idBySupplier }) =>
                        !documentOrders.some(
                            ({ orderItemId: idByDocument }) =>
                                idByDocument === idBySupplier,
                        ),
                );

                dispatch({
                    type: actionTypes.ORDERS_BY_SUPPLIER_GET_LIST_SUCCESS,
                    orderItemsBySupplier,
                    orderItemsBySupplierCount: count,
                    listOnlyOrderFromTheDocumentTakerForAssociation,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ORDERS_BY_SUPPLIER_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const setTabOrderIndex = tabIndex => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TAB_ORDER_INDEX,
            tabIndex,
        });
    };
};
