import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as actions from '../../store/actions/index';

import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import Header from '../../components/Header/Header';
import Tabs from '../../components/Tabs/Tabs';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import SupplierDetailsForm from '../../components/SupplierDetailsForm/SupplierDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './SupplierDetailsPage.intl';
import SupplierMapsForm from '../../components/SupplierMapsForm/SupplierMapsForm';

import { AuthContext } from '../../context/AuthContext';
import EvtSelect from '../../components/EvtSelect/EvtSelect';
class SupplierDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
        showModalConfirmation: false,
        supplier: null,
        selectedState: null,
        selectedCity: null,
        selectedCategoryMap: null,
        query: '',
    };

    componentDidMount() {
        this.reloadSupplier();
    }

    componentWillUnmount() {
        this.props.onCleanSuppliersMaps()
    }

    handleEvTableStateChange = query => {
        const { supplier } = this.props;

        if (supplier) {
            this.setState(
                {
                    query,
                },
                () => {
                    this.reloadSupplier();
                },
            );
        }
    };

    reloadSupplier() {
        const { query } = this.state;
        const supplierId = this.props.match.params.id;

        if (this.props.match.params.id !== 'new') {
            this.props.onInitSupplierDetails(supplierId);
            this.props.onInitStates();
            this.props.onInitCategories();
            this.props.onInitCities();
            if (this.context.hasClaim('supplier-maps-view')) {
                this.props.onInitSupplierMapsCount(supplierId, query);
                this.props.onInitSupplierMaps(supplierId, query);
            }
        } else {
            this.props.onInitSupplierDetails(supplierId);
            this.props.onInitStates();
            this.props.onInitCategories();
            this.props.onInitCities();
        }
    }

    componentDidUpdate(prevProps) {
        const { supplier, selectedMap } = this.props;

        if (supplier && prevProps.supplier !== supplier) {
            this.props.onInitCities(supplier.address?.state);
        }
        if (selectedMap && selectedMap !== prevProps.selectedMap) {
            if (selectedMap.mapId !== prevProps.selectedMap?.mapId) {
                this.props.onAddSupplierMap(selectedMap);
                this.props.onClearMap();
                this.setState({ showModal: !this.state.showModal });
            }
        }
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleSubmit = async (event, values) => {
        if (this.props.loadingDetails) return;

        const { contractorId } = this.context;

        Object.keys(values).length === 0
            ? (values = this.props.supplier)
            : (values.maps = this.props.supplier && this.props.supplier.maps);

        values.maps =
            values.maps &&
            values.maps.map(map => {
                return map.mapId;
            });

        const roleLevelFull = this.context.hasClaim('role-level-full');

        if (!roleLevelFull) {
            values.contractorId = contractorId;
        }

        if (this.props.match.params.id === 'new') {
            await this.props.onCreateSupplier(values);
        } else {
            values.supplierId = this.props.supplier.supplierId;
            await this.props.onUpdateSupplier(values.supplierId, values);
        }
    };

    handleSubmitMaps = async (event, values) => {
        if (this.props.loadingDetails) return;

        const { contractorId } = this.context;

        Object.keys(values).length === 0
            ? (values = this.props.supplier)
            : (values.maps = this.props.supplier && this.props.supplier.maps);

        values.maps =
            values.maps &&
            values.maps.map(map => {
                return map.mapId;
            });

        const roleLevelFull = this.context.hasClaim('role-level-full');

        if (!roleLevelFull) {
            values.contractorId = contractorId;
        }

        if (this.props.match.params.id === 'new') {
            await this.props.onCreateSupplier(values);
        } else {
            values.supplierId = this.props.supplier.supplierId;
            await this.props.onUpdateSupplierMaps(
                values.supplierId,
                values.maps,
            );
        }
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleAddMapModalSubmit = (e, values) => {
        const mapFound = this.props.supplier.maps.find(
            map => map.mapId === values.mapId,
        );

        if (mapFound) {
            toast.error(
                this.props.intl.formatMessage(messages.mapAlreadyAdded),
            );
            return;
        }

        this.props.onInitMapDetails(values.mapId);
    };

    handleDelete = map => {
        this.props.onDeleteSupplierMap(map.mapId);
    };

    handleStateChange = selectedState => {
        const state = this.props.states.find(s => s.uf === selectedState.value);
        this.props.onInitCities(selectedState.value);

        this.setState({
            selectedState: {
                selectedLabelOption: state.name,
                selectedValueOption: state.uf,
            },
        });

        this.setState({
            selectedCity: {
                selectedLabelOption: '',
                selectedValueOption: '',
            },
        });
    };

    handleCityChange = selectedCity => {
        this.setState({
            selectedCity: {
                selectedLabelOption: selectedCity.label,
                selectedValueOption: selectedCity.value,
            },
        });
    };

    handleCategoryMapChange = (selectedCategoryMap) => {
        this.setState({
            selectedCategoryMap: {
                selectedLabelOption: selectedCategoryMap?.label,
                selectedValueOption: selectedCategoryMap?.value,
            },
        });
    };

    handleContractorChange = selectedContractor => {
        if (selectedContractor) {
            this.props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });
        }
    };

    handleToggleModalDelete = () => {
        this.setState({
            showModalConfirmation: !this.state.showModalConfirmation,
        });
    };

    handleDeleteSupplier = async (event, values) => {
        this.setState({
            showModalConfirmation: !this.state.showModalConfirmation,
        });
        await this.props.onDeleteSupplier(this.props.match.params.id);
    };

    maskCategoryFunction = (value, subCategory) => {
        const subCategoryText = !!subCategory ? `(${subCategory})` : '';
        return value === '0' ? null : `${value}${subCategoryText}`;
    };

    render() {
        const { intl, loadingDetails } = this.props;
        const isNew = this.props.match.params.id === 'new' ? true : false;
        const canViewMaps = this.context.hasClaim('supplier-maps-view')
            ? isNew
                ? false
                : true
            : false;
        const categories = this.props.categories;
        const optionsSelect = this.props.maps?.map(map => {
            return {
                value: map.mapId,
                key: map.mapId,
                label: map.name + ' - ' + map.city + ' - ' + map.uf,
            };
        });

        const supplierTabs = [];

        supplierTabs.push({
            name: intl.formatMessage(messages.registrationInfo),
            component: (
                <div>
                    <SupplierDetailsForm
                        key={this.props.supplier}
                        supplier={this.props.supplier}
                        categories={categories}
                        handleSubmit={this.handleSubmit}
                        handleToggleModalDelete={this.handleToggleModalDelete}
                        loading={this.props.loadingDetails}
                        states={this.props.states}
                        cities={this.props.cities}
                        handleStateChange={this.handleStateChange}
                        handleCityChange={this.handleCityChange}
                        handleCategoryMapChange={this.handleCategoryMapChange}
                        handleContractorChange={this.handleContractorChange}
                        contractor={this.props.contractor}
                        selectedCity={this.state.selectedCity}
                        selectedState={this.state.selectedState}
                        selectedCategoryMap={this.state.selectedCategoryMap}
                        isNew={isNew}
                    />
                </div>
            ),
        });
        canViewMaps &&
            supplierTabs.push({
                name: intl.formatMessage(messages.titleMap),
                component: (
                    <div>
                        <SupplierMapsForm
                            handleSubmitMaps={this.handleSubmitMaps}
                            handleDelete={this.handleDelete}
                            handleToggle={this.handleToggleModal}
                            handleEvTableStateChange={
                                this.handleEvTableStateChange
                            }
                            maps={this.props.supplierMaps}
                            isNew={isNew}
                            mapsCount={this.props.supplierMapsCount}
                            loading={this.props.loadingMaps}
                        />
                    </div>
                ),
            });

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/suppliers',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/suppliers/:id',
                            name: intl.formatMessage(messages.titleSupplier, {
                                entity:
                                    this.props.supplier &&
                                    this.props.supplier.displayName,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='row'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleSupplier, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={supplierTabs}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.selectMap)}
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                >
                    <AvForm onValidSubmit={this.handleAddMapModalSubmit}>
                        <Container fluid>
                            <Loading loading={this.props.loadingSelect} />
                            <Row>
                                <Col xs={12} className='text-left mt-3'>
                                    <EvtSelect
                                        name='mapId'
                                        label={intl.formatMessage(
                                            messages.titleMap,
                                        )}
                                        options={optionsSelect}
                                        url={
                                            this.props.supplier?.contractorId &&
                                            `/Contractors/${
                                                this.props.supplier.contractorId
                                            }/Maps`
                                        }
                                        labelKey='documentType'
                                        labelKey2='name'
                                        labelKey3='city'
                                        labelKey4='uf'
                                        labelKey5='fileType'
                                        labelWithMask='categoryType'
                                        labelWithSubMask='subCategory'
                                        maskFunction={this.maskCategoryFunction}
                                        valueKey='mapId'
                                        async
                                        required
                                        take='10'
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12}>
                                    <button className='btn-submit'>
                                        {intl.formatMessage(messages.create)}
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </AvForm>
                </Modal>
                <Modal
                    title={intl.formatMessage(messages.deleteSupplier)}
                    isOpen={this.state.showModalConfirmation}
                    handleToggleModalDelete={this.handleToggleModalDelete}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    toggleFirstButton={this.handleDeleteSupplier}
                    toggleSecondButton={this.handleToggleModalDelete}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        supplier: state.supplierReducer.supplier,
        loadingDetails: state.supplierReducer.loadingDetails,
        loadingMaps: state.supplierReducer.loadingMaps,
        states: state.stateReducer.states,
        cities: state.stateReducer.cities,
        contractor: state.contractorReducer.contractor,
        maps: state.mapReducer.maps,
        loadingSelect: state.mapReducer.loadingViewer,
        supplierMaps: state.supplierReducer.maps || [],
        supplierMapsCount: state.supplierReducer.supplierMapsCount || 0,
        selectedMap: state.mapReducer.selectedMap,
        categories: state.categoryReducer.categories || []        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitSupplierDetails: (companyId, id) =>
            dispatch(actions.initSupplierDetails(companyId, id)),
        onCreateSupplier: values => dispatch(actions.createSupplier(values)),
        onUpdateSupplier: (id, values) =>
            dispatch(actions.updateSupplier(id, values)),
        onUpdateSupplierMaps: (id, mapsIds) =>
            dispatch(actions.updateSupplierMaps(id, mapsIds)),
        onDeleteSupplier: values => dispatch(actions.deleteSupplier(values)),
        onInitStates: () => dispatch(actions.initStates()),
        onInitCities: uf => dispatch(actions.initCities(uf)),
        onInitContractorDetails: id =>
            dispatch(actions.initContractorDetails(id)),
        onInitMapsByContractor: (companyId, filter) =>
            dispatch(actions.initMapsByContractor(companyId, filter)),
        onAddSupplierMap: newMap => dispatch(actions.addSupplierMap(newMap)),
        onUpdateSupplierInput: (property, value) =>
            dispatch(actions.updateSupplierInput(property, value)),
        onDeleteSupplierMap: mapId =>
            dispatch(actions.deleteSupplierMap(mapId)),
        onSetSelectedContractor: selectedContractor =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
        onInitSupplierMapsCount: (supplierId, filter) =>
            dispatch(actions.initSupplierMapsCount(supplierId, filter)),
        onInitSupplierMaps: (supplierId, filter) =>
            dispatch(actions.initSupplierMaps(supplierId, filter)),
        onInitCategories: () => dispatch(actions.initCategories()),
        onInitMapDetails: mapId => dispatch(actions.initMap(mapId)),
        onClearMap: () => dispatch(actions.clearMap()),
        onCleanSuppliersMaps: () => dispatch(actions.cleanSupplierMaps()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SupplierDetailsPage),
);
