import React from 'react';
import { useHistory } from 'react-router';

import { Container, Row, Col } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import { connect } from 'react-redux';
import messages from './ModalMap.intl';

import { injectIntl } from 'react-intl';
import Modal from '../../components/Modal/Modal';

const ModalMap = ({
    intl,
    /* reducer */
    maps,
    /* states */
    orderId = null,
    showMapPicker,
    selectedSupplierId,
    /* functions */
    handleMapPickerToggle,
    handleCleanSupplierPicker,
    handleSubmitMap,
    pathname = null,
}) => {
    const history = useHistory();

    const handleMapPickerSubmit = (e, values) => {
        var mapFull = maps && maps.find(m => m.mapId === values.mapId);
        var origin = 'WEB_UI';
        var hideCrop = mapFull && mapFull.hideCrop;

        history.push({
            pathname: (`/${pathname || 'documents'}`) + '/new',
            state: {
                supplierId: selectedSupplierId,
                mapId: values.mapId,
                orderId,
                origin,
                hideCrop,
                pushUrl: pathname ? `/${pathname}?` : null,
            },
        });

        handleCleanSupplierPicker()
    };

    return (
        <Modal
            title={intl.formatMessage(messages.selectType)}
            isOpen={showMapPicker}
            handleToggle={handleMapPickerToggle}
        >
            <AvForm onValidSubmit={handleSubmitMap || handleMapPickerSubmit}>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <AvField
                                name='mapId'
                                label={intl.formatMessage(messages.labelMaps)}
                                type='select'
                                errorMessage=' '
                                validate={{
                                    required: { value: true },
                                }}
                            >
                                <option value=''>-</option>
                                {maps &&
                                    maps.map(map => {
                                        const mapName = map.name
                                            ? `${map.name}`
                                            : '';
                                        const mapCity = map.city
                                            ? ` - ${map.city}`
                                            : '';
                                        const mapUf = map.uf
                                            ? ` - ${map.uf}`
                                            : '';

                                        const categoryName = `${
                                            map.categoryType
                                        }${
                                            !!map.subCategory
                                                ? `(${map.subCategory})`
                                                : ''
                                        }`;

                                        const categoryText =
                                            map.categoryType !== '0' &&
                                            map.categoryType
                                                ? ` [${categoryName}]`
                                                : '';

                                        const optionName =
                                            mapName +
                                            mapCity +
                                            mapUf +
                                            categoryText;

                                        return (
                                            <option
                                                key={map.mapId}
                                                value={map.mapId}
                                            >
                                                {optionName}
                                            </option>
                                        );
                                    })}
                            </AvField>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col xs={12}>
                            <button className='btn-submit'>
                                {intl.formatMessage(messages.confirmButton)}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        maps: state.supplierReducer.maps,
        categories: state.categoryReducer.categories,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalMap),
);
