import * as actionTypes from '../actions/actionTypes';

const initialState = {
    companies: [],
    companiesCount: 0,
    loadingList: false,

    company: {},
    loadingDetails: false,
};

// Loading True and ErrorFalse
const setLoadingListTrue = (state, _action) => {
    return {
        ...state,
        companies: [],
        loadingList: true,
    };
};

const setLoadingListFalseError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const setLoadingDetailsTrue = (state, _action) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setLoadingDetailsFalse = (state, { error }) => {
    return {
        ...state,
        error,
        loadingDetails: false,
    };
};

const setLoadingNewCompany = (state, _action) => {
    return {
        ...state,
        company: {},
        loadingDetails: true,
    };
};

// Companies
const setCompanies = (state, { companies }) => {
    return {
        ...state,
        companies,
        loadingList: false,
    };
};

const setCompaniesCount = (state, { companiesCount }) => {
    return {
        ...state,
        companiesCount,
    };
};

//Company
const setCompanyDetails = (state, { company }) => {
    return {
        ...state,
        company,
        loadingDetails: false,
    };
};

const importCertificateRequest = (state, action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const importCertificate = (state, action) => {
    return {
        ...state,
        loadingList: false,
    };
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPANY_GET_LIST_REQUEST:
        case actionTypes.COMPANY_GET_COUNT_REQUEST:
            return setLoadingListTrue(state, action);

        case actionTypes.COMPANY_GET_LIST_FAILURE:
        case actionTypes.COMPANY_GET_COUNT_FAILURE:
        case actionTypes.IMPORT_COMPANY_CERTIFICATE_FAILURE:
            return setLoadingListFalseError(state, action);

        case actionTypes.COMPANY_GET_DETAILS_REQUEST:
        case actionTypes.COMPANY_CREATE_REQUEST:
        case actionTypes.COMPANY_UPDATE_REQUEST:
        case actionTypes.COMPANY_DELETE_REQUEST:
            return setLoadingDetailsTrue(state, action);

        case actionTypes.COMPANY_GET_DETAILS_FAILURE:
        case actionTypes.COMPANY_CREATE_FAILURE:
        case actionTypes.COMPANY_UPDATE_FAILURE:
        case actionTypes.COMPANY_DELETE_FAILURE:
            return setLoadingDetailsFalse(state, action);

        case actionTypes.COMPANY_GET_DETAILS_NEW:
            return setLoadingNewCompany(state, action)

        //Companies
        case actionTypes.COMPANY_GET_LIST_SUCCESS:
            return setCompanies(state, action);

        case actionTypes.COMPANY_GET_COUNT_SUCCESS:
            return setCompaniesCount(state, action);

        //Company Details
        case actionTypes.COMPANY_GET_DETAILS_SUCCESS:
            return setCompanyDetails(state, action);

        //Import Certificate
        case actionTypes.IMPORT_COMPANY_CERTIFICATE_REQUEST:
            return importCertificateRequest(state, action);

        case actionTypes.IMPORT_COMPANY_CERTIFICATE_SUCCESS:
            return importCertificate(state, action);

        default:
            return state;
    }
};

export default reducer;
