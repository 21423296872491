import React, { useContext, useState, useEffect } from 'react';

import * as actions from '../../store/actions/index';
import { injectIntl } from 'react-intl';
import messages from './CteDetailsForm.intl';

import { Col, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AuthContext } from '../../context/AuthContext';

import InvoicesStatusCounter from '../InvoicesStatusCounter/InvoicesStatusCounter';
import ActionsButtons from '../ActionsButtons/ActionsButtons';
import DynamicTemplateForm from '../DynamicTemplateForm/DynamicTemplateForm';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import CteActionModals from './components/CteActionModals';
import { cteFormGroupTemplate, cteTemplate } from './components/CteTemplate';
import ModalConfirmReturnToReview from '../ModalConfirmReturnToReview/ModalConfirmReturnToReview';
import { toast } from 'react-toastify';
import { DocumentTypes } from '../../utils/enums';

const CteDetailsForm = props => {
    const {
        intl,
        loading,
        cteDocument,
        documentHistory,
        associatedDocumentsCount,
        cteOrdersCount,
        validationErrors,
        handleToggleAssociatedDocModal,
        handleRedirectToOrders,
    } = props;
    const context = useContext(AuthContext);
    const cteDocumentId = cteDocument?.documentId;

    const [isDraft, setIsDraft] = useState(false);
    const [submitValues, setSubmitValues] = useState(false);
    const [fieldsWithError, setFieldsWithError] = useState([]);
    const [selectedFormField, setSelectedFormField] = useState(null);

    const [showModalSendDocument, setShowModalSendDocument] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);
    const [showReturnToReviewModal, setShowReturnToReviewModal] = useState(false);
    const [showModalReject, setShowModalReject] = useState(false);
    const [fields, setFields] = useState([]);

    const [cteTemplateToForm, setCteTemplateToForm] = useState([]);

    useEffect(() => {
        if (cteDocument?.form?.fields.length > 0) {
            const IdField = {
                name: 'ID do documento',
                key: 'viewDocumentId',
                constraints: { required: false },
                formGroup: 1,
                layout: { xs: 5, lg: 5, md: 5, sm: 5, xl: 5 },
                type: 'Text',
                value: cteDocument.documentId,
                boundingBox: { x: 0, y: 0, height: 0, width: 0, page: 1 },
                hideMapInDocument: false,
                isDocumentField: false,
            };
            const arrayFields = cteDocument?.form?.fields;
            arrayFields.push(IdField);

            setFields(arrayFields);

            //Adicionando template assim que encontrar o documento
            const ICMSType = cteDocument?.form?.fields?.find(
                x => x.key === 'ICMSType',
            )?.value;

            const Toma = cteDocument?.form?.fields?.find(
                x => x.key === '/cteProc/CTe/infCte/ide/toma3/toma',
            );

            const TomaType = cteDocument?.form?.fields?.find(x =>
                x.key.includes('/cteOSProc/CTeOS'),
            )
                ? 'CTeOS'
                : 'toma3';

            const TomaTag = Toma?.value;

            setCteTemplateToForm(cteTemplate(ICMSType, TomaType, TomaTag));
        }
    }, [cteDocument?.form?.fields]);

    const hasDocumentUpdateClaim = context.hasClaim('cte-document-update');
    const currentStep = cteDocument?.currentStep;
    const reviewStep =
        currentStep?.order === 3 && currentStep?.status === 'pending';

    const canUpdateForm = context.hasClaim('cte-document-update') && reviewStep;
    const canViewFile = context.hasClaim('cte-file-view');

    const shouldShowCancelButton =
        currentStep?.order === 3 && currentStep?.status === 'pending';

    const shouldShowRejectButton =
        context.hasClaim('cte-document-reject-justification') &&
        currentStep?.order === 3 &&
        currentStep?.status === 'pending';

    const shouldShowReturnToReviewStepButton = 
        context.hasClaim('document-update-status') &&
        currentStep?.order === 4 && currentStep?.status === 'pending';

    function compareDate(dateToCompare, addMonths) {
        const dateNow = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            0,
            0,
            0,
        );
        const comparisonDate = new Date(
            dateNow.setMonth(dateNow.getMonth() + Number(-addMonths)),
        );

        return new Date(dateToCompare) > comparisonDate;
    }

    const dropdownItems = [
        //Salvar
        {
            name: intl.formatMessage(messages.saveButton),
            onClick: () => setIsDraft(true),
            disabled: !canUpdateForm,
            type: 'submit',
        },
        //Cancelar
        {
            name: intl.formatMessage(messages.cancelButton),
            onClick: () => handleToggleModalCancel(),
            disabled: !shouldShowCancelButton,
        },
        //Rejeitar
        {
            name: intl.formatMessage(messages.rejectButton),
            onClick: () => handleToggleModalReject(),
            disabled: !shouldShowRejectButton,
        },
        // Voltar para etapa de revisão
        {
            name: intl.formatMessage(messages.returnToReviewStep),
            onClick: () => 
                handleToggleReturnToReviewModal(),
            disabled: !shouldShowReturnToReviewStepButton
        }
    ];

    useEffect(() => {
        const errors = Object.keys(validationErrors);
        setFieldsWithError(errors);
    }, [validationErrors]);

    // #region Modal functions
    // Send Document
    const handleToggleModalSendDocument = (cancel = false) => {
        if (cancel) setSubmitValues({});
        setShowModalSendDocument(!showModalSendDocument);
    };

    function handleSubmitForm() {
        const { e, values, isDraft } = submitValues;
        props.handleFormSubmit(e, values, isDraft);
        setSubmitValues({});
        handleToggleModalSendDocument();
    }

    // Cancel
    const handleToggleModalCancel = () => setShowModalCancel(!showModalCancel);

    function handleSubmitCancel() {
        const currentStep = cteDocument.currentStep || {};

        const values = {
            steps: [
                {
                    order: currentStep.order,
                    status: 'error',
                    text: 'Cancelado',
                },
            ],
        };

        props.onCteCancelDocument(cteDocumentId, values);
        handleToggleModalCancel();
    }

    // ReportCancel
    const handleToggleModalReject = () => setShowModalReject(!showModalReject);
    const handleToggleReturnToReviewModal = () => setShowReturnToReviewModal(!showReturnToReviewModal);
        
    function handleRejectSubmit(justificationText) {
        const currentStep = cteDocument.currentStep || {};
        const values = {
            steps: [
                {
                    order: currentStep.order,
                    status: 'error',
                    text: 'Rejeitado - ' + justificationText,
                },
            ],
        };

        if (justificationText?.length > 600) {
            toast.error(intl.formatMessage(messages.canJustificationError));
        } else {
            props.onCteCancelDocument(cteDocumentId, values);
        }
        handleToggleModalReject();
    }

    // Return to review step
    function handleReturnToReviewStepClick() {
        props.onReturnToReviewStep(cteDocumentId);
    }
    // #endregion Modal functions

    const onValidSubmit = (e, values) => {
        const keyValuesArray = Object.entries(values).map(keyValue => {
            const [key, value] = keyValue;

            if (
                !key.includes('toma4') &&
                key !== '/cteProc/CTe/infCte/ide/toma3/toma' &&
                key !== '/cteOSProc/CTeOS/infCte/toma' &&
                key !== 'toma3/xNome' &&
                key !== 'toma3/UF' &&
                key !== 'toma3/CNPJ'
            ) {
                return keyValue;
            }
        });

        const newValues = Object.fromEntries(
            keyValuesArray.filter(x => x !== undefined),
        );

        if (!isDraft) {
            setSubmitValues({ e, values: newValues, isDraft });
            handleToggleModalSendDocument();
        } else {
            props.handleFormSubmit(e, newValues, isDraft);
        }
    };

    const handleDynamicFieldFocus = (e, key) => {
        const field = fields.find(ff => ff.key === key);
        setSelectedFormField(field);
    };

    const handleDynamicFieldChange = (e, key) => {
        if (!key) return;
        setFieldsWithError(fieldsWithError.filter(fkey => fkey !== key));
    };

    return (
        <>
            <InvoicesStatusCounter
                steps={cteDocument?.steps}
                currentStep={cteDocument?.currentStep}
                useAltText={false}
            />

            <section className='map-nota row'>
                <section className='col-6'>
                    {cteDocument?.fileId && canViewFile && (
                        <DocumentViewer
                            selectedDocument={cteDocument}
                            documentHistory={documentHistory}
                            boxes={[]}
                            selectedItem={selectedFormField}
                            docType={DocumentTypes.CTE}
                        />
                    )}
                </section>

                <section className='fields-maps col-6'>
                    <AvForm onValidSubmit={onValidSubmit}>
                        <ActionsButtons
                            loading={loading}
                            canUpdateForm={canUpdateForm}
                            dropdownItems={dropdownItems}
                            selectedDocument={cteDocument}
                            documentsAttachmentCount={associatedDocumentsCount}
                            disableTransactionButton
                            onClickAttachmentsButton={
                                handleToggleAssociatedDocModal
                            }
                            ordersCount={cteOrdersCount}
                            onClickOrdersButton={handleRedirectToOrders}
                            hideSubmitButtonClaim={context.hasClaim(
                                'cte-document-hide-submit-button',
                            )}
                        />
                        <Row>
                            <Col className='cards-fields' xs={12}>
                                <DynamicTemplateForm
                                    fields={fields}
                                    template={cteTemplateToForm}
                                    templateFormGroups={cteFormGroupTemplate(
                                        intl,
                                    )}
                                    fieldsWithError={fieldsWithError}
                                    handleDynamicFieldBlur={() => {}}
                                    handleDynamicFieldFocus={
                                        handleDynamicFieldFocus
                                    }
                                    handleDynamicFieldChange={
                                        handleDynamicFieldChange
                                    }
                                    loading={loading}
                                    readOnly={true}
                                    isRevisionStep={reviewStep}
                                    hasDocumentUpdateClaim={
                                        hasDocumentUpdateClaim
                                    }
                                    documentId={cteDocument?.documentId}
                                    hideFieldWithoutValue
                                />
                            </Col>
                        </Row>
                    </AvForm>
                </section>
            </section>

            {/* Modals */}
            <CteActionModals
                isOpenSendDoc={showModalSendDocument}
                handleToggleModalSendDoc={() =>
                    handleToggleModalSendDocument(true)
                }
                handleSubmitForm={handleSubmitForm}
                isOpenCancel={showModalCancel}
                handleToggleModalCancel={handleToggleModalCancel}
                handleCancelSubmit={handleSubmitCancel}
                isOpenReject={showModalReject}
                handleToggleModalReject={handleToggleModalReject}
                handleRejectSubmit={handleRejectSubmit}
            />

            <ModalConfirmReturnToReview
                handleToggleReturnToReviewModal = {handleToggleReturnToReviewModal}
                showReturnToReviewModal = {showReturnToReviewModal}
                handleReturnToReviewStepClick = {handleReturnToReviewStepClick}
            />

        </>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        onCteCancelDocument: (documentId, values) =>
            dispatch(actions.cancelDocument(documentId, values)),
        onReturnToReviewStep: documentId =>
            dispatch(actions.returnToReviewStep(documentId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(CteDetailsForm),
);

CteDetailsForm.propTypes = {
    cteDocument: PropTypes.object,
    associatedDocumentsCount: PropTypes.number,
    cteOrdersCount: PropTypes.number,
    loading: PropTypes.bool,
    validationErrors: PropTypes.array,
    handleFormSubmit: PropTypes.func,
    handleToggleAssociatedDocModal: PropTypes.func,
    handleRedirectToOrders: PropTypes.func,
};
