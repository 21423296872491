import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';

import messages from './SettingsAccountForm.intl';

const SettingsAccountForm = props => {
    const { intl, handleChangePasswordFormSubmit } = props;

    return (
        <AvForm onValidSubmit={handleChangePasswordFormSubmit}>
          <h5 className='title-area-form'>{intl.formatMessage(messages.changePassword)}</h5>
            <Row>
                <Col xs={12} md={6} lg={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.newPasswordLabelText,
                            )}
                            type='password'
                            name='newPassword'
                            id='newPassword'
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.confirmPasswordLabelText,
                            )}
                            type='password'
                            name='confirmNewPassword'
                            id='confirmNewPassword'
                            errorMessage=' '
                            required
                            validate={{
                                match: {
                                    value: 'newPassword',
                                    errorMessage: intl.formatMessage(
                                        messages.passwordDoesNotMatchValidationText,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <button className='btn-submit'>
                        {intl.formatMessage(messages.buttonChangeText)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    );
};

export default injectIntl(SettingsAccountForm);
