import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';

import { injectIntl } from 'react-intl';
import messages from './AddMapModal.intl';

import { Container, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import EvtSelect from '../EvtSelect/EvtSelect';

function AddMapModal(props) {
    const {
        intl,
        contractors,
        showImportMapFileInput,
        importMap,
        isCopiedMap = false,
    } = props;
    const [files, setFiles] = useState([]);
    const [docsTypes, setDocsTypes] = useState([]);
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState([]);
    const [importedMap, setImportedMap] = useState(null);

    useEffect(() => {
        setFiles(mapFileTypesArray(props.fileTypes));
    }, [props.fileTypes]);

    useEffect(() => {
        setDocsTypes(mapDocTypesArray(props.documentTypes));
    }, [props.documentTypes]);

    useEffect(() => {
        if (props.subCategories) {
            setSubCategoriesByCategory(
                mapSubCategoriesArray(['', ...props.subCategories]) || [],
            );
        }
    }, [props.subCategories]);

    useEffect(() => {
        if (showImportMapFileInput || isCopiedMap) {
            setImportedMap(importMap);
            if (importMap) {
                props.handleStateChange({
                    value: importMap?.uf,
                    label: states?.find(e => {
                        return e?.value === importMap?.uf;
                    })?.label,
                });
                props.handleCategoryChange({
                    value:
                        categoryTypes?.find(e => {
                            return e?.label === importMap?.categoryType;
                        })?.value || '',
                    label: importMap?.categoryType || '',
                });
            }
        }
    }, [importMap]);

    useEffect(() => {
        if ((showImportMapFileInput || isCopiedMap) && props.cities) {
            props.handleCityChange({
                label: importMap?.city,
                value: cities?.find(e => {
                    return e.label === importMap?.city;
                })?.value,
            });
        }
    }, [props.cities]);

    const mapStatesArray = arrayStates => {
        if (!arrayStates) return;
        const map = arrayStates.map(state => {
            return {
                label: state.name,
                value: state.uf,
            };
        });
        return map;
    };

    const mapCitiesArray = citiesArray => {
        if (!citiesArray) return;
        const map = citiesArray.map(city => {
            return {
                label: city.name,
                value: city.code,
            };
        });
        return map;
    };

    const mapFileTypesArray = filesArray => {
        if (!filesArray) return;
        const map = filesArray.map(file => {
            return {
                label: file.Name,
                value: file.Id,
            };
        });
        return map;
    };

    const mapCategoriesArray = categoriesArray => {
        if (!categoriesArray) return;
        const map = categoriesArray.map(category => {
            return {
                label: category.name,
                value: category.code,
            };
        });
        return map;
    };

    const mapSubCategoriesArray = subCategoriesArray => {
        if (!subCategoriesArray) return;
        const map = subCategoriesArray.map(subCategory => {
            return {
                label: subCategory,
                value: subCategory,
            };
        });
        return map;
    };

    const mapDocTypesArray = docsArray => {
        if (!docsArray) return;
        const map = docsArray.map(doc => {
            return {
                label: doc.Name,
                value: doc.Id,
            };
        });
        return map;
    };

    props.fileTypes.push({ Id: 1, Name: 'Image' }, { Id: 2, Name: 'Xml' });
    props.documentTypes.push(
        { Id: 1, Name: 'NFE' },
        { Id: 2, Name: 'NFS' },
        { Id: 3, Name: 'CTE' },
        { Id: 4, Name: 'BILL' },
        { Id: 5, Name: 'OTHER' },
        { Id: 6, Name: 'CTE_EVENTO' },
    );

    const mapVersions = [
        { Id: '0', Name: 'V1' },
        { Id: '1', Name: 'V2' },
        { Id: '2', Name: 'V3' },
    ];

    const states = mapStatesArray(props.states);
    const cities = mapCitiesArray(props.cities);
    const mapVersionsArray = mapDocTypesArray(mapVersions);
    const categoryTypes = mapCategoriesArray([
        { name: '', code: '' },
        ...props.categoryTypes,
    ]);

    const contractorsOptions =
        contractors &&
        contractors.map(contractor => {
            return {
                label: contractor.displayName,
                value: contractor.contractorId,
            };
        });

    return (
        <AvForm onValidSubmit={props.handleAddMapModalSubmit}>
            <Container fluid>
                <Row>
                    {showImportMapFileInput && (
                        <Col xs={12}>
                            <AvField
                                label='File'
                                type='file'
                                onChange={props.handleImportMapFileChange}
                                name='importMapJsonFile'
                                id='importMapJsonFile'
                                accept='application/json'
                            />
                        </Col>
                    )}
                    <Col
                        xs={12}
                        className={`text-left ${showImportMapFileInput &&
                            'mt-3'}`}
                    >
                        <AvField
                            label={intl.formatMessage(messages.name)}
                            type='text'
                            name='name'
                            value={importedMap?.name}
                            validate={{
                                required: { value: true },
                            }}
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label={intl.formatMessage(messages.version)}
                            name='mapVersion'
                            options={mapVersionsArray}
                            option={
                                importedMap && {
                                    selectedValueOption: String(
                                        importedMap?.mapVersion,
                                    ),
                                    selectedLabelOption: mapVersionsArray?.find(
                                        e => {
                                            return (
                                                e?.value ===
                                                String(importedMap?.mapVersion)
                                            );
                                        },
                                    )?.label,
                                }
                            }
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label={intl.formatMessage(messages.contractor)}
                            name='contractorId'
                            options={contractorsOptions}
                            url='/Contractors'
                            labelKey='displayName'
                            valueKey='contractorId'
                            async
                            required={false}
                            option={
                                importedMap && {
                                    selectedValueOption:
                                        importedMap?.contractorId,
                                    selectedLabelOption:
                                        importedMap?.contractorName,
                                }
                            }
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label='Estados'
                            name='stateCode'
                            options={states}
                            handleSelectedOptionChange={props.handleStateChange}
                            required={false}
                            option={
                                !!importedMap
                                    ? {
                                          selectedValueOption: importedMap?.uf,
                                          selectedLabelOption: states?.find(
                                              e => {
                                                  return (
                                                      e?.value ===
                                                      importedMap?.uf
                                                  );
                                              },
                                          )?.label,
                                      }
                                    : props.selectedState || null
                            }
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label='Cidades'
                            name='cityCode'
                            options={cities}
                            required={false}
                            option={props.selectedCity || null}
                            handleSelectedOptionChange={props.handleCityChange}
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label='Tipo de Arquivo'
                            name='fileType'
                            options={files}
                            option={
                                importedMap && {
                                    selectedValueOption: files?.find(e => {
                                        return (
                                            e?.label === importedMap?.fileType
                                        );
                                    })?.value,
                                    selectedLabelOption: importedMap?.fileType,
                                }
                            }
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label='Tipo de Documento'
                            name='documentType'
                            options={docsTypes}
                            option={
                                importedMap && {
                                    selectedValueOption: docsTypes?.find(e => {
                                        return (
                                            e?.label ===
                                            importedMap?.documentType
                                        );
                                    })?.value,
                                    selectedLabelOption:
                                        importedMap?.documentType,
                                }
                            }
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label='Categoria'
                            name='categoryType'
                            options={categoryTypes}
                            required={false}
                            handleSelectedOptionChange={
                                props.handleCategoryChange
                            }
                            option={
                                importedMap && {
                                    selectedValueOption: categoryTypes?.find(
                                        e => {
                                            return (
                                                e?.label ===
                                                importedMap?.categoryType
                                            );
                                        },
                                    )?.value,
                                    selectedLabelOption:
                                        importedMap?.categoryType,
                                }
                            }
                        />
                    </Col>
                    <Col xs={12} className='text-left mt-3'>
                        <EvtSelect
                            label='SubCategoria'
                            name='subCategory'
                            options={subCategoriesByCategory}
                            required={false}
                            option={
                                importedMap && {
                                    selectedValueOption:
                                        importedMap?.subCategory,
                                    selectedLabelOption:
                                        importedMap?.subCategory,
                                }
                            }
                        />
                    </Col>
                    <Col
                        xs={12}
                        className={`text-left ${showImportMapFileInput &&
                            'mt-3'}`}
                    >
                        <AvField
                            label='Id do mapeamento filho'
                            type='text'
                            name='childMapId'
                            validate={{
                                required: { value: false },
                            }}
                            value={importedMap?.childMapId}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <button className='btn-submit'>
                            {intl.formatMessage(messages.create)}
                        </button>
                    </Col>
                </Row>
            </Container>
        </AvForm>
    );
}

AddMapModal.propTypes = {
    contractors: Proptypes.array,
    states: Proptypes.array,
    cities: Proptypes.array,
    handleValidSubmit: Proptypes.func,
    handleStateChange: Proptypes.func,
    handleCityChange: Proptypes.func,
    fileTypes: Proptypes.array,
    documentTypes: Proptypes.array,
    mapVersions: Proptypes.array,
    categoryTypes: Proptypes.array,
};

export default injectIntl(AddMapModal);
