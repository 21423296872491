import { defineMessages } from "react-intl";
import globalMessages from "../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  financialVolumeCounter: {
    id: "dashboard.financialVolumeCounter",
    defaultMessage: "Volume total agregado:"
  }
});
