import * as actionTypes from './actionTypes';
import axios from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

export const initTransactions = (filter, documentId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.TRANSACTIONS_GET_LIST_REQUEST,
        });

        if (!documentId) {
            return axios
                .get('/Transactions' + filter)
                .then(response => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_LIST_SUCCESS,
                        transactions: response.data,
                    });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_LIST_FAILURE,
                        error,
                    });
                });
        } else {
            return axios
                .get(`/Documents/${documentId}/Transactions` + filter)
                .then(response => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_LIST_SUCCESS,
                        transactions: response.data,
                    });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_LIST_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const initTransactionsCount = (filter, documentId) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.TRANSACTIONS_GET_COUNT_REQUEST,
        });

        if (!documentId) {
            await axios
                .get('/Transactions/Count' + filter)
                .then(response => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_COUNT_SUCCESS,
                        transactionsCount: response.data,
                    });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_COUNT_FAILURE,
                        error,
                    });
                });
        } else {
            await axios
                .get(`/Documents/${documentId}/Transactions/Count` + filter)
                .then(response => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_COUNT_SUCCESS,
                        transactionsCount: response.data,
                    });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.TRANSACTIONS_GET_COUNT_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const initTransactionDetails = transactionId => {
    return (dispatch, getState) => {
        if (transactionId === 'new') {
            dispatch({
                type: actionTypes.TRANSACTION_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.TRANSACTION_GET_DETAILS_REQUEST,
            });

            axios.get(`/Transactions/${transactionId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.TRANSACTION_GET_DETAILS_SUCCESS,
                        transaction: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.TRANSACTION_GET_DETAILS_FAILURE,
                        error,
                    });
                });
            }
        };
    };

export const createTransaction = values => {
    return dispatch => {
        dispatch({
            type: actionTypes.TRANSACTION_POST_REQUEST,
        });

        axios
            .post(`/Transactions`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.TRANSACTION_POST_SUCCESS,
                    transactionId: response.data,
                });

                toast.success('Pagamento criado com sucesso');
                dispatch(push('/transactions'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.TRANSACTION_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateTransaction = (transactionId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.TRANSACTION_PUT_REQUEST,
        });

        axios
            .put(`/Transactions/${transactionId}`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.TRANSACTION_PUT_SUCCESS,
                });
                toast.success('Pagamento alterado com sucesso');
                dispatch(push('/transactions'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.TRANSACTION_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentTransactions = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.STATES_GET_LIST_REQUEST,
        });

        axios
            .get(`/Documents/Transactions/${documentId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.TRANSACTIONS_GET_LIST_SUCCESS,
                    invoice: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.TRANSACTIONS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};
