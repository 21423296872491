import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, FormGroup } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { AuthContext } from '../../context/AuthContext';
import EvtSelect from '../../components/EvtSelect/EvtSelect';
import messages from './CategoryDetailsForm.intl';

function CategoryDetailForm(props) {
    const context = useContext(AuthContext);
    const { intl, category, isNew } = props;
    const [selectedContractor, setSelectedContractor] = useState(null);

    const canCreate = context.hasClaim('category-create');
    const canUpdate = context.hasClaim('category-update');
    const canDelete = context.hasClaim('category-delete') && !isNew;
    const isReadOnly = !(canCreate || canUpdate);

    useEffect(() => {
        if (category?.contractorId) {
            setSelectedContractor({
                label: category?.contractorName,
                value: category?.contractorId,
            });
        }
    }, [category]);

    const handleSelectContractor = option => setSelectedContractor(option);

    return (
        <>
            <AvForm
                onValidSubmit={(event, values) =>
                    props.handleSubmit(event, {
                        ...values,
                    })
                }
            >
                <Row>
                    <Col md={6}>
                        <EvtSelect
                            label={intl.formatMessage(messages.contractor)}
                            name='contractorId'
                            id='contractor'
                            url={`/Contractors`}
                            labelKey='displayName'
                            valueKey='contractorId'
                            async
                            addEmptyOption
                            key={selectedContractor?.value}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required={false}
                            handleSelectedOptionChange={handleSelectContractor}
                            option={
                                selectedContractor && {
                                    selectedValueOption:
                                        selectedContractor?.value,
                                    selectedLabelOption:
                                        selectedContractor?.label,
                                }
                            }
                        />
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.name)}
                                type='text'
                                name='name'
                                id='name'
                                disabled={isReadOnly}
                                value={category?.name}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.code)}
                                type='number'
                                name='code'
                                id='code'
                                disabled={isReadOnly}
                                value={category?.code}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(messages.active)}
                                type='checkbox'
                                name='active'
                                id='active'
                                disabled={isReadOnly}
                                value={category?.active || true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.subcategories,
                                )}
                                type='textarea'
                                name='subcategories'
                                id='subcategories'
                                disabled={isReadOnly}
                                value={category?.subcategories?.join(';') || ''}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {!isReadOnly && (
                            <button
                                type='submit'
                                className='btn-submit'
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.submitButton)}
                            </button>
                        )}
                        {canDelete && (
                            <button
                                type='button'
                                className='btn-delete'
                                onClick={props.handleDelete}
                            >
                                {intl.formatMessage(messages.deleteButton)}
                            </button>
                        )}
                    </Col>
                </Row>
            </AvForm>
        </>
    );
}

export default injectIntl(CategoryDetailForm);
