import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import messages from './SearchBar.intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SearchBar extends React.Component {
    state = {
        classNameBar: `search-input search-input-show`,
    };

    componentDidMount() {
        const classNameBar =
            this.state.classNameBar + ` search-input-tab-inside`;
        if (this.props.colorVar) {
            this.setState({ classNameBar });
        }
    }

    handleSearchKeyUp = e => {
        const target = e.target;
        const key = e.key;

        if (key === 'Enter') {
            this.props.handleSearchSubmit &&
                this.props.handleSearchSubmit(target.value);
        }
    };

    handleSearchClickButton = e => {
        this.props.handleSearchSubmit &&
            this.props.handleSearchSubmit(this.searchInput.value);
    };

    handleSearchChange = e => {
        this.props.handleSearchChange &&
            this.props.handleSearchChange(e.target.value);
    };

    render() {
        const { intl, loading, searchTerm, colorVar } = this.props;

        return (
            <div id='search-area' className='search-area'>
                <button
                    id='search-btn'
                    className='search-btn icomoon icon-search'
                    onClick={this.handleSearchClickButton}
                >
                    <FontAwesomeIcon icon='search' />
                </button>
                <input
                    ref={node => (this.searchInput = node)}
                    value={searchTerm}
                    id='search-input'
                    className={this.state.classNameBar}
                    type='text'
                    disabled={loading}
                    placeholder={intl.formatMessage(messages.placeholder, {
                        entity: intl.formatMessage(messages.placeholder),
                    })}
                    onChange={this.handleSearchChange}
                    onKeyUp={this.handleSearchKeyUp}
                />
            </div>
        );
    }
}

SearchBar.propTypes = {
    searchTerm: PropTypes.string,
    handleSearchSubmit: PropTypes.func.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    intl: PropTypes.any,
    colorVar: PropTypes.bool,
};

export default injectIntl(SearchBar);
