import { defineMessages } from "react-intl";
import globalMessages from "../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  insert: {
    id: "maps.actionTypes.insert",
    defaultMessage: "Inserir texto"
  },

  padLeft: {
    id: "maps.actionTypes.padLeft",
    defaultMessage: "Inserir à esquerda"
  },

  padRight: {
    id: "maps.actionTypes.padRight",
    defaultMessage: "Inserir à direita"
  },

  remove: {
    id: "maps.actionTypes.remove",
    defaultMessage: "Remover texto"
  },

  replace: {
    id: "maps.actionTypes.replace",
    defaultMessage: "Substituir texto"
  },

  trim: {
    id: "maps.actionTypes.trim",
    defaultMessage: "Remover espaços"
  },

  trimStart: {
    id: "maps.actionTypes.trimStart",
    defaultMessage: "Remover espaços do início"
  },

  trimEnd: {
    id: "maps.actionTypes.trimEnd",
    defaultMessage: "Remover espaços do fim"
  },

  insertDescription: {
    id: "maps.actionTypes.insertDescription",
    defaultMessage: "Insere o texto informado na posição desejada"
  },

  padLeftDescription: {
    id: "maps.actionTypes.padLeftDescription",
    defaultMessage: "Insere uma quantidade determinada de caracteres à esquerda"
  },

  padRightDescription: {
    id: "maps.actionTypes.padRightDescription",
    defaultMessage: "Insere uma quantidade determinada de caracteres à direita"
  },

  removeDescription: {
    id: "maps.actionTypes.removeDescription",
    defaultMessage: "Remove o texto informado do valor encontrado"
  },

  keep: {
    id: "maps.actionTypes.keep",
    defaultMessage: "Manter texto"
  },

  keepDescription: {
    id: "maps.keepDescription",
    defaultMessage: "Manter texto encontrado pela Regex"
  },

  replaceDescription: {
    id: "maps.actionTypes.replaceDescription",
    defaultMessage: "Substituí o texto informado por um novo dentro do valor encontrado"
  },

  trimDescription: {
    id: "maps.actionTypes.trimDescription",
    defaultMessage: "Remove os espaços do início e do fim do valor encontrado"
  },

  trimStartDescription: {
    id: "maps.actionTypes.trimStartDescription",
    defaultMessage: "Remove os espaços do início do valor encontrado"
  },

  trimEndDescription: {
    id: "maps.actionTypes.trimEndDescription",
    defaultMessage: "Remove os espaços do fim do valor encontrado"
  },

  noAction: {
    id: "maps.noAction",
    defaultMessage: "Nenhuma ação cadastrada"
  },
  formNotSupported: {
    id: "maps.formNotSupported",
    defaultMessage: "Esse formulário não é suportado"
  },

  home: {
    id: "home.title",
    defaultMessage: "Início"
  },

  title: {
    id: "maps.title",
    defaultMessage: "Mapeamentos"
  },
  
  titleMap: {
    id: "maps.titleMap",
    defaultMessage: "Mapeamento {entity}"
  },

  condition: {
    id: "maps.condition",
    defaultMessage: "Condição"
  }
});
