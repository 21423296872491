import React, { Component } from 'react';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import messages from './OrderListPage.intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtTable, {
    buildFilterOr,
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';

class OrderListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
    };

    componentDidMount() {
        this.props.setTabIndex(0);
    }

    properties = () => [
        {
            key: 'supplierName',
            value: this.props.intl.formatMessage(messages.supplierName),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'partnerSupplierName',
            value: this.props.intl.formatMessage(messages.partnerSupplierName),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'erpOrderCode',
            value: this.props.intl.formatMessage(messages.orderCode),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'erpOrderItemCode',
            value: this.props.intl.formatMessage(messages.orderItemCode),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'erpServiceSheetCode',
            value: this.props.intl.formatMessage(
                messages.orderServiceSheetCode,
            ),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'erpServiceSheetItemCode',
            value: this.props.intl.formatMessage(
                messages.orderServiceSheetItemCode,
            ),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'description',
            value: this.props.intl.formatMessage(messages.description),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'value',
            value: this.props.intl.formatMessage(messages.totalGrossValue),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'updatedAt',
            value: this.props.intl.formatMessage(messages.integratedAt),
            type: filterPropertyTypes.DATE,
        },
    ];

    reloadOrdersList = () => {
        const { query } = this.state;
        let filterQuery = query;

        if (query.includes('search=')) {
            const value = new URLSearchParams(query).get('search');
            filterQuery = buildFilterOr(value, this.properties());
        }
        this.props.history.push('/orders' + query);

        this.props.onInitOrders(filterQuery);
        this.props.onInitOrdersCount(filterQuery);
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        const { intl } = this.props;

        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.history.push(`/orders/${rowInfo.original.orderId}`),

            className: !rowInfo.original.isAvailable ? 'disabled-row' : '',
            title: !rowInfo.original.isAvailable
                ? intl.formatMessage(messages.unavailableOrder)
                : '',
        };
    };

    handleNewClick = () => {
        this.props.history.push(`/orders/new`);
    };

    handleRefreshButtonClick = e => {
        this.reloadOrdersList();
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
        this.props.history.push(`/orders` + this.state.query);
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadOrdersList();
            },
        );
    };

    render() {
        const { intl, loadingList, orders, ordersCount } = this.props;
        const { filters } = this.state;

        const newOrderButtonEnabled = this.context.hasClaim('order-create');
        const hideSupplier = this.context.hasClaim('role-level-supplier');

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.supplierName)}</span>
                    </div>
                ),
                accessor: 'supplierName',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    200 +
                    calculateColumnWidth(
                        orders,
                        'supplierName',
                        intl.formatMessage(messages.supplierName),
                    ),
                minWidth:
                    40 +
                    calculateColumnWidth(
                        orders,
                        'supplierName',
                        intl.formatMessage(messages.supplierName),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.description)}</span>
                    </div>
                ),
                accessor: 'description',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    400 +
                    calculateColumnWidth(
                        orders,
                        'description',
                        intl.formatMessage(messages.description),
                    ),
                minWidth:
                    40 +
                    calculateColumnWidth(
                        orders,
                        'description',
                        intl.formatMessage(messages.description),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.orderCode)}</span>
                    </div>
                ),
                accessor: 'erpOrderCode',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    150 +
                    calculateColumnWidth(
                        orders,
                        'erpOrderCode',
                        intl.formatMessage(messages.orderCode),
                    ),
                minWidth:
                    20 +
                    calculateColumnWidth(
                        orders,
                        'erpOrderCode',
                        intl.formatMessage(messages.orderCode),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.orderServiceSheetCode)}
                        </span>
                    </div>
                ),
                accessor: 'erpServiceSheetCode',
                style: {
                    fontSize: 'small',
                },
                maxWidth:
                    200 +
                    calculateColumnWidth(
                        orders,
                        'erpServiceSheetCode',
                        intl.formatMessage(messages.orderServiceSheetCode),
                    ),
                minWidth:
                    20 +
                    calculateColumnWidth(
                        orders,
                        'erpServiceSheetCode',
                        intl.formatMessage(messages.orderServiceSheetCode),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.totalGrossValue)}
                        </span>
                    </div>
                ),
                accessor: 'value',
                style: {
                    fontSize: 'small',
                },
                Cell: props =>
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),

                sortable: false,
                maxWidth:
                    120 +
                    calculateColumnWidth(
                        orders,
                        'value',
                        intl.formatMessage(messages.totalGrossValue),
                    ),
                minWidth:
                    85 +
                    calculateColumnWidth(
                        orders,
                        'value',
                        intl.formatMessage(messages.totalGrossValue),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.integratedAt)}</span>
                    </div>
                ),
                accessor: 'updatedAt',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    const date = new Date(row.value).toLocaleString(
                        navigator.language,
                    );
                    return (
                        <div className='customer-container'>
                            <div className=''>{date}</div>
                        </div>
                    );
                },
                maxWidth:
                    140 +
                    calculateColumnWidth(
                        orders,
                        'updatedAt',
                        intl.formatMessage(messages.integratedAt),
                    ),
                minWidth:
                    100 +
                    calculateColumnWidth(
                        orders,
                        'updatedAt',
                        intl.formatMessage(messages.integratedAt),
                    ),
            },
        ];

        hideSupplier && columns.shift();

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/orders',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                this.props.ordersCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={this.properties()}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                        {newOrderButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOrder,
                                        {
                                            entity: '',
                                        },
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={orders}
                        length={ordersCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                        search
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        orders: state.orderReducer.orders || [],
        ordersCount: state.orderReducer.ordersCount || 0,
        loadingList: state.orderReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitOrders: filter => dispatch(actions.initOrders(filter)),
        onInitOrdersCount: filter => dispatch(actions.initOrdersCount(filter)),
        setTabIndex: tabIndex => dispatch(actions.setTabOrderIndex(tabIndex)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(OrderListPage),
);
