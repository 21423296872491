import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    dropFile: {
        id: 'maps.dropFile',
        defaultMessage:
            'Arraste um arquivo ou clique para fazer o upload de um novo documento',
    },
    rejectFile: {
        id: 'maps.rejectFile',
        defaultMessage: 'Tipo de arquivo não suportado',
    },
});
