import React, { useContext, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import * as actions from '../../../store/actions/index';
import messages from '../CteDetailsPage.intl';
import DynamicModalOrdersDocuments from '../../../components/DynamicModalOrdersDocuments/DynamicModalOrdersDocuments';
import { AuthContext } from '../../../context/AuthContext';

const ModalCteOrders = props => {
    const {
        intl,
        documentId,
        isOpen,
        handleToggle,
        cteOrders,
        cteOrdersCount,
        loadingCteOrders,
        selectedDocument,
    } = props;
    const context = useContext(AuthContext);
    const [CNPJTakerAlt, setCNPJTakerAlt] = useState('');

    useEffect(() => {
        if (documentId !== 'new') {
            props.onInitCteOrdersCount(documentId);
        }
    }, []);

    useEffect(() => {
        if (selectedDocument?.form?.fields) {
            getCNPJTaker();
        }
    }, [selectedDocument]);

    const reloadCteList = query => {
        props.onInitCteOrders(documentId, query);
        props.onInitCteOrdersCount(documentId);
    };

    const getCNPJTaker = () => {
        const formFields = selectedDocument?.form?.fields;
        const TomaTag = formFields?.find(
            x => x.key === '/cteProc/CTe/infCte/ide/toma3/toma',
        )?.value;

        let keyCNPJTaker = '';
        switch (String(TomaTag).trim()) {
            case '0':
                keyCNPJTaker = '/cteProc/CTe/infCte/rem/CNPJ';
                break;
            case '1':
                keyCNPJTaker = '/cteProc/CTe/infCte/exped/CNPJ';
                break;
            case '2':
                keyCNPJTaker = '/cteProc/CTe/infCte/receb/CNPJ';
                break;
            case '3':
                keyCNPJTaker = '/cteProc/CTe/infCte/dest/CNPJ';
                break;
            default:
                keyCNPJTaker = '/cteProc/CTe/infCte/toma/CNPJ';
                break;
        }

        const hasCTeOS =
            formFields?.find(x => x.key.includes('/cteOSProc/CTeOS')) &&
            'CTeOS';

        if (hasCTeOS === 'CTeOS') {
            keyCNPJTaker = keyCNPJTaker?.replace(
                '/cteProc/CTe',
                '/cteOSProc/CTeOS',
            );
        }

        const CNPJTakerValue = formFields
            ?.find(x => x.key === keyCNPJTaker)
            ?.value?.replace(/[^0-9]/g, '');

        setCNPJTakerAlt(CNPJTakerValue);
    };

    return (
        <>
            <DynamicModalOrdersDocuments
                isOpen={isOpen}
                handleToggle={handleToggle}
                selectedDocument={selectedDocument}
                documentId={documentId}
                ordersDocument={cteOrders}
                ordersDocumentCount={cteOrdersCount}
                ordersToFilter={cteOrders}
                loading={loadingCteOrders}
                loadOrdersDocument={reloadCteList}
                titleModal={intl.formatMessage(messages.associatedOrderItems)}
                functionDispatch={() => {
                    reloadCteList('?sort=-createdAt&skip=0&take=5&page=0');
                }}
                orderIntegrationClaim={context.hasClaim(
                    'cte-document-order-integration-update',
                )}
                CNPJTakerAlt={CNPJTakerAlt}
                docType={'CTe'}
            />
        </>
    );
};
const mapStateToProps = state => {
    return {
        cteOrders: state.cteReducer.cteOrders || [],
        cteOrdersCount: state.cteReducer.cteOrdersCount || 0,
        loadingCteOrders: state.cteReducer.loadingCteOrders,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitCteOrders: (documentId, filter) =>
            dispatch(actions.initCteOrders(documentId, filter)),
        onInitCteOrdersCount: documentId =>
            dispatch(actions.initCteOrdersCount(documentId)),
    };
};

ModalCteOrders.propTypes = {
    selectedDocument: PropTypes.object,
    documentId: PropTypes.string,
    isOpen: PropTypes.bool,
    handleToggle: PropTypes.func,
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalCteOrders),
);
