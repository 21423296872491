import React from 'react';
import { injectIntl } from 'react-intl';

import { Container, Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import Modal from '../Modal/Modal';
import messages from '../DynamicFilterModal/DynamicFilterModal.intl';
import DynamicFormFilterField from '../DynamicFormFilterField/DynamicFormFilterField';

const FormFilterModal = props => {
    const { intl, filters, handleFormSubmit, handleResetFilter } = props;

    return (
        <Modal
            title={props.filterEntityTitle}
            isOpen={props.isOpen}
            handleToggle={props.handleToggle}
            hasFooter={false}
            size='lg'
        >
            <AvForm onValidSubmit={handleFormSubmit}>
                <Container fluid>
                    <Row>
                        {props.properties.map(property => {
                            const filter = filters.find(
                                f => f.id === property.key,
                            );
                            return (
                                <DynamicFormFilterField
                                    key={property.key}
                                    property={property}
                                    filter={filter}
                                />
                            );
                        })}
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12}>
                            <button type='submit' disabled={props.loading}>
                                {intl.formatMessage(messages.filterButton)}
                            </button>

                            <button type='button' onClick={handleResetFilter}>
                                {intl.formatMessage(messages.restoreButton)}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </Modal>
    );
};

export default injectIntl(FormFilterModal);
