import React, { useContext, useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { formatFilters } from '../DocumentListPage/DocumentListPage';

import { Container } from 'reactstrap';
import EvtTable, { buildFilterOr } from '../../components/EvtTable/EvtTable';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import messages from './PendingFilesList.intl';
import {
    pendingFilesColumns,
    pendingFilesProperties,
} from './components/PendingFilesColumns';

function PendingFilesListPage(props) {
    const { intl, loadingList, pendingFiles, pendingFilesCount } = props;

    const history = useHistory();

    // States
    const [query, setQuery] = useState('');
    const [filesFilters, setFilesFilters] = useState([]);

    //Mount Page
    useEffect(() => {
        const URL = window.location.search;

        if (URL.includes('&search=')) {
            const filtersURL = formatFilters(URL);
            setFilesFilters(filtersURL);
        }
    }, []);

    //Update Page
    useEffect(() => {
        reloadPendingFilesList();
    }, [query]);

    const reloadPendingFilesList = () => {
        let filterQuery = query;

        if (query.includes('search=')) {
            const value = new URLSearchParams(query).get('search');
            filterQuery = buildFilterOr(value, pendingFilesProperties(intl));
        }

        if (query?.includes('take')) {
            props.onInitPendingFiles(filterQuery);

            history.push('/pendingFiles' + query);
        }
    };

    // handles
    const handleFiltersChange = newFilters => setFilesFilters(newFilters);

    const handleEvTableStateChange = queryTable => {
        setQuery(queryTable);
    };

    const handleDownloadFile = fileId => {
        window.open(`${window.API_URI}/PendingFile/File/${fileId}`, 'Download');
    };

    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => {
                history.push(`/pendingFiles/${rowInfo.original.id}`);
            },
        };
    }

    return (
        <>
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/pendingFiles',
                            name: intl.formatMessage(messages.pendingFiles),
                            active: true,
                        },
                    ]}
                />
                <>
                    <HeaderTable
                        title={intl.formatMessage(messages.pendingFiles)}
                        properties={pendingFilesProperties(intl)}
                        handleFiltersChange={handleFiltersChange}
                        loading={loadingList}
                        onClickReload={() => reloadPendingFilesList()}
                        isAlternativeFilter
                    />
                    <section>
                        <EvtTable
                            filters={filesFilters}
                            columns={pendingFilesColumns(
                                pendingFiles,
                                intl,
                                handleDownloadFile,
                            )}
                            data={pendingFiles}
                            length={pendingFilesCount}
                            pageSize={10}
                            handleStateChange={handleEvTableStateChange}
                            handleGetTrProps={handleGetTrProps}
                            loading={loadingList}
                            defaultSorted={[]}
                            countHeaderText={intl.formatMessage(
                                messages.pendingFilesFound,
                                {
                                    length: pendingFilesCount,
                                },
                            )}
                            search
                        />
                    </section>
                </>
            </Container>
        </>
    );
}

const mapStateToProps = state => {
    return {
        pendingFiles: state.pendingFilesReducer.pendingFiles || [],
        pendingFilesCount: state.pendingFilesReducer.pendingFilesCount || 0,
        loadingList: state.pendingFilesReducer.loadingList || false,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitPendingFiles: filter =>
            dispatch(actions.initPendingFiles(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(PendingFilesListPage)),
);
