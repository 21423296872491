import { toast } from 'react-toastify';
import * as actionTypes from './actionTypes';
import axios from 'axios';

export const initGeneratedReports = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.GENERATED_REPORTS_GET_LIST_REQUEST,
        });

        axios
            .get('/Reports' + (filter ? filter : ''))
            .then(response => {
                dispatch({
                    type: actionTypes.GENERATED_REPORTS_GET_LIST_SUCCESS,
                    generatedReports: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GENERATED_REPORTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initCheckGeneratedReportStatus = (reportId, reportsArray) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.GENERATED_REPORT_GET_DETAILS_REQUEST,
        });

        axios
            .get('/Reports/' + (reportId))
            .then(response => {
                const generateReportNewStatus = response.data

                if (generateReportNewStatus?.status !== 'Pending') {
                    const generatedReports = reportsArray || getState().reportReducer.generatedReports;
                    const newGeneratedReports = generatedReports.filter(x => x.reportId !== generateReportNewStatus.reportId);

                    newGeneratedReports.unshift({ ...generateReportNewStatus })

                    dispatch({
                        type: actionTypes.GENERATED_REPORTS_GET_LIST_SUCCESS,
                        generatedReports: newGeneratedReports
                    });
                }

                dispatch({
                    type: actionTypes.GENERATED_REPORT_GET_DETAILS_SUCCESS,
                    generateReportNewStatus,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GENERATED_REPORT_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const initGeneratedReportsCount = filter => {
    return async dispatch => {
        dispatch({
            type: actionTypes.GENERATED_REPORTS_GET_COUNT_REQUEST,
        });

        await axios
            .get('/Reports/Count' + (filter || ''))
            .then(response => {
                dispatch({
                    type: actionTypes.GENERATED_REPORTS_GET_COUNT_SUCCESS,
                    generatedReportsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GENERATED_REPORTS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initReports = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.REPORTS_GET_LIST_REQUEST,
        });

        axios
            .get('/Reports/AvailableReports' + (filter ? filter : ''))
            .then(response => {
                dispatch({
                    type: actionTypes.REPORTS_GET_LIST_SUCCESS,
                    reports: response.data.results,
                    reportsCount: response.data.count,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.REPORTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const generateReports = (reportType, filters) => {
    return dispatch => {
        dispatch({
            type: actionTypes.GENERATE_REPORT_GET_REQUEST,
        });
        axios
            .post(`/Reports/GenerateReport/${reportType}`, filters || [])
            .then(response => {
                dispatch({
                    type: actionTypes.GENERATE_REPORT_GET_SUCCESS,
                });
                toast.success('Geração do relatório iniciada com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GENERATE_REPORT_GET_FAILURE,
                    error,
                });
            });
    };
};

// Get Reports
export const initScheduledReports = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.REPORTS_SCHEDULED_GET_REQUEST,
        });

        axios
            .get(`/Reports/ScheduledReports`)
            .then(response => {
                dispatch({
                    type: actionTypes.REPORTS_SCHEDULED_GET_SUCCESS,
                    scheduledReports: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.REPORTS_SCHEDULED_GET_FAILURE,
                    error,
                });
            });
    };
};

export const setStateReportFilters = reportFilters => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_REPORT_FILTERS,
            reportFilters,
        });
    };
};
