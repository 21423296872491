export function URIDecode(URIToDecode) {
    try {
        let URIDecodedComponent = decodeURIComponent(URIToDecode);
        return URIDecodedComponent;
    } catch (error) {
        const codedList = [
            { encode: '%3B', decode: ';' },
            { encode: '%3A', decode: ':' },
            { encode: '%40', decode: '@' },
            { encode: '%26', decode: '&' },
            { encode: '%3D', decode: '=' },
            { encode: '%2B', decode: '+' },
            { encode: '%24', decode: '$' },
            { encode: '%2C', decode: ',' },
            { encode: '%2F', decode: '/' },
            { encode: '%3F', decode: '?' },
            { encode: '%23', decode: '#' },
            { encode: '%5B', decode: '[' },
            { encode: '%5D', decode: ']' },
            { encode: '%20', decode: ' ' },
        ];
        let URIDecoded = URIToDecode;

        codedList.forEach(element => {
            URIDecoded = URIDecoded.replaceAll(element.encode, element.decode);
        });

        return URIDecoded;
    }
}
