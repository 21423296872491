import * as actionTypes from '../actions/actionTypes';

const initialState = {
    reports: [],
    reportsCount: 0,
    loadingList: false,

    generatedReports: [],
    generateReportNewStatus: {},
    generatedReportsCount: 0,
    loadingListGenerated: false,

    scheduledReports: [],
    reportFilters: [],
};

// Reducers
const setLoadingListTrue = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setLoadingFalseError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const setReports = (state, { reports, reportsCount }) => {
    return {
        ...state,
        reports,
        reportsCount,
        loadingList: false,
    };
};

const setGeneratedReports = (state, { generatedReports }) => {
    return {
        ...state,
        generatedReports,
        loadingListGenerated: false,
    };
};

const setGeneratedReportNewStatus = (state, { generateReportNewStatus }) => {
    return {
        ...state,
        generateReportNewStatus,
    };
};

const setGeneratedReportsCount = (state, { generatedReportsCount }) => {
    return {
        ...state,
        generatedReportsCount,
    };
};

const setScheduledReports = (state, { scheduledReports }) => {
    return {
        ...state,
        scheduledReports,
    };
};

const setReportFilters = (state, { reportFilters }) => {
    return {
        ...state,
        reportFilters,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //#region GeneratedReports
        //REQUEST
        case actionTypes.GENERATED_REPORTS_GET_LIST_REQUEST:
            return {
                ...state,
                loadingListGenerated: true,
            };

        //FAILURE
        case actionTypes.GENERATED_REPORTS_GET_LIST_FAILURE:
        case actionTypes.GENERATED_REPORT_GET_DETAILS_FAILURE:
        case actionTypes.GENERATED_REPORTS_GET_COUNT_FAILURE:
            return {
                ...state,
                error: action.error || null,
                loadingListGenerated: false,
            };

        case actionTypes.GENERATED_REPORTS_GET_COUNT_SUCCESS:
            return setGeneratedReportsCount(state, action);

        case actionTypes.GENERATED_REPORTS_GET_LIST_SUCCESS:
            return setGeneratedReports(state, action);
        
        case actionTypes.GENERATED_REPORT_GET_DETAILS_SUCCESS:
            return setGeneratedReportNewStatus(state, action);
        //#endregion

        //#region Reports
        //REQUEST
        case actionTypes.REPORTS_GET_LIST_REQUEST:
        case actionTypes.GENERATE_REPORT_GET_REQUEST:
            return setLoadingListTrue(state, action);

        //FAILURE
        case actionTypes.REPORTS_GET_LIST_FAILURE:
        case actionTypes.GENERATE_REPORT_GET_FAILURE:
        case actionTypes.GENERATE_REPORT_GET_SUCCESS:
            return setLoadingFalseError(state, action);

        case actionTypes.REPORTS_GET_LIST_SUCCESS:
            return setReports(state, action);
        //#endregion

        case actionTypes.REPORTS_SCHEDULED_GET_SUCCESS:
            return setScheduledReports(state, action);

        case actionTypes.SET_REPORT_FILTERS:
            return setReportFilters(state, action);

        default:
            return state;
    }
};

export default reducer;
