import { defineMessages } from "react-intl";

const messages = defineMessages({
  displayName: {
    id: "detailsTab.displayName",
    defaultMessage: "Nome Fantasia"
  },

  companyName: {
    id: "detailsTab.companyName",
    defaultMessage: "Razão Social"
  },

  cnpj: {
    id: "detailsTab.cpfcnpj",
    defaultMessage: "CPF/CNPJ"
  },

  street: {
    id: "detailsTab.street",
    defaultMessage: "Endereço"
  },

  number: {
    id: "detailsTab.number",
    defaultMessage: "Número"
  },

  complement: {
    id: "detailsTab.complement",
    defaultMessage: "Complemento"
  },

  neighborhood: {
    id: "detailsTab.neighborhood",
    defaultMessage: "Bairro"
  },

  city: {
    id: "detailsTab.city",
    defaultMessage: "Cidade"
  },

  state: {
    id: "detailsTab.state",
    defaultMessage: "Estado"
  },

  postalCode: {
    id: "detailsTab.postalCode",
    defaultMessage: "CEP"
  },

  municipalRegistration: {
    id: "detailsTab.municipalRegistration",
    defaultMessage: "Inscrição municipal"
  },

  email: {
    id: "detailsTab.email",
    defaultMessage: "E-mail"
  },

  invalidCNPJ: {
    id: "global.invalidCNPJ",
    defaultMessage: "CPF ou CNPJ inválido"
  },

  invalidPostalCode: {
    id: "global.invalidPostalCode",
    defaultMessage: "CEP inválido"
  },

  submitButton: {
    id: "global.submitButton",
    defaultMessage: "Enviar"
  },

  deleteButton: {
    id: "global.deleteButton",
    defaultMessage: "Excluir"
  },

  company: {
    id: "contractors.titleOnlyContractor",
    defaultMessage: "Contratante"
  },

  erpcode: {
    id: "suppliers.erpcode",
    defaultMessage: "Id ERP"
  },

  associateMap: {
    id: "suppliers.associateMap",
    defaultMessage: "Associar mapeamento de nfse automaticamente caso disponível"
  },

  notRequiredOrderToSendDocuments: {
    id: "suppliers.notRequiredOrderToSendDocuments",
    defaultMessage: "Não requerer pedido para enviar documentos desse fornecedor"
  },

  disabled: {
    id: "suppliers.disabled",
    defaultMessage: "Desabilitar"
  },

  customLayout: {
    id: "suppliers.customLayout",
    defaultMessage: "Fornecedor com layout customizado"
  },

  categoryMap: {
    id: "suppliers.categoryMap",
    defaultMessage: "Preferência de categoria para mapeamento NFSe"
  },
});

export default messages;
