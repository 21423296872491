import React, { Component } from 'react';
import { withRouter } from 'react-router';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import messages from './OrderItemsTable.intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from '../../components/Header/Header';
import EvtTable from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';
import { itemsColumns } from './components/Columns';
import { Container } from 'reactstrap';

class OrderItemsTable extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    reloadOrderItemsList = () => {
        const { query } = this.state;
        const orderId = this.props.order.orderId;

        const searchParams = new URLSearchParams();
        searchParams.set('query', query);
        
        this.props.history.push(`/orders/${orderId}${query}`);

        this.props.OnInitOrderItems(orderId, query);
        this.props.OnInitOrderItemsCount(orderId, query);
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.history.push(
                    `/orders/${rowInfo.original.orderId}/items/${
                        rowInfo.original.orderItemId
                    }`,
                ),

            className: !rowInfo?.original?.isActive
                ? 'disabled-row'
                : 'tab-inside',
            title: !rowInfo?.original?.isActive
                ? this.props.intl.formatMessage(messages.unActiveItem)
                : '',
        };
    };

    handleNewClick = () => {
        this.props.history.push(
            `/orders/${this.props.match.params.id}/items/new`,
        );
    };

    handleRefreshButtonClick = e => {
        this.reloadOrderItemsList();
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadOrderItemsList();
            },
        );
    };

    render() {
        const {
            intl,
            loadingItemsList,
            orderItems,
            orderItemsCount,
        } = this.props;
        const { filters } = this.state;

        const newOrderItemButtonEnabled = this.context.hasClaim('order-create');
        const properties = [
            {
                key: 'itemOrder',
                value: intl.formatMessage(messages.itemOrder),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'description',
                value: intl.formatMessage(messages.description),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'quantity',
                value: intl.formatMessage(messages.quantity),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'measureUnity',
                value: intl.formatMessage(messages.measureUnity),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'ncm',
                value: intl.formatMessage(messages.ncm),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'totalNetValue',
                value: intl.formatMessage(messages.totalNetValue),
                type: filterPropertyTypes.TEXT,
            },

            {
                key: 'cnpjTaker',
                value: intl.formatMessage(messages.cnpjTaker),
                type: filterPropertyTypes.TEXT,
            },
        ];

        return (
            <Container fluid>
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.orderItemTitle)}
                            subtitle={
                                this.props.orderItemsCount +
                                intl.formatMessage(messages.subtitleOrderItem)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingItemsList}
                            tabInside
                            hideAddButton
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingItemsList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                        {newOrderItemButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOrderItem,
                                        {
                                            entity: '',
                                        },
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={itemsColumns(intl, orderItems)}
                        data={orderItems}
                        length={orderItemsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingItemsList}
                        tabInside
                        fetchPage
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        order: state.orderReducer.order,
        orderItems: state.orderReducer.orderItems,
        orderItemsCount: state.orderReducer.orderItemsCount || 0,
        loadingItemsList: state.orderReducer.loadingItemsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnInitOrderItems: (orderId, filter) =>
            dispatch(actions.initOrderItems(orderId, filter)),
        OnInitOrderItemsCount: (orderId, query) =>
            dispatch(actions.initOrderItemsCount(orderId, query)),
    };
};

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(OrderItemsTable),
    ),
);
