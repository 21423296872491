import React from 'react';
import { injectIntl } from "react-intl";

import {
    FormGroup,
    Col
} from 'reactstrap';

import {
    AvField
} from "availity-reactstrap-validation";

import messages from "../../MapItemDetailsPage.intl";


const insertMapActionItemForm = (props) => {
    const {intl} = props;    
    return (
        <React.Fragment>
            <Col xs={12} md={4}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.from)}
                        type="number"
                        name='parameters.StartIndex'
                        value={props.parameters.StartIndex}
                        errorMessage=" "
                        required />
                </FormGroup>
            </Col>
            <Col xs={12} md={8}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.text)}
                        type="text"
                        name='parameters.Value'
                        value={props.parameters.Value}
                        errorMessage=" "
                        required />
                </FormGroup>
            </Col>
        </React.Fragment>

    );
}

export default injectIntl(insertMapActionItemForm);