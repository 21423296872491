import React from 'react';
import { injectIntl } from 'react-intl';
import messages from './DatePicker.intl';
import { FormGroup, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

const DatePicker = props => {
    const { intl, filter } = props;

    return (
        <React.Fragment>
            <Col lg={3} md={3} sm={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.from)}
                        type='date'
                        name={`filters[${filter.id}].valueFrom`}
                        id={`filters[${filter.id}].valueFrom`}
                        value={filter.valueFrom}
                        validate={{ datetime: true }}
                        onChange={e =>
                            props.handleFieldChange(e, filter.id, 'valueFrom')
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={3} md={3} sm={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.to)}
                        type='date'
                        name={`filters[${filter.id}].valueTo`}
                        id={`filters[${filter.id}].valueTo`}
                        value={filter.valueTo}
                        validate={{ datetime: true }}
                        onChange={e =>
                            props.handleFieldChange(e, filter.id, 'valueTo')
                        }
                    />
                </FormGroup>
            </Col>
        </React.Fragment>
    );
};

export default injectIntl(DatePicker);
