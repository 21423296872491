import { defineMessages } from "react-intl";
import globalMessages from "../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  suppliersCounter: {
    id: "dashboard.suppliersCounter",
    defaultMessage: "fornecedor(es) cadastrado(s)"
  }
});
