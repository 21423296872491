import Dashboard from '../containers/Dashboard/Dashboard';
import ContractorListPage from '../containers/ContractorListPage/ContractorListPage';
import ContractorDetailsPage from '../containers/ContractorDetailsPage/ContractorDetailsPage';
import CompanyDetailsPage from '../containers/CompanyDetailsPage/CompanyDetailsPage';
import SupplierListPage from '../containers/SupplierListPage/SupplierListPage';
import SupplierDetailsPage from '../containers/SupplierDetailsPage/SupplierDetailsPage';
import DocumentListPage from '../containers/DocumentListPage/DocumentListPage';
import NfeListPage from '../containers/NfeListPage/NfeListPage';
import NfeDetailsPage from '../containers/NfeDetailsPage/NfeDetailsPage';
import CteListPage from '../containers/CteListPage/CteListPage';
import CteDetailsPage from '../containers/CteDetailsPage/CteDetailsPage';
import IntegrationListPage from '../containers/IntegrationListPage/IntegrationListPage';
import DocumentDetailsPage from '../containers/DocumentDetailsPage/DocumentDetailsPage';
import OrderDocumentLinkingPage from '../containers/OrderDocumentLinkingPage/OrderDocumentLinkingPage';
import DocumentsConfigsListPage from '../containers/DocumentsConfigsListPage/DocumentsConfigsListPage';
import DocumentFieldDetailsPage from '../containers/DocumentFieldDetailsPage/DocumentFieldDetailsPage';
import CategoryDetailsPage from '../containers/CategoryDetailsPage/CategoryDetailsPage';
import OrderListPage from '../containers/OrderListPage/OrderListPage';
import OrderDetailsPage from '../containers/OrderDetailsPage/OrderDetailsPage';
import TransactionListPage from '../containers/TransactionListPage/TransactionListPage';
import TransactionDetailsPage from '../containers/TransactionDetailsPage/TransactionDetailsPage';
import MapListPage from '../containers/MapListPage/MapListPage';
import MapDetailsPage from '../containers/MapDetailsPage/MapDetailsPage';
import ReportListPage from '../containers/ReportListPage/ReportListPage';
import UserListPage from '../containers/UserListPage/UserListPage';
import UserDetailsPage from '../containers/UserDetailsPage/UserDetailsPage';
import SettingsPage from '../containers/SettingsPage/SettingsPage';
import MapItemDetailsPage from '../containers/MapItemDetailsPage/MapItemDetailsPage';
import RoleListPage from '../containers/RoleListPage/RoleListPage';
import RoleDetailsPage from '../containers/RoleDetailsPage/RoleDetailsPage';
import LogListPage from '../containers/LogListPage/LogListPage';
import SettingsEmailListPage from '../containers/SettingsEmailListPage/SettingsEmailListPage';
import SettingsMailBoxDetailsPage from '../containers/SettingsMailBoxDetailsPage/SettingsMailBoxDetailsPage';
import OrderItemDetailsPage from '../containers/OrderItemDetailsPage/OrderItemDetailsPage';
import PendingFilesListPage from '../containers/PendingFilesListPage/PendingFilesListPage';
import PendingFilesDetailsPage from '../containers/PendingFilesDetailsPage/PendingFilesDetailsPage';
import OtherListPage from '../containers/OtherListPage/OtherListPage';

var indexRoutes = [
    {
        path: '/home',
        name: 'Dashboard',
        component: Dashboard,
        claim: '',
    },
    {
        path: '/contractors',
        name: 'ContractorListPage',
        component: ContractorListPage,
        claim: 'contractor-view',
    },
    {
        path: '/contractors/:id',
        name: 'ContractorDetailsPage',
        component: ContractorDetailsPage,
        claim: 'contractor-view',
    },
    {
        path: '/contractors/:id/companies/:companyid',
        name: 'CompanyDetailsPage',
        component: CompanyDetailsPage,
        claim: 'contractor-view',
    },
    {
        path: '/suppliers',
        name: 'SupplierListPage',
        component: SupplierListPage,
        claim: 'supplier-view',
    },
    {
        path: '/suppliers/:id',
        name: 'SupplierDetailsPage',
        component: SupplierDetailsPage,
        claim: 'supplier-view',
    },
    {
        path: '/orders',
        name: 'OrderListPage',
        component: OrderListPage,
        claim: 'order-view',
    },
    {
        path: '/orders/:id',
        name: 'OrderDetailsPage',
        component: OrderDetailsPage,
        claim: 'order-view',
    },
    {
        path: '/orders/:id/items/:itemId',
        name: 'OrderItemDetailsPage',
        component: OrderItemDetailsPage,
        claim: 'order-view',
    },
    {
        path: '/:historyPath/documentsconfigs',
        name: 'DocumentsConfigsListPage',
        component: DocumentsConfigsListPage,
        claim: 'document-view',
    },
    {
        path: '/:historyPath/documentsconfigs/documentfield/:id',
        name: 'DocumentFieldDetailsPage',
        component: DocumentFieldDetailsPage,
        claim: 'document-view',
    },
    {
        path: '/:historyPath/documentsconfigs/category/:id',
        name: 'CategoryDetailsPage',
        component: CategoryDetailsPage,
        claim: 'document-view',
    },
    {
        path: '/documents',
        name: 'DocumentListPage',
        component: DocumentListPage,
        claim: 'document-view',
    },
    {
        path: ['/documents/:id', '/otherDocuments/:id'],
        name: 'DocumentDetailsPage',
        component: DocumentDetailsPage,
        claim: 'document-view',
    },
    {
        path: [
            '/documents/:id/orders',
            '/nfeDocuments/:id/orders',
            '/cteDocuments/:id/orders',
            '/otherDocuments/:id/orders',
        ],
        name: 'OrderDocumentLinkingPage',
        component: OrderDocumentLinkingPage,
        claim: 'document-view',
    },
    {
        path: '/nfeDocuments',
        name: 'NfeListPage',
        component: NfeListPage,
        claim: 'nfe-document-view',
    },
    {
        path: '/nfeDocuments/:id',
        name: 'NfeDetailsPage',
        component: NfeDetailsPage,
        claim: 'nfe-document-view',
    },
    {
        path: '/cteDocuments',
        name: 'CteListPage',
        component: CteListPage,
        claim: 'document-view',
    },
    {
        path: '/cteDocuments/:id',
        name: 'CteDetailsPage',
        component: CteDetailsPage,
        claim: 'document-view',
    },
    {
        path: '/otherDocuments',
        name: 'OtherListPage',
        component: OtherListPage,
        claim: 'document-view',
    },
    {
        path: '/integrations',
        name: 'IntegrationListPage',
        component: IntegrationListPage,
        claim: 'integration-view',
    },
    {
        path: '/transactions',
        name: 'TransactionListPage',
        component: TransactionListPage,
        claim: 'transaction-view',
    },
    {
        path: '/transactions/:id',
        name: 'TransactionDetailsPage',
        component: TransactionDetailsPage,
        claim: 'transaction-view',
    },
    {
        path: '/maps',
        name: 'MapListPage',
        component: MapListPage,
        claim: 'map-view',
    },
    {
        path: '/maps/:id',
        name: 'MapDetailsPage',
        component: MapDetailsPage,
        claim: 'map-view',
    },

    {
        path: '/maps/:id/item/:itemid',
        name: 'MapActionsPage',
        component: MapItemDetailsPage,
        claim: 'map-view',
    },
    {
        path: '/reports',
        name: 'ReportListPage',
        component: ReportListPage,
        claim: 'report-view',
    },
    {
        path: '/users',
        name: 'UserListPage',
        component: UserListPage,
        claim: 'user-view',
    },
    {
        path: '/users/:id',
        name: 'UserDetailsPage',
        component: UserDetailsPage,
        claim: 'user-view',
    },
    {
        path: '/settings',
        name: 'SettingsPage',
        component: SettingsPage,
        claim: '',
    },
    {
        path: '/settings/settingsmailboxes',
        name: 'SettingsEmailListPage',
        component: SettingsEmailListPage,
        claim: 'setting-view',
    },
    {
        path: '/settings/settingsmailboxes/:id',
        name: 'SettingsMailBoxDetailsPage',
        component: SettingsMailBoxDetailsPage,
        claim: 'setting-view',
    },
    {
        path: '/roles',
        name: 'RoleListPage',
        component: RoleListPage,
        claim: 'role-view',
    },
    {
        path: '/roles/:id',
        name: 'RoleDetailsPage',
        component: RoleDetailsPage,
        claim: 'role-view',
    },
    {
        path: '/logs',
        name: 'LogListPage',
        component: LogListPage,
        claim: '',
    },
    {
        path: '/pendingFiles',
        name: 'PendingFilesListPage',
        component: PendingFilesListPage,
        claim: 'pending-file-view',
    },
    {
        path: '/pendingFiles/:id',
        name: 'PendingFilesDetailsPage',
        component: PendingFilesDetailsPage,
        claim: 'pending-file-view',
    },
];

export default indexRoutes;
