import * as actionTypes from '../actions/actionTypes';

const initialState = {
    integrationsNfse: [],
    integrationsNfseCount: 0,

    integrationsLogs: [],
    integrationsLogsCount: 0,

    integrationsCte: [],
    integrationsCteCount: 0,

    integrationsNfe: [],
    integrationsNfeCount: 0,

    integrationsNFSeNacional: [],
    integrationsNFSeNacionalCount: 0,

    loadingList: true,
    loadingLogs: false,
    loadingCte: false,
    loadingNfe: false,
    loadingNFSeNacional: false
};

const setLoadingTrue = (state, action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setLoadingTrueLogs = (state, action) => {
    return {
        ...state,
        loadingLogs: true,
    };
};

const setLoadingTrueCte = (state, action) => {
    return {
        ...state,
        loadingCte: true,
    };
};

const setLoadingTrueNfe = (state, action) => {
    return {
        ...state,
        loadingNfe: true,
    };
};

const setLoadingTrueNFSeNacional = (state, action) => {
    return {
        ...state,
        loadingNFSeNacional: true,
    };
};

const setLoadingFalseError = (state, { error }) => {
    return {
        ...state,
        loadingList: false,
        loadingLogs: false,
        loadingCte: false,
        error,
    };
};

//Integrations Nfse
const setIntegrationsNfse = (state, { integrationsNfse }) => {
    return {
        ...state,
        integrationsNfse,
        loadingList: false,
    };
};

const setIntegrationsNfseCount = (state, { integrationsNfseCount }) => {
    return {
        ...state,
        integrationsNfseCount,
    };
};

const setIntegrationsLogs = (state, { integrationsLogs }) => {
    return {
        ...state,
        integrationsLogs,
        loadingLogs: false,
    };
};

const setIntegrationsLogsCount = (state, { integrationsLogsCount }) => {
    return {
        ...state,
        integrationsLogsCount,
    };
};

const setIntegrationsCte = (state, { integrationsCte }) => {
    return {
        ...state,
        integrationsCte,
        loadingCte: false,
    };
};

const setIntegrationsCteCount = (state, { integrationsCteCount }) => {
    return {
        ...state,
        integrationsCteCount,
    };
};

const setIntegrationsNfe = (state, { integrationsNfe }) => {
    return {
        ...state,
        integrationsNfe,
        loadingNfe: false,
    };
};

const setIntegrationsNfeCount = (state, { integrationsNfeCount }) => {
    return {
        ...state,
        integrationsNfeCount,
    };
};

const setIntegrationsNFSeNacional = (state, { integrationsNFSeNacional, integrationsNFSeNacionalCount }) => {
    return {
        ...state,
        integrationsNFSeNacional,
        integrationsNFSeNacionalCount,
        loadingNFSeNacional: false,
    };
};

const setTabIndex = (state, action) => {
    return {
        ...state,
        tabIndex: action.tabIndex,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //REQUEST
        case actionTypes.INTEGRATIONS_NFS_GET_LIST_REQUEST:
            return setLoadingTrue(state, action);

        case actionTypes.INTEGRATIONS_LOGS_GET_LIST_REQUEST:
            return setLoadingTrueLogs(state, action);

        case actionTypes.INTEGRATIONS_CTE_GET_LIST_REQUEST:
            return setLoadingTrueCte(state, action);
        
        case actionTypes.INTEGRATIONS_NFE_GET_LIST_REQUEST:
            return setLoadingTrueNfe(state, action);

        case actionTypes.INTEGRATIONS_NATIONAL_NFSE_GET_LIST_REQUEST:
            return setLoadingTrueNFSeNacional(state, action);

        //FAILURE
        case actionTypes.INTEGRATIONS_NFS_GET_LIST_FAILURE:
        case actionTypes.INTEGRATIONS_NFS_GET_COUNT_FAILURE:
        case actionTypes.INTEGRATIONS_LOGS_GET_LIST_FAILURE:
        case actionTypes.INTEGRATIONS_LOGS_GET_COUNT_FAILURE:
        case actionTypes.INTEGRATIONS_CTE_GET_LIST_FAILURE:
        case actionTypes.INTEGRATIONS_CTE_GET_COUNT_FAILURE:
        case actionTypes.INTEGRATIONS_NFE_GET_LIST_FAILURE:
        case actionTypes.INTEGRATIONS_NFE_GET_COUNT_FAILURE:
        case actionTypes.INTEGRATIONS_NATIONAL_NFSE_GET_LIST_FAILURE:
            return setLoadingFalseError(state, action);

        //NFS
        case actionTypes.INTEGRATIONS_NFS_GET_LIST_SUCCESS:
            return setIntegrationsNfse(state, action);

        case actionTypes.INTEGRATIONS_NFS_GET_COUNT_SUCCESS:
            return setIntegrationsNfseCount(state, action);

        //Log
        case actionTypes.INTEGRATIONS_LOGS_GET_LIST_SUCCESS:
            return setIntegrationsLogs(state, action);

        case actionTypes.INTEGRATIONS_LOGS_GET_COUNT_SUCCESS:
            return setIntegrationsLogsCount(state, action);

        //Cte
        case actionTypes.INTEGRATIONS_CTE_GET_LIST_SUCCESS:
            return setIntegrationsCte(state, action);

        case actionTypes.INTEGRATIONS_CTE_GET_COUNT_SUCCESS:
            return setIntegrationsCteCount(state, action);

        //Nfe
        case actionTypes.INTEGRATIONS_NFE_GET_LIST_SUCCESS:
            return setIntegrationsNfe(state, action);
        
        case actionTypes.INTEGRATIONS_NFE_GET_COUNT_SUCCESS:
            return setIntegrationsNfeCount(state, action);

        //National Nfse
        case actionTypes.INTEGRATIONS_NATIONAL_NFSE_GET_LIST_SUCCESS:
            return setIntegrationsNFSeNacional(state, action)
                    
        //TabIndex
        case actionTypes.INTEGRATIONS_TAB_SET_SUCCESS:
            return setTabIndex(state, action);

        default:
            return state;
    }
};

export default reducer;
