import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    setupName: {
        id: 'settings.setupName',
        defaultMessage: 'Identificador',
    },
    email:{
        id:'settings.email',
        defaultMessage:'Email'
    },
    host: {
        id: 'settings.host',
        defaultMessage: 'Host',
    },
    server: {
        id: 'settings.server',
        defaultMessage: 'Server',
    },
    smtpHost: {
        id: 'settings.smtpHost',
        defaultMessage: 'Host SMTP Server',
    },
    imapHost: {
        id: 'settings.imapHost',
        defaultMessage: 'Host IMAP Server ',
    },
    url: {
        id: 'settings.url',
        defaultMessage: 'Url de Envio',
    },
    active: {
        id: 'settings.active',
        defaultMessage: 'Ativo',
    },

    titleMailBoxes: {
        id: 'settings.titleInbound',
        defaultMessage: 'Caixas de Email',
    },
    mailBox: {
        id: 'settings.inbound',
        defaultMessage: 'Caixa de email',
    },
    titleSettings: {
        id: 'settings.title',
        defaultMessage: 'Configurações',
    },

    subtitle: {
        id: 'settings.subtitle',
        defaultMessage: ' {length} caixas de email encontrados',
    },

    newButtonText: {
        id: 'global.newButtonText',
        defaultMessage: 'Nova {entity}',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
