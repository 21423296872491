import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchBar from '../SearchBar/SearchBar';

import { Operators } from '../../models/FilterBuilder';
import DynamicFormFilterModal from '../DynamicFormFilterModal/DynamicFormFilterModal';
import CheckBox from '../CheckBox/CheckBox';
import { filterPropertyTypes } from '../../utils/enums';
import { URIDecode } from '../../utils/URIDecode';

const EvtAlternativeFilter = props => {
    const {
        hideAddButton,
        tabInside,
        properties,
        handleFiltersChange,
        hideSearchBar,
        loading,
        //Checkbox
        checkboxLabel,
        filterCheckbox,
        checkboxDefaultValue = false,
    } = props;

    const history = useHistory();
    const location = useLocation();

    const [initialFilter, setInitialFilter] = useState({
        showAddButton: false,
        property: '',
        operator: '',
        value: '',
        type: '',
        enums: [],
        id: 'initialFilter',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [showDynamicFilterModal, setShowDynamicFilterModal] = useState(false);
    const [filters, setFilters] = useState([filterCheckbox || initialFilter]);

    const [checkboxValue, setCheckboxValue] = useState(checkboxDefaultValue);

    useEffect(() => {
        getFiltersFromUrl();
        if (filterCheckbox && checkboxValue) {
            setInitialFilter(filterCheckbox);
            handleFiltersChange && handleFiltersChange([filterCheckbox]);
        }
    }, [window.location.search]);

    const getFiltersFromUrl = () => {
        const URL = window.location.search;
        const URL_split = URIDecode(URL)
            .replace('?', '')
            .replaceAll('%3A', ':')
            .split('&');
        if (URL.includes('search=')) {
            URL_split.forEach((filter, index, arr) => {
                const [property, value] = filter
                    .replaceAll('+', ' ')
                    .split(/[[\]:=&?]/gi)
                    .filter(i => i);
                if (property === 'search' && !!value) setSearchTerm(value);
            });
        } else if (URL.includes('filter[')) {
            let newFilters = [];
            let idsArray = [];
            URL_split.forEach((filter, index, arr) => {
                const [property, operator, value, minutes, seconds] = filter
                    .replace('filter', '')
                    .replaceAll('+', ' ')
                    .split(/[[\]:=&?]/gi)
                    .filter(i => i);

                const propertyData = properties.find(p => p.key === property);
                if (!!value) {
                    const filterToAdd = {
                        showAddButton: false,
                        property: property,
                        operator: operator,
                        value,
                        type: propertyData?.type || '',
                        enums: propertyData?.options || [],
                        id: property,
                    };

                    if (filterToAdd.type === filterPropertyTypes.DATE) {
                        filterToAdd.value = `${value}:${minutes}:${seconds}`;
                        const hasId = idsArray.find(
                            id => id === filterToAdd.id,
                        );

                        if (!hasId) {
                            idsArray.push(filterToAdd.id);
                        }

                        switch (filterToAdd.operator) {
                            case Operators.lessThanOrEqualTo:
                                filterToAdd['valueTo'] = value.split('T')[0];
                                break;
                            case Operators.greaterThanOrEqualTo:
                                filterToAdd['valueFrom'] = value.split('T')[0];
                                break;
                            default:
                                break;
                        }
                    }
                    newFilters.push({ ...filterToAdd });
                }
            });

            idsArray.forEach(id => {
                const filterFrom = newFilters.find(f => f.id === id && !!f.valueFrom);
                const filterTo = newFilters.find(f => f.id === id && !!f.valueTo);

                if (!!filterTo && !!filterFrom)
                    filterTo['valueFrom'] = filterFrom?.valueFrom;

                if (!!filterFrom && !!filterTo)
                    filterFrom['valueTo'] = filterTo?.valueTo;

                newFilters = newFilters
                    .filter(f => f.id !== id)
                    .concat([filterTo, filterFrom].filter(Boolean)); // Filtrar qualquer `undefined`
            });

            setFilters(newFilters);
        }
    };

    const handleDynamicFilterModalToogle = () => {
        const URL = window.location.search;
        //Quando não haver um filtro na URL, por exemplo, após restaurar, ele irá verificar e então retirar o último filtro.
        if (!URL.includes('filter[')) {
            setFilters([initialFilter]);
        }

        setShowDynamicFilterModal(!showDynamicFilterModal);
    };

    const handleSearchBarChange = newSearchTerm => {
        setSearchTerm(newSearchTerm);
    };

    const handleSearchBarSubmit = newSearchTerm => {
        setSearchTerm(newSearchTerm);

        const filters = properties?.map(p => {
            let value = newSearchTerm;

            if (p.options && Array.isArray(p.options))
                p.options.forEach(
                    item =>
                        typeof item._map === 'function' &&
                        (value = item._map((value || '').trim().toLowerCase())),
                );

            return {
                property: p.key,
                operator: Operators.like,
                value,
            };
        });

        if (!!newSearchTerm) {
            filters.push({
                operator: Operators.or,
            });

            setFilters([initialFilter]);
            handleFiltersChange && handleFiltersChange(filters, newSearchTerm);
        } else {
            const filtersToReset =
                initialFilter?.id !== 'initialFilter' ? [initialFilter] : [];

            setSearchTerm('');
            handleFiltersChange && handleFiltersChange(filtersToReset);
            history.push(location.pathname);
        }
    };

    function notifyFiltersChange(filters) {
        setSearchTerm('');
        setShowDynamicFilterModal(!showDynamicFilterModal);
        handleFiltersChange && handleFiltersChange(filters);
    }

    const handleFormSubmit = (event, form) => {
        const filters = Object.entries(form.filters).map(filter => {
            const [key, objFilter] = filter;
            const property = properties.find(p => p.key === key);
            let newFilter = null;
            if (
                !!objFilter.value ||
                !!objFilter.valueFrom ||
                !!objFilter.valueTo
            ) {
                let defaultOperator =
                    property.type === filterPropertyTypes.ENUM
                        ? Operators.equalTo
                        : Operators.like;

                newFilter = {
                    showAddButton: false,
                    property: key,
                    operator: property?.operator || defaultOperator,
                    value: objFilter?.value,
                    type: property.type,
                    enums: property?.options || [],
                    id: key,
                };

                if (
                    !!property.maskValue &&
                    typeof property.maskValue === 'function'
                ) {
                    newFilter.value = property.maskValue(newFilter.value);
                }

                if (objFilter?.valueFrom) {
                    newFilter['valueFrom'] = objFilter?.valueFrom;
                }
                if (objFilter?.valueTo) {
                    newFilter['valueTo'] = objFilter?.valueTo;
                }
            }

            return newFilter;
        });

        let array = [];

        let newFilters = filters.map(filter => {
            if (filter && filter.valueFrom) {
                const valueFrom = new Date(
                    filter.valueFrom + 'T00:00:00Z',
                ).toISOString();

                filter.operator = Operators.greaterThanOrEqualTo;
                filter.value = valueFrom;

                array.push({ ...filter });
            }

            if (filter && filter.valueTo) {
                const valueTo = new Date(
                    filter.valueTo + 'T23:59:59Z',
                ).toISOString();

                filter.operator = Operators.lessThanOrEqualTo;
                filter.value = valueTo;
            }
            return filter;
        });

        newFilters = newFilters.concat(array).filter(f => f !== null);
        setFilters(newFilters);
        notifyFiltersChange(newFilters);
    };

    const handleResetFilter = () => {
        const filtersToReset =
            initialFilter?.id !== 'initialFilter' ? [initialFilter] : [];

        setFilters(filtersToReset);
        notifyFiltersChange(filtersToReset);
        history.push(location.pathname);
    };

    const handleCheckboxOnChange = () => {
        const newValue = !checkboxValue;
        setCheckboxValue(newValue);

        let filter = {};

        if (newValue) {
            setInitialFilter(filterCheckbox);
            filter = filterCheckbox;
        } else {
            setInitialFilter({});
            filter = null;
        }

        const newFilters = filter ? [filter] : [];
        handleFiltersChange && handleFiltersChange(newFilters);
    };

    const hasCheckbox = checkboxLabel && filterCheckbox;

    return (
        <>
            <div>
                {!hideSearchBar && (
                    <SearchBar
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchBarChange}
                        handleSearchSubmit={handleSearchBarSubmit}
                        loading={loading}
                        colorVar={!!tabInside}
                    />
                )}
                {hasCheckbox && (
                    <CheckBox
                        label={checkboxLabel}
                        id='checkboxFilter'
                        checked={checkboxValue}
                        value={checkboxValue}
                        onChange={handleCheckboxOnChange}
                        disabled={false}
                    />
                )}
            </div>
            {!hideAddButton && (
                <button
                    className={`new-btn small`}
                    onClick={handleDynamicFilterModalToogle}
                    disabled={loading}
                >
                    <FontAwesomeIcon icon='filter' />
                </button>
            )}
            <DynamicFormFilterModal
                filterEntityTitle={'Filtros'}
                filters={filters}
                isOpen={showDynamicFilterModal}
                handleToggle={() => {
                    setShowDynamicFilterModal(!showDynamicFilterModal);
                }}
                properties={properties}
                handleFormSubmit={handleFormSubmit}
                handleResetFilter={handleResetFilter}
            />
        </>
    );
};

export default EvtAlternativeFilter;
