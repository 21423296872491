import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import { Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import messages from '../PendingFilesDetailsPage.intl';

import Viewer from '../../../components/Viewer/Viewer';
import PendingFileInfoForm from './PendingFileInfoForm';
import { AuthContext } from '../../../context/AuthContext';

const PendingFileForm = props => {
    const {
        intl,
        pendingFile,
        loading,
        handleFormSubmit,
        handleRejectPendingFile,
    } = props;

    const context = useContext(AuthContext)

    const urlDownload = `${window.API_URI}/PendingFile/File/${
        pendingFile?.fileId
    }`;

    const showButton = pendingFile?.status === 'Pending' && context.hasClaim('pending-file-update');

    const [statusHeader, setStatusHeader] = useState({
        title: 'Pendente',
        color: 'warning',
    });

    useEffect(() => {
        if (pendingFile) {
            switch (pendingFile.status) {
                case 'Rejected':
                    setStatusHeader({ title: 'Rejeitado', color: 'danger' });
                    break;
                case 'Sended':
                    setStatusHeader({ title: 'Enviado', color: 'success' });
                    break;
                default:
                    setStatusHeader({ title: 'Pendente', color: 'warning' });
                    break;
            }
        }
    }, [pendingFile]);

    return (
        <>
            {!!pendingFile && (
                <section className='map-nota row'>
                    <section className='col-6'>
                        <Viewer
                            readOnly={true}
                            url={urlDownload}
                            urlDownload={urlDownload}
                            boxes={[]}
                            verboseBoxes={[]}
                            handleShowVerboseBoxesChecked={() => {}}
                            handleEventsClick={() => {}}
                        />
                    </section>
                    <section className='fields-maps col-6'>
                        <AvForm onValidSubmit={handleFormSubmit}>
                            <Row
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'end',
                                    paddingRight: 10,
                                }}
                            >
                                {showButton && (
                                    <>
                                        <Col xs={{ size: 2.4 }}>
                                            <button
                                                type='button'
                                                disabled={loading}
                                                className='btn-delete'
                                                onClick={
                                                    handleRejectPendingFile
                                                }
                                            >
                                                {intl.formatMessage(
                                                    messages.reject,
                                                )}
                                            </button>
                                        </Col>
                                        <Col xs={{ size: 2.4 }}>
                                            <button
                                                type='submit'
                                                disabled={loading}
                                                className='btn-submit'
                                            >
                                                {intl.formatMessage(
                                                    messages.submitButton,
                                                )}
                                            </button>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row>
                                <Col className='cards-fields' xs={12}>
                                    <PendingFileInfoForm
                                        pendingFile={pendingFile}
                                        disableSend={!showButton}
                                        statusHeader={statusHeader}
                                    />
                                </Col>
                            </Row>
                        </AvForm>
                    </section>
                </section>
            )}
        </>
    );
};

export default injectIntl(PendingFileForm);
