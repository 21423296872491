import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    itemDetails: {
        id: 'maps.itemDetails',
        defaultMessage: 'Detalhes do item',
    },
    itemCleanup: {
        id: 'maps.itemCleanup',
        defaultMessage: 'Limpar',
    },
});
