import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, FormGroup, Label, Input } from 'reactstrap';

import { injectIntl } from 'react-intl';
import messages from './Toolbar.intl';

import { viewerModeTypes } from '../../../utils/enums';
import LogIcon from '../../../assets/icons/LogIcon';

const toolbar = props => {
    const { intl } = props;
    return (
        <section className='btns-map'>
            <div className='btns-top-map'>
                {!props.readOnly && (
                    <button
                        title='Shortcut (ALT) + (n)'
                        className={
                            props.mode === viewerModeTypes.ADD
                                ? 'active'
                                : 'primary'
                        }
                        onClick={props.handleNewRectClick}
                    >
                        <FontAwesomeIcon icon='plus' />
                    </button>
                )}
                <button
                    title='Shortcut (ALT) + (p)'
                    className={
                        props.mode === viewerModeTypes.POINTER
                            ? 'active'
                            : 'primary'
                    }
                    onClick={props.handleMousePointClick}
                >
                    <FontAwesomeIcon icon='mouse-pointer' />
                </button>
                <button
                    title='Shortcut (ALT) + (m)'
                    className={
                        props.mode === viewerModeTypes.MOVE
                            ? 'active'
                            : 'primary'
                    }
                    onClick={props.handleMoveClick}
                >
                    <FontAwesomeIcon icon='arrows-alt' />
                </button>
                <button
                    title='Shortcut (ALT) + (-)'
                    className=''
                    onClick={e => props.handleZoomChange(e, -0.1)}
                >
                    <FontAwesomeIcon icon='search-minus' />
                </button>
                <button
                    title='Shortcut (ALT) + (+)'
                    className=''
                    onClick={e => props.handleZoomChange(e, 0.1)}
                >
                    <FontAwesomeIcon icon='search-plus' />
                </button>
                <button
                    title='Shortcut (ALT) + (d)'
                    className=''
                    onClick={e => props.handleDownloadClick()}
                >
                    <FontAwesomeIcon icon='download' />
                </button>
                {props.showXmlButton && (
                    <button
                        title='Xml'
                        className=''
                        onClick={e => props.handleDownloadXmlClick()}
                    >
                       xml
                    </button> )
                }
                {props.showEventsButton && (
                    <button
                        title='Eventos'
                        className=''
                        onClick={e => props.handleEventsClick()}
                    >
                        <FontAwesomeIcon icon='address-book' />
                    </button> )
                }
                <button
                    title='Histórico'
                    onClick={e => props.handleHistoryClick()}
                >
                    <LogIcon
                        width={16}
                        height={16}
                        style={{
                            color: '#3a6bfc'
                        }}
                        color='#3a6bfc'
                    />
                </button>
                {props.showChildDocumentsButton && (
                    <button
                        title={intl.formatMessage(messages.viewerToolbarAssociatedDocuments)}
                        className=''
                        onClick={e => props.handleShowChildDocumentsClick()}
                    >
                        <FontAwesomeIcon icon='address-book' />
                    </button> )
                }
            </div>
            <div className='infos-top-map'>
                <Badge className='percent-image'>
                    {Math.round(props.scale * 100)}%
                </Badge>
                <Badge
                    className='method-image'
                    color={props.readOnly ? 'info' : 'warning'}
                >
                    {intl.formatMessage(messages.viewerToolbarModeText, {
                        mode: props.readOnly
                            ? intl.formatMessage(
                                  messages.viewerToolbarViewingModeText,
                              )
                            : intl.formatMessage(
                                  messages.viewerToolbarEditingModeText,
                              ),
                    })}
                </Badge>
                {props.showVerboseMode && (
                    <div className='checkboxes'>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    onChange={props.handleVerboseChecked}
                                    defaultChecked={false}
                                />{' '}
                                Verbose
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    onChange={
                                        props.handleShowVerboseBoxesChecked
                                    }
                                    defaultChecked={false}
                                />{' '}
                                Show OCR results
                            </Label>
                        </FormGroup>
                    </div>
                )}
            </div>
        </section>
    );
};

export default injectIntl(toolbar);
