import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import { Col, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import Icon from '../../../assets/icons/Index';
import Modal from '../../../components/Modal/Modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MapDetailsViewer = props => {
    const { map, isFull, states } = props;

    const [modalDetails, setModalDetails] = useState(false);

    const history = useHistory();

    useEffect(() => {
        props.onInitStates();
    }, []);

    const getStateByIbgeCode = ibgeCode => {
        const ufCode = Number(String(ibgeCode).slice(0, 2));
        const Uf = states.find(x => x.code === ufCode)?.uf;

        return Uf;
    };

    const mapUf = getStateByIbgeCode(map.ibgeCode);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <p
                style={{ marginBottom: 0, marginTop: 8 }}
                placeholder={`Mapeamento: ${map.name} - ${mapUf}/${map.city}`}
            >
                Mapeamento: {map.name} - {mapUf}/{map.city}
            </p>
            <a onClick={() => setModalDetails(prev => !prev)}>Detalhes</a>

            <Modal
                title={'Detalhes do mapeamento'}
                isOpen={modalDetails}
                handleToggle={() => setModalDetails(prev => !prev)}
            >
                <AvForm>
                    <Row>
                        <Col xs={12}>
                            <AvField
                                label='ID do mapeamento'
                                type='text'
                                name='mapId'
                                value={map?.mapId}
                                disabled
                            />
                        </Col>
                        <Col xs={12}>
                            <AvField
                                label='Nome de mapeamento'
                                type='text'
                                name='name'
                                value={map?.name}
                                disabled
                            />
                        </Col>
                        <Col xs={12}>
                            <AvField
                                label='Estado/Cidade'
                                type='text'
                                name='city'
                                value={`${mapUf}/${map?.city}`}
                                disabled
                            />
                        </Col>
                        <Col xs={12}>
                            <AvField
                                label='Versão'
                                type='text'
                                name='mapVersion'
                                value={Number(map?.mapVersion) + 1}
                                disabled
                            />
                        </Col>
                        <Col xs={12}>
                            <AvField
                                label='Data de Criação'
                                type='text'
                                name='createdAt'
                                value={
                                    map?.createdAt &&
                                    new Date(map?.createdAt).toLocaleString(
                                        navigator.language,
                                    )
                                }
                                disabled
                            />
                        </Col>
                        <Col xs={12}>
                            <AvField
                                label='Atualização'
                                type='text'
                                name='updatedAt'
                                value={
                                    map?.updatedAt &&
                                    new Date(map?.updatedAt).toLocaleString(
                                        navigator.language,
                                    )
                                }
                                disabled
                            />
                        </Col>
                        <Col xs={12}>
                            <AvField
                                label='Tipo de arquivo'
                                type='text'
                                name='fileType'
                                value={map?.fileType}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        {isFull && (
                            <Col xs={12}>
                                <button
                                    type='button'
                                    className='btn-info'
                                    onClick={() =>
                                        history.push(`/maps/${map?.mapId}`)
                                    }
                                >
                                    Acessar
                                </button>
                            </Col>
                        )}
                    </Row>
                </AvForm>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        states: state.stateReducer.states,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitStates: () => dispatch(actions.initStates()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MapDetailsViewer);
