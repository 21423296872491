import React from 'react';
import messages from '../FileUploadForm.intl';

import { Col, Row } from 'reactstrap';

import Modal, { modalTypes } from '../../Modal/Modal';
import { injectIntl } from 'react-intl';
import Loading from '../../Loading/Loading';
import Alert from 'reactstrap/lib/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ModalFile(props) {
    const {
        intl,
        showModalFile,
        loadingCrop,
        handleXmlCancelUploadClick,
        handleXmlConfirmUploadClick,
        loading,
    } = props;

    return (
        <Modal
            noHeader={true}
            isOpen={showModalFile}
            handleToggle={()=>{}}
            type={modalTypes.SUCCESS}
            hasFooter={false}
            firstButtonText={intl.formatMessage(messages.cancelButton)}
            secondButtonText={intl.formatMessage(messages.confirmButton)}
            loading={loadingCrop}
            firstButtonClass='btn-delete'
            secondButtonClass='btn-submit'
            toggleFirstButton={handleXmlCancelUploadClick}
            toggleSecondButton={handleXmlConfirmUploadClick}
            size='xl'
        >
            <Alert color='info'>
                <FontAwesomeIcon icon='info-circle' />{' '}
                {intl.formatMessage(messages.uploadXmlInstructions)}
            </Alert>
            <Loading loading={loadingCrop} />

            <Row>
                <Col xs={{ offset: 2, size: 4 }}>
                    <button
                        className='btn-delete'
                        onClick={handleXmlCancelUploadClick}
                        disabled={loading || loadingCrop}
                    >
                        {intl.formatMessage(messages.cancelButton)}
                    </button>
                </Col>
                <Col xs={4} className='mr-2'>
                    <button
                        className='btn-submit'
                        onClick={handleXmlConfirmUploadClick}
                        disabled={loading || loadingCrop}
                    >
                        {intl.formatMessage(messages.confirmButton)}
                    </button>
                </Col>
            </Row>

            <Row className='d-none' />
        </Modal>
    );
}

export default injectIntl(ModalFile);
