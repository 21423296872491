import React from 'react';
import { injectIntl } from 'react-intl';

import { FormGroup, Col } from 'reactstrap';

import { AvField } from 'availity-reactstrap-validation';

import messages from '../../MapItemDetailsPage.intl';

const KeepMapActionItemForm = props => {
    const { intl } = props;

    return (
        <React.Fragment>
            <Col xs={6}>
                <FormGroup className='mt-5'>
                    <AvField
                        label={'Count'}
                        type='number'
                        name='parameters.Count'
                        value={props.parameters.Count}
                    />
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup className='mt-5'>
                <AvField
                    label={intl.formatMessage(messages.text)}
                    type="text"
                    name="parameters.OldValue"
                    value={props.parameters.OldValue}
                    errorMessage=" "
                    required
                />
                </FormGroup>
            </Col>
        </React.Fragment>
    );
}

export default injectIntl(KeepMapActionItemForm);
