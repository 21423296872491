import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    name: {
        id: 'company.name',
        defaultMessage: 'Nome',
    },
    CNPJCompany: {
        id: 'company.CNPJCompany',
        defaultMessage: 'CNPJ Filial',
    },
    CNPJMatriz: {
        id: 'company.CNPJMatriz',
        defaultMessage: 'CNPJ Matriz',
    },
    city: {
        id: 'company.city',
        defaultMessage: 'Cidade',
    },
    state: {
        id: 'company.state',
        defaultMessage: 'Estado',
    },
    invalidCNPJ: {
        id: 'global.invalidCNPJ',
        defaultMessage: 'CPF ou CNPJ inválido',
    },
    municipalRegistration: {
        id: 'company.municipalRegistration',
        defaultMessage: 'Inscrição municipal',
    },
});

export default messages;
