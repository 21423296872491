import { filterPropertyTypes } from '../../../utils/enums';
import messages from "../IntegrationListPage.intl";

export const NFSeNacionalFilters = (intl) =>
    [{
        key: 'cnpj',
        value: intl.formatMessage(messages.contractorCNPJ),
        type: filterPropertyTypes.NUMBER,
    },
    {
        key: 'searchDate',
        value: intl.formatMessage(messages.searchStartTimeDate),
        type: filterPropertyTypes.DATE,
    },
    {
        key: 'lastNSU',
        value: intl.formatMessage(messages.lastNumber),
        type: filterPropertyTypes.NUMBER,
    },
    {
        key: 'lastNSUReturned',
        value: intl.formatMessage(messages.lastNumberReturned),
        type: filterPropertyTypes.NUMBER,
    },
    {
        key: 'totalCountReturned',
        value: intl.formatMessage(messages.totalNFSeNacionalReturn),
        type: filterPropertyTypes.NUMBER,
    },
    {
        key: 'ibgeCode',
        value: intl.formatMessage(messages.ibgeCode),
        type: filterPropertyTypes.NUMBER,
    },
    {
        key: 'isSuccess',
        value: intl.formatMessage(messages.status),
        type: filterPropertyTypes.ENUM,
        options: [
            {
                key: 'true',
                _map: text =>
                    text.replace(
                        intl
                            .formatMessage(messages.statusSuccess)
                            .toLowerCase(),
                        true,
                    ),
                value: intl.formatMessage(messages.statusSuccess),
            },
            {
                key: 'false',
                _map: text =>
                    text.replace(
                        intl
                            .formatMessage(messages.statusFailed)
                            .toLowerCase(),
                        false,
                    ),
                value: intl.formatMessage(messages.statusFailed, {
                    entity: '',
                }),
            },
        ],
    },
    {
        key: 'message',
        value: intl.formatMessage(messages.errorMessage),
        type: filterPropertyTypes.TEXT,
    }
    ]