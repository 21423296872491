import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';

import messages from './SettingsEmailNotifyForm.intl';
import EvtSelect from '../EvtSelect/EvtSelect';

const SettingsEmailNotifyForm = props => {
    const {
        intl,
        handleFormSubmit,
        settingsEmailToNotify,
        sessionContractor,
        mailBoxes,
        inboundSettings,
    } = props;

    const history = useHistory();
    const [sendNoMap, setSendNoMap] = useState(false);
    const [isEnableDomains, setIsEnableDomains] = useState(false);

    useEffect(() => {
        const isEnable = settingsEmailToNotify?.isEnable;
        const sendDocumentsNoMap =
            settingsEmailToNotify?.allowSendDocumentsWithNoMap;

        if (sendDocumentsNoMap) setSendNoMap(sendDocumentsNoMap);
        if (isEnable) setIsEnableDomains(isEnable);
    }, [settingsEmailToNotify]);

    return (
        !!settingsEmailToNotify && (
            <AvForm
                onValidSubmit={(event, values) =>
                    handleFormSubmit(event, {
                        ...settingsEmailToNotify,
                        ...values,
                        contractorId:
                            inboundSettings?.contractorId ||
                            sessionContractor.contractorId,
                    })
                }
            >
                <h5 className='title-area-form'>
                    {intl.formatMessage(messages.configEmailDomain)}
                </h5>
                <Row>
                    <Col md={4}>
                        <FormGroup check className='required-field'>
                            <AvField
                                style={{ marginTop: 12 }}
                                label={intl.formatMessage(
                                    messages.mailsFromDomains,
                                )}
                                type='checkbox'
                                name='isEnable'
                                id='isEnable'
                                onChange={() => {
                                    setIsEnableDomains(prev => !prev);
                                }}
                                value={settingsEmailToNotify?.isEnable}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        {isEnableDomains ? (
                            <EvtSelect
                                name='userId'
                                label={intl.formatMessage(
                                    messages.usersDomains,
                                )}
                                url={`/Users`}
                                handleSelectedOptionChange={
                                    props.handleUserChange
                                }
                                labelKey='name'
                                valueKey='userId'
                                key={settingsEmailToNotify?.userId}
                                async
                                required
                                option={
                                    settingsEmailToNotify && {
                                        selectedValueOption:
                                            settingsEmailToNotify?.userId,
                                        selectedLabelOption:
                                            settingsEmailToNotify?.userName,
                                    }
                                }
                            />
                        ) : (
                            <FormGroup>
                                <AvField
                                    type='text'
                                    name='userId'
                                    id='userId'
                                    disabled={!isEnableDomains}
                                    label={intl.formatMessage(
                                        messages.usersDomains,
                                    )}
                                    value={''}
                                />
                            </FormGroup>
                        )}
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <AvField
                                type='text'
                                name='domains'
                                id='domains'
                                disabled={!isEnableDomains}
                                label={intl.formatMessage(messages.domains)}
                                value={
                                    (isEnableDomains &&
                                        settingsEmailToNotify?.domains) ||
                                    ''
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <h5 className='title-area-form'>
                    {intl.formatMessage(messages.emailManager)}
                </h5>
                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.notifyEmail)}
                                type='text'
                                name='emailToNotifyWhenUserNotFound'
                                id='emailToNotifyWhenUserNotFound'
                                value={
                                    settingsEmailToNotify?.emailToNotifyWhenUserNotFound ||
                                    ''
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.notifyEmailWhenError)}
                                type='text'
                                name='emailToNotifyWhenInboundDocumentFailed'
                                id='emailToNotifyWhenInboundDocumentFailed'
                                value={
                                    settingsEmailToNotify?.emailToNotifyWhenInboundDocumentFailed ||
                                    ''
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup check className='required-field'>
                            <AvField
                                style={{ marginTop: 12 }}
                                label={intl.formatMessage(
                                    messages.allowSendDocumentsWithNoMap,
                                )}
                                type='checkbox'
                                name='allowSendDocumentsWithNoMap'
                                id='allowSendDocumentsWithNoMap'
                                onChange={() => {
                                    setSendNoMap(prev => !prev);
                                }}
                                value={
                                    settingsEmailToNotify?.allowSendDocumentsWithNoMap
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        {sendNoMap ? (
                            <EvtSelect
                                name='defaultSupplierToUse'
                                label={intl.formatMessage(
                                    messages.defaultSupplierToUse,
                                )}
                                url={`/Contractors/${
                                    inboundSettings?.contractorId
                                }/Suppliers`}
                                handleSelectedOptionChange={
                                    props.handleSupplierChange
                                }
                                labelKey='displayName'
                                labelKey2='documentNumber1'
                                valueKey='supplierId'
                                key={
                                    settingsEmailToNotify?.defaultSupplierToUse
                                }
                                async
                                required
                                option={
                                    settingsEmailToNotify && {
                                        selectedValueOption:
                                            settingsEmailToNotify?.defaultSupplierToUse,
                                        selectedLabelOption:
                                            settingsEmailToNotify?.defaultSupplierNameToUse,
                                    }
                                }
                            />
                        ) : (
                            <FormGroup>
                                <AvField
                                    type='text'
                                    name='defaultSupplierToUse'
                                    id='defaultSupplierToUse'
                                    disabled={!sendNoMap}
                                    label={intl.formatMessage(
                                        messages.defaultSupplierToUse,
                                    )}
                                    value={''}
                                    errorMessage=' '
                                />
                            </FormGroup>
                        )}
                    </Col>
                    <Col md={4}>
                        {sendNoMap ? (
                            <EvtSelect
                                name='defaultMapToUse'
                                label={intl.formatMessage(
                                    messages.defaultMapToUse,
                                )}
                                url={`/Contractors/${
                                    inboundSettings?.contractorId
                                }/Maps`}
                                handleSelectedOptionChange={
                                    props.handleMapChange
                                }
                                labelKey='name'
                                valueKey='mapId'
                                key={settingsEmailToNotify?.defaultMapToUse}
                                async
                                required
                                option={
                                    settingsEmailToNotify && {
                                        selectedValueOption:
                                            settingsEmailToNotify?.defaultMapToUse,
                                        selectedLabelOption:
                                            settingsEmailToNotify?.defaultMapNameToUse,
                                    }
                                }
                                disabled={!sendNoMap}
                            />
                        ) : (
                            <FormGroup>
                                <AvField
                                    type='text'
                                    name='defaultMapToUse'
                                    id='defaultMapToUse'
                                    disabled={!sendNoMap}
                                    label={intl.formatMessage(
                                        messages.defaultMapToUse,
                                    )}
                                    value={''}
                                    errorMessage=' '
                                />
                            </FormGroup>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.skipValidationStepWhenAllFieldsAreValid,
                                )}
                                type='checkbox'
                                name='skipValidationStepWhenAllFieldsAreValid'
                                id='skipValidationStepWhenAllFieldsAreValid'
                                value={
                                    settingsEmailToNotify?.skipValidationStepWhenAllFieldsAreValid
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.autoCancelLowRateOCRDocuments,
                                )}
                                type='checkbox'
                                name='autoCancelLowRateOCRDocuments'
                                id='autoCancelLowRateOCRDocuments'
                                value={
                                    settingsEmailToNotify?.autoCancelLowRateOCRDocuments
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.saveFilesRejectedByIntegrationProccess,
                                )}
                                type='checkbox'
                                name='saveFilesRejectedByIntegrationProccess'
                                id='saveFilesRejectedByIntegrationProccess'
                                value={
                                    settingsEmailToNotify?.saveFilesRejectedByIntegrationProccess
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={6}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 100,
                        }}
                    >
                        <FormGroup>
                            <label className='text'>
                                {mailBoxes > 0
                                    ? intl.formatMessage(
                                          messages.thereAreNoEmails,
                                          {
                                              entity: mailBoxes,
                                          },
                                      )
                                    : intl.formatMessage(
                                          messages.configureEmail,
                                      )}
                            </label>
                        </FormGroup>
                        <button
                            type='button'
                            style={{
                                width: 160,
                                marginTop: 0,
                            }}
                            onClick={() => {
                                history.push('/settings/settingsmailboxes');
                            }}
                        >
                            {intl.formatMessage(messages.buttonConfigureEmails)}
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    </Col>
                </Row>
            </AvForm>
        )
    );
};

export default injectIntl(SettingsEmailNotifyForm);
