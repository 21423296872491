import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    nfeItemDetails: {
        id: 'nfe.nfeItemDetails',
        defaultMessage: 'Detalhes do Item',
    },
});

export default messages;
