import React from "react"

const pendingFiles = ({
    width = '30',
    height = '30',
    viewBox = '0 0 30 30',
    fill = 'none',
}) => (
    <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        style={{ marginLeft: '-5px', marginRight: '25px' }}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path d="M8 12V23C8 24.1046 8.89543 25 10 25H25.5C26.8812 25 28 23.9412 28 22.634V12" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M28 12.5151C28 13.3476 27.6106 14.1276 26.9612 14.5951L20.6604 19.1328C19.0547 20.2891 16.9465 20.2891 15.3407 19.1328L9.03878 14.5939C8.38943 14.1264 8 13.3476 8 12.5138C8 11.125 9.05998 10 10.3658 10H17.5" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M23.5 9L23 4H24L23.5 9Z" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        <circle cx="23.5" cy="12.5" r="1" fill="black" stroke="black" />
    </svg>
)

export default pendingFiles