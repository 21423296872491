import React, { useState, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';

import EvtTable from '../../EvtTable/EvtTable';
import Modal from '../../Modal/Modal';
import { columnsOrdersDocuments } from './Columns';
import messages from '../DynamicModalOrdersDocuments.intl';
import SearchBar from '../../SearchBar/SearchBar';
import { connect } from 'react-redux';
import CheckBox from '../../CheckBox/CheckBox';

function ModalMultiplesOrders(props) {
    const {
        intl,
        // state props
        isOpenSearchOrders,
        loadingItemsBySupplierList,
        selectedOrders,
        orderItemsBySupplier,
        orderItemsBySupplierCount,
        hasAllowOrderDocumentClaim,
        listOnlyOrderFromTheDocumentTakerForAssociation,
        noCheckOrdersFromTaker,
        selectedDocument,
        addItemBtnText,
        associateBtnText,
        CNPJTakerAlt,
        loadingListOrders,
        // Funcs
        handleToggleSearchOrders,
        handleSelectOrderItem,
        handleAddItemSubmit,
        handleAssociateButton,
    } = props;

    const [dataOrderItems, setDataOrderItems] = useState(orderItemsBySupplier);
    const [dataOrderItemsCount, setDataOrderItemsCount] = useState(
        orderItemsBySupplierCount,
    );
    const [searchTerm, setSearchTerm] = useState(orderItemsBySupplier);

    const [listFromDocumentTaker, setListFromDocumentTaker] = useState(false);
    const [filteredOrderItems, setFilteredOrderItems] = useState(
        orderItemsBySupplier,
    );

    useEffect(() => {
        const enableDocumentTaker =
            listOnlyOrderFromTheDocumentTakerForAssociation &&
            !noCheckOrdersFromTaker;

        if (isOpenSearchOrders) setSearchTerm('');
        else setListFromDocumentTaker(false);

        if (enableDocumentTaker && isOpenSearchOrders) {
            setListFromDocumentTaker(
                listOnlyOrderFromTheDocumentTakerForAssociation,
            );
        }
    }, [
        listOnlyOrderFromTheDocumentTakerForAssociation,
        noCheckOrdersFromTaker,
        isOpenSearchOrders,
    ]);

    useEffect(() => {
        let newOrders = [];
        if (
            listFromDocumentTaker &&
            listOnlyOrderFromTheDocumentTakerForAssociation
        ) {
            newOrders = filterDataToTaker();
        }

        if (listFromDocumentTaker) {
            setFilteredOrderItems(newOrders);
            setDataOrderItems(newOrders);
            setDataOrderItemsCount(newOrders?.length);
        } else {
            setFilteredOrderItems(orderItemsBySupplier);
            setDataOrderItems(orderItemsBySupplier);
            setDataOrderItemsCount(orderItemsBySupplier.length);
        }
    }, [
        listFromDocumentTaker,
        orderItemsBySupplier,
        listOnlyOrderFromTheDocumentTakerForAssociation,
    ]);

    const handleSearchBarChange = newSearchTerm => setSearchTerm(newSearchTerm);

    function filterDataToTaker() {
        let newData = [];

        const CNPJTaker =
            CNPJTakerAlt ||
            selectedDocument?.form?.fields
                ?.find(x => x.key.toLowerCase() === 'contractordocumentnumber1')
                ?.value?.replace(/[^0-9]/g, '');

        if (CNPJTaker?.length === 14 || CNPJTaker?.length === 11) {
            newData = orderItemsBySupplier.filter(item => {
                const termLowerCase = String(CNPJTaker)
                    ?.toLowerCase()
                    ?.trim();

                return item?.cnpjTaker
                    ?.replace(/[^0-9]/g, '')
                    ?.toLowerCase()
                    .trim()
                    .includes(termLowerCase);
            });
        }

        return newData;
    }

    function handleSearchBarSubmit(newSearchTerm) {
        if (!newSearchTerm) {
            setDataOrderItems(filteredOrderItems);
            setDataOrderItemsCount(filteredOrderItems?.length);
            return;
        }
        setSearchTerm(newSearchTerm);

        const newOrders = filteredOrderItems.filter(item => {
            const termLowerCase = String(newSearchTerm.toLowerCase()).trim();

            const formatShippingDate = new Date(
                item?.shippingDate,
            ).toLocaleString(navigator.language);

            const formatTotalGrossValue = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(String(item?.totalGrossValue));

            return (
                item?.orderCode?.toLowerCase().includes(termLowerCase) ||
                item?.orderName?.toLowerCase().includes(termLowerCase) ||
                item?.itemOrder?.toLowerCase().includes(termLowerCase) ||
                item?.orderMeasure?.toLowerCase().includes(termLowerCase) ||
                item?.description?.toLowerCase().includes(termLowerCase) ||
                formatTotalGrossValue?.toLowerCase().includes(termLowerCase) ||
                formatShippingDate.toLowerCase().includes(termLowerCase)
            );
        });

        setDataOrderItems(newOrders);
        setDataOrderItemsCount(newOrders?.length);
    }

    return (
        <Modal
            title={intl.formatMessage(messages.searchItemOrders)}
            isOpen={isOpenSearchOrders}
            handleToggle={handleToggleSearchOrders}
            size={'xxl'}
            scrollable={true}
            modalOutBody={
                <>
                    {!props.hideSearchBar && (
                        <header>
                            <div
                                className='btns-topo'
                                style={{
                                    float: 'right',
                                    marginRight: 20,
                                    flexDirection: 'column',
                                }}
                            >
                                <SearchBar
                                    searchTerm={searchTerm}
                                    handleSearchChange={handleSearchBarChange}
                                    handleSearchSubmit={handleSearchBarSubmit}
                                    loading={
                                        loadingListOrders ||
                                        loadingItemsBySupplierList
                                    }
                                    colorVar
                                />
                            </div>
                        </header>
                    )}
                </>
            }
            modalFooter={
                <>
                    {listOnlyOrderFromTheDocumentTakerForAssociation &&
                        !noCheckOrdersFromTaker && (
                            <CheckBox
                                style={{ fontSize: '17px' }}
                                label={intl.formatMessage(
                                    messages.onlyOrderFromTheDocumentTaker,
                                )}
                                id='ListOnlyOrderFromTheDocumentTakerForAssociation'
                                checked={listFromDocumentTaker}
                                value={listFromDocumentTaker}
                                onChange={() => {
                                    setListFromDocumentTaker(
                                        !listFromDocumentTaker,
                                    );
                                }}
                                disabled={false}
                            />
                        )}
                    <button
                        className='btn-submit'
                        onClick={handleAddItemSubmit}
                        style={{
                            float: 'right',
                            marginRight: 10,
                        }}
                    >
                        {addItemBtnText ||
                            intl.formatMessage(messages.confirmButton)}
                    </button>
                    {handleAssociateButton && (
                        <button
                            className='btn-submit'
                            onClick={handleAssociateButton}
                            style={{
                                float: 'right',
                                marginRight: 10,
                                width: 'auto',
                                minWidth: 130,
                                paddingRight: 5,
                                paddingLeft: 5,
                            }}
                        >
                            {associateBtnText ||
                                intl.formatMessage(messages.associateButton)}
                        </button>
                    )}
                </>
            }
            removeFooterButtons
        >
            <Container fluid>
                <Row>
                    <EvtTable
                        fetchPage
                        columns={columnsOrdersDocuments(
                            intl,
                            orderItemsBySupplier,
                            hasAllowOrderDocumentClaim,
                            () => {},
                            true,
                            handleSelectOrderItem,
                            selectedOrders,
                        )}
                        data={dataOrderItems}
                        length={dataOrderItemsCount}
                        pageSize={101}
                        loading={
                            loadingListOrders || loadingItemsBySupplierList
                        }
                        countHeaderText={intl.formatMessage(
                            messages.itemsSubtitle,
                            {
                                length: dataOrderItemsCount,
                            },
                        )}
                        handleStateChange={() => {}}
                        removePagination
                        tabInside
                    />
                </Row>
            </Container>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        orderItemsBySupplier: state.orderReducer.orderItemsBySupplier || [],
        listOnlyOrderFromTheDocumentTakerForAssociation:
            state.orderReducer.listOnlyOrderFromTheDocumentTakerForAssociation,
        orderItemsBySupplierCount:
            state.orderReducer.orderItemsBySupplierCount || 0,
        loadingItemsBySupplierList:
            state.orderReducer.loadingItemsBySupplierList,
        selectedDocument: state.documentReducer.selectedDocument,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalMultiplesOrders),
);
