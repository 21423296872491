import * as actionTypes from "../actions/actionTypes";

const initialState = {
  widgets: [],
  dataArray: []
};

const setWidgets = (state, widgets,ordersCountWidget,transactionsCountWidget) => {
  return {
    ...state,
    widgets,
    ordersCountWidget,
    transactionsCountWidget
  };
};

const setWidgetData = (state, payload) => {

  const dataArray = state.dataArray.slice();
  let found = dataArray.find(d => d.widgetId === payload.widgetId);

  if (found) {
    found = payload;
  } else {
    dataArray.push(payload);
  }

  return {
    ...state,
    dataArray
  };
};

const setWidgetRequest = (state, action) => {
  return {
      ...state,
      widgets: [],
      dataArray: []
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.WIDGETS_GET_SUCCESS:
      return setWidgets(state, action.widgets, action.ordersCountWidget,action.transactionsCountWidget);

    case actionTypes.WIDGET_GET_SUCCESS:    
      return setWidgetData(state, action.payload);

    case actionTypes.WIDGETS_GET_REQUEST:
        return setWidgetRequest(state, action.payload);

    default:
      return state;
  }
};

export default reducer;
