import React from 'react';
import { injectIntl } from 'react-intl';
import messages from '../NfeDetailsForm.intl';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Modal from '../../../components/Modal/Modal';
import { Col, FormGroup, Row } from 'reactstrap';
import Loading from '../../Loading/Loading';

function ModalJustificationOperationNotRealized(props) {
    const {
        intl,
        isOpen,
        handleToggleModal,
        handleSubmit,
        loadingModal,
    } = props;

    const formRef = React.createRef();

    return (
        <Modal
            title={intl.formatMessage(messages.justifyOperationNotRealized)}
            isOpen={isOpen}
            handleToggle={handleToggleModal}
            hasFooter={true}
            toggleSecondButton={() => {
                if (formRef.current) formRef.current.handleSubmit();
            }}
            firstButtonClass='delete'
            secondButtonClass='btn-submit'
            firstButtonText={intl.formatMessage(messages.closeModal)}
            secondButtonText={intl.formatMessage(messages.submitButton)}
            toggleFirstButton={handleToggleModal}
        >
            <Loading loading={loadingModal} />
            <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                <p style={{ marginRight: '30px' }}>
                    {intl.formatMessage(messages.justification)}
                </p>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <AvField
                                label={''}
                                type='textarea'
                                style={{
                                    height: '200px',
                                    width: '400px',
                                }}
                                name='justificationText'
                                id='justificationText'
                                validate={{
                                    minLength: {
                                        value: 15,
                                        errorMessage:
                                            'A Justificativa deve conter entre 15 e 255 caracteres.',
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage:
                                            'A Justificativa deve conter entre 15 e 255 caracteres.',
                                    },
                                    required: {
                                        value: true,
                                        errorMessage:
                                            'O motivo da operação não realizada é obrigatório.',
                                    },
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </AvForm>
        </Modal>
    );
}

export default injectIntl(ModalJustificationOperationNotRealized);
