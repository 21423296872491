import React, { useState } from 'react';
import DynamicField from '../../../components/DynamicField/DynamicField';
import DynamicPanel from '../../../components/DynamicPanel/DynamicPanel';
import { Col, Row } from 'reactstrap';
import EvtSelect from '../../../components/EvtSelect/EvtSelect';

import messages from '../PendingFilesDetailsPage.intl';
import { injectIntl } from 'react-intl';

const PendingFileInfoForm = props => {
    const { intl, pendingFile, disableSend, statusHeader } = props;

    const [supplier, setSupplier] = useState(null);

    const handleSupplierChange = value => {
        setSupplier({
            label: value.label,
            value: value.value,
        });
    };

    const maskCategoryType = value => {
        let newValue = value;

        if (String(value) === '0') newValue = null;

        return newValue;
    };

    return (
        <>
            {/* Informações para Envio */}
            {!disableSend && (
                <DynamicPanel
                    index={1}
                    headerTitle={intl.formatMessage(messages.sendInfoHeader)}
                    hasFields={true}
                >
                    <Row>
                        <Col
                            xs={12}
                            style={{
                                paddingBottom: '20px',
                            }}
                        >
                            <EvtSelect
                                label={intl.formatMessage(
                                    messages.globalSupplier,
                                )}
                                name='supplierId'
                                url={`/Contractors/${
                                    pendingFile?.contractorId
                                }/Suppliers`}
                                labelKey='displayName'
                                labelKey2='documentNumber1'
                                valueKey='supplierId'
                                async
                                required={true}
                                handleSelectedOptionChange={
                                    handleSupplierChange
                                }
                                disabled={disableSend}
                            />
                        </Col>
                        <Col
                            xs={12}
                            style={{
                                paddingBottom: '20px',
                            }}
                        >
                            <EvtSelect
                                label={intl.formatMessage(messages.globalMap)}
                                name='mapId'
                                url={`/Suppliers/${supplier?.value}/Maps`}
                                labelKey='documentType'
                                labelKey2='name'
                                labelKey3='city'
                                labelKey4='uf'
                                labelBrackets='categoryType'
                                bracketMask={maskCategoryType}
                                valueKey='mapId'
                                key={supplier?.value}
                                async
                                required={true}
                                disabled={!supplier || disableSend}
                            />
                        </Col>
                    </Row>
                </DynamicPanel>
            )}
            {/* Detalhes */}
            <DynamicPanel
                index={1}
                headerTitle={intl.formatMessage(messages.detailsHeader)}
                badgeStatus={{
                    title: statusHeader.title,
                    color: statusHeader.color,
                }}
                hasFields={true}
            >
                <Row>
                    <Col xs={12}>
                        <DynamicField
                            label={intl.formatMessage(messages.createdAt)}
                            type={'text'}
                            name={'createdAt'}
                            id={'createdAt'}
                            rows={5}
                            value={new Date(
                                pendingFile?.createdAt,
                            ).toLocaleString(navigator.language)}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <DynamicField
                            label={intl.formatMessage(messages.fileName)}
                            type={'text'}
                            name={'fileName'}
                            id={'fileName'}
                            value={pendingFile?.fileName}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <DynamicField
                            label={intl.formatMessage(messages.sendedBy)}
                            type={'text'}
                            name={'sendedBy'}
                            id={'sendedBy'}
                            value={pendingFile?.sendedBy}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <DynamicField
                            label={intl.formatMessage(messages.source)}
                            type={'text'}
                            name={'source'}
                            id={'source'}
                            value={pendingFile?.source}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <DynamicField
                            label={intl.formatMessage(messages.rejectionReason)}
                            type={'textarea'}
                            name={'rejectionReason'}
                            id={'rejectionReason'}
                            rows={5}
                            value={pendingFile?.rejectionReason}
                            disabled={true}
                        />
                    </Col>
                </Row>
            </DynamicPanel>
        </>
    );
};

export default injectIntl(PendingFileInfoForm);
