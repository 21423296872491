import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    newDocument: {
        id: 'documents.newDocument',
        defaultMessage: 'Novo documento',
    },
    mapsNotFound: {
        id: 'documents.mapsNotFound',
        defaultMessage: 'Não há mapeamento disponível',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    titleDocument: {
        id: 'documents.titleDocument',
        defaultMessage: 'Documents',
    },
    events: {
        id: 'documents.events',
        defaultMessage: 'Eventos',
    },

    eventDescription: {
        id: 'documents.eventDescription',
        defaultMessage: 'Evento',
    },

    date: {
        id: 'documents.date',
        defaultMessage: 'Data',
    },

    justification: {
        id: 'documents.justification',
        defaultMessage: 'Motivo:',
    },

    closeModal: {
        id: 'documents.closeModal',
        defaultMessage: 'Fechar',
    },

    btnReportCancel: {
        id: 'documents.reportCancel',
        defaultMessage: 'Informar Cancelamento',
    },

    status: {
        id: 'documents.status',
        defaultMessage: 'Status integração',
    },
    invoiceNumber: {
        id: 'invoices.invoiceNumber',
        defaultMessage: 'Número',
    },
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
    createdAt: {
        id: 'maps.createdAt',
        defaultMessage: 'Criado Em',
    },
    titleInvoice: {
        id: 'invoices.titleInvoice',
        defaultMessage: 'Nota Fiscal',
    },
    subtitle: {
        id: 'invoices.subtitle',
        defaultMessage: ' {length} documentos encontrados',
    },
    categoryType: {
        id: 'maps.categoryType',
        defaultMessage: 'Categoria',
    },
    totalValue: {
        id: 'maps.totalValue',
        defaultMessage: 'Valor total',
    },
    associatedDocuments: {
        id: 'documents.associatedDocuments',
        defaultMessage: 'Documentos associados',
    },
    newAttachmentButton: {
        id: 'documents.newAttachmentButton',
        defaultMessage: '+ Documento Vinculado',
    },
    newBoletoAttachment: {
        id: 'documents.newBoletoAttachment',
        defaultMessage: '+ Boleto',
    },
    newOrdersButton: {
        id: 'documents.newOrdersButton',
        defaultMessage: '+ Pedido',
    },
    attachmentButton: {
        id: 'documents.attachmentButton',
        defaultMessage: 'Anexos',
    },
    selectType: {
        id: 'invoices.selectType',
        defaultMessage: 'Selecione o tipo',
    },
    selectMap: {
        id: 'invoices.selectMap',
        defaultMessage: 'Selecione o mapeamento',
    },
    labelMaps: {
        id: 'invoices.labelMaps',
        defaultMessage: 'Tipos',
    },
    mapsTitle: {
        id: 'invoices.mapsTitle',
        defaultMessage: 'Mapeamentos',
    },
    confirmInformCancelNfsText: {
        id: 'invoices.confirmInformCancelNfsText',
        defaultMessage:
            'Deseja realmente informar o cancelamento da NFS feito na prefeitura? Essa ação não poderá ser desfeita, caso seja feita por engano, o documento não poderá mais ser processado pelo ALLDOCS e terá que ser lançado manualmente.',
    },
    canUploadError: {
        id: 'invoices.canUploadError',
        defaultMessage:
            'Documento não vinculado ao fornecedor, entre em contato com o suporte.',
    },
    canJustificationError: {
        id: 'documents.canJustificationError',
        defaultMessage: 'Excedido o limite máximo de 600 caracteres.',
    },
    childValues: {
        id: 'documents.childValues',
        defaultMessage:
            'Valor documento pai {entity1}, Valor somatório dos documentos filhos {entity2}',
    },
    newRawFile: {
        id: 'documents.newRawFile',
        defaultMessage: '+ Arquivo',
    },
    docType: {
        id: 'invoices.docType',
        defaultMessage: 'Tipo',
    },
    fileDownload: {
        id: 'documents.fileDownload',
        defaultMessage: 'Download do arquivo',
    },
    protocolNumber: {
        id: 'documents.protocolNumber',
        defaultMessage: 'Número do Protocolo',
    },
    history: {
        id: 'documents.history',
        defaultMessage: 'Histórico',
    },
    logDescription: {
        id: 'documents.logDescription',
        defaultMessage: 'Descrição',
    },
    user: {
        id: 'documents.user',
        defaultMessage: 'Usuário',
    },
    createdAt: {
        id: 'documents.createdAt',
        defaultMessage: 'Registrado em:',
    },
});
