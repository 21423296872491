import { FormattedMessage } from 'react-intl';
import React from 'react';

import { apiErrorCodes } from './apiErrorMessages.intl';

export const formatErrorMessage = (errorCode, message) => {
    const result = apiErrorCodes.find(aec => aec.errorCode === errorCode);

    const backEndErrors = {
        101: true,
    };

    if (backEndErrors[errorCode]) {
        return <FormattedMessage id={errorCode} defaultMessage={message} />;
    }

    if (result) {
        return (
            <FormattedMessage
                id={result.id}
                defaultMessage={result.defaultMessage}
            />
        );
    } else
        return (
            <FormattedMessage
                id='global.defaultError'
                defaultMessage='Oh, não! Alguma coisa deu errado...'
            />
        );
};
