import React from 'react';
import { injectIntl } from 'react-intl';
import TooltipFormField from '../TooltipFormField/TooltipFormField';

import { useState } from 'react';
import { FormGroup } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import ListField from '../ListField/ListField';

const DynamicField = props => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
        if (props.field?.formGroup === 6) {
            setIsHovered(true)
        }
    };

    const handleMouseOut = () => {
        if (props.field?.formGroup === 6) {
            setIsHovered(false)
        }
    };

    const handleDynamicFieldFocus = e => {
        props.handleDynamicFieldFocus(e, props.id, props.field || {});
    };

    const handleDynamicFieldChange = e => {
        props.handleDynamicFieldChange(e, props.id);
    };

    let validate = {
        required: {
            value: props.required,
            errorMessage: ' ',
        },
    };

    if (props.minLength != null) {
        validate = {
            ...validate,
            minLength: {
                value: props.minLength ? props.minLength : '',
                errorMessage: ' ',
            },
        };
    }

    if (props.maxLength != null) {
        validate = {
            ...validate,
            maxLength: {
                value: props.maxLength ? props.maxLength : '',
                errorMessage: ' ',
            },
        };
    }

    if (props.label?.toUpperCase().includes("CNPJ") || props.label?.toUpperCase().includes("CPF")) {
        validate = {
            ...validate,
            pattern: {
                value: '^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}|[0-9]{11}|[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}|[0-9]{11}|[0-9]{14})$',
                errorMessage: 'CPF ou CNPJ inválido'
            }
        }
    }

    const handleLoadComponent = () => {
        switch (props.type) {
            case 'boolean':
                return (
                    <FormGroup key={props.key}>
                        <AvField
                            label={props.label}
                            type='select'
                            name={props.name}
                            id={props.id}
                            value={props.value}
                            validate={validate}
                            disabled={props.disabled}
                            className={props.className}
                            onFocus={handleDynamicFieldFocus}
                            onChange={handleDynamicFieldChange}
                            onBlur={props.onBlur}
                            style={{ backgroundColor: '#F3F5F9' }}
                        >
                            <option value='false'>Não</option>
                            <option value='true'>Sim</option>
                        </AvField>
                    </FormGroup>
                );
            case 'list':
                return (
                    <ListField
                        label={props.label}
                        textList={props.textList}
                        name={props.name}
                        id={props.id}
                        disabled={props.disabled}
                        className={props.className}
                        validate={validate}
                        required={props.required}
                        value={props.value}
                        documentFieldsListId={props.documentFieldsListId}
                    />
                );
            default:
                return (
                    <FormGroup key={props.key}>
                        <AvField
                            label={props.label}
                            type={props.type}
                            name={props.name}
                            id={props.id}
                            value={props.value}
                            validate={validate}
                            disabled={props.disabled}
                            className={props.className}
                            onFocus={handleDynamicFieldFocus}
                            onChange={handleDynamicFieldChange}
                            onBlur={props.onBlur}
                            rows={props.rows}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                        />
                        {isHovered && <TooltipFormField label={props.label}/>}
                    </FormGroup>
                );
        }
    };

    return <>{handleLoadComponent()}</>;
};

export default injectIntl(DynamicField);
