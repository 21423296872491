import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from './routes/routes';

import { ToastContainer, toast } from 'react-toastify';

import LoginPage from './containers/LoginPage/LoginPage';
import NotFoundPage from './containers/NotFoundPage/NotFoundPage';
import Layout from './components/Layout/Layout';

import { version } from '../package.json';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as icons from './utils/fontAwesomeIcons';
import { CacheBuster } from './services/CacheBuster';
import Loading from './components/Loading/Loading';
library.add(icons);

class App extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.error !== prevProps.error) {
            this.props.error && toast.error(this.props.error);
        }
    }

    render() {
        const session = this.props.session || {};
        const claims = session.claims || [];

        const filteredRoutes = routes.filter(route => {
            return claims.find(
                claim => claim.value === route.claim || route.claim === '',
            );
        });

        return (
            <CacheBuster
                currentVersion={version}
                isEnabled={true} //If false, the library is disabled.
                isVerboseMode={true} //If true, the library writes verbose logs to console.
                loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
            >
                <React.Fragment>
                    <Switch>
                        <Route
                            path='/login'
                            name='LoginPage'
                            component={LoginPage}
                        />
                        <Redirect push from='/' exact to='/home' />
                        <Layout>
                            <Switch>
                                {filteredRoutes.map(route => {
                                    return (
                                        <Route
                                            exact
                                            path={route.path}
                                            name={route.name}
                                            component={route.component}
                                            key={route.name}
                                        />
                                    );
                                })}
                                <Route component={NotFoundPage} />
                            </Switch>
                        </Layout>
                    </Switch>
                    <ToastContainer autoClose={2000} />
                </React.Fragment>
            </CacheBuster>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.globalReducer.error,
        session: state.authenticationReducer.session,
    };
};

export default withRouter(connect(mapStateToProps)(App));
