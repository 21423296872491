import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';
import messages from './ChangePasswordForm.intl';

const changePasswordForm = props => {

	const {
		intl,
		contractorColor,
		handleBackClickToogle,
		handleChangePasswordFormSubmit,
	} = props;

	return (
		<AvForm onValidSubmit={handleChangePasswordFormSubmit}>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<AvField
							label={intl.formatMessage(messages.newPasswordLabelText)}
							type='password'
							name='newPassword'
							id='newPassword'
							errorMessage=' '
							required
						/>
					</FormGroup>
				</Col>
				<Col xs={12}>
					<FormGroup>
						<AvField
							label={intl.formatMessage(messages.confirmPasswordLabelText)}
							type='password'
							name='confirmNewPassword'
							id='confirmNewPassword'
							errorMessage=' '
							required
							validate={{
								match: {
									value: 'newPassword',
									errorMessage: intl.formatMessage(
										messages.passwordDoesNotMatchValidationText,
									),
								},
							}}
						/>
					</FormGroup>
				</Col>
			</Row>

			<button
				style={{ backgroundColor: contractorColor }}
				className='login-btn'
				type='submit'
			>
				{intl.formatMessage(messages.buttonChangeText)}
			</button>
			<button
				style={{ color: contractorColor }}
				className='btn-link btnForgotPassword'
				type='button'
				onClick={handleBackClickToogle}
			>
				{intl.formatMessage(messages.returnButton)}
			</button>
		</AvForm>
	);
};

export default injectIntl(changePasswordForm);
