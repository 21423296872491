import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { Row, Col } from 'reactstrap';

import DynamicPanel from '../../components/DynamicPanel/DynamicPanel';
import DynamicField from '../../components/DynamicField/DynamicField';
import { formGroupTypes } from '../../utils/enums';
import messages from './DynamicTemplateForm.intl';
import { useEffect } from 'react';

const DynamicTemplateForm = props => {
    const {
        intl,
        readOnly,
        fieldsWithError,
        fields,
        template,
        templateFormGroups,
        documentId,
        enabledFieldsWithoutTemplate,
        isRevisionStep,
        hasDocumentUpdateClaim,
    } = props;

    useEffect(() => {
        modifyDatasToTemplate(fields, template);
    }, [fields]);

    const [templateFields, setTemplateFields] = useState([]);

    const createFieldByTemplate = templateField => {
        const value =
            fields.find(x => x.key === templateField?.valueKey)?.value || '';

        const newTemplateField = {
            name: templateField.name,
            key: templateField.key,
            constraints: { required: false },
            formGroup: 1,
            layout: {
                xs: templateField.size,
                lg: templateField.size,
                md: templateField.size,
                sm: templateField.size,
                xl: templateField.size,
            },
            type: 'Text',
            value,
            boundingBox: {
                x: 0,
                y: 0,
                height: 0,
                width: 0,
                page: 1,
            },
            hideMapInDocument: false,
            isDocumentField: false,
        };
        return newTemplateField;
    };

    function updateFieldToTemplateField(templateField, fieldsToModify) {
        const othersGroup = templateFormGroups.find(
            x => (x.type = formGroupTypes.OTHERS),
        );

        let fieldToUpdate = fieldsToModify.find(
            x => x.key === templateField?.key,
        );

        if (!fieldToUpdate) return undefined;

        if (templateField?.maskValue) {
            fieldToUpdate = {
                ...fieldToUpdate,
                value: templateField?.maskValue(fieldToUpdate.value),
            };
        }

        if (templateField?.addValueInField && fieldToUpdate.value !== '') {
            fieldToUpdate = {
                ...fieldToUpdate,
                value: fieldToUpdate.value + templateField?.addValueInField,
            };
        }

        // Caso haja um array na key do campo, ocorre um erro de desconstrução do campo em objeto
        const includeArray =
            fieldToUpdate.key.includes('[') || fieldToUpdate.key.includes(']');
        if (includeArray) return;

        let newField = template.find(field => field.key === fieldToUpdate.key);

        if (newField) {
            if (
                fieldToUpdate?.key === 'viewDocumentId' ||
                fieldToUpdate?.key === '/nfeProc/NFe/infNFe/@Id'
            ) {
                fieldToUpdate.value = String(documentId);
            }
            return {
                ...fieldToUpdate,
                name: newField.name,
                formGroup: newField.formGroup,
                layout: {
                    lg: newField.size,
                    md: newField.size,
                    sm: newField.size,
                    xl: newField.size,
                    xs: newField.size,
                },
            };
        } else if (
            enabledFieldsWithoutTemplate &&
            !fieldToUpdate?.isDocumentField
        ) {
            return {
                ...fieldToUpdate,
                formGroup: othersGroup?.formGroup || 99,
                layout: {
                    lg: 4,
                    md: 4,
                    sm: 4,
                    xl: 4,
                    xs: 4,
                },
            };
        }
    }

    function modifyDatasToTemplate(fieldsData, templateData) {
        if (fieldsData) {
            const documentFields = fieldsData.filter(x => x.isDocumentField);

            let fieldsToModify = fieldsData;

            let valuesFieldsTemplate = templateData
                .filter(x => !!x.valueKey)
                .map(vField => {
                    const newVField = createFieldByTemplate(vField, fieldsData);
                    return newVField;
                });

            fieldsToModify = fieldsData.concat(valuesFieldsTemplate);

            // Create array of fields based on a template
            let newFieldsOfTemplate = templateData.map(templateField => {
                const newTemplateField = updateFieldToTemplateField(
                    templateField,
                    fieldsToModify,
                );
                return newTemplateField;
            });

            // Remove undefined items of array
            newFieldsOfTemplate = newFieldsOfTemplate.filter(
                x => x !== undefined,
            );

            // Add Document Fields after create array of fields
            if (!!documentFields) {
                const headerGroup = templateFormGroups.find(
                    x => x.type === formGroupTypes.GENERAL,
                );
                documentFields.map(docField => {
                    newFieldsOfTemplate.splice(0, 0, {
                        ...docField,
                        formGroup: headerGroup?.formGroup || 1,
                    });
                });
            }

            //Conversion for groups of Fields and Form
            let formGroups = [];
            formGroups = templateFormGroups.map(formG => {
                const fields = newFieldsOfTemplate.filter(
                    x => x.formGroup === formG.formGroup,
                );
                return { ...formG, fields };
            });

            setTemplateFields(formGroups);
        }
    }

    function createDynamicPanels() {
        let rows = [];

        templateFields.forEach(groupField => {
            if (groupField.fields.length === 0) return;

            if (props.hideFieldWithoutValue) {
                const hasValue = groupField.fields.find(x => x.value !== '');
                if (!hasValue) return;
            }

            rows.push(
                <DynamicPanel
                    key={groupField.formGroup}
                    index={groupField.formGroup}
                    headerTitle={groupField.headerTitle}
                    hasFields={groupField.fields}
                >
                    <Row>
                        {groupField.fields?.map((field, index, row) => {
                            const className =
                                fieldsWithError.find(
                                    fkey => fkey === field.key,
                                ) && 'av-invalid is-invalid';

                            if (
                                !field ||
                                field.hideMapInDocument ||
                                (props.hideFieldWithoutValue &&
                                    field.value === '' &&
                                    !field.isDocumentField)
                            ) {
                                return null;
                            }

                            let disabledField = readOnly;

                            if (
                                field.isDocumentField &&
                                isRevisionStep &&
                                hasDocumentUpdateClaim
                            ) {
                                disabledField = false;
                            }
                            return (
                                field && (
                                    <Col
                                        key={index}
                                        className={field.layout.classes}
                                        lg={field.layout.lg}
                                        md={field.layout.md}
                                        xs={field.layout.xs}
                                        offsetlg={field.layout.offsetLg}
                                        offsetmd={field.layout.offsetMd}
                                        offsetsm={field.layout.offsetSm}
                                        offsetxl={field.layout.offsetXl}
                                        offsetxs={field.layout.offsetXs}
                                        sm={field.layout.sm}
                                        xl={field.layout.xl}
                                        style={
                                            field.type.toLowerCase() === 'list'
                                                ? { paddingBottom: 16 }
                                                : {}
                                        }
                                    >
                                        <DynamicField
                                            key={index}
                                            label={field.name}
                                            textList={field.textList}
                                            type={
                                                field.type === 'textarea'
                                                    ? 'textarea'
                                                    : field.type === 'boolean'
                                                    ? 'boolean'
                                                    : field.type.toLowerCase() ===
                                                      'list'
                                                    ? 'list'
                                                    : 'text'
                                            }
                                            name={field.key}
                                            id={field.key}
                                            value={field.value}
                                            documentFieldsListId={
                                                field.documentFieldsListId
                                            }
                                            minLength={
                                                field.constraints?.minLength
                                            }
                                            maxLength={
                                                field.constraints?.maxLength
                                            }
                                            pattern={field.constraints?.pattern}
                                            required={false}
                                            disabled={disabledField}
                                            className={className}
                                            handleDynamicFieldFocus={
                                                props.handleDynamicFieldFocus
                                            }
                                            handleDynamicFieldChange={
                                                props.handleDynamicFieldChange
                                            }
                                            onBlur={
                                                props.handleDynamicFieldBlur
                                            }
                                            rows={field.constraints?.rows}
                                        />
                                    </Col>
                                )
                            );
                        })}
                    </Row>
                </DynamicPanel>,
            );
        });

        return rows;
    }

    return <>{createDynamicPanels()}</>;
};

export default injectIntl(DynamicTemplateForm);
