import React, { useContext } from 'react';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import {
    filterPropertyTypes,
    sources,
    statusDescription,
} from '../../../utils/enums';
import messages from '../NfeListPage.intl';
import { Badge } from 'reactstrap';
import { sourceComponent } from '../../DocumentListPage/components/Columns';
import { Operators } from '../../../models/FilterBuilder';

import DropdownListButton from '../../../components/DropdownListButton/DropdownListButton';
import { AuthContext } from '../../../context/AuthContext';
import PortalFileIcon from '../../../assets/icons/PortalFileIcon';

function handleGoToNfeClick(e, fileId) {
    const url = `/nfeDocuments/${fileId}` || {};
    window.open(url);
    e.stopPropagation();
}

export const columnsNfe = (documents, intl, hasFull = false) => {
    let columns = [];
    if (hasFull) {
        columns.push({
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.titleContractor, {
                            entity: '',
                        })}
                    </span>
                </div>
            ),
            accessor: 'contractor',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                var id = row.index + 1;
                return (
                    <div className='customer-container'>
                        <span className={`color-number color-number-${id}`}>
                            {row.value && row.value.substr(0, 1).toUpperCase()}
                        </span>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            maxWidth:
                300 +
                calculateColumnWidth(
                    documents,
                    'contractor',
                    intl.formatMessage(messages.titleContractor, {
                        entity: '',
                    }),
                ),
            minWidth:
                100 +
                calculateColumnWidth(
                    documents,
                    'contractor',
                    intl.formatMessage(messages.titleContractor, {
                        entity: '',
                    }),
                ),
        });
    }

    columns.push(
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.titleSource)}</span>
                </div>
            ),
            accessor: 'source',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return sourceComponent(row);
            },
            maxWidth: 75,
            minWidth: 75,
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.titleInvoice)}</span>
                </div>
            ),
            accessor: 'documentNumber',
            sortMethod: (a, b) => {
                const A = Number(a) || Number.MAX_SAFE_INTEGER;
                const B = Number(b) || Number.MAX_SAFE_INTEGER;
                return A - B;
            },
            Cell: row => <p>{row.value || ''}</p>,
            style: {
                fontSize: 'small',
            },
            maxWidth: 330,
            minWidth: 330,
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.titleSupplier, {
                            entity: '',
                        })}
                    </span>
                </div>
            ),
            accessor: 'supplierName',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            maxWidth:
                300 +
                calculateColumnWidth(
                    documents,
                    'supplierName',
                    intl.formatMessage(messages.titleSupplier, {
                        entity: '',
                    }),
                ),
            minWidth:
                60 +
                calculateColumnWidth(
                    documents,
                    'supplierName',
                    intl.formatMessage(messages.titleSupplier, {
                        entity: '',
                    }),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.titleTaker, {
                            entity: '',
                        })}
                    </span>
                </div>
            ),
            accessor: 'contractorName',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            maxWidth:
                300 +
                calculateColumnWidth(
                    documents,
                    'contractorName',
                    intl.formatMessage(messages.titleTaker, {
                        entity: '',
                    }),
                ),
            minWidth:
                60 +
                calculateColumnWidth(
                    documents,
                    'contractorName',
                    intl.formatMessage(messages.titleTaker, {
                        entity: '',
                    }),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.emissionDate)}</span>
                </div>
            ),
            accessor: 'emissionDate',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                const date = new Date(row.value).toLocaleString(
                    navigator.language,
                );
                return (
                    <div className='customer-container'>
                        <div className=''>
                            {date.toUpperCase() !== 'INVALID DATE'
                                ? date
                                : row.value}
                        </div>
                    </div>
                );
            },
            minWidth:
                20 +
                calculateColumnWidth(
                    documents,
                    'emissionDate',
                    intl.formatMessage(messages.emissionDate),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>
                        {intl.formatMessage(messages.integratedAtDocument)}
                    </span>
                </div>
            ),
            accessor: 'createdAt',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                const date = new Date(row.value).toLocaleString(
                    navigator.language,
                );
                return (
                    <div className='customer-container'>
                        <div className=''>{date}</div>
                    </div>
                );
            },
            minWidth:
                40 +
                calculateColumnWidth(
                    documents,
                    'createdAt',
                    intl.formatMessage(messages.integratedAtDocument),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.status)}</span>
                </div>
            ),
            id: 'status',
            style: {
                fontSize: 'small',
            },
            sortMethod: (a, b) => {
                const preference = {
                    'status-invoice status-success': 1,
                    'status-invoice status-pending': 2,
                    'status-invoice status-error': 3,
                };

                const A =
                    preference[a.props.className.toLowerCase()] ||
                    Number.MAX_SAFE_INTEGER;
                const B =
                    preference[b.props.className.toLowerCase()] ||
                    Number.MAX_SAFE_INTEGER;

                return A - B;
            },
            accessor: d => {
                let color = '';

                if (d.status.toLowerCase() === 'pending') {
                    color += 'warning';
                } else if (d.status.toLowerCase() === 'success') {
                    color += 'success';
                } else if (d.status.toLowerCase() === 'error') {
                    color += 'danger';
                }

                var message = d.statusDescription
                    .split('-')[0]
                    .split(':')[0]
                    .trim();

                return (
                    <Badge
                        style={{ fontSize: '0.8rem', color: '#ffffff' }}
                        color={color}
                        pill
                    >
                        {message}
                    </Badge>
                );
            },
            minWidth:
                200 +
                calculateColumnWidth(
                    documents,
                    'statusDescription',
                    intl.formatMessage(messages.status),
                ),
        },
    );

    return columns;
};

export const nfeProperties = (intl, hasFull = false) => {
    const filtersProperties = [];

    filtersProperties.push(
        {
            key: 'contractorName',
            value: intl.formatMessage(messages.titleTaker, { entity: '' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'contractorCNPJ',
            value: intl.formatMessage(messages.titleTaker, { entity: 'CNPJ' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
            maskValue: value => value.replace(/[^\d]+/g, ''),
        },
        {
            key: 'supplierName',
            value: intl.formatMessage(messages.titleProvider, { entity: '' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'supplierCNPJ',
            value: intl.formatMessage(messages.titleProvider, {
                entity: 'CNPJ',
            }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
            maskValue: value => value.replace(/[^\d]+/g, ''),
        },
        {
            key: 'documentNumber',
            value: intl.formatMessage(messages.invoiceNumberFilter),
            type: filterPropertyTypes.NUMBER,
            lg: 4,
        },
        {
            key: 'city',
            value: intl.formatMessage(messages.city),
            type: filterPropertyTypes.TEXT,
            lg: 4,
        },
    );

    if (hasFull) {
        filtersProperties.push({
            key: 'contractor',
            value: intl.formatMessage(messages.titleOnlyContractor, {
                entity: '',
            }),
            type: filterPropertyTypes.TEXT,
            lg: 4,
        });
    }

    filtersProperties.push(
        {
            key: 'source',
            value: intl.formatMessage(messages.titleSource),
            type: filterPropertyTypes.ENUM,
            options: [
                {
                    key: sources.portal,
                    value: 'Portal',
                },
                {
                    key: sources.email,
                    value: 'Email',
                },
                {
                    key: sources.webService,
                    value: 'Integração',
                },
            ],
            lg: 4,
            operator: Operators.like,
        },
        {
            key: 'statusDescription',
            value: 'Status',
            type: filterPropertyTypes.ENUM,
            options: Object.values(statusDescription).map(s => {
                return {
                    key: s,
                    value: s,
                };
            }),
            lg: 4,
            operator: Operators.like,
        },
        {
            key: 'createdAt',
            value: intl.formatMessage(messages.createdAt),
            type: filterPropertyTypes.DATE,
        },
    );

    return filtersProperties;
};

//SUMMARY
export const ColumnsNfeSummary = (
    summaryNfe,
    intl,
    handleManifestAwarenessSummary,
    handleManifestUnawareSummary,
    handleSearchNfe,
) => {
    let columns = [];
    const context = useContext(AuthContext);

    columns.push(
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.createdAt)}</span>
                </div>
            ),
            accessor: 'createdAt',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                const date = new Date(row.value).toLocaleString(
                    navigator.language,
                );
                return (
                    <div className='customer-container'>
                        <div className=''>{date}</div>
                    </div>
                );
            },
            maxWidth:
                90 +
                calculateColumnWidth(
                    summaryNfe,
                    'createdAt',
                    intl.formatMessage(messages.createdAt),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.nfeKey)}</span>
                </div>
            ),
            accessor: 'nFeKey',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            minWidth:
                60 +
                calculateColumnWidth(
                    summaryNfe,
                    'nFeKey',
                    intl.formatMessage(messages.nfeKey),
                ),
            maxWidth: 350,
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.supplierName)}</span>
                </div>
            ),
            accessor: 'supplierName',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            minWidth:
                60 +
                calculateColumnWidth(
                    summaryNfe,
                    'supplierName',
                    intl.formatMessage(messages.supplierName),
                ),
            maxWidth: 340,
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.supplierCNPJ)}</span>
                </div>
            ),
            accessor: 'supplierCNPJ',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
            maxWidth:
                110 +
                calculateColumnWidth(
                    summaryNfe,
                    'supplierCNPJ',
                    intl.formatMessage(messages.supplierCNPJ),
                ),
            minWidth:
                60 +
                calculateColumnWidth(
                    summaryNfe,
                    'supplierCNPJ',
                    intl.formatMessage(messages.supplierCNPJ),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.emissionDate)}</span>
                </div>
            ),
            accessor: 'emissionDate',
            style: {
                fontSize: 'small',
            },
            Cell: row => {
                const date = new Date(row.value).toLocaleString(
                    navigator.language,
                );
                return (
                    <div className='customer-container'>
                        <div className=''>{date}</div>
                    </div>
                );
            },
            maxWidth:
                100 +
                calculateColumnWidth(
                    summaryNfe,
                    'emissionDate',
                    intl.formatMessage(messages.emissionDate),
                ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.status)}</span>
                </div>
            ),
            id: 'status',
            style: {
                fontSize: 'small',
            },
            sortMethod: (a, b) => {
                const preference = {
                    'status-invoice status-success': 1,
                    'status-invoice status-pending': 2,
                    'status-invoice status-error': 3,
                };

                const A =
                    preference[a.props.className.toLowerCase()] ||
                    Number.MAX_SAFE_INTEGER;
                const B =
                    preference[b.props.className.toLowerCase()] ||
                    Number.MAX_SAFE_INTEGER;

                return A - B;
            },
            accessor: d => {
                let color = 'danger';
                let description = 'Error';

                if (String(d.status) === '0') {
                    description = intl.formatMessage(messages.pending);
                    color = 'warning';
                } else if (String(d.status) === '1') {
                    description = intl.formatMessage(messages.awareOperation);
                    color = 'success';
                } else if (String(d.status) === '2') {
                    description = intl.formatMessage(messages.unknown);
                    color = 'danger';
                }
                return (
                    <Badge
                        style={{ fontSize: '0.8rem', color: '#ffffff' }}
                        color={color}
                        pill
                    >
                        {description}
                    </Badge>
                );
            },
            minWidth: 150,
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.nfeDocument)}</span>
                </div>
            ),

            accessor: 'nfeDocumentId',

            Cell: row => {
                const { original } = row;
                const nfeId = original.nfeDocumentId;
                return nfeId ? (
                    <span
                        style={{ marginLeft: 25 }}
                        title={intl.formatMessage(messages.nfeDocument)}
                        onClick={
                            nfeId ? e => handleGoToNfeClick(e, nfeId) : false
                        }
                    >
                        <PortalFileIcon />
                    </span>
                ) : (
                    ''
                );
            },
            maxWidth: 100,
        },
        {
            accessor: 'actions',
            style: {
                display: 'flex',
                fontSize: 'small',
            },
            Cell: row => {
                const status = row.original.status;
                const hasAware =
                    String(status) !== '1' &&
                    context.hasClaim('nfe-manifest-aware');
                const hasUnknown =
                    context.hasClaim('nfe-manifest-unaware') &&
                    String(status) === '0';
                const hasSearch =
                    context.hasClaim('nfe-document-create') &&
                    String(status) === '1' &&
                    !row.original.documentObtainedByTheUser;
                const items = [
                    {
                        name: intl.formatMessage(
                            messages.manifestAwarenessSummary,
                        ),
                        onClick: () =>
                            handleManifestAwarenessSummary(row.original),
                        disabled: !hasAware,
                    },
                    {
                        name: intl.formatMessage(
                            messages.manifestUnknownSummary,
                        ),
                        onClick: () =>
                            handleManifestUnawareSummary(row.original),
                        disabled: !hasUnknown,
                    },
                    {
                        name: intl.formatMessage(messages.searchNFe),
                        onClick: () => handleSearchNfe(row.original),
                        disabled: !hasSearch,
                    },
                ];
                return <DropdownListButton items={items} />;
            },
            sortable: false,
            maxWidth: 50,
        },
    );

    return columns;
};

export const nfeSummaryProperties = intl => {
    const filtersProperties = [];

    filtersProperties.push(
        {
            key: 'supplierName',
            value: intl.formatMessage(messages.supplierName, { entity: '' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'supplierCNPJ',
            value: intl.formatMessage(messages.supplierCNPJ, { entity: '' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'nFeKey',
            value: intl.formatMessage(messages.nfeKey, { entity: '' }),
            type: filterPropertyTypes.TEXT,
            lg: 6,
        },
        {
            key: 'status',
            value: intl.formatMessage(messages.status, { entity: '' }),
            type: filterPropertyTypes.ENUM,
            lg: 6,
            options: [
                { key: 0, value: intl.formatMessage(messages.pending) },
                { key: 1, value: intl.formatMessage(messages.awareOperation) },
                { key: 2, value: intl.formatMessage(messages.unknown) },
            ],
        },
        {
            key: 'createdAt',
            value: intl.formatMessage(messages.createdAt, { entity: '' }),
            type: filterPropertyTypes.DATE,
        },
        {
            key: 'emissionDate',
            value: intl.formatMessage(messages.emissionDate, { entity: '' }),
            type: filterPropertyTypes.DATE,
        },
    );

    return filtersProperties;
};
