import * as actionTypes from './actionTypes';
import axios from 'axios';

export const initOcrOperationResults = fileId => {
    return dispatch => {
      dispatch({
        type: actionTypes.OCR_OPERATION_RESULT_REQUEST
      });
  
      axios
        .get(`/OcrOperationResults/${fileId}`)
        .then(response => {
          dispatch({
            type: actionTypes.OCR_OPERATION_RESULT_SUCCESS,
            ocrOperationResult: response.data
          });
        })
        .catch(error => {
          dispatch({
            type: actionTypes.OCR_OPERATION_RESULT_FAILURE,
            error
          });          
        });
    };
  };