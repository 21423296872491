import React from 'react';

const TooltipFormField = ({ label }) => {
  const isLabelTooLong = label.length > 20;

  return (
    <div
      className='tooltip-form-field'
      style={{
        animation: `tooltip-appear 0.3s ease-in-out ${isLabelTooLong ? '0.1s' : '1s'} forwards`,
      }}
    >
      {label}
    </div>
  );
};

export default TooltipFormField;