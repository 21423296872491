import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import OrderItemsDetailsForm from '../../components/OrderItemsDetailsForm/OrderItemsDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './OrderItemDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';

class OrderDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
    };
    
    NEW_ITEM={
        isActive: true,
    }

    componentDidMount() {
        const orderId = this.props.match.params.id;
        const orderItemId = this.props.match.params.itemId;

        this.props.onInitOrderItemDetails(orderId, orderItemId);
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingItemDetails) return;
        const orderId = this.props.match.params.id;
        const orderItemId = this.props.match.params.itemId;

        const finalValues = {
            ...values,
            orderId: orderId,
            orderItemId: orderItemId,
            type: Number(values.type),
        };

        if (orderItemId === 'new') {
            this.props.onCreateOrderItem(orderId, finalValues);
        } else {
            this.props.onUpdateOrderItem(orderId, orderItemId, finalValues);
        }
    };

    handleToggleModalDelete = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleDeleteField = async () => {
        this.setState({
            showModal: !this.state.showModal,
        });
        await this.props.onDeleteOrderItem(
            this.props.orderItem.orderId,
            this.props.orderItem.orderItemId,
        );
    };

    render() {
        const { intl, loadingItemDetails } = this.props;

        const isNew = this.props.match.params.itemId === 'new' ? true : false;

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/orders',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: `/orders/${this.props.match.params.id}`,
                            name: intl.formatMessage(messages.titleOrder, {
                                entity: '',
                            }),
                            active: false,
                        },
                        {
                            path: `/orders/${
                                this.props.match.params.id
                            }/items/${this.props.match.params.itemId}`,
                            name: intl.formatMessage(messages.titleOrderItem, {
                                entity:
                                    this.props.orderItem &&
                                    this.props.orderItem.description,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleOrderItem, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content orderDetails'>
                    <Loading loading={loadingItemDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={[
                            {
                                name: intl.formatMessage(messages.itemInfo),
                                component: (
                                    <OrderItemsDetailsForm
                                        orderItem={
                                            isNew ? this.NEW_ITEM : this.props.orderItem
                                        }
                                        handleSubmit={this.handleSubmit}
                                        handleDelete={this.handleToggleModal}
                                        handleToggleModalDelete={
                                            this.handleToggleModalDelete
                                        }
                                        isNew={isNew}
                                        loading={this.props.loadingItemDetails}
                                    />
                                ),
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteOrderItem)}
                    isOpen={this.state.showModal}
                    handleToggleModalDelete={this.handleToggleModalDelete}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText='Sim'
                    secondButtonText='Não'
                    toggleFirstButton={this.handleDeleteField}
                    toggleSecondButton={this.handleToggleModalDelete}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        orderItem: state.orderReducer.orderItem,
        order: state.orderReducer.order,
        loadingItemDetails: state.orderReducer.loadingItemDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitOrderItemDetails: (orderId, orderItemId) =>
            dispatch(actions.initOrderItemDetails(orderId, orderItemId)),
        onUpdateOrderItem: (orderId, orderItemId, values) =>
            dispatch(actions.updateOrderItem(orderId, orderItemId, values)),
        onCreateOrderItem: (orderId, values) =>
            dispatch(actions.createOrderItem(orderId, values)),
        onDeleteOrderItem: (orderId, orderItemId) =>
            dispatch(actions.deleteOrderItem(orderId, orderItemId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(OrderDetailsPage),
);
