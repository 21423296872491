import React from 'react';
import { injectIntl } from 'react-intl';
import messages from './DynamicFilterField.intl';
import { FormGroup, Row, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Combobox from '../Combobox/Combobox';
import Datepicker from '../DatePicker/DatePicker';
import { filterPropertyTypes } from '../../utils/enums';
import SelectFilterField from '../SelectFilterField/SelectFilterField';

function DynamicFilterField(props) {
    const { intl, filter, properties } = props;

    let field = (
        <Col lg={4} md={4}>
            <FormGroup>
                <AvField
                    label={intl.formatMessage(messages.value)}
                    type='text'
                    name={`filters[${filter.id}].value`}
                    id={`filters[${filter.id}].value`}
                    value={filter.value}
                    onChange={e =>
                        props.handleFieldChange(e, filter.id, 'value')
                    }
                    errorMessage=' '
                />
            </FormGroup>
        </Col>
    );

    switch (filter.type) {
        case filterPropertyTypes.DATE:
            field = (
                <Datepicker
                    filter={filter}
                    handleFieldChange={props.handleFieldChange}
                />
            );
            break;
        case filterPropertyTypes.ENUM:
            field = (
                <Combobox
                    filter={filter}
                    enums={filter.enums}
                    handleFieldChange={props.handleFieldChange}
                />
            );
            break;
        case filterPropertyTypes.SELECT:
            field = (
                <Col lg={4} md={4} style={{ textAlign: 'left' }}>
                    <SelectFilterField
                        label={intl.formatMessage(messages.value)}
                        filter={filter}
                        properties={properties}
                        handleFieldChange={props.handleFieldChange}
                    />
                </Col>
            );
            break;
        case filterPropertyTypes.TEXT:
        case filterPropertyTypes.NUMBER:
        default:
            break;
    }

    const operators = [
        {
            key: 'like',
            message: intl.formatMessage(messages.like),
        },
        {
            key: 'eq',
            message: intl.formatMessage(messages.equalTo),
        },
        {
            key: 'ne',
            message: intl.formatMessage(messages.notEqualTo),
        },
        {
            key: 'gt',
            message: intl.formatMessage(messages.greaterThan),
        },
        {
            key: 'ge',
            message: intl.formatMessage(messages.greaterThanOrEqualTo),
        },
        {
            key: 'lt',
            message: intl.formatMessage(messages.lessThan),
        },
        {
            key: 'le',
            message: intl.formatMessage(messages.lessThanOrEqualTo),
        },
    ];

    return (
        <React.Fragment>
            <Row>
                <Col lg={4} md={4}>
                    <FormGroup>
                        <AvField
                            type='select'
                            name={`filters[${filter.id}].property`}
                            id={`filters[${filter.id}].property`}
                            label={intl.formatMessage(messages.filterBy)}
                            value={filter.property}
                            onChange={e =>
                                props.handleFieldChange(
                                    e,
                                    filter.id,
                                    'property',
                                )
                            }
                        >
                            <option value=''>-</option>
                            {props.properties &&
                                props.properties.map(property => {
                                    return (
                                        <option
                                            key={property.key}
                                            value={property.key}
                                        >
                                            {property.value}
                                        </option>
                                    );
                                })}
                        </AvField>
                    </FormGroup>
                </Col>
                {filter.type !== filterPropertyTypes.DATE && (
                    <Col lg={3} md={3}>
                        <FormGroup>
                            <AvField
                                type='select'
                                name={`filters[${filter.id}].operator`}
                                id={`filters[${filter.id}].operator`}
                                label={intl.formatMessage(messages.operator)}
                                value={filter.operator}
                                disabled={
                                    filter.type === filterPropertyTypes.Combobox
                                }
                                onChange={e =>
                                    props.handleFieldChange(
                                        e,
                                        filter.id,
                                        'operator',
                                    )
                                }
                            >
                                <option value=''>-</option>
                                {operators.map(operator => {
                                    return (
                                        <option
                                            key={operator.key}
                                            value={operator.key}
                                        >
                                            {operator.message}
                                        </option>
                                    );
                                })}
                            </AvField>
                        </FormGroup>
                    </Col>
                )}
                {field}
                <Col lg={1} md={1}>
                    <FormGroup>
                        <button
                            type='button'
                            className={`new-btn small filter ${
                                filter.showAddButton ? '' : 'btn-delete'
                            }`}
                            title={
                                filter.showAddButton
                                    ? intl.formatMessage(messages.addFilter)
                                    : intl.formatMessage(messages.removeFilter)
                            }
                            onClick={e => {
                                e.preventDefault();
                                props.handleAddRemoveFilter(filter);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={
                                    filter.showAddButton ? 'plus' : 'trash-alt'
                                }
                                size='1x'
                            />
                        </button>
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default injectIntl(DynamicFilterField);
