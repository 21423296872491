import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './TechnicalLogsTable.intl';

import JsonTreeModal from '../../components/JsonTreeModal/JsonTreeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable, { calculateColumnWidth } from '../../components/EvtTable/EvtTable';

import { filterPropertyTypes } from '../../utils/enums';

class TechnicalLogsTable extends Component {
    state = {
        activeTab: 0,
        showTechnicalLogModal: false,
        technicalLog: {},
        filters: [],
        query: '',
    };

    reloadTechnicalLogsList = () => {
        const { query } = this.state;
        this.props.onInitTechnicalLogsCount(query);
        this.props.onInitTechnicalLogs(query);
    };

    handleTechnicalLogToggleModal = technicalLog => {
        this.setState({
            showTechnicalLogModal: !this.state.showTechnicalLogModal,
            technicalLog,
        });
    };

    handleTechnicalLogsRowClick = (e, technicalLog) => {
        this.handleTechnicalLogToggleModal(technicalLog);
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadTechnicalLogsList();
            },
        );
    };

    handleRefreshButtonClick = () => {
        this.reloadTechnicalLogsList();
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => this.handleTechnicalLogToggleModal(rowInfo.original),
        };
    };

    render() {
        const {
            intl,
            loadingTechnicalLogsList,
            technicalLogs,
            technicalLogsCount,
        } = this.props;
        const { filters } = this.state;

        const properties = [
            {
                key: 'createdBy',
                value: intl.formatMessage(messages.createdBy),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'createdAt',
                value: intl.formatMessage(messages.createdAt),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'type',
                value: intl.formatMessage(messages.type),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 1, value: 'Debug' },
                    { key: 2, value: 'Info' },
                    { key: 3, value: 'Warning' },
                    { key: 4, value: 'Error' },
                    { key: 5, value: 'Fatal' },
                ],
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdAt)}</span>
                    </div>
                ),
                accessor: 'createdAt',
                style: {
                    fontSize: 'small',
                    
                },
                maxWidth: 150 + calculateColumnWidth(technicalLogs, 'createdAt', intl.formatMessage(messages.createdAt)),
                minWidth: 40 + calculateColumnWidth(technicalLogs, 'createdAt', intl.formatMessage(messages.createdAt)),
                Cell: row => {
                    return new Date(row.value).toLocaleString(navigator.language);
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdBy)}</span>
                    </div>
                ),
                accessor: 'createdBy',
                style: {
                    fontSize: 'small',
                    
                },
                maxWidth: 150 + calculateColumnWidth(technicalLogs, 'createdBy', intl.formatMessage(messages.createdBy)),
                minWidth: 60 + calculateColumnWidth(technicalLogs, 'createdBy', intl.formatMessage(messages.createdBy)),
            },

            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.type)}</span>
                    </div>
                ),
                accessor: 'type',
                style: {
                    fontSize: 'small',
                    
                },
                Cell: row => row.value,
                minWidth: calculateColumnWidth(technicalLogs, 'type', intl.formatMessage(messages.type)),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.message)}</span>
                    </div>
                ),
                accessor: 'message',
                style: {
                    fontSize: 'small',
                    
                },
                maxWidth: 200 + calculateColumnWidth(technicalLogs, 'message', intl.formatMessage(messages.message)),
                minWidth: 150 + calculateColumnWidth(technicalLogs, 'message', intl.formatMessage(messages.message)),
            },
        ];

        return (
            <React.Fragment>
                <header className='table-screen'>
                    <section className='title'></section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingTechnicalLogsList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingTechnicalLogsList
                                    ? 'loading'
                                    : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                    </section>
                </header>
                <EvtTable
                    filters={filters}
                    columns={columns}
                    data={technicalLogs}
                    length={technicalLogsCount}
                    pageSize={10}
                    handleStateChange={this.handleEvTableStateChange}
                    handleGetTrProps={this.handleGetTrProps}
                    loading={loadingTechnicalLogsList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                />
                <JsonTreeModal
                    isOpen={this.state.showTechnicalLogModal}
                    handleToggle={this.handleTechnicalLogToggleModal}
                    data={this.state.technicalLog}
                    darkTheme={false}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        technicalLogs: state.logReducer.technicalLogs || [],
        technicalLogsCount: state.logReducer.technicalLogsCount,
        loadingTechnicalLogsList: state.logReducer.loadingTechnicalLogsList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitTechnicalLogs: filters =>
            dispatch(actions.initTechnicalLogs(filters)),
        onInitTechnicalLogsCount: filters =>
            dispatch(actions.initTechnicalLogsCount(filters)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TechnicalLogsTable),
);
