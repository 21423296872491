import * as actionTypes from '../actions/actionTypes';

const initialState = {
    mapsCount: 0,
    maps: [],
    mapOcrResult: [],
    mapFieldOcr: null,
    selectedMap: null,
    selectedMapItem: null,
    loadingViewer: false,
    loadingFields: true,
    loadingList: true,
    loadingMapItemDetails: false,
};

const loadingItemDetailsTrue = (state, action) => {
    return { ...state, loadingMapItemDetails: true };
};

const loadingItemDetailsFalseError = (state, { error }) => {
    return { ...state, loadingMapItemDetails: false, error };
};

const setLoadingViewerTrue = (state, action) => {
    return { ...state, loadingViewer: true };
};

const setMapError = (state, { error }) => {
    return { ...state, error, loadingViewer: false };
};

const loadingViewerFalse = (state, action) => {
    return { ...state, loadingViewer: false, loadingFields: false };
};

const setMaps = (state, action) => {
    return {
        ...state,
        maps: action.maps,
        selectedMap: null,
        loadingList: false,
    };
};

const setMapItemRequest = (state, action) => {
    return { ...state, loadingMapItemDetails: true, selectedMapItem: null };
};

const setMapItem = (state, action) => {
    return {
        ...state,
        selectedMapItem: action.selectedMapItem,
        selectedMap: action.selectedMap,
        loadingMapItemDetails: false,
    };
};

const setMapsCount = (state, action) => {
    return { ...state, mapsCount: action.mapsCount };
};

const setMapsRequest = (state, action) => {
    return { ...state, maps: undefined, selectedMap: null, loadingList: true };
};

const setMap = (state, action) => {
    return { ...state, selectedMap: action.map, loadingViewer: false };
};

const setMapPost = (state, action) => {
    return { ...state, maps: [] };
};

const setFileIdRequest = (state, action) => {
    const selectedMap = { ...state.selectedMap };
    selectedMap.fileId = action.fileId;

    return { ...state, selectedMap, loadingViewer: true };
};

const setFileId = (state, action) => {
    return { ...state, loadingViewer: false };
};

const updateMapItem = (state, action) => {
    const mapItems = state.selectedMap.mapItems.slice();
    const foundMapItem = mapItems.find(
        item => item.mapItemId === action.updatedMapItem.mapItemId,
    );

    foundMapItem.field = {
        ...action.updatedMapItem.field,
    };

    foundMapItem.boundingBox = {
        ...action.updatedMapItem.boundingBox,
    };

    return {
        ...state,
        selectedMap: {
            ...state.selectedMap,
            mapItems,
        },
        loadingViewer: false,
        loadingFields: false,
    };
};

const addMapItem = (state, action) => {
    const mapItems = state.selectedMap.mapItems.slice();

    mapItems.push({
        mapItemId: action.payload.mapItemId,
        ...action.payload.newMapItem,
    });

    return {
        ...state,
        selectedMap: {
            ...state.selectedMap,
            mapItems,
        },
        loadingViewer: false,
        loadingFields: false,
    };
};

const copyMapItem = (state, action) => {
    const newMapItem = {
        ...state.selectedMapItem,
        ...action.payload.newMapItem,
    };

    return {
        ...state,
        selectedMapItem: newMapItem,
        loadingMapItemDetails: false,
    };
};

const removeMapItem = (state, action) => {
    const mapItems = state.selectedMap.mapItems.filter(
        item => item.mapItemId !== action.mapItemId,
    );

    return {
        ...state,
        selectedMap: {
            ...state.selectedMap,
            mapItems,
        },
        loadingViewer: false,
        loadingFields: false,
    };
};

const saveMapAction = (state, action) => {
    const mapActions = action.mapActions.slice();

    return {
        ...state,
        selectedMapItem: {
            ...state.selectedMapItem,
            mapActions: [...mapActions],
        },
        loadingMapItemDetails: false,
    };
};

const deleteMapAction = (state, action) => {
    const mapActions = state.selectedMapItem.mapActions.filter(
        ma => ma.mapActionId !== action.payload.mapActionId,
    );

    return {
        ...state,
        selectedMapItem: {
            ...state.selectedMapItem,
            mapActions: [...mapActions],
        },
        loadingMapItemDetails: false,
    };
};

const setMapActionRequest = (state, action) => {
    return { ...state, loadingMapItemDetails: true };
};

const setMapRequest = (state, action) => {
    const selectedMap = { ...state.selectedMap };
    return {
        ...state,
        selectedMap,
        loadingViewer: true,
        loadingFields: true,
        loadingMapItemDetails: true,
    };
};

const setMapItemFieldUpdateRequest = (state, action) => {
    return { ...state, loadingMapItemDetails: true, loadingViewer: true };
};

const setMapItemUpdate = (state, action) => {
    const newMapItem = { ...state.selectedMapItem, ...action.payload.values };

    return {
        ...state,

        selectedMapItem: newMapItem,
        loadingMapItemDetails: false,
        loadingViewer: false,
    };
};

const setMapItemFieldUpdate = (state, action) => {
    const mapItems = state.selectedMap.mapItems.filter(
        mapItem => mapItem.mapItemId !== state.selectedMapItem.mapItemId,
    );

    const newMapItem = { ...state.selectedMapItem, ...action.payload.values };

    mapItems.push(newMapItem);

    return {
        ...state,
        selectedMap: {
            ...state.selectedMap,
            mapItems,
        },
        selectedMapItem: newMapItem,
        loadingMapItemDetails: false,
    };
};

const setMapItemFieldRegex = (state, action) => {
    return {
        ...state,
        selectedMapItem: {
            ...state.selectedMapItem,
            field: {
                ...state.selectedMapItem.field,
                constraints: {
                    ...state.selectedMapItem.field.constraints,
                    pattern: action.pattern,
                },
            },
        },
    };
};

const setOcrResults = (state, { mapOcrResult }) => {
    return { ...state, loadingViewer: false, mapOcrResult };
};

const setMapFieldOcr = (state, { mapFieldOcr }) => {
    return { ...state, loadingMapItemDetails: false, mapFieldOcr };
};

const setUpdateMapFilterDelimeter = (state, { error }) => {
    let newState = { ...state, loadingViewer: false };

    if (error) newState = { ...newState, error };

    return newState;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MAPS_GET_LIST_REQUEST:
            return setMapsRequest(state, action);

        case actionTypes.MAPS_GET_LIST_FAILURE:
        case actionTypes.MAPS_GET_LIST_SUCCESS:
            return setMaps(state, action);

        case actionTypes.MAPS_GET_COUNT_SUCCESS:
            return setMapsCount(state, action);

        case actionTypes.MAP_GET_DETAILS_FAILURE:
        case actionTypes.MAP_GET_DETAILS_SUCCESS:
            return setMap(state, action);

        case actionTypes.MAP_UPLOAD_FILE_REQUEST:
            return setFileIdRequest(state, action);

        case actionTypes.MAP_UPLOAD_FILE_FAILURE:
        case actionTypes.MAP_UPLOAD_FILE_SUCCESS:
            return setFileId(state, action);

        case actionTypes.MAP_GET_DETAILS_REQUEST:
        case actionTypes.MAP_ITEM_ADD_REQUEST:
        case actionTypes.MAP_ITEM_UPDATE_REQUEST:
        case actionTypes.MAP_ITEM_REMOVE_REQUEST:
            return setMapRequest(state, action);

        case actionTypes.MAP_ITEM_ADD_SUCCESS:
            return addMapItem(state, action);

        case actionTypes.MAP_ITEM_COPY_FAILURE:
        case actionTypes.MAP_ITEM_COPY_SUCCESS:
            return copyMapItem(state, action);

        case actionTypes.MAP_ITEM_UPDATE_FAILURE:
        case actionTypes.MAP_ITEM_UPDATE_SUCCESS:
            return updateMapItem(state, action);

        case actionTypes.MAP_ITEM_REMOVE_FAILURE:
        case actionTypes.MAP_ITEM_REMOVE_SUCCESS:
            return removeMapItem(state, action);

        case actionTypes.MAP_ACTION_DELETE_REQUEST:
        case actionTypes.MAP_ACTION_SAVE_REQUEST:
            return setMapActionRequest(state, action);

        case actionTypes.MAP_DELETE_ACTION:
        case actionTypes.MAP_ADD_ACTION:
        case actionTypes.MAP_UPDATE_ACTION:
            return saveMapAction(state, action);

        case actionTypes.MAP_ACTION_DELETE_FAILURE:
        case actionTypes.MAP_ACTION_DELETE_SUCCESS:
            return deleteMapAction(state, action);

        case actionTypes.MAP_ITEM_GET_DETAILS_REQUEST:
            return setMapItemRequest(state, action);

        case actionTypes.MAP_ITEM_GET_DETAILS_SUCCESS:
            return setMapItem(state, action);

        case actionTypes.MAP_ITEM_FIELD_UPDATE_REQUEST:
        case actionTypes.MAP_ITEM_DETAILS_UPDATE_REQUEST:
            return setMapItemFieldUpdateRequest(state, action);

        case actionTypes.MAP_ITEM_FIELD_UPDATE_SUCCESS:
        case actionTypes.MAP_ITEM_FIELD_UPDATE_FAILURE:
            return setMapItemFieldUpdate(state, action);

        case actionTypes.MAP_ITEM_DETAILS_UPDATE_SUCCESS:
            return setMapItemUpdate(state, action);

        case actionTypes.MAP_ITEM_FIELD_TYPE_CHANGE:
            return setMapItemFieldRegex(state, action);

        case actionTypes.MAP_CREATE_SUCCESS:
        case actionTypes.MAP_ACTION_COPY_SUCCESS:
            return setMapPost(state, action);

        case actionTypes.MAP_GET_OCR_RESULT_REQUEST:
        case actionTypes.MAP_ASSOCIATE_REQUEST:
        case actionTypes.MAP_UPDATE_MAP_FILTER_DELIMETER_REQUEST:
            return setLoadingViewerTrue(state, action);

        case actionTypes.MAP_GET_OCR_RESULT_SUCCESS:
            return setOcrResults(state, action);

        case actionTypes.MAP_GET_OCR_RESULT_FAILURE:
        case actionTypes.MAP_ASSOCIATE_FAILURE:
            return setMapError(state, action);

        case actionTypes.MAP_ITEM_ADD_FAILURE:
        case actionTypes.MAP_ASSOCIATE_SUCCESS:
            return loadingViewerFalse(state, action);

        case actionTypes.MAP_ITEM_OCR_FIELD_REQUEST:
            return loadingItemDetailsTrue(state, action);

        case actionTypes.MAP_ITEM_OCR_FIELD_SUCCESS:
            return setMapFieldOcr(state, action);

        case actionTypes.MAP_ITEM_OCR_FIELD_FAILURE:
            return loadingItemDetailsFalseError(state, action);

        case actionTypes.MAP_UPDATE_MAP_FILTER_DELIMETER_SUCCESS:
        case actionTypes.MAP_UPDATE_MAP_FILTER_DELIMETER_FAILURE:
            return setUpdateMapFilterDelimeter(state, action);

        default:
            return state;
    }
};

export default reducer;
