import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';

import Modal, { modalTypes } from '../../../components/Modal/Modal';
import { useLinkingOrders } from '../../../context/LinkingOrdersContext';

function ModalRemoveOrderItem({
    isOpen = false,
    handleToggleModal = () => {},
    onDeleteAssociatedOrders,
    onInitRemoveNfeOrderItems,
    docType,
}) {
    const {
        orderItemToRemove,
        setOrderItemToRemove,
        setSelectedOrders,
        selectedOrders,
        documentId,
    } = useLinkingOrders();

    const handleRemoveItem = async () => {
        const orderItemId = orderItemToRemove?.orderItemId;

        const newSelectOrders = selectedOrders.filter(i => i !== orderItemId);

        if (docType === 'NFE') {
            await onInitRemoveNfeOrderItems(
                documentId,
                orderItemToRemove?.nfeItemId,
                orderItemId,
            );
        } else {
            await onDeleteAssociatedOrders(documentId, orderItemId, docType);
        }

        setSelectedOrders(newSelectOrders);
        setOrderItemToRemove(null);

        handleToggleModal();
    };

    return (
        <Modal
            title={'Remover Item do Pedido'}
            isOpen={isOpen}
            handleToggle={handleToggleModal}
            type={modalTypes.ERROR}
            hasFooter={true}
            firstButtonText='Sim'
            secondButtonText='Não'
            toggleFirstButton={handleRemoveItem}
            toggleSecondButton={handleToggleModal}
        >
            {'Tem certeza que deseja remover o item de pedido?'}
        </Modal>
    );
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        onDeleteAssociatedOrders: (documentId, orderItemId, docType) =>
            dispatch(
                actions.deleteOrdersDocument(documentId, orderItemId, docType),
            ),
        onInitRemoveNfeOrderItems: (documentId, nfeItemId, orderItemId) =>
            dispatch(
                actions.initRemoveNfeOrderItems(
                    documentId,
                    nfeItemId,
                    orderItemId,
                ),
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalRemoveOrderItem);
