import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import messages from './EvtSelect.intl';

import { AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { AsyncPaginate } from 'react-select-async-paginate';

import axios from 'axios';

function EvtSelect(props) {
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [valueForm, setValueForm] = useState(null);
    const timer = useRef(null);

    const key = useRef(makeid(5));

    const {
        intl,
        // key,
        name,
        label,
        required,
        options,
        async,
        defaultOptions,
        disabled,
        url,
        labelKey,
        labelKey2,
        labelKey3,
        labelKey4,
        labelKey5,
        valueLabel,
        labelBrackets,
        labelDate,
        labelWithMask,
        labelWithSubMask,
        bracketMask,
        valueKey,
        handleSelectedOptionChange,
        option,
        isSearchable,
        skip,
        take,
        placeholder,
        paginated,
        addEmptyOption,
        isMulti = false,
    } = props;

    useEffect(() => {
        if (!url && paginated) handleLoadOptions();
    }, [props.options]);

    const { selectedValueOption, selectedLabelOption, isMultiValues } =
        option || {};
    const getLabel = useCallback(
        (option, value) => {
            const brackets = value.split('.');
            let result = option;

            brackets.forEach(bracket => {
                result = result[bracket];
            });

            return result;
        },
        [labelBrackets, valueKey],
    );

    const formatToBrackets = useCallback(
        (option, value) => {
            let result = getLabel(option, value);

            if (!!bracketMask) {
                result = bracketMask(result);
            }

            if (!result) {
                return '';
            }

            return ` [${result}]`;
        },
        [labelBrackets],
    );

    const valueFormat = useCallback(data => {
        let valueFormatted = String(data[valueLabel]) || '';

        valueFormatted = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(valueFormatted);

        return valueFormatted;
    }, []);

    const dateFormat = useCallback(value => {
        let date = new Date(value).toLocaleString(navigator.language);
        const [shippingDate, hour] =
            String(date) !== 'Invalid Date' ? date?.split(' ') : '';
        return shippingDate;
    }, []);

    const labelMaskFormat = useCallback(data => {
        let labelMaskFormatted = props.maskFunction(
            String(data[labelWithMask]),
            data[labelWithSubMask],
        );
        labelMaskFormatted = labelMaskFormatted
            ? ` [${labelMaskFormatted}]`
            : '';
        return labelMaskFormatted;
    }, []);

    useEffect(() => {
        if (isMulti) {
            const newMultiValues = formatOptions(isMultiValues);

            setValueForm(newMultiValues);
        } else {
            let newValue = selectedValue
                ? { label: selectedLabel, value: selectedValue }
                : null;
            setValueForm(newValue);
        }
    }, [selectedValue, selectedLabel, isMultiValues]);

    useEffect(() => {
        setSelectedValue(selectedValueOption);
        setSelectedLabel(selectedLabelOption);
    }, [selectedValueOption, selectedLabelOption]);

    function formatOptions(arrayValues) {
        return (
            arrayValues &&
            arrayValues.map(item => {
                return {
                    label:
                        item[labelKey] +
                        (item[labelKey2] ? ' - ' + item[labelKey2] : '') +
                        (item[labelKey3] ? ' - ' + item[labelKey3] : '') +
                        (item[labelKey4] ? ' - ' + item[labelKey4] : '') +
                        (item[labelKey5] ? ' - ' + item[labelKey5] : '') +
                        (item[valueLabel] ? ' - ' + valueFormat(item) : '') +
                        (item[labelDate]
                            ? ' - ' + dateFormat(item[labelDate])
                            : '') +
                        (labelBrackets
                            ? formatToBrackets(item, labelBrackets)
                            : '') +
                        (item[labelWithMask] ? labelMaskFormat(item) : ''),

                    value: getLabel(item, valueKey),
                    data: item,
                };
            })
        );
    }

    function handleChange(option) {
        if (!isMulti) {
            setSelectedValue(option.value);
            setSelectedLabel(option.label);
            handleSelectedOptionChange && handleSelectedOptionChange(option);
        } else {
            const optionFormatted = option.map(item => item.data);
            handleSelectedOptionChange &&
                handleSelectedOptionChange(optionFormatted);
        }
    }

    function fetchOptions(inputValue, callback) {
        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(
            () => handleLoadOptions(inputValue, callback),
            800,
        );
    }

    async function handleLoadOptions(inputValue, loadedOptions) {
        if (!url) {
            if (paginated) return { options: options, hasMore: false };

            loadedOptions([]);
            return;
        }

        const hasFilter = url.includes('?');

        const filter = inputValue
            ? (hasFilter ? '&' : '?') +
              `filter[${labelKey}]=like:${inputValue.toUpperCase()}`
            : '';
        const filter2 = inputValue
            ? labelKey2
                ? `&filter[${labelKey2}]=like:${inputValue.toUpperCase()}&or=true`
                : ''
            : '';
        const filter3 = inputValue
            ? labelKey3
                ? `&filter[${labelKey3}]=like:${inputValue.toUpperCase()}&or=true`
                : ''
            : '';
        const filter4 = inputValue
            ? labelKey4
                ? `&filter[${labelKey4}]=like:${inputValue.toUpperCase()}&or=true`
                : ''
            : '';
        const filter5 = inputValue
            ? labelKey5
                ? `&filter[${labelKey5}]=like:${inputValue.toUpperCase()}&or=true`
                : ''
            : '';

        const hasFilterInputValue =
            filter || filter2 || filter3 || filter4 || filter5 || hasFilter;

        const skipTake = paginated
            ? (hasFilterInputValue ? '&' : '?') +
              `skip=${
                  loadedOptions?.length ? loadedOptions?.length : '0'
              }&take=${take ? take : '40'}`
            : (hasFilterInputValue ? '&' : '?') +
              `skip=${skip ? skip : '0'}&take=${take ? take : '100'}`;

        try {
            const response = await axios.get(
                url + filter + filter2 + filter3 + skipTake,
            );

            const options = formatOptions(response.data || []);

            if (paginated) {
                if (!options.length) return { options, hasMore: false };
                return { options, hasMore: true };
            } else {
                let newOptions = [];
                if (addEmptyOption) newOptions.push({});
                newOptions = newOptions.concat(options);
                loadedOptions(newOptions);
            }
        } catch (err) {
            if (paginated) return { options: [], hasMore: false };
            else loadedOptions([]);
        }
    }

    return (
        <>
            {label && <label>{label}</label>}
            {paginated && async ? (
                <AsyncPaginate
                    key={key?.current}
                    placeholder={
                        typeof placeholder !== 'undefined'
                            ? placeholder
                            : intl.formatMessage(messages.selectPlaceholder)
                    }
                    className={'evt-select ' + props.className}
                    onChange={handleChange}
                    loadOptions={handleLoadOptions}
                    defaultOptions={
                        defaultOptions === undefined ? true : defaultOptions
                    }
                    value={valueForm}
                    isDisabled={disabled}
                    isSearchable={isSearchable}
                    debounceTimeout={800}
                    isMulti={isMulti}
                />
            ) : async ? (
                <AsyncSelect
                    key={key}
                    placeholder={
                        typeof placeholder !== 'undefined'
                            ? placeholder
                            : intl.formatMessage(messages.selectPlaceholder)
                    }
                    className={'evt-select ' + props.className}
                    onChange={handleChange}
                    loadOptions={fetchOptions}
                    defaultOptions={
                        defaultOptions === undefined ? true : defaultOptions
                    }
                    value={valueForm}
                    isDisabled={disabled}
                    isSearchable={isSearchable}
                    isMulti={isMulti}
                />
            ) : (
                <Select
                    key={key}
                    placeholder={
                        typeof placeholder !== 'undefined'
                            ? placeholder
                            : intl.formatMessage(messages.selectPlaceholder)
                    }
                    className={'evt-select ' + props.className}
                    options={options}
                    onChange={handleChange}
                    value={valueForm}
                    isDisabled={disabled}
                    isMulti={isMulti}
                />
            )}
            <AvField
                key={key + '_hidden'}
                type='hidden'
                id={name}
                name={name}
                value={selectedValue}
                validate={{
                    required: { value: required },
                }}
            />
        </>
    );
}

function makeid(length) {
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        );
    }
    return result;
}

EvtSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    async: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    url: PropTypes.string,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    disabled: PropTypes.bool,
    handleSelectedOptionChange: PropTypes.func,
};

export default injectIntl(EvtSelect);
