import React, { useState, useEffect, useMemo } from 'react';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

import messages from '../IntegrationListPage.intl';
import { filterPropertyTypes } from '../../../utils/enums';

import Header from '../../../components/Header/Header';
import EvtTable from '../../../components/EvtTable/EvtTable';
import EvtFilter from '../../../components/EvtFilter/EvtFilter';

import { columnsLogs } from '../components/ColumnsIntegrations';

function DocumentLogsIntegration(props) {
    const {
        onInitLogsSearch,
        onInitLogsSearchCount,
        integrationsLogs,
        integrationsLogsCount,
        loadingLogs,
        handleGetTrProps,
        activeTab,
        intl,
    } = props;

    const [query, setQuery] = useState('');
    const [filtersLog, setFiltersLog] = useState([]);

    useEffect(() => {
        reloadIntegrationsList();
    }, [query]);

    function reloadIntegrationsList() {
        const { filterValue } = filtersLog;

        window.history.replaceState(
            query,
            undefined,
            'integrations' + (query && query + `&index=${activeTab}`),
        );
        if (query.includes('take')) {
            onInitLogsSearch(query, filterValue);
            onInitLogsSearchCount(query, filterValue);
        }
    }

    function handleReloadButton() {
        reloadIntegrationsList();
    }

    function handleEvTableStateChangeNfse(queryTable) {
        setQuery(queryTable);
    }

    function handleFiltersLogChange(newFiltersLog, value) {
        setFiltersLog({ filters: newFiltersLog, filterValue: value });
    }

    //Testar após o Back-End Estiver pronto
    const logsColumns = useMemo(() => {
        return columnsLogs(integrationsLogs, intl);
    }, [integrationsLogs]);

    const filterFields = [
        {
            key: 'type',
            value: intl.formatMessage(messages.type),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'date',
            value: intl.formatMessage(messages.integrationDate),
            type: filterPropertyTypes.DATE,
        },

        {
            key: 'isSuccess',
            value: intl.formatMessage(messages.status),
            type: filterPropertyTypes.ENUM,
            options: [
                {
                    key: 'true',
                    _map: text =>
                        text.replace(
                            intl
                                .formatMessage(messages.statusSuccess)
                                .toLowerCase(),
                            true,
                        ),
                    value: intl.formatMessage(messages.statusSuccess),
                },
                {
                    key: 'false',
                    _map: text =>
                        text.replace(
                            intl
                                .formatMessage(messages.statusFailed)
                                .toLowerCase(),
                            false,
                        ),
                    value: intl.formatMessage(messages.statusFailed, {
                        entity: '',
                    }),
                },
            ],
        },
        {
            key: 'message',
            value: intl.formatMessage(messages.errorMessage),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'dataDescription',
            value: intl.formatMessage(messages.dataDescription),
            type: filterPropertyTypes.TEXT,
        },
    ];

    return (
        <div>
            <header className='table-screen'>
                <section className='title'>
                    <Header title={intl.formatMessage(messages.title)} />
                </section>
                <section className='btns-topo'>
                    <EvtFilter
                        properties={filterFields}
                        handleFiltersChange={handleFiltersLogChange}
                        loading={loadingLogs}
                    />
                    <button
                        className={`new-btn small ${
                            loadingLogs ? 'loading' : ''
                        }`}
                        onClick={handleReloadButton}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                </section>
            </header>
            <section>
                <EvtTable
                    filters={filtersLog.filters}
                    columns={logsColumns}
                    data={integrationsLogs}
                    length={integrationsLogsCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeNfse}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingLogs}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: integrationsLogsCount,
                    })}
                    fetchPage
                    search
                />
            </section>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        integrationsLogs: state.integrationReducer.integrationsLogs || [],
        integrationsLogsCount:
            state.integrationReducer.integrationsLogsCount || 0,
        loadingLogs: state.integrationReducer.loadingLogs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitLogsSearch: (filter, search) =>
            dispatch(actions.initIntegrationsLogsSearch(filter, search)),
        onInitLogsSearchCount: (filter, search) =>
            dispatch(actions.initIntegrationsLogsSearchCount(filter, search)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(DocumentLogsIntegration)),
);
