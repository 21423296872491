import * as actionTypes from '../actions/actionTypes';

const initialState = {
    documentsCteCount: 0,
    documentsCte: [],
    loadingCteList: true,

    cteDocument: null,
    loadingCteDetails: false,

    cteOrders: [],
    cteOrdersCount: 0,
    loadingCteOrders: false,
};

const setLoadingTrue = (state, action) => {
    return {
        ...state,
        loadingCteList: true,
        loadingCteDetails: true,
    };
};

const setLoadingFalse = (state, { error }) => {
    let newState = {
        ...state,
        loadingCteList: false,
        loadingCteDetails: false,
    };

    if (error) newState = { ...newState, error };
    return {
        ...newState,
    };
};

//#region Cte
const setCreateDocumentCte = (state, { error }) => {
    let newState = {
        ...state,
        loadingCteDetails: false,
    };

    if (error) newState = { ...newState, error };
    return {
        ...newState,
    };
};

const setDetailsLoadingTrue = (state, action) => {
    return {
        ...state,
        loadingCteDetails: true,
    };
};

const setDocumentsCte = (state, { documentsCte, documentsCteCount, error }) => {
    let newState = {
        ...state,
        documentsCte,
        documentsCteCount,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingCteList: false,
        loadingCteDetails: false,
    };
};

const setCteDocumentDetails = (state, { cteDocument, error }) => {
    let newState = {
        ...state,
        cteDocument,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingCteDetails: false,
    };
};
//#endregion Cte

// #region Cte Orders
const setLoadingCteOrdersTrue = (state, action) => {
    return {
        ...state,
        loadingCteOrders: true,
    };
};

const setCteOrders = (state, { cteOrders, error }) => {
    let newState = {
        ...state,
        cteOrders,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingCteOrders: false,
    };
};

const setCteOrdersCount = (state, { cteOrdersCount, error }) => {
    let newState = {
        ...state,
        cteOrdersCount,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
    };
};
// #endregion Cte Orders

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //REQUEST
        case actionTypes.CTE_DOCUMENT_CREATE_REQUEST:
        case actionTypes.CTE_DOCUMENTS_GET_LIST_REQUEST:
            return setLoadingTrue(state, action);

        //LOADING CTE DETAILS
        case actionTypes.CTE_DOCUMENTS_GET_DETAILS_REQUEST:
        case actionTypes.INVOICE_PUT_REQUEST:
            return setDetailsLoadingTrue(state, action);

        //SET CREATE DOCUMENT SUCCESS
        case actionTypes.CTE_DOCUMENT_CREATE_SUCCESS:
        case actionTypes.CTE_DOCUMENT_CREATE_FAILURE:
        case actionTypes.INVOICE_PUT_SUCCESS:
        case actionTypes.INVOICE_PUT_FAILURE:
            return setCreateDocumentCte(state, action);

        //SET DOCUMENTS SUCCESS
        case actionTypes.CTE_DOCUMENTS_GET_LIST_SUCCESS:
        case actionTypes.CTE_DOCUMENTS_GET_LIST_FAILURE:
            return setDocumentsCte(state, action);

        //SET CTE DETAILS SUCCESS
        case actionTypes.CTE_DOCUMENTS_GET_DETAILS_SUCCESS:
        case actionTypes.CTE_DOCUMENTS_GET_DETAILS_FAILURE:
            return setCteDocumentDetails(state, action);

        //SET CTE ORDERS
        case actionTypes.CTE_ORDERS_GET_LIST_REQUEST:
            return setLoadingCteOrdersTrue(state, action);

        case actionTypes.CTE_ORDERS_GET_LIST_SUCCESS:
        case actionTypes.CTE_ORDERS_GET_LIST_FAILURE:
            return setCteOrders(state, action);

        case actionTypes.CTE_ORDERS_GET_COUNT_SUCCESS:
        case actionTypes.CTE_ORDERS_GET_COUNT_FAILURE:
            return setCteOrdersCount(state, action);

        default:
            return state;
    }
};

export default reducer;
