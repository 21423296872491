import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    reportScheduled: {
        id: 'settings.reportScheduled',
        defaultMessage: 'Relatórios Agendados',
    },
    Emails: {
        id: 'settings.enableMultipleOrders',
        defaultMessage: 'Emails',
    },
    recurrence: {
        id: 'settings.recurrence.title',
        defaultMessage: 'Recorrência',
    },
    daily: {
        id: 'settings.recurrence.daily',
        defaultMessage: 'Diário',
    },
    weekly: {
        id: 'settings.recurrence.weekly',
        defaultMessage: 'Semanal',
    },
    monthly: {
        id: 'settings.recurrence.monthly',
        defaultMessage: 'Mensal',
    },
});

export default messages;
