import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    associatedOrderItems: {
        id: 'orders.associatedOrderItems',
        defaultMessage: 'Pedidos Associados',
    },

    invoicesTitle: {
        id: 'invoices.title',
        defaultMessage: 'Documentos',
    },
    titleCte: {
        id: 'cte.cteTab',
        defaultMessage: 'CT-e',
    },
});

export default messages;
