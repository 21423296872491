import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const PurchaseOrder = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const { prod } = nfeItem;

    return (
        <>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.xPed)}
                            type='text'
                            name='xPed'
                            id='xPed'
                            value={prod?.xPed || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.nItemPed)}
                            type='text'
                            name='nItemPed'
                            id='nItemPed'
                            value={prod?.nItemPed || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(PurchaseOrder);
