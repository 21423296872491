import React, { useContext, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';

import * as actions from '../../store/actions/index';
import { AuthContext } from '../../context/AuthContext';

import Modal from '../Modal/Modal';
import InvoicesStatusCounter from '../InvoicesStatusCounter/InvoicesStatusCounter';
import ActionsButtons from '../ActionsButtons/ActionsButtons';
import messages from './NfeDetailsForm.intl';
import { nfeFormGroupTemplate, nfeTemplate } from './components/NfeTemplate';
import DynamicTemplateForm from '../DynamicTemplateForm/DynamicTemplateForm';
import { connect } from 'react-redux';
import ModalConfirmReturnToReview from '../ModalConfirmReturnToReview/ModalConfirmReturnToReview';
import ModalJustificationOperationNotRealized from './components/ModalJustificationOperationNotRealized';
import DocumentViewer, { getBoxes } from '../DocumentViewer/DocumentViewer';
import { DocumentTypes } from '../../utils/enums';

const NfeDetailsForm = props => {
    const {
        intl,
        loading,
        nfeDocument,
        documentHistory,
        associatedDocumentsCount,
        nfeItemsCount,
        nfeOrdersCount,
        validationErrors,
        handleToggleAssociatedDocModal,
        handleRedirectToOrders,
        handleToggleItemsModal,
    } = props;
    const context = useContext(AuthContext);

    const [showJustificationModal, setShowJustificationModal] = useState(false);
    const [showReturnToReviewModal, setShowReturnToReviewModal] = useState(false);

    const [isDraft, setIsDraft] = useState(false);
    const [submitValues, setSubmitValues] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);
    const [showModalSendDocument, setShowModalSendDocument] = useState(false);
    const [fieldsWithError, setFieldsWithError] = useState([]);
    const [selectedFormField, setSelectedFormField] = useState(null);
    const [boxes, setBoxes] = useState([]);
    const [collapse, setCollapse] = useState([1, 2, 3, 4, 5, 6, 7]);

    const hasDocumentUpdateClaim = context.hasClaim('nfe-document-update');
    const canViewFile = context.hasClaim('nfe-file-view');

    const nfeDocumentId = nfeDocument?.documentId || '';
    
    const fields = nfeDocument?.form?.fields;
    const reviewStep =
        nfeDocument?.currentStep?.order === 3 &&
        nfeDocument?.currentStep?.status === 'pending';

    const hasSuccessManifestAccept =
        nfeDocument?.events?.filter(
            x =>
                x.currentStep?.status.toLowerCase() === 'success' &&
                x.description?.toLowerCase() === 'confirmacao da operacao',
        ).length >= 1;

    const hasSuccessManifestUnaware =
        nfeDocument?.events?.filter(
            x =>
                x.currentStep?.status.toLowerCase() === 'success' &&
                x.description?.toLowerCase() === 'desconhecimento da operacao',
        ).length >= 1;

    const hasSuccessManifestOperationNotRealized =
        nfeDocument?.events?.filter(
            x =>
                x.currentStep?.status.toLowerCase() === 'success' &&
                x.description?.toLowerCase() === 'operacao nao realizada',
        ).length >= 1;

    const canUpdateForm = context.hasClaim('nfe-document-update') && reviewStep;
    const canManifestAccept =
        !hasSuccessManifestAccept && context.hasClaim('nfe-manifest-accept');

    const canManifestUnaware =
        !hasSuccessManifestUnaware && context.hasClaim('nfe-manifest-unaware');

    const canManifestOperationNotRealized =
        !hasSuccessManifestOperationNotRealized &&
        context.hasClaim('nfe-manifest-operation-not-realized');
    
    const shouldShowReturnToReviewStepButton = 
        context.hasClaim('document-update-status') &&
        nfeDocument?.currentStep?.order === 4 && nfeDocument?.currentStep?.status === 'pending';

    const dropdownItems = [
        //Salvar
        {
            name: intl.formatMessage(messages.saveButton),
            onClick: () => setIsDraft(true),
            disabled: !canUpdateForm,
            type: 'submit',
        },
        //Cancelar
        {
            name: intl.formatMessage(messages.cancelButton),
            onClick: () => handleToggleModalCancel(),
            disabled: !canUpdateForm,
        },
        //ManifestAccept
        {
            name: intl.formatMessage(messages.acceptManifest),
            onClick: () => handleManifestAccept(nfeDocument),
            disabled: !canManifestAccept,
        },
        //ManifestUnaware
        {
            name: intl.formatMessage(messages.unawareManifest),
            onClick: () => handleManifestUnaware(nfeDocument),
            disabled: !canManifestUnaware,
        },
        //ManifestOperationNotRealized
        {
            name: intl.formatMessage(messages.operationNotRealizedManifest),
            onClick: () => handleToggleJustificationModal(),
            disabled: !canManifestOperationNotRealized,
        },
        // Voltar para etapa de revisão
        {
            name: intl.formatMessage(messages.returnToReviewStep),
            onClick: () => handleToggleReturnToReviewModal(),
            disabled: !shouldShowReturnToReviewStepButton
        }
    ];

    useEffect(() => {
        const errors = Object.keys(validationErrors);
        setFieldsWithError(errors);
    }, [validationErrors]);

    const handleToggleModalSendDocument = (cancel = false) => {
        if (cancel) setSubmitValues({});

        setShowModalSendDocument(!showModalSendDocument);
    };

    const onValidSubmit = (e, values) => {
        if (!isDraft) {
            setSubmitValues({ e, values, isDraft });
            handleToggleModalSendDocument();
        } else {
            props.handleFormSubmit(e, values, isDraft);
        }
    };

    function handleSubmitForm() {
        const { e, values, isDraft } = submitValues;
        props.handleFormSubmit(e, values, isDraft);
        setSubmitValues({});
        handleToggleModalSendDocument();
    }

    const handleDynamicFieldBlur = (e, index) => {
        const boxes = getBoxes(fields);
        setBoxes(boxes);
    };

    const handleDynamicFieldFocus = (e, key) => {
        const field = fields.find(ff => ff.key === key);

        const bb = field.boundingBox
            ? {
                  ...field.boundingBox,
                  key,
              }
            : {
                  key,
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0,
                  page: 1,
              };

        setBoxes([bb]);
        setSelectedFormField(field);
    };

    const handleDynamicFieldChange = (e, key) => {
        if (!key) return;
        setFieldsWithError(fieldsWithError.filter(fkey => fkey !== key));
    };

    const handleFormGroupToggle = (e, index) => {
        const found = collapse.find(col => col === Number(index));

        if (found) {
            const filtered = collapse.filter(col => col !== Number(index));
            setCollapse(filtered);
        } else {
            setCollapse([...collapse, Number(index)]);
        }
    };

    const handleManifestAccept = nfeDocument => {
        props.onInitManifestAccept(nfeDocumentId);
    };

    const handleManifestUnaware = nfeDocument => {
        props.onInitManifestUnaware(nfeDocumentId);
    };

    const handleManifestOperationNotRealized = (nfeDocument, justification) => {
        props.onInitManifestOperationNotRealized(nfeDocumentId, justification);
    };

    const handleSubmitJustification = (e, values) => {
        const justificationText = values.justificationText;
        handleManifestOperationNotRealized(nfeDocument, justificationText);
        handleToggleJustificationModal();
    };

    const handleToggleJustificationModal = () =>
        setShowJustificationModal(!showJustificationModal);
    
    const handleToggleModalCancel = () => setShowModalCancel(!showModalCancel);

    const handleSubmitCancel= () => {
        props.handleSubmitCancel(nfeDocumentId);
        handleToggleModalCancel();
    }

    const handleToggleReturnToReviewModal = () => 
        setShowReturnToReviewModal(!showReturnToReviewModal);
    
    const handleReturnToReviewStepClick= () => {
        var nfeDocumentId = nfeDocument?.documentId
        props.onReturnToReviewStep(nfeDocumentId);
    }

    return (
        <>
            <InvoicesStatusCounter
                steps={nfeDocument?.steps}
                currentStep={nfeDocument?.currentStep}
                useAltText={false}
            />

            <section className='map-nota row'>
                <section className='col-6'>
                    {nfeDocument?.fileId && canViewFile &&(
                        <DocumentViewer
                            selectedDocument={nfeDocument}
                            documentHistory={documentHistory}
                            boxes={[]}
                            selectedItem={selectedFormField}
                            docType={DocumentTypes.NFE}
                        />
                    )}
                </section>

                <section className='fields-maps col-6'>
                    <AvForm onValidSubmit={onValidSubmit}>
                        <ActionsButtons
                            loading={loading}
                            canUpdateForm={canUpdateForm}
                            dropdownItems={dropdownItems}
                            selectedDocument={nfeDocument}
                            documentsAttachmentCount={associatedDocumentsCount}
                            disableTransactionButton
                            onClickAttachmentsButton={
                                handleToggleAssociatedDocModal
                            }
                            nfeItemsCount={nfeItemsCount}
                            onCLickItemsNfe={handleToggleItemsModal}
                            ordersCount={nfeOrdersCount}
                            onClickOrdersButton={handleRedirectToOrders}
                            hideSubmitButtonClaim={context.hasClaim(
                                'nfe-document-hide-submit-button',
                            )}
                        />
                        <Row>
                            <Col className='cards-fields' xs={12}>
                                <DynamicTemplateForm
                                    fields={fields}
                                    template={nfeTemplate()}
                                    templateFormGroups={nfeFormGroupTemplate(
                                        intl,
                                    )}
                                    fieldsWithError={fieldsWithError}
                                    handleDynamicFieldBlur={
                                        handleDynamicFieldBlur
                                    }
                                    handleDynamicFieldFocus={
                                        handleDynamicFieldFocus
                                    }
                                    handleDynamicFieldChange={
                                        handleDynamicFieldChange
                                    }
                                    loading={loading}
                                    readOnly={true}
                                    handleFormGroupToggle={
                                        handleFormGroupToggle
                                    }
                                    isRevisionStep={reviewStep}
                                    hasDocumentUpdateClaim={
                                        hasDocumentUpdateClaim
                                    }
                                    documentId={nfeDocument?.documentId}
                                    collapse={collapse}
                                />
                            </Col>
                        </Row>
                    </AvForm>
                </section>
            </section>

            {/* Modals */}
            <Modal
                title={intl.formatMessage(messages.confirmSend)}
                isOpen={showModalSendDocument}
                handleToggle={() => handleToggleModalSendDocument(true)}
                hasFooter={true}
                firstButtonClass='delete'
                secondButtonClass='btn-submit'
                firstButtonText={intl.formatMessage(messages.noText)}
                secondButtonText={intl.formatMessage(messages.yesText)}
                toggleFirstButton={() => handleToggleModalSendDocument(true)}
                toggleSecondButton={handleSubmitForm}
            >
                <p>Atenção:</p>
                {intl.formatMessage(messages.sendDocumentConfirmText)}
            </Modal>

            <Modal
                    title={intl.formatMessage(messages.cancelButton)}
                    isOpen={showModalCancel}
                    handleToggle={() => handleToggleModalCancel(true)}
                    hasFooter={true}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    firstButtonText={intl.formatMessage(messages.noText)}
                    secondButtonText={intl.formatMessage(messages.yesText)}
                    toggleFirstButton={() => handleToggleModalCancel(true)}
                    toggleSecondButton={handleSubmitCancel}
                >
                    <p>Atenção:</p>
                    {intl.formatMessage(messages.confirmCancelText)}
            </Modal>
            
            <ModalJustificationOperationNotRealized
                isOpen={showJustificationModal}
                handleToggleModal={handleToggleJustificationModal}
                handleSubmit={handleSubmitJustification}
                loadingModal={loading}
            />

            <ModalConfirmReturnToReview
                handleToggleReturnToReviewModal = {handleToggleReturnToReviewModal}
                showReturnToReviewModal = {showReturnToReviewModal}
                handleReturnToReviewStepClick = {handleReturnToReviewStepClick}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        onInitManifestAccept: documentId =>
            dispatch(actions.initManifestAccept(documentId)),
        onInitManifestUnaware: documentId =>
            dispatch(actions.initManifestUnaware(documentId)),
        onInitManifestOperationNotRealized: (documentId, justificative) =>
            dispatch(
                actions.initManifestOperationNotRealized(
                    documentId,
                    justificative,
                ),
            ),
        onReturnToReviewStep: documentId =>
            dispatch(actions.returnToReviewStep(documentId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(NfeDetailsForm),
);

NfeDetailsForm.propTypes = {
    nfeDocument: PropTypes.object,
    associatedDocumentsCount: PropTypes.number,
    nfeItemsCount: PropTypes.number,
    nfeOrdersCount: PropTypes.number,
    loading: PropTypes.bool,
    validationErrors: PropTypes.array,
    handleFormSubmit: PropTypes.func,
    handleToggleAssociatedDocModal: PropTypes.func,
    handleToggleItemsModal: PropTypes.func,
    handleRedirectToOrders: PropTypes.func,
};
