import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as actions from '../../../store/actions/index';
import messages from './Widget.intl';

import SuppliersCounterWidget from '../SuppliersCounterWidget/SuppliersCounterWidget';
import PendingOrdersCounterWidget from '../PendingOrdersCounterWidget/PendingOrdersCounterWidget';
import PendingTransactionsCounterWidget from '../PendingTransactionsCounterWidget/PendingTransactionsCounterWidget';
import PendingInvoicesCounterWidget from '../PendingInvoicesCounterWidget/PendingInvoicesCounterWidget';
import FinancialVolumeCounterWidget from '../FinancialVolumeCounterWidget/FinancialVolumeCounterWidget';
import MonthlyConsumptionCounterWidget from '../MonthlyConsumptionCounterWidget/MonthlyConsumptionCounterWidget';
import MonthByMonthVolumeChart from '../MonthByMonthVolumeChart/MonthByMonthVolumeChart';
import MonthlyVolumeByClientChart from '../MonthlyVolumeByClientChart/MonthlyVolumeByClientChart';
import OverdueTransactionsCounterWidget from '../OverdueTransactionsCounterWidget/OverdueTransactionsCounterWidget';
import GenericWidgetTable from '../GenericWidgetTable/GenericWidgetTable';

const widgetTypes = {
    suppliersCounter: 'suppliers-counter',
    pendingOrdersCounter: 'pending-orders-counter',
    pendingTransactionsCounter: 'pending-payments-counter',
    pendingInvoicesCounter: 'pending-invoices-counter',
    financialVolumeCounter: 'financial-volume-counter',
    monthlyConsumptionCounter: 'monthly-consuption-counter',
    monthByMonthVolumeChart: 'month-by-month-volume-chart',
    monthlyVolumeByClientChart: 'monthly-volume-by-client-chart',
    overdueTransactionsCounterWidget: 'overdue-payments-counter',
    pendingDocumentsListWidget: 'pending-documents-list',
    paymentsDueListWidget: 'payments-due-list',
};
class Widget extends Component {
    componentDidMount() {
        const { widgetId, url } = this.props;
        this.props.onInitWidget(widgetId, url);    
    }

    render() {
        const { widgetType, intl, readOnly } = this.props;

        let widget;
        switch (widgetType) {
            case widgetTypes.suppliersCounter:
                widget = (
                        <SuppliersCounterWidget
                            {...this.props.data}
                            readOnly={readOnly}
                        />
                );
                break;

            case widgetTypes.pendingOrdersCounter:
                widget = (
                    <PendingOrdersCounterWidget
                        {...this.props.data }
                        ordersCount={this.props.ordersCountWidget}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.pendingTransactionsCounter:
                widget = (
                    <PendingTransactionsCounterWidget
                        {...this.props.data}
                        transactionsCount={this.props.transactionsCountWidget}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.overdueTransactionsCounterWidget:
                widget = (
                    <OverdueTransactionsCounterWidget
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.pendingInvoicesCounter:
                widget = (
                    <PendingInvoicesCounterWidget
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.financialVolumeCounter:
                widget = (
                    <FinancialVolumeCounterWidget
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.monthlyConsumptionCounter:
                widget = (
                    <MonthlyConsumptionCounterWidget
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;
            case widgetTypes.monthByMonthVolumeChart:
                widget = (
                    <MonthByMonthVolumeChart
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.monthlyVolumeByClientChart:
                widget = (
                    <MonthlyVolumeByClientChart
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

                case widgetTypes.paymentsDueListWidget:
                case widgetTypes.pendingDocumentsListWidget:
                    widget = (
                        <GenericWidgetTable
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                    break;

            default:
                break;
        }

        return (
            <React.Fragment>
                {this.props.data ? (
                    widget
                ) : (
                    <h2>{intl.formatMessage(messages.loadingText)}</h2>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const dataArray = state.widgetReducer.dataArray;
    const data = dataArray.find(d => d.widgetId === props.widgetId);

    return {
        data,
        ordersCountWidget: state.widgetReducer.ordersCountWidget || 0,
        transactionsCountWidget: state.widgetReducer.transactionsCountWidget || 0,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitWidget: (widgetId, url) =>
            dispatch(actions.initWidget(widgetId, url)),
            onInitOrdersCount: filter =>    
            dispatch(actions.initOrdersCount(filter)),
            onInitTransactionsCount: (filter, documentId) =>
            dispatch(actions.initTransactionsCount(filter, documentId)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(Widget));
