import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import DynamicFormFilterModal from '../DynamicFormFilterModal/DynamicFormFilterModal';
import { filterPropertyTypes } from '../../utils/enums';
import { toast } from 'react-toastify';
import { Operators } from '../../models/FilterBuilder';

const ReportFilterModal = props => {
    const { showModal, handleToggleModal, handleGenerateReport } = props;

    const defaultFilter = {
        hideAddButton: false,
        property: '',
        operator: '',
        value: '',
        type: '',
        enums: [],
        id: 'firstFilter',
    };

    const [properties, setProperties] = useState([]);
    const [filters, setFilters] = useState([defaultFilter]);

    useEffect(() => {
        setFilters([defaultFilter]);
        const newProperties = handleFormatToProperties(props.reportFilters);
        setProperties(newProperties);
    }, [showModal]);

    const handleAddRemoveFilter = ({ id, showAddButton }) => {
        if (showAddButton) {
            setFilters([
                ...filters,
                {
                    showAddButton: false,
                    property: '',
                    operator: '',
                    value: '',
                    type: '',
                    enums: [],
                    id: Math.random()
                        .toString(36)
                        .substr(2, 9),
                },
            ]);
        } else {
            const newFilters = filters.filter(f => {
                return f.id !== id;
            });

            setFilters(newFilters);
        }
    };

    const handleFieldChange = (e, id, field) => {
        const value = e.target.value;

        if (value === '') return;

        const property = properties.find(prop => prop.key === value);
        const canUpdateType = field === 'property';
        const canUpdateEnums =
            property && property.type === filterPropertyTypes.ENUM;

        const newFilters = filters.filter(f => {
            if (f.id === id) {
                f[field] = value;
                canUpdateType && (f['type'] = property.type);
                canUpdateEnums && (f['enums'] = property.options);
                canUpdateEnums && (f['operator'] = 'eq');
            }

            return f;
        });

        setFilters(newFilters);
    };

    const handleResetFilter = () => {
        setFilters([defaultFilter]);
        handleToggleModal();
    };

    const handleFormatToProperties = propertiesFilters => {
        let newProperties = [];

        if (propertiesFilters) {
            propertiesFilters.forEach(f => {
                const type =
                    f.type === 'date'
                        ? filterPropertyTypes.DATE
                        : filterPropertyTypes.TEXT;

                newProperties.push({
                    key: f.key,
                    value: f.name,
                    type,
                });
            });
        }

        return newProperties;
    };

    const handleFormSubmit = (e, values) => {
        const filters = Object.entries(values.filters).map(filter => {
            const [key, objFilter] = filter;
            const property = properties.find(p => p.key === key);
            let newFilter = {};
            if (
                !!objFilter.value ||
                !!objFilter.valueFrom ||
                !!objFilter.valueTo
            ) {
                let defaultOperator =
                    property.type === filterPropertyTypes.ENUM
                        ? Operators.equalTo
                        : Operators.like;

                newFilter = {
                    showAddButton: false,
                    property: key,
                    operator: property?.operator || defaultOperator,
                    value: objFilter?.value,
                    type: property.type,
                    enums: property?.options || [],
                    id: key,
                };

                if (objFilter?.valueFrom) {
                    newFilter['valueFrom'] = objFilter?.valueFrom;
                }
                if (objFilter?.valueTo) {
                    newFilter['valueTo'] = objFilter?.valueTo;
                }
            }
            
            return newFilter;
        });

        let array = [];

        let newFilters = filters.map(filter => {
            if (!!filter?.valueFrom) {
                const valueFrom = new Date(
                    filter.valueFrom + 'T00:00:00Z',
                ).toISOString();

                filter.operator = 'ge';
                filter.value = valueFrom;

                array.push({ ...filter });
            }

            if (!!filter?.valueTo) {
                const valueTo = new Date(
                    filter.valueTo + 'T23:59:59Z',
                ).toISOString();

                filter.operator = 'le';
                filter.value = valueTo;
            }

            return filter;
        });

        newFilters = newFilters.concat(array);
        newFilters.forEach(filter => {
            if (!!filter?.valueFrom || !!filter?.valueTo) {
                delete filter?.valueFrom;
                delete filter?.valueTo;
            }
        });

        handleGenerateReport(newFilters);
        handleToggleModal();
    };

    return (
        <>
            <DynamicFormFilterModal
                filterEntityTitle={'Filtros do Relatório'}
                buttonSubmitText={'Gerar'}
                filters={filters}
                isOpen={showModal}
                handleToggle={handleToggleModal}
                properties={properties}
                handleFormSubmit={handleFormSubmit}
                handleAddRemoveFilter={handleAddRemoveFilter}
                handleFieldChange={handleFieldChange}
                handleResetFilter={handleResetFilter}
                hideReset
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        reportFilters: state.reportReducer.reportFilters || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportFilterModal);
