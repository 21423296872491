import React, { useEffect, useState } from 'react';

import { NavLink as ReactNavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import messages from './MapListItem.intl';

import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';

function MapListItem(props) {
    const { intl, selectedMapItem } = props;
    const { boundingBox } = selectedMapItem || {};

    const [inputValue, setInputValue] = useState(null);
    const [isMouseOverButton, setIsMouseOverButton] = useState(false);

    useEffect(() => {
        setInputValue(props.item?.field?.name || '');
    }, [props.item]);

    function handleMoveOverIndexButton(e) {
        if (e?.target?.classList?.value?.indexOf('btn-danger') === -1) {
            setIsMouseOverButton(true);
        }
    }

    function handleMoveOutIndexButton(e) {
        if (e?.target?.classList?.value?.indexOf('btn-primary') === -1) {
            setIsMouseOverButton(false);
        }
    }

    function handleFieldFocus(e) {
        props.handleFieldFocus(e, props.id);
    }

    function handleInputChange(e) {
        setInputValue(e?.target?.value);
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <Form>
                        <FormGroup>
                            <Input
                                type='text'
                                name='field.name'
                                id={props.id}
                                onFocus={handleFieldFocus}
                                onBlur={e =>
                                    props.handleFieldChange(
                                        e,
                                        props.item?.mapItemId,
                                    )
                                }
                                onChange={handleInputChange}
                                value={inputValue}
                            />
                        </FormGroup>
                    </Form>

                    <button
                        className={
                            isMouseOverButton ? 'btn-danger' : 'btn-primary'
                        }
                        onClick={e =>
                            props.handleRemoveItemClick(
                                e,
                                props.item?.mapItemId,
                            )
                        }
                        onMouseOver={handleMoveOverIndexButton}
                        onMouseOut={handleMoveOutIndexButton}
                    >
                        {isMouseOverButton ? 'X' : props.index}
                    </button>
                </Col>
            </Row>
            {selectedMapItem && (
                <div className='link-action-register display'>
                    <strong>
                        {`X: ${boundingBox?.x} - Y: 
                        ${boundingBox?.y} - Width: 
                        ${boundingBox?.width} - Height: 
                        ${boundingBox?.height} ${boundingBox?.page &&
                            ` - Page: ${boundingBox?.page}`} `}
                    </strong>
                </div>
            )}
            <Row>
                <Col xs={8}>
                    <ReactNavLink
                        className='link-action-register'
                        to='#'
                        onClick={() => {
                            props.handleItemDetails(props.item?.mapItemId);
                        }}
                    >
                        {intl.formatMessage(messages.itemDetails)}
                    </ReactNavLink>
                </Col>
                <Col xs={4}>
                    {selectedMapItem && (
                        <ReactNavLink
                            onClick={props.handleSelectedMapItemCleanupClick}
                            to='#'
                            style={{
                                fontSize: '14px',
                                color: 'red',
                            }}
                        >
                            {intl.formatMessage(messages.itemCleanup)}
                        </ReactNavLink>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default injectIntl(MapListItem);
