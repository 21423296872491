import * as actionTypes from '../actions/actionTypes';

const initialState = {
    auditLogs: null,
    technicalLogs: null,
    loadingAuditLogsList: false,
    loadingTechnicalLogsList: false,
    auditLogsCount: 0,
    technicalLogsCount: 0,
};

const setAuditLogsRequest = (state, action) => {
    return {
        ...state,
        auditLogs: undefined,
        loadingAuditLogsList: true,
    };
};

const setAuditLogsCount = (state, action) => {
    return {
        ...state,
        auditLogsCount: action.auditLogsCount,
    };
};

const setAuditLogs = (state, action) => {
    return {
        ...state,
        auditLogs: action.auditLogs,
        loadingAuditLogsList: false,
    };
};

const setTechnicalLogsRequest = (state, action) => {
    return {
        ...state,
        technicalLogs: undefined,
        loadingTechnicalLogsList: true,
    };
};

const setTechnicalLogsCount = (state, action) => {
    return {
        ...state,
        technicalLogsCount: action.technicalLogsCount,
    };
};

const setTechnicalLogs = (state, action) => {
    return {
        ...state,
        technicalLogs: action.technicalLogs,
        loadingTechnicalLogsList: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUDIT_LOGS_GET_LIST_REQUEST:
            return setAuditLogsRequest(state, action);
        case actionTypes.AUDIT_LOGS_GET_LIST_FAILURE:
        case actionTypes.AUDIT_LOGS_GET_LIST_SUCCESS:
            return setAuditLogs(state, action);
        case actionTypes.TECHNICAL_LOGS_GET_LIST_REQUEST:
            return setTechnicalLogsRequest(state, action);
        case actionTypes.TECHNICAL_LOGS_GET_LIST_FAILURE:
        case actionTypes.TECHNICAL_LOGS_GET_LIST_SUCCESS:
            return setTechnicalLogs(state, action);
        case actionTypes.AUDIT_LOGS_GET_COUNT_SUCCESS:
            return setAuditLogsCount(state, action);
        case actionTypes.TECHNICAL_LOGS_GET_COUNT_SUCCESS:
            return setTechnicalLogsCount(state, action);
        default:
            return state;
    }
};

export default reducer;
