import * as actionTypes from '../actions/actionTypes';

const initialState = {
    documentsNfeCount: 0,
    documentsNfe: [],
    loadingNfeList: true,

    summaryNfeCount: 0,
    summaryNfe: [],
    loadingNfeSummaryList: true,

    nfeDocument: null,
    loadingNfeDetails: false,

    nfeItems: [],
    nfeItemsCount: 0,
    loadingNfeItems: true,

    nfeItemDetails: {},
    loadingNfeItemDetails: false,

    nfeOrders: [],
    nfeOrdersCount: 0,
    loadingNfeOrders: true,

    isContinueModal: false,
    isSuccessModal: false,
    loadingModalNfeOrders: false,
};

const setLoadingTrue = (state, action) => {
    return {
        ...state,
        loadingNfeList: true,
        loadingNfeSummaryList: true,
        loadingNfeDetails: true,
    };
};

const setLoadingFalse = (state, { error }) => {
    let newState = {
        ...state,
        loadingNfeList: false,
        loadingNfeSummaryList: false,
        loadingNfeDetails: false,
    };

    if (error) newState = { ...newState, error };
    return {
        ...newState,
    };
};

//#region Nfe
const setCreateDocumentNfe = (state, { error }) => {
    let newState = {
        ...state,
        loadingNfeDetails: false,
    };

    if (error) newState = { ...newState, error };
    return {
        ...newState,
    };
};

const setDetailsLoadingTrue = (state, action) => {
    return {
        ...state,
        loadingNfeDetails: true,
    };
};

const setDocumentsNfe = (state, { documentsNfe, documentsNfeCount, error }) => {
    let newState = {
        ...state,
        documentsNfe,
        documentsNfeCount,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingNfeList: false,
        loadingNfeDetails: false,
    };
};

const setNfeDocumentDetails = (state, { nfeDocument, error }) => {
    let newState = {
        ...state,
        nfeDocument,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingNfeDetails: false,
    };
};

// Summary Nfe

const setSummaryNfe = (state, { summaryNfe, summaryNfeCount, error }) => {
    let newState = {
        ...state,
        summaryNfe,
        summaryNfeCount,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingNfeSummaryList: false,
    };
};
//#endregion Nfe

//#region Nfe Items
const setLoadingNfeItemsTrue = (state, action) => {
    return {
        ...state,
        loadingNfeItems: true,
    };
};

const setNfeItems = (state, { nfeItems, error }) => {
    let newState = {
        ...state,
        nfeItems,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingNfeItems: false,
    };
};

const setNfeItemsCount = (state, { nfeItemsCount, error }) => {
    let newState = {
        ...state,
        nfeItemsCount,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingNfeItems: false,
    };
};

const setLoadingNfeItemDetails = (state, action) => {
    return {
        ...state,
        loadingNfeItemDetails: true,
    };
};

const setNfeItemsDetails = (state, { nfeItemDetails, error }) => {
    let newState = {
        ...state,
        nfeItemDetails,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingNfeItemDetails: false,
    };
};

// Orders
const setLoadingNfeOrdersTrue = (state, action) => {
    return {
        ...state,
        loadingNfeOrders: true,
    };
};

const setLoadingModalNfeOrdersTrue = (state, action) => {
    return {
        ...state,
        loadingModalNfeOrders: true,
    };
};

const setNfeOrders = (state, { nfeOrders, error }) => {
    let newState = {
        ...state,
        nfeOrders,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingNfeOrders: false,
    };
};

//#endregion Nfe Items

const setNfeOrdersCount = (state, { nfeOrdersCount, error }) => {
    let newState = {
        ...state,
        nfeOrdersCount,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
    };
};

const setAddOrderItem = (state, { isSuccessModal, isContinueModal, error }) => {
    let newState = {
        ...state,
        isSuccessModal,
        isContinueModal,
    };

    if (error)
        newState = {
            ...state,
            isSuccessModal: false,
            isContinueModal: false,
            error,
        };

    return {
        ...newState,
        loadingModalNfeOrders: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //REQUEST
        case actionTypes.NFE_DOCUMENT_CREATE_REQUEST:
        case actionTypes.NFE_DOCUMENTS_GET_LIST_REQUEST:
        case actionTypes.NFE_SUMMARY_GET_LIST_REQUEST:
        case actionTypes.NFE_MANIFEST_ACTION_REQUEST:
            return setLoadingTrue(state, action);

        //LOADING FALSE
        case actionTypes.NFE_MANIFEST_ACTION_FAILURE:
        case actionTypes.NFE_MANIFEST_ACTION_SUCCESS:
            return setLoadingFalse(state, action);

        //LOADING NFE DETAILS
        case actionTypes.NFE_DOCUMENTS_GET_DETAILS_REQUEST:
        case actionTypes.INVOICE_PUT_REQUEST:
            return setDetailsLoadingTrue(state, action);

        //SET CREATE DOCUMENT SUCCESS
        case actionTypes.NFE_DOCUMENT_CREATE_SUCCESS:
        case actionTypes.NFE_DOCUMENT_CREATE_FAILURE:
        case actionTypes.INVOICE_PUT_SUCCESS:
        case actionTypes.INVOICE_PUT_FAILURE:
            return setCreateDocumentNfe(state, action);

        //SET DOCUMENTS SUCCESS
        case actionTypes.NFE_DOCUMENTS_GET_LIST_SUCCESS:
        case actionTypes.NFE_DOCUMENTS_GET_LIST_FAILURE:
            return setDocumentsNfe(state, action);

        //SET NFE DETAILS SUCCESS
        case actionTypes.NFE_DOCUMENTS_GET_DETAILS_SUCCESS:
        case actionTypes.NFE_DOCUMENTS_GET_DETAILS_FAILURE:
            return setNfeDocumentDetails(state, action);

        //SET SUMMARY SUCCESS
        case actionTypes.NFE_SUMMARY_GET_LIST_SUCCESS:
        case actionTypes.NFE_SUMMARY_GET_LIST_FAILURE:
            return setSummaryNfe(state, action);

        //REQUEST
        case actionTypes.NFE_ITEMS_GET_LIST_REQUEST:
            return setLoadingNfeItemsTrue(state, action);

        //SET ITEMS
        case actionTypes.NFE_ITEMS_GET_LIST_SUCCESS:
        case actionTypes.NFE_ITEMS_GET_LIST_FAILURE:
            return setNfeItems(state, action);

        //SET ITEMS COUNT
        case actionTypes.NFE_ITEMS_GET_COUNT_SUCCESS:
        case actionTypes.NFE_ITEMS_GET_COUNT_FAILURE:
            return setNfeItemsCount(state, action);

        //SET NFE ITEM DETAILS
        case actionTypes.NFE_ITEMS_GET_DETAILS_SUCCESS:
            return setLoadingNfeItemDetails(state, action);

        case actionTypes.NFE_ITEMS_GET_DETAILS_SUCCESS:
        case actionTypes.NFE_ITEMS_GET_DETAILS_FAILURE:
            return setNfeItemsDetails(state, action);

        //REQUEST
        case actionTypes.NFE_ORDERS_GET_LIST_REQUEST:
            return setLoadingNfeOrdersTrue(state, action);

        case actionTypes.NFE_ORDERS_UPDATE_REQUEST:
            return setLoadingModalNfeOrdersTrue(state, action);

        case actionTypes.NFE_ORDERS_UPDATE_SUCCESS:
        case actionTypes.NFE_ORDERS_UPDATE_FAILURE:
            return setAddOrderItem(state, action);

        //SET ORDERS
        case actionTypes.NFE_ORDERS_GET_LIST_SUCCESS:
        case actionTypes.NFE_ORDERS_GET_LIST_FAILURE:
            return setNfeOrders(state, action);

        //SET ORDERS COUNT
        case actionTypes.NFE_ORDERS_GET_COUNT_SUCCESS:
        case actionTypes.NFE_ORDERS_GET_COUNT_FAILURE:
            return setNfeOrdersCount(state, action);
        default:
            return state;
    }
};

export default reducer;
