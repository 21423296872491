import React from 'react';
import PropTypes from 'prop-types';

const header = props => {
    return (
        <React.Fragment>
            <h2
                className='title-page'
                style={
                    props.headerTitleSize
                        ? { fontSize: props.headerTitleSize }
                        : {}
                }
            >
                {props.title}
            </h2>
            <p className='text-muted subtitle-page'>{props.subtitle}</p>
        </React.Fragment>
    );
};

header.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

export default header;
