import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    confirmDefaultMapText: {
        id: 'nfe.confirmDefaultMapText',
        defaultMessage:
            'Caro usuário, este documento está utilizando o mapeamento padrão do sistema. Assim que possível providencie a regularização do cadastro desse fornecedor.',
    },
    sendDocumentConfirmText: {
        id: 'nfe.sendDocumentConfirmText',
        defaultMessage:
            'Após o envio do documento as informações não poderão mais ser alteradas. Deseja realizar essa operação?',
    },
    destCnpjNotFound: {
        id: 'nfe.destCnpjNotFound',
        defaultMessage:
            'Não foi possível realizar a ação. CNPJ do Destinatário não encontrado',
    },

    btnInformCancel: {
        id: 'nfe.btnInformCancel',
        defaultMessage: 'Informar Cancelamento (Emissor)',
    },
    confirmSend: {
        id: 'nfe.confirmSend',
        defaultMessage: 'Confirmar Envio',
    },
    acceptManifest: {
        id: 'nfe.acceptManifest',
        defaultMessage: 'Manifestar confirmação da operação',
    },
    unawareManifest: {
        id: 'nfe.unawareManifest',
        defaultMessage: 'Manifestar desconhecimento',
    },
    operationNotRealizedManifest: {
        id: 'nfe.operationNotRealizedManifest',
        defaultMessage: 'Manifestar operação não realizada',
    },
    events: {
        id: 'documents.events',
        defaultMessage: 'Eventos',
    },
    eventDescription: {
        id: 'documents.eventDescription',
        defaultMessage: 'Evento',
    },
    date: {
        id: 'documents.date',
        defaultMessage: 'Data',
    },
    justification: {
        id: 'nfe.justification',
        defaultMessage: 'Justificativa',
    },
    status: {
        id: 'documents.status',
        defaultMessage: 'Status integração',
    },
    justifyOperationNotRealized: {
        id: 'nfe.justifyOperationNotRealized',
        defaultMessage: 'Justificar operação não realizada',
    },
    closeModal: {
        id: 'nfe.closeModal',
        defaultMessage: 'Fechar',
    },
});
