import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { injectIntl } from 'react-intl';
import messages from './PendingInvoicesCounterWidget.intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../context/AuthContext';
import ErrorWidget from '../../../components/ErrorBoundary/ErrorWidget';

function PendingInvoicesCounterWidget(props) {
    const context = useContext(AuthContext);

    const { intl } = props;
    const clickable = context.hasClaim('document-view');

    function handleClick() {
        if (clickable) {
            props.history.push('/documents');
        }
    }

    return (
        <ErrorWidget>
            <div onClick={handleClick} className={clickable ? 'clickable' : ''}>
                <FontAwesomeIcon icon='folder' className='mr-5 c03' size='2x' />
                <ErrorWidget>
                    <h1>{props.data ? props.data : 0}</h1>
                    <p>{intl.formatMessage(messages.pendingInvoicesCounter)}</p>
                </ErrorWidget>
            </div>
        </ErrorWidget>
    );
}

export default injectIntl(withRouter(PendingInvoicesCounterWidget));
