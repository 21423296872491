import React from "react";

const SupplierIcon = ({
  width="26",
  height="30",
  viewBox="0 0 36 40",
  fill="none",
  style=""
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {style}
    xmlns="http://www.w3.org/2000/svg">
  >
    <path d="M23.8182 2H2V20.9091H23.8182V2Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.8182 9.27272H29.6364L34 13.6364V20.9091H23.8182V9.27272Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.54545 28.1818C10.5538 28.1818 12.1818 26.5538 12.1818 24.5455C12.1818 22.5371 10.5538 20.9091 8.54545 20.9091C6.53714 20.9091 4.90909 22.5371 4.90909 24.5455C4.90909 26.5538 6.53714 28.1818 8.54545 28.1818Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M27.4545 28.1818C29.4628 28.1818 31.0909 26.5538 31.0909 24.5455C31.0909 22.5371 29.4628 20.9091 27.4545 20.9091C25.4462 20.9091 23.8182 22.5371 23.8182 24.5455C23.8182 26.5538 25.4462 28.1818 27.4545 28.1818Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SupplierIcon;


