import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    title: {
        id: 'invoices.title',
        defaultMessage: 'Documentos',
    },
    titleInvoice: {
        id: 'invoices.titleInvoice',
        defaultMessage: 'Nota Fiscal',
    },
    sender: {
        id: 'invoices.sender',
        defaultMessage: 'Remetente',
    },
    issuer: {
        id: 'invoices.issuer',
        defaultMessage: 'Emitente',
    },
    recipient: {
        id: 'invoices.recipient',
        defaultMessage: 'Destinatário',
    },
    accessKey: {
        id: 'invoices.accessKey',
        defaultMessage: 'Chave de acesso',
    },
    invoiceNumber: {
        id: 'invoices.invoiceNumber',
        defaultMessage: 'Número',
    },
    invoiceNumberFilter: {
        id: 'invoices.invoiceNumberFilter',
        defaultMessage: 'Número do Documento',
    },
    cteNumber: {
        id: 'invoices.cteNumber',
        defaultMessage: 'Número do CTE',
    },
    subtitle: {
        id: 'invoices.subtitle',
        defaultMessage: ' {length} documentos encontrados',
    },
    statusWaiting: {
        id: 'invoices.statusWaiting',
        defaultMessage: 'Esperando resultado',
    },
    statusInProgress: {
        id: 'invoices.statusInProgress',
        defaultMessage: 'Em processamento',
    },
    statusCompleted: {
        id: 'invoices.statusCompleted',
        defaultMessage: 'Completo',
    },
    statusFailed: {
        id: 'invoices.statusFailed',
        defaultMessage: 'Falhou',
    },
    statusReview: {
        id: 'invoices.statusReview',
        defaultMessage: 'Em revisão',
    },
    statusText: {
        id: 'invoices.statusText',
        defaultMessage: 'Descrição do status',
    },

    tabNfse: {
        id: 'invoices.tabNfse',
        defaultMessage: 'NFS-e',
    },
    tabNfe: {
        id: 'invoices.tabNfe',
        defaultMessage: 'NF-e',
    },
    tabCte: {
        id: 'invoices.tabCte',
        defaultMessage: 'CT-e',
    },
    tabOther: {
        id: 'invoices.tabOther',
        defaultMessage: 'Outros Pagamentos',
    },

    newButtonText: {
        id: 'global.newButtonText',
        defaultMessage: 'Nova {entity}',
    },
    selectSupplier: {
        id: 'suppliers.selectSupplier',
        defaultMessage: 'Selecione o fornecedor',
    },
    selectDate: {
        id: 'documents.selectDate',
        defaultMessage: 'Selecione um range de datas',
    },
    labelMaps: {
        id: 'invoices.labelMaps',
        defaultMessage: 'Tipos',
    },
    titleContractor: {
        id: 'contractors.titleContractor',
        defaultMessage: 'Contratante {entity}',
    },
    titleOnlyContractor: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
    titleSource: {
        id: 'documents.titleSource',
        defaultMessage: 'Origem',
    },
    titleOnlySupplier: {
        id: 'suppliers.titleOnlySupplier',
        defaultMessage: 'Fornecedor',
    },
    integratedAtDocument: {
        id: 'documentos.integratedAtDocument',
        defaultMessage: 'Integrado em',
    },
    emissionDateDocument: {
        id: 'documentos.emissionDateDocument',
        defaultMessage: 'Emitido em',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    canUploadError: {
        id: 'invoices.canUploadError',
        defaultMessage:
            'Documento não vinculado ao fornecedor, entre em contato com o suporte.',
    },
    canJustificationError: {
        id: 'documents.canJustificationError',
        defaultMessage: 'Excedido o limite máximo de 600 caracteres.',
    },
    uploadWOOrderNotAllowed: {
        id: 'invoices.uploadWOOrderNotAllowed',
        defaultMessage:
            'Não é permitido enviar este tipo de documento sem pedido. Por favor faça o envio através da aba "Pedidos".',
    },
    selectType: {
        id: 'invoices.selectType',
        defaultMessage: 'Selecione o tipo',
    },
    success: {
        id: 'documents.success',
        defaultMessage: 'Sucesso',
    },
    error: {
        id: 'documents.error',
        defaultMessage: 'Erro',
    },
    pending: {
        id: 'documents.pending',
        defaultMessage: 'Pendente',
    },
    invalidinterval: {
        id: 'document.invalidinterval',
        defaultMessage: 'Intervalo de datas deve ser inferior a 31 dias',
    },
    categoryType: {
        id: 'maps.categoryType',
        defaultMessage: 'Categoria',
    },
    subCategory: {
        id: 'maps.subCategory',
        defaultMessage: 'SubCategoria',
    },
    city: {
        id: 'invoices.city',
        defaultMessage: 'Cidade',
    },
    titleTaker: {
        id: 'contractors.titleTaker',
        defaultMessage: 'Tomador {entity}',
    },
    titleProvider: {
        id: 'suppliers.titleProvider',
        defaultMessage: 'Prestador {entity}',
    },
});

export default messages;
