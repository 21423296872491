import React from 'react';

const CloudIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M22 15C22 13 20.209 11 18 11C18 7.686 15.314 5 12 5C9.363 5 7.129 6.703 6.324 9.068C3.885 9.401 2 11.47 2 14C2 16.761 4.239 19 7 19H12.5'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M22.76 20.92H17'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M20.84 19L22.76 20.92L20.84 22.84'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default CloudIcon;
