import React from 'react';
import PropTypes from 'prop-types';

export function getSteps(steps, currentStep) {
    let step = {};
    var newSteps = [];

    steps &&
        steps.forEach(item => {
            if (Object.keys(step).length !== 0 && step.order !== item.order) {
                newSteps.push(step);
            }
            step = item;
        });

    currentStep &&
        Object.keys(currentStep)?.length > 0 &&
        newSteps.push(currentStep);
    return newSteps;
}

const InvoicesStatusCounter = props => {
    const { steps, currentStep, useAltText = false } = props;
    const invoiceSteps = getSteps(steps, currentStep);
    return (
        <div
            id='invoice-status'
            className={
                'row invoice-status invoice-status-' + invoiceSteps?.length
            }
        >
            {invoiceSteps?.map((item, index) => {
                let ballClass =
                    'invoice-status-step-ball ' + item.status.toLowerCase();

                const createdAt =
                    new Date(item.createdAt).toLocaleDateString(
                        navigator.language,
                    ) +
                    ' ' +
                    new Date(item.createdAt).toLocaleTimeString(
                        navigator.language,
                    );

                let itemText = item.text;

                if (useAltText && item.altText) {
                    itemText = item.altText;
                }

                return (
                    <div
                        key={index}
                        className='element-parent invoice-status-step'
                    >
                        <div className={ballClass} />
                        <span className='invoice-status-step-strike' />
                        <span className='custom-tooltip'>
                            <span>{itemText}</span>
                            <span className='data-tooltip'>
                                {'\n Atualizado em: \n' + createdAt}
                            </span>
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

InvoicesStatusCounter.propTypes = {
    steps: PropTypes.array,
    currentStep: PropTypes.any,
    useAltText: PropTypes.bool,
};

export default InvoicesStatusCounter;
