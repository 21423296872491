import React from "react";
import { injectIntl } from "react-intl";

import { FormGroup, Col } from "reactstrap";
import messages from "../../MapItemDetailsPage.intl";
import { AvField } from "availity-reactstrap-validation";
// import { mapActionConditions } from "../../../../utils/enums";

const conditionActionItemForm = props => {
  const { intl } = props;

  return (
    <React.Fragment>
      <Col xs={12} md={12}>
        <FormGroup>
          <AvField
            type="select"
            name="condition.type"
            id="condition.type"
            label={intl.formatMessage(messages.condition)}
            onChange={props.onConditionChange}
            value={props.condition && props.condition.type}
          >
            <option value="0" />
            <option value="1">Regex</option>
            <option value="2">MinLength</option>
            <option value="3">MaxLength</option>
          </AvField>
        </FormGroup>
      </Col>
      {(props.conditionRequired ||
        (props.condition && Number(props.condition.type) > 0)) && (
        <Col xs={12} md={12}>
          <FormGroup>
            <AvField
              type="text"
              name={
                props.conditionValue
                  ? "condition.parameters." + props.conditionValue
                  : props.condition &&
                    (Number(props.condition.type) === 1
                      ? "condition.parameters.Expression"
                      : Number(props.condition.type) === 2
                      ? "condition.parameters.MinLength"
                      : Number(props.condition.type) === 3
                      ? "condition.parameters.MaxLength"
                      : "")
              }
              label={
                props.conditionValue
                  ? props.conditionValue
                  : props.condition &&
                    (Number(props.condition.type) === 1
                      ? "Expression"
                      : Number(props.condition.type) === 2
                      ? "MinLength"
                      : Number(props.condition.type) === 3
                      ? "MaxLength"
                      : "")
              }
              value={
                props.condition &&
                (Number(props.condition.type) === 1
                  ? props.condition.parameters.Expression
                  : Number(props.condition.type) === 2
                  ? props.condition.parameters.MinLength
                  : Number(props.condition.type) === 3
                  ? props.condition.parameters.MaxLength
                  : "")
              }
              errorMessage=" "
              required={props.conditionRequired}
            />
          </FormGroup>
        </Col>
      )}
    </React.Fragment>
  );
};

export default injectIntl(conditionActionItemForm);
