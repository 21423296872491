import * as actionTypes from './actionTypes';
import axios from 'axios';

export const initWidgets = () => {
	return async dispatch => {
		let transactionsCountWidget = 0;
		let ordersCountWidget = 0;
		dispatch({
			type: actionTypes.WIDGETS_GET_REQUEST
		});

		try {
			const { data: transactionCountNumber } = await axios
				.get('/Transactions/Count?filter[isPaid]=eq:false&sort=dueDate&skip=0&take=10')

			transactionsCountWidget = transactionCountNumber

			const { data: orderCountNumber } = await axios
				.get(`/Orders/Count?filter[isPaid]=eq:false&sort=dueDate&skip=0&take=10`)

			ordersCountWidget = orderCountNumber
			const { data: widgetValue } = await axios
				.get('/Widgets')

			return dispatch({
				type: actionTypes.WIDGETS_GET_SUCCESS,
				ordersCountWidget,
				transactionsCountWidget,
				widgets: widgetValue
			});
		} catch (error) {
			return dispatch({
				type: actionTypes.WIDGETS_GET_FAILURE,
				error
			});
		}
	};
};

export const initWidget = (widgetId, url) => {
	return dispatch => {
		dispatch({
			type: actionTypes.WIDGET_GET_REQUEST
		});

		return axios
			.get(url)
			.then(response => {
				dispatch({
					type: actionTypes.WIDGET_GET_SUCCESS,
					payload: { widgetId, data: response.data }
				});
			})
			.catch(error => {
				dispatch({
					type: actionTypes.WIDGET_GET_FAILURE,
					error
				});
			});
	};
};
