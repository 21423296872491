import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'transactions.title',
        defaultMessage: 'Pagamentos',
    },

    titleTransaction: {
        id: 'transactions.titleTransaction',
        defaultMessage: 'Pagamento {entity}',
    },

    subtitle: {
        id: 'transactions.subtitle',
        defaultMessage: ' pagamento(s) encontrado(s)',
    },

    descriptionTransaction: {
        id: 'fields.description',
        defaultMessage: 'Descrição',
    },

    valueTransaction: {
        id: 'transactions.valueTransaction',
        defaultMessage: 'Valor',
    },

    currency: {
        id: 'global.currency',
        defaultMessage: 'Moeda',
    },

    referenceCode: {
        id: 'transactions.referenceCode',
        defaultMessage: 'Referência (Nº Nota)',
    },

    contractorName: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },

    supplierName: {
        id: 'suppliers.titleOnlySupplier',
        defaultMessage: 'Fornecedor',
    },

    paymentVoucherFileId: {
        id: 'transactions.paymentVoucherFileId',
        defaultMessage: 'Comprovante',
    },

    dueDate: {
        id: 'transactions.dueDate',
        defaultMessage: 'Vencimento',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    status: {
        id: 'transactions.status',
        defaultMessage: 'Status',
    },

    totalTransaction: {
        id: 'global.total',
        defaultMessage: 'Total: ',
    },

    interestByMonth: {
        id: 'transactions.interestByMonth',
        defaultMessage: 'Juros: {value}% ao mês',
    },

    anticipateAmount: {
        id: 'transactions.anticipateAmount',
        defaultMessage: 'Valor para adiantar: ',
    },
    paid: {
        id: 'global.paid',
        defaultMessage: 'Pago',
    },
    pending: {
        id: 'global.pending',
        defaultMessage: 'Pendente',
    },
    overdue: {
        id: 'global.overdue',
        defaultMessage: 'Vencido',
    },
    unavailableTransaction: {
        id: 'transactions.unavailableTransaction',
        defaultMessage: 'Pagamento indisponível',
    },
    paymentVoucher: {
        id: 'transactions.paymentVoucher',
        defaultMessage: 'Comprovante',
    },
    erpDocumentCode: {
        id: 'transactions.erpDocumentCode',
        defaultMessage: 'Código Documento ERP',
    },
    erpDocumentItemCode: {
        id: 'transactions.erpDocumentItemCode',
        defaultMessage: 'Código Pagamento ERP',
    },
});

export default messages;
