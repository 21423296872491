import React, { useState, useEffect, useMemo } from 'react';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

import messages from '../IntegrationListPage.intl';
import { filterPropertyTypes } from '../../../utils/enums';

import Header from '../../../components/Header/Header';
import EvtTable from '../../../components/EvtTable/EvtTable';
import EvtFilter from '../../../components/EvtFilter/EvtFilter';

import { columnsNFe } from '../components/ColumnsIntegrations';

function NFeListIntegrations(props) {
    const {
        onInitNFeSearch,
        onInitNFeSearchCount,
        integrationsNfe,
        integrationsNfeCount,
        loadingNfe,
        handleGetTrProps,
        activeTab,
        intl,
    } = props;

    const [query, setQuery] = useState('');
    const [filtersNfe, setFiltersNfe] = useState([]);

    useEffect(() => {
        reloadIntegrationsList();
    }, [query]);

    function reloadIntegrationsList() {
        const { filterValue } = filtersNfe;
        const urlParams = new URLSearchParams(query);
        const take = urlParams.get('take') || 10;
        const skip = urlParams.get('skip') || 0;
        const sort = urlParams.get('sort') || '-createdAt';
        const querySearch = `?skip=${skip}&take=${take}&sort=${sort}&index=${activeTab}`;

        const queryURL = filterValue
            ? querySearch
            : query + `&index=${activeTab}`;
        const url = filterValue
            ? queryURL + `&search=${filterValue}`
            : queryURL;

        window.history.replaceState(url, undefined, 'integrations' + url);
        if (query.includes('take')) {
            onInitNFeSearch(queryURL, filterValue);
            onInitNFeSearchCount(queryURL, filterValue);
        }
    }

    function handleReloadButton() {
        reloadIntegrationsList();
    }

    function handleEvTableStateChangeNfse(queryTable) {
        setQuery(queryTable);
    }

    function handleFiltersNfeChange(newFiltersNfe, value) {
        setFiltersNfe({ filters: newFiltersNfe, filterValue: value });
    }

    const nfeColumns = useMemo(() => {
        return columnsNFe(integrationsNfe, intl);
    }, [integrationsNfe]);

    const filterFields = [
        {
            key: 'cnpj',
            value: intl.formatMessage(messages.contractorCNPJ),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'searchDate',
            value: intl.formatMessage(messages.searchStartTimeDate),
            type: filterPropertyTypes.DATE,
        },
        {
            key: 'lastNSU',
            value: intl.formatMessage(messages.lastNumber),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'lastNSUReturned',
            value: intl.formatMessage(messages.lastNumberReturned),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'totalNFeReturned',
            value: intl.formatMessage(messages.totalNFeReturn),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'totalSummaryReturned',
            value: intl.formatMessage(messages.totalSummaryReturned),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'ibgeUfCode',
            value: intl.formatMessage(messages.ibgeCode),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'isSuccess',
            value: intl.formatMessage(messages.status),
            type: filterPropertyTypes.ENUM,
            options: [
                {
                    key: 'true',
                    _map: text =>
                        text.replace(
                            intl
                                .formatMessage(messages.statusSuccess)
                                .toLowerCase(),
                            true,
                        ),
                    value: intl.formatMessage(messages.statusSuccess),
                },
                {
                    key: 'false',
                    _map: text =>
                        text.replace(
                            intl
                                .formatMessage(messages.statusFailed)
                                .toLowerCase(),
                            false,
                        ),
                    value: intl.formatMessage(messages.statusFailed, {
                        entity: '',
                    }),
                },
            ],
        },
        {
            key: 'message',
            value: intl.formatMessage(messages.errorMessage),
            type: filterPropertyTypes.TEXT,
        },
    ];

    return (
        <div>
            <header className='table-screen'>
                <section className='title'>
                    <Header title={intl.formatMessage(messages.title)} />
                </section>
                <section className='btns-topo'>
                    <EvtFilter
                        properties={filterFields}
                        handleFiltersChange={handleFiltersNfeChange}
                        loading={loadingNfe}
                    />
                    <button
                        className={`new-btn small ${
                            loadingNfe ? 'loading' : ''
                        }`}
                        onClick={handleReloadButton}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                </section>
            </header>
            <section>
                <EvtTable
                    filters={filtersNfe.filters}
                    columns={nfeColumns}
                    data={integrationsNfe}
                    length={integrationsNfeCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeNfse}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingNfe}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length:integrationsNfeCount,
                    })}
                    fetchPage
                />
            </section>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        integrationsNfe: state.integrationReducer.integrationsNfe || [],
        integrationsNfeCount:
            state.integrationReducer.integrationsNfeCount || 0,
        loadingNfe: state.integrationReducer.loadingNfe,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitNFeSearch: (filter, search) =>
            dispatch(actions.initIntegrationsNfeSearch(filter, search)),
        onInitNFeSearchCount: (filter, search) =>
            dispatch(actions.initIntegrationsNfeSearchCount(filter, search)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(NFeListIntegrations)),
);
