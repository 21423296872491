import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'orders.title',
        defaultMessage: 'Pedidos',
    },

    titleOrder: {
        id: 'orders.titleOrder',
        defaultMessage: 'Pedido {entity}',
    },

    subtitle: {
        id: 'orders.subtitle',
        defaultMessage: ' pedido(s) encontrado(s)',
    },

    orderCode: {
        id: 'orders.orderCode',
        defaultMessage: 'Pedido',
    },

    orderItemCode: {
        id: 'orders.orderItemCode',
        defaultMessage: 'Item',
    },

    orderServiceSheetCode: {
        id: 'orders.orderServiceSheetCode',
        defaultMessage: 'Folha',
    },

    orderServiceSheetItemCode: {
        id: 'orders.orderServiceSheetItemCode',
        defaultMessage: 'Item da Folha',
    },

    orderDescription: {
        id: 'fields.description',
        defaultMessage: 'Descrição',
    },

    orderValue: {
        id: 'transactions.valueTransaction',
        defaultMessage: 'Valor',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    supplierName: {
        id: 'suppliers.titleOnlySupplier',
        defaultMessage: 'Fornecedor',
    },

    partnerSupplierName: {
        id: 'orders.partnerSupplierName',
        defaultMessage: 'Fornecedor Parceiro',
    },

    unavailableOrder: {
        id: 'orders.unavailableOrder',
        defaultMessage: 'Pedido indisponível',
    },

    updatedAt: {
        id: 'maps.updatedAt',
        defaultMessage: 'Atualizado em',
    },

    totalGrossValue: {
        id: 'orders.totalGrossValue',
        defaultMessage: 'Valor bruto total',
    },
    
    //TODO: Remover após a adição no globalMessages 
    integratedAt: {
        id: 'orders.integratedAt',
        defaultMessage: 'Integrado/Atualizado Em',
    },
});

export default messages;
