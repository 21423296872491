import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    title: {
        id: 'documents.title',
        defaultMessage: 'Documentos',
    },

    btnInformCancel: {
        id: 'documents.btnInformCancel',
        defaultMessage: 'Informar Cancelamento (Emissor)',
    },

    btnReportCancel: {
        id: 'documents.reportCancel',
        defaultMessage: 'Informar Cancelamento',
    },

    titleDocument: {
        id: 'documents.titleDocument',
        defaultMessage: 'Documento',
    },

    btnTransactions: {
        id: 'documents.transactions',
        defaultMessage: 'Pagamentos',
    },

    btnIsDraft: {
        id: 'documents.save',
        defaultMessage: 'Salvar',
    },

    ordersButton: {
        id: 'documents.ordersButton',
        defaultMessage: 'Pedidos',
    },
    confirmSend: {
        id: 'documents.confirmSend',
        defaultMessage: 'Confirmar Envio',
    },

    shouldSelectCategory: {
        id: 'documents.shouldSelectCategory',
        defaultMessage: 'Categoria de mapeamento da NFS',
    },

    selectCategoryMap: {
        id: 'documents.selectCategoryMap',
        defaultMessage: 'Selecione a categoria de mapeamento do documento',
    },
    
    confirmDefaultMap: {
        id: 'documents.confirmDefaultMap',
        defaultMessage: 'Atenção',
    },
    sendDocumentConfirmText: {
        id: 'documents.sendDocumentConfirmText',
        defaultMessage:
            'Após o envio do documento as informações não poderão mais ser alteradas. Deseja realizar essa operação?',
    },
    confirmDefaultMapText: {
        id: 'documents.confirmDefaultMapText',
        defaultMessage:
            'Caro usuário, este documento está utilizando o mapeamento padrão do sistema. Assim que possível providencie a regularização do cadastro desse fornecedor.',
    },
});
