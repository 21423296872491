import React, { useEffect, useState } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';
import messages from './SettingsNotifications.intl';

const NotificationsPage = props => {
    const {
        intl,
        handleFormSubmit,
        notificationSettings,
        notifications,
    } = props;
    const [enableNotifications, setEnableNotifications] = useState({});
    useEffect(() => {
        const initialEnableNotifications = notifications.reduce(
            (current, notification) => {
                const isEnable = Boolean(
                    notificationSettings?.find(
                        r => r.type === notification.key,
                    ),
                );
                current[notification.type] = isEnable;

                return current;
            },
            {},
        );
        setEnableNotifications(initialEnableNotifications);
    }, [notifications]);

    function ReorganizeValues(values) {
        const notificationObject = Object.entries(values);
        const payloadContent = [];
        notificationObject.forEach(rep => {
            let [key, value] = rep;
            if (value?.isEnable) {
                delete value.isEnable;
                const availableNotifications = notifications?.find(r => {
                    return r.type === key;
                });

                value = {
                    ...value,
                    notificationName: availableNotifications.name,
                    type: availableNotifications.key,
                };
                payloadContent.push(value);
            }
        });

        const newValues = { notificationsEnabled: payloadContent };
        return newValues;
    }

    return notifications ? (
        <AvForm
            onValidSubmit={(_, values) => {
                const newValues = ReorganizeValues(values);
                handleFormSubmit(newValues);
            }}
        >
            <h5 className='title-area-form'>
                {intl.formatMessage(messages.notificationCenter)}
            </h5>
            {notifications?.map(({ type: key, ...notification }) => {
                const notificationToEnable = notificationSettings?.find(r => {
                    return r.type === notification.key;
                });

                return (
                    <Row style={{ minHeight: 80 }} key={notification.type}>
                        <Col md={4}>
                            <FormGroup check className='required-field'>
                                <AvField
                                    label={notification?.name || ''}
                                    type='checkbox'
                                    name={`${key}.isEnable`}
                                    id={`${key}.isEnable`}
                                    value={!!notificationToEnable || false}
                                    onChange={() => {
                                        setEnableNotifications(prev => {
                                            const notifications = Object.assign(
                                                {},
                                                prev,
                                            );
                                            notifications[key] = !notifications[
                                                key
                                            ];
                                            return notifications;
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <AvField
                                    label={intl.formatMessage(messages.emails)}
                                    type='text'
                                    name={`${key}.emails`}
                                    id={`${key}.emails`}
                                    value={
                                        enableNotifications[key]
                                            ? notificationToEnable?.emails
                                            : ''
                                    }
                                    disabled={!enableNotifications[key]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                );
            })}
            <Row>
                <Col xs={12}>
                    <button
                        type='submit'
                        className='btn-submit'
                        disabled={props.loading}
                    >
                        {intl.formatMessage(messages.submitButton)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    ) : (
        <div />
    );
};

export default injectIntl(NotificationsPage);
