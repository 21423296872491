import { defineMessages } from "react-intl";

import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,

  title: {
    id: "orders.title",
    defaultMessage: "Pedidos"
  },

  titleOrder: {
    id: "orders.titleOrder",
    defaultMessage: "Pedido {entity}"
  },

  orderInfo: {
    id: "orders.orderInfo",
    defaultMessage: "Dados do Pedido"
  },

  deleteOrder: {
    id: "orders.deleteOrder",
    defaultMessage: "Excluir Pedido"
  },

  invoices: {
    id: "suppliers.invoiceTabTitle",
    defaultMessage: "Documentos"
  },

  orderItems: {
    id: "suppliers.orderItemsTabTitle",
    defaultMessage: "Itens do pedido"
  },

  name: {
    id: "global.name",
    defaultMessage: "Nome"
  },

  cnpj: {
    id: "global.cnpj",
    defaultMessage: "CPF/CNPJ"
  },

  email: {
    id: "global.email",
    defaultMessage: "E-mail"
  },

  city: {
    id: "global.city",
    defaultMessage: "Cidade"
  },

  state: {
    id: "global.state",
    defaultMessage: "Estado"
  },

  home: {
    id: "home.title",
    defaultMessage: "Início"
  }
});

export default messages;
