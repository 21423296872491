import React from 'react';
import { AuthProvider } from '../context/AuthContext';
import { LinkingOrdersProvider } from '../context/LinkingOrdersContext';
import { CombinedContextProvider } from '../context/CombinedContext';

const GlobalContextProvider = ({ children, session }) => {
    return (
        <>
            <AuthProvider session={session}>
                <LinkingOrdersProvider>
                    <CombinedContextProvider>
                        {children}
                    </CombinedContextProvider>
                </LinkingOrdersProvider>
            </AuthProvider>
        </>
    );
};

export default GlobalContextProvider;
