import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    name: {
        id: 'roles.name',
        defaultMessage: 'Nome',
    },
    subtitle: {
        id: 'roles.subtitle',
        defaultMessage: ' {length} perfil(is) encontrado(s)',
    },

    company: {
        id: 'roles.company',
        defaultMessage: 'Contratante',
    },
    
    companyName: {
        id: 'roles.company.name',
        defaultMessage: 'Nome do contratante',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    title: {
        id: 'roles.title',
        defaultMessage: 'Perfis',
    },

    titleOnlyRole: {
        id: 'roles.titleOnlyRole',
        defaultMessage: 'Perfil',
    },
});

export default messages;
