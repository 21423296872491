import React, { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as actions from '../../store/actions/index';
import messages from './AssociatedDocumentsModal.intl';
import { AuthContext } from '../../context/AuthContext';

import ModalMapPickerDetails from '../../containers/DocumentDetailsPage/components/ModalMapPickerDetails';
import EvtTable from '../EvtTable/EvtTable';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
    columnsAssociatedDocuments,
    formatToCurrency,
} from './components/Columns';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';
import DropdownListButton from '../DropdownListButton/DropdownListButton';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentTypes } from '../../utils/enums';

const AssociatedDocumentsModal = props => {
    const {
        intl,
        showAssociatedDocModal,
        setShowAssociatedDocModal,
        handleToggleAssociatedDocModal,
        loadingModal,
        selectedDocument,
        //mapState
        parentValues,
        maps,
        associatedDocuments,
        associatedDocumentsCount,
        loadingAssociatedDocuments,
        pushUrl,
        docType,
        queryId,
        noMap,
    } = props;

    const [showMapPicker, setShowMapPicker] = useState(false);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [docTypeState, setDocTypeState] = useState(false);
    const [queryState, setQueryState] = useState(false);

    const history = useHistory();
    const context = useContext(AuthContext);
    const documentId = selectedDocument?.documentId;

    useEffect(() => {
        let newDocType = 'Documents';

        switch (docType?.toUpperCase()) {
            case DocumentTypes.CTE:
                newDocType = 'CTe';
                break;
            case DocumentTypes.NFE:
                newDocType = 'NFe';
                break;
            default:
                newDocType = 'Documents';
                break;
        }

        setDocTypeState(newDocType);
    }, [docType]);

    useEffect(() => {
        if (selectedDocument && documentId !== `new`) {
          props.onInitAssociatedDocumentsCount(documentId, docTypeState);
          props.onGetParentChildValues(documentId, docTypeState);
        }
      }, [selectedDocument, documentId]);

    const getUrlCreateByDocType = docType => {
        let createUrl = `/documents/new`;
        switch (docType) {
            case DocumentTypes.CTE:
            case 'CTE_EVENTO':
                createUrl = '/cteDocuments/new';
                break;
            case DocumentTypes.NFE:
                createUrl = '/nfeDocuments/new';
                break;
            default:
                createUrl = `/documents/new`;
                break;
        }

        return createUrl;
    };

    const handleSetDropdownOpen = isOpen => setDropdownIsOpen(isOpen);

    const handleMapPickerSubmit = (e, values) => {
        setShowMapPicker(false);
        handleToggleAssociatedDocModal();

        var mapFull = maps && maps.find(m => m.mapId === values.mapId);
        var origin = 'WEB_UI';
        var hideCrop = mapFull && mapFull.hideCrop;
        history.push({
            pathname: getUrlCreateByDocType(mapFull.documentType),
            state: {
                supplierId: values?.supplierId,
                mapId: values.mapId,
                origin: origin,
                hideCrop,
                parentDocumentId: documentId,
                pushUrl,
            },
        });

        props.onCleanSupplierMaps();
    };

    const handleSupplierMapPickerToggle = () => {
        if (selectedDocument?.isGroupingDocument) {
            const childMapId = selectedDocument?.map?.childMapId;
            var mapFull = maps && maps.find(map => map.mapId === childMapId);

            var hideCrop = mapFull && mapFull.hideCrop;

            setShowAssociatedDocModal(false);
            setShowMapPicker(false);

            history.push({
                pathname: `/documents/new`,
                state: {
                    supplierId: selectedDocument?.supplierId,
                    mapId: childMapId,
                    origin: 'WEB_UI',
                    hideCrop,
                    tabIndex: null,
                    parentDocumentId: documentId,
                    pushUrl,
                },
            });
            return;
        }

        setShowMapPicker(!showMapPicker);
    };

    const handleAddBoletoClick = () => {
        setShowAssociatedDocModal(false);
        setShowMapPicker(false);

        history.push({
            pathname: `/documents/new`,
            state: {
                supplierId: selectedDocument?.supplierId,
                origin: 'WEB_UI',
                hideCrop: true,
                tabIndex: null,
                parentDocumentId: documentId,
                isBoletoAttachment: true,
                pushUrl,
            },
        });
    };

    const handleRawFileClick = () => {
        setShowAssociatedDocModal(false);
        setShowMapPicker(false);

        history.push({
            pathname: `/documents/new`,
            state: {
                supplierId: selectedDocument?.supplierId,
                origin: 'WEB_UI',
                hideCrop: true,
                tabIndex: null,
                parentDocumentId: documentId,
                isRawFile: true,
                pushUrl,
            },
        });
    };

    const handleEvTableAssociatedStateChange = query => {
        if (query !== queryState){
            setQueryState(query)
            props.onInitAssociatedDocuments(documentId, query, docTypeState);}
    };

    const handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: { cursor: 'pointer' },
            onClick: e => {
                if (
                    rowInfo.original.documentType === 'RAW_FILE' ||
                    rowInfo.original.documentType === 9
                )
                    return;
                window.open(`/documents/${rowInfo.original.documentId}`);
            },
        };
    };

    const currentStep = selectedDocument?.currentStep || {};

    const allowAttachment =
        selectedDocument?.isGroupingDocument ||
        (((context.hasClaim('document-allow-attachment-nfse') &&
            selectedDocument?.documentType === 'NFS') ||
            (context.hasClaim('cte-document-allow-attachment') &&
                selectedDocument?.documentType === 'CTE') ||
            (context.hasClaim('nfe-document-allow-attachment') &&
                selectedDocument?.documentType === 'NFE') ||
            (context.hasClaim('document-allow-attachment-other') &&
                selectedDocument?.documentType === 'OTHER')) &&
            currentStep?.status !== 'error');

    const allowAttachmentBoleto =
        ((selectedDocument?.documentType === 'NFS' &&
            context.hasClaim('document-allow-add-default-boleto')) ||
            (selectedDocument?.documentType === 'CTE' &&
                context.hasClaim('cte-document-allow-add-default-boleto')) ||
            (selectedDocument?.documentType === 'NFE' &&
                context.hasClaim('nfe-document-allow-add-default-boleto')) ||
            (selectedDocument?.documentType === 'OTHER' &&
                context.hasClaim('other-document-allow-add-default-boleto'))) &&
        currentStep?.status !== 'error';

    const allowAttachmentRawFile =
        ((context.hasClaim('document-allow-add-raw-file') &&
            selectedDocument?.documentType === 'NFS') ||
            (context.hasClaim('cte-document-allow-add-raw-file') &&
                selectedDocument?.documentType === 'CTE') ||
            (context.hasClaim('nfe-document-allow-add-raw-file') &&
                selectedDocument?.documentType === 'NFE') ||
            (context.hasClaim('document-allow-add-raw-file') &&
                selectedDocument?.documentType === 'OTHER')) &&
        !selectedDocument?.isGroupingDocument &&
        currentStep?.status !== 'error';

    return (
        <Modal
            isOpen={showAssociatedDocModal}
            handleToggle={handleToggleAssociatedDocModal}
            size={'xxl'}
            modalBodyClassName={'list-modal'}
        >
            <Loading loading={loadingModal} />
            <header className='table-screen'>
                <section className='title' style={{ width: '100%' }}>
                    <Header
                        title={intl.formatMessage(messages.associatedDocuments)}
                    />
                </section>
                <section
                    className='btns-topo'
                    style={{
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                >
                    <DropdownListButton
                        className='dropdown-new-btn'
                        handleSetOpen={handleSetDropdownOpen}
                        items={[
                            //Salvar
                            {
                                name: intl.formatMessage(
                                    messages.newAttachmentButton,
                                ),
                                onClick: () => handleSupplierMapPickerToggle(),
                                disabled: !allowAttachment,
                            },
                            //Boleto
                            {
                                name: intl.formatMessage(
                                    messages.newBoletoAttachment,
                                ),
                                onClick: () => handleAddBoletoClick(),
                                disabled: !allowAttachmentBoleto,
                            },
                            //RawFile
                            {
                                name: intl.formatMessage(messages.newRawFile),
                                onClick: () => handleRawFileClick(),
                                disabled: !allowAttachmentRawFile,
                            },
                        ]}
                    >
                        <button className='new-btn'>
                            <span>
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: '',
                                })}
                            </span>
                            <FontAwesomeIcon
                                icon={
                                    dropdownIsOpen
                                        ? 'chevron-up'
                                        : 'chevron-down'
                                }
                                size='1x'
                            />
                        </button>
                    </DropdownListButton>
                </section>
            </header>
            <section>
                <EvtTable
                    columns={columnsAssociatedDocuments(
                        associatedDocuments,
                        intl,
                        docType,
                    )}
                    data={associatedDocuments}
                    length={associatedDocumentsCount}
                    pageSize={5}
                    handleGetTrProps={handleGetTrProps}
                    handleStateChange={handleEvTableAssociatedStateChange}
                    loading={loadingAssociatedDocuments}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: associatedDocumentsCount,
                    })}
                    fetchPage
                />
            </section>
            <Row>
                <Col xs={12}>
                    <label className='text'>
                        {intl.formatMessage(messages.childValues, {
                            entity1: `${formatToCurrency(
                                parentValues.documentValue,
                            )}`,
                            entity2: `${formatToCurrency(
                                parentValues.totalChildValue,
                            )}`,
                        })}
                    </label>
                </Col>
            </Row>
            <ModalMapPickerDetails
                document={selectedDocument}
                handleSupplierMapPickerToogle={handleSupplierMapPickerToggle}
                showMapPicker={showMapPicker}
                handleMapPickerSubmit={handleMapPickerSubmit}
                noMap={noMap}
            />
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        associatedDocuments: state.documentReducer.associatedDocuments || [],
        associatedDocumentsCount:
            state.documentReducer.associatedDocumentsCount || 0,
        loadingAssociatedDocuments:
            state.documentReducer.loadingAssociatedDocuments,
        parentValues: state.documentReducer.parentChildValues || 0,
        maps: state.supplierReducer.maps,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitAssociatedDocuments: (documentId, filter, docType) =>
            dispatch(
                actions.initAssociatedDocuments(documentId, filter, docType),
            ),
        onCleanSupplierMaps: () => dispatch(actions.cleanSupplierMaps()),
        onInitAssociatedDocumentsCount: (documentId, docType) =>
            dispatch(actions.initAssociatedDocumentsCount(documentId, docType)),
        onGetParentChildValues: (documentId, docType) =>
            dispatch(actions.getParentChildValues(documentId, docType)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(AssociatedDocumentsModal),
);
