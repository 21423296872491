import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Header from '../../components/Header/Header';
import messages from './NotFoundPage.intl';

class NotFoundPage extends Component {
    state = {};

    render() {
        const { intl } = this.props;

        return <Header title={intl.formatMessage(messages.pageNotFound)} />;
    }
}

export default injectIntl(NotFoundPage);
