import React, { useContext, useState } from 'react';

import { useHistory, useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AuthContext } from '../../../context/AuthContext';

import * as actions from '../../../store/actions/index';

import EvtTable from '../../../components/EvtTable/EvtTable';
import HeaderTable from '../../../components/HeaderTable/HeaderTable';
import messages from '../DocumentsConfigsListPage.intl';
import { columnsCategory, propertiesCategory } from '../components/Columns';

const CategoryList = props => {
    const {
        intl,
        //ComponentProps
        activeTab,
        //StateProps
        loadingList,
        categories,
        categoriesCount,
    } = props;

    const context = useContext(AuthContext);
    const history = useHistory();
    const params = useParams();

    const [queryState, setQueryState] = useState('');
    const [filters, setFilters] = useState([]);

    const newButtonActive = context.hasClaim('category-create');

    function reloadCategoryList(query) {
        history.push(`/${params.historyPath}/documentsconfigs` + query);

        props.onInitCategories(query);
    }

    const handleFiltersChange = filters => setFilters(filters);

    const handleRefreshButtonClick = () =>
        reloadCategoryList(queryState);

    const handleNewCategoryButtonClick = () => {
        history.push(
            `/${params.historyPath}/documentsconfigs/category/newcategory`,
            {
                tabIndex: activeTab,
            },
        );
    };

    const handleEvTableStateChange = query => {
        const newQuery = query.includes('index')
            ? query
            : query + `&index=${activeTab}`;

        setQueryState(newQuery);
        if (query) {
            reloadCategoryList(newQuery);
        }
    };

    const handleGetTrProps = (_state, rowInfo, _column, _instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: _e => {
                history.push(
                    `/${params.historyPath}/documentsconfigs/category/${
                        rowInfo.original.categoryId
                    }`,
                    {
                        tabIndex: activeTab,
                    },
                );
            },
        };
    };

    const handleDeleteCategory = category => {
        props.onDeleteCategories(category.categoryId, true);
    };

    return (
        <>
            <HeaderTable
                title={intl.formatMessage(messages.categoriesTitle)}
                properties={propertiesCategory(intl)}
                loading={loadingList}
                handleFiltersChange={handleFiltersChange}
                onClickReload={handleRefreshButtonClick}
                onClickNewButton={handleNewCategoryButtonClick}
                newButtonEnabled={newButtonActive}
                newButtonText={intl.formatMessage(messages.newCategoryText)}
            />
            <section>
                <EvtTable
                    filters={filters}
                    columns={columnsCategory(intl, handleDeleteCategory)}
                    data={categories}
                    length={categoriesCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChange}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: categoriesCount,
                    })}
                />
            </section>
        </>
    );
};

const mapStateToProps = state => {
    return {
        categories: state.categoryReducer.categoriesList || [],
        categoriesCount: state.categoryReducer.categoriesListCount || 0,
        loadingList: state.categoryReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitCategories: filter =>
            dispatch(actions.initCategoriesList(filter)),
        onDeleteCategories: (categoryId, dropdownAction) =>
            dispatch(actions.deleteCategory(categoryId, dropdownAction)),
    };
};

export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(CategoryList),
    ),
);
