import React, { useContext, useState } from 'react';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import * as actions from '../../store/actions/index';
import EvtTable from '../EvtTable/EvtTable';
import Modal, { modalTypes } from '../Modal/Modal';
import { columnsOrdersDocuments } from './components/Columns';
import messages from './DynamicModalOrdersDocuments.intl';
import ModalMultiplesOrders from './components/ModalMultiplesOrders';
import { AuthContext } from '../../context/AuthContext';

const DynamicModalOrdersDocuments = props => {
    const {
        intl,
        ordersDocument,
        ordersDocumentCount,
        loading,
        selectedDocument,
        documentId,
        isOpen,
        handleToggle,
        disableAddItemButton,
        loadOrdersDocument,
        ordersColumns,
        sortTable,
        loadingModalOrders,
        handleBtnClickNewOrder,
        pageSize,
        frontPagination,
        titleModal,
        functionDispatch,
        orderIntegrationClaim,
        docType,
        ordersToFilter,
        CNPJTakerAlt,
    } = props;

    const context = useContext(AuthContext);
    const orderIntegration =
        orderIntegrationClaim ||
        context.hasClaim('document-order-integration-update');

    const hasAllowOrderDocumentClaim =
        !disableAddItemButton &&
        (orderIntegration ||
            (selectedDocument?.currentStep?.order === 3 &&
                selectedDocument?.currentStep?.status === 'pending'));

    const [searchItems, setSearchItems] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [orderItemDelete, setOrderItemDelete] = useState({});

    const handleStateChangeOrdersItems = query => loadOrdersDocument(query);

    const handleToggleSearchItems = () => {
        const filter = '?sort=-createdAt&skip=0&take=100&page=0';

        props.onInitOrderItemsBySupplier(
            selectedDocument?.supplierId,
            filter,
            ordersToFilter,
        );

        setSearchItems(!searchItems);
        setSelectedOrders([]);
    };

    const handleSelectOrderItem = item => {
        const isSelected = selectedOrders.find(it => it === item?.orderItemId);

        if (isSelected) {
            const filteredOrders = selectedOrders.filter(
                it => it !== item?.orderItemId,
            );

            setSelectedOrders(filteredOrders);
        } else {
            setSelectedOrders([...selectedOrders, item?.orderItemId]);
        }
    };

    const handleAddItemSubmit = () => {
        if (selectedOrders.length === 0) {
            toast.error(intl.formatMessage(messages.noItemSelected));
            return;
        }

        const orderItems = selectedOrders.join(',');

        props.onUpdateOrdersDocument(
            documentId,
            orderItems,
            docType,
            functionDispatch,
        );
        handleToggleSearchItems();
    };

    const handleToggleModalDelete = (orderItem = null) => {
        setShowModalDelete(!showModalDelete);
        setOrderItemDelete(orderItem || orderItemDelete);
    };

    const handleDeleteItem = () => {
        const orderItemId = orderItemDelete?.orderItemId;
        props.onDeleteOrdersDocument(
            documentId,
            orderItemId,
            docType,
            functionDispatch,
        );

        setShowModalDelete(!showModalDelete);
        setOrderItemDelete(null);
    };

    const tableColumns =
        ordersColumns ||
        columnsOrdersDocuments(
            intl,
            ordersDocument,
            hasAllowOrderDocumentClaim,
            handleToggleModalDelete,
            false,
        );

    return (
        <>
            <Modal
                title={
                    titleModal ||
                    intl.formatMessage(messages.associatedOrderDocuments)
                }
                isOpen={isOpen}
                handleToggle={handleToggle}
                size={'xxl'}
            >
                {hasAllowOrderDocumentClaim && (
                    <Row>
                        <Col xs={{ size: 2 }}>
                            <button
                                className='btn-submit'
                                onClick={
                                    handleBtnClickNewOrder ||
                                    handleToggleSearchItems
                                }
                                disabled={loading}
                            >
                                {intl.formatMessage(messages.newOrdersButton)}
                            </button>
                        </Col>
                    </Row>
                )}
                <Row>
                    <EvtTable
                        fetchPage
                        columns={tableColumns}
                        data={ordersDocument}
                        length={ordersDocumentCount}
                        pageSize={pageSize || 5}
                        handleStateChange={handleStateChangeOrdersItems}
                        loading={loading || loadingModalOrders}
                        defaultSorted={
                            sortTable || [
                                {
                                    id: 'createdAt',
                                    desc: true,
                                },
                            ]
                        }
                        countHeaderText={intl.formatMessage(
                            messages.itemsSubtitle,
                            {
                                length: ordersDocumentCount,
                            },
                        )}
                        tabInside
                        frontPagination={frontPagination}
                    />
                </Row>
            </Modal>
            <ModalMultiplesOrders
                isOpenSearchOrders={searchItems}
                selectedOrders={selectedOrders}
                hasAllowOrderDocumentClaim={hasAllowOrderDocumentClaim}
                handleToggleSearchOrders={handleToggleSearchItems}
                handleSelectOrderItem={handleSelectOrderItem}
                handleAddItemSubmit={handleAddItemSubmit}
                CNPJTakerAlt={CNPJTakerAlt}
            />
            <Modal
                title={intl.formatMessage(messages.removeOrderItem)}
                isOpen={showModalDelete}
                handleToggleModalDelete={handleToggleModalDelete}
                type={modalTypes.ERROR}
                hasFooter={true}
                firstButtonText='Sim'
                secondButtonText='Não'
                toggleFirstButton={handleDeleteItem}
                toggleSecondButton={handleToggleModalDelete}
            >
                {intl.formatMessage(messages.confirmDeleteText)}
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {
        loadingModalOrders: state.documentReducer.loadingModalOrders,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateOrdersDocument: (
            documentId,
            orderItemId,
            docType,
            functionDispatch,
        ) =>
            dispatch(
                actions.updateOrdersDocument(
                    documentId,
                    orderItemId,
                    docType,
                    functionDispatch,
                ),
            ),
        onDeleteOrdersDocument: (
            documentId,
            orderItemId,
            functionDispatch,
            docType,
        ) =>
            dispatch(
                actions.deleteOrdersDocument(
                    documentId,
                    orderItemId,
                    functionDispatch,
                    docType,
                ),
            ),
        onInitOrderItemsBySupplier: (supplierId, filter, ordersToFilter) =>
            dispatch(
                actions.initOrderItemsBySupplier(
                    supplierId,
                    filter,
                    ordersToFilter,
                ),
            ),
    };
};

DynamicModalOrdersDocuments.propTypes = {
    selectedDocument: PropTypes.object,
    documentId: PropTypes.string,
    ordersDocument: PropTypes.array,
    ordersDocumentCount: PropTypes.number,
    pageSize: PropTypes.number,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool,
    disableAddItemButton: PropTypes.bool,
    loadingModalOrders: PropTypes.bool,
    frontPagination: PropTypes.bool,
    handleToggle: PropTypes.func,
    loadOrdersDocument: PropTypes.func,
    handleBtnClickNewOrder: PropTypes.func,
    ordersColumns: PropTypes.any,
    sortTable: PropTypes.any,
    CNPJTakerAlt: PropTypes.any,
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DynamicModalOrdersDocuments),
);
