import React, { useState, useEffect } from 'react';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import messages from './ModalMultiplesOrdersDocument.intl';

import { Row, Col } from 'reactstrap';

import EvtTable from '../EvtTable/EvtTable';
import Modal, { modalTypes } from '../Modal/Modal';
import { toast } from 'react-toastify';
import { columnsOrdersDocuments } from '../DynamicModalOrdersDocuments/components/Columns';
import ModalMultiplesOrders from '../DynamicModalOrdersDocuments/components/ModalMultiplesOrders';

function ModalMultiplesOrdersDocument(props) {
    const {
        intl,
        documentId,
        supplierId,
        allowDeleteOrderButton,
        allowAddOrderButton,
        isOpen,
        handleToggle,
        //reducer
        session,
    } = props;

    const [searchItems, setSearchItems] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [orderItemDelete, setOrderItemDelete] = useState({});
    const [memoryList, setMemoryList] = useState([]);

    const [selectedOrders, setSelectedOrders] = useState([]);

    useEffect(() => {
        setMemoryList([]);
    }, []);

    useEffect(() => {
        if (!isOpen) {
            props.handleFormSubmit && props.handleFormSubmit(memoryList);
        }
    }, [isOpen]);

    function handleStateChangeOrdersItems(query) {
        reloadOrdersDocument(query);
    }

    function reloadOrdersDocument(queryReload) {
        const filter = queryReload;
        if (documentId) {
            props.onGetOrdersDocument(documentId, filter);
        }
    }

    function handleToggleSearchItems() {
        const filter = '?sort=-createdAt&skip=0&take=100&page=0';

        props.onInitOrderItemsBySupplier(supplierId, filter);

        setSearchItems(!searchItems);
    }

    function handleAddItemSubmit() {
        if (selectedOrders.length === 0) {
            toast.error(intl.formatMessage(messages.noItemSelected));
            return;
        }

        handleToggleSearchItems();
    }

    function handleSelectOrderItem(item) {
        const isSelected = selectedOrders.find(it => it === item?.orderItemId);

        if (isSelected) {
            const filteredOrders = selectedOrders.filter(
                it => it !== item?.orderItemId,
            );
            const filteredOrdersMemory = memoryList.filter(
                it => it?.orderItemId !== item?.orderItemId,
            );
            setSelectedOrders(filteredOrders);
            setMemoryList(filteredOrdersMemory);
        } else {
            setSelectedOrders([...selectedOrders, item?.orderItemId]);
            setMemoryList([...memoryList, item]);
        }
    }

    //Delete
    function handleToggleModalDelete(orderItem = null) {
        setShowModalDelete(!showModalDelete);
        orderItem && setOrderItemDelete(orderItem);
    }

    async function handleDeleteItem() {
        const orderItemId = orderItemDelete?.orderItemId;
        const newMemoryList = memoryList.filter(
            i => i.orderItemId !== orderItemId,
        );
        const newSelectOrders = selectedOrders.filter(i => i !== orderItemId);

        setMemoryList(newMemoryList);
        setSelectedOrders(newSelectOrders);

        setShowModalDelete(!showModalDelete);
        setOrderItemDelete(null);
    }

    //return
    const hasAllowAddOrderButton = allowAddOrderButton || true;
    const hasAllowDeleteOrderButton =
        allowDeleteOrderButton || allowAddOrderButton || true;

    const columnsOrders = columnsOrdersDocuments(
        intl,
        memoryList,
        hasAllowDeleteOrderButton,
        handleToggleModalDelete,
        false,
        handleSelectOrderItem,
        selectedOrders,
    );

    return (
        <>
            <Modal
                title={intl.formatMessage(messages.associatedOrderDocuments)}
                isOpen={isOpen}
                handleToggle={handleToggle}
                size={'xxl'}
            >
                {hasAllowAddOrderButton && (
                    <Row>
                        <Col xs={{ size: 2 }}>
                            <button
                                className='btn-submit'
                                onClick={handleToggleSearchItems}
                            >
                                {intl.formatMessage(messages.newOrdersButton)}
                            </button>
                        </Col>
                    </Row>
                )}
                <Row>
                    <EvtTable
                        fetchPage
                        columns={columnsOrders}
                        data={memoryList}
                        length={memoryList?.length}
                        pageSize={5}
                        handleStateChange={handleStateChangeOrdersItems}
                        loading={false}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                        countHeaderText={intl.formatMessage(
                            messages.itemsSubtitle,
                            {
                                length: memoryList?.length,
                            },
                        )}
                        frontPagination
                        tabInside
                    />
                </Row>
            </Modal>
            {/* Itens para associação */}
            <ModalMultiplesOrders
                isOpenSearchOrders={searchItems}
                selectedOrders={selectedOrders}
                hasAllowOrderDocumentClaim={true}
                handleToggleSearchOrders={handleToggleSearchItems}
                handleSelectOrderItem={handleSelectOrderItem}
                handleAddItemSubmit={handleAddItemSubmit}
                noCheckOrdersFromTaker
            />
            <Modal
                title={intl.formatMessage(messages.removeOrderItem)}
                isOpen={showModalDelete}
                handleToggleModalDelete={handleToggleModalDelete}
                type={modalTypes.ERROR}
                hasFooter={true}
                firstButtonText='Sim'
                secondButtonText='Não'
                toggleFirstButton={handleDeleteItem}
                toggleSecondButton={handleToggleModalDelete}
            >
                {intl.formatMessage(messages.confirmDeleteText)}
            </Modal>
        </>
    );
}

const mapStateToProps = state => {
    return {
        ordersDocument: state.documentReducer.ordersDocument || [],
        ordersDocumentCount: state.documentReducer.ordersDocumentCount || 0,
        loadingModalOrders: state.documentReducer.loadingModalOrders,
        orderItems: state.orderReducer.orderItems,
        session: state.authenticationReducer.session,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetOrdersDocument: (documentId, filter) =>
            dispatch(actions.getOrdersDocument(documentId, filter)),
        onInitOrderItemsActive: (orderId, query) =>
            dispatch(actions.initOrderItemsActive(orderId, query)),
        onInitOrderItemsBySupplier: (supplierId, filter) =>
            dispatch(actions.initOrderItemsBySupplier(supplierId, filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalMultiplesOrdersDocument),
);
