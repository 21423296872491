import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import axios from 'axios';

export const initCategories = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.CATEGORIES_GET_REQUEST,
        });

        axios
            .get('/Categories')
            .then(response => {
                dispatch({
                    type: actionTypes.CATEGORIES_GET_SUCCESS,
                    categories: response.data.result,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CATEGORIES_GET_FAILURE,
                    error,
                });
                error?.response?.data &&
                    toast.error(
                        'Ocorreu um erro ao carregar a lista de categories. ' +
                            error.response.data,
                    );
            });
    };
};

export const initCategoriesList = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.CATEGORIES_GET_LIST_REQUEST,
        });

        axios
            .get('/Categories' + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.CATEGORIES_GET_LIST_SUCCESS,
                    categoriesList: response.data.result,
                    categoriesListCount: response.data.count,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CATEGORIES_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initCategoryDetails = categoryId => {
    return dispatch => {
        dispatch({
            type: actionTypes.CATEGORY_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/Categories/${categoryId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.CATEGORY_GET_DETAILS_SUCCESS,
                    category: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CATEGORY_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const createCategory = values => {
    return dispatch => {
        dispatch({
            type: actionTypes.CATEGORIES_CREATE_REQUEST,
        });

        axios
            .post(`/Categories`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.CATEGORIES_CREATE_SUCCESS,
                });
                dispatch(
                    push({
                        pathname: `/documents/documentsconfigs`,
                        state: {
                            tabIndex: 2,
                        },
                    }),
                );
                toast.success('Categoria criada com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CATEGORIES_CREATE_FAILURE,
                    error,
                });
            });
    };
};

export const updateCategory = (categoryId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.CATEGORIES_UPDATE_REQUEST,
        });

        axios
            .put(`/Categories/${categoryId}`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.CATEGORIES_UPDATE_SUCCESS,
                });
                dispatch(
                    push({
                        pathname: `/documents/documentsconfigs`,
                        state: {
                            tabIndex: 2,
                        },
                    }),
                );
                toast.success('Categoria atualizada com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CATEGORIES_UPDATE_FAILURE,
                    error,
                });
            });
    };
};

export const deleteCategory = (categoryId, dropdownAction = false) => {
    return dispatch => {
        dispatch({
            type: actionTypes.CATEGORIES_DELETE_REQUEST,
        });

        axios
            .delete(`/Categories/${categoryId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.CATEGORIES_DELETE_SUCCESS,
                });
                if (dropdownAction) {
                    initCategoriesList(
                        '?sort=-createdAt&skip=0&take=10&page=0&index=2',
                    )(dispatch);
                } else {
                    dispatch(
                        push({
                            pathname: `/documents/documentsconfigs`,
                            state: {
                                tabIndex: 2,
                            },
                        }),
                    );
                }
                toast.success('Categoria excluída com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CATEGORIES_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const cleanCategoryState = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.CATEGORY_GET_DETAILS_SUCCESS,
            category: null,
        });
    };
};
