import React from 'react';
import Proptypes from 'prop-types';

const ButtonBadge = props => {
    return (
        <div className='badge-circle'>
            {props.count > 99 ? '+99' : props.count}
        </div>
    );
};

export default ButtonBadge;
