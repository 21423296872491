import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    selectType: {
        id: 'modalMap.selectType',
        defaultMessage: 'Selecione o tipo',
    },
    labelMaps: {
        id: 'modalMap.labelMaps',
        defaultMessage: 'Tipos',
    },
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
});
