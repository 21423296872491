import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Col, Container, Row } from 'reactstrap';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AddMapModal from '../../components/AddMapModal/AddMapModal';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtSelect from '../../components/EvtSelect/EvtSelect';
import EvtTable, { buildFilterOr } from '../../components/EvtTable/EvtTable';
import Header from '../../components/Header/Header';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import { AuthContext } from '../../context/AuthContext';
import * as actions from '../../store/actions/index';
import { filterPropertyTypes } from '../../utils/enums';
import ModalAssociateMap from '../MapDetailsPage/components/ModalAssociateMap';
import { MapListColumns } from './components/MapListColumns';
import messages from './MapListPage.intl';

class MapListPage extends Component {
    static contextType = AuthContext;

    state = {
        showAddMapModal: false,
        showCopyMapModal: false,
        showImportMapModal: false,
        showEditMapModal: false,
        copiedMap: null,
        cities: [],
        selectedCity: null,
        selectedState: null,
        selectedCategory: null,
        filters: [],
        query: '',
        importMap: null,
        mapToUpdate: {},
        showDeactivateMapModal: false,
        selectedMap: {},
        showAssociateConfirmationModal: false,
        showAssociateMapModal: false,
    };

    properties = () => {
        const { intl, categories } = this.props;
        const properties = [
            {
                key: 'name',
                value: intl.formatMessage(messages.name),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'contractorName',
                value: intl.formatMessage(messages.contractor),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'city',
                value: intl.formatMessage(messages.city),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'ibgeCode',
                value: intl.formatMessage(messages.ibge),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'documentType',
                value: intl.formatMessage(messages.documentType),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'fileType',
                value: intl.formatMessage(messages.fileType),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'categoryType',
                value: intl.formatMessage(messages.categoryType),
                type: filterPropertyTypes.ENUM,
                options: this.mapCategoriesArray(categories),
            },
            {
                key: 'subCategory',
                value: intl.formatMessage(messages.subCategory),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'mapVersion',
                value: intl.formatMessage(messages.mapVersion),
                type: filterPropertyTypes.ENUM,
                options: [
                    { key: 0, value: 'V1' },
                    { key: 1, value: 'V2' },
                    { key: 2, value: 'V3' },
                ],
            },
        ];
        return properties;
    };

    componentDidMount() {
        this.props.ontInitCategories();
        this.props.onInitStates()
    }

    reloadMapsList = () => {
        const { query } = this.state;
        let filterQuery = query;

        if (query.includes('search=')) {
            const value = new URLSearchParams(query).get('search');
            filterQuery = buildFilterOr(value, this.properties());
        }
        this.props.history.push('/maps' + query);

        this.props.onInitMapsCount(filterQuery);
        this.props.onInitMaps(filterQuery);
    };

    mapCategoriesArray = categoriesArray => {
        if (!categoriesArray) return;
        const map = categoriesArray.map(category => {
            return {
                key: category.code,
                value: category.name,
            };
        });
        return map;
    };

    mapCategoriesSelect = categoriesArray => {
        if (!categoriesArray) return;
        const map = categoriesArray.map(category => {
            return {
                label: category.name,
                value: category.code,
            };
        });
        return map;
    };

    mapSubCategoriesSelect = subCategoriesArray => {
        if (!subCategoriesArray) return;
        const map = subCategoriesArray.map(subCategory => {
            return {
                label: subCategory,
                value: subCategory,
            };
        });
        return map;
    };

    handleNewMapModalToogle = () => {
        this.setState({
            showAddMapModal: !this.state.showAddMapModal,
            cities: [],
            selectedCity: null,
        });
    };

    handleStateChange = selectedState => {
        const state = this.props.states.find(
            s => s.uf === selectedState?.value,
        );
        if (state) {
            this.setState({
                selectedState: {
                    selectedLabelOption: state.name,
                    selectedValueOption: state.code,
                },
                cities: [...state.cities],
            });
        }
        this.setState({
            selectedCity: undefined,
        });
    };

    handleCityChange = selectedCity => {
        this.setState({
            selectedCity: {
                selectedLabelOption: selectedCity.label,
                selectedValueOption: selectedCity.value,
            },
        });
    };

    handleAddMapModalSubmit = (e, values) => {
        const { selectedCity } = this.state;
        const { selectedState } = this.state;

        const map = {
            ...values,
            ibgeCode:
                selectedCity?.selectedValueOption ||
                selectedState?.selectedValueOption ||
                0,
            city: selectedCity?.selectedLabelOption || '',
            mapVersion: Number(values.mapVersion),
        };

        this.props.onCreateMap(map);

        this.setState({
            showAddMapModal: !this.state.showAddMapModal,
        });
    };

    handleCopyMapModalToogle = async row => {
        this.setState(
            {
                copiedMap: row.original,
            },
            () => {
                this.setState({
                    showCopyMapModal: !this.state.showCopyMapModal,
                    cities: [],
                    selectedCity: null,
                });
            },
        );
    };

    handleCopyMapModalSubmit = (e, values) => {
        const { selectedCity } = this.state;

        let copiedMap = this.state.copiedMap;
        delete copiedMap.mapId;

        copiedMap.mapItems.map(mi => {
            mi.mapActions.map(ma => {
                return delete ma.mapActionId;
            });
            return delete mi.mapItemId;
        });

        const map = {
            name: values.name,
            contractorId: values.contractorId,
            ibgeCode: values.cityCode || 0,
            city: selectedCity?.selectedLabelOption || '',
            documentType: values.documentType,
            fileType: values.fileType,
            categoryType: values.categoryType,
            subCategory: values.subCategory,
            childMapId: values.childMapId,
            mapVersion: Number(values.mapVersion),
        };

        const mapCopy = {
            ...copiedMap,
            ...map,
        };
        delete mapCopy.fileId;

        this.props.onCopyMap(mapCopy);

        this.setState({ showCopyMapModal: !this.state.showCopyMapModal });
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        const { intl } = this.props;
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => {
                const nodeName = e.target.nodeName;
                if (nodeName === 'BUTTON' || nodeName === 'path') {
                    return;
                }

                this.props.onInitMapDetails(rowInfo.original.mapId);
            },

            className: !rowInfo.original.active ? 'disabled-row' : '',
            title: !rowInfo.original.active
                ? intl.formatMessage(messages.inactivatedMap)
                : '',
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadMapsList();
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
        this.props.history.push(`/maps` + this.state.query);
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadMapsList();
            },
        );
    };

    handleCopyMapButtonClick = (e, row) => {
        e.preventDefault();
        this.handleCopyMapModalToogle(row);
    };

    handleExportMapButtonClick = (e, row) => {
        e.preventDefault();
        const copy = { ...row.original };
        const { mapId } = copy;

        delete copy.mapId;
        delete copy.fileId;
        delete copy.active;
        delete copy.ibgeCode;
        delete copy.contractorId;
        delete copy.contractorName;

        const json = JSON.stringify(copy);

        const pom = document.createElement('a');
        pom.setAttribute(
            'href',
            'data:application/json;charset=utf-8,' + encodeURIComponent(json),
        );
        pom.setAttribute('download', `${mapId}.json`);
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
    };

    handleImportMapModalToogle = async e => {
        this.setState({
            showImportMapModal: !this.state.showImportMapModal,
            cities: [],
            selectedCity: null,
            importMap: null,
        });
    };

    handleImportMapFileChange = e => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const map = JSON.parse(reader.result);
                    this.setState({
                        importMap: map,
                    });
                } catch (ex) {
                    toast.error(ex.message);
                }
            };
            reader.readAsText(e.target.files[0]);
        }
    };

    handleImportMapModalSubmit = (e, values) => {
        const { importMap, selectedCity } = this.state;

        if (!importMap) {
            toast.error('Nenhum arquivo foi adicionado.');
            return;
        }

        const map = {
            ...importMap,
            name: values.name,
            contractorId: values.contractorId,
            ibgeCode: values.cityCode || 0,
            city: selectedCity?.selectedLabelOption || '',
            documentType: values.documentType,
            fileType: values.fileType,
            categoryType: values.categoryType,
            subCategory: values.subCategory,
            childMapId: values.childMapId,
            mapVersion: Number(values.mapVersion),
        };

        this.props.onCreateMap(map);
    };

    handleEditMapButtonClick = (e, row) => {
        const mapToUpdate = {
            mapId: row?.original?.mapId,
            name: row?.original?.name,
            categoryType: row?.original?.categoryType,
            subCategory: row?.original?.subCategory,
        };

        const categoryId = this.props.categories.find(
            categoryByCode => categoryByCode.name === mapToUpdate.categoryType,
        )?.categoryId;

        if (!!categoryId) {
            this.props.onInitCategoryDetails(categoryId);
        }

        this.setState({
            showEditMapModal: !this.state.showEditMapModal,
            mapToUpdate,
        });
    };

    handleEditMap = (e, values) => {
        this.props.onUpdateMapFromList(values, this.state.query);

        this.setState({
            showEditMapModal: !this.state.showEditMapModal,
        });
    };

    handleDeactivateMapToogleModal = (e, row) => {
        if (row.original) {
            this.setState({
                showDeactivateMapModal: !this.state.showDeactivateMapModal,
                selectedMap: row.original,
            });
        } else {
            this.setState({
                showDeactivateMapModal: !this.state.showDeactivateMapModal,
            });
        }
    };

    handleBtnDeactivateMapClick = () => {
        this.setState({
            showDeactivateMapModal: !this.state.showDeactivateMapModal,
        });

        this.props.onDeleteMap(this.state.selectedMap.mapId);
    };

    handleCloseDeactivateMapModal = () => {
        this.setState({
            showDeactivateMapModal: false,
        });
    };

    handleToggleModalAssociateMap = (hasFalse = false) => {
        this.setState({
            showAssociateMapModal: hasFalse
                ? false
                : !this.state.showAssociateMapModal,
        });
    };

    handleToggleConfirmationModalAssociate = () => {
        this.setState({
            showAssociateConfirmationModal: !this.state
                .showAssociateConfirmationModal,
        });
    };

    handleAssociateClick = (e, row) => {
        if (row.original) {
            this.setState({
                selectedMap: row.original,
            });
        }

        if (!row.original?.city) {
            toast.error(
                'Não é possível associar mapeamento sem cidade aos fornecedores',
            );
            return;
        }

        if (row.original?.contractorId) {
            this.handleToggleConfirmationModalAssociate();
        } else {
            this.handleToggleModalAssociateMap();
        }
    };

    handleAssociateMapSubmit = (e, values) => {
        this.props.onAssociateMapInSuppliers(values.mapId, values.contractorId);
        this.setState({
            showAssociateConfirmationModal: !this.state
                .showAssociateConfirmationModal,
        });
    };

    handleCategoryChange = category => {
        this.setState({
            selectedCategory: {
                selectedLabelOption: category.label,
                selectedValueOption: category.value,
            },
        });
        const categoryToSearch = this.props.categories.find(
            categoryByCode => categoryByCode.code === category.value,
        );

        if (category.value !== '') {
            this.props.onInitCategoryDetails(categoryToSearch?.categoryId);
        }
    };

    render() {
        const { intl, loadingList, maps, mapsCount, categories } = this.props;
        const { filters, mapToUpdate } = this.state;

        const newMapButtonEnabled = this.context.hasClaim('map-create');
        const canInactivateMap = this.context.hasClaim('map-delete');
        const hasLevelFull = this.context.hasClaim('role-level-full');
        const categoryTypes = this.mapCategoriesSelect(categories);
        const subCategoriesByCategory = this.mapSubCategoriesSelect(
            this.props.category?.subcategories || [],
        );
        const selectedCategory = categoryTypes.find(
            cat => cat.label === mapToUpdate.categoryType,
        );

        const columns = MapListColumns(
            intl,
            maps,
            canInactivateMap,
            hasLevelFull,
            this.handleCopyMapButtonClick,
            this.handleExportMapButtonClick,
            this.handleEditMapButtonClick,
            this.handleDeactivateMapToogleModal,
            this.handleAssociateClick,
        );

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/maps',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={intl.formatMessage(messages.subtitle, {
                                length: this.props.mapsCount,
                            })}
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={this.properties()}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.isLoading ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                        <button
                            className='new-btn small'
                            onClick={this.handleImportMapModalToogle}
                        >
                            <FontAwesomeIcon icon='upload' />
                        </button>
                        {newMapButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewMapModalToogle}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyMap,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={maps}
                        length={mapsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                        search
                    />
                </section>
                {/* Create Modal */}
                <Modal
                    title={
                        intl.formatMessage(messages.create) +
                        ' ' +
                        intl.formatMessage(messages.titleOnlyMap)
                    }
                    isOpen={this.state.showAddMapModal}
                    handleToggle={this.handleNewMapModalToogle}
                >
                    <AddMapModal
                        states={this.props.states}
                        cities={this.state.cities}
                        fileTypes={[]}
                        documentTypes={[]}
                        mapVersions={[]}
                        categoryTypes={this.props.categories}
                        handleStateChange={this.handleStateChange}
                        handleAddMapModalSubmit={this.handleAddMapModalSubmit}
                        handleCategoryChange={this.handleCategoryChange}
                        subCategories={this.props.category?.subcategories || []}
                        loading={this.props.loadingList}
                        handleCityChange={this.handleCityChange}
                        selectedCity={this.state.selectedCity}
                        selectedState={this.state.selectedState}
                        showImportMapFileInput={false}
                    />
                </Modal>
                {/* Copy Modal */}
                <Modal
                    title={
                        intl.formatMessage(messages.copy) +
                        ' ' +
                        intl.formatMessage(messages.titleOnlyMap)
                    }
                    isOpen={this.state.showCopyMapModal}
                    handleToggle={this.handleCopyMapModalToogle}
                >
                    <AddMapModal
                        states={this.props.states}
                        cities={this.state.cities}
                        fileTypes={[]}
                        documentTypes={[]}
                        mapVersions={[]}
                        categoryTypes={this.props.categories}
                        handleStateChange={this.handleStateChange}
                        handleAddMapModalSubmit={this.handleCopyMapModalSubmit}
                        handleCategoryChange={this.handleCategoryChange}
                        subCategories={this.props.category?.subcategories || []}
                        loading={this.props.loadingList}
                        handleCityChange={this.handleCityChange}
                        selectedCity={this.state.selectedCity}
                        selectedState={this.state.selectedState}
                        showImportMapFileInput={false}
                        importMap={this.state.copiedMap}
                        isCopiedMap
                    />
                </Modal>
                {/* Import Modal */}
                <Modal
                    title={
                        intl.formatMessage(messages.import) +
                        ' ' +
                        intl.formatMessage(messages.titleOnlyMap)
                    }
                    isOpen={this.state.showImportMapModal}
                    handleToggle={this.handleImportMapModalToogle}
                >
                    <AddMapModal
                        states={this.props.states}
                        cities={this.state.cities}
                        fileTypes={[]}
                        documentTypes={[]}
                        mapVersions={[]}
                        categoryTypes={this.props.categories}
                        handleStateChange={this.handleStateChange}
                        handleAddMapModalSubmit={
                            this.handleImportMapModalSubmit
                        }
                        handleCategoryChange={this.handleCategoryChange}
                        subCategories={this.props.category?.subcategories || []}
                        loading={this.props.loadingList}
                        handleCityChange={this.handleCityChange}
                        selectedCity={this.state.selectedCity}
                        selectedState={this.state.selectedState}
                        showImportMapFileInput={true}
                        importMap={this.state.importMap}
                        handleImportMapFileChange={
                            this.handleImportMapFileChange
                        }
                    />
                </Modal>
                <Modal
                    title={
                        intl.formatMessage(messages.titleEdit) +
                        ' ' +
                        intl.formatMessage(messages.titleOnlyMap)
                    }
                    isOpen={this.state.showEditMapModal}
                    handleToggle={this.handleEditMapButtonClick}
                >
                    <AvForm onValidSubmit={this.handleEditMap}>
                        <Container fluid>
                            <Row>
                                <AvField
                                    type='hidden'
                                    name='mapId'
                                    value={mapToUpdate?.mapId}
                                />
                                <Col xs={12} className={'text-left mt-3'}>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.name,
                                        )}
                                        type='text'
                                        name='name'
                                        value={mapToUpdate?.name}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                                <Col xs={12} className='text-left mt-3'>
                                    <EvtSelect
                                        label='Categoria'
                                        name='categoryType'
                                        options={[
                                            {
                                                label: (
                                                    <div
                                                        style={{ height: 20 }}
                                                    />
                                                ),
                                                value: '',
                                            },
                                            ...categoryTypes,
                                        ]}
                                        option={{
                                            selectedValueOption:
                                                selectedCategory?.value,
                                            selectedLabelOption:
                                                selectedCategory?.label,
                                        }}
                                        required={false}
                                    />
                                </Col>
                                <Col xs={12} className='text-left mt-3'>
                                    <EvtSelect
                                        label='SubCategoria'
                                        name='subCategory'
                                        options={[
                                            {
                                                label: '',
                                                value: '',
                                            },
                                            ...subCategoriesByCategory,
                                        ]}
                                        option={{
                                            selectedValueOption:
                                                mapToUpdate.subCategory,
                                            selectedLabelOption:
                                                mapToUpdate.subCategory,
                                        }}
                                        required={false}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12}>
                                    <button className='btn-submit'>
                                        {intl.formatMessage(
                                            messages.updateButton,
                                        )}
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </AvForm>
                </Modal>
                <Modal
                    key='deactiveMapModal'
                    title={
                        intl.formatMessage(messages.deactivateMap) +
                        ' ' +
                        this.state.selectedMap.name
                    }
                    isOpen={this.state.showDeactivateMapModal}
                    handleToggle={this.handleCloseDeactivateMapModal}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    secondButtonClass='delete'
                    toggleFirstButton={this.handleBtnDeactivateMapClick}
                    toggleSecondButton={this.handleCloseDeactivateMapModal}
                >
                    {intl.formatMessage(messages.confirmDeactiveText)}
                </Modal>
                <ModalAssociateMap
                    map={this.state.selectedMap}
                    showAssociateMapModal={this.state.showAssociateMapModal}
                    showAssociateConfirmationModal={
                        this.state.showAssociateConfirmationModal
                    }
                    handleToggleModalAssociateMap={
                        this.handleToggleModalAssociateMap
                    }
                    handleAssociateMapSubmit={this.handleAssociateMapSubmit}
                    handleToggleConfirmationModalAssociate={
                        this.handleToggleConfirmationModalAssociate
                    }
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        maps: state.mapReducer.maps || [],
        mapsCount: state.mapReducer.mapsCount || 0,
        states: state.stateReducer.states,
        loadingList: state.mapReducer.loadingList,
        categories: state.categoryReducer.categories || [],
        category: state.categoryReducer.category || null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitMaps: filter => dispatch(actions.initMaps(filter)),
        onInitMapsCount: filter => dispatch(actions.initMapsCount(filter)),
        onInitMapDetails: mapId => dispatch(actions.initMapDetails(mapId)),
        onInitStates: () => dispatch(actions.initStates()),
        onCreateMap: map => dispatch(actions.createMap(map)),
        onCopyMap: map => dispatch(actions.copyMap(map)),
        ontInitCategories: () => dispatch(actions.initCategories()),
        onInitCategoryDetails: categoryId =>
            dispatch(actions.initCategoryDetails(categoryId)),
        onDeleteMap: mapId => dispatch(actions.deleteMap(mapId)),
        onAssociateMapInSuppliers: (mapId, contratorId) =>
            dispatch(actions.associateMapInSuppliers(mapId, contratorId)),
        onUpdateMapFromList: (map, query) =>
            dispatch(actions.updateMapFromList(map, query)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MapListPage),
);
