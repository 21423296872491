import { defineMessages } from "react-intl";

import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,

  title: {
    id: "suppliers.title",
    defaultMessage: "Fornecedores"
  },

  titleOnlySupplier: {
    id: "suppliers.titleOnlySupplier",
    defaultMessage: "Fornecedor"
  },

  subtitle: {
    id: "suppliers.subtitle",
    defaultMessage: " fornecedor(es) {entity}"
  },

  companyName: {
    id: "detailsTab.companyName",
    defaultMessage: "Razão Social"
  },

  displayName: {
    id: 'detailsTab.displayName',
    defaultMessage: 'Nome Fantasia',
  },

  cnpj: {
    id: "suppliers.cnpj",
    defaultMessage: "CPF/CNPJ"
  },

  email: {
    id: "suppliers.email",
    defaultMessage: "E-mail"
  },

  city: {
    id: "suppliers.city",
    defaultMessage: "Cidade"
  },

  state: {
    id: "suppliers.state",
    defaultMessage: "Estado"
  },

  home: {
    id: "home.title",
    defaultMessage: "Início"
  },
  activity: {
    id: "suppliers.activity",
    defaultMessage: "Atividade"
  }
});

export default messages;
