import React, { useEffect, useState } from 'react';
import EvtSelect from '../EvtSelect/EvtSelect';
import axios from 'axios';
import { AvField } from 'availity-reactstrap-validation';

function ListField(props) {
    const {
        label,
        name,
        textList,
        id,
        key,
        documentFieldsListId,
        disabled,
        className,
        validate,
        value,
        required,
    } = props;

    const [selectedListItem, setSelectedListItem] = useState([]);

    useEffect(() => {
        if (!!value && !!textList) {
            setSelectedListItem({ label: textList, value });
        }
    }, [value, textList]);

    const handleSelectedOptionChange = option => {
        setSelectedListItem(option);
    };

    return (
        <>
            <EvtSelect
                url={`/Documents/DocumentFields/Lists/${documentFieldsListId}/Items`}
                className={`${className || ''} dynamic-field`}
                label={label}
                name={name}
                id={id}
                key={key}
                labelKey='name'
                valueKey='value'
                disabled={disabled}
                required={required}
                take="10"
                handleSelectedOptionChange={handleSelectedOptionChange}
                option={
                    selectedListItem && {
                        selectedValueOption: selectedListItem.value,
                        selectedLabelOption: selectedListItem.label,
                    }
                }
                errorMessage=''
                async
            />
            <AvField
                style={{
                    visibility: 'hidden',
                    overflow: 'hidden',
                    margin: 0,
                    padding: 0,
                    width: 0,
                    height: 0,
                }}
                id={`${id}TextList`}
                name={`${name}TextList`}
                key={`${key}TextList`}
                value={selectedListItem.label}
                type='text'
            />
        </>
    );
}

export default ListField;
