import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import messages from '../NfeDetailsPage.intl';

export const columnsItems = (intl, nfeItems) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemNfeNumber)}</span>
            </div>
        ),
        accessor: 'nItemNumber',
        style: {
            fontSize: 'small',
        },
        minWidth: 60,
        minWidth: 80,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemNfeDescription)}</span>
            </div>
        ),
        accessor: 'prod.xProd',
        style: {
            fontSize: 'small',
        },
        minWidth:
            85 +
            calculateColumnWidth(
                nfeItems,
                'prod.xProd',
                intl.formatMessage(messages.itemOrderDescription),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ncm)}</span>
            </div>
        ),
        accessor: 'prod.ncm',
        style: {
            fontSize: 'small',
        },
        minWidth:
            50 +
            calculateColumnWidth(
                nfeItems,
                'prod.ncm',
                intl.formatMessage(messages.ncm),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.quantity)}</span>
            </div>
        ),
        accessor: 'prod.qCom',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const quantityValue = String(row.value);
            const value = quantityValue.includes('.')
                ? quantityValue.replace('.', ',')
                : quantityValue;
            return (
                <div className='customer-container'>
                    <div className=''>{`${value || 0}${
                        row.original.prod.uCom
                    }`}</div>
                </div>
            );
        },
        minWidth:
            65 +
            calculateColumnWidth(
                nfeItems,
                'prod.qCom',
                intl.formatMessage(messages.quantity),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.unitaryNetValue)}</span>
            </div>
        ),
        accessor: 'prod.vUnCom',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        minWidth:
            60 +
            calculateColumnWidth(
                nfeItems,
                'prod.vUnCom',
                intl.formatMessage(messages.unitaryNetValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalGrossValue)}</span>
            </div>
        ),
        accessor: 'prod.vProd',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        minWidth:
            70 +
            calculateColumnWidth(
                nfeItems,
                'prod.vProd',
                intl.formatMessage(messages.totalGrossValue),
            ),
    },
];

export const columnsOrders = (
    intl,
    nfeOrders,
    handleToggleModalDelete,
    hasAllowDelete,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.nfeItemNumber)}</span>
            </div>
        ),
        accessor: 'nfeItemNumber',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                nfeOrders,
                'nfeItemNumber',
                intl.formatMessage(messages.nfeItemNumber),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                nfeOrders,
                'nfeItemNumber',
                intl.formatMessage(messages.nfeItemNumber),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.titleOrderCode)}</span>
            </div>
        ),
        accessor: 'orderCode',
        style: {
            fontSize: 'small',
        },

        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'orderCode',
                intl.formatMessage(messages.titleOrderCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.description)}</span>
            </div>
        ),
        accessor: 'orderName',
        style: {
            fontSize: 'small',
        },

        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'orderName',
                intl.formatMessage(messages.description),
            ),
    },

    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrder)}</span>
            </div>
        ),
        accessor: 'itemOrder',
        style: {
            fontSize: 'small',
        },
        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.orderMeasure)}</span>
            </div>
        ),
        accessor: 'orderMeasure',
        style: {
            fontSize: 'small',
        },
        minWidth:
            50 +
            calculateColumnWidth(
                nfeOrders,
                'orderMeasure',
                intl.formatMessage(messages.orderMeasure),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrderDescription)}</span>
            </div>
        ),
        accessor: 'description',
        style: {
            fontSize: 'small',
        },
        minWidth:
            85 +
            calculateColumnWidth(
                nfeOrders,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
        maxWidth:
            90 +
            calculateColumnWidth(
                nfeOrders,
                'description',
                intl.formatMessage(messages.itemOrderDescription),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.nfeOrderTotalGrossValue)}
                </span>
            </div>
        ),
        accessor: 'totalGrossValue',
        Cell: row => {
            const valueFormat = () => {
                let valueFormatted = String(row.value) || '';

                valueFormatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }).format(valueFormatted);

                return valueFormatted;
            };
            return (
                <div className='customer-container'>
                    <div className=''>{valueFormat()}</div>
                </div>
            );
        },
        style: {
            fontSize: 'small',
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                nfeOrders,
                'totalGrossValue',
                intl.formatMessage(messages.nfeOrderTotalGrossValue),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                nfeOrders,
                'totalGrossValue',
                intl.formatMessage(messages.nfeOrderTotalGrossValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.deleteButton)}</span>
            </div>
        ),
        accessor: 'delete',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <button
                        style={{ width: '100px' }}
                        className={
                            !hasAllowDelete ? 'btn-disable' : 'btn-delete'
                        }
                        onClick={() => {
                            handleToggleModalDelete(row.original);
                        }}
                        disabled={!hasAllowDelete}
                    >
                        <FontAwesomeIcon icon='trash-alt' />
                    </button>
                </div>
            );
        },
        maxWidth: 100,
    },
];
