import * as actionTypes from '../actions/actionTypes';

const initialState = {
    cfopsList: [],
    cfopsCount: 0,
    loading: false
};

const setCFOPs = (state, action) => {
    return {
        ...state,
        cfopsList: action.cfops,
        loading: false,
    };
};

const setCFOPsRequest = (state) => {
    return {
        ...state,
        cfopsList: [],
        loading: true,
    };
};

const setCFOPsRequestError = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CFOPS_GET_REQUEST:
            return setCFOPsRequest(state);

        case actionTypes.CFOPS_GET_SUCCESS:
            return setCFOPs(state, action);

        case actionTypes.CFOPS_GET_FAILURE:
            return setCFOPsRequestError(state, action);

        default:
            return state;
    }
};
export default reducer;