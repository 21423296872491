import * as actionTypes from '../actions/actionTypes';

const initialState = {
    fieldsCount: 0,
    fields: [],
    field: null,
    contractors: [],
    loadingList: true,
};

const setFieldsRequest = (state, _action) => {
    return {
        ...state,
        fields: [],
        loadingList: true,
    };
};

const setFields = (state, { fields }) => {
    return {
        ...state,
        fields,
        loadingList: false,
        field: null,
    };
};

const setFieldsFailure = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const setFieldsCountRequest = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setFieldsCount = (state, { fieldsCount }) => {
    return {
        ...state,
        fieldsCount,
        loadingList: false,
    };
};

const setFieldsCountFailure = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const setFieldRequest = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setFieldSuccess = (state, { field }) => {
    return {
        ...state,
        field,
        loadingList: false,
    };
};

const setFieldFailure = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const createDocumentFieldRequest = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const createDocumentFieldSuccess = (state, { field }) => {
    return {
        ...state,
        field,
        loadingList: false,
    };
};

const createDocumentFieldFailure = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const updateFieldRequest = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const updateFieldSuccess = (state, { field }) => {
    return {
        ...state,
        field,
        loadingList: false,
    };
};

const updateFieldFailure = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const deleteFieldRequest = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const deleteFieldSuccess = (state, _action) => {
    return {
        ...state,
        loadingList: false,
    };
};

const deleteFieldFailure = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const getContractorsRequest = (state, _action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const getContractorsSuccess = (state, action) => {
    return {
        ...state,
        contractors: action.contractors,
        loadingList: false,
    };
};

const getContractorsFailure = (state, { error }) => {
    return {
        ...state,
        error,
        loadingList: false,
    };
};

const setDocumentFieldsDetailsNew = (state, action) => {
    return {
        ...state,
        field: undefined,
        loadingList: false,
        loading: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DOCUMENT_FIELDS_GET_LIST_REQUEST:
            return setFieldsRequest(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_LIST_SUCCESS:
            return setFields(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_LIST_FAILURE:
            return setFieldsFailure(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_COUNT_REQUEST:
            return setFieldsCountRequest(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_COUNT_SUCCESS:
            return setFieldsCount(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_COUNT_FAILURE:
            return setFieldsCountFailure(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_DETAILS_REQUEST:
            return setFieldRequest(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_DETAILS_SUCCESS:
            return setFieldSuccess(state, action);

        case actionTypes.DOCUMENT_FIELDS_GET_DETAILS_FAILURE:
            return setFieldFailure(state, action);

        case actionTypes.DOCUMENT_FIELDS_PUT_REQUEST:
            return updateFieldRequest(state, action);

        case actionTypes.DOCUMENT_FIELDS_PUT_SUCCESS:
            return updateFieldSuccess(state, action);

        case actionTypes.DOCUMENT_FIELDS_PUT_FAILURE:
            return updateFieldFailure(state, action);

        case actionTypes.DOCUMENT_FIELDS_DELETE_REQUEST:
            return deleteFieldRequest(state, action);

        case actionTypes.DOCUMENT_FIELDS_DELETE_SUCCESS:
            return deleteFieldSuccess(state, action);

        case actionTypes.DOCUMENT_FIELDS_DELETE_FAILURE:
            return deleteFieldFailure(state, action);

        case actionTypes.DOCUMENT_FIELDS_CONTRACTORS_REQUEST:
            return getContractorsRequest(state, action);

        case actionTypes.DOCUMENT_FIELDS_CONTRACTORS_SUCCESS:
            return getContractorsSuccess(state, action);

        case actionTypes.DOCUMENT_FIELDS_CONTRACTORS_FAILURE:
            return getContractorsFailure(state, action);

        case actionTypes.DOCUMENT_FIELDS_CREATE_REQUEST:
            return createDocumentFieldRequest(state, action);

        case actionTypes.DOCUMENT_FIELDS_CREATE_SUCCESS:
            return createDocumentFieldSuccess(state, action);

        case actionTypes.DOCUMENT_FIELDS_CREATE_FAILURE:
            return createDocumentFieldFailure(state, action);

        case actionTypes.DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_NEW:
            return setDocumentFieldsDetailsNew(state, action);

        default:
            return state;
    }
};

export default reducer;
