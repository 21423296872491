// Dependencies
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import messages from '../../containers/LoginPage/LoginPage.intl';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import PoweredBy from '../PoweredBy/PoweredBy';
import { isDomainIncluded } from '../../utils/getAuth0Config';

export const LoginForm = props => {
    const {
        intl,
        contractorColor,
        contractorId,
        handleLoginFormSubmit,
        handleForgotPasswordToogle,
        onLoginWithGoogle,
    } = props;

    const {
        isAuthenticated,
        getIdTokenClaims,
        loginWithPopup,
        logout,
    } = useAuth0();

    useEffect(() => {
        if (isAuthenticated && isDomainIncluded()) {
            getAuth();
        }
    }, [isAuthenticated]);

    const handleLoginWithAuth0 = () => {
        try {
            loginWithPopup();
        } catch (error) {
            logout({
                logoutParams: {
                    returnTo: `${window.location.origin}/login`,
                },
            });
        }
    };

    async function getAuth() {
        let token = '';

        try {
            token = await getIdTokenClaims();
        } catch (error) {
            toast.error('Erro ao obter token');
            console.error('Erro ao obter token:', error);
            logout();
        }

        onLoginWithGoogle(token.__raw, contractorId);
    }

    return (
        <AvForm onValidSubmit={handleLoginFormSubmit}>
            <FormGroup>
                <AvField
                    label={intl.formatMessage(messages.email)}
                    type='email'
                    name='email'
                    id='email'
                    errorMessage={intl.formatMessage(messages.invalidEmail)}
                    required
                />
            </FormGroup>

            <FormGroup>
                <AvField
                    label={intl.formatMessage(messages.password)}
                    type='password'
                    name='password'
                    id='password'
                    errorMessage=' '
                    required
                />
            </FormGroup>

            <div className='btns-login'>
                <button
                    style={{ backgroundColor: contractorColor }}
                    className='login-btn'
                    type='submit'
                >
                    {intl.formatMessage(messages.loginButton)}
                </button>

                {isDomainIncluded() && (
                    <button
                        style={{ backgroundColor: contractorColor }}
                        className='login-btn'
                        type='button'
                        onClick={handleLoginWithAuth0}
                    >
                        Entrar via SSO
                    </button>
                )}

                <button
                    style={{ color: contractorColor }}
                    className='btn-link btnForgotPassword'
                    type='button'
                    onClick={handleForgotPasswordToogle}
                >
                    {intl.formatMessage(messages.forgotPassword)}
                </button>

                <PoweredBy intl={intl} />
            </div>
        </AvForm>
    );
};

export default injectIntl(LoginForm);
