import React, { useContext, useEffect, useState } from 'react';

import { injectIntl } from 'react-intl';
import messages from '../CteDetailsForm.intl';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

import { AuthContext } from '../../../context/AuthContext';
import Modal from '../../../components/Modal/Modal';

const CteActionModals = props => {
    const {
        intl,
        // SendDocument
        isOpenSendDoc,
        handleToggleModalSendDoc,
        handleSubmitForm,
        // Cancel
        isOpenCancel,
        handleToggleModalCancel,
        handleCancelSubmit,
        // ReportCancel
        isOpenReject,
        handleToggleModalReject,
        handleRejectSubmit,
    } = props;

    const formRef = React.createRef();
    const context = useContext(AuthContext);

    useEffect(() => {
        if (isOpenReject) {
            setJustificationText('');
        }
    }, [isOpenReject]);

    const [justificationText, setJustificationText] = useState('');
    const justificationTextChange = e => setJustificationText(e.target.value);

    const handleModalRequired = () => {
        if (context.hasClaim('cte-document-reject-justification')) {
            return { required: { value: true } };
        } else {
            return { required: { value: false } };
        }
    };

    return (
        <>
            {/* Enviar */}
            <Modal
                title={intl.formatMessage(messages.confirmSend)}
                isOpen={isOpenSendDoc}
                handleToggle={handleToggleModalSendDoc}
                hasFooter={true}
                firstButtonClass='delete'
                secondButtonClass='btn-submit'
                firstButtonText={intl.formatMessage(messages.noText)}
                secondButtonText={intl.formatMessage(messages.yesText)}
                toggleFirstButton={handleToggleModalSendDoc}
                toggleSecondButton={handleSubmitForm}
            >
                <p>Atenção:</p>
                {intl.formatMessage(messages.sendDocumentConfirmText)}
            </Modal>

            {/* Cancelar */}
            <Modal
                title={intl.formatMessage(messages.cancelButton)}
                isOpen={isOpenCancel}
                handleToggle={handleToggleModalCancel}
                hasFooter={true}
                firstButtonClass='delete'
                secondButtonClass='btn-submit'
                firstButtonText={intl.formatMessage(messages.noText)}
                secondButtonText={intl.formatMessage(messages.yesText)}
                toggleFirstButton={handleToggleModalCancel}
                toggleSecondButton={handleCancelSubmit}
            >
                {intl.formatMessage(messages.confirmCancelText)}
            </Modal>

            {/* Reject */}
            <AvForm
                ref={formRef}
                onValidSubmit={() => handleRejectSubmit(justificationText)}
            >
                <Modal
                    title={intl.formatMessage(messages.rejectButton)}
                    isOpen={isOpenReject}
                    handleToggle={handleToggleModalReject}
                    hasFooter={true}
                    toggleSecondButton={() => {
                        if (formRef.current) formRef.current.handleSubmit();
                    }}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    firstButtonText={intl.formatMessage(messages.closeModal)}
                    secondButtonText={intl.formatMessage(messages.submitButton)}
                    toggleFirstButton={handleToggleModalReject}
                >
                    <p style={{ marginRight: '30px' }}>
                        {intl.formatMessage(messages.justification)}
                    </p>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <AvField
                                    label={''}
                                    type='textarea'
                                    style={{
                                        height: '200px',
                                        width: '400px',
                                    }}
                                    name='justificationtext'
                                    value={justificationText}
                                    onChange={justificationTextChange}
                                    id='justification'
                                    validate={() => handleModalRequired()}
                                    errorMessage='O motivo do cancelamento é obrigatório'
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal>
            </AvForm>
        </>
    );
};

export default injectIntl(CteActionModals);
