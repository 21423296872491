import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    setupName: {
        id: 'settings.setupName',
        defaultMessage: 'Identificador da configuração',
    },
    EmailFromOriginalMailBox: {
        id: 'settings.EmailFromOriginalMailBox',
        defaultMessage: 'E-mail sem encaminhamaneto automático',
    },
    active: {
        id: 'settings.active',
        defaultMessage: 'Ativo',
    },
    email: {
        id: 'settings.email',
        defaultMessage: 'Email',
    },
    url: {
        id: 'settings.url',
        defaultMessage: 'URL de Envio',
    },
    smtp: {
        id: 'settings.smtp',
        defaultMessage: 'SMTP',
    },
    imap: {
        id: 'settings.imap',
        defaultMessage: 'IMAP',
    },
    port: {
        id: 'settings.port',
        defaultMessage: 'Port {entity}',
    },
    host: {
        id: 'settings.host',
        defaultMessage: 'Host {entity}',
    },
    user: {
        id: 'settings.user',
        defaultMessage: 'Usuário',
    },
    password: {
        id: 'settings.password',
        defaultMessage: 'Senha ',
    },
    loginAuth2: {
        id: 'settings.loginAuth2',
        defaultMessage: 'Login via Oauth2 ',
    },

    //submit and delete
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },

    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
});

export default messages;
