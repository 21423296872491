import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import * as actions from '../../../store/actions/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';

import EvtFilter from '../../../components/EvtFilter/EvtFilter';
import EvtTable from '../../../components/EvtTable/EvtTable';
import Header from '../../../components/Header/Header';

import ReportFilterModal from '../../../components/ReportFilterModal/ReportFilterModal';
import { reportsColumns, reportsProperties } from '../components/Columns';
import messages from '../ReportListPage.intl';

function GeneratedReportsList(props) {
    const {
        intl,
        //state
        loadingList,
        reports,
        reportsCount,
        //props
        activeTab,
        handleGetTrProps,
    } = props;

    const [filters, setFilters] = useState([]);
    const [query, setQuery] = useState('');
    const [report, setReport] = useState('');
    const [showModalReportFilters, setShowModalReportFilters] = useState(false);

    const history = useHistory();

    const reloadReportsList = stateQuery => {
        const query = stateQuery.includes('&index=')
            ? stateQuery
            : stateQuery + `&index=${activeTab}`;
        history.push(`/reports` + query);

        props.onInitReports(stateQuery);
    };

    const handleRefreshButtonClick = () => {
        reloadReportsList(query);
    };

    const handleEvTableStateChange = stateQuery => {
        setQuery(stateQuery);
        reloadReportsList(stateQuery);
    };

    const handleFiltersChange = filters => {
        setFilters(filters);
        history.push(`/reports` + query);
    };

    const handleClickButtonGenerate = reportData => {
        props.setStateReportFilters(reportData.filters);
        setReport(reportData);
        reportData.filters[0].name
            ? handleToggleModalReportFilters()
            : props.generateReports(reportData.reportType, filters);
    };

    const handleToggleModalReportFilters = () => {
        setShowModalReportFilters(!showModalReportFilters);
    };

    const handleGenerateReport = filters => {
        props.generateReports(report.reportType, filters);
    };

    return (
        <>
            <Container fluid>
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.reportsTitle)}
                            subtitle={intl.formatMessage(
                                messages.reportsSubtitle,
                                {
                                    entity: props.reportsCount,
                                },
                            )}
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={reportsProperties(intl)}
                            handleFiltersChange={handleFiltersChange}
                            loading={loadingList}
                            hideAddButton
                            hideSearch
                        />
                        <button
                            className={`new-btn small ${
                                loadingList ? 'loading' : ''
                            }`}
                            onClick={handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={reportsColumns(
                            intl,
                            reports,
                            handleClickButtonGenerate,
                        )}
                        data={reports}
                        length={reportsCount}
                        pageSize={10}
                        handleStateChange={handleEvTableStateChange}
                        handleGetTrProps={handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                        search
                    />
                </section>
            </Container>
            <ReportFilterModal
                showModal={showModalReportFilters}
                handleToggleModal={handleToggleModalReportFilters}
                handleGenerateReport={handleGenerateReport}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        reports: state.reportReducer.reports || [],
        reportsCount: state.reportReducer.reportsCount || 0,
        loadingList: state.reportReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitReports: filter => dispatch(actions.initReports(filter)),
        generateReports: (reportType, filters) =>
            dispatch(actions.generateReports(reportType, filters)),
        setStateReportFilters: filters =>
            dispatch(actions.setStateReportFilters(filters)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(GeneratedReportsList),
);
