import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, FormGroup } from 'reactstrap';
import { AuthContext } from '../../context/AuthContext';
import messages from './SettingsMailBoxDetailsForm.intl';
import {
    AvField,
    AvForm,
    AvRadio,
    AvRadioGroup,
} from 'availity-reactstrap-validation';

function SettingsMailBoxDetailsForm(props) {
    const context = useContext(AuthContext);
    const { intl, isNew, mailBox } = props;

    const [disabledLogin, setDisabledLogin] = useState(false);

    const canCreateEmail = context.hasClaim('setting-create') && isNew;

    const canDeleteEmail = context.hasClaim('setting-delete') && !isNew;

    const canUpdateEmail = context.hasClaim('setting-update') && !isNew;

    const isReadOnly = !(canCreateEmail || canUpdateEmail);

    useEffect(() => {
        if (!!mailBox) setDisabledLogin(mailBox.oauth2);
    }, [mailBox]);

    const handleFieldChange = e => {
        const value = e.target.value;
        switch (value) {
            case 'true':
                setDisabledLogin(false);
                break;
            case 'false':
                setDisabledLogin(true);
                break;
            default:
                break;
        }
    };

    return (
        mailBox && (
            <AvForm
                onValidSubmit={props.handleSubmit}
                // model={{ server: mailBox?.imapServerHost ? 'IMAP' : 'SMTP' }}
            >
                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.setupName)}
                                type='text'
                                name='setupName'
                                id='setupName'
                                disabled={isReadOnly}
                                value={mailBox?.setupName || ''}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.url)}
                                type='text'
                                name='url'
                                id='url'
                                disabled={isReadOnly}
                                value={mailBox?.url || ''}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(messages.active)}
                                type='checkbox'
                                name='active'
                                id='active'
                                disabled={isReadOnly}
                                value={mailBox?.active || false}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.host, {
                                    entity: '(SMTP)',
                                })}
                                type='text'
                                name='smtpServerHost'
                                id='smtpServerHost'
                                disabled={isReadOnly}
                                value={mailBox?.smtpServerHost || ''}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.port, {
                                    entity: '(SMTP)',
                                })}
                                type='number'
                                name='smtpServerPort'
                                id='smtpServerPort'
                                disabled={isReadOnly}
                                value={
                                    (!!Number(mailBox?.smtpServerPort) &&
                                        mailBox?.smtpServerPort) ||
                                    ''
                                }
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.host, {
                                    entity: '(IMAP)',
                                })}
                                type='text'
                                name='imapServerHost'
                                id='imapServerHost'
                                disabled={isReadOnly}
                                value={mailBox?.imapServerHost || ''}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.port, {
                                    entity: '(IMAP)',
                                })}
                                type='number'
                                name='imapServerPort'
                                id='imapServerPort'
                                disabled={isReadOnly}
                                value={
                                    (!!Number(mailBox?.imapServerPort) &&
                                        mailBox?.imapServerPort) ||
                                    ''
                                }
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.email)}
                                type='text'
                                name='email'
                                id='email'
                                disabled={isReadOnly}
                                value={mailBox?.email || ''}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.user)}
                                type='text'
                                name='userName'
                                id='userName'
                                disabled={isReadOnly || disabledLogin}
                                value={
                                    disabledLogin ? '' : mailBox?.userName || ''
                                }
                                required={!disabledLogin}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.password)}
                                type='password'
                                name='password'
                                id='password'
                                disabled={isReadOnly || disabledLogin}
                                value={
                                    disabledLogin ? '' : mailBox?.password || ''
                                }
                                required={!disabledLogin}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(messages.loginAuth2)}
                                type='checkbox'
                                name='oauth2'
                                id='oauth2'
                                disabled={isReadOnly}
                                value={isNew ? true : mailBox?.oauth2}
                                onChange={e => handleFieldChange(e)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.EmailFromOriginalMailBox,
                                )}
                                type='checkbox'
                                name='emailFromOriginalMailBox'
                                id='emailFromOriginalMailBox'
                                disabled={isReadOnly}
                                value={
                                    isNew
                                        ? false
                                        : mailBox?.emailFromOriginalMailBox
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {!isReadOnly && (
                            <button
                                type='submit'
                                className='btn-submit'
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.submitButton)}
                            </button>
                        )}
                        {canDeleteEmail && (
                            <button
                                type='button'
                                className='btn-delete'
                                onClick={props.handleToggleModalDelete}
                            >
                                {intl.formatMessage(messages.deleteButton)}
                            </button>
                        )}
                    </Col>
                </Row>
            </AvForm>
        )
    );
}

export default injectIntl(SettingsMailBoxDetailsForm);
