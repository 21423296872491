const ssoEnvVariables = Object.fromEntries(
    Object.entries(window)
        .filter(([key]) => key.startsWith('REACT_APP_SSO'))
        .map(([key, value]) => {
            const formattedKey = key
                .replace('REACT_APP_SSO_', '')
                .toLowerCase();
            const formattedValue = value.trim();
            return [formattedKey, formattedValue];
        }),
);

const getAuth0Config = () => {
    return ssoEnvVariables;
};

const domainsToCheck = [
    'unimed.alldocs',
    'unimed.qas',
    'app.qas',
    'app.local',
    'app.dev',
];

export function isDomainIncluded() {
    const currentDomain = window.location.hostname;
    return domainsToCheck.some(domain => currentDomain.includes(domain));
}

export const isHttpsAuth0 = () =>
    window.location.protocol === 'https:' && isDomainIncluded();

export default getAuth0Config;
