import * as actionTypes from '../actions/actionTypes';

const initialState = {
    suppliers: [],
    supplier: null,
    maps: [],
    loadingDetails: true,
    loadingMaps: false,
    loadingList: false,
    supplierMapsCount: 0,
    supplierOrders: [],
};

const setDetailsRequest = (state, action) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setListRequest = (state, action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setMapsRequest = (state, action) => {
    return {
        ...state,
        loadingMaps: true,
    };
};

const setLoadingFalseError = (state, { error }) => {
    let newState = {
        ...state,
    };

    if (error) newState = { ...state, error };
    return {
        ...newState,
        loadingDetails: false,
        loadingList: false,
        loadingMaps: false,
    };
};

const setSuppliers = (state, action) => {
    return {
        ...state,
        suppliers: action.suppliers,
        suppliersCount: action.suppliersCount,
        loadingList: false,
    };
};

const setSuppliersCount = (state, action) => {
    return {
        ...state,
        suppliersCount: action.suppliersCount,
    };
};

const setSupplierDetailsRequest = (state, action) => {
    return {
        ...state,
        supplier: undefined,
        loadingDetails: true,
    };
};

const setSupplierDetails = (state, action) => {
    return {
        ...state,
        supplier: action.supplier,
        loadingDetails: false,
    };
};

const setSupplierDetailsPost = (state, action) => {
    return {
        ...state,
        supplier: action.supplier,
        loadingDetails: false,
        suppliers: [],
    };
};

const setSupplierDetailsNew = (state, action) => {
    return {
        ...state,
        supplier: undefined,
        loadingDetails: false,
    };
};

const setSupplierNewMap = (state, action) => {
    const maps = action.maps.slice();

    return {
        ...state,
        supplier: {
            ...state.supplier,
            maps,
        },
        maps,
    };
};

const setSupplierInput = (state, action) => {
    const supplier = action.supplier;

    return {
        ...state,
        supplier: {
            ...state.supplier,
            supplier,
        },
    };
};

const setSupplierMaps = (state, action) => {
    return {
        ...state,
        maps: action.maps,
        loadingMaps: false,
    };
};

const setSupplierMapsCount = (state, action) => {
    return {
        ...state,
        supplierMapsCount: action.supplierMapsCount,
    };
};

const setSupplierOrders = (state, { supplierOrders }) => {
    return {
        ...state,
        supplierOrders,
    };
};

const cleanSupplierMaps = (state, _action) => {
    return {
        ...state,
        maps: null,
        supplierMapsCount: 0,
        loadingMaps: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //REQUEST
        case actionTypes.SUPPLIER_PUT_REQUEST:
        case actionTypes.SUPPLIER_PUT_MAP_REQUEST:
        case actionTypes.SUPPLIER_DELETE_REQUEST:
            return setDetailsRequest(state, action);
        case actionTypes.SUPPLIERS_GET_LIST_REQUEST:
            return setListRequest(state, action);
        case actionTypes.SUPPLIER_GET_MAPS_REQUEST:
            return setMapsRequest(state, action);
        case actionTypes.SUPPLIER_POST_REQUEST:
        case actionTypes.SUPPLIER_GET_DETAILS_REQUEST:
            return setSupplierDetailsRequest(state, action);
        case actionTypes.SUPPLIER_GET_DETAILS_NEW:
            return setSupplierDetailsNew(state, action);

        //FAILURE
        case actionTypes.SUPPLIER_POST_FAILURE:
        case actionTypes.SUPPLIER_GET_DETAILS_FAILURE:
        case actionTypes.SUPPLIERS_GET_LIST_FAILURE:
        case actionTypes.SUPPLIER_PUT_FAILURE:
        case actionTypes.SUPPLIER_DELETE_FAILURE:
        case actionTypes.SUPPLIERS_GET_ORDERS_FAILURE:
        case actionTypes.SUPPLIER_PUT_MAP_FAILURE:
        case actionTypes.SUPPLIER_PUT_MAP_SUCCESS:
            return setLoadingFalseError(state, action);

        //SUCCESS
        case actionTypes.SUPPLIERS_GET_LIST_SUCCESS:
            return setSuppliers(state, action);

        case actionTypes.SUPPLIER_GET_DETAILS_SUCCESS:
        case actionTypes.SUPPLIER_PUT_SUCCESS:
            return setSupplierDetails(state, action);

        case actionTypes.SUPPLIER_POST_SUCCESS:
            return setSupplierDetailsPost(state, action);

        case actionTypes.SUPPLIERS_GET_COUNT_SUCCESS:
            return setSuppliersCount(state, action);

        case actionTypes.SUPPLIER_GET_MAPS_SUCCESS:
            return setSupplierMaps(state, action);

        case actionTypes.SUPPLIER_GET_MAPS_COUNT_SUCCESS:
            return setSupplierMapsCount(state, action);

        case actionTypes.SUPPLIER_SET_SUCCESS:
            return setSupplierDetails(state, action);

        case actionTypes.SUPPLIER_DELETE_MAP:
        case actionTypes.SUPPLIER_ADD_MAP:
            return setSupplierNewMap(state, action);

        case actionTypes.SUPPLIER_UPDATE_INPUT:
            return setSupplierInput(state, action);

        case actionTypes.SUPPLIERS_GET_ORDERS_SUCCESS:
            return setSupplierOrders(state, action);
            
        case actionTypes.CLEAN_SUPPLIER_MAPS:
            return cleanSupplierMaps(state, action);
        default:
            return state;
    }
};

export default reducer;
