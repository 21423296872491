import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    title: {
        id: 'other.title',
        defaultMessage: 'Outro documentos',
    },
    invoicesTitle: {
        id: 'invoices.title',
        defaultMessage: 'Outros documentos',
    },
    otherTab: {
        id: 'other.otherTab',
        defaultMessage: 'Outros',
    },
    otherNew: {
        id: 'other.new',
        defaultMessage: 'outro documento',
    },
    subtitle: {
        id: 'invoices.subtitle',
        defaultMessage: ' {length} documentos encontrados',
    },
    titleSource: {
        id: 'other.titleSource',
        defaultMessage: 'Origem',
    },
    sender: {
        id: 'other.sender',
        defaultMessage: 'Remetente',
    },
    issuer: {
        id: 'other.issuer',
        defaultMessage: 'Emitente',
    },
    recipient: {
        id: 'other.recipient',
        defaultMessage: 'Destinatário',
    },
    accessKey: {
        id: 'other.accessKey',
        defaultMessage: 'Chave de acesso',
    },
    otherNumber: {
        id: 'other.otherNumber',
        defaultMessage: 'Número do documento',
    },
    invoiceNumber: {
        id: 'invoices.invoiceNumber',
        defaultMessage: 'Número',
    },
    integratedAtDocument: {
        id: 'other.integratedAtDocument',
        defaultMessage: 'Integrado Em',
    },
    city: {
        id: 'other.city',
        defaultMessage: 'Cidade',
    },
    canUploadError: {
        id: 'invoices.canUploadError',
        defaultMessage:
            'Documento não vinculado ao fornecedor',
    },
    // #endregion Columns
});

export default messages;
