import React from 'react';
import { injectIntl } from 'react-intl';

import { FormGroup, Col } from 'reactstrap';

import { AvField } from 'availity-reactstrap-validation';

import messages from '../../MapItemDetailsPage.intl';

function ReplaceMapActionItemForm(props) {
    const { intl } = props;

    return (
        <React.Fragment>
            <Col xs={6}>
                <FormGroup
                    check
                    className='mb-5 py-4'
                    style={{ textAlign: 'left' }}
                >
                    <AvField
                        label='Ignore Case'
                        type='checkbox'
                        name='parameters.IgnoreCase'
                        value={props.parameters.IgnoreCase}
                    />
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup
                    check
                    className='mb-5 py-4'
                    style={{ textAlign: 'left' }}
                >
                    <AvField
                        label='Regex?'
                        type='checkbox'
                        name='parameters.IsRegex'
                        value={props.parameters.IsRegex}
                    />
                </FormGroup>
            </Col>
            <br />
            <br />
            <Col xs={6}>
                <FormGroup className='mt-5'>
                    <AvField
                        label={'Count'}
                        type='number'
                        name='parameters.Count'
                        value={props.parameters.Count}
                    />
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup className='mt-5'>
                    <AvField
                        label={'StartAt'}
                        type='number'
                        name='parameters.StartAt'
                        value={props.parameters.StartAt}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.oldText)}
                        type='text'
                        name='parameters.OldValue'
                        value={props.parameters.OldValue}
                        errorMessage=' '
                        required
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.newText)}
                        type='text'
                        name='parameters.NewValue'
                        value={props.parameters.NewValue}
                        errorMessage=' '
                    />
                </FormGroup>
            </Col>
        </React.Fragment>
    );
}

export default injectIntl(ReplaceMapActionItemForm);
