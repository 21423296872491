import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './RoleDetailsPage.intl';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import Select from 'react-select';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import CheckBoxList from '../../components/CheckBoxList/CheckBoxList';
import EvtSelect from '../../components/EvtSelect/EvtSelect';

import { AuthContext } from '../../context/AuthContext';

class RoleDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        selectedAllowedRoles: [],
    };

    componentDidMount() {
        this.props.onInitClaims();

        if (this.props.match.params.id === 'new') {
            this.props.onInitCleanRole();
            this.props.onInitContractorDetails('new');
        } else {
            this.props.onInitRolesDetails(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps) {
        const { role } = this.props;
        if (role && role !== prevProps.role) {
            const allowedRoles = role.allowedRoles || [];
            const selectedAllowedRoles = allowedRoles.map(r => {
                return { label: r.name, value: r.roleId };
            });

            this.setState({
                selectedAllowedRoles,
            });

            this.context.hasClaim('contractor-view') &&
                this.props.onInitContractorDetails(
                    this.props.role.contractorId,
                );
        }
    }

    distinct = (arr, prop) => {
        return arr.reduce((a, d) => {
            if (!a.includes(d[prop])) {
                a.push(d[prop]);
            }
            return a;
        }, []);
    };

    handleClaimChange = e => {
        const value = this.props.checkedClaims[e.target.id];

        if (value) {
            this.props.onChangeSelectedClaim(e.target.id, false);
        } else {
            this.props.onChangeSelectedClaim(e.target.id, true);
        }
    };

    handleRoleLevelChange = e => {
        this.props.onCleanRoleLevels();

        const value = this.props.checkedRoleLevel[e.target.id];

        if (value) {
            this.props.onChangeSelectedRoleLevel(e.target.id, false);
        } else {
            this.props.onChangeSelectedRoleLevel(e.target.id, true);
        }
    };

    handleSubmit = (e, values) => {
        const claims = [];

        for (var keyClaim in this.props.checkedClaims) {
            claims.push(keyClaim);
        }

        for (var keyRole in this.props.checkedRoleLevel) {
            claims.push(keyRole);
        }

        const allowedRoles = this.state.selectedAllowedRoles?.map(sar => {
            return {
                roleId: sar.value,
                name: sar.label,
            };
        });

        const claimsChecked = this.checkerAllowedClaims(
            this.props.claims,
            claims,
        );
        const finalValue = {
            ...values,
            claims: claimsChecked,
            allowedRoles,
        };

        if (this.props.match.params.id === 'new') {
            this.props.onCreateRole(finalValue);
        } else {
            finalValue.roleId = this.props.match.params.id;
            this.props.onUpdateRole(finalValue);
        }
    };

    checkerAllowedClaims = (allowedClaims, claimsToChecker) => {
        let claimsChecked = [];

        allowedClaims.map(claim => {
            if (claimsToChecker.includes(claim.value)) {
                claimsChecked.push(claim.value);
            }
        });

        return claimsChecked;
    };

    handleCheckAllChange = e => {
        this.props.onCheckAllClaims(e.target.checked);
    };

    handleCheckAllChange = e => {
        this.props.onCheckAllClaims(e.target.checked);
    };

    handleAllowedRolesSelectChange = e => {
        this.setState({
            selectedAllowedRoles: e,
        });
    };

    handleContractorChange = selectedContractor => {
        if (selectedContractor) {
            this.props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });

            this.setState(
                {
                    selectedAllowedRoles: [],
                },
                () => {
                    this.props.onInitRolesByContractor(
                        selectedContractor.value,
                    );
                },
            );
        }
    };

    render() {
        const id = this.props.match.params.id;

        const { selectedAllowedRoles } = this.state;
        const { intl, loadingDetails } = this.props;

        const role = this.props.role || {};
        let claims = this.props.claims || [];

        var contractorsOption = new Map();
        contractorsOption.set(
            this.props.contractor && this.props.contractor.contractorId,
            this.props.contractor && this.props.contractor.displayName,
        );

        const levels = claims.filter(m => m.policy === 'RoleLevel');
        claims = claims.filter(m => m.policy !== 'RoleLevel');

        const canCreateRole =
            this.context.hasClaim('role-create') && id === 'new';

        const canUpdateRole =
            this.context.hasClaim('role-update') && id !== 'new';

        const isReadOnly = !(canUpdateRole || canCreateRole);

        const rolesContractor = this.props.rolesContractor || [];
        const allowedRoles = rolesContractor.map(r => {
            return { label: r.name, value: r.roleId };
        });

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/roles',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/roles/:id',
                            name: intl.formatMessage(messages.titleRole, {
                                entity: this.props.role && this.props.role.name,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleRole, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content roles-form'>
                    <Loading loading={loadingDetails} />
                    <AvForm onValidSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.name,
                                        )}
                                        type='text'
                                        name='name'
                                        id='name'
                                        value={role.name || ''}
                                        errorMessage=' '
                                        required
                                        disabled={isReadOnly}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                {!isReadOnly ? (
                                    <EvtSelect
                                        name='contractorId'
                                        label={intl.formatMessage(
                                            messages.company,
                                        )}
                                        options={contractorsOption}
                                        url='/Contractors'
                                        labelKey='displayName'
                                        valueKey='contractorId'
                                        handleSelectedOptionChange={
                                            this.handleContractorChange
                                        }
                                        key={role?.contractorId}
                                        async
                                        required
                                        option={
                                            this.props.contractor && {
                                                selectedValueOption:
                                                    this.props.contractor &&
                                                    this.props.contractor
                                                        .contractorId,
                                                selectedLabelOption:
                                                    this.props.contractor &&
                                                    this.props.contractor
                                                        .displayName,
                                            }
                                        }
                                    />
                                ) : (
                                    <FormGroup>
                                        <AvField
                                            type='text'
                                            name='contractorId'
                                            id='contractorId'
                                            disabled={isReadOnly}
                                            label={intl.formatMessage(
                                                messages.company,
                                            )}
                                            value={role.contractorName}
                                            errorMessage=' '
                                            required
                                        />
                                    </FormGroup>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={8} xl={6}>
                                <label>
                                    {intl.formatMessage(
                                        messages.allowedRolesLabel,
                                    )}
                                </label>
                                <Select
                                    name='allowedRoles'
                                    className='evt-select'
                                    options={allowedRoles}
                                    isMulti
                                    placeholder={intl.formatMessage(
                                        messages.selectPlaceholder,
                                    )}
                                    closeMenuOnSelect={false}
                                    value={selectedAllowedRoles}
                                    onChange={
                                        this.handleAllowedRolesSelectChange
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col xs={12}>
                                <CheckBoxList
                                    items={levels}
                                    checked={this.props.checkedRoleLevel}
                                    groupby={this.distinct(levels, 'entity')}
                                    name='CheckBoxAccess'
                                    handleChange={this.handleRoleLevelChange}
                                    isReadOnly={isReadOnly}
                                    showSwitchButtons={false}
                                />
                                <CheckBoxList
                                    items={claims}
                                    checked={this.props.checkedClaims}
                                    groupby={this.distinct(claims, 'entity')}
                                    name='CheckBoxClaims'
                                    handleChange={this.handleClaimChange}
                                    isReadOnly={isReadOnly}
                                    showSwitchButtons={false}
                                />
                            </Col>
                        </Row>
                        <footer>
                            <Row className='mt-3'>
                                <Col xs={12}>
                                    {!isReadOnly && (
                                        <button className='btn-submit'>
                                            {intl.formatMessage(
                                                messages.submitButton,
                                            )}
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        </footer>
                    </AvForm>
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        checkedRoleLevel: state.roleReducer.checkedRoleLevel || [],
        checkedClaims: state.roleReducer.checkedClaims || [],
        checkedRoles: state.roleReducer.checkedRoles || [],
        role: state.roleReducer.role,
        rolesContractor: state.roleReducer.rolesContractor || [],
        roles: state.roleReducer.roles || [],
        claims: state.claimReducer.claims || [],
        contractors: state.contractorReducer.contractors || [],
        loadingDetails: state.roleReducer.loadingDetails,
        levels: state.roleReducer.roleLevels || [],
        contractor: state.contractorReducer.contractor,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitRolesByContractor: contractorId =>
            dispatch(actions.initRolesByContractor(contractorId)),
        onInitClaims: () => dispatch(actions.initClaims()),
        onInitContractors: () => dispatch(actions.initContractors()),
        onCreateRole: values => dispatch(actions.createRole(values)),
        onInitRolesDetails: roleId =>
            dispatch(actions.initRolesDetails(roleId)),
        onUpdateRole: values => dispatch(actions.updateRole(values)),
        onChangeSelectedUserRole: (userRole, checked) =>
            dispatch(actions.changeSelectedUserRole(userRole, checked)),
        onChangeSelectedClaim: (claim, checked) =>
            dispatch(actions.changeSelectedClaim(claim, checked)),
        onChangeSelectedRoleLevel: (level, checked) =>
            dispatch(actions.changeSelectedRoleLevel(level, checked)),
        onInitCleanRole: () => dispatch(actions.initCleanRole()),
        onCleanRoleLevels: () => dispatch(actions.cleanRoleLevels()),
        onCheckAllClaims: checked => dispatch(actions.checkAllClaims(checked)),
        onSetSelectedContractor: selectedContractor =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
        onInitContractorDetails: id =>
            dispatch(actions.initContractorDetails(id)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RoleDetailsPage),
);
