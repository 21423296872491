import React, { useContext } from 'react';

import { injectIntl } from 'react-intl';
import messages from './UserDetailsForm.intl';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactTable from 'react-table';
import { Row, Col, FormGroup, Badge } from 'reactstrap';
import Select from 'react-select';

import { AuthContext } from '../../context/AuthContext';
import { accessesColumns } from './components/Columns';
import EvtSelect from '../EvtSelect/EvtSelect';

function UserDetailsForm(props) {
    const {
        intl,
        canCreateUser,
        canUpdateUser,
        canResetPassword,
        contractorId,
        selectedUserCompanies,
        userCompanies,
    } = props;
    const user = props.user || {};
    const accesses = props.accesses || [];

    const isReadOnly = !(canCreateUser || canUpdateUser);

    const blocked = user && user.blocked;

    const context = useContext(AuthContext);

    const roleLevelFull = context.hasClaim('role-level-full');

    const roleLevelContractor = context.hasClaim('role-level-contractor');

    const canViewCompany = context.hasClaim('company-view');

    const levelFullAndCanViewCompany = canViewCompany && roleLevelFull;

    const showSystemUser = !isReadOnly && roleLevelFull;

    const isReadOnlyEmail = !canCreateUser;

    const hasContractor = roleLevelContractor || roleLevelFull;

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                {blocked && (
                    <Col lg={12} md={12} sm={12}>
                        <FormGroup className='mb-5'>
                            <h2>
                                <Badge
                                    pill
                                    className='method-image'
                                    color={'danger'}
                                >
                                    {intl.formatMessage(messages.blocked)}
                                </Badge>
                            </h2>
                        </FormGroup>
                    </Col>
                )}
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.name)}
                            type='text'
                            name='name'
                            id='name'
                            value={user.name}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.email)}
                            type='text'
                            name='emailAddress'
                            id='emailAddress'
                            value={user.emailAddress}
                            errorMessage=' '
                            disabled={isReadOnlyEmail}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <h5 className='title-area-form'>Acessos</h5>
            <Row>
                <Col xs={12} className='mt-3'>
                    <ReactTable
                        showPagination={false}
                        noDataText={intl.formatMessage(messages.noDataText)}
                        columns={accessesColumns(
                            intl,
                            props.handleRemoveAccessButtonClick,
                            isReadOnly,
                        )}
                        data={accesses}
                        minRows={0}
                        className='-striped -highlight text-center'
                        previousText={intl.formatMessage(
                            messages.previousButton,
                        )}
                        nextText={intl.formatMessage(messages.nextButtonText)}
                    />
                </Col>
            </Row>
            {canViewCompany && (
                <h5 className='title-area-form' style={{ marginTop: 120 }}>
                    Filiais
                </h5>
            )}
            {levelFullAndCanViewCompany && (
                <Row>
                    <Col xs={6}>
                        <EvtSelect
                            label={intl.formatMessage(messages.company)}
                            name='contractorId'
                            url='/Contractors'
                            labelKey='displayName'
                            valueKey='contractorId'
                            async
                            required={false}
                            disabled={isReadOnly}
                            handleSelectedOptionChange={
                                props.handleCompanyContractorChange
                            }
                        />
                    </Col>
                </Row>
            )}
            {canViewCompany && (
                <Row>
                    <Col xs={12}>
                        <label>
                            {intl.formatMessage(messages.companiesForReport)}
                        </label>
                        <Select
                            name='companies'
                            className='evt-select'
                            options={userCompanies}
                            isMulti
                            placeholder={intl.formatMessage(
                                messages.selectPlaceholder,
                            )}
                            key={contractorId}
                            closeMenuOnSelect={false}
                            isDisabled={isReadOnly || !hasContractor}
                            value={selectedUserCompanies}
                            onChange={props.handleUserCompaniesSelectChange}
                        />
                    </Col>
                </Row>
            )}
            <Row className='mt-3'>
                <Col xs={6}>
                    {showSystemUser && (
                        <AvField
                            label={intl.formatMessage(messages.systemUser)}
                            type='checkbox'
                            name='systemUser'
                            id='systemUser'
                            value={user.systemUser || ''}
                            checked={user.systemUser}
                            disabled={isReadOnly}
                        />
                    )}
                </Col>
                <Col xs={12}>
                    {!isReadOnly && (
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    )}
                    {!isReadOnly && (
                        <button
                            type='button'
                            onClick={props.handleNewAccessButtonClick}
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.newAccess)}
                        </button>
                    )}
                    {canResetPassword && (
                        <button
                            type='button'
                            onClick={props.handleNewPasswordButtonClick}
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.newPasswordButtonText)}
                        </button>
                    )}
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(UserDetailsForm);
