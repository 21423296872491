import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './RoleListPage.intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtTable from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { AuthContext } from '../../context/AuthContext';
import { rolesColumns, rolesProperties } from './components/Columns';

class RoleListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: ' ',
    };

    reloadRolesList = () => {
        const { query } = this.state;

        this.props.history.push('/roles' + query);

        this.props.onInitRolesCount(query);
        this.props.onInitRoles(query);
    };

    handleNewClick = () => {
        this.props.history.push(`/roles/new`);
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.history.push(`/roles/${rowInfo.original.id}`),
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadRolesList();
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadRolesList();
            },
        );
    };

    render() {
        const { intl, loadingList, roles, rolesCount } = this.props;
        const { filters } = this.state;
        
        const newRoleButtonEnabled = this.context.hasClaim('role-create');

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/roles',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={intl.formatMessage(messages.subtitle, {
                                length: this.props.rolesCount,
                            })}
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={rolesProperties(intl)}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                            cleanUpFilter
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newRoleButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyRole,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={rolesColumns(intl, roles)}
                        data={roles}
                        length={rolesCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        roles: state.roleReducer.roles || [],
        rolesCount: state.roleReducer.rolesCount || 0,
        loadingList: state.roleReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitRoles: filter => dispatch(actions.initRoles(filter)),
        onInitRolesCount: filter => dispatch(actions.initRolesCount(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RoleListPage),
);
