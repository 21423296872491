import { defineMessages } from "react-intl";
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
  ...globalMessages,
  changePassword: {
    id: 'settings.changePassword',
    defaultMessage: 'Alterar Senha',
  },
  newPasswordLabelText: {
    id: 'settings.newPasswordLabelText',
    defaultMessage: 'Nova senha',
  },
  confirmPasswordLabelText: {
    id: 'settings.confirmPasswordLabelText',
    defaultMessage: 'Confirmar senha',
  },
  passwordDoesNotMatchValidationText: {
    id: 'settings.passwordDoesNotMatchValidationText',
    defaultMessage: 'As senhas não são iguais',
  },
  
});

export default messages;
