import { defineMessages } from "react-intl";
import globalMessages from "../../../utils/globalMessages";

export default defineMessages({
  ...globalMessages,

  contractorName: {
      id: 'widgetTable.contractorName',
      defaultMessage: 'Contratante',
  },

  supplierName: {
    id: 'widgetTable.supplierName',
    defaultMessage: 'Fornecedor',
  },

  dueDate: {
    id: 'widgetTable.dueDate',
    defaultMessage: 'Dt. Venc',
  },

  value: {
    id: 'widgetTable.value',
    defaultMessage: 'Valor',
  },

  latestPendingDocuments: {
    id: 'widgetTable.latestPendingDocuments',
    defaultMessage: 'Útimos documentos adicionados',
  },

  paymentsDueList: {
    id: 'widgetTable.paymentsDueList',
    defaultMessage: 'Pagamentos pendentes',
  },
});
