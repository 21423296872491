import React, { useState, useEffect, useMemo } from 'react';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

import messages from '../IntegrationListPage.intl';
import { NFSeNacionalFilters } from "../components/Filters"

import Header from '../../../components/Header/Header';
import EvtTable from '../../../components/EvtTable/EvtTable';
import EvtFilter from '../../../components/EvtFilter/EvtFilter';

import { columnsNFSeNacional } from "../components/ColumnsIntegrations";

function NFSeNacionalListIntegration(props) {
    const {
        onInitNFSeNacionalSearch,
        onInitNFSeNacionalSearchCount,
        integrationsNFSeNacional,
        integrationsNFSeNacionalCount,
        loadingNFSeNacional,
        handleGetTrProps,
        activeTab,
        intl,
    } = props;

    const [query, setQuery] = useState('');
    const [filtersNFSeNacional, setFiltersNFSeNacional] = useState([]);

    useEffect(() => {
        reloadIntegrationsList();
    }, [query]);

    function reloadIntegrationsList() {
        const { filterValue } = filtersNFSeNacional;
        const urlParams = new URLSearchParams(query);
        const take = urlParams.get('take') || 10;
        const skip = urlParams.get('skip') || 0;
        const sort = urlParams.get('sort') || '-createdAt';
        const querySearch = `?skip=${skip}&take=${take}&sort=${sort}&index=${activeTab}`;

        const queryURL = filterValue
            ? querySearch
            : query + `&index=${activeTab}`;
        const url = filterValue
            ? queryURL + `&search=${filterValue}`
            : queryURL;

        window.history.replaceState(url, undefined, 'integrations' + url);
        if (query.includes('take')) {
            onInitNFSeNacionalSearch(queryURL, filterValue);
        }
    }

    function handleReloadButton() {
        reloadIntegrationsList();
    }

    function handleEvTableStateChangeNFSeNacional(queryTable) {
        setQuery(queryTable);
    }

    function handleFiltersNFSeNacionalChange(newFiltersNFSeNacional, value) {
        setFiltersNFSeNacional({ filters: newFiltersNFSeNacional, filterValue: value });
    }

    const NFSeNacionalColumns = useMemo(() => {
        return columnsNFSeNacional(integrationsNFSeNacional, intl);
    }, [integrationsNFSeNacional]);

    return (
        <div>
            <header className='table-screen'>
                <section className='title'>
                    <Header title={intl.formatMessage(messages.title)} />
                </section>
                <section className='btns-topo'>
                    <EvtFilter
                        properties={NFSeNacionalFilters(intl)}
                        handleFiltersChange={handleFiltersNFSeNacionalChange}
                        loading={loadingNFSeNacional}
                    />
                    <button
                        className={`new-btn small ${loadingNFSeNacional ? 'loading' : ''
                            }`}
                        onClick={handleReloadButton}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                </section>
            </header>
            <section>
                <EvtTable
                    filters={filtersNFSeNacional.filters}
                    columns={NFSeNacionalColumns}
                    data={integrationsNFSeNacional}
                    length={integrationsNFSeNacionalCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeNFSeNacional}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingNFSeNacional}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: integrationsNFSeNacionalCount,
                    })}
                    fetchPage
                />
            </section>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        integrationsNFSeNacional: state.integrationReducer.integrationsNFSeNacional || [],
        integrationsNFSeNacionalCount:
            state.integrationReducer.integrationsNFSeNacionalCount || 0,
        loadingNFSeNacional: state.integrationReducer.loadingNFSeNacional,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onInitNFSeNacionalSearch: (filter, search) =>
            dispatch(actions.initIntegrationsNFSeNacionalSearch(filter, search)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(NFSeNacionalListIntegration)),
);