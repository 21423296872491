import { goBack, push } from 'connected-react-router';
import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';
import { initOrderItemsBySupplier } from './orderActions';
import { initNfeOrders, initNfeOrdersCount } from './NFeActions';
import { initCteOrders } from './cteActions';

//NFSe
export const initDocuments = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICES_GET_LIST_REQUEST,
        });

        axios
            .get('/Documents' + filter)
            .then(response => {
                // create a fake column "invoiceNumber" to avoid sorting problemns
                response.data.forEach(document => {
                    const form = document.form || {};
                    const fields = form.fields || [];

                    const numbers = fields.filter(
                        field => field.key === 'invoiceNumber',
                    );

                    document.invoiceNumber = numbers?.[0]?.value || '';
                });

                dispatch({
                    type: actionTypes.INVOICES_GET_LIST_SUCCESS,
                    documents: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsCount = filter => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICE_GET_COUNT_REQUEST,
        });

        await axios
            .get('/Documents/Count' + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_GET_COUNT_SUCCESS,
                    documentsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

//Search
export const initDocumentsSearch = (value, documentType, filter) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICES_SEARCH_GET_LIST_REQUEST,
        });

        await axios
            .get(
                `/Documents/${encodeURIComponent(
                    value,
                )}/Search/${documentType}` + filter,
            )
            .then(response => {
                response.data.forEach(document => {
                    const form = document.form || {};
                    const fields = form.fields || [];

                    const numbers = fields.filter(
                        field => field.key === 'invoiceNumber',
                    );

                    document.invoiceNumber = numbers?.[0]?.value || '';
                });

                dispatch({
                    type: actionTypes.INVOICES_SEARCH_GET_LIST_SUCCESS,
                    documentsSearch: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_SEARCH_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsCteSearch = (value, filter) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICES_SEARCH_GET_LIST_REQUEST,
        });

        await axios
            .get(`/Documents/${encodeURIComponent(value)}/Search/CTE` + filter)
            .then(response => {
                response.data.forEach(documentsCte => {
                    const form = documentsCte.form || {};
                    const fields = form.fields || [];

                    const numbers = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/ide/nCT',
                    );

                    if (numbers.length > 0) {
                        documentsCte.invoiceNumber = numbers[0].value || '';
                    } else {
                        documentsCte.invoiceNumber = '';
                    }

                    const infDoc = fields.filter(
                        field => field.key === '/cteProc/protCTe/infProt/chCTe',
                    );

                    if (infDoc.length > 0) {
                        documentsCte.infDoc = infDoc[0].value || '';
                    } else {
                        documentsCte.infDoc = '';
                    }

                    const emit = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/emit/xNome',
                    );

                    if (emit.length > 0) {
                        documentsCte.emit = emit[0].value || '';
                    } else {
                        documentsCte.emit = '';
                    }

                    const rem = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/rem/xNome',
                    );

                    if (rem.length > 0) {
                        documentsCte.rem = rem[0].value || '';
                    } else {
                        documentsCte.rem = '';
                    }

                    const dest = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/dest/xNome',
                    );

                    if (dest.length > 0) {
                        documentsCte.dest = dest[0].value || '';
                    } else {
                        documentsCte.dest = '';
                    }
                });

                dispatch({
                    type: actionTypes.INVOICES_SEARCH_GET_LIST_SUCCESS,
                    documentsSearch: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_SEARCH_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsSearchCount = (value, documentType, filter) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICES_SEARCH_GET_COUNT_REQUEST,
        });

        await axios
            .get(
                `/Documents/${encodeURIComponent(
                    value,
                )}/Search/${documentType}/Count` + filter,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICES_SEARCH_GET_COUNT_SUCCESS,
                    documentsSearchCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_SEARCH_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsReport = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICES_REPORT_GET_LIST_REQUEST,
        });

        axios
            .get('/Documents')
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICES_REPORT_GET_LIST_SUCCESS,
                    documentsReport: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_REPORT_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsXmlReportZip = (beginDate, endDate) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICE_GET_XML_ZIP_REQUEST,
        });

        await axios
            .get(
                `/Documents/CteXmlReportZip?beginDate=${beginDate}&endDate=${endDate}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            )
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_GET_XML_ZIP_SUCCESS,
                    xmlFilesZip: response.data,
                });

                const disposition = response.request.getResponseHeader(
                    'Content-Disposition',
                );
                var fileName = '';
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
                let blob = new Blob([response.data], {
                    type: 'application/zip',
                });

                const downloadUrl = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_GET_XML_ZIP_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentDetails = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/Documents/${documentId}`)
            .then(function (response) {
                const document = response && response.data;
                const form = document.form || {};
                const fields = form.fields || [];

                const dates = fields.filter(field => field.type === 'DateTime');
                dates.forEach(field => {
                    if (field.value.length === 10) {
                        const num = Number(field.value);
                        if (num) {
                            field.value = new Date(num * 1000).toLocaleString(
                                navigator.language,
                            );
                        }
                    }
                });

                document.steps = document.steps.map(s => {
                    return { ...s, createdAt: new Date(s.createdAt) };
                });

                dispatch({
                    type: actionTypes.INVOICE_GET_DETAILS_SUCCESS,
                    document,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const createDocument = (payload, pushUrl) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_UPLOAD_FILE_REQUEST,
        });
        let endPointUrl = `/Documents`;

        if (!!payload.isBoletoAttachment) {
            endPointUrl = `/Documents/Attachment/Boleto`;
        } else if (!!payload.isRawFile) {
            endPointUrl = `/Documents/Attachment/RawFile`;
        }

        delete payload.isBoletoAttachment;

        const orderItemQuery = !!payload.orderItemsList
            ? `${payload.orderItemsList.join(',')}`
            : null;

        const data = new FormData();

        if (!!payload.isRawFile) {
            const files = payload.file;
            for (var x = 0; x < files.length; x++) {
                data.append('file', files[x]);
            }

            delete payload.file;
            delete payload.isRawFile;
        }

        payload.orderItemsList = orderItemQuery;
        const objectPayload = Object.entries(payload);

        objectPayload.forEach(item => {
            let [key, value] = item;
            value && data.append(key, value);
        });

        axios
            .post(endPointUrl, data)
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_UPLOAD_FILE_SUCCESS,
                });

                toast.success('Upload realizado com sucesso');
                dispatch(push(pushUrl || '/documents?'));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_UPLOAD_FILE_FAILURE,
                    error,
                });
            });
    };
};

export const updateDocumentFormFields = (
    documentId,
    values,
    orderId,
    isDraft,
    isAttachment,
    documentType,
    selectedCategoryMap
) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_PUT_REQUEST,
        });

        axios
            .put(`/${documentType === 'OTHER' || documentType === 'NFS' ? 'Documents' : documentType}/${documentId}/FormFields`, {
                ...values,
                orderId,
                isDraft,
                selectedCategoryMap
            })
            .then(function (response) {
                dispatch({
                    type: actionTypes.INVOICE_PUT_SUCCESS,
                });
                if (!isDraft) {
                    toast.success('Nota fiscal enviada com sucesso');
                } else {
                    toast.success('Nota fiscal salva com sucesso');
                }

                if (isAttachment) window.location.reload();
                switch (documentType) {
                    case 'CTe':
                        dispatch(push(`/cteDocuments?`));
                        break;
                    case 'NFe':
                        dispatch(push(`/nfeDocuments?`));
                        break;
                    case 'OTHER':
                        dispatch(push(`/otherDocuments?`));
                        break;
                    default:
                        dispatch(push(`/documents?`));
                        break;
                }
            })
            .catch(function (error) {
                const errorCode = error?.response?.data?.errorCode;

                if (errorCode === 10002 || errorCode === 10005) {
                    const errors = Object.entries(
                        error?.response?.data?.errors,
                    );
                    const errorsArray = [];

                    errors?.length > 0 &&
                        errors.forEach(error => {
                            let [key, message] = error;
                            const field = message
                                .replace('Field', '')
                                .replace(' is required.', '')
                                .replace(' is invalid.', '');

                            errorsArray.push(field);
                        });

                    let message = `Erro ao atualizar os campos:${errorsArray.join(
                        ', ',
                    )} `;

                    const requiredText =
                        errorsArray.length === 1
                            ? 'é obrigatório.'
                            : 'são obrigatórios.';

                    const invalidText =
                        errorsArray.length === 1
                            ? 'é inválido.'
                            : 'são inválidos.';

                    switch (errorCode) {
                        case 10002:
                            message = message + requiredText;
                            error.response.data.errorCode = 101;
                            error.response.data.message = message;
                            break;

                        case 10005:
                            message = message + invalidText;
                            error.response.data.errorCode = 101;
                            error.response.data.message = message;
                            break;

                        default:
                            break;
                    }
                }

                dispatch({
                    type: actionTypes.INVOICE_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const reportCancelDocument = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_GET_REPORT_CANCEL_REQUEST,
            documentId,
        });
        axios
            .get(`/documents/${documentId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_REPORT_CANCEL_SUCCESS,
                });
                dispatch(goBack());
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_REPORT_DOCUMENT_CANCEL,
                    error,
                });
                toast.error('Ocorreu um erro ao informar o cancelamento');
            });
    };
};

export const cancelDocument = (documentId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_CANCEL_DOCUMENT_REQUEST,
        });

        axios
            .patch(`/Documents/${documentId}/Steps`, { ...values })
            .then(function (response) {
                dispatch({
                    type: actionTypes.INVOICE_CANCEL_DOCUMENT_SUCCESS,
                });
                toast.success('Nota fiscal cancelada com sucesso');
                dispatch(goBack());
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.INVOICE_CANCEL_DOCUMENT_FAILURE,
                    error,
                });
            });
    };
};

export const informCancelIssuer = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_INFORM_CANCEL_ISSUER_REQUEST,
        });

        axios
            .patch(`/Documents/${documentId}/Cancel`)
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_INFORM_CANCEL_ISSUER_SUCCESS,
                });
                toast.success('Cancelamento da NFS informada.');
                dispatch(goBack());
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_INFORM_CANCEL_ISSUER_FAILURE,
                    error,
                });
            });
    };
};

export const updateFilterDocumentsType = (documentType = 2) => {
    return dispatch => {
        axios
            .get(`/Documents/SearchFields/${documentType}`)
            .then(function ({ data }) {
                dispatch({
                    type: actionTypes.INVOICE_GET_FILTER_DOCUMENTS_TYPE,
                    filtersDocumentType: data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.INVOICE_CANCEL_DOCUMENT_FAILURE,
                    error,
                });
            });
    };
};

export const getActiveTab = tabIndex => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_GET_ACTIVE_TAB,
            tabIndex,
        });
    };
};

export const updateActiveTab = (documentsId, tabIndex) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_UPDATE_ACTIVE_TAB,
            documentsId,
            tabIndex,
        });
        dispatch(push(`/documents/${documentsId}`));
    };
};

export const initDocumentsNfseFilter = (filters, skip, take, sort) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICES_GET_LIST_REQUEST,
        });

        await axios
            .post(
                `/Documents/NFS/Filter?skip=${skip}&take=${take}&sort=${sort}`,
                filters,
            )
            .then(response => {
                response.data.forEach(document => {
                    const form = document.form || {};
                    const fields = form.fields || [];

                    const numbers = fields.filter(
                        field => field.key === 'invoiceNumber',
                    );

                    document.invoiceNumber = numbers?.[0]?.value || '';
                });

                dispatch({
                    type: actionTypes.INVOICES_GET_LIST_SUCCESS,
                    documents: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsNfseFilterCount = filters => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICE_GET_COUNT_REQUEST,
        });

        await axios
            .post(`/Documents/NFS/Filter/Count`, filters)
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_GET_COUNT_SUCCESS,
                    documentsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsOtherFilter = (filters, skip, take, sort) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICES_OTHER_GET_LIST_REQUEST,
        });

        axios
            .post(
                `/Documents/OTHER/Filter?skip=${skip}&take=${take}&sort=${sort}`,
                filters,
            )
            .then(response => {
                response.data.forEach(document => {
                    const form = document.form || {};
                    const fields = form.fields || [];

                    const numbers = fields.filter(
                        field => field.key === 'invoiceNumber',
                    );

                    document.invoiceNumber = numbers?.[0]?.value || '';
                });

                dispatch({
                    type: actionTypes.INVOICES_OTHER_GET_LIST_SUCCESS,
                    documentsOther: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_OTHER_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsOtherFilterCount = filters => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICE_OTHER_GET_COUNT_REQUEST,
        });

        await axios
            .post(`/Documents/OTHER/Filter/Count`, filters)
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_OTHER_GET_COUNT_SUCCESS,
                    documentsOtherCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_OTHER_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsCteFilterCount = filters => {
    return async dispatch => {
        dispatch({
            type: actionTypes.INVOICE_CTE_GET_COUNT_REQUEST,
        });

        await axios
            .post(`/Documents/CTE/Filter/Count`, filters)
            .then(response => {
                dispatch({
                    type: actionTypes.INVOICE_CTE_GET_COUNT_SUCCESS,
                    documentsCteCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICE_CTE_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsCteFilter = (filters, skip, take, sort) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICES_CTE_GET_LIST_REQUEST,
        });

        axios
            .post(
                `/Documents/CTE/Filter?skip=${skip}&take=${take}&sort=${sort}`,
                filters,
            )
            .then(response => {
                response.data.forEach(documentsCte => {
                    const form = documentsCte.form || {};
                    const fields = form.fields || [];

                    const numbers = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/ide/nCT',
                    );

                    if (numbers.length > 0) {
                        documentsCte.invoiceNumber = numbers[0].value || '';
                    } else {
                        documentsCte.invoiceNumber = '';
                    }

                    const infDoc = fields.filter(
                        field => field.key === '/cteProc/protCTe/infProt/chCTe',
                    );

                    if (infDoc.length > 0) {
                        documentsCte.infDoc = infDoc[0].value || '';
                    } else {
                        documentsCte.infDoc = '';
                    }

                    const emit = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/emit/xNome',
                    );

                    if (emit.length > 0) {
                        documentsCte.emit = emit[0].value || '';
                    } else {
                        documentsCte.emit = '';
                    }

                    const rem = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/rem/xNome',
                    );

                    if (rem.length > 0) {
                        documentsCte.rem = rem[0].value || '';
                    } else {
                        documentsCte.rem = '';
                    }

                    const dest = fields.filter(
                        field => field.key === '/cteProc/CTe/infCte/dest/xNome',
                    );

                    if (dest.length > 0) {
                        documentsCte.dest = dest[0].value || '';
                    } else {
                        documentsCte.dest = '';
                    }
                });

                dispatch({
                    type: actionTypes.INVOICES_CTE_GET_LIST_SUCCESS,
                    documentsCte: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INVOICES_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const setQuery = query => {
    return dispatch => {
        dispatch({
            type: actionTypes.QUERY_SET_REQUEST,
            query: query,
        });
    };
};

//OrderDocuments
export const getOrdersDocument = (documentId, filter) => {
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_ORDERS_GET_REQUEST,
        });

        axios
            .get(`/Documents/${documentId}/OrderItems` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_GET_SUCCESS,
                    ordersDocument: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_GET_FAILURE,
                    error,
                });
            });
    };
};

export const getOrdersDocumentCount = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_ORDERS_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Documents/${documentId}/OrderItems/Count`)
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_GET_COUNT_SUCCESS,
                    ordersDocumentCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const updateOrdersDocument = (
    documentId,
    orderItemId,
    docType,
    functionDispatch,
) => {
    return (dispatch, getState) => {
        const supplierId =
            getState().documentReducer.selectedDocument?.supplierId || null;

        dispatch({
            type: actionTypes.DOCUMENT_ORDERS_PUT_REQUEST,
        });

        axios
            .put(
                `/${docType ||
                    'Documents'}/${documentId}/OrderItems/${orderItemId}`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_PUT_SUCCESS,
                });

                if (!!functionDispatch) {
                    functionDispatch();
                } else {
                    switch (docType) {
                        case 'NFE':
                            initNfeOrders(
                                documentId,
                                '?sort=-createdAt&skip=0&take=5',
                            )(dispatch);
                            initNfeOrdersCount(documentId)(dispatch);
                            break;

                        case 'CTE':
                            initCteOrders(
                                documentId,
                                '?sort=-createdAt&skip=0&take=5',
                            )(dispatch);
                            initNfeOrdersCount(documentId)(dispatch);
                            break;

                        default:
                            getOrdersDocument(
                                documentId,
                                '?sort=-createdAt&skip=0&take=5',
                            )(dispatch);
                            getOrdersDocumentCount(documentId)(dispatch);
                            break;
                    }
                    initOrderItemsBySupplier(
                        supplierId,
                        '?sort=-createdAt&skip=0&take=100&page=0',
                    )(dispatch, getState);
                }

                toast.success(
                    `${orderItemId.split(',')?.length ||
                        'Os'} itens de pedidos foram associados`,
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteOrdersDocument = (
    documentId,
    orderItemId,
    docType,
    functionDispatch,
) => {
    return (dispatch, getState) => {
        const supplierId =
            getState().documentReducer.selectedDocument?.supplierId || null;

        dispatch({
            type: actionTypes.DOCUMENT_ORDERS_DELETE_REQUEST,
        });

        axios
            .delete(
                `/${docType ||
                'Documents'}/${documentId}/OrderItems/${orderItemId}`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_DELETE_SUCCESS,
                    ordersDocument: response.data,
                });

                if (!!functionDispatch) {
                    functionDispatch();
                } else {
                    switch (docType) {
                        case 'NFE':
                            initNfeOrders(
                                documentId,
                                '?sort=-createdAt&skip=0&take=5',
                            )(dispatch);
                            initNfeOrdersCount(documentId)(dispatch);
                            break;

                        case 'CTE':
                            initCteOrders(
                                documentId,
                                '?sort=-createdAt&skip=0&take=5',
                            )(dispatch);
                            initNfeOrdersCount(documentId)(dispatch);
                            break;

                        default:
                            getOrdersDocument(
                                documentId,
                                '?sort=-createdAt&skip=0&take=5',
                            )(dispatch);
                            getOrdersDocumentCount(documentId)(dispatch);
                            break;
                    }

                    initOrderItemsBySupplier(
                        supplierId,
                        '?sort=-createdAt&skip=0&take=100&page=0',
                    )(dispatch, getState);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_DELETE_FAILURE,
                    error,
                });
            });
    };
};

//associatedDocuments
export const initAssociatedDocuments = (documentId, filter, docType) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ASSOCIATED_DOCUMENTS_GET_LIST_REQUEST,
        });

        axios
            .get(
                `/${docType ||
                'Documents'}${filter}&filter[parentDocumentId]=eq:${documentId}`,
            )
            .then(response => {
                response.data.forEach(document => {
                    const fields = document.form.fields || {};

                    const numbers = fields.filter(
                        field => field.key === 'invoiceNumber',
                    );

                    document.invoiceNumber = numbers?.[0]?.value || '';
                });

                dispatch({
                    type: actionTypes.ASSOCIATED_DOCUMENTS_GET_LIST_SUCCESS,
                    associatedDocuments: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ASSOCIATED_DOCUMENTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initAssociatedDocumentsCount = (documentId, docType) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.ASSOCIATED_DOCUMENTS_GET_COUNT_REQUEST,
        });

        await axios
            .get(
                `/${docType ||
                'Documents'}/Count?sort=-createdAt&skip=0&take=10&page=0&filter[parentDocumentId]=eq:${documentId}`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.ASSOCIATED_DOCUMENTS_GET_COUNT_SUCCESS,
                    associatedDocumentsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ASSOCIATED_DOCUMENTS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const getParentChildValues = (documentId, docType) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.PARENT_CHILD_VALUES_GET_REQUEST,
            parentChildValues: {
                totalChildValue: 0,
                childValues: [],
                documentValue: 0,
            },
        });

        await axios
            .get(
                `/${docType ||
                'Documents'}/Parent/${documentId}/TotalChildValue`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.PARENT_CHILD_VALUES_GET_SUCCESS,
                    parentChildValues: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.PARENT_CHILD_VALUES_GET_FAILURE,
                    error,
                });
            });
    };
};

export const cleanSelectedDocument = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.INVOICE_GET_DETAILS_SUCCESS,
            document: null,
        });
    };
};

export const cleanValidationErrors = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.CLEAN_VALIDATION_ERRORS,
            validationErrors: [],
        });
    };
};

export const returnToReviewStep = documentId => {
    return async dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_RETURN_STEP_REQUEST,
        });

        await axios
            .patch(`/Documents/${documentId}/ReturnToReviewStep`)
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_RETURN_STEP_SUCCESS,
                });
                toast.success(
                    'Status do documento alterado para aguardando revisão',
                );
                dispatch(goBack());
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_RETURN_STEP_FAILURE,
                    error,
                });
            });
    };
};

//associatedDocuments
export const initdocumentHistory = (documentId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.TIMELINE_DOCUMENT_GET_LIST_REQUEST,
        });

        axios
            .get(
                `/Documents/History/${documentId}`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.TIMELINE_DOCUMENT_GET_LIST_SUCCESS,
                    documentHistory: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.TIMELINE_DOCUMENT_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};