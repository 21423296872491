import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    contractorName: {
        id: 'documentFields.contractorName',
        defaultMessage: 'Contratante',
    },
    name: {
        id: 'documentFields.name',
        defaultMessage: 'Nome',
    },
    key: {
        id: 'documentFields.key',
        defaultMessage: 'Key',
    },
    documentType: {
        id: 'documentFields.documentType',
        defaultMessage: 'Tipo do documento',
    },
    categoryType: {
        id: 'documentFields.categoryType',
        defaultMessage: 'Categoria do Documento',
    },
    activeCustom: {
        id: 'documentFields.active',
        defaultMessage: 'Ativo',
    },
    title: {
        id: 'documentFields.title',
        defaultMessage: 'Campos dos Documentos',
    },
    titleDocumentsConfigs: {
        id: 'documentFields.titleDocumentsConfigs',
        defaultMessage: 'Configurações de Documentos',
    },
    categoriesTitle: {
        id: 'documentFields.categoriesTitle',
        defaultMessage: 'Categorias',
    },
    tabMapFields: {
        id: 'documentFields.titleMapFields',
        defaultMessage: 'Campos de Mapeamentos',
    },
    field: {
        id: 'documentFields.field',
        defaultMessage: 'Campo',
    },
    subtitle: {
        id: 'documentFields.subtitle',
        defaultMessage: ' {length} campos encontrados',
    },
    newField: {
        id: 'documentFields.newField',
        defaultMessage: 'Campo',
    },

    newButtonText: {
        id: 'global.newButtonText',
        defaultMessage: 'Nova {entity}',
    },
    titleInvoice: {
        id: 'invoice.titleInvoice',
        defaultMessage: 'Documentos',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    code: {
        id: 'documentFields.code',
        defaultMessage: 'Código',
    },
    newCategoryText: {
        id: 'documentFields.newCategoryText',
        defaultMessage: 'Nova Categoria',
    },
});

export default messages;
