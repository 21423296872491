import React, { useState, useEffect, useMemo, useContext } from 'react';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, withRouter } from 'react-router';

import messages from '../NfeListPage.intl';

import EvtTable from '../../../components/EvtTable/EvtTable';
import HeaderTable from '../../../components/HeaderTable/HeaderTable';
import { columnsNfe, nfeProperties } from '../components/ColumnsNfe';

import { formatFilters } from '../../DocumentListPage/DocumentListPage';

function NfeDocumentList(props) {
    const {
        intl,
        hasFull,
        newNfeDocumentButtonEnabled,
        onInitDocumentsNfe,
        documentsNfe,
        documentsNfeCount,
        loadingNfeList,
        handleNewDocumentButtonClick,
        handleGetTrProps,
        activeTab,
    } = props;
    const history = useHistory();

    const [query, setQuery] = useState('');
    const [filtersNfe, setFiltersNfe] = useState({
        filters: [],
        filterValue: '',
    });

    //Mount Page
    useEffect(() => {
        const URL = window.location.search;

        if (URL.includes('&search=')) {
            const filtersURL = formatFilters(URL);
            const value = filtersURL.shift().value;
            setFiltersNfe({ ...filtersNfe, filterValue: value });
        }
    }, []);

    //Update Page
    useEffect(() => {
        reloadNfeList();
    }, [query]);

    function reloadNfeList() {
        const queryIndex = query?.includes('&index=')
            ? query
            : query + `&index=${activeTab}`;

        if (query?.includes('take')) {
            onInitDocumentsNfe(query, filtersNfe.filterValue);

            history.push('/nfeDocuments' + queryIndex);
        }
    }

    const handleFiltersNfeChange = (newFiltersNfe, value) =>
        setFiltersNfe({ filters: newFiltersNfe, filterValue: value });

    function handleEvTableStateChangeNfe(queryTable) {
        setQuery(queryTable);
    }

    return (
        <>
            <HeaderTable
                title={intl.formatMessage(messages.title)}
                properties={nfeProperties(intl, hasFull)}
                handleFiltersChange={handleFiltersNfeChange}
                loading={loadingNfeList}
                onClickReload={() => reloadNfeList()}
                onClickNewButton={handleNewDocumentButtonClick}
                newButtonEnabled={newNfeDocumentButtonEnabled}
                titleNewButton={intl.formatMessage(messages.nfeTab)}
                isAlternativeFilter
                documentFieldButtonEnabled
            />
            <section>
                <EvtTable
                    filters={filtersNfe.filters}
                    columns={columnsNfe(documentsNfe, intl, hasFull)}
                    data={documentsNfe}
                    length={documentsNfeCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeNfe}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingNfeList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: documentsNfeCount,
                    })}
                    search
                />
            </section>
        </>
    );
}

const mapStateToProps = state => {
    return {
        documentsNfe: state.NFeReducer.documentsNfe || [],
        documentsNfeCount: state.NFeReducer.documentsNfeCount || 0,
        loadingNfeList: state.NFeReducer.loadingNfeList || false,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //Filters
        onInitDocumentsNfe: (filters, value) =>
            dispatch(actions.initDocumentsNfe(filters, value)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(NfeDocumentList)),
);
