import { toast } from 'react-toastify';
import { goBack, push } from 'connected-react-router';
import * as actionTypes from './actionTypes';
import axios from 'axios';

//#region Nfe
export const initDocumentNfeCreate = payload => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_DOCUMENT_CREATE_REQUEST,
        });

        // Endpoint selector for chosen functionality
        let endPointUrl = `/NFe`;
        if (!!payload.isRawFile) {
            endPointUrl = `/NFe/Attachment/RawFile`;
        }

        delete payload.isRawFile;

        // Data to form request
        const data = new FormData();
        const objectPayload = Object.entries(payload);

        objectPayload.forEach(item => {
            let [key, value] = item;
            value && data.append(key, value);
        });

        axios
            .post(`${endPointUrl}`, data)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_DOCUMENT_CREATE_SUCCESS,
                });

                toast.success('Upload realizado com sucesso');
                dispatch(goBack());
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_DOCUMENT_CREATE_FAILURE,
                    error,
                });
            });
    };
};
export const initDocumentsNfe = (query, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.NFE_DOCUMENTS_GET_LIST_REQUEST,
        });

        axios
            .get(`/NFe/SummaryView${query}` + search)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_DOCUMENTS_GET_LIST_SUCCESS,
                    documentsNfe: response.data.result,
                    documentsNfeCount: response.data.count,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_DOCUMENTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsNfeDetails = nfeDocumentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_DOCUMENTS_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/NFe/${nfeDocumentId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_DOCUMENTS_GET_DETAILS_SUCCESS,
                    nfeDocument: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_DOCUMENTS_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const initSummaryNfe = (query, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.NFE_SUMMARY_GET_LIST_REQUEST,
        });

        axios
            .get(`/NFe/Summary${query}` + search)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_SUMMARY_GET_LIST_SUCCESS,
                    summaryNfe: response.data.result,
                    summaryNfeCount: response.data.count,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_SUMMARY_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};
//#endregion Nfe

//#region NfeManifest
export const initAwarenessSummary = (summaryId, summaryModel) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_MANIFEST_ACTION_REQUEST,
        });

        axios
            .post(`/NFe/Summary/${summaryId}/Aware`, { ...summaryModel })
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_SUCCESS,
                });
                dispatch(initSummaryNfe(window.location.search, ''));
                toast.success('Manifestado ciência do resumo');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_FAILURE,
                    error,
                });
            });
    };
};

export const initDefineUnknownSummary = (summaryId, summaryModel) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_MANIFEST_ACTION_REQUEST,
        });

        axios
            .post(`/NFe/Summary/${summaryId}/Unaware`, { ...summaryModel })
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_SUCCESS,
                });
                dispatch(initSummaryNfe(window.location.search, ''));
                toast.success('Manifestado desconhecimento do resumo');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_FAILURE,
                    error,
                });
            });
    };
};

export const initSearchNFe = summaryModel => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_MANIFEST_ACTION_REQUEST,
        });

        axios
            .post(`/NFe/Summary/XmlByKey`, { ...summaryModel })
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_SUCCESS,
                });
                dispatch(initSummaryNfe(window.location.search, ''));
                toast.success(
                    `O Documento ${response.data?.message ||
                        'XXXXXX'} foi inserido com sucesso no portal`,
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_FAILURE,
                    error,
                });
            });
    };
};

export const initManifestAccept = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_MANIFEST_ACTION_REQUEST,
        });

        axios
            .post(`/NFe/${documentId}/Accept`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_SUCCESS,
                });
                dispatch(initDocumentsNfeDetails(documentId));
                toast.success('Manifestado a confirmação da operação');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_FAILURE,
                    error,
                });
            });
    };
};

export const initManifestUnaware = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_MANIFEST_ACTION_REQUEST,
        });

        axios
            .post(`/NFe/${documentId}/Unaware`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_SUCCESS,
                });
                dispatch(initDocumentsNfeDetails(documentId));
                toast.success('Manifestado o desconhecimento');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_FAILURE,
                    error,
                });
            });
    };
};

export const initManifestOperationNotRealized = (documentId, justificative) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_MANIFEST_ACTION_REQUEST,
        });

        axios
            .post(
                `/NFe/${documentId}/OperationNotRealized?justificative=${justificative}`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_SUCCESS,
                });
                dispatch(initDocumentsNfeDetails(documentId));
                toast.success('Manifestado a operação não realizada');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_MANIFEST_ACTION_FAILURE,
                    error,
                });
            });
    };
};
//#endregion NfeManifest

//#region Nfe Items
export const initNfeItems = (documentId, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_ITEMS_GET_LIST_REQUEST,
        });

        axios
            .get(`NFe/${documentId}/Items${query}`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_ITEMS_GET_LIST_SUCCESS,
                    nfeItems: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_ITEMS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initNfeItemsCount = (documentId, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_ITEMS_GET_COUNT_REQUEST,
        });

        axios
            .get(`NFe/${documentId}/Items/Count${query}`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_ITEMS_GET_COUNT_SUCCESS,
                    nfeItemsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_ITEMS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initNfeItemDetails = nfeItemId => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_ITEMS_GET_DETAILS_REQUEST,
        });

        axios
            .get(`NFe/${nfeItemId}/Items/Details`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_ITEMS_GET_DETAILS_SUCCESS,
                    nfeItemDetails: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_ITEMS_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};
//#endregion Nfe Items

// #region Nfe Orders
export const initNfeOrders = (documentId, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_ORDERS_GET_LIST_REQUEST,
        });

        axios
            .get(`NFe/${documentId}/OrderItems${query}`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_ORDERS_GET_LIST_SUCCESS,
                    nfeOrders: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_ORDERS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initNfeOrdersCount = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_ORDERS_GET_COUNT_REQUEST,
        });

        axios
            .get(`NFe/${documentId}/OrderItems/Count`)
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_ORDERS_GET_COUNT_SUCCESS,
                    nfeOrdersCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NFE_ORDERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initAddNfeOrderItems = (
    documentId,
    nfeOrderItems,
    isContinueModal = false,
) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_ORDERS_UPDATE_REQUEST,
        });

        axios
            .put(`NFe/${documentId}/OrderItems`, { orderItems: nfeOrderItems })
            .then(response => {
                dispatch({
                    type: actionTypes.NFE_ORDERS_UPDATE_SUCCESS,
                    isSuccessModal: true,
                    isContinueModal,
                });
                toast.success(
                    `${nfeOrderItems?.length ||
                        'Os'} itens de pedidos foram associados`,
                );
                initNfeOrders(documentId, '?sort=-createdAt&skip=0&take=1000')(
                    dispatch,
                );
                initNfeOrdersCount(documentId)(dispatch);
            })
            .catch(error => {
                console.log({ nfeError: error });
                dispatch({
                    type: actionTypes.NFE_ORDERS_UPDATE_FAILURE,
                    isSuccessModal: false,
                    error,
                });
            });
    };
};

export const initRemoveNfeOrderItems = (documentId, nfeItemId, orderItemId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_ORDERS_DELETE_REQUEST,
        });

        axios
            .delete(
                `NFe/${documentId}/NfeItems/${nfeItemId}/OrderItems/${orderItemId}`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_DELETE_SUCCESS,
                });
                initNfeOrders(documentId, '?sort=-createdAt&skip=0&take=1000')(
                    dispatch,
                );
                initNfeOrdersCount(documentId)(dispatch);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_ORDERS_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const cleanIsSuccessCloseModal = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_ORDERS_UPDATE_SUCCESS,
            isSuccessModal: false,
            isContinueModal: false,
        });
    };
};

// #endregion Nfe Orders

// #region NfeActions

export const cleanNfeDocument = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.NFE_DOCUMENTS_GET_DETAILS_SUCCESS,
            nfeDocument: null,
        });
    };
};

// #enregion NfeActions
