import React from 'react';
import { Badge } from 'reactstrap';
import { calculateColumnWidth } from '../../../components/EvtTable/EvtTable';
import messages from '../IntegrationListPage.intl';

export const columnsNfs = (integrations, intl) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.supplierCNPJ)}</span>
            </div>
        ),
        accessor: 'supplierCNPJ',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            160 +
            calculateColumnWidth(
                integrations,
                'supplierCNPJ',
                intl.formatMessage(messages.supplierCNPJ),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                integrations,
                'supplierCNPJ',
                intl.formatMessage(messages.supplierCNPJ),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.contractorCNPJ)}</span>
            </div>
        ),
        accessor: 'contractorCNPJ',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            160 +
            calculateColumnWidth(
                integrations,
                'contractorCNPJ',
                intl.formatMessage(messages.contractorCNPJ),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                integrations,
                'contractorCNPJ',
                intl.formatMessage(messages.contractorCNPJ),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.beginDate)}</span>
            </div>
        ),
        accessor: 'beginDate',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        maxWidth:
            40 +
            calculateColumnWidth(
                integrations,
                'beginDate',
                intl.formatMessage(messages.beginDate),
            ),
        minWidth: calculateColumnWidth(
            integrations,
            'beginDate',
            intl.formatMessage(messages.beginDate),
        ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.endDate)}</span>
            </div>
        ),
        accessor: 'endDate',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        maxWidth:
            40 +
            calculateColumnWidth(
                integrations,
                'endDate',
                intl.formatMessage(messages.endDate),
            ),
        minWidth: calculateColumnWidth(
            integrations,
            'endDate',
            intl.formatMessage(messages.endDate),
        ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        id: 'message',
        style: {
            fontSize: 'small',
        },
        accessor: d => {
            let classStatus = 'status-invoice ';
            let status;
            let rowText;
            let color = '';

            if (d.isSuccess) {
                classStatus += 'status-success';
                color += 'success';
                if (d.message) {
                    status = intl.formatMessage(messages.statusSuccess);
                    rowText = d.message;
                } else {
                    status = intl.formatMessage(messages.statusSuccess);
                }
                return (
                <div>
                  <Badge 
                  style={{ fontSize: '0.8rem', color: '#ffffff' }} 
                  color={color} 
                  pill
                  >
                    {status}
                </Badge>
                  <span style={{ marginLeft: 10 }}>{rowText}</span>
                </div>
            );
            } else {
                classStatus += 'status-error';
                color += 'danger';
                status = intl.formatMessage(messages.statusFailed)
                rowText = d.message;
                return (
                    <div>
                      <Badge 
                      style={{ fontSize: '0.8rem', color: '#ffffff', display: 'inline-block', width: '63px', textAlign: 'center' }} 
                      color={color} 
                      pill
                      >
                        {status}
                    </Badge>
                      <span style={{ marginLeft: 10 }}>{rowText}</span>
                    </div>
                );
            }
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                integrations,
                'message',
                intl.formatMessage(messages.status),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                integrations,
                'message',
                intl.formatMessage(messages.status),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ibgeCode)}</span>
            </div>
        ),
        accessor: 'ibgeCityCode',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            80 +
            calculateColumnWidth(
                integrations,
                'ibgeCityCode',
                intl.formatMessage(messages.ibgeCode),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                integrations,
                'ibgeCityCode',
                intl.formatMessage(messages.ibgeCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.executionDate)}</span>
            </div>
        ),
        accessor: 'executionDate',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        maxWidth:
            40 +
            calculateColumnWidth(
                integrations,
                'executionDate',
                intl.formatMessage(messages.executionDate),
            ),
        minWidth: calculateColumnWidth(
            integrations,
            'executionDate',
            intl.formatMessage(messages.executionDate),
        ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.cityName)}</span>
            </div>
        ),
        accessor: 'cityName',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            160 +
            calculateColumnWidth(
                integrations,
                'cityName',
                intl.formatMessage(messages.cityName),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                integrations,
                'cityName',
                intl.formatMessage(messages.cityName),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.uf)}</span>
            </div>
        ),
        accessor: 'uf',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            160 +
            calculateColumnWidth(
                integrations,
                'uf',
                intl.formatMessage(messages.uf),
            ),
        minWidth:
            45 +
            calculateColumnWidth(
                integrations,
                'uf',
                intl.formatMessage(messages.uf),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.amountDocumentsReturned)}
                </span>
            </div>
        ),
        accessor: 'amountDocumentsReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            120 +
            calculateColumnWidth(
                integrations,
                'amountDocumentsReturned',
                intl.formatMessage(messages.amountDocumentsReturned),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                integrations,
                'amountDocumentsReturned',
                intl.formatMessage(messages.amountDocumentsReturned),
            ),
    },
];

export const columnsLogs = (logs, intl) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.type)}</span>
            </div>
        ),
        accessor: 'type',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            160 +
            calculateColumnWidth(
                logs,
                'type',
                intl.formatMessage(messages.type),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                logs,
                'type',
                intl.formatMessage(messages.type),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.integrationDate)}</span>
            </div>
        ),
        accessor: 'date',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        maxWidth:
            160 +
            calculateColumnWidth(
                logs,
                'date',
                intl.formatMessage(messages.integrationDate),
            ),
        minWidth:
            30 +
            calculateColumnWidth(
                logs,
                'date',
                intl.formatMessage(messages.integrationDate),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        id: 'message',
        style: {
            fontSize: 'small',
        },
        accessor: d => {
            let classStatus = 'status-invoice ';
            let status;
            let rowText;
            let color = '';

            if (d.isSuccess) {
                classStatus += 'status-success';
                color += 'success';
                if (d.message) {
                    status = intl.formatMessage(messages.statusSuccess);
                    rowText = d.message;
                } else {
                    status = intl.formatMessage(messages.statusSuccess);
                }
                return (
                <div>
                  <Badge 
                  style={{ fontSize: '0.8rem', color: '#ffffff' }} 
                  color={color} 
                  pill
                  >
                    {status}
                </Badge>
                  <span style={{ marginLeft: 10 }}>{rowText}</span>
                </div>
            );
            } else {
                classStatus += 'status-error';
                color += 'danger';
                status = intl.formatMessage(messages.statusFailed)
                rowText = d.message;
                return (
                    <div>
                      <Badge 
                      style={{ fontSize: '0.8rem', color: '#ffffff', display: 'inline-block', width: '63px', textAlign: 'center' }} 
                      color={color} 
                      pill
                      >
                        {status}
                    </Badge>
                      <span style={{ marginLeft: 10 }}>{rowText}</span>
                    </div>
                );
            }
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                logs,
                'message',
                intl.formatMessage(messages.status),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                logs,
                'message',
                intl.formatMessage(messages.status),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.dataDescription)}</span>
            </div>
        ),
        accessor: 'dataDescription',
        style: {
            fontSize: 'small',
        },
        minWidth:
            60 +
            calculateColumnWidth(
                logs,
                'dataDescription',
                intl.formatMessage(messages.dataDescription),
            ),
    },
];

export const columnsNFe = (NFeLogs, intl) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.contractorCNPJ)}</span>
            </div>
        ),
        accessor: 'cnpj',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            80 +
            calculateColumnWidth(
                NFeLogs,
                'cnpj',
                intl.formatMessage(messages.contractorCNPJ),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFeLogs,
                'cnpj',
                intl.formatMessage(messages.contractorCNPJ),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.searchStartTimeDate)}</span>
            </div>
        ),
        accessor: 'searchDate',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const searchDate = new Date(row.value).toLocaleString(
                navigator.language,
            );
            return (
                <div className='customer-container'>
                    <div className=''>{searchDate}</div>
                </div>
            );
        },
        maxWidth:
            70 +
            calculateColumnWidth(
                NFeLogs,
                'searchDate',
                intl.formatMessage(messages.searchStartTimeDate),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFeLogs,
                'searchDate',
                intl.formatMessage(messages.searchStartTimeDate),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.lastNumber)}</span>
            </div>
        ),
        accessor: 'lastNSU',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            140 +
            calculateColumnWidth(
                NFeLogs,
                'lastNSU',
                intl.formatMessage(messages.lastNumber),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFeLogs,
                'lastNSU',
                intl.formatMessage(messages.lastNumber),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.lastNumberReturned)}</span>
            </div>
        ),
        accessor: 'lastNSUReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            140 +
            calculateColumnWidth(
                NFeLogs,
                'lastNSUReturned',
                intl.formatMessage(messages.lastNumberReturned),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFeLogs,
                'lastNSUReturned',
                intl.formatMessage(messages.lastNumberReturned),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalNFeReturn)}</span>
            </div>
        ),
        accessor: 'totalNFeReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            90 +
            calculateColumnWidth(
                NFeLogs,
                'totalNFeReturned',
                intl.formatMessage(messages.totalNFeReturn),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFeLogs,
                'totalNFeReturned',
                intl.formatMessage(messages.totalNFeReturn),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalSummaryReturned)}</span>
            </div>
        ),
        accessor: 'totalSummaryReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            110 +
            calculateColumnWidth(
                NFeLogs,
                'totalSummaryReturned',
                intl.formatMessage(messages.totalSummaryReturned),
            ),
        minWidth:
            90 +
            calculateColumnWidth(
                NFeLogs,
                'totalSummaryReturned',
                intl.formatMessage(messages.totalSummaryReturned),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ibgeCode)}</span>
            </div>
        ),
        accessor: 'ibgeUfCode',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                NFeLogs,
                'ibgeUfCode',
                intl.formatMessage(messages.ibgeCode),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFeLogs,
                'ibgeUfCode',
                intl.formatMessage(messages.ibgeCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        id: 'message',
        style: {
            fontSize: 'small',
        },
        accessor: d => {
            let classStatus = 'status-invoice ';
            let status;
            let rowText;
            let color = '';

            if (d.isSuccess) {
                classStatus += 'status-success';
                color += 'success';
                if (d.message) {
                    status = intl.formatMessage(messages.statusSuccess);
                    rowText = d.message;
                } else {
                    status = intl.formatMessage(messages.statusSuccess);
                }
            } else {
                classStatus += 'status-error';
                color += 'danger';
                status = intl.formatMessage(messages.statusFailed)
                rowText = d.message;
            }
            return (
                <div>
                  <Badge style={{ fontSize: '0.8rem', color: '#ffffff' }} color={color} pill>{status}</Badge>
                  <span style={{ marginLeft: 10 }}>{rowText}</span>
                </div>
            );
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                NFeLogs,
                'message',
                intl.formatMessage(messages.status),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                NFeLogs,
                'message',
                intl.formatMessage(messages.status),
            ),
    },
];

export const columnsCte = (CteLogs, intl) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.contractorCNPJ)}</span>
            </div>
        ),
        accessor: 'cnpj',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            80 +
            calculateColumnWidth(
                CteLogs,
                'cnpj',
                intl.formatMessage(messages.contractorCNPJ),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                CteLogs,
                'cnpj',
                intl.formatMessage(messages.contractorCNPJ),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.searchStartTimeDate)}</span>
            </div>
        ),
        accessor: 'searchDate',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const searchDate = new Date(row.value).toLocaleString(
                navigator.language,
            );
            return (
                <div className='customer-container'>
                    <div className=''>{searchDate}</div>
                </div>
            );
        },
        maxWidth:
            70 +
            calculateColumnWidth(
                CteLogs,
                'searchDate',
                intl.formatMessage(messages.searchStartTimeDate),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                CteLogs,
                'searchDate',
                intl.formatMessage(messages.searchStartTimeDate),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.lastNumber)}</span>
            </div>
        ),
        accessor: 'lastNSU',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            140 +
            calculateColumnWidth(
                CteLogs,
                'lastNSU',
                intl.formatMessage(messages.lastNumber),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                CteLogs,
                'lastNSU',
                intl.formatMessage(messages.lastNumber),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.lastNumberReturned)}</span>
            </div>
        ),
        accessor: 'lastNSUReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            140 +
            calculateColumnWidth(
                CteLogs,
                'lastNSUReturned',
                intl.formatMessage(messages.lastNumberReturned),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                CteLogs,
                'lastNSUReturned',
                intl.formatMessage(messages.lastNumberReturned),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalCountReturn)}</span>
            </div>
        ),
        accessor: 'totalCountReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            90 +
            calculateColumnWidth(
                CteLogs,
                'totalCountReturned',
                intl.formatMessage(messages.totalCountReturn),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                CteLogs,
                'totalCountReturned',
                intl.formatMessage(messages.totalCountReturn),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ibgeCode)}</span>
            </div>
        ),
        accessor: 'ibgeUfCode',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                CteLogs,
                'ibgeUfCode',
                intl.formatMessage(messages.ibgeCode),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                CteLogs,
                'ibgeUfCode',
                intl.formatMessage(messages.ibgeCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        id: 'message',
        style: {
            fontSize: 'small',
        },
        accessor: d => {
            let classStatus = 'status-invoice ';
            let status;
            let rowText;
            let color = '';

            if (d.isSuccess) {
                classStatus += 'status-success';
                color += 'success';
                if (d.message) {
                    status = intl.formatMessage(messages.statusSuccess);
                    rowText = d.message;
                } else {
                    status = intl.formatMessage(messages.statusSuccess);
                }
                return (
                <div>
                  <Badge 
                  style={{ fontSize: '0.8rem', color: '#ffffff' }} 
                  color={color} 
                  pill
                  >
                    {status}
                </Badge>
                  <span style={{ marginLeft: 10 }}>{rowText}</span>
                </div>
            );
            } else {
                classStatus += 'status-error';
                color += 'danger';
                status = intl.formatMessage(messages.statusFailed)
                rowText = d.message;
                return (
                    <div>
                      <Badge 
                      style={{ fontSize: '0.8rem', color: '#ffffff', display: 'inline-block', width: '63px', textAlign: 'center' }} 
                      color={color} 
                      pill
                      >
                        {status}
                    </Badge>
                      <span style={{ marginLeft: 10 }}>{rowText}</span>
                    </div>
                );
            }
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                CteLogs,
                'message',
                intl.formatMessage(messages.status),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                CteLogs,
                'message',
                intl.formatMessage(messages.status),
            ),
    },
];

export const columnsNFSeNacional = (NFSeNacionalLogs, intl) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.contractorCNPJ)}</span>
            </div>
        ),
        accessor: 'cnpj',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            80 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'cnpj',
                intl.formatMessage(messages.contractorCNPJ),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'cnpj',
                intl.formatMessage(messages.contractorCNPJ),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.searchStartTimeDate)}</span>
            </div>
        ),
        accessor: 'searchDate',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const searchDate = new Date(row.value).toLocaleString(
                navigator.language,
            );
            return (
                <div className='customer-container'>
                    <div className=''>{searchDate}</div>
                </div>
            );
        },
        maxWidth:
            70 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'searchDate',
                intl.formatMessage(messages.searchStartTimeDate),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'searchDate',
                intl.formatMessage(messages.searchStartTimeDate),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.lastNumber)}</span>
            </div>
        ),
        accessor: 'lastNSU',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            140 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'lastNSU',
                intl.formatMessage(messages.lastNumber),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'lastNSU',
                intl.formatMessage(messages.lastNumber),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.lastNumberReturned)}</span>
            </div>
        ),
        accessor: 'lastNSUReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            140 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'lastNSUReturned',
                intl.formatMessage(messages.lastNumberReturned),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'lastNSUReturned',
                intl.formatMessage(messages.lastNumberReturned),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalNFSeNacionalReturn)}</span>
            </div>
        ),
        accessor: 'totalCountReturned',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            90 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'totalCountReturned',
                intl.formatMessage(messages.totalNFSeNacionalReturn),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'totalCountReturned',
                intl.formatMessage(messages.totalNFSeNacionalReturn),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ibgeCode)}</span>
            </div>
        ),
        accessor: 'ibgeCode',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'ibgeCode',
                intl.formatMessage(messages.ibgeCode),
            ),
        minWidth:
            60 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'ibgeCode',
                intl.formatMessage(messages.ibgeCode),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        id: 'message',
        style: {
            fontSize: 'small',
        },
        accessor: d => {
            let classStatus = 'status-invoice ';
            let status;
            let rowText;
            let color = '';

            if (d.isSuccess) {
                classStatus += 'status-success';
                color += 'success';
                if (d.message) {
                    status = intl.formatMessage(messages.statusSuccess);
                    rowText = d.message;
                } else {
                    status = intl.formatMessage(messages.statusSuccess);
                }
                return (
                <div>
                  <Badge 
                  style={{ fontSize: '0.8rem', color: '#ffffff' }} 
                  color={color} 
                  pill
                  >
                    {status}
                </Badge>
                  <span style={{ marginLeft: 10 }}>{rowText}</span>
                </div>
            );
            } else {
                classStatus += 'status-error';
                color += 'danger';
                status = intl.formatMessage(messages.statusFailed)
                rowText = d.message;
                return (
                    <div>
                      <Badge 
                      style={{ fontSize: '0.8rem', color: '#ffffff', display: 'inline-block', width: '63px', textAlign: 'center' }} 
                      color={color} 
                      pill
                      >
                        {status}
                    </Badge>
                      <span style={{ marginLeft: 10 }}>{rowText}</span>
                    </div>
                );
            }
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'message',
                intl.formatMessage(messages.status),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                NFSeNacionalLogs,
                'message',
                intl.formatMessage(messages.status),
            ),
    },
];
