import { defineMessages } from "react-intl";

import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,
 
  subtitle: {
    id: "roles.subtitle",
    defaultMessage: " {length} perfil(is) encontrado(s)"
  },

  name: {
    id: "roles.name",
    defaultMessage: "Nome do perfil"
  },

  company: {
    id: "roles.company",
    defaultMessage: "Contratante"
  },

  home: {
    id: "home.title",
    defaultMessage: "Início"
  },

  title: {
    id: "roles.title",
    defaultMessage: "Perfis"
  },

    titleRole: {
    id: "roles.titleOnlyRole",
    defaultMessage: "Perfil"
  },

  titleCanCreate: {
    id: "roles.titleCanCreate",
    defaultMessage: "Permissão para criar"
  },

  tabPermission: {
    id: "roles.tabPermission",
    defaultMessage: "Permissões"
  },

  allowedRolesLabel: {
    id: "roles.allowedRolesLabel",
    defaultMessage: "Perfis liberados para criação"
  },

  submitButton: {
    id: "global.submitButton",
    defaultMessage: "Enviar"
  },
  
  updateButton: {
    id: "global.updateButton",
    defaultMessage: "Atualizar",
  },
  
});

export default messages;
