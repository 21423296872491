import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    companyTitle: {
        id: 'company.companyTitle',
        defaultMessage: 'Filial',
    },
    newCompany: {
        id: 'company.newCompany',
        defaultMessage: 'Nova Filial',
    },
    subtitleCompany: {
        id: 'company.subtitleCompany',
        defaultMessage: ' filiai(s) encontrada(s)',
    },
    name: {
        id: 'company.name',
        defaultMessage: 'Nome',
    },
    CNPJCompany: {
        id: 'company.CNPJCompany',
        defaultMessage: 'CNPJ Filial',
    },
    CNPJMatriz: {
        id: 'company.CNPJMatriz',
        defaultMessage: 'CNPJ Matriz',
    },
    state: {
        id: 'company.state',
        defaultMessage: 'Estado',
    },
    city: {
        id: 'company.city',
        defaultMessage: 'Cidade',
    },

    certificateStatus: {
        id: 'company.certificateStatus',
        defaultMessage: 'Status do certificado',
    },

    importCertificate: {
        id: 'company.importCertificate',
        defaultMessage: 'Importar Certificado',
    },

    updateCertificate: {
        id: 'company.updateCertificate',
        defaultMessage: 'Atualizar certificado',
    },

    password: {
        id: 'company.password',
        defaultMessage: 'Senha',
    },
    choiceOne: {
        id: 'company.choiceOne',
        defaultMessage: 'Escolha apenas um arquivo',
    },
    choiceFile: {
        id: 'company.choiceFile',
        defaultMessage: 'Escolha um arquivo',
    },
});

export default messages;
