import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'logs.title',
        defaultMessage: 'Logs',
    },

    subtitle: {
        id: 'logs.subtitle',
        defaultMessage: '{length} log(s) encontrado(s)',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    createdBy: {
        id: 'logs.createdBy',
        defaultMessage: 'Criado por',
    },

    message: {
        id: 'logs.message',
        defaultMessage: 'Mensagem',
    },

    filterButton: {
        id: 'global.filterButton',
        defaultMessage: 'Filtrar',
    },

    filterTitle: {
        id: 'global.filterTitle',
        defaultMessage: 'Filtro de {entity}',
    },
});

export default messages;
