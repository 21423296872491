import React, { useState, useEffect, useMemo, useContext } from 'react';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory, withRouter } from 'react-router';
import { Container } from 'reactstrap';

import messages from './NfeListPage.intl';

import { AuthContext } from '../../context/AuthContext';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Tabs from '../../components/Tabs/Tabs';
import ModalSupplier from '../../components/ModalSupplier/ModalSupplier';
import NfeDocumentList from './pages/NfeDocumentList';
import NfeSummaryList from './pages/NfeSummaryList';

function NfeListPage(props) {
    const {
        intl,
        onCleanSupplierMaps,
    } = props;

    const context = useContext(AuthContext);
    const history = useHistory();

    const hasFull = context.hasClaim('role-level-full');
    const newNfeDocumentButtonEnabled = context.hasClaim('nfe-document-create');

    const [activeTab, setActiveTab] = useState(0);
    const [showSupplierPicker, setShowSupplierPicker] = useState('');
    const [selectedSupplierId, setSelectedSupplierId] = useState('');
    const [selectedUploaderEmail, setUploaderEmail] = useState('');

    useEffect(() => {
        const index =
            new URLSearchParams(window.location.search).get('index') || 0;

        setActiveTab(Number(index));
        onCleanSupplierMaps();
    }, []);

    useEffect(() => {
        const origin = 'WEB_UI';
       if(selectedSupplierId !== ''){
           history.push({
                pathname: `/nfeDocuments/new`,
                state: {
                    supplierId: selectedSupplierId,
                    uploaderEmail: selectedUploaderEmail,
                    origin: origin,
                },
            });
            setShowSupplierPicker(false);
       }
    }, [selectedSupplierId]);
 
    
    function handleGetTrProps(state, rowInfo, column, instance) {
        return {
            style: { cursor: 'pointer' },
            onClick: e => history.push(`/nfeDocuments/${rowInfo.original.id}`),
        };
    }

    function handleNewDocumentButtonClick() {
        if (
            context.hasClaim('role-level-full') || 
            context.hasClaim('role-level-contractor')
        ) {
            const uploaderEmail = context.emailAddress;
            setUploaderEmail(uploaderEmail);
            setShowSupplierPicker(!showSupplierPicker);

        } else if (context.hasClaim('role-level-supplier')) {
            const supplierId = context.supplierId;
            const uploaderEmail = context.emailAddress

            setSelectedSupplierId(supplierId);
            setUploaderEmail(uploaderEmail);

            setShowSupplierPicker(false);
        }
    }

    const handleSupplierPickerToggle = () =>
        setShowSupplierPicker(!showSupplierPicker);

    function handleSupplierPickerSubmit(e, values) {
        const supplierId = values.supplierId || '';

        setSelectedSupplierId(supplierId);
    }

    const handleTabClick = (e, index) => {
        if (activeTab !== index) {
            setActiveTab(index);
        }

        history.push(`nfeDocuments?sort=-createdAt&skip=0&take=10&page=0`);
    };

    const tabs = [
        {
            name: intl.formatMessage(messages.nfeTab),
            component: (
                <NfeDocumentList
                    hasFull={hasFull}
                    newNfeDocumentButtonEnabled={newNfeDocumentButtonEnabled}
                    handleNewDocumentButtonClick={handleNewDocumentButtonClick}
                    handleGetTrProps={handleGetTrProps}
                    activeTab={activeTab}
                />
            ),
        },
        {
            name: intl.formatMessage(messages.nfeSummaryTab),
            component: (
                <NfeSummaryList
                    handleGetTrProps={() => {}}
                    activeTab={activeTab}
                />
            ),
        },
    ];

    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/nfeDocuments',
                        name: intl.formatMessage(messages.title),
                        active: true,
                    },
                ]}
            />
            <section className='content-middle'>
                <Tabs
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    tabs={tabs}
                />
            </section>
            <ModalSupplier
                handleSupplierPickerToggle={handleSupplierPickerToggle}
                handleSupplierPickerSubmit={handleSupplierPickerSubmit}
                showSupplierPicker={showSupplierPicker}
                showContractor={true}
                selectedSupplier={{}}
                selectedContractor={{}}
            />
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        maps: state.supplierReducer.maps || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //Modals
        onCleanSupplierMaps: () => dispatch(actions.cleanSupplierMaps()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(NfeListPage)),
);
