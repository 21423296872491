import { push } from 'connected-react-router';
import axios from 'axios';
import { sha3_512 } from 'js-sha3';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import * as actionTypes from './actionTypes';
import clearSessionCookies from '../../utils/clearSessionCookies';
import { getAppVersion } from '../../utils/getVersion';
import { isHttpsAuth0 } from '../../utils/getAuth0Config';

export const login = (emailAddress, password, contractorId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SESSION_REQUEST,
        });

        return axios
            .post('/Sessions', {
                emailAddress: emailAddress,
                password: sha3_512(password),
                clientType: 'Web',
                contractorId: contractorId,
                clientVersion: getAppVersion(),
            })
            .then(response => {
                const session = response.data;
                const { accesses } = session;

                if (accesses.length === 0) {
                    dispatch({
                        type: actionTypes.SESSION_FAILURE,
                    });
                }

                if (accesses.length > 1) {
                    dispatch({
                        type: actionTypes.SET_SESSION_ACCESS_SELECTION,
                        session,
                    });
                } else {
                    dispatch(setAccess({ session }));
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SESSION_FAILURE_PASSWORD,
                    error,
                });
                toast.error('Usuário/senha inválida.');
            });
    };
};

export const setAccess = payload => {
    const { contractorId, session } = payload;
    const { accesses, sessionId, user } = session;

    return async dispatch => {
        dispatch({ type: actionTypes.SESSION_ACCESS_SELECTION_REQUEST });

        try {
            await axios.patch(`/Sessions/${sessionId}`, {
                contractorId: !contractorId
                    ? accesses && accesses[0].contractorId
                    : contractorId,
            });

            dispatch({ type: actionTypes.SESSION_ACCESS_SELECTION_SUCCESS });

            if (user && user.changePassword) {
                dispatch({
                    type: actionTypes.USER_CHANGE_PASSWORD_REQUEST,
                    session,
                });
            } else {
                if (
                    window.location.search.substr(1).split('backurl=')[1] &&
                    window.location.search.substr(1).split('backurl=')[1] !==
                        '/login'
                ) {
                    const queryParams = new URLSearchParams(
                        window.location.search,
                    );
                    const URL = queryParams
                        .toString()
                        .replace(window.location.origin, '')
                        .replace('backurl=', '')
                        .replace(/%2F/g, '/')
                        .replace(/%3A/g, ':')
                        .replace(/%3F/g, '?')
                        .replace(/%3D/g, '=');
                    window.location = URL;
                } else {
                    dispatch(push('/home'));
                }
            }
        } catch (error) {
            dispatch({
                type: actionTypes.SESSION_ACCESS_SELECTION_FAILURE,
                error,
            });
        }
    };
};

export const changePassword = payload => {
    return async dispatch => {
        dispatch({ type: actionTypes.USER_CHANGE_PASSWORD_REQUEST });

        if (!payload.sessionId) {
            payload.sessionId = Cookies.get('SessionId');
        }

        if (!payload.userId) {
            payload.userId = Cookies.get('UserId');
        }
        await axios
            .put(
                `/Users/${payload.userId}/Password?sessionId=${
                    payload.sessionId
                }`,
                {
                    newPassword: sha3_512(payload.newPassword),
                },
            )
            .then(response => {
                dispatch({ type: actionTypes.USER_CHANGE_PASSWORD_SUCCESS });
                toast.success('A senha foi alterada com sucesso.');
                dispatch(push('/login'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.USER_CHANGE_PASSWORD_FAILURE,
                    error,
                });

                const { message } = error;

                if (message === 'Request failed with status code 401')
                    toast.error(
                        'Link expirado, solicite uma nova senha em "Esqueci minha senha"',
                    );
            });
    };
};

export const logout = () => {
    clearSessionCookies();

    return dispatch => {
        dispatch({ type: actionTypes.LOG_OUT_SUCCESS });
        !isHttpsAuth0() && dispatch(push(`/login?backurl=${window.location.href}`));
    };
};

export const getCurrentSession = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_CURRENT_SESSION_REQUEST,
        });

        const sessionId = Cookies.get('SessionId');

        if (!sessionId) {
            dispatch({
                type: actionTypes.GET_CURRENT_SESSION_SUCCESS,
            });

            return;
        }

        return axios
            .get(`/Sessions/${sessionId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.GET_CURRENT_SESSION_SUCCESS,
                    session: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GET_CURRENT_SESSION_FAILURE,
                    error,
                });
            });
    };
};

export const forgotPassword = emailAddress => {
    return async dispatch => {
        dispatch({ type: actionTypes.FORGOT_PASSWORD_REQUEST });

        try {
            await axios.post('/ForgotPasswordRequests', {
                emailAddress,
                clientType: 'Web',
                clientVersion: getAppVersion(),
            });
            dispatch({ type: actionTypes.FORGOT_PASSWORD_SUCCESS });

            // TODO: i18n + shouldn't be here
            toast.success('Um email será enviado para alteração da senha.');
        } catch (error) {
            dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILURE, error });

            // TODO: i18n + shouldn't be here
            toast.error(
                'Não foi possível recuperar sua senha. Tente novamente mais tarde ou contate o suporte.',
            );
        }
    };
};

export const getUserContractors = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTORS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Contractors`)
            .then(response => {
                dispatch({
                    type: actionTypes.CONTRACTORS_GET_LIST_SUCCESS,
                    contractors: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CONTRACTORS_GET_LIST_FAILURE,
                    error,
                });
                toast.error(
                    'Ocorreu ao buscar os contratros do usuário. ' +
                        error.response.data,
                );
            });
    };
};

export const loginByGoogle = (token, contractorId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SESSION_REQUEST,
        });
        return axios
            .post(`/Sessions/Token`, {
                token,
                contractorId,
                clientType: 'Web',
                clientVersion: getAppVersion(),
            })
            .then(response => {
                const session = response.data;
                const { accesses } = session;

                if (accesses.length === 0) {
                    dispatch({
                        type: actionTypes.SESSION_FAILURE,
                    });
                }

                if (accesses.length > 1) {
                    dispatch({
                        type: actionTypes.SET_SESSION_ACCESS_SELECTION,
                        session,
                    });
                } else {
                    dispatch(setAccess({ session }));
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SESSION_FAILURE_PASSWORD,
                    error,
                });
                toast.error('Token inválido.');
            });
    };
};
