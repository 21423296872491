import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'transactions.title',
        defaultMessage: 'Pagamentos',
    },
    titleTransaction: {
        id: 'transactions.titleTransaction',
        defaultMessage: 'Pagamento {entity}',
    },
    documentId: {
        id: 'documents.documentId',
        defaultMessage: 'ID Documento',
    },
    isPaid: {
        id: 'documents.isPaid',
        defaultMessage: 'Pago',
    },
    isAvailable: {
        id: 'global.isAvailable',
        defaultMessage: 'Disponível',
    },
    contractorId: {
        id: 'contractors.contractorId',
        defaultMessage: 'ID Contratante',
    },
    erpDocumentCode: {
        id: 'transactions.erpDocumentCode',
        defaultMessage: 'Código Documento ERP',
    },
    erpDocumentItemCode: {
        id: 'transactions.erpDocumentItemCode',
        defaultMessage: 'Código pagamento ERP',
    },
    dueDate: {
        id: 'transactions.dueDate',
        defaultMessage: 'Vencimento',
    },
    supplierId: {
        id: 'suppliers.supplierId',
        defaultMessage: 'ID Fornecedor',
    },
    referenceCode: {
        id: 'transactions.referenceCode',
        defaultMessage: 'Referência (Nº Nota)',
    },
    value: {
        id: 'global.value',
        defaultMessage: 'Valor',
    },
    currency: {
        id: 'global.currency',
        defaultMessage: 'Moeda',
    },
    paid: {
        id: 'global.paid',
        defaultMessage: 'Pago',
    },
    pending: {
        id: 'global.pending',
        defaultMessage: 'Pendente',
    },
    overdue: {
        id: 'global.overdue',
        defaultMessage: 'Vencido',
    },
    company: {
        id: 'roles.company',
        defaultMessage: 'Contratante',
    },
    supplier: {
        id: 'suppliers.titleOnlySupplier',
        defaultMessage: 'Fornecedor',
    },
});

export default messages;
