import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    titleInvoice: {
        id: 'invoice.title',
        defaultMessage: 'Documentos',
    },
    titleDocumentsConfigs: {
        id: 'category.titleDocumentsConfigs',
        defaultMessage: 'Configurações de Documentos',
    },
    titleCategory: {
        id: 'category.titleCategory',
        defaultMessage: 'Categoria {entity}',
    },
    newCategory: {
        id: 'category.newCategory',
        defaultMessage: 'Nova Categoria',
    },
    deleteCategory: {
        id: 'category.deleteCategory',
        defaultMessage: 'Excluir Categoria',
    },
});
