import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './UserListPage.intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable, {
    buildFilterOr,
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';

class UserListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    properties = () => [
        {
            key: 'name',
            value: this.props.intl.formatMessage(messages.name),
            type: filterPropertyTypes.TEXT,
        },
        {
            key: 'emailAddress',
            value: this.props.intl.formatMessage(messages.email),
            type: filterPropertyTypes.TEXT,
        },
    ];

    reloadUsersList = () => {
        const { query } = this.state;
        let filterQuery = query;

        if (query.includes('search=')) {
            const value = new URLSearchParams(query).get('search');
            filterQuery = buildFilterOr(value, this.properties());
        }

        this.props.history.push('/users' + query);

        this.props.onInitUsersCount(filterQuery);
        this.props.onInitUsers(filterQuery);
    };

    handleRowClick = (e, id) => {
        this.props.history.push(`/users/${id}`);
    };

    handleNewClick = () => {
        this.props.history.push(`/users/new`);
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.history.push(`/users/${rowInfo.original.userId}`),
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadUsersList();
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
        this.props.history.push(`/users` + this.state.query);
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadUsersList();
            },
        );
    };

    render() {
        const { intl, loadingList, users, usersCount } = this.props;
        const { filters } = this.state;

        const newUserButtonEnabled = this.context.hasClaim('user-create');

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'name',
                style: {
                    fontSize: 'small',
                },
                Cell: row => {
                    var id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth:
                    30 +
                    calculateColumnWidth(
                        users,
                        'name',
                        intl.formatMessage(messages.name),
                    ),
                minWidth:
                    150 +
                    calculateColumnWidth(
                        users,
                        'name',
                        intl.formatMessage(messages.name),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'emailAddress',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    30 +
                    calculateColumnWidth(
                        users,
                        'emailAddress',
                        intl.formatMessage(messages.email),
                    ),
                maxWidth:
                    150 +
                    calculateColumnWidth(
                        users,
                        'emailAddress',
                        intl.formatMessage(messages.email),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.company)}</span>
                    </div>
                ),
                accessor: 'contractorName',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    60 +
                    calculateColumnWidth(
                        users,
                        'contractorName',
                        intl.formatMessage(messages.company),
                    ),
                maxWidth:
                    150 +
                    calculateColumnWidth(
                        users,
                        'contractorName',
                        intl.formatMessage(messages.company),
                    ),
            },

            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.supplier)}</span>
                    </div>
                ),
                accessor: 'supplierName',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    60 +
                    calculateColumnWidth(
                        users,
                        'supplierName',
                        intl.formatMessage(messages.supplier),
                    ),
                maxWidth:
                    150 +
                    calculateColumnWidth(
                        users,
                        'supplierName',
                        intl.formatMessage(messages.supplier),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.function)}</span>
                    </div>
                ),
                accessor: 'roleName',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    50 +
                    calculateColumnWidth(
                        users,
                        'roleName',
                        intl.formatMessage(messages.function),
                    ),
                maxWidth:
                    100 +
                    calculateColumnWidth(
                        users,
                        'roleName',
                        intl.formatMessage(messages.function),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdAt)}</span>
                    </div>
                ),
                id: 'createdAt',
                style: {
                    fontSize: 'small',
                },
                accessor: d =>
                    new Date(d.createdAt).toLocaleString(navigator.language),
                maxWidth:
                    150 +
                    calculateColumnWidth(
                        users,
                        'createdAt',
                        intl.formatMessage(messages.createdAt),
                    ),
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/users',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={intl.formatMessage(messages.subtitle, {
                                length: this.props.usersCount,
                            })}
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={this.properties()}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newUserButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyUser,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={users}
                        length={usersCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                        search
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.userReducer.users || [],
        usersCount: state.userReducer.usersCount || 0,
        loadingList: state.userReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitUsers: filter => dispatch(actions.initUsers(filter)),
        onInitUsersCount: filter => dispatch(actions.initUsersCount(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(UserListPage),
);
