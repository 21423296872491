import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    orders: {
        id: 'settings.orders',
        defaultMessage: 'Pedidos',
    },
    enableMultipleOrderItems: {
        id: 'settings.enableMultipleOrders',
        defaultMessage:
            'Suportar associar múltiplos itens de pedido no documento.',
    },
    enableAutomaticOrderAssociation: {
        id: 'settings.enableAutomaticOrderAssociation',
        defaultMessage:
            'Associar automaticamente o pedido/item pedido quando o número for informado no documento.',
    },
    listOnlyOrderFromTheDocumentTakerForAssociation: {
        id: 'settings.listOnlyOrderFromTheDocumentTakerForAssociation',
        defaultMessage:
            'Listar somente pedidos do tomador do documento para associação.',
    },
    associateAllItemOrdersWhenOnlyOrderNumberAreFound: {
        id: 'settings.associateAllItemOrdersWhenOnlyOrderNumberAreFound',
        defaultMessage: 'Com a associação automática de pedidos habilitada, associar todos os itens de pedidos quando somente o número do pedido for encontrado'
    },
    enableJobAutomaticOrderAssociation: {
        id: 'settings.enableJobAutomaticOrderAssociation',
        defaultMessage:
            'Habilitar serviço para associar pedidos a documentos.',
    },  
});

export default messages;
