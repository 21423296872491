import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    shippingDate: {
        id: 'orders.shippingDate',
        defaultMessage: 'Data de remessa',
    },
    itemOrder: {
        id: 'orderItem.itemOrder',
        defaultMessage: 'Item pedido',
    },

    isActive: {
        id: 'orderItem.isActive',
        defaultMessage: 'Disponível',
    },

    measureItem: {
        id: 'orderItem.measureItem',
        defaultMessage: 'Item medição',
    },

    type: {
        id: 'orderItem.type',
        defaultMessage: 'Tipo',
    },

    quantity: {
        id: 'orderItem.quantity',
        defaultMessage: 'Quantidade',
    },

    measureUnity: {
        id: 'orderItem.measureUnity',
        defaultMessage: 'Unidade de medida',
    },

    ncm: {
        id: 'orderItem.ncm',
        defaultMessage: 'NCM',
    },

    cnpjTaker: {
        id: 'orderItem.cnpjTaker',
        defaultMessage: 'CNPJ Tomador',
    },

    aliquotICMS: {
        id: 'orderItem.aliquotICMS',
        defaultMessage: 'Aliquota ICMS(%)',
    },

    icmsValue: {
        id: 'orderItem.icmsValue',
        defaultMessage: 'Valor ICMS',
    },

    aliquotIPI: {
        id: 'orderItem.aliquotIPI',
        defaultMessage: 'Aliquota IPI(%)',
    },

    ipiValue: {
        id: 'orderItem.ipiValue',
        defaultMessage: 'Valor IPI',
    },

    aliquotISS: {
        id: 'orderItem.aliquotISS',
        defaultMessage: 'Aliquota ISS(%)',
    },

    issValue: {
        id: 'orderItem.issValue',
        defaultMessage: 'Valor ISS',
    },

    unitaryNetValue: {
        id: 'orderItem.unitaryNetValue',
        defaultMessage: 'Valor líquido unitário',
    },
    unitaryGrossValue: {
        id: 'orderItem.unitaryGrossValue',
        defaultMessage: 'Valor bruto unitário',
    },
    totalNetValue: {
        id: 'orderItem.totalNetValue',
        defaultMessage: 'Valor líquido total',
    },

    totalGrossValue: {
        id: 'orderItem.totalGrossValue',
        defaultMessage: 'Valor bruto total',
    },

    deliveryInfo: {
        id: 'orderItem.deliveryInfo',
        defaultMessage: 'Dados de Entrega',
    },

    name: {
        id: 'orderItem.name',
        defaultMessage: 'Nome',
    },

    cnpjDestiny: {
        id: 'orderItem.cnpjDestiny',
        defaultMessage: 'CNPJ Destino',
    },

    address: {
        id: 'orderItem.address',
        defaultMessage: 'Endereço',
    },

    number: {
        id: 'orderItem.number',
        defaultMessage: 'Número',
    },

    city: {
        id: 'orderItem.city',
        defaultMessage: 'Cidade',
    },

    state: {
        id: 'orderItem.state',
        defaultMessage: 'Estado',
    },
    
    cep: {
        id: 'orderItem.cep',
        defaultMessage: 'CEP',
    },

    //Buttons
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },
    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
});

export default messages;
