import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';

export const initClaims = () => {
    return dispatch => {
      dispatch({
        type: actionTypes.CLAIMS_GET_LIST_REQUEST
      });
  
      axios
        .get("/claims")
        .then(response => {
          dispatch({
            type: actionTypes.CLAIMS_GET_LIST_SUCCESS,
            claims: response.data
          });
        })
        .catch(error => {
          dispatch({
            type: actionTypes.CLAIMS_GET_LIST_FAILURE,
            error
          });
          toast.error("Ocorreu um erro ao carregar a lista de claims. " + error.response.data);
        });
    };
  };