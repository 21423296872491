import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import messages from './SupplierMapsForm.intl';
import { Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import EvtTable from '../EvtTable/EvtTable';
import { AuthContext } from '../../context/AuthContext';

class SupplierMapsForm extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    render() {
        const {
            intl,
            isNew,
            loading,
            maps,
            mapsCount,
            handleEvTableStateChange,
        } = this.props;
        const { filters } = this.state;

        const canEditMaps =
            this.context.hasClaim('supplier-maps-edit') && !isNew;

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.documentType)}</span>
                    </div>
                ),
                accessor: 'documentType',
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'name',
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.city)}</span>
                    </div>
                ),
                accessor: 'city',
                Cell: row => {
                    var id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.uf)}</span>
                    </div>
                ),
                accessor: 'uf',
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.fileType)}</span>
                    </div>
                ),
                accessor: 'fileType',
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.categoryType)}</span>
                    </div>
                ),
                accessor: 'categoryType',
                Cell: row => {
                    let categoryValue = row.value == 0 ? '' : row.value;
                    if (!!row.original.subCategory) {
                        categoryValue = `${categoryValue}(${
                            row.original.subCategory
                        })`;
                    }
                    return (
                        <div className='customer-container'>
                            <div className=''>{categoryValue}</div>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.ibge)}</span>
                    </div>
                ),
                accessor: 'ibgeCode',
                maxWidth: '150',
                Cell: row => {
                    if (row.value == 0){
                        return undefined
                    }
                    return row.value
                }
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdAt)}</span>
                    </div>
                ),
                accessor: 'createdAt',
                maxWidth: '200',
                Cell: row => {
                    return new Date(row.value).toLocaleString(
                        navigator.language,
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.updatedAt)}</span>
                    </div>
                ),
                accessor: 'updatedAt',
                maxWidth: '200',
                Cell: row => {
                    return new Date(row.value).toLocaleString(
                        navigator.language,
                    );
                },
            },
        ];

        if (canEditMaps) {
            columns.push({
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <button
                                style={{ width: '100px' }}
                                className='btn-delete'
                                onClick={() =>
                                    this.props.handleDelete(row.original)
                                }
                            >
                                <FontAwesomeIcon icon='trash-alt' />
                            </button>
                        </div>
                    );
                },
                maxWidth: 100,
            });
        }

        return (
            <AvForm onValidSubmit={this.props.handleSubmitMaps}>
                <EvtTable
                    filters={filters}
                    columns={columns}
                    data={maps}
                    length={mapsCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChange}
                    loading={loading}
                    defaultSorted={[
                        {
                            id: 'name',
                            desc: true,
                        },
                    ]}
                    fetchPage
                    tabInside
                />
                {canEditMaps && (
                    <Row className='mt-3'>
                        <Col xs={12}>
                            <button
                                type='submit'
                                className='btn-submit'
                                disabled={loading}
                            >
                                {intl.formatMessage(messages.submitButton)}
                            </button>
                            <button
                                type='button'
                                onClick={this.props.handleToggle}
                                disabled={loading}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyMap,
                                    ),
                                })}
                            </button>
                        </Col>
                    </Row>
                )}
            </AvForm>
        );
    }
}

export default injectIntl(SupplierMapsForm);
