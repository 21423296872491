import React from 'react';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    pendingFiles: [],
    pendingFilesCount: 0,
    loadingList: false,

    pendingFile: null,
    loadingDetails: false,
};

const setPendingFilesRequest = (state, action) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setLoadingDetailsFalseRequest = (state, { error }) => {
    let newState = {
        ...state,
    };

    if (error) newState = { ...newState, error };

    return {
        ...newState,
        loadingDetails: false,
    };
};

const setLoadingDetailsTrueRequest = (state, action) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setPendingFileDetailsRequest = (state, action) => {
    return {
        ...state,
        pendingFile: null,
        loadingDetails: true,
    };
};

const setPendingFiles = (state, { pendingFiles, pendingFilesCount, error }) => {
    let newState = {
        ...state,
        pendingFiles,
        pendingFilesCount,
    };

    if (error) newState = { ...newState, error };

    return {
        ...newState,
        loadingList: false,
    };
};

const setPendingFileDetails = (state, { pendingFile, error }) => {
    let newState = {
        ...state,
        pendingFile,
    };

    if (error) newState = { ...newState, error };

    return {
        ...newState,
        loadingDetails: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //List
        case actionTypes.PENDING_FILES_GET_LIST_REQUEST:
            return setPendingFilesRequest(state, action);

        case actionTypes.PENDING_FILES_GET_LIST_SUCCESS:
        case actionTypes.PENDING_FILES_GET_LIST_FAILURE:
            return setPendingFiles(state, action);

        // Details and CreateDoc
        case actionTypes.PENDING_FILES_ACTION_REQUEST:
            return setLoadingDetailsTrueRequest(state, action);

        case actionTypes.PENDING_FILES_ACTION_SUCCESS:
        case actionTypes.PENDING_FILES_ACTION_FAILURE:
            return setLoadingDetailsFalseRequest(state, action);

        case actionTypes.PENDING_FILES_GET_DETAILS_REQUEST:
            return setPendingFileDetailsRequest(state, action);

        case actionTypes.PENDING_FILES_GET_DETAILS_SUCCESS:
        case actionTypes.PENDING_FILES_GET_DETAILS_FAILURE:
            return setPendingFileDetails(state, action);

        default:
            return state;
    }
};

export default reducer;
