import { toast } from 'react-toastify';
import { goBack } from 'connected-react-router';
import * as actionTypes from './actionTypes';
import axios from 'axios';

// #region Cte
export const initDocumentCteCreate = payload => {
    return dispatch => {
        dispatch({
            type: actionTypes.CTE_DOCUMENT_CREATE_REQUEST,
        });

        // Endpoint selector for chosen functionality
        let endPointUrl = `/CTe`;
        if (!!payload.isRawFile) {
            endPointUrl = `/CTe/Attachment/RawFile`;
        }

        delete payload.isRawFile;

        // Data to form request
        const data = new FormData();
        const objectPayload = Object.entries(payload);

        objectPayload.forEach(item => {
            let [key, value] = item;
            value && data.append(key, value);
        });

        axios
            .post(`${endPointUrl}`, data)
            .then(response => {
                dispatch({
                    type: actionTypes.CTE_DOCUMENT_CREATE_SUCCESS,
                });

                toast.success('Upload realizado com sucesso');
                dispatch(goBack());
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CTE_DOCUMENT_CREATE_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsCte = (query, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.CTE_DOCUMENTS_GET_LIST_REQUEST,
        });

        axios
            .get(`/CTe/SummaryView${query}` + search)
            .then(response => {
                dispatch({
                    type: actionTypes.CTE_DOCUMENTS_GET_LIST_SUCCESS,
                    documentsCte: response.data.result,
                    documentsCteCount: response.data.count,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CTE_DOCUMENTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentsCteDetails = cteDocumentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.CTE_DOCUMENTS_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/CTe/${cteDocumentId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.CTE_DOCUMENTS_GET_DETAILS_SUCCESS,
                    cteDocument: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CTE_DOCUMENTS_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};
// #endregion Cte

// #region Cte Orders
export const initCteOrders = (documentId, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.CTE_ORDERS_GET_LIST_REQUEST,
        });

        axios
            .get(`/CTe/${documentId}/OrderItems${query}`)
            .then(response => {
                dispatch({
                    type: actionTypes.CTE_ORDERS_GET_LIST_SUCCESS,
                    cteOrders: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CTE_ORDERS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initCteOrdersCount = documentId => {
    return dispatch => {
        dispatch({
            type: actionTypes.CTE_ORDERS_GET_COUNT_REQUEST,
        });

        axios
            .get(`/CTe/${documentId}/OrderItems/Count`)
            .then(response => {
                dispatch({
                    type: actionTypes.CTE_ORDERS_GET_COUNT_SUCCESS,
                    cteOrdersCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CTE_ORDERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};
// #endregion Cte Orders

// #region Cte Actions
export const cleanCteDocument = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.CTE_DOCUMENTS_GET_DETAILS_SUCCESS,
            cteDocument: null,
        });
    };
};
// #endregion Cte Actions
