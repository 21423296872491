import axios from 'axios';
import { toast } from 'react-toastify';
import clearSessionCookies from './clearSessionCookies';

let timer;

export default history => {
    axios.defaults.baseURL = window.API_URI;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;

    axios.interceptors.response.use(
        response => response,
        error => {
            if (history.location.pathname.indexOf('forgotPassword') !== -1) {
                return Promise.reject(error);
            }

            if (error.response.status === 401) {
                if (timer) clearTimeout(timer) 
                else toast.error(
                    'Tempo limite de sessão esgotado, por favor entre novamente',
                )

                timer = setTimeout(() => {},8000)
                
                clearSessionCookies();
                if (!window.location.href.includes('backurl')) {
                    history.push(`/login?backurl=${window.location.href}`);
                }
            }

            if (error.response.status === 403) {
                toast.error('Não autorizado.');
            }

            return Promise.reject(error);
        },
    );
};
