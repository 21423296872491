import React, { Component } from 'react';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';

import * as actions from '../../store/actions/index';
import EvtTable from '../EvtTable/EvtTable';
import Modal, { modalTypes } from '../Modal/Modal';
import messages from './ModalOrdersDocument.intl';
import { columnsOrdersDocuments } from '../DynamicModalOrdersDocuments/components/Columns';
import ModalMultiplesOrders from '../DynamicModalOrdersDocuments/components/ModalMultiplesOrders';
import { AuthContext } from '../../context/AuthContext';

class ModalOrdersDocuments extends Component {
    static contextType = AuthContext;

    state = {
        showItemComboBox: false,
        searchItems: false,
        showModalDelete: false,
        orderItemsCount: null,
        selectedOrders: [],
        orderItem: {},
        orderItemDelete: {},
        query: '',
        defaultItem: '',
    };
    componentDidMount() {
        const windowPath= window.location.pathname;
        const documentId = windowPath.replace(/\/(documents|otherDocuments)\//, '');

        
        if (documentId !== 'new') {
            this.props.onGetOrdersDocumentCount(documentId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { orderItems } = this.props;

        if (prevProps.orderItems !== orderItems) {
            this.setState(
                {
                    orderItemsCount: orderItems.length,
                },
                () => {
                    this.setState({
                        showItemComboBox: true,
                    });
                },
            );

            if (orderItems.length === 1) {
                const orderItem = orderItems.shift();
                const totalGrossValue = () => {
                    let valueFormatted =
                        String(orderItem.totalGrossValue) || '';

                    valueFormatted = new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(valueFormatted);

                    return valueFormatted;
                };
                const label = `${orderItem.itemOrder} - ${
                    orderItem.description
                } - ${orderItem.cnpjTaker} - ${totalGrossValue()}`;
                const value = orderItem.orderItemId;

                this.setState({
                    defaultItem: { label: label, value: value },
                });
            }
        }
    }

    handleStateChangeOrdersItems = query => {
        this.setState({ query }, () => {
            this.reloadOrdersDocument();
        });
    };

    reloadOrdersDocument = () => {
        const { documentId } = this.props;
        const filter = this.state.query;
        this.props.onGetOrdersDocument(documentId, filter);
        this.props.onGetOrdersDocumentCount(documentId);
    };

    handleToggleSearchItems = () => {
        const { selectedDocument } = this.props;
        const filter = '?sort=-createdAt&skip=0&take=100&page=0';

        this.props.onInitOrderItemsBySupplier(
            selectedDocument?.supplierId,
            filter,
        );

        this.setState({
            searchItems: !this.state.searchItems,
            selectedOrders: [],
        });
    };

    handleSelectOrderItem = item => {
        const isSelected = this.state.selectedOrders.find(
            it => it === item?.orderItemId,
        );

        if (isSelected) {
            const filteredOrders = this.state.selectedOrders.filter(
                it => it !== item?.orderItemId,
            );
            this.setState({
                selectedOrders: filteredOrders,
            });
        } else {
            this.setState({
                selectedOrders: [
                    ...this.state.selectedOrders,
                    item?.orderItemId,
                ],
            });
        }
    };

    handleSelectItem = props => {
        this.setState({ orderItem: props.data });
    };

    handleAddItemSubmit = () => {
        const { selectedOrders } = this.state;

        if (selectedOrders.length === 0) {
            toast.error(this.props.intl.formatMessage(messages.noItemSelected));
            return;
        }

        const orderItems = selectedOrders.join(',');

        this.props.onUpdateOrdersDocument(this.props.documentId, orderItems);
        this.handleToggleSearchItems();
    };

    handleToggleModalDelete = (orderItem = null) => {
        this.setState({
            showModalDelete: !this.state.showModalDelete,
            orderItemDelete: !!orderItem
                ? orderItem
                : this.state.orderItemDelete,
        });
    };

    handleDeleteItem = async () => {
        const orderItemId = this.state.orderItemDelete?.orderItemId;
        await this.props.onDeleteOrdersDocument(
            this.props.documentId,
            orderItemId,
        );

        this.setState({
            showModalDelete: !this.state.showModalDelete,
            orderItemDelete: null,
        });
    };

    render() {
        const {
            intl,
            ordersDocument,
            ordersDocumentCount,
            loadingModalOrders,
            selectedDocument,
        } = this.props;

        const { searchItems, showModalDelete, selectedOrders } = this.state;

        const hasAllowOrderDocumentClaim =
            this.context.hasClaim('document-order-integration-update') ||
            (selectedDocument?.currentStep?.order === 3 &&
                selectedDocument?.currentStep?.status === 'pending');

        return (
            <>
                <Modal
                    title={intl.formatMessage(
                        messages.associatedOrderDocuments,
                    )}
                    isOpen={this.props.isOpen}
                    handleToggle={this.props.handleToggle}
                    size={'xxl'}
                >
                    {hasAllowOrderDocumentClaim && (
                        <Row>
                            <Col xs={{ size: 2 }}>
                                <button
                                    className='btn-submit'
                                    onClick={this.handleToggleSearchItems}
                                >
                                    {intl.formatMessage(
                                        messages.newOrdersButton,
                                    )}
                                </button>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <EvtTable
                            fetchPage
                            columns={columnsOrdersDocuments(
                                intl,
                                ordersDocument,
                                hasAllowOrderDocumentClaim,
                                this.handleToggleModalDelete,
                                false,
                            )}
                            data={ordersDocument}
                            length={ordersDocumentCount}
                            pageSize={5}
                            handleStateChange={
                                this.handleStateChangeOrdersItems
                            }
                            loading={loadingModalOrders}
                            defaultSorted={[
                                {
                                    id: 'createdAt',
                                    desc: true,
                                },
                            ]}
                            countHeaderText={intl.formatMessage(
                                messages.itemsSubtitle,
                                {
                                    length: ordersDocumentCount,
                                },
                            )}
                            tabInside
                        />
                    </Row>
                </Modal>
                <ModalMultiplesOrders
                    isOpenSearchOrders={searchItems}
                    selectedOrders={selectedOrders}
                    hasAllowOrderDocumentClaim={hasAllowOrderDocumentClaim}
                    handleToggleSearchOrders={this.handleToggleSearchItems}
                    handleSelectOrderItem={this.handleSelectOrderItem}
                    handleAddItemSubmit={this.handleAddItemSubmit}
                />
                <Modal
                    title={intl.formatMessage(messages.removeOrderItem)}
                    isOpen={showModalDelete}
                    handleToggleModalDelete={this.handleToggleModalDelete}
                    type={modalTypes.ERROR}
                    hasFooter={true}
                    firstButtonText='Sim'
                    secondButtonText='Não'
                    toggleFirstButton={this.handleDeleteItem}
                    toggleSecondButton={this.handleToggleModalDelete}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ordersDocument: state.documentReducer.ordersDocument || [],
        ordersDocumentCount: state.documentReducer.ordersDocumentCount || 0,
        loadingModalOrders: state.documentReducer.loadingModalOrders,
        selectedDocument: state.documentReducer.selectedDocument,
        orderItems: state.orderReducer.orderItems,
        supplierOrders: state.supplierReducer.supplierOrders || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetOrdersDocument: (documentId, filter) =>
            dispatch(actions.getOrdersDocument(documentId, filter)),
        onGetOrdersDocumentCount: documentId =>
            dispatch(actions.getOrdersDocumentCount(documentId)),
        onUpdateOrdersDocument: (documentId, orderItemId) =>
            dispatch(actions.updateOrdersDocument(documentId, orderItemId)),
        onDeleteOrdersDocument: (documentId, orderItemId) =>
            dispatch(actions.deleteOrdersDocument(documentId, orderItemId)),
        onInitOrderItems: (orderId, query) =>
            dispatch(actions.initOrderItems(orderId, query)),
        onInitSupplierOrdersAvailable: (supplierId, filter) =>
            dispatch(actions.initSupplierOrdersAvailable(supplierId, filter)),
        onInitOrderItemsBySupplier: (supplierId, filter) =>
            dispatch(actions.initOrderItemsBySupplier(supplierId, filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalOrdersDocuments),
);
