import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    //Tab
    reportsTitle: {
        id: 'reports.reportsTitle',
        defaultMessage: 'Relatórios',
    },

    reportsSubtitle: {
        id: 'reports.reportsSubtitle',
        defaultMessage: '{entity} relatório(s) encontrado(s)',
    },

    generatedReportsTitle: {
        id: 'reports.generatedReportsTitle',
        defaultMessage: 'Relatórios Gerados',
    },

    generatedReportsSubtitle: {
        id: 'reports.generatedReportsSubtitle',
        defaultMessage: '{entity} relatório(s) gerados encontrado(s)',
    },

    //Filters
    generate: {
        id: 'reports.generate',
        defaultMessage: 'Gerar',
    },

    createdAt: {
        id: 'reports.createdAt',
        defaultMessage: 'Data de criação',
    },

    user: {
        id: 'reports.user',
        defaultMessage: 'Usuário',
    },

    name: {
        id: 'reports.name',
        defaultMessage: 'Nome',
    },

    status: {
        id: 'reports.status',
        defaultMessage: 'Status',
    },

    //Status
    success: {
        id: 'reports.success',
        defaultMessage: 'Completo',
    },
    fail: {
        id: 'reports.fail',
        defaultMessage: 'Falha',
    },
    inProcess: {
        id: 'reports.inProcess',
        defaultMessage: 'Em Processamento',
    },

    generatingDate: {
        id: 'reports.generatingDate',
        defaultMessage: 'Gerando data',
    },

    downloadLink: {
        id: 'report.downloadLink',
        defaultMessage: 'Download',
    },

    //Global
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    titleOnlyContractor: {
        id: 'reports.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },
});

export default messages;
