import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'users.title',
        defaultMessage: 'Usuários',
    },

    titleUser: {
        id: 'users.titleUser',
        defaultMessage: 'Usuário {entity}',
    },

    registrationInfo: {
        id: 'users.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    access: {
        id: 'users.access',
        defaultMessage: 'Acessos',
    },

    deleteUser: {
        id: 'users.deleteUser',
        defaultMessage: 'Excluir Usuário',
    },

    name: {
        id: 'users.name',
        defaultMessage: 'Nome',
    },

    email: {
        id: 'users.email',
        defaultMessage: 'Email',
    },

    password: {
        id: 'users.password',
        defaultMessage: 'Senha',
    },

    role: {
        id: 'users.role',
        defaultMessage: 'Função',
    },

    company: {
        id: 'users.company',
        defaultMessage: 'Contratante',
    },

    supplier: {
        id: 'users.supplier',
        defaultMessage: 'Fornecedor',
    },

    userCompany: {
        id: 'users.userCompany',
        defaultMessage: 'Filial',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    modalTitle: {
        id: 'users.selectAcccess',
        defaultMessage: 'Selecione o acesso',
    },

    modalCompaniesTitle: {
        id: 'users.modalCompaniesTitle',
        defaultMessage: 'Selecione a filial',
    },

    deleteAccess: {
        id: 'modal.deleteAccess',
        defaultMessage: 'Apagar acesso',
    },

    userAccessAlreadyAdded: {
        id: 'modal.userAccessAlreadyAdded',
        defaultMessage: 'Usuário já possui esse acesso',
    },

    userCompanyAlreadyAdded: {
        id: 'modal.userCompanyAlreadyAdded',
        defaultMessage: 'Usuário já possui essa filial',
    },
    
    newPasswordConfirmationModalTitle: {
        id: 'users.newPasswordConfirmationModalTitle',
        defaultMessage: 'Envio de renovação de senha'
    },

    newPasswordConfirmationModalText: {
        id: 'users.newPasswordConfirmationModalText',
        defaultMessage: 'Tem certeza que deseja enviar um email de renovação de senha para esse usuário?'
    }
});

export default messages;
