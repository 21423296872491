import axios from 'axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import * as actionTypes from './actionTypes';

export const initMaps = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAPS_GET_LIST_REQUEST,
        });

        axios
            .get('/Maps' + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.MAPS_GET_LIST_SUCCESS,
                    maps: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAPS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initMapsCount = filter => {
    return async dispatch => {
        dispatch({
            type: actionTypes.MAPS_GET_COUNT_REQUEST,
        });

        await axios
            .get('/Maps/Count' + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.MAPS_GET_COUNT_SUCCESS,
                    mapsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAPS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initMapItemDetails = (mapId, mapItemId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: actionTypes.MAP_ITEM_GET_DETAILS_REQUEST,
        });
        const responseMap = await axios.get(`/Maps/${mapId}`);

        const response = await axios
            .get(`/Maps/${mapId}/MapItems/${mapItemId}`)
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ITEM_GET_DETAILS_FAILURE,
                    error,
                });
            });

        dispatch({
            type: actionTypes.MAP_ITEM_GET_DETAILS_SUCCESS,
            selectedMapItem: response.data,
            selectedMap: responseMap.data,
        });
    };
};

export const initMapDetails = mapId => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/Maps/${mapId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_GET_DETAILS_SUCCESS,
                    map: response.data,
                });

                dispatch(push(`/maps/${mapId}`));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const initMap = mapId => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/Maps/${mapId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_GET_DETAILS_SUCCESS,
                    map: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const uploadMapFile = (selectedMap, file) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_UPLOAD_FILE_REQUEST,
        });

        const data = new FormData();
        data.append('file', file);

        axios
            .put(`/Maps/${selectedMap.mapId}/File`, data)
            .then(() => {
                dispatch({
                    type: actionTypes.MAP_UPLOAD_FILE_SUCCESS,
                });

                window.location.reload();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_UPLOAD_FILE_FAILURE,
                    error,
                });
            });
    };
};

export const createMap = map => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_CREATE_REQUEST,
        });

        axios
            .post('/Maps', map)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_CREATE_SUCCESS,
                });
                toast.success('Mapeamento criado com sucesso');
                dispatch(initMapDetails(response.data));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_CREATE_FAILURE,
                    error,
                });
            });
    };
};

export const copyMap = map => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ACTION_COPY_REQUEST,
        });

        axios
            .post('/Maps', map)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ACTION_COPY_SUCCESS,
                });
                toast.success('Mapeamento criado com sucesso');
                dispatch(initMapDetails(response.data));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ACTION_COPY_FAILURE,
                    error,
                });
            });
    };
};

export const updateMap = map => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_UPDATE_REQUEST,
        });

        axios
            .put(`/Maps/${map.mapId}`, map)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_UPDATE_SUCCESS,
                });
                toast.success('Mapeamento alterado com sucesso');

                map.mapItems.length > 0 && window.location.reload();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_UPDATE_FAILURE,
                    error,
                });
            });
    };
};

export const updateMapFromList = (map, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_UPDATE_FROM_LIST_REQUEST,
        });

        axios
            .put(`/Maps/FromList/${map.mapId}`, map)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_UPDATE_FROM_LIST_SUCCESS,
                });
                toast.success('Mapeamento alterado com sucesso');
                dispatch(initMaps(query));
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_UPDATE_FROM_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const updateCropMap = (map, crop) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_UPDATE_REQUEST,
        });

        map.hideCrop = crop;

        axios
            .put(`/Maps/${map.mapId}`, map)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_UPDATE_SUCCESS,
                });
                toast.success('Mapeamento alterado com sucesso');

                map.mapItems.length > 0 && window.location.reload();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_UPDATE_FAILURE,
                    error,
                });
            });
    };
};

export const deleteMap = mapId => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_DELETE_REQUEST,
        });

        axios
            .delete(`/maps/${mapId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.MAP_DELETE_SUCCESS,
                });
                toast.success('Mapeamento desativado com sucesso');
                setTimeout(() => {
                    window.location.reload();
                  }, 3000);
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.MAP_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const updateMapItem = (selectedMap, updatedMapItem) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ITEM_UPDATE_REQUEST,
        });

        axios
            .put(
                `/Maps/${selectedMap.mapId}/MapItems/${
                    updatedMapItem.mapItemId
                }`,
                updatedMapItem,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ITEM_UPDATE_SUCCESS,
                    updatedMapItem,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ITEM_UPDATE_FAILURE,
                    error,
                });
            });
    };
};

export const addMapItem = (mapId, newMapItem) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ITEM_ADD_REQUEST,
        });

        axios
            .post(`/Maps/${mapId}/MapItems`, newMapItem)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ITEM_ADD_SUCCESS,
                    payload: {
                        mapItemId: response.data.mapItemId,
                        newMapItem,
                    },
                });
                toast.success('Item criado com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ITEM_ADD_FAILURE,
                    error,
                });
            });
    };
};

export const copyMapItem = (mapId, newMapItem) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ITEM_COPY_REQUEST,
        });

        axios
            .post(`/Maps/${mapId}/MapItems`, newMapItem)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ITEM_COPY_SUCCESS,
                    payload: {
                        mapItemId: response.data.mapItemId,
                        newMapItem,
                    },
                });
                toast.success('Item copiado com sucesso');
                initMapDetails(mapId)(dispatch);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ITEM_COPY_FAILURE,
                    error,
                });
            });
    };
};

export const removeMapItem = (selectedMap, mapItemId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ITEM_REMOVE_REQUEST,
        });

        axios
            .delete(`/Maps/${selectedMap.mapId}/MapItems/${mapItemId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ITEM_REMOVE_SUCCESS,
                    mapItemId,
                });
                toast.success('Item excluído com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ITEM_REMOVE_FAILURE,
                    error,
                });
            });
    };
};

export const saveMapAction = newAction => {
    return (dispatch, getState) => {
        const mapActions = getState().mapReducer.selectedMapItem.mapActions.slice();

        mapActions.push(newAction);

        dispatch({
            type: actionTypes.MAP_ADD_ACTION,
            mapActions,
        });
    };
};

export const updateMapAction = editedAction => {
    return (dispatch, getState) => {
        const mapActions = getState().mapReducer.selectedMapItem.mapActions.slice();
        var foundIndex = mapActions.findIndex(
            x => x.mapActionId === editedAction.mapActionId,
        );

        mapActions[foundIndex] = editedAction;

        dispatch({
            type: actionTypes.MAP_UPDATE_ACTION,
            mapActions,
        });
    };
};

export const saveMapActions = mapActions => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ADD_ACTION,
            mapActions: [...mapActions],
        });
    };
};

export const deleteMapAction = mapActionId => {
    return (dispatch, getState) => {
        const mapActions = getState().mapReducer.selectedMapItem.mapActions.slice();

        const updatedMapActions = mapActions.filter(
            ma => ma.mapActionId !== mapActionId,
        );

        updatedMapActions.map((mapAction, index) => {
            return (mapAction.order = index + 1);
        });

        dispatch({
            type: actionTypes.MAP_DELETE_ACTION,
            mapActions: updatedMapActions,
        });
    };
};

export const updateMapItemDetails = (mapId, mapItemId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ITEM_DETAILS_UPDATE_REQUEST,
        });

        axios
            .put(`/Maps/${mapId}/MapItems/${mapItemId}`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.MAP_ITEM_DETAILS_UPDATE_SUCCESS,
                    payload: {
                        values,
                    },
                });

                toast.success('Item alterado com sucesso');
                initMapDetails(mapId)(dispatch);
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.MAP_ITEM_DETAILS_UPDATE_FAILURE,
                    error,
                });
                dispatch(push(`/maps/${mapId}`));
                toast.error('O Campo de mapeamento escolhido não existe');
            });
    };
};

export const updateMapItemFieldDetails = (mapId, mapItemId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ITEM_FIELD_UPDATE_REQUEST,
        });

        axios
            .put(`/Maps/${mapId}/MapItems/${mapItemId}`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.MAP_ITEM_FIELD_UPDATE_SUCCESS,
                    payload: {
                        values,
                    },
                });

                toast.success('Campo alterado com sucesso');
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.MAP_ITEM_FIELD_UPDATE_FAILURE,
                    error,
                });
            });
    };
};

export const initMapOcrTest = mapId => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_GET_OCR_RESULT_REQUEST,
        });

        axios
            .get(`/Maps/${mapId}/Ocr`)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_GET_OCR_RESULT_SUCCESS,
                    mapOcrResult: response.data,
                });
                if (response.data?.length < 1) {
                    toast.success('Não foi encontrado resultados do OCR');
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_GET_OCR_RESULT_FAILURE,
                    error,
                });
            });
    };
};

export const processMapFieldOcr = (mapId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ITEM_OCR_FIELD_REQUEST,
        });

        axios
            .post(`/maps/${mapId}/mapFieldOcr`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ITEM_OCR_FIELD_SUCCESS,
                    mapFieldOcr: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ITEM_OCR_FIELD_FAILURE,
                    error,
                });
            });
    };
};

export const associateMapInSuppliers = (mapId, contractorId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ASSOCIATE_REQUEST,
        });

        axios
            .put(`/Maps/${mapId}/Associate/${contractorId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ASSOCIATE_SUCCESS,
                });
                const suppliersCount = Number(
                    response?.data.split('suppliers')[0],
                );
                toast.success(
                    `Mapeamento associado a ${suppliersCount} ${
                        suppliersCount != 1 ? 'fornecedores' : 'fornecedor'
                    }`,
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ASSOCIATE_FAILURE,
                    error,
                });
            });
    };
};

export const updateMapFilterDelimeter = (mapId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_ASSOCIATE_REQUEST,
        });

        axios
            .put(`/Maps/${mapId}/MapFilterDelimeter`, { ...values })
            .then(response => {
                dispatch({
                    type: actionTypes.MAP_ASSOCIATE_SUCCESS,
                });
                toast.success(`Mapeamento atualizado com sucesso!`);
                initMapDetails(mapId)(dispatch);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAP_ASSOCIATE_FAILURE,
                    error,
                });
            });
    };
};

const patterns = {
    //eslint-disable-next-line
    Number:
        '^[+-]?[0-9]{1,3}(?:(?:,[0-9]{3})*(?:.[0-9]{2})?|(?:.[0-9]{3})*(?:,[0-9]{2})?|[0-9]*(?:[.,][0-9]*)?)$',
    //eslint-disable-next-line
    Money:
        '^[+-]?[0-9]{1,3}(?:(?:,[0-9]{3})*(?:.[0-9]{2})?|(?:.[0-9]{3})*(?:,[0-9]{2})?|[0-9]*(?:[.,][0-9]{2})?)$',
    Text: '',
    //eslint-disable-next-line
    Date:
        '^((([0-2]\\d|3[0-1])\\/(0\\d|1[0-2]))|((0\\d|1[0-2])\\/([0-2]\\d|3[0-1])))\\/\\d{4}(\\s([0-1]\\d|[2][0-3])(\\:[0-5]\\d){1,2})?$',
    //eslint-disable-next-line
    Time: '^(([0-1]d|[2][0-3])(:[0-5]d){1,2})?$',
};

export const setFieldTypePattern = type => {
    return dispatch => {
        const pattern = patterns[type];

        dispatch({
            type: actionTypes.MAP_ITEM_FIELD_TYPE_CHANGE,
            pattern,
        });
    };
};

export const clearMap = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_GET_DETAILS_SUCCESS,
            map: null,
        });
    };
};

export const clearOcrResults = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAP_GET_OCR_RESULT_SUCCESS,
            mapOcrResult: [],
        });
    };
};
