import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { injectIntl } from 'react-intl';
import messages from './MapList.intl';
import MapListItem from './MapListItem/MapListItem';

function mapList(props) {
    const { intl } = props;

    return (
        <ListGroup>
            {!props.mapItems || props.mapItems.length === 0 ? (
                <ListGroupItem>
                    <span>{intl.formatMessage(messages.mapListEmptyText)}</span>
                </ListGroupItem>
            ) : (
                <React.Fragment>
                    {props.mapItems.map((mapItem, index) => {
                        const isThisMapSelected =
                            props.selectedMapItem &&
                            props.selectedMapItem.mapItemId ===
                                mapItem.mapItemId;

                        return !isThisMapSelected ? (
                            <ListGroupItem
                                key={mapItem.mapItemId}
                                className='list-actions no-border'
                            >
                                <MapListItem
                                    index={index + 1}
                                    id={mapItem.mapItemId}
                                    mapId={props.mapId}
                                    item={mapItem}
                                    fields={props.fields}
                                    handleFieldChange={props.handleFieldChange}
                                    handleFieldFocus={props.handleFieldFocus}
                                    handleSelectedMapItemCleanupClick={
                                        props.handleSelectedMapItemCleanupClick
                                    }
                                    handleRemoveItemClick={
                                        props.handleRemoveItemClick
                                    }
                                    handleItemDetails={props.handleItemDetails}
                                />
                            </ListGroupItem>
                        ) : (
                            <ListGroupItem
                                key={props.selectedMapItem.mapItemId}
                                className='list-actions selected'
                            >
                                <MapListItem
                                    index={index + 1}
                                    selectedMapItem={props.selectedMapItem}
                                    id={props.selectedMapItem.mapItemId}
                                    mapId={props.mapId}
                                    item={props.selectedMapItem}
                                    fields={props.fields}
                                    handleFieldChange={props.handleFieldChange}
                                    handleFieldFocus={props.handleFieldFocus}
                                    handleSelectedMapItemCleanupClick={
                                        props.handleSelectedMapItemCleanupClick
                                    }
                                    handleRemoveItemClick={
                                        props.handleRemoveItemClick
                                    }
                                    handleItemDetails={props.handleItemDetails}
                                />
                            </ListGroupItem>
                        );
                    })}
                </React.Fragment>
            )}
        </ListGroup>
    );
}

mapList.propTypes = {
    mapItems: PropTypes.array,
};

export default injectIntl(mapList);
