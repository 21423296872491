import { AvForm } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';

import { injectIntl } from 'react-intl';
import { Col } from 'reactstrap';
import DynamicForm from '../../../components/DynamicForm/DynamicForm';

function MapFormFields(props) {
    const { formFields = [], scrollAreaRef, loadingDetails } = props;

    let formattedFields = formatFields(formFields);

    useEffect(() => {
        formattedFields = formatFields(formFields);
    }, [formFields]);

    function formatFields(fields) {
        const result = fields.map(field => {
            const boundingBox = field?.boundingBox;
            return {
                name: field?.name,
                key: field?.key,
                formGroup: field?.formGroup,
                type: field?.type,
                constraints: field?.constraints,
                layout: field?.layout,
                boundingBox: {
                    x: boundingBox?.x,
                    y: boundingBox?.y,
                    width: boundingBox?.width,
                    height: boundingBox?.height,
                    page: boundingBox?.page || 1,
                },
                value: field?.value,
                hideMapInDocument: false,
                index: field?.index || 0,
            };
        });
        return result;
    }

    return (
        <AvForm>
            <Col className='cards-fields' xs={12}>
                <DynamicForm
                    fields={formattedFields}
                    fieldsWithError={[]}
                    handleDynamicFieldBlur={props.handleDynamicFieldBlur}
                    handleDynamicFieldFocus={props.handleDynamicFieldFocus}
                    handleDynamicFieldChange={() => {}}
                    loading={loadingDetails}
                    hideFormGroupWhenEmpty
                />
            </Col>
        </AvForm>
    );
}

export default injectIntl(MapFormFields);
