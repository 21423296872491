import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter, useParams } from 'react-router';

import * as actions from '../../store/actions/index';

import { Container } from 'reactstrap';
import messages from './CategoryDetailsPage.intl';

import Modal, { modalTypes } from '../../components/Modal/Modal';
import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import CategoryDetailsForm from '../../components/CategoryDetailsForm/CategoryDetailsForm';

const CategoryDetailsPage = props => {
    const { intl, category, loadingDetails } = props;
    const params = useParams();
    const historyPath = params.historyPath;
    const categoryId = params.id;

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        if (categoryId !== 'newcategory') {
            props.onInitCategoryDetails(categoryId);
        }
    }, []);

    useEffect(
        () => () => {
            props.onCleanCategoryState();
        },
        [],
    );

    const handleSubmit = (event, values) => {
        if (categoryId === 'newcategory') {
            props.onCreateCategory(values);
        } else {
            props.onUpdateCategory(categoryId, values);
        }
    };

    const handleToggleModalDelete = () => setShowDeleteModal(!showDeleteModal);

    const handleDeleteCategory = async () => {
        setShowDeleteModal(false);
        await props.onDeleteCategories(category.categoryId);
    };

    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: `/${historyPath}`,
                        name: intl.formatMessage(messages.titleInvoice),
                        active: false,
                    },
                    {
                        path: `/${historyPath}/documentsconfigs`,
                        name: intl.formatMessage(
                            messages.titleDocumentsConfigs,
                        ),
                        active: false,
                    },
                    {
                        path: `/${historyPath}/documentsconfigs/category/${categoryId}`,
                        name: intl.formatMessage(messages.titleCategory, {
                            entity: `(${categoryId})`,
                        }),
                        active: true,
                    },
                ]}
            />
            <header>
                <section className='title'>
                    <Header
                        title={intl.formatMessage(messages.newCategory)}
                        subtitle={`(${categoryId})`}
                    />
                </section>
            </header>
            <section className='content-middle bg-content'>
                <Loading loading={loadingDetails} />
                <Tabs
                    handleTabClick={() => {}}
                    activeTab={0}
                    tabs={[
                        {
                            name: intl.formatMessage(messages.registrationInfo),
                            component: (
                                <CategoryDetailsForm
                                    category={category}
                                    loading={loadingDetails}
                                    handleSubmit={handleSubmit}
                                    handleDelete={handleToggleModalDelete}
                                    isNew={categoryId === 'newcategory'}
                                />
                            ),
                        },
                    ]}
                />
            </section>
            <Modal
                title={intl.formatMessage(messages.deleteCategory)}
                isOpen={showDeleteModal}
                handleToggleModalDelete={handleToggleModalDelete}
                type={modalTypes.ERROR}
                hasFooter={true}
                firstButtonText={intl.formatMessage(messages.yesText)}
                secondButtonText={intl.formatMessage(messages.noText)}
                toggleFirstButton={handleDeleteCategory}
                toggleSecondButton={handleToggleModalDelete}
            >
                {intl.formatMessage(messages.confirmDeleteText)}
            </Modal>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        category: state.categoryReducer.category,
        loadingDetails: state.categoryReducer.loadingDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //Reducer of DocumentField
        onCreateCategory: values => dispatch(actions.createCategory(values)),
        onInitCategoryDetails: categoryId =>
            dispatch(actions.initCategoryDetails(categoryId)),
        onUpdateCategory: (categoryId, values) =>
            dispatch(actions.updateCategory(categoryId, values)),
        onDeleteCategories: categoryId =>
            dispatch(actions.deleteCategory(categoryId)),
        onCleanCategoryState: () => dispatch(actions.cleanCategoryState()),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(CategoryDetailsPage)),
);
