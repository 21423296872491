import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    // #region CT-e
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    title: {
        id: 'cte.title',
        defaultMessage: 'CT-e',
    },
    invoicesTitle: {
        id: 'invoices.title',
        defaultMessage: 'Documentos',
    },
    cteTab: {
        id: 'cte.cteTab',
        defaultMessage: 'CT-e',
    },
    subtitle: {
        id: 'invoices.subtitle',
        defaultMessage: ' {length} documentos encontrados',
    },
    // #endregion CT-e

    // #region Columns
    titleSource: {
        id: 'cte.titleSource',
        defaultMessage: 'Origem',
    },
    sender: {
        id: 'cte.sender',
        defaultMessage: 'Remetente',
    },
    issuer: {
        id: 'cte.issuer',
        defaultMessage: 'Emitente',
    },
    recipient: {
        id: 'cte.recipient',
        defaultMessage: 'Destinatário',
    },
    accessKey: {
        id: 'cte.accessKey',
        defaultMessage: 'Chave de acesso',
    },
    cteNumber: {
        id: 'cte.cteNumber',
        defaultMessage: 'Número do CT-e',
    },
    invoiceNumber: {
        id: 'invoices.invoiceNumber',
        defaultMessage: 'Número',
    },
    integratedAtDocument: {
        id: 'cte.integratedAtDocument',
        defaultMessage: 'Integrado Em',
    },
    // #endregion Columns
});

export default messages;
