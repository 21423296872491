import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    mapListEmptyText: {
        id: 'maps.mapListEmptyText',
        defaultMessage: 'Nenhuma região foi mapeada',
    },
    itemDetails: {
        id: 'maps.itemDetails',
        defaultMessage: 'Detalhes do item',
    },
});
