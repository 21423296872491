import React, { useEffect, useState } from 'react'
import Switch from '../../Switch/Switch'
import CheckBox from '../../CheckBox/CheckBox'

const CheckBoxSection = (
    { name, 
      items, 
      handleChange, 
      checked, 
      entity, 
      isGroupBy, 
      isReadOnly, 
      count, 
      handleChangeByEntity,
      showSwitchButtons }) => {

    const [stateItems, setStateItems] = useState(false)

    useEffect(() => {
        const allEnabledCitiesSelected = items
            .filter(item => item.entity === entity )
            .map(item => item.value)
            .every(itemValue => {
                return checked[itemValue] === true
            });

        setStateItems(allEnabledCitiesSelected);
    }, [items, checked]);

    const handleSwitchChange = () => {
        const newStateItems = !stateItems
        const selectEntityItems = items.filter(item => item.entity === entity).map(item => item.value)

        if (newStateItems) {

            handleChangeByEntity(selectEntityItems, newStateItems)
        }
        handleChangeByEntity(selectEntityItems, newStateItems)
        setStateItems(newStateItems)
    }

    return (
        <fieldset
            className='form-group'
            inline='true'
            name={name}
            key={name + count}
        >
            {isGroupBy && <legend>{entity}</legend>}
            
            {showSwitchButtons && 
            <Switch
                id={`switch${entity}`}
                label={''}
                checked={stateItems}
                onChange={() => handleSwitchChange()}
                style={{marginLeft: 900}}
            />}

            <div className='form-control border-0 p-0 h-auto is-untouched is-pristine av-valid'>
                {items &&
                    items
                        .filter(x => !isGroupBy || x.entity === entity)
                        .map((item, index) => {
                            return (
                                <CheckBox
                                    label={item.description}
                                    value={item.value}
                                    id={item.value}
                                    key={item.value}
                                    checked={
                                        checked[item.value]
                                            ? true
                                            : false
                                    }
                                    onChange={handleChange}
                                    isReadOnly={isReadOnly}
                                />
                            );
                        })}
            </div>
            <div className='valid-feedback' />
        </fieldset>
    )
}

export default CheckBoxSection;
