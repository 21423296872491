import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import ScrollArea from 'react-scrollbar';
import { Nav } from 'reactstrap';
import messages from './Sidebar.intl';
import NavItemLink from '../NavItemLink/NavItemLink';
import { getAppVersion } from '../../utils/getVersion';

const SideBar = props => {
    const { intl, isMenuCollapsed } = props;
    const iconWidth = 32;

    return (
        <aside className={`menu ${isMenuCollapsed ? 'small' : ''}`}>
            <div className='area-menu'>
                <button className='minimize-menu' onClick={props.collapseMenu}>
                    <FontAwesomeIcon
                        icon={`${
                            isMenuCollapsed ? 'chevron-right' : 'chevron-left'
                        }`}
                    />
                </button>

                <section id='menus'>
                    <div className='menu-system'>
                        <div id='logo-container'>
                            <img
                                src={props.logoSrc}
                                alt='...'
                                id='logo'
                                className='logo'
                            />
                        </div>
                        <img src={props.logoMiniSrc} alt='...' id='logo-mini' />

                        <p id='welcome-user'>
                            <span style={{ color: '#444 !important' }}>
                                {intl.formatMessage(messages.welcome)}
                            </span>
                            <span>{props && props.username}</span>
                        </p>

                        <ScrollArea
                            speed={0.8}
                            className='content-menu'
                            contentClassName='content'
                            horizontal={false}
                        >
                            <div>
                                <Nav vertical className='nav-principal'>
                                    <NavItemLink
                                        id='home'
                                        selected={
                                            window.location.pathname === '/home'
                                        }
                                        iconName={'home'}
                                        iconWidth={iconWidth}
                                        toLink={'/home'}
                                        title={intl.formatMessage(
                                            messages.homeLink,
                                        )}
                                        isMenuCollapsed={isMenuCollapsed}
                                    />

                                    {props.hasClaim('contractor-menu-view') && (
                                        <NavItemLink
                                            id='contractors'
                                            iconName={'contractors'}
                                            iconWidth={iconWidth}
                                            toLink={'/contractors'}
                                            title={intl.formatMessage(
                                                messages.contractorsLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('supplier-menu-view') && (
                                        <NavItemLink
                                            id='suppliers'
                                            iconName={'suppliers'}
                                            iconWidth={iconWidth}
                                            toLink={'/suppliers'}
                                            title={intl.formatMessage(
                                                messages.suppliersLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('order-menu-view') && (
                                        <NavItemLink
                                            id='orders'
                                            iconName={'orders'}
                                            iconWidth={iconWidth}
                                            toLink={'/orders'}
                                            title={intl.formatMessage(
                                                messages.ordersLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('document-menu-view') && (
                                        <NavItemLink
                                            id='documents'
                                            iconName={'nfseDocument'}
                                            iconWidth={iconWidth}
                                            toLink={'/documents'}
                                            title={intl.formatMessage(
                                                messages.nfseLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim(
                                        'nfe-document-menu-view',
                                    ) && (
                                        <NavItemLink
                                            id='nfeDocuments'
                                            iconName={'nfeDocument'}
                                            iconWidth={iconWidth}
                                            toLink={'/nfeDocuments'}
                                            title={intl.formatMessage(
                                                messages.nfeLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim(
                                        'cte-document-menu-view',
                                    ) && (
                                        <NavItemLink
                                            id='cteDocuments'
                                            iconName={'cteDocument'}
                                            iconWidth={iconWidth}
                                            toLink={'/cteDocuments'}
                                            title={intl.formatMessage(
                                                messages.cteLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('document-menu-view') && (
                                        <NavItemLink
                                            id='otherDocuments'
                                            iconName={'otherDocument'}
                                            iconWidth={iconWidth}
                                            toLink={'/otherDocuments'}
                                            title={intl.formatMessage(
                                                messages.otherLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim(
                                        'pending-file-menu-view',
                                    ) && (
                                        <NavItemLink
                                            id='pendingFiles'
                                            iconName={'pendingFiles'}
                                            iconWidth={iconWidth}
                                            toLink={'/pendingFiles'}
                                            title={intl.formatMessage(
                                                messages.pendingFilesLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('integration-view') && (
                                        <NavItemLink
                                            id='import'
                                            iconName={'import'}
                                            iconWidth={iconWidth}
                                            toLink={'/integrations'}
                                            title={intl.formatMessage(
                                                messages.integrationsLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim(
                                        'transaction-menu-view',
                                    ) && (
                                        <NavItemLink
                                            id='transactions'
                                            iconName={'transactions'}
                                            iconWidth={iconWidth}
                                            toLink={'/transactions'}
                                            title={intl.formatMessage(
                                                messages.transactionsLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('map-menu-view') && (
                                        <NavItemLink
                                            id='maps'
                                            iconName={'maps'}
                                            iconWidth={iconWidth}
                                            toLink={'/maps'}
                                            title={intl.formatMessage(
                                                messages.mapsLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('report-view') && (
                                        <NavItemLink
                                            id='reports'
                                            iconName={'documents'}
                                            iconWidth={iconWidth}
                                            toLink={'/reports'}
                                            title={intl.formatMessage(
                                                messages.reports,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('user-menu-view') && (
                                        <NavItemLink
                                            id='users'
                                            iconName={'users'}
                                            iconWidth={iconWidth}
                                            toLink={'/users'}
                                            title={intl.formatMessage(
                                                messages.usersLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('role-menu-view') && (
                                        <NavItemLink
                                            id='profiles'
                                            iconName={'profiles'}
                                            iconWidth={iconWidth}
                                            toLink={'/roles'}
                                            title={intl.formatMessage(
                                                messages.rolesLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('log-menu-view') && (
                                        <NavItemLink
                                            id='logs'
                                            iconName={'logs'}
                                            iconWidth={iconWidth}
                                            toLink={'/logs'}
                                            title={'Logs'}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}

                                    {props.hasClaim('settings-menu-view') && (
                                        <NavItemLink
                                            id='settings'
                                            iconName={'settings'}
                                            iconWidth={iconWidth}
                                            toLink={'/settings'}
                                            title={intl.formatMessage(
                                                messages.settingsLink,
                                            )}
                                            isMenuCollapsed={isMenuCollapsed}
                                        />
                                    )}
                                    <NavItemLink
                                        id='logout'
                                        iconName={'logout'}
                                        iconWidth={iconWidth}
                                        toLink={'/home'}
                                        noSelected
                                        title={intl.formatMessage(
                                            messages.logoutLink,
                                        )}
                                        onClick={props.handleLogoutClick}
                                        isMenuCollapsed={isMenuCollapsed}
                                    />
                                </Nav>
                            </div>
                        </ScrollArea>
                    </div>
                </section>

                <footer className='text-center'>
                    <p>
                        v{getAppVersion()}
                        <br />
                        {intl.formatMessage(messages.developedBy)}{' '}
                        <a
                            href='https://www.evtit.com/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            evt/apps
                        </a>
                    </p>
                </footer>
            </div>
        </aside>
    );
};

export default injectIntl(SideBar);
