import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    name: {
        id: 'suppliers.name',
        defaultMessage: 'Nome',
    },
    contractor: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },
    version: {
        id: 'map.version',
        defaultMessage: 'Versão',
    },
});
