import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TooltipItem(props) {
    const { icon, id, message, placement, delay } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    function Toggle() {
        setTooltipOpen(!tooltipOpen);
    }

    return (
        <span>
            {icon && (
                <FontAwesomeIcon
                    className='ml-2'
                    icon={icon}
                    id={`Tooltip-${id}`}
                />
            )}
            <Tooltip
                placement={placement || 'top'}
                className={`react-tooltip`}
                isOpen={tooltipOpen}
                target={`Tooltip-${id}`}
                toggle={Toggle}
                arrowClassName={`arrow-${placement || 'top'}`}
            >
                {message}
            </Tooltip>
        </span>
    );
}

export default TooltipItem;
