import React from "react";
const NFeIcon = ({
    width = '30',
    height = '30',
    viewBox = "0 0 30 30",
    fill = "none",
}) =>

(
    <svg width={width} height={height} viewBox={viewBox} style = {{marginLeft: '-5px', marginRight: '25px'}} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M24.5 13H2.5C2.22386 13 2 13.2239 2 13.5V20.5C2 20.7761 2.22386 21 2.5 21H11.3361H16.0425H24.5C24.7761 21 25 20.7761 25 20.5V13.5C25 13.2239 24.7761 13 24.5 13Z" fill="black" stroke="black" stroke-width="2" />
        <path d="M8.37944 14.0909V21H7.11772L4.11186 16.6515H4.06126V21H2.6005V14.0909H3.88246L6.8647 18.4361H6.92543V14.0909H8.37944ZM9.58634 21V14.0909H14.1609V15.2953H11.0471V16.9416H13.8573V18.146H11.0471V21H9.58634ZM18.345 17.5927V18.733H15.1941V17.5927H18.345ZM21.9311 21.1012C21.3981 21.1012 20.9393 20.9933 20.5547 20.7773C20.1723 20.5592 19.8777 20.2511 19.6708 19.853C19.4639 19.4527 19.3604 18.9792 19.3604 18.4327C19.3604 17.8997 19.4639 17.4319 19.6708 17.0293C19.8777 16.6267 20.169 16.313 20.5446 16.0881C20.9224 15.8632 21.3655 15.7507 21.8738 15.7507C22.2156 15.7507 22.5339 15.8058 22.8285 15.916C23.1254 16.024 23.384 16.187 23.6044 16.4052C23.8271 16.6233 24.0002 16.8977 24.1239 17.2283C24.2476 17.5567 24.3095 17.9413 24.3095 18.3821V18.7768H19.9339V17.8862H22.9567C22.9567 17.6793 22.9117 17.496 22.8217 17.3363C22.7318 17.1766 22.6069 17.0518 22.4473 16.9618C22.2898 16.8696 22.1065 16.8235 21.8974 16.8235C21.6792 16.8235 21.4858 16.8741 21.3171 16.9753C21.1507 17.0743 21.0202 17.2081 20.9258 17.3768C20.8313 17.5432 20.783 17.7288 20.7807 17.9334V18.7802C20.7807 19.0366 20.8279 19.2581 20.9224 19.4448C21.0191 19.6315 21.1552 19.7754 21.3306 19.8766C21.506 19.9778 21.7141 20.0284 21.9547 20.0284C22.1144 20.0284 22.2606 20.0059 22.3933 19.9609C22.526 19.916 22.6396 19.8485 22.734 19.7585C22.8285 19.6686 22.9004 19.5584 22.9499 19.4279L24.2791 19.5156C24.2116 19.835 24.0733 20.1139 23.8642 20.3523C23.6573 20.5884 23.3896 20.7729 23.0613 20.9055C22.7351 21.036 22.3584 21.1012 21.9311 21.1012Z" fill="white" strokeOpacity="0" />
        <path d="M8.4 23.6V27.4C8.3996 27.5572 8.43017 27.7129 8.48997 27.8583C8.54976 28.0037 8.6376 28.1359 8.74847 28.2473C8.85934 28.3587 8.99108 28.4472 9.13615 28.5077C9.28123 28.5683 9.43681 28.5996 9.594 28.6H26.4C26.5572 28.6004 26.7129 28.5698 26.8583 28.51C27.0037 28.4502 27.1359 28.3624 27.2473 28.2515C27.3587 28.1407 27.4472 28.0089 27.5077 27.8638C27.5683 27.7188 27.5996 27.5632 27.6 27.406V27.4V9.4H22C21.7878 9.4 21.5843 9.31571 21.4343 9.16569C21.2843 9.01566 21.2 8.81217 21.2 8.6V3H9.6C9.28277 3 8.97846 3.1256 8.75358 3.34935C8.52871 3.5731 8.40158 3.87678 8.4 4.194V10.4045" stroke="black" stroke-width="2.15" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M27.25 8.25L22.355 3.35C22.2433 3.23866 22.1107 3.15044 21.9648 3.09039C21.819 3.03033 21.6627 2.99962 21.505 3H21.2V8.6C21.2 8.81218 21.2843 9.01566 21.4343 9.16569C21.5843 9.31572 21.7878 9.4 22 9.4H27.6V9.095C27.6 8.93807 27.5691 8.78268 27.509 8.63769C27.449 8.49271 27.361 8.36097 27.25 8.25Z" stroke="black" stroke-width="2.15" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default NFeIcon;