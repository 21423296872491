import { defineMessages } from "react-intl";
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
  ...globalMessages,
  primaryColorText: {
    id: "detailsTab.primaryColorText",
    defaultMessage: "Cor primária"
  },
  secondaryColorText: {
    id: "detailsTab.secondaryColorText",
    defaultMessage: "Cor secundária"
  },
  logoNormalText: {
    id: "detailsTab.logoNormalText",
    defaultMessage: "Logo"
  },
  logoMiniText: {
    id: "detailsTab.logoMiniText",
    defaultMessage: "Logo mini"
  }
});

export default messages;
