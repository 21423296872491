import React from 'react';

import Checkbox from '../CheckBox/CheckBox';
import CheckBoxSection from './components/CheckBoxSection';

const checkBoxList = props => {
    let count = 0;
    if (props.groupby) {
        return props.groupby.map((entity, index) => {
            count++;
            return (
                <CheckBoxSection
                    name={props.name}
                    items={props.items}
                    handleChange={props.handleChange}
                    handleChangeByEntity={props.handleChangeByEntity}
                    checked={props.checked}
                    entity={entity}
                    isReadOnly={props.readOnly}
                    isGroupBy={props.groupby}
                    count={count}
                    showSwitchButtons={props.showSwitchButtons} />
            );
        });
    } else {
        return (
            <CheckBoxSection
                name={props.name}
                items={props.items}
                handleChange={props.handleChange}
                handleChangeByEntity={props.handleChangeByEntity}
                checked={props.checked}
                entity={''}
                isReadOnly={props.readOnly}
                isGroupBy={props.groupby}
                count={count}
                showSwitchButtons={props.showSwitchButtons} />
        )
    }
};

export default checkBoxList;
