import React from 'react';
import { useState, useEffect, useCallback } from 'react';

import { Rect, Group, Text } from 'react-konva';

const BoxesView = props => {
    const {
        newBox,
        transformer,
        readOnly,
        color,
        verbose,
        pageNumber,
        boxes,
    } = props;

    const [boxesRect, setBoxes] = useState([]);

    useEffect(() => changeBoxes(), []);

    useEffect(() => changeBoxes(), [boxes, pageNumber]);

    const changeBoxes = useCallback(() => {
        const boxesHasNumber = boxes.find(box => box?.page);
        const newBoxes = boxesHasNumber
            ? boxes.filter(box => box?.page === pageNumber)
            : boxes;
        setBoxes(newBoxes);
    }, [boxes, pageNumber]);

    return (
        <Group>
            {boxesRect.map(box => {
                if (!box) return null;

                return (
                    <Rect
                        key={box.key}
                        name={box.key}
                        x={box.x}
                        y={box.y}
                        width={box.width}
                        height={box.height}
                        fill='transparent'
                        stroke={color}
                        onDragEnd={props.handleRectChange}
                        onTransformEnd={props.handleRectChange}
                        draggable={!readOnly}
                    />
                );
            })}
            {!readOnly &&
                boxesRect.map((box, index) => {
                    const x = box.x - 18;
                    const y = box.y + 5;
                    const text = index + 1;

                    return (
                        <Text
                            key={index}
                            x={x}
                            y={y}
                            text={text}
                            fontSize={16}
                            fontStyle='normal'
                            align='right'
                            fill={color}
                            stroke={color}
                            strokeWidth={0}
                            shadowColor='#f9f9f9'
                            shadowBlur={2}
                            shadowOpacity={10}
                        />
                    );
                })}
            {verbose &&
                boxesRect.map((box, index) => {
                    if (!box) return null;

                    const x = box.x + box.width + 10;
                    const y = box.y + 5;

                    const text = `X: ${box.x}\nY: ${box.y}\nW: ${
                        box.width
                    }\nH: ${box.height}`;

                    return (
                        <Text
                            key={index}
                            x={x}
                            y={y}
                            text={text}
                            fontSize={16}
                            fontStyle='normal'
                            align='left'
                            fill={'red'}
                            stroke={'red'}
                            strokeWidth={0}
                            shadowColor='#f9f9f9'
                            shadowBlur={2}
                            shadowOpacity={10}
                        />
                    );
                })}
            {newBox && (
                <Rect
                    name={newBox.key}
                    key={newBox.key}
                    x={newBox.x}
                    y={newBox.y}
                    width={newBox.width}
                    height={newBox.height}
                    fill='transparent'
                    stroke={'blue'}
                />
            )}
            {transformer}
        </Group>
    );
};

export default BoxesView;
