import { defineMessages } from "react-intl";

import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,

  title: {
    id: "company.title",
    defaultMessage: "Contratantes"
  },

  titleContractor: {
    id: "company.titleContractor",
    defaultMessage: "Contratante {entity}"
  },

  titleCompany: {
    id: "company.titleCompany",
    defaultMessage: "Filial {entity}"
  },

  deleteCompany: {
    id: "company.deleteCompany",
    defaultMessage: "Excluir Filial"
  },
  
  home: {
    id: "home.title",
    defaultMessage: "Início"
  }
});

export default messages;
