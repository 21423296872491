import messages from '../../DynamicTemplateForm/DynamicTemplateForm.intl';

export const nfeTemplate = () => [
    //#region  Cabeçalho
    {
        key: '/nfeProc/protNFe/infProt/chNFe',
        name: 'Chave XML',
        formGroup: 1,
        size: 12,
    },
    {
        key: '/nfeProc/NFe/infNFe/@Id',
        name: 'ID do documento',
        formGroup: 1,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/ide/nNF',
        name: 'Número da NF-e',
        formGroup: 1,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/ide/serie',
        name: 'Série',
        formGroup: 1,
        size: 2,
    },
    {
        key: '/nfeProc/NFe/infNFe/ide/dhEmi',
        name: 'Data emissão',
        formGroup: 1,
        size: 6,
    },
    //#endregion Cabeçalho

    //#region  Emitente
    {
        key: '/nfeProc/NFe/infNFe/emit/xNome',
        name: 'Nome/Razão social',
        formGroup: 2,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/emit/CNPJ',
        name: 'CNPJ/CPF',
        formGroup: 2,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/emit/CPF',
        name: 'CNPJ/CPF',
        formGroup: 2,
        size: 6,
    },
    //#endregion  Emitente

    //#region  Pagador
    {
        key: '/nfeProc/NFe/infNFe/dest/xNome',
        name: 'Nome/Razão social',
        formGroup: 3,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/dest/CNPJ',
        name: 'CNPJ/CPF',
        formGroup: 3,
        size: 6,
    },
    //#endregion  Pagador

    //#region Valores Totais
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vProd',
        name: 'Produtos e Serviços',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vNF',
        name: 'Valor total da NF-e',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vFrete',
        name: 'Frete',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vSeg',
        name: 'Seguro',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vDesc',
        name: 'Descontos',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vOutro',
        name: 'Despesas Acessórias',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vBC',
        name: 'Base ICMS',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vICMS',
        name: 'ICMS',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vICMSDeson',
        name: 'ICMS Desonerado',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vBCST',
        name: 'Base ICMS ST',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vST',
        name: 'ICMS ST',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vIPI',
        name: 'IPI',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vPIS',
        name: 'PIS',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vCOFINS',
        name: 'COFINS',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ISSQNtot/vISS',
        name: 'ISS',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ISSQNtot/vBC',
        name: 'BASE ISS',
        formGroup: 4,
        size: 4,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/ICMSTot/vII',
        name: 'II',
        formGroup: 4,
        size: 4,
    },
    //#endregion Valores Totais

    //#region Impostos Retidos
    {
        key: '/nfeProc/NFe/infNFe/total/retTrib/vRetPIS',
        name: 'PIS Retido',
        formGroup: 5,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/retTrib/vRetCSLL',
        name: 'CSLL Retido',
        formGroup: 5,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/retTrib/vBCIRRF',
        name: 'Base IRRF',
        formGroup: 5,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/retTrib/vIRRF',
        name: 'IRRF Retido',
        formGroup: 5,
        size: 6,
    },
    {
        key: '/nfeProc/NFe/infNFe/total/retTrib/vRetCOFINS',
        name: 'CONFINS Retido',
        formGroup: 5,
        size: 6,
    },
    //#endregion Impostos Retidos
];

export const nfeFormGroupTemplate = intl => {
    return [
        {
            formGroup: 1,
            headerTitle: intl.formatMessage(messages.generalTitle),
        },
        {
            formGroup: 2,
            headerTitle: intl.formatMessage(messages.issuer),
        },
        {
            formGroup: 3,
            headerTitle: intl.formatMessage(messages.payerTitle),
        },
        {
            formGroup: 4,
            headerTitle: intl.formatMessage(messages.totalValuesTitle),
        },
        {
            formGroup: 5,
            headerTitle: intl.formatMessage(messages.withholdingTaxesTitle),
        },
        {
            formGroup: 6,
            headerTitle: intl.formatMessage(messages.taxesTitle),
        },
        {
            formGroup: 7,
            headerTitle: intl.formatMessage(messages.othersTitle),
        },
    ];
};
