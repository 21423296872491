import React from 'react';

import Header from '../Header/Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl } from 'react-intl';
import { withRouter, useHistory } from 'react-router-dom';

import EvtAlternativeFilter from '../EvtAlternativeFilter/EvtAlternativeFilter';
import EvtFilter from '../EvtFilter/EvtFilter';
import globalMessages from '../../utils/globalMessages';

const HeaderTable = props => {
    const {
        intl,
        title,
        titleNewButton,
        isAlternativeFilter,
        properties,
        handleFiltersChange,
        loading,
        onClickReload,
        onClickNewButton,
        newButtonEnabled,
        documentFieldButtonEnabled,
        hideSearchBar,
        newButtonText = null,
    } = props;

    const history = useHistory();

    return (
        <header className='table-screen'>
            <section className='title'>
                <Header title={`${title}`} />
            </section>
            <section className='btns-topo'>
                {isAlternativeFilter ? (
                    <EvtAlternativeFilter
                        properties={properties}
                        handleFiltersChange={handleFiltersChange}
                        loading={loading}
                        hideSearchBar={hideSearchBar}
                    />
                ) : (
                    <EvtFilter
                        properties={properties}
                        handleFiltersChange={handleFiltersChange}
                        loading={loading}
                        hideSearchBar={hideSearchBar}
                    />
                )}
                {documentFieldButtonEnabled && (
                    <button
                        className={`new-btn small`}
                        onClick={() => {
                            history.push(
                                `${history.location.pathname}/documentsconfigs`,
                            );
                        }}
                    >
                        <FontAwesomeIcon icon='cog' size='2x' />
                    </button>
                )}
                <button
                    className={`new-btn small ${loading ? 'loading' : ''}`}
                    onClick={onClickReload}
                >
                    <FontAwesomeIcon icon='sync' size='1x' />
                </button>
                {newButtonEnabled && (
                    <button className='new-btn' onClick={onClickNewButton}>
                        {newButtonText ||
                            intl.formatMessage(globalMessages.newButtonText, {
                                entity: `${titleNewButton || title}`,
                            })}
                    </button>
                )}
            </section>
        </header>
    );
};

export default injectIntl(withRouter(HeaderTable));
