import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Header from '../Header/Header';

function DetailsHeader(props) {
    const { routes, headerTitle, headerClassName } = props;
    return (
        <>
            <Breadcrumb routes={routes} />
            <header className={headerClassName || 'table-screen'}>
                <section className='title'>
                    <Header title={headerTitle} />
                </section>
            </header>
        </>
    );
}

DetailsHeader.propTypes = {
    routes: PropTypes.array.isRequired,
    headerTitle: PropTypes.string.isRequired || PropTypes.object.isRequired,
};

export default DetailsHeader;
