import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const IPIForm = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const nfeIPI = nfeItem?.imposto?.ipi?.ipiObject;

    return (
        <>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.CST)}
                            type='text'
                            name='cstIpiDescription'
                            id='cstIpiDescription'
                            value={
                                `${nfeIPI?.cst} - ${
                                    nfeIPI?.cstIpiDescription
                                }` || ''
                            }
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vBC)}
                            type='text'
                            name='vBC'
                            id='vBC'
                            value={nfeIPI?.vBC || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pIPI)}
                            type='text'
                            name='pIPI'
                            id='pIPI'
                            value={nfeIPI?.pIPI || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.qUnid)}
                            type='text'
                            name='qUnid'
                            id='qUnid'
                            value={nfeIPI?.qUnid || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                {' '}
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vUnid)}
                            type='text'
                            name='vUnid'
                            id='vUnid'
                            value={nfeIPI?.vUnid || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vIPI)}
                            type='text'
                            name='vIPI'
                            id='vIPI'
                            value={nfeIPI?.vIPI || ''}
                            errorMessage=' '
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(IPIForm);
