import React from "react";

const DocumentIcon = ({
  width="26",
  height="30",
  viewBox="0 0 36 40",
  fill="none",
  style=""
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {style}
    xmlns="http://www.w3.org/2000/svg">
  >
    <path d="M18 2H5.2C4.35131 2 3.53737 2.33714 2.93726 2.93726C2.33714 3.53737 2 4.35131 2 5.2V30.8C2 31.6487 2.33714 32.4626 2.93726 33.0627C3.53737 33.6629 4.35131 34 5.2 34H24.4C25.2487 34 26.0626 33.6629 26.6627 33.0627C27.2629 32.4626 27.6 31.6487 27.6 30.8V11.6L18 2Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 2V11.6H27.6" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.2 19.6H8.39999" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.2 26H8.39999" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6 13.2H9.99999H8.39999" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default DocumentIcon;
