import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    shippingDate: {
        id: 'orders.shippingDate',
        defaultMessage: 'Data de remessa',
    },
    orderItemTitle: {
        id: 'orderItem.orderItemTitle',
        defaultMessage: 'Itens do pedido',
    },
    titleOrderItem: {
        id: 'orderItem.titleOrderItem',
        defaultMessage: 'Item {entity}',
    },
    subtitleOrderItem: {
        id: 'orderItem.subtitleOrderItem',
        defaultMessage: ' item(s) encontrado(s)',
    },
    quantity: {
        id: 'orderItem.quantity',
        defaultMessage: 'Quantidade',
    },
    measureUnity: {
        id: 'orderItem.measureUnity',
        defaultMessage: 'Unidade de Medida',
    },
    ncm: {
        id: 'orderItem.ncm',
        defaultMessage: 'NCM',
    },
    totalNetValue: {
        id: 'orderItem.totalNetValue',
        defaultMessage: 'Valor Líquido Total',
    },
    totalGrossValue: {
        id: 'orderItem.totalGrossValue',
        defaultMessage: 'Valor Bruto Total',
    },
    itemOrder: {
        id: 'orderItem.itemOrder',
        defaultMessage: 'Item',
    },
    cnpjTaker: {
        id: 'orderItem.cnpjTaker',
        defaultMessage: 'CNPJ Tomador',
    },
    unActiveItem: {
        id: 'orders.unActiveItem',
        defaultMessage: 'Item indisponível',
    },
});

export default messages;
