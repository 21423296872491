import React from 'react';
import { Badge } from 'reactstrap';
import Axios from 'axios';

import messages from '../AssociatedDocumentsModal.intl';
import { calculateColumnWidth } from '../../EvtTable/EvtTable';
import DropdownListButton from '../../DropdownListButton/DropdownListButton';

export const formatToCurrency = value => {
    const valueFormatted = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);

    return valueFormatted;
};

export const columnsAssociatedDocuments = (
    associatedDocuments,
    intl,
    documentType,
) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.titleSupplier, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'supplierName',
        minWidth: calculateColumnWidth(
            associatedDocuments,
            'supplierName',
            intl.formatMessage(messages.titleSupplier, {
                entity: '',
            }),
        ),
        minWidth: calculateColumnWidth(
            associatedDocuments,
            'supplierName',
            intl.formatMessage(messages.titleSupplier, {
                entity: '',
            }),
        ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.docType)}</span>
            </div>
        ),
        accessor: 'documentType',
        Cell: row => {
            let docType = row.value;

            switch (String(docType).toUpperCase()) {
                case 'RAW_FILE':
                    docType = 'Arquivo avulso';
                    break;
                case '1':
                    docType = 'NFe';
                    break;
                case '2':
                    docType = 'NFSe';
                    break;
                case '5':
                    docType = 'Outros Pagamentos';
                    break;
                default:
            }
            return <p>{docType}</p>;
        },
        minWidth:
            40 +
            calculateColumnWidth(
                associatedDocuments,
                'documentType',
                intl.formatMessage(messages.docType),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.titleInvoice)}</span>
            </div>
        ),
        accessor: 'documentNumber',
        sortMethod: (a, b) => {
            const A = Number(a) || Number.MAX_SAFE_INTEGER;
            const B = Number(b) || Number.MAX_SAFE_INTEGER;
            return A - B;
        },
        Cell: row => {
            const rowValue = row.value
                ?.replace('.pdf', '')
                .replace('.jpg', '')
                .replace('.png', '')
                .replace('.xml', '');

            return <p>{rowValue}</p>;
        },
        minWidth:
            40 +
            calculateColumnWidth(
                associatedDocuments,
                'documentNumber',
                intl.formatMessage(messages.titleInvoice),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.createdAt)}</span>
            </div>
        ),
        accessor: 'createdAt',
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        maxWidth:
            40 +
            calculateColumnWidth(
                associatedDocuments,
                'createdAt',
                intl.formatMessage(messages.createdAt),
            ),
        minWidth: calculateColumnWidth(
            associatedDocuments,
            'createdAt',
            intl.formatMessage(messages.createdAt),
        ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>
                    {intl.formatMessage(messages.categoryType, {
                        entity: '',
                    })}
                </span>
            </div>
        ),
        accessor: 'categoryType',
        minWidth:
            40 +
            calculateColumnWidth(
                associatedDocuments,
                'categoryType',
                intl.formatMessage(messages.categoryType, {
                    entity: '',
                }),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalValue)}</span>
            </div>
        ),
        accessor: 'totalValue',
        Cell: row => {
            const totalValue =
                row.original?.form?.fields?.find(e => e.key === 'totalValue')
                    ?.value || '';

            const result = String(totalValue);

            return (
                <div className='customer-container'>
                    <div className=''>{result}</div>
                </div>
            );
        },
        minWidth:
            40 +
            calculateColumnWidth(
                associatedDocuments,
                'totalValue',
                intl.formatMessage(messages.totalValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),
        id: 'status',
        sortMethod: (a, b) => {
            const preference = {
                'status-invoice status-success': 1,
                'status-invoice status-pending': 2,
                'status-invoice status-error': 3,
            };

            const A =
                preference[a.props.className.toLowerCase()] ||
                Number.MAX_SAFE_INTEGER;
            const B =
                preference[b.props.className.toLowerCase()] ||
                Number.MAX_SAFE_INTEGER;

            return A - B;
        },
        accessor: d => {
            let color = '';

            if (d.currentStep.status.toLowerCase() === 'pending') {
                color += 'warning';
            } else if (d.currentStep.status.toLowerCase() === 'success') {
                color += 'success';
            } else if (d.currentStep.status.toLowerCase() === 'error') {
                color += 'danger';
            }
            return (
                <Badge
                    style={{ fontSize: '0.8rem', color: '#ffffff' }}
                    color={color}
                    pill
                >
                    {d.currentStep.text}
                </Badge>
            );
        },
        minWidth:
            45 +
            calculateColumnWidth(
                associatedDocuments,
                'currentStep.text',
                intl.formatMessage(messages.status),
            ),
    },
    {
        accessor: 'download',
        style: {
            display: 'flex',
            justifyContent: 'end',
        },
        Cell: row => {
            const isRawFile = row.original.documentType === 'RAW_FILE';
            const fileName = row.original.documentNumber
                ?.replace('.pdf', '')
                .replace('.jpg', '')
                .replace('.png', '')
                .replace('.xml', '');

            const handleDownloadFile = (documentId, fileName) => {
                const urlDownload = `${window.API_URI}/${documentType ||
                    'Documents'}/${documentId}/File`;

                Axios({
                    url: urlDownload,
                    method: 'GET',
                    responseType: 'blob',
                }).then(response => {
                    const extension = response.data.type.split('/')[1];
                    const href = URL.createObjectURL(response.data);

                    // Create element "a" with href to file and click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `${fileName}.${extension}`); // Add extension and file name dynamically
                    document.body.appendChild(link);
                    link.click();

                    // Clean up "a" element
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                });
            };

            return (
                <DropdownListButton
                    items={[
                        //DownloadRawFile
                        {
                            name: intl.formatMessage(messages.fileDownload),
                            onClick: () =>
                                handleDownloadFile(
                                    row.original.documentId,
                                    fileName,
                                ),
                            disabled: !isRawFile,
                        },
                    ]}
                />
            );
        },
        maxWidth: 50,
    },
];
