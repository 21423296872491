import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './SuppliersTable.intl';

import EvtTable, { calculateColumnWidth } from '../EvtTable/EvtTable';

import { AuthContext } from '../../context/AuthContext';

class SuppliersTable extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    handleEvTableStateChange = query => {
        const { contractorId } = this.props;

        if (contractorId) {
            this.setState(
                {
                    query,
                },
                () => {
                    const { query } = this.state;
                    this.props.onInitSuppliers(contractorId, query);
                },
            );
        }
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.history.push(
                    `/suppliers/${rowInfo.original.supplierId}`,
                ),
        };
    };

    render() {
        const { intl, loadingList, suppliers, suppliersCount } = this.props;
        const { filters } = this.state;

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.displayName)}</span>
                    </div>
                ),
                accessor: 'displayName',
                Cell: row => {
                    var id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    suppliers,
                    'displayName',
                    intl.formatMessage(messages.displayName),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.cnpj)}</span>
                    </div>
                ),
                accessor: 'documentNumber1',
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>
                                {row.value.length === 14
                                    ? row.value.replace(
                                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                          '$1.$2.$3/$4-$5',
                                      )
                                    : row.value.replace(
                                          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                                          '$1.$2.$3-$4',
                                      )}
                            </div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    suppliers,
                    'documentNumber1',
                    intl.formatMessage(messages.cnpj),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'emailAddress',
                minWidth: calculateColumnWidth(
                    suppliers,
                    'emailAddress',
                    intl.formatMessage(messages.email),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.city)}</span>
                    </div>
                ),
                accessor: 'address.city',
                minWidth: calculateColumnWidth(
                    suppliers,
                    'address.city',
                    intl.formatMessage(messages.city),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.state)}</span>
                    </div>
                ),
                accessor: 'address.state',
                minWidth: calculateColumnWidth(
                    suppliers,
                    'address.state',
                    intl.formatMessage(messages.state),
                ),
            },
        ];
        return (
            <EvtTable
                filters={filters}
                columns={columns}
                data={suppliers}
                length={suppliersCount}
                pageSize={10}
                handleStateChange={this.handleEvTableStateChange}
                handleGetTrProps={this.handleGetTrProps}
                loading={loadingList}
                defaultSorted={[
                    {
                        id: 'createdAt',
                        desc: true,
                    },
                ]}
                tabInside
                fetchPage
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        suppliers: state.supplierReducer.suppliers || [],
        suppliersCount: state.supplierReducer.suppliersCount || 0,
        loadingList: state.supplierReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitSuppliers: (contractorId, filter) =>
            dispatch(actions.initSuppliersByContractor(contractorId, filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(SuppliersTable)),
);
