import React from 'react'
import { injectIntl } from 'react-intl'
import globalMessages from '../../utils/globalMessages'
import Modal from "../Modal/Modal"

const ModalConfirmReturnToReview = (props) => {
    const { intl, handleToggleReturnToReviewModal, showReturnToReviewModal, handleReturnToReviewStepClick } = props
    return (
        <Modal
            title={intl.formatMessage(globalMessages.confirmReturnToReviewTitle)}
            isOpen={showReturnToReviewModal}
            handleToggle={() => handleToggleReturnToReviewModal()}
            hasFooter={true}
            firstButtonClass='delete'
            secondButtonClass='btn-submit'
            firstButtonText={intl.formatMessage(globalMessages.noText)}
            secondButtonText={intl.formatMessage(globalMessages.yesText)}
            toggleFirstButton={() => handleToggleReturnToReviewModal()}
            toggleSecondButton={handleReturnToReviewStepClick}
        >
            <p>Atenção:</p>
            {intl.formatMessage(globalMessages.confirmReturnToReviewText)}
        </Modal>
    )
}

export default injectIntl(ModalConfirmReturnToReview)
