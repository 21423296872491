import React, { Component } from 'react';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

import messages from '../IntegrationListPage.intl';
import { filterPropertyTypes } from '../../../utils/enums';
import { AuthContext } from '../../../context/AuthContext';

import Header from '../../../components/Header/Header';
import EvtTable from '../../../components/EvtTable/EvtTable';
import EvtFilter from '../../../components/EvtFilter/EvtFilter';

import { columnsNfs } from '../components/ColumnsIntegrations';

class NFSeListIntegration extends Component {
    static contextType = AuthContext;

    state = {
        filtersNFSe: [],
        filterValue: '',
        query: '',
    };

    reloadIntegrationsList() {
        const { query, filterValue } = this.state;
        const urlParams = new URLSearchParams(query);
        const take = urlParams.get('take') || 10;
        const skip = urlParams.get('skip') || 0;
        const sort = urlParams.get('sort') || '-createdAt';
        const querySearch = `?skip=${skip}&take=${take}&sort=${sort}&index=${
            this.props.activeTab
        }`;
        const queryURL = filterValue
            ? querySearch
            : query + `&index=${this.props.activeTab}`;
        const url = filterValue
            ? queryURL + `&search=${filterValue}`
            : queryURL;

        window.history.replaceState(url, undefined, 'integrations' + url);

        this.props.onInitIntegrationsNfseSearch(queryURL, filterValue);
        this.props.onInitIntegrationsNfseSearchCount(queryURL, filterValue);
    }

    handleReloadButton = () => {
        this.reloadIntegrationsList();
    };

    handleEvTableStateChangeNfse = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadIntegrationsList();
            },
        );
    };

    handleFiltersNfseChange = (filtersNFSe, value) => {
        this.setState({
            filtersNFSe,
            filterValue: value,
        });
    };

    render() {
        const {
            integrationsNfse,
            integrationsNfseCount,
            loadingList,
            intl,
        } = this.props;

        const NFSeColumns = columnsNfs(integrationsNfse, intl);

        const filterFields = [
            {
                key: 'supplierCNPJ',
                value: intl.formatMessage(messages.supplierCNPJ),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'contractorCNPJ',
                value: intl.formatMessage(messages.contractorCNPJ),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'beginDate',
                value: intl.formatMessage(messages.beginDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'endDate',
                value: intl.formatMessage(messages.endDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'executionDate',
                value: intl.formatMessage(messages.executionDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'isSuccess',
                value: intl.formatMessage(messages.status),
                type: filterPropertyTypes.ENUM,
                options: [
                    {
                        key: 'true',
                        _map: text =>
                            text.replace(
                                intl
                                    .formatMessage(messages.statusSuccess)
                                    .toLowerCase(),
                                true,
                            ),
                        value: intl.formatMessage(messages.statusSuccess),
                    },
                    {
                        key: 'false',
                        _map: text =>
                            text.replace(
                                intl
                                    .formatMessage(messages.statusFailed)
                                    .toLowerCase(),
                                false,
                            ),
                        value: intl.formatMessage(messages.statusFailed, {
                            entity: '',
                        }),
                    },
                ],
            },
            {
                key: 'message',
                value: intl.formatMessage(messages.errorMessage),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'ibgeCityCode',
                value: intl.formatMessage(messages.ibgeCode),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'cityName',
                value: intl.formatMessage(messages.cityName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'uf',
                value: intl.formatMessage(messages.uf),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'amountDocumentsReturned',
                value: intl.formatMessage(messages.amountDocumentsReturned),
                type: filterPropertyTypes.NUMBER,
            },
        ];

        return (
            <div>
                <header className='table-screen'>
                    <section className='title'>
                        <Header title={intl.formatMessage(messages.title)} />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={filterFields}
                            handleFiltersChange={this.handleFiltersNfseChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleReloadButton}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                    </section>
                </header>
                <section>
                    <EvtTable
                        filters={this.state.filtersNFSe}
                        columns={NFSeColumns}
                        data={integrationsNfse}
                        length={integrationsNfseCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChangeNfse}
                        handleGetTrProps={this.props.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'executionDate',
                                desc: true,
                            },
                        ]}
                        countHeaderText={intl.formatMessage(messages.subtitle, {
                            length: integrationsNfseCount,
                        })}
                        fetchPage
                    />
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        integrationsNfse: state.integrationReducer.integrationsNfse || [],
        integrationsNfseCount:
            state.integrationReducer.integrationsNfseCount || 0,
        loadingList: state.integrationReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitIntegrationsNfseSearch: (query, search) =>
            dispatch(actions.initIntegrationsNfseSearch(query, search)),
        onInitIntegrationsNfseSearchCount: (query, search) =>
            dispatch(actions.initIntegrationsNfseSearchCount(query, search)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(NFSeListIntegration)),
);
