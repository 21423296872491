import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';

//Get Document Fields

export const initDocumentFields = (filter, includeDocument) => {
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_FIELDS_GET_LIST_REQUEST,
        });

        const query = isDocument =>
            filter
                ? `${filter}&filter[includeDocument]=like:${isDocument}`
                : ``;

        axios
            .get(`/Documents/DocumentFields${query(includeDocument)}`)
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_GET_LIST_SUCCESS,
                    fields: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initDocumentFieldsCount = (filter, includeDocument) => {
    return async dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_FIELDS_GET_COUNT_REQUEST,
        });

        const query = isDocument =>
            filter
                ? `${filter}&filter[includeDocument]=like:${isDocument}`
                : ``;

        await axios
            .get(`/Documents/DocumentFields/Count${query(includeDocument)}`)
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_GET_COUNT_SUCCESS,
                    fieldsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

//Document Field Details

export const initDocumentFieldsDetails = documentFieldId => {
    return async dispatch => {
        if (
            documentFieldId === 'newdocumentfield' ||
            documentFieldId === 'newmapfield'
        ) {
            dispatch({
                type: actionTypes.DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.DOCUMENT_FIELDS_DOCUMENTS_GET_DETAILS_REQUEST,
            });

            try {
                const response = await axios.get(
                    `/Documents/DocumentFields/${documentFieldId}`,
                );
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_GET_DETAILS_SUCCESS,
                    field: response.data,
                });
            } catch (error) {
                toast.error('Campo não encontrado');
                dispatch(push('/documents/documentsconfigs'));
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_GET_DETAILS_FAILURE,
                    error,
                });
            }
        }
    };
};

export const getDocumentField = (documentFieldId, tabIndex, historyPath) => {
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_FIELDS_GET_DETAILS_REQUEST,
        });
        dispatch(
            push(
                `/${historyPath ||
                    'documents'}/documentsconfigs/documentfield/${documentFieldId}`,
                {
                    tabIndex,
                },
            ),
        );
    };
};

export const createDocumentField = documentFieldInputModel => {
    const tabIndex = documentFieldInputModel.includeDocument ? 0 : 1;
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_FIELDS_CREATE_REQUEST,
        });

        axios
            .post('/Documents/DocumentFields', { ...documentFieldInputModel })
            .then(response => {
                toast.success('Campo criado com sucesso');
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_CREATE_SUCCESS,
                    field: response.data,
                });
                dispatch(
                    push({
                        pathname: `/documents/documentsconfigs`,
                        state: {
                            tabIndex,
                        },
                    }),
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_CREATE_FAILURE,
                    error,
                });
            });
    };
};

export const updateDocumentField = (documentFieldUpdate, documentFieldId) => {
    const tabIndex = documentFieldUpdate.includeDocument ? 0 : 1;

    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_FIELDS_PUT_REQUEST,
        });
        axios
            .put(`/Documents/DocumentFields/${documentFieldId}`, {
                ...documentFieldUpdate,
            })
            .then(() => {
                toast.success('Campo salvo com sucesso');
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_PUT_SUCCESS,
                    field: documentFieldUpdate,
                });
                dispatch(
                    push({
                        pathname: `/documents/documentsconfigs`,
                        state: {
                            tabIndex,
                        },
                    }),
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteDocumentField = documentFieldId => {
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_FIELDS_DELETE_REQUEST,
        });
        axios
            .delete(`/Documents/DocumentFields/${documentFieldId}`)
            .then(() => {
                toast.success('Campo excluido com sucesso');
                dispatch(
                    push({
                        pathname: `/documents/documentsconfigs`,
                        state: {
                            tabIndex: 0,
                        },
                    }),
                );
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_DELETE_SUCCESS,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_DELETE_REQUEST,
                    error,
                });
            });
    };
};

// Get Contractors
export const getContractors = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.DOCUMENT_FIELDS_CONTRACTORS_REQUEST,
        });

        axios
            .get(`/Contractors?sort=asc&skip=0&take=100`)
            .then(response => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_CONTRACTORS_SUCCESS,
                    contractors: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.DOCUMENT_FIELDS_CONTRACTORS_FAILURE,
                    error,
                });
            });
    };
};
