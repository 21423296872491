import React from 'react';
import { NavLink as ReactNavLink } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import Icon from '../../assets/icons/Index';

const NavItemLink = props => {
    const {
        iconName,
        iconWidth,
        selected,
        title,
        toLink,
        onClick,
        id,
        noSelected,
        isMenuCollapsed,
    } = props;

    const adjustedTitle = title.length > 13 && window.screen.width === 1280 ? (
      <>{title.substring(0, 9)} <br />
        {title.substring(9)}</>
     ) : (
      title
    );   
        
    return (
        <>
            <NavItem id={`Tooltip-${id}`}>
                <NavLink
                    className={`sidebar-link ${selected ? 'selected' : ''}`}
                    activeClassName={noSelected ? 'notSelected ' : 'selected'}
                    to={toLink || '/'}
                    tag={ReactNavLink}
                    onClick={onClick ? onClick : () => {}}
                    title={!isMenuCollapsed ? '' : title}
                >
                    <Icon
                        style={{
                            marginRight: '20px',
                        }}
                        width={iconWidth}
                        name={iconName}
                    />
                    <p>{adjustedTitle}</p>
                </NavLink>
            </NavItem>
        </>
    );
};

export default NavItemLink;
