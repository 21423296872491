import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import messages from '../FileUploadForm.intl';

import { AvForm } from 'availity-reactstrap-validation';
import { Col, Row, FormGroup } from 'reactstrap';
import { useLocation, useParams } from 'react-router';

import Uploader from '../../Uploader/Uploader';
import ModalMultiplesOrdersDocument from '../../ModalMultiplesOrdersDocument/ModalMultiplesOrdersDocument';
import FilesTable from '../../FilesTable/FilesTable';

function UploadOrderForm(props) {
    const {
        files,
        setFiles,
        dropzoneActive,
        handleUploaderDrop,
        handleUploaderDragEnter,
        handleUploaderDragLeave,
        handleCropConfirmClick,
        selectedDocument,
        setOrderItemsList,
        orderItemsList,
        isDocument,
        documentType,
        intl,
    } = props;
    const [searchItems, setSearchItems] = useState(false);
    const [allowedFileTypes, setallowedFileTypes] = useState(
        'image/jpeg, image/png, image/gif, image/bmp, application/pdf, application/x-pdf, application/xml, text/xml',
    );
    const location = useLocation();
    const params = useParams();
    const isNew = params.id === 'new';
    const supplierId = isNew
        ? location.state?.supplierId
        : selectedDocument?.supplierId;
    const isNFE = documentType === 'NFE';
    const isCTE = documentType === 'CTE';
    const isRawFile = documentType === 'RAW_FILE';
    function handleFormSubmit(event, values) {}

    function handleToggleModalItems() {
        setSearchItems(!searchItems);
    }

    function handleOrderListSubmit(values) {
        setOrderItemsList(values);
    }

    useEffect(() => {
        if (isNFE || isCTE) {
            setallowedFileTypes('application/xml, text/xml');
        } else {
            setallowedFileTypes(
                'image/jpeg, image/png, image/gif, image/bmp, application/pdf, application/x-pdf, application/xml, text/xml',
            );
        }
    }, [documentType]);

    return (
        <>
            <section>
                <AvForm onValidSubmit={handleFormSubmit}>
                    <Row>
                        <Col md={isRawFile ? 8 : 12}>
                            <Uploader
                                files={files}
                                dropzoneActive={dropzoneActive}
                                onDrop={handleUploaderDrop}
                                onDragEnter={handleUploaderDragEnter}
                                onDragLeave={handleUploaderDragLeave}
                                fileType={allowedFileTypes}
                                className={props.uploaderClassName}
                                multiple={isRawFile}
                            />
                        </Col>
                        {isRawFile && (
                            <Col md={4}>
                                <FilesTable files={files} setFiles={setFiles} />
                            </Col>
                        )}
                    </Row>
                    {!!isDocument && (
                        <Row>
                            {isRawFile ? (
                                <Col md={12}>
                                    <button
                                        type='button'
                                        className='btn-submit'
                                        disabled={props.loading}
                                        onClick={handleCropConfirmClick}
                                        style={{
                                            float: 'right',
                                            marginLeft: 10,
                                        }}
                                    >
                                        {intl.formatMessage(
                                            messages.confirmButton,
                                        )}
                                    </button>
                                </Col>
                            ) : (
                                <Col md={!!orderItemsList?.length ? 2 : 12}>
                                    <button
                                        type='button'
                                        className='btn-submit'
                                        disabled={props.loading}
                                        onClick={handleToggleModalItems}
                                        style={{
                                            float: 'left',
                                            marginLeft: 10,
                                        }}
                                    >
                                        {intl.formatMessage(
                                            messages.addOrderButton,
                                        )}
                                    </button>
                                </Col>
                            )}
                            {!!orderItemsList?.length && (
                                <Col xs={12} md={8}>
                                    <FormGroup
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <label
                                            className='text'
                                            style={{ marginTop: 3 }}
                                        >
                                            {intl.formatMessage(
                                                messages.linkItemInDocument,
                                                {
                                                    entity:
                                                        `${
                                                            orderItemsList?.length
                                                        }` || '',
                                                },
                                            )}
                                        </label>
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                    )}
                </AvForm>
            </section>
            {!!isDocument && (
                <ModalMultiplesOrdersDocument
                    isOpen={searchItems}
                    handleToggle={handleToggleModalItems}
                    supplierId={supplierId}
                    handleFormSubmit={handleOrderListSubmit}
                />
            )}
        </>
    );
}

export default injectIntl(UploadOrderForm);
