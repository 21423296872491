import React from 'react';
import { injectIntl } from 'react-intl';

import { Row, Col, CardHeader, Card } from 'reactstrap';

import DynamicPanel from '../../components/DynamicPanel/DynamicPanel';
import DynamicField from '../../components/DynamicField/DynamicField';
import { formGroupTypes } from '../../utils/enums';
import messages from './DynamicForm.intl';

export function groupBy(array, key) {
    return array.reduce(
        (acc, item) => ({
            ...acc,
            [item[key]]: [...(acc[item[key]] ?? []), item],
        }),
        {},
    );
}

const dynamicForm = props => {
    const {
        intl,
        readOnly,
        fieldsWithError,
        documentType,
        fields,
        hideFormGroupWhenEmpty,
    } = props;

    let rows = [];
    let cteFields = [];
    let isOther = documentType === 'OTHER' ?? true;

    const fieldsList = fields || [];
    const fieldsByIndex = groupBy(fieldsList, 'index');

    Object.entries(fieldsByIndex).forEach((indexFields, index) => {
        const [key, fields] = indexFields;
        let components = [];
        for (let i = 1; i <= 12; i++) {
            const hasFields = fields?.find(field => field.formGroup === i);
            
            //hide services and middlewear when is OTHER or hasHideFormGroupWhenEmpty
            if (
                (isOther && (i === 5 || i === 4)) ||
                (hideFormGroupWhenEmpty && !hasFields)
            ) {
                continue;
            }

            if (hasFields)
            {
                components.push(
                <DynamicPanel
                    key={i}
                    index={i}
                    headerTitle={
                        i === formGroupTypes.GENERAL
                            ? intl.formatMessage(messages.generalTitle)
                            : i === formGroupTypes.PROVIDER
                            ? intl.formatMessage(messages.providerTitle)
                            : i === formGroupTypes.BORROWER
                            ? intl.formatMessage(messages.borrowerTitle)
                            : i === formGroupTypes.MIDDLEWARE
                            ? intl.formatMessage(messages.middlewareTitle)
                            : i === formGroupTypes.SERVICES
                            ? intl.formatMessage(messages.servicesTitle)
                            : i === formGroupTypes.TAXES
                            ? intl.formatMessage(messages.taxesTitle)
                            : i === formGroupTypes.OTHERS
                            ? intl.formatMessage(messages.othersTitle)
                            : i === formGroupTypes.PARTS
                            ? intl.formatMessage(messages.partsTitle)
                            : i === formGroupTypes.DOCUMENT
                            ? intl.formatMessage(messages.documentValues)
                            : i === formGroupTypes.FEDERALTAXES
                            ? intl.formatMessage(messages.federalTaxes)
                            : i === formGroupTypes.MUNICIPALTAXES
                            ? intl.formatMessage(messages.municipalTaxes)
                            : i === formGroupTypes.TEXTS
                            ? intl.formatMessage(messages.texts)
                            : ''
                    }
                    hasFields={hasFields}
                >
                    <Row>
                        {fields.map((field, index) => {
                            const className =
                                fieldsWithError.find(
                                    fkey => fkey === field.key,
                                ) && 'av-invalid is-invalid';

                            if (field.hideMapInDocument) return null;

                            let disabledField = readOnly;

                            if (
                                field.isDocumentField &&
                                props.isRevisionStep &&
                                props.hasDocumentUpdateClaim
                            ) {
                                disabledField = false;
                            }

                            return (
                                i === field.formGroup && (
                                    <Col
                                        key={index}
                                        className={field.layout.classes}
                                        lg={field.layout.lg}
                                        md={field.layout.md}
                                        xs={field.layout.xs}
                                        offsetlg={field.layout.offsetLg}
                                        offsetmd={field.layout.offsetMd}
                                        offsetsm={field.layout.offsetSm}
                                        offsetxl={field.layout.offsetXl}
                                        offsetxs={field.layout.offsetXs}
                                        sm={field.layout.sm}
                                        xl={field.layout.xl}
                                        style={
                                            field.type.toLowerCase() === 'list'
                                                ? { paddingBottom: 16 }
                                                : {}
                                        }
                                    >
                                        <DynamicField
                                            key={index}
                                            label={field.name}
                                            textList={field.textList}
                                            type={
                                                field.type === 'textarea'
                                                    ? 'textarea'
                                                    : field.type === 'boolean'
                                                    ? 'boolean'
                                                    : field.type.toLowerCase() ===
                                                      'list'
                                                    ? 'list'
                                                    : 'text'
                                            }
                                            name={field.key}
                                            id={field.key}
                                            value={field.value}
                                            documentFieldsListId={
                                                field.documentFieldsListId
                                            }
                                            minLength={
                                                field.constraints.minLength
                                            }
                                            maxLength={
                                                field.constraints.maxLength
                                            }
                                            pattern={field.constraints.pattern}
                                            required={false}
                                            disabled={disabledField}
                                            className={className}
                                            handleDynamicFieldFocus={
                                                props.handleDynamicFieldFocus
                                            }
                                            handleDynamicFieldChange={
                                                props.handleDynamicFieldChange
                                            }
                                            onBlur={
                                                props.handleDynamicFieldBlur
                                            }
                                            rows={field.constraints.rows}
                                            field={field}
                                        />
                                    </Col>
                                )
                            );
                        })}
                    </Row>
                </DynamicPanel>,
                );
            }
        }
        rows.push({ index, components });
    });

    return (
        <>
            {rows.length < 1 && hideFormGroupWhenEmpty ? (
                <Card
                    tabIndex={0}
                    style={{
                        marginBottom: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 10,
                    }}
                >
                    <CardHeader
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 20,
                        }}
                    >
                        {'Não há dados a serem mostrados. Faça o Teste do OCR.'}
                    </CardHeader>
                </Card>
            ) : (
                <></>
            )}
            {rows.map(row => {
                return (
                    <div>
                        {rows.length > 1 && <h5>DOCUMENTO {row.index + 1}</h5>}
                        <div>{row.components}</div>
                    </div>
                );
            })}
        </>
    );
};

export default injectIntl(dynamicForm);
