import React, { useEffect, useState } from 'react';
import EvtSelect from '../EvtSelect/EvtSelect';
import { AvField } from 'availity-reactstrap-validation';

const SelectFilterField = props => {
    const { label, disabled, filter } = props;

    const [selectItem, setSelectItem] = useState([]);

    const property = props.properties.find(x => x.key === filter.property);

    useEffect(() => {
        if (!!filter.value) {
            property.getDetails(setSelectItem, filter.value);
        }
    }, [filter.value]);

    const handleSelectedOptionChange = option => {
        props.handleFieldChange(
            { target: { value: option.value } },
            filter.id,
            'value',
        );
        setSelectItem(option);
    };

    return (
        <>
            <EvtSelect
                style={{ textAlign: 'left' }}
                url={property.urlToRequest}
                label={label}
                name={`filters[${filter.id}].select`}
                id={`filters[${filter.id}].select`}
                labelKey={property.labelKey || 'name'}
                labelKey2={property.labelKey2 || null}
                labelKey3={property.labelKey3 || null}
                labelKey4={property.labelKey4 || null}
                valueLabel={property.valueLabel || null}
                labelBrackets={property.labelBrackets || null}
                labelDate={property.labelDate || null}
                labelWithMask={property.labelWithMask || null}
                valueKey={filter.property}
                disabled={disabled}
                placeholder='Escolha um...'
                take='10'
                handleSelectedOptionChange={handleSelectedOptionChange}
                option={
                    selectItem && {
                        selectedValueOption: selectItem.value,
                        selectedLabelOption: selectItem.label,
                    }
                }
                errorMessage=''
                async
            />
            <AvField
                style={{
                    visibility: 'hidden',
                    overflow: 'hidden',
                    margin: 0,
                    padding: 0,
                    width: 0,
                    height: 0,
                }}
                id={`filters[${filter.id}].value`}
                name={`filters[${filter.id}].value`}
                value={selectItem.value}
                type='text'
            />
        </>
    );
};

export default SelectFilterField;
