import React from 'react';
const OtherIcon = ({
    width = '33',
    height = '30',
    viewBox = '0 0 33 30',
    fill = 'none',
}) => (
    <svg
        width={width}
        height={height}
        viewBox={viewBox}
        style={{ marginLeft: '-5px', marginRight: '25px' }}
        fill={fill}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M26.2969 13H3.60938C3.32461 13 3.09375 13.2239 3.09375 13.5V20.5C3.09375 20.7761 3.32461 21 3.60938 21H12.7216H17.5751H26.2969C26.5816 21 26.8125 20.7761 26.8125 20.5V13.5C26.8125 13.2239 26.5816 13 26.2969 13Z'
            fill='black'
            stroke='black'
            stroke-width='2'
        />
        <path
            d='M8.39028 17.4545C8.39028 18.0097 8.28505 18.482 8.07459 18.8714C7.86578 19.2609 7.58074 19.5583 7.21947 19.7638C6.85986 19.9677 6.45551 20.0696 6.00641 20.0696C5.55399 20.0696 5.14798 19.9668 4.78837 19.7614C4.42875 19.5559 4.14455 19.2584 3.93574 18.869C3.72693 18.4795 3.62253 18.008 3.62253 17.4545C3.62253 16.8994 3.72693 16.4271 3.93574 16.0376C4.14455 15.6482 4.42875 15.3516 4.78837 15.1477C5.14798 14.9422 5.55399 14.8395 6.00641 14.8395C6.45551 14.8395 6.85986 14.9422 7.21947 15.1477C7.58074 15.3516 7.86578 15.6482 8.07459 16.0376C8.28505 16.4271 8.39028 16.8994 8.39028 17.4545ZM7.29902 17.4545C7.29902 17.0949 7.24516 16.7917 7.13744 16.5447C7.03138 16.2978 6.88141 16.1105 6.68751 15.9829C6.49362 15.8553 6.26659 15.7915 6.00641 15.7915C5.74623 15.7915 5.51919 15.8553 5.3253 15.9829C5.13141 16.1105 4.9806 16.2978 4.87288 16.5447C4.76682 16.7917 4.71379 17.0949 4.71379 17.4545C4.71379 17.8141 4.76682 18.1174 4.87288 18.3643C4.9806 18.6113 5.13141 18.7985 5.3253 18.9261C5.51919 19.0537 5.74623 19.1175 6.00641 19.1175C6.26659 19.1175 6.49362 19.0537 6.68751 18.9261C6.88141 18.7985 7.03138 18.6113 7.13744 18.3643C7.24516 18.1174 7.29902 17.8141 7.29902 17.4545Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M11.6162 18.3743V16.1818H12.6752V20H11.6585V19.3065H11.6187C11.5325 19.5302 11.3892 19.71 11.1887 19.8459C10.9898 19.9818 10.747 20.0497 10.4603 20.0497C10.2051 20.0497 9.98057 19.9917 9.78668 19.8757C9.59279 19.7597 9.44115 19.5948 9.33178 19.381C9.22406 19.1672 9.16937 18.9112 9.16772 18.6129V16.1818H10.2267V18.424C10.2283 18.6494 10.2888 18.8275 10.4081 18.9584C10.5274 19.0894 10.6874 19.1548 10.8879 19.1548C11.0155 19.1548 11.1348 19.1258 11.2458 19.0678C11.3569 19.0082 11.4464 18.9203 11.5143 18.8043C11.5839 18.6883 11.6179 18.545 11.6162 18.3743Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M15.5531 16.1818V16.9773H13.2537V16.1818H15.5531ZM13.7758 15.267H14.8347V18.8267C14.8347 18.9245 14.8496 19.0007 14.8795 19.0554C14.9093 19.1084 14.9507 19.1457 15.0037 19.1672C15.0584 19.1888 15.1214 19.1996 15.1927 19.1996C15.2424 19.1996 15.2921 19.1954 15.3418 19.1871C15.3915 19.1772 15.4296 19.1697 15.4562 19.1648L15.6227 19.9528C15.5697 19.9693 15.4951 19.9884 15.399 20.0099C15.3029 20.0331 15.186 20.0472 15.0485 20.0522C14.7933 20.0621 14.5696 20.0282 14.3773 19.9503C14.1867 19.8724 14.0384 19.7514 13.9324 19.5873C13.8263 19.4233 13.7741 19.2161 13.7758 18.9659V15.267Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M16.2429 20V16.1818H17.2695V16.848H17.3093C17.3789 16.611 17.4958 16.432 17.6598 16.3111C17.8239 16.1884 18.0128 16.1271 18.2266 16.1271C18.2796 16.1271 18.3368 16.1304 18.3981 16.1371C18.4594 16.1437 18.5133 16.1528 18.5597 16.1644V17.104C18.51 17.0891 18.4412 17.0759 18.3534 17.0643C18.2655 17.0527 18.1851 17.0469 18.1122 17.0469C17.9565 17.0469 17.8172 17.0808 17.6946 17.1488C17.5736 17.2151 17.4775 17.3079 17.4063 17.4272C17.3367 17.5465 17.3019 17.6841 17.3019 17.8398V20H16.2429Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M20.6546 20.0746C20.2685 20.0746 19.9345 19.9925 19.6528 19.8285C19.3727 19.6627 19.1565 19.4324 19.004 19.1374C18.8515 18.8408 18.7753 18.4969 18.7753 18.1058C18.7753 17.7114 18.8515 17.3667 19.004 17.0717C19.1565 16.7751 19.3727 16.5447 19.6528 16.3807C19.9345 16.215 20.2685 16.1321 20.6546 16.1321C21.0407 16.1321 21.3738 16.215 21.6539 16.3807C21.9356 16.5447 22.1527 16.7751 22.3051 17.0717C22.4576 17.3667 22.5338 17.7114 22.5338 18.1058C22.5338 18.4969 22.4576 18.8408 22.3051 19.1374C22.1527 19.4324 21.9356 19.6627 21.6539 19.8285C21.3738 19.9925 21.0407 20.0746 20.6546 20.0746ZM20.6595 19.2543C20.8352 19.2543 20.9819 19.2045 21.0995 19.1051C21.2172 19.004 21.3059 18.8665 21.3655 18.6925C21.4268 18.5185 21.4575 18.3204 21.4575 18.0984C21.4575 17.8763 21.4268 17.6783 21.3655 17.5043C21.3059 17.3302 21.2172 17.1927 21.0995 17.0916C20.9819 16.9905 20.8352 16.94 20.6595 16.94C20.4822 16.94 20.3331 16.9905 20.2121 17.0916C20.0928 17.1927 20.0025 17.3302 19.9412 17.5043C19.8815 17.6783 19.8517 17.8763 19.8517 18.0984C19.8517 18.3204 19.8815 18.5185 19.9412 18.6925C20.0025 18.8665 20.0928 19.004 20.2121 19.1051C20.3331 19.2045 20.4822 19.2543 20.6595 19.2543Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M26.3993 17.2706L25.4298 17.3302C25.4132 17.2474 25.3776 17.1728 25.3229 17.1065C25.2682 17.0386 25.1961 16.9847 25.1066 16.9449C25.0188 16.9035 24.9136 16.8828 24.7909 16.8828C24.6269 16.8828 24.4885 16.9176 24.3758 16.9872C24.2631 17.0551 24.2068 17.1463 24.2068 17.2606C24.2068 17.3518 24.2432 17.4288 24.3162 17.4918C24.3891 17.5548 24.5142 17.6053 24.6915 17.6435L25.3826 17.7827C25.7538 17.8589 26.0305 17.9815 26.2128 18.1506C26.3951 18.3196 26.4863 18.5417 26.4863 18.8168C26.4863 19.067 26.4125 19.2866 26.265 19.4755C26.1192 19.6644 25.9187 19.8119 25.6635 19.918C25.4099 20.0224 25.1174 20.0746 24.786 20.0746C24.2805 20.0746 23.8778 19.9693 23.5779 19.7589C23.2796 19.5467 23.1047 19.2584 23.0534 18.8938L24.0949 18.8391C24.1264 18.9932 24.2026 19.1109 24.3236 19.1921C24.4446 19.2717 24.5995 19.3114 24.7885 19.3114C24.9741 19.3114 25.1232 19.2758 25.2359 19.2045C25.3502 19.1316 25.4082 19.038 25.4099 18.9236C25.4082 18.8275 25.3676 18.7488 25.2881 18.6875C25.2086 18.6245 25.0859 18.5765 24.9202 18.5433L24.259 18.4116C23.8861 18.337 23.6085 18.2077 23.4262 18.0238C23.2456 17.8398 23.1553 17.6053 23.1553 17.3203C23.1553 17.075 23.2216 16.8637 23.3542 16.6864C23.4884 16.5091 23.6765 16.3724 23.9184 16.2763C24.162 16.1801 24.4471 16.1321 24.7735 16.1321C25.2558 16.1321 25.6353 16.234 25.912 16.4378C26.1904 16.6417 26.3528 16.9193 26.3993 17.2706Z'
            fill='white'
            strokeOpacity="0"
        />
        <path
            d='M9.69376 23.6V27.4C9.69335 27.5572 9.72487 27.7129 9.78654 27.8583C9.8482 28.0037 9.93879 28.1359 10.0531 28.2473C10.1675 28.3587 10.3033 28.4472 10.4529 28.5077C10.6025 28.5683 10.763 28.5996 10.9251 28.6H28.2563C28.4184 28.6004 28.5789 28.5698 28.7289 28.51C28.8788 28.4502 29.0152 28.3624 29.13 28.2515C29.2449 28.1407 29.3362 28.0089 29.3986 27.8638C29.4611 27.7188 29.4933 27.5632 29.4938 27.406V27.4V9.4H23.7188C23.4999 9.4 23.2901 9.31571 23.1354 9.16569C22.9807 9.01566 22.8938 8.81217 22.8938 8.6V3H10.9313C10.6041 3 10.2903 3.1256 10.0584 3.34935C9.82649 3.5731 9.69539 3.87678 9.69376 4.194V10.4045'
            stroke='black'
            stroke-width='2.15'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M29.1328 8.25L24.0848 3.35C23.9696 3.23866 23.8329 3.15044 23.6824 3.09039C23.5321 3.03033 23.3709 2.99962 23.2083 3H22.8937V8.6C22.8937 8.81218 22.9807 9.01566 23.1354 9.16569C23.29 9.31572 23.4999 9.4 23.7187 9.4H29.4937V9.095C29.4937 8.93807 29.4619 8.78268 29.3999 8.63769C29.338 8.49271 29.2473 8.36097 29.1328 8.25Z'
            stroke='black'
            stroke-width='2.15'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default OtherIcon;
