import React from 'react';
import messages from '../OrderItemsTable.intl';
import { calculateColumnWidth } from '../../EvtTable/EvtTable';

export const itemsColumns = (intl, orderItems) => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.itemOrder)}</span>
            </div>
        ),
        accessor: 'itemOrder',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            400 +
            calculateColumnWidth(
                orderItems,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                orderItems,
                'itemOrder',
                intl.formatMessage(messages.itemOrder),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.description)}</span>
            </div>
        ),
        accessor: 'description',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            400 +
            calculateColumnWidth(
                orderItems,
                'description',
                intl.formatMessage(messages.description),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                orderItems,
                'description',
                intl.formatMessage(messages.description),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.quantity)}</span>
            </div>
        ),
        accessor: 'quantity',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const quantityValue = String(row.value);
            const value = quantityValue.includes('.')
                ? quantityValue.replace('.', ',')
                : quantityValue;
            return (
                <div className='customer-container'>
                    <div className=''>{`${value}${
                        row.original.measureUnity
                    }`}</div>
                </div>
            );
        },
        maxWidth:
            100 +
            calculateColumnWidth(
                orderItems,
                'quantity',
                intl.formatMessage(messages.quantity),
            ),
        minWidth:
            20 +
            calculateColumnWidth(
                orderItems,
                'quantity',
                intl.formatMessage(messages.quantity),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.ncm)}</span>
            </div>
        ),
        accessor: 'ncm',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            200 +
            calculateColumnWidth(
                orderItems,
                'ncm',
                intl.formatMessage(messages.ncm),
            ),
        minWidth:
            50 +
            calculateColumnWidth(
                orderItems,
                'ncm',
                intl.formatMessage(messages.ncm),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalNetValue)}</span>
            </div>
        ),
        Cell: props =>
            new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(props.value),
        accessor: 'totalNetValue',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                orderItems,
                'totalNetValue',
                intl.formatMessage(messages.totalNetValue),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                orderItems,
                'totalNetValue',
                intl.formatMessage(messages.totalNetValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.totalGrossValue)}</span>
            </div>
        ),
        Cell: props =>
            new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(props.value),
        accessor: 'totalGrossValue',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            250 +
            calculateColumnWidth(
                orderItems,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                orderItems,
                'totalGrossValue',
                intl.formatMessage(messages.totalGrossValue),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.createdAt)}</span>
            </div>
        ),
        accessor: 'createdAt',
        style: {
            fontSize: 'small',
        },
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);
            return (
                <div className='customer-container'>
                    <div className=''>{date}</div>
                </div>
            );
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                orderItems,
                'createdAt',
                intl.formatMessage(messages.createdAt),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.shippingDate)}</span>
            </div>
        ),
        Cell: row => {
            const date = new Date(row.value).toLocaleDateString('pt-BR', 
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'UTC',
            });
            const [shippingDate, hour] =
                String(date) !== 'Invalid Date' ? date?.split(' ') : '';
            return (
                <div className='customer-container'>
                    <div className=''>{shippingDate}</div>
                </div>
            );
        },
        accessor: 'shippingDate',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            60 +
            calculateColumnWidth(
                orderItems,
                'shippingDate',
                intl.formatMessage(messages.shippingDate),
            ),
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.cnpjTaker)}</span>
            </div>
        ),
        accessor: 'cnpjTaker',
        style: {
            fontSize: 'small',
        },
        maxWidth:
            300 +
            calculateColumnWidth(
                orderItems,
                'cnpjTaker',
                intl.formatMessage(messages.cnpjTaker),
            ),
        minWidth:
            40 +
            calculateColumnWidth(
                orderItems,
                'cnpjTaker',
                intl.formatMessage(messages.cnpjTaker),
            ),
    },
];
