import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    newPasswordLabelText: {
        id: 'settings.newPasswordLabelText',
        defaultMessage: 'Nova senha',
    },

    confirmPasswordLabelText: {
        id: 'settings.confirmPasswordLabelText',
        defaultMessage: 'Confirmar senha',
    },

    passwordDoesNotMatchValidationText: {
        id: 'settings.passwordDoesNotMatchValidationText',
        defaultMessage: 'As senhas não são iguais',
    },

    returnButton: {
        id: 'global.returnButton',
        defaultMessage: 'Retornar',
    },
});

export default messages;
