import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'suppliers.title',
        defaultMessage: 'Fornecedores',
    },

    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },

    registrationInfo: {
        id: 'suppliers.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    deleteSupplier: {
        id: 'suppliers.deleteSupplier',
        defaultMessage: 'Excluir Fornecedor',
    },

    companyName: {
        id: 'detailsTab.companyName',
        defaultMessage: 'Razão Social',
    },

    displayName: {
        id: 'detailsTab.displayName',
        defaultMessage: 'Nome Fantasia',
    },

    cnpj: {
        id: 'detailsTab.cpfcnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    email: {
        id: 'detailsTab.email',
        defaultMessage: 'E-mail',
    },

    city: {
        id: 'detailsTab.city',
        defaultMessage: 'Cidade',
    },

    state: {
        id: 'detailsTab.state',
        defaultMessage: 'Estado',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    registrationInfoUsers: {
        id: 'suppliers.registrationInfoUsers',
        defaultMessage: 'Usuários',
    },

    titleMap: {
        id: 'maps.title',
        defaultMessage: 'Mapeamentos',
    },
    
    selectMap: {
        id: 'maps.selectMap',
        defaultMessage: 'Selecione o Mapeamento',
    },

    mapAlreadyAdded: {
        id: 'suppliers.mapAlreadyAdded',
        defaultMessage: 'Fornecedor já possui o mapeamento selecionado'
    }
});

export default messages;
