import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Layer, Stage, Image, Transformer } from 'react-konva';
import * as pdfjs from 'pdfjs-dist';

import Toolbar from './Toolbar/Toolbar';
import BoxesView from './Boxes/Boxes';
import Loading from '../Loading/Loading';

import { viewerModeTypes } from '../../utils/enums';

import { AuthContext } from '../../context/AuthContext';
import Cookies from 'js-cookie';
import Pagination from '../Pagination/Pagination';
import MapDetailsViewer from './components/MapsDetailsViewer';

class Viewer extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            scale: 1,
            width: 0,
            height: 0,
            mode: viewerModeTypes.POINTER,
            moving: false,
            moveInitialPosition: null,
            moveScrollPosition: { x: 0, y: 0 },
            newBox: null,
            imageLoader: true,
            verbose: false,
            showVerboseBoxes: false,
            //pdf
            loadingTask: null,
            pdfDoc: null,
            page: 0,
            docNumPages: null,
        };

        this.divRef = React.createRef();
        this.stageRef = React.createRef();
    }

    componentDidMount() {
        const url = this.props.url || {};

        const sessionCookie = Cookies.get('SessionId');
        var loadingTask = pdfjs.getDocument(
            url + '?sessionId=' + sessionCookie,
        );
        loadingTask.promise
            .then(
                pdf => {
                    this.setState({
                        pdfDoc: pdf,
                        docNumPages: pdf.numPages,
                    });
                    this.image = new window.Image();

                    this.renderPage(0);
                },
                function(erro) {
                    throw erro;
                },
            )
            .catch(page => {
                this.image = new window.Image();

                this.image.onload = () => {
                    if (this.imageNode) {
                        this.imageNode.getLayer().batchDraw();
                    }

                    const widthProportion = this.divRef.current
                        ? this.divRef.current.clientWidth / this.image.width
                        : this.image.width;
                    this.setScale(null, widthProportion * 0.98);

                    this.setState({
                        width: this.image.width * 3, // max zoom
                        height: this.image.height * 3, // max zoom
                        imageLoader: !this.state.imageLoader,
                    });
                };

                this.image.src = url;
            });
    }

    componentDidUpdate(prevProps, prevState) {
        const { boxes, selectedItem } = this.props;
        const { scale, mode, page } = this.state;
        const div = this.stageRef.current.attrs.container;

        if (
            boxes !== prevProps.boxes &&
            (boxes.length > 0 && boxes.length === 1)
        ) {
            const box = boxes[0];

            const x = box.x * scale;
            const halfBoxWidth = (box.width * scale) / 2;
            const halfContainerWidth = (div.clientWidth - halfBoxWidth) / 2;
            const newX = x - halfContainerWidth;

            const y = box.y * scale;

            var scrollOptions = {
                left: newX,
                top: y,
                behavior: 'smooth',
            };
            div.scrollTo(scrollOptions);

            this.setState({
                mode: viewerModeTypes.POINTER,
                moving: false,
                moveInitialPosition: null,
            });
        } else if (
            mode === viewerModeTypes.POINTER &&
            boxes !== prevProps.boxes &&
            boxes.length > 1
        ) {
            div.scrollTo(0, 0);
        }

        if (prevState.page !== page) {
            this.renderPage(page);
        }

        if (selectedItem && prevProps.selectedItem !== selectedItem) {
            const itemPage = selectedItem?.boundingBox.page || 1;
            this.handleSetPage(itemPage - 1);
        }
    }

    handleStageWheel = e => {
        if (!e.evt.altKey) return;

        e.evt.preventDefault();

        const value = e.evt.wheelDelta > 0 ? 0.1 : -0.1;
        this.handleZoomChange(e, value);
    };

    handleZoomChange = (e, value) => {
        const scale = parseFloat((this.state.scale + value).toFixed(1));

        if (scale < 0 || scale > 3) return;

        this.setState(
            {
                scale,
            },
            () => {
                this.stageRef.current.getStage().scale({
                    x: this.state.scale,
                    y: this.state.scale,
                });

                this.stageRef.current.getStage().batchDraw();
            },
        );
    };

    handleDownloadClick = () => {
        const urlDownload = this.props.urlDownload || {};

        window.open(urlDownload, 'Download');
    };

    handleDownloadXmlClick = () => {
        const urlXml = this.props.urlXml || {};
        var a = document.createElement('a');
        a.href = urlXml;
        a.setAttribute('download', '');
        a.click();
        return false;
    };

    setScale = (e, value) => {
        this.setState(
            {
                ...this.state,
                scale: parseFloat(value.toFixed(6)),
            },
            () => {
                this.stageRef.current.getStage().scale({
                    x: this.state.scale,
                    y: this.state.scale,
                });

                this.stageRef.current.getStage().batchDraw();
            },
        );
    };

    handleStageMouseDown = e => {
        switch (this.state.mode) {
            case viewerModeTypes.POINTER:
                if (this.props.readOnly) return;

                if (e.target.className === 'Rect') {
                    const name = e.target.name();
                    const box = this.props.boxes.find(b => b.key === name);

                    if (box) {
                        const stage = this.transformerNode.getStage();
                        const selectedRect = stage.findOne('.' + box.key);

                        if (selectedRect === this.transformerNode.node()) {
                            return;
                        }

                        if (selectedRect) {
                            this.transformerNode.attachTo(selectedRect);
                        } else {
                            this.transformerNode.detach();
                        }

                        this.transformerNode.getLayer().batchDraw();
                        this.props.handleBoxSelectionChange &&
                            this.props.handleBoxSelectionChange(box);
                    }
                } else if (e.target.className === 'Transformer') {
                    return;
                } else {
                    this.transformerNode.detach();
                    this.transformerNode.getLayer().batchDraw();
                    this.props.handleBoxSelectionChange &&
                        this.props.handleBoxSelectionChange(null);
                }
                break;
            case viewerModeTypes.ADD:
                if (this.props.readOnly) return;

                const stage = this.stageRef.current.getStage();
                const pos = stage.getPointerPosition();

                const x = Math.round(pos.x / this.state.scale);
                const y = Math.round(pos.y / this.state.scale);

                if (this.state.newBox !== null) {
                    const newBox = {
                        ...this.state.newBox,
                    };

                    newBox.width = x - this.state.newBox.x;
                    newBox.height = y - this.state.newBox.y;

                    if (newBox.width < 0) {
                        newBox.x = newBox.x + newBox.width;
                        newBox.width = -newBox.width;
                    }

                    if (newBox.height < 0) {
                        newBox.y = newBox.y + newBox.height;
                        newBox.height = -newBox.height;
                    }

                    this.props.handleNewBoxClick(newBox, this.props.mapVersion);
                    this.setState({
                        ...this.state,
                        newBox: null,
                    });
                } else {
                    const key =
                        '_' +
                        Math.random()
                            .toString(36)
                            .substr(2, 9);

                    const newBox = {
                        key: key,
                        x: x,
                        y: y,
                        width: 0,
                        height: 0,
                    };

                    this.setState({
                        ...this.state,
                        newBox,
                    });
                }

                this.props.handleBoxSelectionChange &&
                    this.props.handleBoxSelectionChange(null);
                break;
            case viewerModeTypes.MOVE:
                const pointerPos = this.stageRef.current
                    .getStage()
                    .getPointerPosition();

                this.setState({
                    moving: true,
                    moveInitialPosition: pointerPos,
                });
                break;
            default:
                return;
        }
    };

    handleStageMouseUp = e => {
        const { mode, moving } = this.state;
        if (mode === viewerModeTypes.MOVE && moving) {
            this.setState({
                moving: false,
                moveInitialPosition: null,
            });
        }
    };

    handleRectChange = e => {
        const rect = e.target;
        let changedBox = this.props.boxes.find(b => b.key === rect.attrs.name);

        changedBox.x = rect.x();
        changedBox.y = rect.y();
        changedBox.width = Math.round(rect.width() * rect.scaleX());
        changedBox.height = Math.round(rect.height() * rect.scaleY());

        rect.scaleX(1);
        rect.scaleY(1);

        this.props.handleBoxChange(changedBox);
    };

    handleMoveClick = () => {
        this.setState({
            ...this.state,
            mode: viewerModeTypes.MOVE,
        });
    };

    handleMousePointClick = () => {
        this.setState({
            ...this.state,
            mode: viewerModeTypes.POINTER,
        });
    };

    handleNewRectClick = () => {
        this.setState(
            {
                ...this.state,
                mode: viewerModeTypes.ADD,
            },
            () => {
                const stage = this.stageRef.current.getStage();
                stage.x(0);
                stage.y(0);
            },
        );
    };

    handleMouseMove = e => {
        const stage = this.stageRef.current.getStage();
        const pos = stage.getPointerPosition();

        const x = Math.round(pos.x / this.state.scale);
        const y = Math.round(pos.y / this.state.scale);

        const {
            mode,
            moving,
            moveInitialPosition,
            moveScrollPosition,
            newBox,
        } = this.state;

        if (mode === viewerModeTypes.MOVE && moving) {
            const originalX = moveInitialPosition.x / this.state.scale;
            const originalY = moveInitialPosition.y / this.state.scale;

            const diffX = originalX - x;
            const diffY = originalY - y;

            const scrollXPos = moveScrollPosition.x + diffX;
            const scrollYPos = moveScrollPosition.y + diffY;

            this.setState(
                {
                    moveScrollPosition: {
                        x: scrollXPos < 0 ? 0 : scrollXPos,
                        y: scrollYPos < 0 ? 0 : scrollYPos,
                    },
                },
                () => {
                    const { moveScrollPosition } = this.state;
                    const div = this.stageRef.current.attrs.container;
                    div.scrollTo(moveScrollPosition.x, moveScrollPosition.y);
                },
            );
        } else if (newBox !== null) {
            const box = {
                ...newBox,
                width: x - newBox.x,
                height: y - newBox.y,
            };

            this.setState({
                ...this.state,
                newBox: box,
            });
        }
    };

    handleVerboseChecked = e => {
        this.setState({
            verbose: e.target.checked,
        });
    };

    handleShowVerboseBoxesChecked = e => {
        const checked = e.target.checked;
        this.setState(
            {
                showVerboseBoxes: checked,
            },
            () => {
                this.props.handleShowVerboseBoxesChecked &&
                    this.props.handleShowVerboseBoxesChecked();
            },
        );
    };

    handleStageKeyDown = e => {
        const { mode } = this.state;
        const { readOnly, boxes } = this.props;

        switch (e.key) {
            case '+':
                if (e.altKey) {
                    this.handleZoomChange(e, 0.1);
                }
                break;
            case '-':
                if (e.altKey) {
                    this.handleZoomChange(e, -0.1);
                }
                break;
            case 'p':
                if (e.altKey) {
                    this.setState({
                        mode: viewerModeTypes.POINTER,
                    });
                }
                break;
            case 'm':
                if (e.altKey) {
                    this.setState({
                        mode: viewerModeTypes.MOVE,
                    });
                }
                break;
            case 'n':
                if (e.altKey) {
                    this.setState({
                        mode: viewerModeTypes.ADD,
                    });
                }
                break;
            case 'd':
                if (e.altKey) {
                    this.handleDownloadClick();
                }
                break;
            case 'Escape':
                if (mode === viewerModeTypes.ADD) {
                    this.setState({
                        newBox: null,
                    });
                }
                break;
            case 'Delete':
                if (!readOnly && boxes.length === 1) {
                    this.props.handleRemoveBoxKeyPress &&
                        this.props.handleRemoveBoxKeyPress(e, boxes[0].key);
                }
                break;
            default:
                break;
        }
    };

    renderPage = pageNum => {
        const { pdfDoc } = this.state;

        pdfDoc &&
            pdfDoc.getPage(pageNum + 1).then(page => {
                var scale = 1.3349;

                var viewport = page.getViewport({ scale: scale });
                var canvas = document.createElement('canvas');
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                var renderTask = page.render(renderContext);
                renderTask.promise.then(async () => {
                    const fileDataUrl = canvas.toDataURL();

                    this.image = new window.Image();

                    this.image.onload = () => {
                        if (this.imageNode) {
                            this.imageNode.getLayer().batchDraw();
                        }

                        const widthProportion = this.divRef.current
                            ? this.divRef.current.clientWidth / this.image.width
                            : this.image.width;
                        this.setScale(null, widthProportion * 0.98);

                        this.setState({
                            width: this.image.width * 3, // max zoom
                            height: this.image.height * 3, // max zoom
                            imageLoader: false,
                        });
                    };

                    this.image.src = fileDataUrl;
                });
            });
    };

    handleSetPage = page => {
        this.setState({ page });
    };

    render() {
        const {
            mode,
            imageLoader,
            scale,
            width,
            height,
            newBox,
            verbose,
            showVerboseBoxes,
            page,
        } = this.state;

        const {
            loading,
            readOnly,
            boxes,
            verboseBoxes,
            urlXml,
            hasEvents,
            handleEventsClick,
            handleHistoryClick,
            hasChildDocuments,
            handleShowChildDocumentsClick,
        } = this.props;
        const cursorStyle = mode === viewerModeTypes.MOVE ? 'move' : 'default';

        const isRoleFull = this.context.hasClaim('role-level-full');

        const boxesReadOnly =
            readOnly ||
            mode === viewerModeTypes.MOVE ||
            mode === viewerModeTypes.ADD;

        return (
            <section
                className='image-map'
                ref={this.divRef}
                style={{ cursor: cursorStyle }}
            >
                <Loading loading={imageLoader || loading} />
                <Toolbar
                    readOnly={readOnly}
                    mode={mode}
                    scale={scale}
                    handleEventsClick={handleEventsClick}
                    handleHistoryClick={handleHistoryClick}
                    handleDownloadXmlClick={this.handleDownloadXmlClick}
                    handleZoomChange={this.handleZoomChange}
                    handleDownloadClick={this.handleDownloadClick}
                    handleNewRectClick={
                        this.props.mapVersion === 0
                            ? this.handleNewRectClick
                            : this.props.handleNewMapItem
                    }
                    handleMoveClick={this.handleMoveClick}
                    handleMousePointClick={this.handleMousePointClick}
                    handleVerboseChecked={this.handleVerboseChecked}
                    handleShowVerboseBoxesChecked={
                        this.handleShowVerboseBoxesChecked
                    }
                    showVerboseMode={isRoleFull}
                    showXmlButton={urlXml ? true : false}
                    showEventsButton={hasEvents ? true : false}
                    handleShowChildDocumentsClick={
                        handleShowChildDocumentsClick
                    }
                    showChildDocumentsButton={hasChildDocuments ? true : false}
                />
                <div tabIndex={1} onKeyDown={this.handleStageKeyDown}>
                    <div className='ReactTable'>
                        <div className='pagination-bottom viewer'>
                            {this.props.mapInfo && (
                                <MapDetailsViewer
                                    map={this.props.mapInfo}
                                    isFull={isRoleFull}
                                />
                            )}
                            <Pagination
                                previousText={'<'}
                                nextText={'>'}
                                pages={this.state.docNumPages}
                                page={this.state.page}
                                onPageChange={this.handleSetPage}
                            />
                        </div>
                    </div>
                    <Stage
                        className={this.props.className || 'image-edit-map'}
                        ref={this.stageRef}
                        width={width}
                        height={height}
                        onWheel={this.handleStageWheel}
                        onMouseDown={this.handleStageMouseDown}
                        onMouseUp={this.handleStageMouseUp}
                        onMouseMove={this.handleMouseMove}
                    >
                        <Layer>
                            <Image
                                ref={node => (this.imageNode = node)}
                                image={this.image}
                            />
                            <BoxesView
                                readOnly={boxesReadOnly}
                                verbose={verbose}
                                newBox={newBox}
                                boxes={boxes}
                                color={'#1bd75f'}
                                pageNumber={page + 1}
                                handleRectChange={this.handleRectChange}
                                transformer={
                                    <Transformer
                                        ref={node =>
                                            (this.transformerNode = node)
                                        }
                                        rotateEnabled={false}
                                    />
                                }
                            />
                            {showVerboseBoxes && (
                                <BoxesView
                                    readOnly={boxesReadOnly}
                                    verbose={verbose}
                                    newBox={null}
                                    boxes={verboseBoxes}
                                    color={'blue'}
                                    pageNumber={page + 1}
                                    handleRectChange={this.handleRectChange}
                                    transformer={
                                        <Transformer
                                            ref={node =>
                                                (this.transformerNode = node)
                                            }
                                            rotateEnabled={false}
                                        />
                                    }
                                />
                            )}
                        </Layer>
                    </Stage>
                </div>
            </section>
        );
    }
}

Viewer.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    boxes: PropTypes.array,
    verboseBoxes: PropTypes.array,
    handleBoxChange: PropTypes.func,
    handleNewBoxClick: PropTypes.func,
    handleShowVerboseBoxesChecked: PropTypes.func,
};

export default Viewer;
