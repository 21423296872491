import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams, withRouter } from 'react-router';

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { injectIntl } from 'react-intl';

import messages from './DocumentsConfigsListPage.intl';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import * as actions from '../../store/actions/index';
import EvtTable from '../../components/EvtTable/EvtTable';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { AuthContext } from '../../context/AuthContext';
import Tabs from '../../components/Tabs/Tabs';
import {
    columnsDocumentFields,
    columnsMapFields,
    propertiesDocumentFields,
    propertiesMapFields,
} from './components/Columns';
import CategoryList from './pages/CategoryList';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const DocumentsConfigsListPage = props => {
    const { intl, loadingList, categories, fields, fieldsCount } = props;

    const context = useContext(AuthContext);
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const newButtonActive = context.hasClaim('document-field-create');
    const mustViewDocumentField = context.hasClaim('document-view');

    const [activeTab, setActiveTab] = useState(0);
    const [filters, setFilters] = useState([]);
    const [queryState, setQueryState] = useState('');

    useEffect(() => {
        props.ontInitCategories();

        const URL = window.location.search;
        const index = new URLSearchParams(URL).get('index') || 0;
        const tabIndex = location.state?.tabIndex;
        if (!!index) {
            setActiveTab(Number(index));
        } else {
            setActiveTab(tabIndex || 0);
        }
    }, []);

    useEffect(() => {
        if (activeTab !== 2 && !!queryState) {
            reloadDocumentFieldList();
        }
    }, [queryState]);

    function reloadDocumentFieldList() {
        history.push(`/${params.historyPath}/documentsconfigs` + queryState);
        const includeDocument = activeTab === 0 ? true : false;

        if (queryState) {
            props.onInitDocumentFields(queryState, includeDocument);
            props.onInitDocumentFieldsCount(queryState, includeDocument);
        }
    }

    const handleFiltersChange = filters => {
        setFilters(filters);
    };

    const handleNewFieldButtonClick = () => {
        // Esse push envia o activeTab para a tela de Detalhes para que haja verificações diferenciando entre tipos de campos
        history.push(
            `/${params.historyPath}/documentsconfigs/documentfield/${
                activeTab === 0 ? 'newdocumentfield' : 'newmapfield'
            }`,
            {
                tabIndex: activeTab,
            },
        );
    };

    const handleTabClick = (_e, index) => {
        if (activeTab !== index) {
            setActiveTab(index);
            setFilters([]);
        }

        history.push(
            `/${params.historyPath}/documentsconfigs` +
                `?sort=-createdAt&skip=0&take=10&page=0&index=${index}`,
        );
        setQueryState(`?sort=-createdAt&skip=0&take=10&page=0&index=${index}`);
    };

    const handleGetTrProps = (_state, rowInfo, _column, _instance) => {
        const historyPath = params.historyPath;
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: _e =>
                props.getDocumentField(
                    rowInfo.original.documentFieldId,
                    activeTab,
                    historyPath,
                ),
        };
    };

    const handleRefreshDocumentFieldButtonClick = () =>
        reloadDocumentFieldList(queryState);

    const handleEvTableStateChange = query => {
        let queryURL = query;

        if (!queryURL.includes('index='))
            queryURL = queryURL + `&index=${activeTab}`;

        setQueryState(queryURL);
    };

    const mapCategoriesArray = categoriesArray => {
        if (!categoriesArray) return;
        const map = categoriesArray.map(category => {
            return {
                key: category.code,
                value: category.name,
            };
        });
        return map;
    };

    const tabs = [];

    if (mustViewDocumentField) {
        tabs.push({
            name: intl.formatMessage(messages.title),
            component: (
                <div>
                    <HeaderTable
                        title={intl.formatMessage(messages.title)}
                        properties={propertiesDocumentFields(
                            intl,
                            mapCategoriesArray,
                            categories,
                        )}
                        loading={loadingList}
                        handleFiltersChange={handleFiltersChange}
                        onClickReload={handleRefreshDocumentFieldButtonClick}
                        onClickNewButton={handleNewFieldButtonClick}
                        newButtonEnabled={newButtonActive}
                        titleNewButton={intl.formatMessage(messages.field)}
                        hideSearchBar
                    />
                    <section>
                        <EvtTable
                            filters={filters}
                            columns={columnsDocumentFields(intl, fields)}
                            data={fields}
                            length={fieldsCount}
                            pageSize={10}
                            handleStateChange={handleEvTableStateChange}
                            handleGetTrProps={handleGetTrProps}
                            loading={loadingList}
                            defaultSorted={[
                                {
                                    id: 'createdAt',
                                    desc: true,
                                },
                            ]}
                            countHeaderText={intl.formatMessage(
                                messages.subtitle,
                                { length: fieldsCount },
                            )}
                        />
                    </section>
                </div>
            ),
        });
    }

    // Map Fields
    if (mustViewDocumentField) {
        tabs.push({
            name: intl.formatMessage(messages.tabMapFields),
            component: (
                <>
                    <HeaderTable
                        title={intl.formatMessage(messages.tabMapFields)}
                        properties={propertiesMapFields(
                            intl,
                            mapCategoriesArray,
                            categories,
                        )}
                        loading={loadingList}
                        handleFiltersChange={handleFiltersChange}
                        onClickReload={handleRefreshDocumentFieldButtonClick}
                        onClickNewButton={handleNewFieldButtonClick}
                        newButtonEnabled={newButtonActive}
                        titleNewButton={intl.formatMessage(messages.field)}
                        hideSearchBar
                    />
                    <section>
                        <EvtTable
                            filters={filters}
                            columns={columnsMapFields(intl, fields)}
                            data={fields}
                            length={fieldsCount}
                            pageSize={10}
                            handleStateChange={handleEvTableStateChange}
                            handleGetTrProps={handleGetTrProps}
                            loading={loadingList}
                            defaultSorted={[
                                {
                                    id: 'createdAt',
                                    desc: true,
                                },
                            ]}
                            countHeaderText={intl.formatMessage(
                                messages.subtitle,
                                { length: fieldsCount },
                            )}
                        />
                    </section>
                </>
            ),
        });
    }

    if (mustViewDocumentField) {
        tabs.push({
            name: intl.formatMessage(messages.categoriesTitle),
            component: (
                <>
                    <CategoryList
                        activeTab={activeTab}
                        handleGetTrProps={handleGetTrProps}
                        newButtonActive={newButtonActive}
                    />
                </>
            ),
        });
    }

    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: `/${params.historyPath}`,
                        name: intl.formatMessage(messages.titleInvoice),
                        active: false,
                    },
                    {
                        path: `/${params.historyPath}/documentsconfigs`,
                        name: intl.formatMessage(
                            messages.titleDocumentsConfigs,
                        ),
                        active: true,
                    },
                ]}
            />

            <section className='content-middle'>
                <Tabs
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    tabs={tabs}
                />
            </section>
        </Container>
    );
};

const mapStateToProps = state => {
    return {
        fields: state.documentFieldsReducer.fields || [],
        fieldsCount: state.documentFieldsReducer.fieldsCount || 0,
        loadingList: state.documentFieldsReducer.loadingList,
        categories: state.categoryReducer.categories || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitDocumentFields: (filter, includeDocument) =>
            dispatch(actions.initDocumentFields(filter, includeDocument)),
        onInitDocumentFieldsCount: (filter, includeDocument) =>
            dispatch(actions.initDocumentFieldsCount(filter, includeDocument)),

        getDocumentField: (documentFieldId, tabIndex, historyPath) =>
            dispatch(
                actions.getDocumentField(
                    documentFieldId,
                    tabIndex,
                    historyPath,
                ),
            ),
        ontInitCategories: () => dispatch(actions.initCategories()),
    };
};
export default injectIntl(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(DocumentsConfigsListPage),
    ),
);
