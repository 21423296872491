import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    titleInbound: {
        id: 'settings.titleInbound',
        defaultMessage: 'Caixas de Email',
    },
    newMailBox: {
        id: 'settings.newMailBox',
        defaultMessage: 'Nova Caixa de Email',
    },
    titleOnlyInbound: {
        id: 'settings.titleInboundEmail',
        defaultMessage: 'Caixa de Email',
    },
    titleSettings: {
        id: 'settings.title',
        defaultMessage: 'Configurações',
    },
    Inbound: {
        id: 'settings.Inbound',
        defaultMessage: 'Caixa {entity}',
    },
    deleteInbound: {
        id: 'settings.deleteInbound',
        defaultMessage: 'Excluir Caixa',
    },
});
