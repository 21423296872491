import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = props => {
  return (
    <React.Fragment>
      {props.loading && (
        <div className="loading-inside">
          <FontAwesomeIcon icon="spinner" size="4x" pulse />
        </div>
      )}
    </React.Fragment>
  );
};

export default Loading;
