import React from 'react';

const EmailIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M19.68 5.79736V12.5068'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M20.64 18.2666H14.88'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M18.72 16.3467L20.64 18.2667L18.72 20.1867'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            d='M11.04 16.3468H5.28C4.2192 16.3468 3.36 15.4876 3.36 14.4268V5.79736'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M5.29056 3.8667H17.7504C18.816 3.8667 19.681 4.7307 19.681 5.79726V5.79726C19.681 6.43758 19.3632 7.03566 18.8333 7.3947L13.6906 10.8805C12.3802 11.7685 10.6598 11.7685 9.34944 10.8805L4.20768 7.39566C3.67776 7.03662 3.36 6.43758 3.36 5.79822V5.79726C3.36 4.7307 4.224 3.8667 5.29056 3.8667Z'
            stroke='#323232'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
);

export default EmailIcon;
