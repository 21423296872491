import React from 'react';
import { injectIntl } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';
import { FormGroup, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';

const FormDatePicker = props => {
    const { intl, filter, property } = props;

    return (
        <Col lg={10} md={8} className='form-date-picker'>
            <label className='text'>{property.value}:</label>
            <Col lg={4} md={3} sm={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(globalMessages.from)}
                        type='date'
                        name={`filters[${filter.id}].valueFrom`}
                        id={`filters[${filter.id}].valueFrom`}
                        value={filter.valueFrom}
                        validate={{ datetime: true }}
                    />
                </FormGroup>
            </Col>
            <Col lg={4} md={3} sm={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(globalMessages.to)}
                        type='date'
                        name={`filters[${filter.id}].valueTo`}
                        id={`filters[${filter.id}].valueTo`}
                        value={filter.valueTo}
                        validate={{ datetime: true }}
                    />
                </FormGroup>
            </Col>
        </Col>
    );
};

export default injectIntl(FormDatePicker);
