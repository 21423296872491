import React, { useState, useEffect, useContext } from 'react';
import Proptypes from 'prop-types';

import { injectIntl } from 'react-intl';
import messages from './SupplierPicker.intl';

import { Container, Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import EvtSelect from '../EvtSelect/EvtSelect';
import { AuthContext } from '../../context/AuthContext';

function SupplierPicker(props) {
    const context = useContext(AuthContext);
    const [contractorId, setContractorId] = useState(null);
    const [showContractorsSelect, setShowContractorsSelect] = useState(false);
    const [showSuppliersSelect, setShowSuppliersSelect] = useState(false);
    const { intl, handleSupplierPickerSubmit } = props;

    useEffect(() => {      
        if (context.claims.length === 0) return;

        if (context.hasClaim('role-level-full')) {
            setShowContractorsSelect(true);
        } else if (context.hasClaim('role-level-contractor')) {
            const { contractorId } = context;
            setShowContractorsSelect(false);
            setShowSuppliersSelect(true);
            setContractorId(contractorId);
        }
    }, [context]);
    
    function handleContractorsChange(option) {
        setContractorId(option.value);
        setShowSuppliersSelect(true);
    }

    return (     
        <AvForm onValidSubmit={handleSupplierPickerSubmit}>
            <Container fluid>
                {showContractorsSelect && (
                    <Row>
                        <Col xs={12} className='text-left'>
                            <EvtSelect
                                name='contractorId'
                                label={intl.formatMessage(
                                    messages.contractorsTitle,
                                )}
                                url='/Contractors'
                                labelKey='displayName'
                                valueKey='contractorId'
                                handleSelectedOptionChange={handleContractorsChange}
                                async
                                required
                            />
                        </Col>
                    </Row>
                )}
                {showSuppliersSelect && (
                    <Row>
                        <Col xs={12} className='text-left mt-4'>
                            <EvtSelect
                                key={contractorId}
                                name='supplierId'
                                label={intl.formatMessage(messages.suppliersTitle)}
                                url={
                                    `/Contractors/${contractorId}/Suppliers`
                                }
                                labelKey='displayName'
                                labelKey2='documentNumber1'
                                valueKey='supplierId'
                                async
                                required
                              />
                        </Col>
                    </Row>
                )}
                <Row className='mt-2'>
                    <Col xs={12}>
                        <button className='btn-submit'>
                            {intl.formatMessage(messages.confirmButton)}
                        </button>
                    </Col>
                </Row>
            </Container>
        </AvForm>
    );
}

SupplierPicker.propTypes = {
    handleValidSubmit: Proptypes.func,
};

export default injectIntl(SupplierPicker);
