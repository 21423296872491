import * as actionTypes from '../actions/actionTypes';

const initialState = {
	session: null,
	accesses: [],
	errorMessage: null,
	loading: false,
};

const setCurrentSession = (state, action) => {
    action &&
        action.session &&
        (action.session.hasClaim = claim => {
            const { session } = action || {};
            const { claims } = session || {};

            return claims && claims.find(c => c.value === claim) ? true : false;
        });

	return {
		...state,
		session: action.session,
		loading: false,
	};
};

const setNewSession = (state, action) => {
	return {
		...state,
		session: null,
	};
};

const setNewSessionRequest = (state, action) => {
	return {
		...state,
		loading: true,
	};
};

const setNewSessionFailure = (state, action) => {
	return {
		...state,
		loading: false,
	};
};

const setNewSessionFailurePassword = (state, action) => {
	return {
		... state,
		loading: false,
	}
}

const setAccessSelectionComplete = (state, action) => {
	return {
		...state,
		loading: false,
	};
};

const removeCurrentSession = state => {
	return {
		...state,
		session: null,
	};
};

const setForgotPasswordError = (state, action) => {
	const errorMessage = action.error.response.data
		.map(e => `Code: ${e.code} Description: ${e.description}`)
		.join('\n');
	return {
		...state,
		errorMessage,
	};
};

const cleanUpErrorMessage = (state, action) => {
	return {
		...state,
		errorMessage: null,
	};
};

const setChangePasswordComplete = (state, action) => {
	return {
		...state,
		loading: false,
	}
}

const setLoading = (state, action) => {
	return {
		...state,
		loading: action.loading,
	}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SESSION_REQUEST:
			return setNewSessionRequest(state, action);
			
		case actionTypes.SESSION_SUCCESS:
			return setNewSession(state, action);

		case actionTypes.SESSION_FAILURE:
			return setNewSessionFailure(state, action);

			case actionTypes.SESSION_FAILURE_PASSWORD:
				return setNewSessionFailurePassword(state, action);

		case actionTypes.SESSION_ACCESS_SELECTION_SUCCESS:
		case actionTypes.SESSION_ACCESS_SELECTION_FAILURE:
			return setAccessSelectionComplete(state, action);

		case actionTypes.SET_SESSION_ACCESS_SELECTION:
		case actionTypes.GET_CURRENT_SESSION_SUCCESS:
			return setCurrentSession(state, action);

		case actionTypes.USER_CHANGE_PASSWORD_REQUEST:
			return setLoading(state, { loading: true });

		case actionTypes.GET_CURRENT_SESSION_REQUEST:
			return setLoading(state, { loading: true });

		case actionTypes.LOG_OUT_SUCCESS:
			return removeCurrentSession(state, action);

		case actionTypes.SESSION_ACCESS_SELECTION_REQUEST:
			return setLoading(state, { loading: true });

		case actionTypes.FORGOT_PASSWORD_CONFIRMATION_SUCCESS:
			return cleanUpErrorMessage(state, action);

		case actionTypes.FORGOT_PASSWORD_REQUEST:
			return setLoading(state, { loading: true });

		case actionTypes.FORGOT_PASSWORD_SUCCESS:
		case actionTypes.FORGOT_PASSWORD_FAILURE:
		case actionTypes.GET_CURRENT_SESSION_FAILURE:
			return setLoading(state, { loading: false });

		case actionTypes.FORGOT_PASSWORD_CONFIRMATION_FAILURE:
			return setForgotPasswordError(state, action);

		case actionTypes.USER_CHANGE_PASSWORD_SUCCESS:
		case actionTypes.USER_CHANGE_PASSWORD_FAILURE:
			return setChangePasswordComplete(state, action);

		default:
			return state;
	}
};

export default reducer;
