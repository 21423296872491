import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';

//Get Document Fields

export const initMailBox = (filter, contractorId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAILS_GET_LIST_REQUEST,
        });

        axios
            .get(
                `/Contractors/${contractorId}/Settings/Documents/Inbound/Mail/MailBox${filter}`,
            )
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAILS_GET_LIST_SUCCESS,
                    mailBoxes: response?.data,
                    mailBoxesCount: response?.data.length,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAILS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

//Inbound Details

export const initMailBoxDetails = setupId => {
    return async dispatch => {
        if (setupId === 'newmailbox') {
            dispatch({
                type: actionTypes.SETTINGS_EMAIL_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.SETTINGS_EMAIL_GET_DETAILS_REQUEST,
            });

            try {
                const response = await axios.get(
                    `/Settings/Documents/Inbound/Mail/MailBox/${setupId}`,
                );
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_GET_DETAILS_SUCCESS,
                    mailBox: response.data,
                });
            } catch (error) {
                toast.error('Caixa de Email não encontrada');
                dispatch(push('/settings/settingsmailboxes'));
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_GET_DETAILS_FAILURE,
                    error,
                });
            }
        }
    };
};

//Create, Update and Delete Email

export const createMailBox = mailBoxModel => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAIL_CREATE_REQUEST,
        });

        axios
            .post('/Settings/Documents/Inbound/Mail/MailBox', {
                ...mailBoxModel,
            })
            .then(response => {
                toast.success('Caixa de Email criado com sucesso');
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_CREATE_SUCCESS,
                    mailBox: response.data,
                });
                dispatch(
                    push({
                        pathname: `/settings/settingsmailboxes`,
                    }),
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_CREATE_FAILURE,
                    error,
                });
            });
    };
};

export const updateMailBox = (mailBoxUpdate, setupId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAIL_PUT_REQUEST,
        });
        axios
            .put(`/Settings/Documents/Inbound/Mail/MailBox/${setupId}`, {
                ...mailBoxUpdate,
            })
            .then(() => {
                toast.success('Caixa de Email salvo com sucesso');
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_PUT_SUCCESS,
                    mailBox: mailBoxUpdate,
                });
                dispatch(
                    push({
                        pathname: `/settings/settingsmailboxes`,
                    }),
                );
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteMailBox = setupId => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAIL_DELETE_REQUEST,
        });
        axios
            .delete(`/Settings/Documents/Inbound/Mail/MailBox/${setupId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_DELETE_SUCCESS,
                });
                dispatch(
                    push({
                        pathname: `/settings/settingsmailboxes`,
                    }),
                );
                toast.success('Caixa de Email excluido com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_DELETE_FAILURE,
                    error,
                });
            });
    };
};

//Get Email
export const getMailBox = setupId => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAIL_GET_DETAILS_REQUEST,
        });
        dispatch(push(`/settings/settingsmailboxes/${setupId}`, {}));
    };
};

// SettingsDocument Email

export const getEmailToNotify = contractorId => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAIL_NOTIFY_REQUEST,
        });

        axios
            .get(`/Settings/${contractorId}`)
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_NOTIFY_SUCCESS,
                    settingsEmailToNotify:
                        response.data.documents.inbound.email,
                    inboundSettings: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_NOTIFY_FAILURE,
                    error,
                });
            });
    };
};

export const updateEmailToNotify = (emailToNotifyUpdate, settingId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAIL_NOTIFY_UPDATE_REQUEST,
        });

        axios
            .put(`/Settings/${settingId}/Documents/Inbound/Mail`, {
                ...emailToNotifyUpdate,
            })
            .then(() => {
                toast.success('Caixa de Email salva com sucesso');
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_NOTIFY_UPDATE_SUCCESS,
                    settingsEmailToNotify: emailToNotifyUpdate,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_NOTIFY_UPDATE_FAILURE,
                    error,
                });
            });
    };
};

export const createEmailToNotify = emailToNotify => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_EMAIL_NOTIFY_CREATE_REQUEST,
        });

        axios
            .post(`/Settings/Documents/Inbound/Mail`, { ...emailToNotify })
            .then(response => {
                toast.success('Caixa de Email criada com sucesso');
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_NOTIFY_CREATE_SUCCESS,
                    settingsEmailToNotify: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_EMAIL_NOTIFY_CREATE_FAILURE,
                    error,
                });
            });
    };
};

//ModuleSettings
export const updateModuleSettings = (
    moduleSettings,
    settingId,
    documentType,
) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_UPDATE_MODULE_REQUEST,
        });

        axios
            .put(`/Settings/${settingId}/${documentType}`, {
                ...moduleSettings,
            })
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_UPDATE_MODULE_SUCCESS,
                    moduleSettings,
                    documentType,
                });
                toast.success('Configurações salvas com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_UPDATE_MODULE_FAILURE,
                    error,
                });
            });
    };
};

export const updateOrdersSettings = (settingId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_ORDERS_PUT_REQUEST,
        });

        axios
            .put(`/Settings/${settingId}/Orders`, {
                ...values,
            })
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_ORDERS_PUT_SUCCESS,
                    ordersSettings: values,
                });
                toast.success('Configurações salvas com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_ORDERS_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const updateReportsSettings = (settingId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_REPORTS_PUT_REQUEST,
        });

        axios
            .put(`/Settings/${settingId}/Reports`, {
                ...values,
            })
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_REPORTS_PUT_SUCCESS,
                    reportScheduled: values.reportScheduled,
                });
                toast.success('Configurações salvas com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_REPORTS_PUT_FAILURE,
                    error,
                });
            });
    };
};


export const updateCitiesToMonitor = (settingId, citiesToMonitor, contractorId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_CITIES_MONITOR_PUT_REQUEST,
        });

        axios
            .put(`/Settings/${settingId}/NFSE/MonitoringCities`, {
                citiesToMonitor,
            })
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_CITIES_MONITOR_PUT_SUCCESS,
                    checkedCities: citiesToMonitor,
                });
                toast.success('Prefeituras salvas com sucesso');
                dispatch(getEmailToNotify(contractorId))
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_CITIES_MONITOR_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const getEnabledCities = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.ENABLED_CITIES_GET_LIST_REQUEST,
        });

        axios
            .get(`/Settings/Documents/NfseMonitoringCities`)
            .then(response => {
                dispatch({
                    type: actionTypes.ENABLED_CITIES_GET_LIST_SUCCESS,
                    enabledCities: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ENABLED_CITIES_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};
export const updateCitiesToMonitorNacional = (settingId, citiesToMonitor, contractorId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SETTINGS_NACIONAL_CITIES_MONITOR_PUT_REQUEST,
        });

        axios
            .put(`/Settings/${settingId}/NFSE/NfseNacionalMonitoringCities`, {
                citiesToMonitor,
            })
            .then(response => {
                dispatch({
                    type: actionTypes.SETTINGS_NACIONAL_CITIES_MONITOR_PUT_SUCCESS,
                    checkedNacionalCities: citiesToMonitor,
                });
                toast.success('Prefeituras salvas com sucesso');
                dispatch(getEmailToNotify(contractorId))
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SETTINGS_NACIONAL_CITIES_MONITOR_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const getEnabledCitiesNacional = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.ENABLED_NACIONAL_CITIES_GET_LIST_REQUEST,
        });

        axios
            .get(`/Settings/Documents/NfseNacionalMonitoringCities`)
            .then(response => {
                dispatch({
                    type: actionTypes.ENABLED_NACIONAL_CITIES_GET_LIST_SUCCESS,
                    enabledNacionalCities: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ENABLED_NACIONAL_CITIES_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const changeSelectedNacionalCities = (city, checked) => {
    return (dispatch, getState) => {
        const cities = getState().settingsReducer.checkedNacionalCities;
        const final = [];

        for (var key in cities) {
            if (key !== city) {
                final[key] = true;
            }
        }

        if (checked) {
            final[city] = true;
        }

        dispatch({
            type: actionTypes.SETTINGS_SET_NACIONAL_CITIES_SUCCESS,
            checkedNacionalCities: final,
        });
    };
};

export const changeSelectedCities = (city, checked) => {
    return (dispatch, getState) => {
        const cities = getState().settingsReducer.checkedCities;
        const final = [];

        for (var key in cities) {
            if (key !== city) {
                final[key] = true;
            }
        }

        if (checked) {
            final[city] = true;
        }

        dispatch({
            type: actionTypes.SETTINGS_SET_CITIES_SUCCESS,
            checkedCities: final,
        });
    };
};

export const setCheckedCities = (cities) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SETTINGS_SET_CITIES_SUCCESS,
            checkedCities: cities,
        });
    };
};

export const setCheckedCitiesNacional = (cities) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SETTINGS_SET_NACIONAL_CITIES_SUCCESS,
            checkedNacionalCities: cities,
        });
    };
};

// Notifications
export const initNotifications = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.NOTIFICATIONS_GET_REQUEST,
        });

        axios
            .get(`/AvailableNotifications`)
            .then(response => {
                dispatch({
                    type: actionTypes.NOTIFICATIONS_GET_SUCCESS,
                    notifications: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATIONS_GET_FAILURE,
                    error,
                });
            });
    };
};

export const updateNotifications = (settingId, notificationsValues) => {
    return dispatch => {
        dispatch({
            type: actionTypes.NOTIFICATIONS_PUT_REQUEST,
        });

        axios
            .put(`/Settings/${settingId}/Notifications`, {
                ...notificationsValues,
            })
            .then(response => {
                dispatch({
                    type: actionTypes.NOTIFICATIONS_PUT_SUCCESS,
                    notificationsSettings:
                        notificationsValues.notificationsEnabled,
                });

                toast.success('Notificações salvas com sucesso');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATIONS_PUT_FAILURE,
                    error,
                });
            });
    };
};
