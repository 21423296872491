import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    generalTitle: {
        id: 'invoices.generalTitle',
        defaultMessage: 'Cabeçalho',
    },
    providerTitle: {
        id: 'invoices.providerTitle',
        defaultMessage: 'Prestador de Serviços',
    },
    issuer: {
        id: 'invoices.issuer',
        defaultMessage: 'Emitente',
    },
    sender: {
        id: 'invoices.sender',
        defaultMessage: 'Remetente',
    },
    dispatcher: {
        id: 'invoices.dispatcher',
        defaultMessage: 'Expedidor',
    },
    receiver: {
        id: 'invoices.receiver',
        defaultMessage: 'Recebedor',
    },
    recipient: {
        id: 'invoices.recipient',
        defaultMessage: 'Destinatário',
    },
    borrowerTitle: {
        id: 'invoices.borrowerTitle',
        defaultMessage: 'Tomador de Serviços',
    },
    middlewareTitle: {
        id: 'invoices.middlewareTitle',
        defaultMessage: 'Intermediário de Serviços',
    },
    servicesTitle: {
        id: 'invoices.servicesTitle',
        defaultMessage: 'Serviços',
    },
    taxesTitle: {
        id: 'invoices.taxesTitle',
        defaultMessage: 'Valores/Impostos',
    },
    othersTitle: {
        id: 'invoices.othersTitle',
        defaultMessage: 'Outros',
    },

    payerTitle: {
        id: 'invoices.payerTitle',
        defaultMessage: 'Pagador',
    },
    totalValuesTitle: {
        id: 'invoices.totalValuesTitle',
        defaultMessage: 'Valores totais',
    },
    withholdingTaxesTitle: {
        id: 'invoices.withholdingTaxesTitle',
        defaultMessage: 'Impostos Retidos',
    },
});

export default messages;
