import React from "react";
import { injectIntl } from "react-intl";

import { FormGroup, Col } from "reactstrap";

import { AvField } from "availity-reactstrap-validation";

import messages from "../../MapItemDetailsPage.intl";

const padLeftMapActionItemForm = props => {
  const { intl } = props;

  return (
    <React.Fragment>
      <Col xs={12} md={4}>
        <FormGroup>
          <AvField
            label={intl.formatMessage(messages.size)}
            type="number"
            name="parameters.TotalWidth"
            value={props.parameters.TotalWidth}
            errorMessage=" "
            required
          />
        </FormGroup>
      </Col>
      <Col xs={12} md={8}>
        <FormGroup>
          <AvField
            label={intl.formatMessage(messages.characters)}
            type="text"
            name="parameters.PaddingChar"
            value={props.parameters.PaddingChar}
            errorMessage=" "
            validate={{
              required: { value: true },
              minLength: { value: 1 },
              maxLength: { value: 1 },
          }}
          />
        </FormGroup>
      </Col>
    </React.Fragment>
  );
};

export default injectIntl(padLeftMapActionItemForm);
