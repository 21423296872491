import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    //orders
    noItemSelected: {
        id: 'orders.noItemSelected',
        defaultMessage: 'Nenhum item foi selecionado',
    },
    hasExistThisOrder:{
        id: 'orderItem.hasExistThisOrder',
        defaultMessage: 'O item escolhido já foi adicionado a lista.',
    },
    titleSupplier: {
        id: 'suppliers.titleSupplier',
        defaultMessage: 'Fornecedor {entity}',
    },
    itemsSubtitle: {
        id: 'invoices.itemsSubtitle',
        defaultMessage: ' {length} itens encontrados',
    },
    newOrdersButton: {
        id: 'documents.newOrdersButton',
        defaultMessage: '+ Pedido',
    },
    associatedOrderDocuments: {
        id: 'documents.associatedOrderDocuments',
        defaultMessage: 'Itens Associados',
    },
    searchItemOrders: {
        id: 'documents.searchItemOrders',
        defaultMessage: 'Escolha os itens',
    },
    itemOrder: {
        id: 'documents.itemOrder',
        defaultMessage: 'Item do Pedido',
    },
    order: {
        id: 'documents.order',
        defaultMessage: 'Pedido',
    },
    titleOrder: {
        id: 'documents.titleOrder',
        defaultMessage: 'Descrição',
    },
    titleOrderCode: {
        id: 'documents.titleOrderCode',
        defaultMessage: 'Pedido',
    },
    itemOrderDescription: {
        id: 'documents.itemOrderDescription',
        defaultMessage: 'Descrição do Item',
    },
    totalGrossValue:{
        id: 'documents.totalGrossValue',
        defaultMessage:'Valor bruto'
    },
    removeOrderItem: {
        id: 'orderItem.removeOrderItem',
        defaultMessage: 'Remover Item do Pedido',
    },
    orderMeasure: {
        id: 'orderItem.orderMeasure',
        defaultMessage: 'Medição',
    },
    errorNoExistOrderItems: {
        id: 'orderItem.errorNoExistOrderItems',
        defaultMessage: 'Não existem itens de pedido cadastrados para o fornecedor do documento',
    },
});
