import React, { useState, useEffect, useMemo } from 'react';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

import messages from '../IntegrationListPage.intl';
import { filterPropertyTypes } from '../../../utils/enums';

import Header from '../../../components/Header/Header';
import EvtTable from '../../../components/EvtTable/EvtTable';
import EvtFilter from '../../../components/EvtFilter/EvtFilter';

import { columnsCte } from '../components/ColumnsIntegrations';

function CteListIntegrations(props) {
    const {
        onInitCteSearch,
        onInitCteSearchCount,
        integrationsCte,
        integrationsCteCount,
        loadingCte,
        handleGetTrProps,
        activeTab,
        intl,
    } = props;

    const [query, setQuery] = useState('');
    const [filtersCte, setFiltersCte] = useState([]);

    useEffect(() => {
        reloadIntegrationsList();
    }, [query]);

    function reloadIntegrationsList() {
        const { filterValue } = filtersCte;
        const urlParams = new URLSearchParams(query);
        const take = urlParams.get('take') || 10;
        const skip = urlParams.get('skip') || 0;
        const sort = urlParams.get('sort') || '-createdAt';
        const querySearch = `?skip=${skip}&take=${take}&sort=${sort}&index=${activeTab}`;

        const queryURL = filterValue
            ? querySearch
            : query + `&index=${activeTab}`;
        const url = filterValue
            ? queryURL + `&search=${filterValue}`
            : queryURL;

        window.history.replaceState(url, undefined, 'integrations' + url);
        if (query.includes('take')) {
            onInitCteSearch(queryURL, filterValue);
            onInitCteSearchCount(queryURL, filterValue);
        }
    }

    function handleReloadButton() {
        reloadIntegrationsList();
    }

    function handleEvTableStateChangeNfse(queryTable) {
        setQuery(queryTable);
    }

    function handleFiltersCteChange(newFiltersCte, value) {
        setFiltersCte({ filters: newFiltersCte, filterValue: value });
    }

    //Testar após o Back-End Estiver pronto
    const cteColumns = useMemo(() => {
        return columnsCte(integrationsCte, intl);
    }, [integrationsCte]);

    const filterFields = [
        {
            key: 'cnpj',
            value: intl.formatMessage(messages.contractorCNPJ),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'searchDate',
            value: intl.formatMessage(messages.searchStartTimeDate),
            type: filterPropertyTypes.DATE,
        },
        {
            key: 'lastNSU',
            value: intl.formatMessage(messages.lastNumber),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'lastNSUReturned',
            value: intl.formatMessage(messages.lastNumberReturned),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'totalCountReturned',
            value: intl.formatMessage(messages.totalCountReturn),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'ibgeUfCode',
            value: intl.formatMessage(messages.ibgeCode),
            type: filterPropertyTypes.NUMBER,
        },
        {
            key: 'isSuccess',
            value: intl.formatMessage(messages.status),
            type: filterPropertyTypes.ENUM,
            options: [
                {
                    key: 'true',
                    _map: text =>
                        text.replace(
                            intl
                                .formatMessage(messages.statusSuccess)
                                .toLowerCase(),
                            true,
                        ),
                    value: intl.formatMessage(messages.statusSuccess),
                },
                {
                    key: 'false',
                    _map: text =>
                        text.replace(
                            intl
                                .formatMessage(messages.statusFailed)
                                .toLowerCase(),
                            false,
                        ),
                    value: intl.formatMessage(messages.statusFailed, {
                        entity: '',
                    }),
                },
            ],
        },
        {
            key: 'message',
            value: intl.formatMessage(messages.errorMessage),
            type: filterPropertyTypes.TEXT,
        },
    ];

    return (
        <div>
            <header className='table-screen'>
                <section className='title'>
                    <Header title={intl.formatMessage(messages.title)} />
                </section>
                <section className='btns-topo'>
                    <EvtFilter
                        properties={filterFields}
                        handleFiltersChange={handleFiltersCteChange}
                        loading={loadingCte}
                    />
                    <button
                        className={`new-btn small ${
                            loadingCte ? 'loading' : ''
                        }`}
                        onClick={handleReloadButton}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                </section>
            </header>
            <section>
                <EvtTable
                    filters={filtersCte.filters}
                    columns={cteColumns}
                    data={integrationsCte}
                    length={integrationsCteCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeNfse}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingCte}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: integrationsCteCount,
                    })}
                    fetchPage
                />
            </section>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        integrationsCte: state.integrationReducer.integrationsCte || [],
        integrationsCteCount:
            state.integrationReducer.integrationsCteCount || 0,
        loadingCte: state.integrationReducer.loadingCte,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitCteSearch: (filter, search) =>
            dispatch(actions.initIntegrationsCteSearch(filter, search)),
        onInitCteSearchCount: (filter, search) =>
            dispatch(actions.initIntegrationsCteSearchCount(filter, search)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(CteListIntegrations)),
);
