// Dependencies
import React from 'react';
import { injectIntl } from 'react-intl';
import messages from '../../containers/LoginPage/LoginPage.intl';
import { getAppVersion } from '../../utils/getVersion';

export const PoweredBy = props => {

	const { intl } = props;

	return (
		<div id="poweredBy" className='text-center'>
			<label>
				v{getAppVersion()}
				<br />
				{intl.formatMessage(messages.developedBy)}
				<a href="https://www.evtit.com/" target="_blank" rel="noopener noreferrer">evt/apps</a>
			</label>
		</div>
	)
}

export default injectIntl(PoweredBy);