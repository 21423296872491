import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    viewerToolbarModeText: {
        id: "viewer.toolbar.viewerToolbarModeText",
        defaultMessage: "Modo: {mode}"
    },
    viewerToolbarEditingModeText: {
        id: "viewer.toolbar.viewerToolbarEditingModeText",
        defaultMessage: "Edição"
    },
    viewerToolbarViewingModeText: {
        id: "viewer.toolbar.viewerToolbarViewingModeText",
        defaultMessage: "Visualização"
    },
    viewerToolbarAssociatedDocuments: {
        id: "viewer.toolbar.viewerToolbarAssociatedDocuments",
        defaultMessage: "Documentos associados"
    }
});