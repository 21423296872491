import React, { createContext, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { LinkingOrdersContext } from './LinkingOrdersContext';

export const CombinedContext = createContext();

export const CombinedContextProvider = ({ children }) => {
    const authContext = useContext(AuthContext);
    const linkingOrdersContext = useContext(LinkingOrdersContext);

    return (
        <CombinedContext.Provider value={{ auth: authContext, linkingOrders: linkingOrdersContext }}>
            {children}
        </CombinedContext.Provider>
    );
};

export const useCombinedContext = () => {
    const context = useContext(CombinedContext);
    if (!context) {
        throw new Error('useCombinedContext deve ser usado dentro de um CombinedContextProvider');
    }
    return context;
};