import { defineMessages } from "react-intl";

import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,

  btnNewInvoice: {
    id: "invoices.btnNewInvoice",
    defaultMessage: "Novo Documento"
  },

  title: {
    id: "invoices.title",
    defaultMessage: "Documentos"
  },

  titleSupplier: {
    id: "invoices.titleSupplier",
    defaultMessage: "Nota Fiscal"
  },

  subtitle: {
    id: "invoices.subtitle",
    defaultMessage: " nota(s) fiscal(ais) encontrada(s)"
  },

  contractors: {
    id: "contractors.titleOnlyContractor",
    defaultMessage: "Contratante"
  },

  suppliers: {
    id: "suppliers.titleOnlySupplier",
    defaultMessage: "Fornecedor"
  },

  invoice: {
    id: "invoices.invoice",
    defaultMessage: "Nota Fiscal"
  },
  
  invoiceNumber: {
    id: "invoices.invoiceNumber",
    defaultMessage: "Número"
  },

  status: {
    id: "invoices.status",
    defaultMessage: "Status"
  },

  home: {
    id: "home.title",
    defaultMessage: "Início"
  },

  createdAt: {
    id: "global.createdAt",
    defaultMessage: "Criado em"
  }
});

export default messages;
