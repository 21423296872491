import React, { useState, useEffect, useContext } from 'react';

import { injectIntl } from 'react-intl';
import messages from '../OtherListPage.intl';

import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router';

import { AuthContext } from '../../../context/AuthContext';
import EvtTable from '../../../components/EvtTable/EvtTable';
import { formatFilters } from '../../DocumentListPage/DocumentListPage';

import { columnsOther, otherProperties } from '../components/ColumnsOther';
import HeaderTable from '../../../components/HeaderTable/HeaderTable';

function OtherList(props) {
    const {
        intl,
        hasFull,
        newDocumentButtonEnabled,
        handleNewDocumentButtonClick,
        handleGetTrProps,
        activeTab,
        // mapStateProp
        documentsOther,
        documentsOtherCount,
        loadingOtherList,
        categories
    } = props;

    const context = useContext(AuthContext);
    const history = useHistory();

    const [query, setQuery] = useState('');
    const [filtersOther, setFiltersOther] = useState({
        filters: [],
        filterValue: '',
    });

    //Mount Page
    useEffect(() => {
        const URL = window.location.search;

        if (URL.includes('&search=')) {
            const filtersURL = formatFilters(URL);
            const value = filtersURL.shift().value;
            setFiltersOther({ ...filtersOther, filterValue: value });
        }
    }, []);

    //Update Page
    useEffect(() => {
        reloadOtherList();
    }, [query]);

    //#region Function to Component
    function reloadOtherList() {
        if (query?.includes('take')) {

            const urlParams = new URLSearchParams(query);
            const take = urlParams.get('take') || 10;
            const skip = urlParams.get('skip') || 0;
            const sort = urlParams.get('sort') || '-createdAt';

            if (filtersOther.filterValue){
                props.onInitDocumentsSearch(filtersOther.filterValue, "OTHER", query)
            }
            else{
                props.onInitDocumentsOtherFilter(filtersOther.filters, skip, take, sort);
                props.onInitDocumentsOtherFilterCount(filtersOther.filters)
            }

            history.push('/otherDocuments' + query);
        }
    }

   const mapCategoriesArray = categoriesArray => {
        if (!categoriesArray) return;
        const map = categoriesArray.map(category => {
            return {
                key: category.code,
                value: category.name,
            };
        });
        return map;
    };

    const handleFiltersOtherChange = (newFiltersOther, value) =>
        setFiltersOther({ filters: newFiltersOther, filterValue: value });

    function handleEvTableStateChangeOther(queryTable) {
        setQuery(queryTable);
    }

    //#endregion Function to Component
    return (
        <>
            <HeaderTable
                title={intl.formatMessage(messages.title)}
                properties={otherProperties(intl, mapCategoriesArray, categories, hasFull)}
                handleFiltersChange={handleFiltersOtherChange}
                loading={loadingOtherList}
                onClickReload={() => reloadOtherList()}
                onClickNewButton={handleNewDocumentButtonClick}
                newButtonEnabled={newDocumentButtonEnabled}
                titleNewButton={intl.formatMessage(messages.otherNew)}
                isAlternativeFilter
                documentFieldButtonEnabled
            />
            <section>
                <EvtTable
                    filters={filtersOther.filters}
                    columns={columnsOther(documentsOther, intl, hasFull)}
                    data={documentsOther}
                    length={documentsOtherCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChangeOther}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingOtherList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: documentsOtherCount,
                    })}
                    search
                />
            </section>
        </>
    );
}

const mapStateToProps = state => {
    return {
        documentsOther: state.documentReducer.documentsOther || [],
        documentsOtherCount: state.documentReducer.documentsOtherCount || 0,
        loadingOtherList: state.documentReducer.loadingOtherList,
        categories: state.categoryReducer.categories || [],
        fields: state.documentFieldsReducer.fields || [],
        query: state.documentReducer.query || '',
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //Filters
        onInitDocumentsSearchCount: (value, documentType, query) =>
            dispatch(
                actions.initDocumentsSearchCount(value, documentType, query),
            ),
        onInitDocumentsSearch: (value, documentType, query) =>
            dispatch(actions.initDocumentsSearch(value, documentType, query)),
        onInitDocumentsOtherFilterCount: filters =>
            dispatch(actions.initDocumentsOtherFilterCount(filters)),
        onInitDocumentsOtherFilter: (filters, skip, take, sort) =>
            dispatch(
                actions.initDocumentsOtherFilter(filters, skip, take, sort),
            ),
        onInitDocumentsReport: () => dispatch(actions.initDocumentsReport()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(OtherList)),
);
