import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    generalTitle: {
        id: 'invoices.generalTitle',
        defaultMessage: 'Cabeçalho',
    },

    providerTitle: {
        id: 'invoices.providerTitle',
        defaultMessage: 'Prestador de Serviços',
    },
    issuer: {
        id: 'invoices.issuer',
        defaultMessage: 'Emitente',
    },
    sender: {
        id: 'invoices.sender',
        defaultMessage: 'Remetente',
    },
    recipient: {
        id: 'invoices.recipient',
        defaultMessage: 'Destinatário',
    },

    borrowerTitle: {
        id: 'invoices.borrowerTitle',
        defaultMessage: 'Tomador de Serviços',
    },

    middlewareTitle: {
        id: 'invoices.middlewareTitle',
        defaultMessage: 'Intermediário de Serviços',
    },

    servicesTitle: {
        id: 'invoices.servicesTitle',
        defaultMessage: 'Serviços',
    },

    taxesTitle: {
        id: 'invoices.taxesTitle',
        defaultMessage: 'Valores/Impostos',
    },

    othersTitle: {
        id: 'invoices.othersTitle',
        defaultMessage: 'Outros',
    },
    partsTitle: {
        id: 'invoices.partsTitle',
        defaultMessage: 'Partes envolvidas',
    },
    documentValues: {
        id: 'invoices.documentValues',
        defaultMessage: 'Valores do documento',
    },
    municipalTaxes: {
        id: 'invoices.municipalTaxes',
        defaultMessage: 'Imposto municipal',
    },
    federalTaxes: {
        id: 'invoices.federalTaxes',
        defaultMessage: 'Imposto federal',
    },
    texts: {
        id: 'invoices.texts',
        defaultMessage: 'Texto do documento',
    },
});

export default messages;
