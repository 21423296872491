import { defineMessages } from "react-intl";
import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,
  
  email: {
    id: "login.email",
    defaultMessage: "Email"
  },

  password: {
    id: "login.password",
    defaultMessage: "Senha"
  },

  loginButton: {
    id: "global.loginButton",
    defaultMessage: "Entrar"
  },
  invalidEmail: {
    id: "login.invalidEmail",
    defaultMessage: "O email digitado não é válido"
  },
  forgotPassword: {
    id: "login.forgotPassword",
    defaultMessage: "Esqueci minha senha"
  },
  forgotPasswordSent: {
    id: "login.forgotPasswordSent",
    defaultMessage: "Enviar"
  },
  returnButton: {
    id: "global.returnButton",
    defaultMessage: "Retornar"
  },
  selectContractor: {
    id: "login.selectContractor",
    defaultMessage: "Selecione o cliente"
  },
  changePasswordTitle: {
    id: "login.changePasswordTitle",
    defaultMessage: "Alterar senha"
  }
});

export default messages;
