import React from "react";

const LogoutIcon = ({
  width="26",
  height="30",
  viewBox="0 0 36 40",
  fill="none",
  style=""
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {style}
    xmlns="http://www.w3.org/2000/svg">
  >
    <path d="M23.3333 2L30.4444 2C31.3874 2 32.2918 2.3746 32.9586 3.0414C33.6254 3.70819 34 4.61256 34 5.55556L34 30.4444C34 31.3874 33.6254 32.2918 32.9586 32.9586C32.2918 33.6254 31.3874 34 30.4444 34L23.3333 34" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.8889 9.11111L2.00003 18L10.8889 26.8889" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.00004 18L23.3334 18" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default LogoutIcon;
