import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import Modal from '../Modal/Modal';
import messages from '../../utils/globalMessages';
import CheckBoxList from '../../components/CheckBoxList/CheckBoxList';
import Loading from '../Loading/Loading';
import Switch from '../Switch/Switch';

function ModalCities(props) {
    const {
        intl,
        isReadOnly = false,
        isOpen,
        modalTitle,
        handleToggle,
        handleFormSubmit,
        enabledCities,
        loadingCities,
        checkedCities,
        handleCityChange,
        handleChangeAllCities,
        states,
    } = props;

    const [selectAllCities, setSelectAllCities] = useState(false)
    useEffect(() => {
        const allEnabledCitiesSelected = enabledCities.every(city => {
            return checkedCities[city.ibgeCode] === true;
        });

        setSelectAllCities(allEnabledCitiesSelected);
    }, [checkedCities, enabledCities]);

    const handleSelectAllCities = () => {
        const newSelectAllCities = !selectAllCities;

        if (newSelectAllCities) {
            const initialCheckedCities = {}
            enabledCities.forEach(city => {
                initialCheckedCities[city.ibgeCode] = true;
            });
            
            handleChangeAllCities(initialCheckedCities);
        }
        else handleChangeAllCities([]);

        setSelectAllCities(newSelectAllCities);
    };

    const handleSelectAllCitiesByState = (cities, selectOrDiselect) => {
        const selectedCities = { ...checkedCities }

        if (selectOrDiselect){
            cities.forEach(cityIbgeCode => {
                selectedCities[cityIbgeCode] = true
            })

            handleChangeAllCities(selectedCities);
        }
        else{
            cities.forEach(cityIbgeCode => {
                if (selectedCities.hasOwnProperty(cityIbgeCode)) {
                    delete selectedCities[cityIbgeCode];
                }
            });
            handleChangeAllCities(selectedCities)
        }
    };

    const Distinct = (arr, prop) => {
        return arr.reduce((a, d) => {
            if (!a.includes(d[prop])) {
                a.push(d[prop]);
            }
            return a;
        }, []);
    };

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result =
                a[property] < b[property]
                    ? -1
                    : a[property] > b[property]
                        ? 1
                        : 0;
            return result * sortOrder;
        };
    }

    function reorganizeCities(arr) {
        let newCitiesArray = [];
        arr.forEach(city => {
            let stateCity = null;
            if (!!states.length) {
                var someString = String(city.ibgeCode);
                var id = Number(someString.substr(0, 2));

                stateCity = states.find(state => state.code === id);
            }
            const obj = {
                entity: stateCity?.uf,
                value: city.ibgeCode,
                description: city.name,
            };

            newCitiesArray.push(obj);
        });
        newCitiesArray.sort(dynamicSort('entity'));

        return newCitiesArray;
    }

    const items = reorganizeCities(enabledCities);

    return (
        <Modal
            title={modalTitle}
            isOpen={isOpen}
            handleToggle={handleToggle}
            hasFooter={false}
            size='xl'
        >
            <Loading loading={loadingCities} />
            <section
                className='content-middle bg-content roles-form'
                style={{ textAlign: 'left' }}
            >
                <AvForm onValidSubmit={handleFormSubmit}>
                    <Switch
                        id={'selectAndDiselectAllCities'}
                        label={selectAllCities ?
                            intl.formatMessage(messages.selectAllCities)
                            : intl.formatMessage(messages.diselectAllCities)}
                        checked={selectAllCities}
                        onChange={handleSelectAllCities} />
                    <Row className='mt-5'>
                        <Col xs={12}>
                            <CheckBoxList
                                items={items}
                                checked={checkedCities}
                                groupby={Distinct(items, 'entity')}
                                name='CheckBoxClaims'
                                handleChange={handleCityChange}
                                handleChangeByEntity={handleSelectAllCitiesByState}
                                isReadOnly={isReadOnly}
                                showSwitchButtons={true}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12}>
                            {!isReadOnly && (
                                <button className='btn-submit'>
                                    {intl.formatMessage(messages.submitButton)}
                                </button>
                            )}
                        </Col>
                    </Row>
                </AvForm>
            </section>
        </Modal>
    );
}

export default injectIntl(ModalCities);
