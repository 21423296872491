import React from "react";

const ImportIcon = ({
  width="26",
  height="30",
  viewBox="0 -10 532 600",
  fill="none",
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {{marginLeft: '-5px', marginRight: '25px'}}
    xmlns="http://www.w3.org/2000/svg">
  >
   <path d="M512 488v.12A23.94 23.94 0 0 1 488 512H151.88A23.94 23.94 0 0 1 128 488V352h127.63v64.9c0 14.27 17.28 21.34 27.37 11.27L378.56 332a17 17 0 0 0 0-23.93l-95.49-96.25c-10.09-10.07-27.37-3-27.37 11.27v65H128V23.88A24 24 0 0 1 152 0h232v112a16 16 0 0 0 16 16h112z" stroke="black" strokeWidth="43" strokeLinecap="round" strokeLinejoin="round"></path>
   <path d="M505 105L407.1 7a24 24 0 0 0-17-7H384v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zM283 211.82c-10.09-10.07-27.37-3-27.37 11.27v65H16a16 16 0 0 0-16 16V336a16 16 0 0 0 16 16h239.63v64.9c0 14.27 17.28 21.34 27.37 11.27L378.56 332a17 17 0 0 0 0-24z" stroke="black" strokeWidth="43" strokeLinecap="round" strokeLinejoin="round"></path>
</svg>
);

export default ImportIcon;
