import React from 'react';
import { injectIntl } from 'react-intl';
import messages from '../../utils/globalMessages';

import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FilesTable(props) {
    const { intl, files, setFiles } = props;

    const filesColumns = [
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>Nome do Arquivo</span>
                </div>
            ),
            accessor: 'path',
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>Tamanho</span>
                </div>
            ),
            accessor: 'size',
            Cell: row => (
                <div className='customer-container'>
                    <div>{row.value} bytes</div>
                </div>
            ),
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <button
                        style={{
                            margin: 0,
                            width: '150px',
                            float: 'initial',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        className={'btn-delete'}
                        onClick={() => setFiles([])}
                    >
                        <p style={{ margin: 0, marginRight: 5 }}>
                            Deletar Todos
                        </p>
                        <FontAwesomeIcon icon='trash-alt' />
                    </button>
                </div>
            ),
            accessor: 'size',
            Cell: row => (
                <div className='customer-container'>
                    <button
                        className={'btn-delete'}
                        onClick={() => {
                            handleDeleteFile(row.original);
                        }}
                    >
                        <FontAwesomeIcon icon='trash-alt' />
                    </button>
                </div>
            ),
        },
    ];

    const handleDeleteFile = file => {
        const fileName = file.path;

        const newFiles = files.filter(x => x.path !== fileName);
        setFiles(newFiles);
    };

    return (
        <>
            <p className='text-muted subtitle-page'>
                Total de arquivos: {files.length}
            </p>
            <ReactTable
                showPagination={false}
                noDataText={intl.formatMessage(messages.noDataText)}
                columns={filesColumns}
                data={files}
                minRows={0}
                className='-striped -highlight text-center files-table'
                previousText={intl.formatMessage(messages.previousButton)}
                nextText={intl.formatMessage(messages.nextButtonText)}
            />
        </>
    );
}

export default injectIntl(FilesTable);
