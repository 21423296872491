import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, FormGroup } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { AuthContext } from '../../context/AuthContext';
import EvtSelect from '../../components/EvtSelect/EvtSelect';
import messages from './DocumentFieldDetailsForm.intl';

function DocumentFieldDetailsForm(props) {
    const context = useContext(AuthContext);

    const { intl, field, isNew, categories } = props;
    const mapItem = field?.mapItem?.field || {};

    const { constraints, layout } = mapItem;
    const documentTypes = ['NFS', 'NFE', 'CTE', 'OTHER'];

    const canCreateDocumentField =
        context.hasClaim('document-field-create') && isNew;

    const canDeleteDocumentField =
        context.hasClaim('document-field-delete') && !isNew;

    const canUpdateDocumentField =
        context.hasClaim('document-field-update') && !isNew;

    const isReadOnly = !(canCreateDocumentField || canUpdateDocumentField);

    const isDocument = field?.includeDocument;

    const [showListSelect, setShowListSelect] = useState(false);
    const [selectedFieldType, setSelectedFieldType] = useState(false);
    const [selectedContractor, setSelectedContractor] = useState(null);
    const [selectedList, setSelectedList] = useState(null);

    const [selectedDocType, setSelectedDocType] = useState('NFS');
    const [formGroup, setFormGroup] = useState('0');

    useEffect(() => {
        if (field?.documentFieldsListId) {
            setSelectedList({
                label: field?.documentFieldsListName,
                value: field?.documentFieldsListId,
            });
        }

        if (field?.contractorId) {
            setSelectedContractor({
                label: field?.contractorName,
                value: field?.contractorId,
            });
        }

        if (field?.documentType) {
            setSelectedDocType(field?.documentType || 'NFS');
        }

        if (field?.mapItem?.field?.formGroup) {
            setFormGroup(field?.mapItem?.field?.formGroup);
        }
    }, [field]);

    useEffect(() => {
        if (!!selectedContractor) {
            (mapItem?.type?.toUpperCase() === 'LIST' ||
                selectedFieldType === 'LIST') &&
                setShowListSelect(true);
        }
    }, [selectedContractor]);

    const handleSelectFieldType = (e, option) => {
        const optionUpper = option?.toUpperCase();

        if (optionUpper === 'LIST') {
            setShowListSelect(true);
        } else {
            setShowListSelect(false);
            setSelectedList('');
        }

        setSelectedFieldType(optionUpper);
    };

    const handleSelectContractor = option => {
        setSelectedContractor(option);
        setShowListSelect(false);
        setSelectedList('');
    };

    const handleSelectedDocType = e => {
        const value = e?.target.value;
        setSelectedDocType(value);

        if (value === 'NFE' || value === 'CTE') {
            setFormGroup('1');
        }
    };

    const handleSelectedList = option => setSelectedList(option);
    const hasDocumentWithContractor = isDocument && showListSelect;

    return (
        <AvForm
            onValidSubmit={(event, values) =>
                props.handleSubmit(event, {
                    ...values,
                    includeDocument: field.includeDocument,
                })
            }
        >
            <Row>
                {isDocument && (
                    <Col md={4}>
                        <EvtSelect
                            label={intl.formatMessage(messages.contractor)}
                            name='contractorId'
                            id='contractor'
                            url={`/Contractors`}
                            labelKey='displayName'
                            valueKey='contractorId'
                            async
                            key={selectedContractor?.value}
                            required
                            disabled={isReadOnly}
                            errorMessage=' '
                            handleSelectedOptionChange={handleSelectContractor}
                            option={
                                selectedContractor && {
                                    selectedValueOption:
                                        selectedContractor?.value,
                                    selectedLabelOption:
                                        selectedContractor?.label,
                                }
                            }
                        />
                    </Col>
                )}
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.documentType)}
                            type='select'
                            name='documentType'
                            id='documentType'
                            disabled={isReadOnly}
                            value={(field && field.documentType) || ''}
                            onChange={handleSelectedDocType}
                            errorMessage=' '
                            required
                        >
                            <option value=""> </option>
                            {documentTypes &&
                                documentTypes.map(documentType => (
                                    <option value={documentType}>
                                        {documentType}
                                    </option>
                                ))}
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.categoryType)}
                            type='select'
                            name='categoryType'
                            id='categoryType'
                            disabled={isReadOnly}
                            value={field && field.categoryType}
                            errorMessage=' '
                        >
                            <option />
                            {categories &&
                                categories.map(category => (
                                    <option value={category.name}>
                                        {category.name}
                                    </option>
                                ))}
                        </AvField>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.name)}
                            type='text'
                            name='name'
                            id='name'
                            disabled={isReadOnly}
                            value={field && field.name}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.description)}
                            type='text'
                            name='mapItem.field.description'
                            id='description'
                            disabled={isReadOnly}
                            value={field && mapItem.description}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label='Map name'
                            type='text'
                            name='mapItem.field.name'
                            id='mapName'
                            disabled={isReadOnly}
                            value={field && mapItem.name}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={hasDocumentWithContractor ? 2 : 3}>
                    <FormGroup>
                        <AvField
                            label='Key'
                            type='text'
                            name='mapItem.field.key'
                            id='key'
                            disabled={isReadOnly}
                            value={field && mapItem.key}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={hasDocumentWithContractor ? 2 : 3}>
                    <FormGroup>
                        <AvField
                            label='KeyXml'
                            type='text'
                            name='mapItem.field.KeyXml'
                            id='keyXml'
                            disabled={isReadOnly}
                            value={field && mapItem.keyXml}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={hasDocumentWithContractor ? 1 : 2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.type)}
                            type='select'
                            name='mapItem.field.type'
                            id='type'
                            disabled={isReadOnly}
                            value={field && mapItem.type}
                            errorMessage=' '
                            required
                            onChange={handleSelectFieldType}
                        >
                            <option />
                            <option value='Number'>Number</option>
                            <option value='Money'>Money</option>
                            <option value='Text'>Text</option>
                            <option value='Date'>Date</option>
                            <option value='Time'>Time</option>
                            <option value='List'>List</option>
                            <option value='textarea'>TextArea</option>
                            <option value='boolean'>Boolean</option>
                        </AvField>
                    </FormGroup>
                </Col>
                {hasDocumentWithContractor && (
                    <Col md={3}>
                        <EvtSelect
                            label={intl.formatMessage(messages.fieldsList)}
                            name='documentFieldsListId'
                            id='documentFieldsListId'
                            url={`/Documents/DocumentFields/${
                                selectedContractor?.value
                            }/Lists`}
                            labelKey='listName'
                            valueKey='id'
                            async
                            disabled={isReadOnly}
                            key={selectedList?.id}
                            required
                            handleSelectedOptionChange={handleSelectedList}
                            option={
                                selectedList && {
                                    selectedValueOption: selectedList.value,
                                    selectedLabelOption: selectedList.label,
                                }
                            }
                        />
                    </Col>
                )}
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label='Form Group'
                            type='select'
                            name='mapItem.field.formGroup'
                            id='formGroup'
                            disabled={
                                isReadOnly ||
                                selectedDocType === 'NFE' ||
                                selectedDocType === 'CTE'
                            }
                            value={formGroup}
                            onChange={e => setFormGroup(e.target.value)}
                            errorMessage=' '
                            required
                        >
                            <option />
                            <option value='1'>
                                {intl.formatMessage(messages.generalTitle)}
                            </option>
                            <option value='2'>
                                {intl.formatMessage(messages.providerTitle)}
                            </option>
                            <option value='3'>
                                {intl.formatMessage(messages.borrowerTitle)}
                            </option>
                            <option value='4'>
                                {intl.formatMessage(messages.middlewareTitle)}
                            </option>
                            <option value='5'>
                                {intl.formatMessage(messages.servicesTitle)}
                            </option>
                            <option value='6'>
                                {intl.formatMessage(messages.taxesTitle)}
                            </option>
                            <option value='7'>
                                {intl.formatMessage(messages.othersTitle)}
                            </option>
                            <option value='8'>
                                {intl.formatMessage(messages.partsTitle)}
                            </option>
                            <option value='9'>
                                {intl.formatMessage(messages.documentValues)}
                            </option>
                            <option value='10'>
                                {intl.formatMessage(messages.municipalTaxes)}
                            </option>
                            <option value='11'>
                                {intl.formatMessage(messages.federalTaxes)}
                            </option>
                            <option value='12'>
                                {intl.formatMessage(messages.texts)}
                            </option>
                        </AvField>
                    </FormGroup>
                </Col>
                <Col md={1}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(messages.active)}
                            type='checkbox'
                            name='active'
                            id='active'
                            disabled={isReadOnly}
                            value={isNew ? false : (field && field.active)}
                        />
                    </FormGroup>
                </Col>
                <Col md={1}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(messages.isSearchable)}
                            type='checkbox'
                            name='mapItem.field.isSearchable'
                            id='isSearchable'
                            disabled={isReadOnly}
                            value={field && mapItem.isSearchable}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <h5 className='title-area-form'>Constraints</h5>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label='Pattern'
                            type='text'
                            name='mapItem.field.constraints.pattern'
                            id='pattern'
                            disabled={isReadOnly}
                            value={constraints && constraints.pattern}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup check className='mt-3 required-field'>
                        <AvField
                            label={intl.formatMessage(messages.required)}
                            type='checkbox'
                            name='mapItem.field.constraints.required'
                            id='required'
                            disabled={isReadOnly}
                            value={constraints && constraints.required}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label='Min Length'
                            type='number'
                            name='mapItem.field.constraints.minLength'
                            id='minLength'
                            disabled={isReadOnly}
                            value={constraints && constraints.minLength}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label='Max Length'
                            type='number'
                            name='mapItem.field.constraints.maxLength'
                            id='maxLength'
                            disabled={isReadOnly}
                            value={constraints && constraints.maxLength}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label='Rows'
                            type='number'
                            name='mapItem.field.constraints.rows'
                            id='rows'
                            disabled={isReadOnly}
                            value={constraints && constraints.rows}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <div>
                <h5 className='title-area-form'>Layout</h5>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <AvField
                                label='Classes'
                                type='text'
                                name='mapItem.field.layout.classes'
                                id='classes'
                                disabled={isReadOnly}
                                value={layout && layout.classes}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Xs'
                                type='number'
                                name='mapItem.field.layout.xs'
                                id='xs'
                                disabled={isReadOnly}
                                value={layout && layout.xs}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetXs'
                                type='number'
                                name='mapItem.field.layout.offsetXs'
                                id='offsetXs'
                                disabled={isReadOnly}
                                value={layout && layout.offsetXs}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Sm'
                                type='number'
                                name='mapItem.field.layout.sm'
                                id='sm'
                                disabled={isReadOnly}
                                value={layout && layout.sm}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetSm'
                                type='number'
                                name='mapItem.field.layout.offsetSm'
                                id='offsetSm'
                                disabled={isReadOnly}
                                value={layout && layout.offsetSm}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Md'
                                type='number'
                                name='mapItem.field.layout.md'
                                id='md'
                                disabled={isReadOnly}
                                value={layout && layout.md}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetMd'
                                type='number'
                                name='mapItem.field.layout.offsetMd'
                                id='offsetMd'
                                disabled={isReadOnly}
                                value={layout && layout.offsetMd}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Lg'
                                type='number'
                                name='mapItem.field.layout.lg'
                                id='lg'
                                disabled={isReadOnly}
                                value={layout && layout.lg}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetLg'
                                type='number'
                                name='mapItem.field.layout.offsetLg'
                                id='offsetLg'
                                disabled={isReadOnly}
                                value={layout && layout.offsetLg}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='Xl'
                                type='number'
                                name='mapItem.field.layout.xl'
                                id='xl'
                                disabled={isReadOnly}
                                value={layout && layout.xl}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <AvField
                                label='OffsetXl'
                                type='number'
                                name='mapItem.field.layout.offsetXl'
                                id='offsetXl'
                                disabled={isReadOnly}
                                value={layout && layout.offsetXl}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col xs={12}>
                    {!isReadOnly && (
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    )}

                    {canDeleteDocumentField && (
                        <button
                            type='button'
                            className='btn-delete'
                            onClick={props.handleToggleModalDelete}
                        >
                            {intl.formatMessage(messages.deleteButton)}
                        </button>
                    )}
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(DocumentFieldDetailsForm);
