import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    from: {
        id: 'global.from',
        defaultMessage: 'A partir de',
    },
    to: {
        id: 'global.to',
        defaultMessage: 'até',
    },
});

export default messages;
