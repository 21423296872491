import * as actionTypes from '../actions/actionTypes';

const initialState = {
    transactions: [],
    transaction: null,
    loadingList: true,
    loadingDetails: true,
    transactionsCount: 0,
};

const setTransactions = (state, action) => {
    return {
        ...state,
        transactions: action.transactions,
        loadingList: false,
    };
};

const setTransactionsCount = (state, action) => {
    return {
        ...state,
        transactionsCount: action.transactionsCount,
    };
};

const setTransactionsRequest = (state, action) => {
    return {
        ...state,
        transactions: [],
        loadingList: true,
    };
};

const setTransactionDetailsRequest = (state, action) => {
    return {
        ...state,
        transaction: undefined,
        loadingDetails: true,
    };
};

const setTransactionDetails = (state, action) => {
    return {
        ...state,
        transaction: action.transaction,
        loadingDetails: false,
    };
};

const setTransactionDetailsPost = (state, action) => {
    return {
        ...state,
        transaction: action.transaction,
        loadingDetails: false,
        transactions: []
    };
};

const setTransactionDetailsNew = (state, action) => {
    return {
        ...state,
        transaction: undefined,
        loadingDetails: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TRANSACTIONS_GET_LIST_REQUEST:
            return setTransactionsRequest(state, action);

        case actionTypes.TRANSACTIONS_GET_LIST_FAILURE:
        case actionTypes.TRANSACTIONS_GET_LIST_SUCCESS:
            return setTransactions(state, action);

        case actionTypes.TRANSACTIONS_GET_COUNT_SUCCESS:
            return setTransactionsCount(state, action);

        case actionTypes.TRANSACTION_POST_SUCCESS:
        case actionTypes.TRANSACTION_POST_FAILURE:
            return setTransactionDetailsPost(state, action);

        case actionTypes.TRANSACTION_PUT_SUCCESS:
        case actionTypes.TRANSACTION_GET_DETAILS_FAILURE:
        case actionTypes.TRANSACTION_GET_DETAILS_SUCCESS:
            return setTransactionDetails(state, action);

        case actionTypes.TRANSACTION_GET_DETAILS_NEW:
            return setTransactionDetailsNew(state, action);

        case actionTypes.TRANSACTION_POST_REQUEST:
        case actionTypes.TRANSACTION_GET_DETAILS_REQUEST:
            return setTransactionDetailsRequest(state, action);
        default:
            return state;
    }
};

export default reducer;
