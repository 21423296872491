import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    invoiceMap: {
        id: 'maps.invoiceMap',
        defaultMessage: 'Mapear Nota {entity}',
    },
    uploadMultipleFilesText: {
        id: 'maps.uploadMultipleFilesText',
        defaultMessage: 'Somente um arquivo disponível para upload',
    },
    imgFileSizeText: {
        id: 'maps.imgFileSizeText',
        defaultMessage: 'O arquivo deve ser menor do que {size}MB',
    },
    completedRequiredFieldsText: {
        id: 'maps.completedRequiredFieldsText',
        defaultMessage: 'concluído do campos obrigatórios',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    title: {
        id: 'maps.title',
        defaultMessage: 'Mapear Nota',
    },
    titleMap: {
        id: 'maps.titleMap',
        defaultMessage: 'Mapeamento {entity}',
    },
    deactivateMap: {
        id: 'maps.deactivateMap',
        defaultMessage: 'Inativar',
    },
    activateMap: {
        id: 'maps.activateMap',
        defaultMessage: 'Ativar',
    },
    titleOnlyField: {
        id: 'maps.titleOnlyField',
        defaultMessage: 'Campo',
    },
    itemDetails: {
        id: 'maps.itemDetails',
        defaultMessage: 'Detalhes do item',
    },
    itemCleanup: {
        id: 'maps.itemCleanup',
        defaultMessage: 'Limpar',
    },
    ocrTest: {
        id: 'maps.ocrTest',
        defaultMessage: 'Testar OCR',
    },
    associate: {
        id: 'maps.associate',
        defaultMessage: 'Associar',
    },
    modalAssociateTitle: {
        id: 'maps.modalAssociateTitle',
        defaultMessage: 'Associar Mapeamento',
    },
    contractor: {
        id: 'maps.contractor',
        defaultMessage: 'Contratante',
    },
    associateMapWarning: {
        id: 'maps.associateMapWarning',
        defaultMessage:
            'Ao confirmar, irá associar o mapeamentos a todos os fornecedores da cidade do mapeamento.',
    },
    confirmAssociate: {
        id: 'maps.confirmAssociate',
        defaultMessage: 'Confirmar Associação',
    },
    updateMapFile: {
        id: 'maps.updateMapFile',
        defaultMessage: 'Atualizar arquivo',
    },
    deleteMapItemConfirmatinTitle: {
        id: 'maps.deleteMapItemConfirmatinTitle',
        defaultMessage: 'Deletar item',
    },
    deleteMapItemConfirmationMessage: {
        id: 'maps.deleteMapItemConfirmationMessage',
        defaultMessage: 'Tem certeza que vai deletar o campo',
    },
    documentType: {
        id: 'maps.documentType',
        defaultMessage: 'Tipo documento',
    },
    fileType: {
        id: 'maps.fileType',
        defaultMessage: 'Tipo arquivo',
    },

    categoryType: {
        id: 'maps.categoryType',
        defaultMessage: 'Categoria',
    },
    subCategory: {
        id: 'maps.subCategory',
        defaultMessage: 'SubCategoria',
    },
    childMapId: {
        id: 'maps.childMapId',
        defaultMessage: 'Mapeamento filho',
    },
    mapsFields: {
        id: 'maps.fields',
        defaultMessage: 'Campos',
    },
    mapExample: {
        id: 'maps.mapExample',
        defaultMessage: 'Resultado do OCR',
    },
    newField: {
        id: 'maps.newField',
        defaultMessage: 'Novo Campo',
    },
    mapOptions: {
        id: 'maps.mapOptions',
        defaultMessage: 'Opções do mapeamento',
    },
    isRepeatingMap: {
        id: 'maps.isRepeatingMap',
        defaultMessage: 'Habilitar extração de múltiplos documentos',
    },
    start: {
        id: 'maps.start',
        defaultMessage: 'Início',
    },
    end: {
        id: 'maps.end',
        defaultMessage: 'Fim',
    },
    pixelAdjust: {
        id: 'mapItem.pixelAdjust',
        defaultMessage: 'Ajuste por pixel',
    },
    orientation: {
        id: 'mapItem.orientation',
        defaultMessage: 'Orientação',
    },
});
