import * as actionTypes from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actions from './index';

export const initContractors = filter => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTORS_GET_LIST_REQUEST,
        });

        axios
            .get('/Contractors' + (filter ? filter : ''))
            .then(response => {
                dispatch({
                    type: actionTypes.CONTRACTORS_GET_LIST_SUCCESS,
                    contractors: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CONTRACTORS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initContractorsCount = query => {
    return async dispatch => {
        dispatch({
            type: actionTypes.CONTRACTORS_GET_COUNT_REQUEST,
        });

        await axios
            .get('/Contractors/Count' + (query || ''))
            .then(response => {
                dispatch({
                    type: actionTypes.CONTRACTORS_GET_COUNT_SUCCESS,
                    contractorsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CONTRACTORS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initContractorDetails = contractorId => {
    return (dispatch, getState) => {
        if (contractorId === 'new') {
            dispatch(actions.initStates());
            dispatch({
                type: actionTypes.CONTRACTOR_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.CONTRACTOR_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Contractors/${contractorId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.CONTRACTOR_GET_DETAILS_SUCCESS,
                        contractor: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.CONTRACTOR_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const createContractor = values => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTOR_POST_REQUEST,
        });

        axios
            .post(`/Contractors`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.CONTRACTOR_POST_SUCCESS,
                    contractorId: response.data,
                });

                toast.success('Contratante criado com sucesso');
                dispatch(push('/contractors'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CONTRACTOR_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateContractor = (contractorId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTOR_PUT_REQUEST,
        });

        axios
            .put(`/Contractors/${contractorId}`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.CONTRACTOR_PUT_SUCCESS,
                });
                toast.success('Contratante alterado com sucesso');
                dispatch(push('/contractors'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CONTRACTOR_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteContractor = contractorId => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTOR_DELETE_REQUEST,
        });

        axios
            .delete(`/Contractors/${contractorId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.CONTRACTOR_DELETE_SUCCESS,
                });
                toast.success('Contratante excluído com sucesso');
                dispatch(push('/contractors'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CONTRACTOR_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const initRolesByContractor = contractorId => {
    return dispatch => {
        dispatch({
            type: actionTypes.ROLES_CONTRACTOR_GET_LIST_REQUEST,
        });

        axios
            .get(`/Contractors/${contractorId}/Roles`)
            .then(response => {
                dispatch({
                    type: actionTypes.ROLES_CONTRACTOR_GET_LIST_SUCCESS,
                    rolesContractor: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ROLES_CONTRACTOR_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initSuppliersByContractor = (contractorId, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIERS_GET_LIST_REQUEST,
        });

        axios
            .get(
                `/Contractors/${contractorId}/Suppliers` + (query ? query : ''),
            )
            .then(response => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_LIST_SUCCESS,
                    suppliers: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initSuppliersByContractorCount = (contractorId, query) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIERS_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Contractors/${contractorId}/Suppliers/Count${query}`)
            .then(response => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_COUNT_SUCCESS,
                    suppliersCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initMapsByContractor = (contractorId, filter) => {
    return dispatch => {
        dispatch({
            type: actionTypes.MAPS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Contractors/${contractorId}/Maps${filter}`)
            .then(response => {
                dispatch({
                    type: actionTypes.MAPS_GET_LIST_SUCCESS,
                    maps: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.MAPS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const uploadContractorNormalLogo = (contractorId, file) => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTOR_UPLOAD_NORMAL_LOGO_REQUEST,
        });

        const data = new FormData();
        data.append('logo', file);

        axios
            .put(`/Contractors/${contractorId}/Logo`, data)
            .then(response => {
                dispatch({
                    type: actionTypes.CONTRACTOR_UPLOAD_NORMAL_LOGO_SUCCESS,
                });
                
                toast.success('O logo do contratante foi atualizado com sucesso.');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CONTRACTOR_UPLOAD_NORMAL_LOGO_FAILURE,
                    error,
                });
            });
    };
};

export const uploadContractorSmallLogo = (contractorId, file) => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTOR_UPLOAD_SMALL_LOGO_REQUEST,
        });

        const data = new FormData();
        data.append('smallLogo', file);

        axios
            .put(`/Contractors/${contractorId}/SmallLogo`, data)
            .then(response => {
                dispatch({
                    type: actionTypes.CONTRACTOR_UPLOAD_SMALL_LOGO_SUCCESS,
                });

                toast.success('O logo pequeno do contratante foi atualizado com sucesso.');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CONTRACTOR_UPLOAD_SMALL_LOGO_FAILURE,
                    error,
                });
            });
    };
};

export const uploadContractorAttachment = (contractorId, file) => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTOR_UPLOAD_ATTACHMENT_REQUEST,
        });

        const data = new FormData();
        data.append('attachment', file);

        axios
        .put(`/Contractors/${contractorId}/Attachment`, data)
            .then(response => {
                dispatch({
                    type: actionTypes.CONTRACTOR_UPLOAD_ATTACHMENT_SUCCESS,
                });

                toast.success('O anexo do contratante foi atualizado com sucesso.');
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CONTRACTOR_UPLOAD_ATTACHMENT_FAILURE,
                    error,
                });
            });
    };
};

export const initLayoutByContractor = domain => {
    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTORS_GET_LAYOUT_REQUEST,
        });

        axios
            .get(`/ContractorsLayout/${domain}`)
            .then(response => {
                dispatch({
                    type: actionTypes.CONTRACTORS_GET_LAYOUT_SUCCESS,
                    contractorLayout: response.data,
                });
            })
            .catch(error => {
                let parts = window.location.hostname.split('.');
                parts[0] = 'app';
                window.location.hostname = parts.join('.');
            });
    };
};

export const setSelectedContractor = contractor => {

    return dispatch => {
        dispatch({
            type: actionTypes.CONTRACTOR_SET_REQUEST,
        });
        dispatch({
            type: actionTypes.CONTRACTOR_SET_SUCCESS,
            contractor: contractor,
        });
    };
};

export const setTabContractorIndex = tabIndex => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TAB_CONTRACTOR_INDEX,
            tabIndex,
        });
    };
};
