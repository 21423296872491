import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './ContractorListPage.intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import { AuthContext } from '../../context/AuthContext';

import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';

class ContractorListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    componentDidMount() {
        this.props.onSetTabContractorIndex(0);
    }

    reloadContractorsList = () => {
        const { query } = this.state;

        this.props.history.push('/contractors' + query);

        this.props.onInitContractors(query);
        this.props.onInitContractorsCount(query);
    };

    handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e =>
                this.props.history.push(
                    `/contractors/${rowInfo.original.contractorId}`,
                ),
        };
    };

    handleNewClick = () => {
        this.props.history.push(`/contractors/new`);
    };

    handleRefreshButtonClick = e => {
        this.reloadContractorsList();
    };

    handleEvTableStateChange = query => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadContractorsList();
            },
        );
    };

    handleFiltersChange = filters => {
        this.setState({
            filters,
        });
        this.props.history.push(`/contractors` + this.state.query);
    };

    render() {
        const { intl, loadingList, contractors, contractorsCount } = this.props;
        const { filters } = this.state;

        const newContractButtonEnabled = this.context.hasClaim(
            'contractor-create',
        );
        const properties = [
            {
                key: 'displayName',
                value: intl.formatMessage(messages.displayName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'documentNumber1',
                value: intl.formatMessage(messages.cnpj),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'emailAddress',
                value: intl.formatMessage(messages.email),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'address.city',
                value: intl.formatMessage(messages.city),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'address.state',
                value: intl.formatMessage(messages.state),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.displayName)}</span>
                    </div>
                ),
                accessor: 'displayName',
                Cell: row => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                style: {
                    fontSize: 'small',
                },
                maxWidth: 310,
                minWidth:
                    150 +
                    calculateColumnWidth(
                        contractors,
                        'displayName',
                        intl.formatMessage(messages.displayName),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.cnpj)}</span>
                    </div>
                ),
                accessor: 'documentNumber1',
                minWidth:
                    100 +
                    calculateColumnWidth(
                        contractors,
                        'documentNumber1',
                        intl.formatMessage(messages.cnpj),
                    ),
                Cell: row => {
                    return (
                        <div className='customer-container'>
                            <div className=''>
                                {row.value.length === 14
                                    ? row.value.replace(
                                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                          '$1.$2.$3/$4-$5',
                                      )
                                    : row.value.replace(
                                          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                                          '$1.$2.$3-$4',
                                      )}
                            </div>
                        </div>
                    );
                },
                style: {
                    textAlign: 'center',
                    fontSize: 'small',
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'emailAddress',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    80 +
                    calculateColumnWidth(
                        contractors,
                        'emailAddress',
                        intl.formatMessage(messages.email),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.city)}</span>
                    </div>
                ),
                accessor: 'address.city',
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    80 +
                    calculateColumnWidth(
                        contractors,
                        'address.city',
                        intl.formatMessage(messages.city),
                    ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.state)}</span>
                    </div>
                ),
                accessor: 'address.state',
                maxWidth: 80,
                style: {
                    fontSize: 'small',
                },
                minWidth:
                    30 +
                    calculateColumnWidth(
                        contractors,
                        'address.state',
                        intl.formatMessage(messages.state),
                    ),
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/contractors',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                this.props.contractorsCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newContractButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleContractor,
                                        {
                                            entity: '',
                                        },
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={contractors}
                        length={contractorsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        contractors: state.contractorReducer.contractors || [],
        contractorsCount: state.contractorReducer.contractorsCount || 0,
        loadingList: state.contractorReducer.loadingList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitContractors: query => dispatch(actions.initContractors(query)),
        onInitContractorsCount: query =>
            dispatch(actions.initContractorsCount(query)),
        onSetTabContractorIndex: tabIndex =>
            dispatch(actions.setTabContractorIndex(tabIndex)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ContractorListPage),
);
