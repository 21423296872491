import React from 'react';

import { injectIntl } from 'react-intl';
import { Col, Row, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal, { modalTypes } from '../../Modal/Modal';
import messages from '../FileUploadForm.intl';
import ImageCropper from '../../ImageCropper/ImageCropper';
import Loading from '../../Loading/Loading';

function ModalImage(props) {
    const {
        intl,
        showModal,
        loadingCrop,
        handleCropModalToggle,
        handleCropConfirmClick,
        cropFileUrl,
        handleCropChange,
        loading,
    } = props;
    
    return (
        <>
            <Modal
                noHeader={true}
                isOpen={showModal}
                handleToggle={()=>{}}
                type={modalTypes.SUCCESS}
                hasFooter={false}
                firstButtonText={intl.formatMessage(messages.cancelButton)}
                secondButtonText={intl.formatMessage(messages.confirmButton)}
                loading={loadingCrop}
                firstButtonClass='btn-delete'
                secondButtonClass='btn-submit'
                toggleFirstButton={handleCropModalToggle}
                toggleSecondButton={handleCropConfirmClick}
                size='xl'
            >
                <Loading loading={loadingCrop} />
                <Row>
                    <Col xs={{ offset: 2, size: 4 }}>
                        <button
                            className='btn-delete'
                            onClick={handleCropModalToggle}
                            disabled={loading || loadingCrop}
                        >
                            {intl.formatMessage(messages.cancelButton)}
                        </button>
                    </Col>
                    <Col xs={4} className='mr-2'>
                        <button
                            className='btn-submit'
                            onClick={handleCropConfirmClick}
                            disabled={loading || loadingCrop}
                        >
                            {intl.formatMessage(messages.confirmButton)}
                        </button>
                    </Col>
                </Row>
                <br />
                {!props.hideCrop && (
                    <Alert color='info'>
                        <FontAwesomeIcon icon='info-circle' />{' '}
                        {intl.formatMessage(messages.cropInstructions)}
                    </Alert>
                )}
                <br />
                <ImageCropper
                    src={cropFileUrl}
                    handleCropChange={handleCropChange}
                    hideCrop={props.hideCrop}
                />
                <Row className='d-none' />
            </Modal>
        </>
    );
}

export default injectIntl(ModalImage);
