import React, { useEffect, useState } from 'react';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import messages from './ModalNfeItemDetails.intl';

import Modal from '../Modal/Modal';
import NfeItemDetailsForm from '../NfeItemDetailsForm/NfeItemDetailsForm';

const ModalNfeItemDetails = props => {
    const { intl, isOpen, handleToggle, nfeItemDetails } = props;

    return (
        <>
            <Modal
                title={intl.formatMessage(messages.nfeItemDetails)}
                isOpen={isOpen}
                handleToggle={handleToggle}
                size={'xxl'}
            >
                <section className='content-middle bg-content'>
                    <NfeItemDetailsForm
                        nfeItem={nfeItemDetails}
                        loading={false}
                    />
                </section>
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

ModalNfeItemDetails.propTypes = {
    selectedDocument: PropTypes.object,
    nfeItemId: PropTypes.string,
    isOpen: PropTypes.bool,
    handleToggle: PropTypes.func,
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ModalNfeItemDetails),
);
