import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const tabs = props => {
    return (
        <div>
            <Nav tabs>
                {props.tabs.map((data, index) => {
                    if (data.disabled) {
                        return;
                    }

                    return (
                        <NavItem key={index}>
                            <NavLink
                                disabled={data.disabled}
                                className={classnames({
                                    active: props.activeTab === index,
                                })}
                                onClick={e =>
                                    props.handleTabClick(e, index, data)
                                }
                                key={index}
                            >
                                {data.name}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={props.activeTab} className='mt-3'>
                {props.tabs
                    .filter((_, index) => props.activeTab === index)
                    .map((data, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                key={index}
                                style={{
                                    display: 'block',
                                }}
                            >
                                {data.component}
                            </TabPane>
                        );
                    })}
            </TabContent>
        </div>
    );
};

export default tabs;
