import React from "react";

const TransactionIcon =   ({
  width="26",
  height="30",
  viewBox="0 0 36 40",
  fill="none",
  style=""
}) => (
  <svg 
    width={width}
    height={height} 
    fill= {fill}
    viewBox = {viewBox}
    style = {style}
    xmlns="http://www.w3.org/2000/svg">
  >
    <path d="M31.0909 2H4.90909C3.30244 2 2 3.30244 2 4.90909V22.3636C2 23.9703 3.30244 25.2727 4.90909 25.2727H31.0909C32.6976 25.2727 34 23.9703 34 22.3636V4.90909C34 3.30244 32.6976 2 31.0909 2Z" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 10.7273H34" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default TransactionIcon;
