import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import { injectIntl } from 'react-intl';
import messages from './DocumentDetailsPage.intl';

import { Row, Col } from 'reactstrap';
import { Container } from 'reactstrap';
import EvtTable from '../../components/EvtTable/EvtTable';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DocumentDetailsForm from '../../components/DocumentDetailsForm/DocumentDetailsForm';
import FileUploadForm from '../../components/FileUploadForm/FileUploadForm';
import Modal from '../../components/Modal/Modal';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup } from 'reactstrap';
import { CombinedContext } from '../../context/CombinedContext';
import { toast } from 'react-toastify';
import ModalOrdersDocuments from '../../components/ModalOrdersDocument/ModalOrdersDocument';
import { columnsEvents, columnsLogs } from './components/Columns';
import AssociatedDocumentsModal from '../../components/AssociatedDocumentsModal/AssociatedDocumentsModal';

class DocumentDetailsPage extends PureComponent {
    static contextType = CombinedContext;

    state = {
        showModal: false,
        showModalReject: false,
        showEventsModal: false,
        showHistoryModal: false,
        documentHistoryList: [],
        showModalReport: false,
        showMapPicker: false,
        showOrdersDocuments: false,
        showChildDocumentsModal: false,
        showModalInformCancel: false,
        dropdownIsOpen: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            justificationtext: '',
        };
        this.state = {
            conditionalRequired: '',
            documentHistoryList: [],
        };
        this.formRef = React.createRef();
        this.justificationtextChange = this.justificationtextChange.bind(this);
    }

    justificationtextChange(e) {
        this.setState({
            justificationtext: e.target.value,
        });
    }

    componentDidMount() {
        const documentId = this.props.match.params.id;
        if (documentId !== 'new') {
            this.props.onInitDocumentDetails(documentId);
            this.props.onInitdocumentHistory(documentId);
            this.props.onInitCategories();
        }
    }
    componentDidUpdate(prevProps) {
        const { selectedDocument } = this.props;
        const paramId = this.props.match.params.id;

        if (paramId !== prevProps.match.params.id && paramId !== 'new') {
            this.props.onInitAssociatedDocumentsCount(paramId);
        }
        if (selectedDocument != prevProps.selectedDocument) {
            if (selectedDocument?.documentType === 'NFE') {
                this.props.history.push(
                    `/nfeDocuments/${selectedDocument.documentId}`,
                );
            } else if (selectedDocument?.documentType === 'CTE') {
                this.props.history.push(
                    `/cteDocuments/${selectedDocument.documentId}`,
                );
            }

            if (selectedDocument?.isGroupingDocument) {
                this.props.onInitSupplierMaps(selectedDocument?.supplierId);
            }
        }
    }

    componentWillUnmount() {
        this.props.onCleanSelectedDocument();
    }

    handleValidFormSubmit = (e, values, isDraft, selectedCategoryMap) => {
        const formFields = {
            formFields: values,
        };
        delete formFields.formFields.viewDocumentId;

        this.props.onUpdateDocumentFormDetails(
            this.props.selectedDocument.documentId,
            formFields,
            formFields.formFields && formFields.formFields.orderId
                ? formFields.formFields.orderId
                : this.props.selectedDocument.orderId,
            isDraft,
            this.props.selectedDocument?.isAttachment,
            this.props.selectedDocument?.documentType,
            selectedCategoryMap
        );
    };

    handleUploadFile = (file, orderItemsList) => {
        const {
            supplierId,
            mapId,
            orderId,
            origin,
            parentDocumentId,
            isBoletoAttachment,
            isRawFile,
            pushUrl,
        } = this.props.location.state || {};

        let listItemsId = [];

        orderItemsList &&
            orderItemsList.forEach(item => {
                listItemsId.push(item.orderItemId);
            });

        const payload = {
            file,
            supplierId,
            mapId,
            orderId,
            origin,
            parentDocumentId,
            orderItemsList: listItemsId,
        };

        if (isRawFile) {
            this.props.onCreateDocument({ ...payload, isRawFile }, pushUrl);
            return;
        } else if (isBoletoAttachment) {
            this.props.onCreateDocument(
                { ...payload, isBoletoAttachment },
                pushUrl,
            );
            return;
        } else {
            this.props.onCreateDocument(payload, pushUrl);
        }
    };

    handleToggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    handleToggleModalReject = () => {
        this.setState({ showModalReject: !this.state.showModalReject });
    };

    handleToggleModalReport = () => {
        this.setState({ showModalReport: !this.state.showModalReport });
    };

    handleToggleEventsModal = () => {
        this.setState({ showEventsModal: !this.state.showEventsModal });
    };

    handleToggleHistoryModal = () => {
        this.setState({ showHistoryModal: !this.state.showHistoryModal });
    };

    handleHistoryPage = query => {
        const documentHistoryList = this.paginateHistory(query);

        this.setState({
            documentHistoryList,
        })
    };
    handleToggleModalInformCancel = () => {
        const currentStep = this.props.selectedDocument?.currentStep || {};

        if (currentStep.status === 'error') {
            return;
        }

        if (
            currentStep.order === 3 ||
            (currentStep.order === 4 && currentStep.status === 'pending')
        ) {
            this.handleInformCancelSubmit();
        } else {
            this.setState({
                showModalInformCancel: !this.state.showModalInformCancel,
            });
        }
    };

    handleInformCancelSubmit = () => {
        const documentId = this.props.selectedDocument?.documentId;

        this.props.onInformCancelIssuer(documentId);

        this.setState({
            showModalInformCancel: false,
        });
    };

    handleReturnToReviewStep = () => {
        const documentId = this.props.selectedDocument?.documentId;
        this.props.onReturnToReviewStep(documentId);
    };

    handleToggleCancel = () => {
        const documentId = this.props.match.params.id;

        const selectedDocument = this.props.selectedDocument || {};
        const currentStep = selectedDocument.currentStep || {};

        const values = {
            steps: [
                {
                    order: currentStep.order,
                    status: 'error',
                    text: 'Cancelado',
                },
            ],
        };

        this.setState({
            showModal: !this.state.showModal,
        });
        this.props.onCancelDocument(documentId, values);
    };

    handleToggleReportCancel = () => {
        const documentId = this.props.match.params.id;

        this.setState({
            showModalReport: !this.state.showModalReport,
        });
        this.props.onreportCancelDocument(documentId);
    };

    handleToggleJustificationCancel = () => {
        const documentId = this.props.match.params.id;

        const selectedDocument = this.props.selectedDocument || {};
        const currentStep = selectedDocument.currentStep || {};
        const text = this.state.justificationtext;
        const values = {
            steps: [
                {
                    order: currentStep.order,
                    status: 'error',
                    text: 'Rejeitado - ' + text,
                },
            ],
        };

        if (text.length > 600) {
            toast.error(
                this.props.intl.formatMessage(messages.canJustificationError),
            );
        } else {
            this.setState({
                showModalReject: !this.state.showModalReject,
            });
            this.props.onCancelDocument(documentId, values);
        }
    };

    handleModalRequired = () => {
        if (this.context.auth.hasClaim('document-reject-justification')) {
            return { required: { value: true } };
        } else {
            return { required: { value: false } };
        }
    };

    handleShowVerboseBoxesChecked = () => {
        this.props.selectedDocument &&
            this.props.onInitOcrOperationResult(
                this.props.selectedDocument.fileId,
            );
    };

    handleShowEvents = () => {
        this.handleToggleEventsModal();
    };

    handleShowHistory = () => {
        this.handleToggleHistoryModal();
    };

    handleToggleShowChildDocuments = () => {
        this.setState({
            showChildDocumentsModal: !this.state.showChildDocumentsModal,
        });
    };

    handleShowChildDocuments = () => {
        this.handleToggleShowChildDocuments();
    };

    HandleShowOrdersDocuments = () => {
        const documentId = this.props.selectedDocument?.documentId;
        const isOther =
            this.props.selectedDocument?.documentType.toUpperCase() === 'OTHER';
        this.context.linkingOrders.setSelectedDocument(
            this.props.selectedDocument,
        );

        if (documentId) {
            this.props.history.push({
                pathname: `/${isOther ? 'otherDocuments' : 'documents'
                    }/${documentId}/orders`,
            });
        }
    };

    paginateHistory(query) {
        const currentPage = parseInt(query.split('&')[2].substring(5));

        const pageIndex = currentPage === 0 ? currentPage : currentPage * 10;
        const pageSize = 10 * (currentPage + 1);

        const documentHistoryList = this.props.documentHistory.slice(pageIndex, pageSize);
        return documentHistoryList;
    }

    render() {
        const {
            intl,
            loading,
            loadingDetails,
            ocrOperationResult,
            validationErrors,
            associatedDocumentsCount,
            ordersDocumentCount,
        } = this.props;
        const documentHistoryLength = this.props.documentHistory.length || 0;
        const documentHistoryList = this.state.documentHistoryList;

        const { hideCrop } = this.props.location.state || {};
        const selectedDocument = this.props.selectedDocument || {};
        const selectedDocumentId =
            this.props.selectedDocument?.documentId || '';
        const currentStep = selectedDocument.currentStep || {};
        const events = selectedDocument.events || {};
        const documentType = selectedDocument?.documentType || '';
        const categories = this.props.categories;

        const allowAddOrderButton =
            selectedDocument?.currentStep?.order === 3 &&
            selectedDocument?.currentStep?.status === 'pending';

        const showDocumentDetails =
            this.props.match.params.id !== 'new' &&
            !(currentStep.order === 1 && currentStep.status === 'pending');

        const documentPath =
            documentType === 'OTHER' ? 'otherDocuments' : 'documents';

        return (
            <Container fluid>
                {showDocumentDetails ? (
                    <DocumentDetailsForm
                        selectedDocument={selectedDocument}
                        categories={categories}
                        validationErrors={validationErrors}
                        ocrOperationResult={ocrOperationResult}
                        loadingDetails={loadingDetails}
                        handleValidFormSubmit={this.handleValidFormSubmit}
                        handleCancelButtonModal={this.handleToggleModal}
                        handleRejectButtonModal={this.handleToggleModalReject}
                        handleReportCancelButtonModal={
                            this.handleToggleModalReport
                        }
                        handleShowVerboseBoxesChecked={
                            this.handleShowVerboseBoxesChecked
                        }
                        handleInformCancelButtonClick={
                            this.handleToggleModalInformCancel
                        }
                        handleReturnToReviewStepClick={
                            this.handleReturnToReviewStep
                        }
                        handleShowEvents={this.handleShowEvents}
                        handleShowHistory={this.handleShowHistory}
                        handleShowChildDocuments={this.handleShowChildDocuments}
                        handleShowOrdersDocuments={
                            this.HandleShowOrdersDocuments
                        }
                        ordersDocumentCount={ordersDocumentCount}
                        documentsCount={associatedDocumentsCount}
                        documentPath={documentPath}
                    />
                ) : (
                    <>
                        <Breadcrumb
                            routes={[
                                {
                                    path: '/',
                                    name: intl.formatMessage(messages.home),
                                    active: false,
                                },
                                {
                                    path: `/${documentPath}`,
                                    name: intl.formatMessage(
                                        messages.titleDocument,
                                    ),
                                    active: false,
                                },
                                {
                                    path: `/${documentPath}/:id`,
                                    name: intl.formatMessage(
                                        messages.newDocument,
                                    ),
                                    active: true,
                                },
                            ]}
                        />
                        <header>
                            <section className='title'>
                                <Header
                                    title={intl.formatMessage(
                                        messages.newDocument,
                                    )}
                                />
                            </section>
                        </header>
                        <FileUploadForm
                            loading={loading}
                            handleUploadFile={this.handleUploadFile}
                            hideCrop={hideCrop}
                            selectedDocument={selectedDocument}
                            isDocument
                        />
                    </>
                )}
                <AvForm
                    ref={this.formRef}
                    onValidSubmit={() => this.handleToggleJustificationCancel()}
                >
                    <Modal
                        title={intl.formatMessage(messages.rejectButton)}
                        isOpen={this.state.showModalReject}
                        handleToggle={this.handleToggleModalReject}
                        hasFooter={true}
                        toggleSecondButton={() => {
                            if (this.formRef.current)
                                this.formRef.current.handleSubmit();
                        }}
                        firstButtonClass='delete'
                        secondButtonClass='btn-submit'
                        firstButtonText={intl.formatMessage(
                            messages.closeModal,
                        )}
                        secondButtonText={intl.formatMessage(
                            messages.submitButton,
                        )}
                        toggleFirstButton={this.handleToggleModalReject}
                    >
                        <p style={{ marginRight: '30px' }}>
                            {intl.formatMessage(messages.justification)}
                        </p>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <AvField
                                        label={''}
                                        type='textarea'
                                        style={{
                                            height: '200px',
                                            width: '400px',
                                        }}
                                        name='justificationtext'
                                        value={this.state.justificationtext}
                                        onChange={this.justificationtextChange}
                                        id='justification'
                                        validate={this.handleModalRequired()}
                                        errorMessage='O motivo do cancelamento é obrigatório'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal>
                </AvForm>
                <Modal
                    title={intl.formatMessage(messages.cancelButton)}
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                    hasFooter={true}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    firstButtonText={intl.formatMessage(messages.noText)}
                    secondButtonText={intl.formatMessage(messages.yesText)}
                    toggleFirstButton={this.handleToggleModal}
                    toggleSecondButton={this.handleToggleCancel}
                >
                    {intl.formatMessage(messages.confirmCancelText)}
                </Modal>
                <Modal
                    title={intl.formatMessage(messages.btnReportCancel)}
                    isOpen={this.state.showModalReport}
                    handleToggle={this.handleToggleModalReport}
                    hasFooter={true}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    firstButtonText={intl.formatMessage(messages.noText)}
                    secondButtonText={intl.formatMessage(messages.yesText)}
                    toggleFirstButton={this.handleToggleModalReport}
                    toggleSecondButton={this.handleToggleReportCancel}
                >
                    <p>Atenção:</p>
                    {intl.formatMessage(messages.confirmReportCancelText)}
                </Modal>
                <Modal
                    title={intl.formatMessage(messages.btnReportCancel)}
                    isOpen={this.state.showModalInformCancel}
                    handleToggle={this.handleToggleModalInformCancel}
                    hasFooter={true}
                    firstButtonClass='delete'
                    secondButtonClass='btn-submit'
                    firstButtonText={intl.formatMessage(messages.noText)}
                    secondButtonText={intl.formatMessage(messages.yesText)}
                    toggleFirstButton={this.handleToggleModalInformCancel}
                    toggleSecondButton={this.handleInformCancelSubmit}
                >
                    <p>Atenção:</p>
                    {intl.formatMessage(messages.confirmInformCancelNfsText)}
                </Modal>
                <Modal
                    title={intl.formatMessage(messages.events)}
                    isOpen={this.state.showEventsModal}
                    handleToggle={this.handleToggleEventsModal}
                    size={'xl'}
                >
                    <EvtTable
                        columns={columnsEvents(intl)}
                        data={events}
                        length={events.length}
                        countHeaderText={`${events?.length ||
                            0} evento(s) encontrado(s)`}
                        alignCountToLeft={true}
                        pageSize={10}
                        fetchPage
                    />
                </Modal>
                <Modal
                    title={intl.formatMessage(messages.history)}
                    isOpen={this.state.showHistoryModal}
                    handleToggle={this.handleToggleHistoryModal}
                    size={'xl'}
                >
                    <EvtTable
                        columns={columnsLogs(intl)}
                        data={documentHistoryList}
                        length={documentHistoryLength}
                        countHeaderText={`${documentHistoryLength} ocorrência(s) encontrada(s)`}
                        handleStateChange={this.handleHistoryPage}
                        alignCountToLeft={true}
                        pageSize={10}
                        fetchPage
                    />
                </Modal>
                <ModalOrdersDocuments
                    isOpen={this.state.showOrdersDocuments}
                    handleToggle={this.HandleShowOrdersDocuments}
                    documentId={this.props.match.params.id}
                    allowAddOrderButton={allowAddOrderButton}
                />
                <AssociatedDocumentsModal
                    showAssociatedDocModal={this.state.showChildDocumentsModal}
                    setShowAssociatedDocModal={newState =>
                        this.setState({
                            showChildDocumentsModal: newState,
                        })
                    }
                    handleToggleAssociatedDocModal={
                        this.handleToggleShowChildDocuments
                    }
                    loadingModal={this.props.loadingMaps}
                    selectedDocument={this.props.selectedDocument}
                    pushUrl={`/documents/${selectedDocumentId}`}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingDetails: state.documentReducer.loadingDetails,
        selectedDocument: state.documentReducer.selectedDocument,
        loading: state.globalReducer.loading,
        ocrOperationResult: state.ocrOperationResultReducer.ocrOperationResult,
        validationErrors: state.documentReducer.validationErrors,
        documentsCte: state.documentReducer.documentsCte || [],
        associatedDocuments: state.documentReducer.associatedDocuments || [],
        associatedDocumentsCount:
            state.documentReducer.associatedDocumentsCount || 0,
        loadingAssociatedDocuments:
            state.documentReducer.loadingAssociatedDocuments,
        ordersDocumentCount: state.documentReducer.ordersDocumentCount || 0,
        parentChildValues: state.documentReducer.parentChildValues || 0,
        loadingMaps: state.supplierReducer.loadingMaps,
        documentHistory: state.documentReducer.documentHistory || [],
        loadingdocumentHistory:
            state.documentReducer.loadingdocumentHistory,
        categories: state.categoryReducer.categories || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreateDocument: (payload, pushUrl) =>
            dispatch(actions.createDocument(payload, pushUrl)),
        onInitDocumentDetails: documentId =>
            dispatch(actions.initDocumentDetails(documentId)),
        onUpdateDocumentFormDetails: (
            documentId,
            values,
            orderId,
            isDraft,
            isAttachment,
            documentType,
            selectedCategoryMap
        ) =>
            dispatch(
                actions.updateDocumentFormFields(
                    documentId,
                    values,
                    orderId,
                    isDraft,
                    isAttachment,
                    documentType,
                    null,
                    selectedCategoryMap
                ),
            ),
        onCancelDocument: (documentId, values) =>
            dispatch(actions.cancelDocument(documentId, values)),
        onInformCancelIssuer: documentId =>
            dispatch(actions.informCancelIssuer(documentId)),
        onreportCancelDocument: documentId =>
            dispatch(actions.reportCancelDocument(documentId)),
        onInitOcrOperationResult: fileId =>
            dispatch(actions.initOcrOperationResults(fileId)),
        onInitAssociatedDocumentsCount: documentId =>
            dispatch(actions.initAssociatedDocumentsCount(documentId)),
        onInitdocumentHistory: documentId =>
            dispatch(actions.initdocumentHistory(documentId)),
        updateActiveTab: documentId =>
            dispatch(actions.updateActiveTab(documentId)),
        onInitSupplierOrdersAvailable: (supplierId, filter) =>
            dispatch(actions.initSupplierOrdersAvailable(supplierId, filter)),
        onCleanSelectedDocument: () =>
            dispatch(actions.cleanSelectedDocument()),
        onInitSupplierMaps: supplierId =>
            dispatch(actions.initSupplierMaps(supplierId)),
        onInitCategories: () => dispatch(actions.initCategories()),
        onCleanSupplierMaps: () => dispatch(actions.cleanSupplierMaps()),
        onCleanValidationErrors: () =>
            dispatch(actions.cleanValidationErrors()),
        onReturnToReviewStep: documentId =>
            dispatch(actions.returnToReviewStep(documentId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DocumentDetailsPage),
);
