import * as actionTypes from './actionTypes';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as actions from './index';

export const initSuppliers = (filter = '') => {
    return (dispatch, getState) => {

        let isDisabled = false;

        if (filter?.includes('filter[disabled]=eq:true')) {
            isDisabled = true;
        }

        dispatch({
            type: actionTypes.SUPPLIERS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Suppliers${isDisabled ? '/Disabled' : ''}${filter}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_LIST_SUCCESS,
                    suppliers: response.data.result,
                    suppliersCount: response.data.count,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initSuppliersCount = (filter = '') => {
    return async dispatch => {
        dispatch({
            type: actionTypes.SUPPLIERS_GET_COUNT_REQUEST,
        });

        await axios
            .get('/Suppliers/Count' + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_COUNT_SUCCESS,
                    suppliersCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initSupplierMaps = (supplierId, filter = '') => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIER_GET_MAPS_REQUEST,
        });

        axios
            .get(`/Suppliers/${supplierId}/Maps${filter}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.SUPPLIER_GET_MAPS_SUCCESS,
                    maps: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.SUPPLIER_GET_MAPS_FAILURE,
                    error,
                });
            });
    };
};

export const initSupplierMapsCount = (supplierId, filter = '') => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIER_GET_MAPS_COUNT_REQUEST,
        });

        axios
            .get(`/Suppliers/${supplierId}/Maps/Count` + filter)
            .then(function(response) {
                dispatch({
                    type: actionTypes.SUPPLIER_GET_MAPS_COUNT_SUCCESS,
                    supplierMapsCount: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.SUPPLIER_GET_MAPS_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initSupplierDetails = supplierId => {
    return (dispatch, getState) => {
        if (supplierId === 'new') {
            dispatch(actions.initStates());
            dispatch({
                type: actionTypes.SUPPLIER_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.SUPPLIER_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Suppliers/${supplierId}`)
                .then(function(response) {
                    const supplier = response.data;
                    dispatch({
                        type: actionTypes.SUPPLIER_GET_DETAILS_SUCCESS,
                        supplier,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.SUPPLIER_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const createSupplier = values => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIER_POST_REQUEST,
        });

        axios
            .post(`/Suppliers`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.SUPPLIER_POST_SUCCESS,
                });

                toast.success('Fornecedor criado com sucesso');
                dispatch(push('/suppliers'));
            })

            .catch(function(error) {
                dispatch({
                    type: actionTypes.SUPPLIER_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateSupplier = (supplierId, values) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIER_PUT_REQUEST,
        });

        axios
            .put(`/Suppliers/${supplierId}`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.SUPPLIER_PUT_SUCCESS,
                });

                toast.success('Fornecedor alterado com sucesso');
                dispatch(initSupplierDetails(supplierId));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.SUPPLIER_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const updateSupplierMaps = (supplierId, mapsIds) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIER_PUT_MAP_REQUEST,
        });

        axios
            .put(`/Suppliers/${supplierId}/MapUpdate`, mapsIds)
            .then(function(response) {
                dispatch({
                    type: actionTypes.SUPPLIER_PUT_MAP_SUCCESS,
                });
                toast.success('Mapeamentos alterados com sucesso');
                dispatch(push('/suppliers'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.SUPPLIER_PUT_MAP_FAILURE,
                    error,
                });
            });
    };
};

export const deleteSupplier = supplierId => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIER_DELETE_REQUEST,
        });

        axios
            .delete(`/Suppliers/${supplierId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.SUPPLIER_GET_DETAILS_SUCCESS,
                });
                toast.success('Fornecedor desativado com sucesso');
                dispatch(push('/suppliers'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.SUPPLIER_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const addSupplierMap = newMap => {
    return (dispatch, getState) => {
        const maps = getState().supplierReducer.maps.slice();

        maps.push(newMap);

        dispatch({
            type: actionTypes.SUPPLIER_ADD_MAP,
            maps: maps,
        });
    };
};

export const deleteSupplierMap = mapId => {
    return (dispatch, getState) => {
        const maps = getState().supplierReducer.maps.slice();

        const updatedMaps = maps.filter(map => map.mapId !== mapId);

        dispatch({
            type: actionTypes.SUPPLIER_DELETE_MAP,
            maps: updatedMaps,
        });
    };
};

export const updateSupplierInput = (property, value) => {
    return (dispatch, getState) => {
        const supplier = getState().supplierReducer.supplier;

        for (var prop in supplier) {
            if (prop === property) {
                supplier[prop] = value;
            } else if (prop === 'address') {
                supplier[property] = value;
            }
        }

        dispatch({
            type: actionTypes.SUPPLIER_UPDATE_INPUT,
            supplier: supplier,
        });
    };
};

export const setSelectedSupplier = supplier => {
    return dispatch => {
        dispatch({
            type: actionTypes.SUPPLIER_SET_REQUEST,
        });
        dispatch({
            type: actionTypes.SUPPLIER_SET_SUCCESS,
            supplier: supplier,
        });
    };
};

export const initSupplierOrders = (supplierId, filter = '') => {
    return async dispatch => {
        dispatch({
            type: actionTypes.SUPPLIERS_GET_ORDERS_REQUEST,
        });

        await axios
            .get(`/Suppliers/${supplierId}/Orders` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_ORDERS_SUCCESS,
                    supplierOrders: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_ORDERS_FAILURE,
                    error,
                });
            });
    };
};

export const initSupplierOrdersAvailable = (supplierId, filter = '') => {
    return async dispatch => {
        dispatch({
            type: actionTypes.SUPPLIERS_GET_ORDERS_REQUEST,
        });

        await axios
            .get(`/Suppliers/${supplierId}/Orders/Available` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_ORDERS_SUCCESS,
                    supplierOrders: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.SUPPLIERS_GET_ORDERS_FAILURE,
                    error,
                });
            });
    };
};

export const cleanSupplierMaps = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.CLEAN_SUPPLIER_MAPS,
        });
    };
};
