import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    title: {
        id: 'integration.title',
        defaultMessage: 'Integrações',
    },
    executionDate: {
        id: 'integrations.executionDate',
        defaultMessage: 'Data de execução',
    },
    supplierCNPJ: {
        id: 'integrations.supplierCNPJ',
        defaultMessage: 'CNPJ Fornecedor',
    },
    contractorCNPJ: {
        id: 'integrations.contractorCNPJ',
        defaultMessage: 'CNPJ Tomador',
    },
    beginDate: {
        id: 'integrations.beginDate',
        defaultMessage: 'Data Início',
    },
    endDate: {
        id: 'integrations.endDate',
        defaultMessage: 'Data Fim',
    },
    cityName: {
        id: 'integrations.cityName',
        defaultMessage: 'Cidade',
    },
    uf: {
        id: 'integrations.uf',
        defaultMessage: 'Estado',
    },
    amountDocumentsReturned: {
        id: 'integrations.amountDocumentsReturned',
        defaultMessage: 'Qtd Documentos',
    },
    subtitle: {
        id: 'integrations.subtitle',
        defaultMessage: ' {length} integrações encontradas',
    },
    tabNfse: {
        id: 'integrations.tabNfse',
        defaultMessage: 'NFS-e',
    },
    tabNfe: {
        id: 'integrations.tabNfe',
        defaultMessage: 'NF-e',
    },
    tabCte: {
        id: 'integrations.tabCte',
        defaultMessage: 'CT-e',
    },
    tabOther: {
        id: 'integrations.tabOther',
        defaultMessage: 'Outros Pagamentos',
    },
    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
    canUploadError: {
        id: 'integrations.canUploadError',
        defaultMessage:
            'Documento não vinculado ao fornecedor, entre em contato com o suporte.',
    },
    status: {
        id: 'integrations.status',
        defaultMessage: 'Status',
    },
    statusSuccess: {
        id: 'integrations.statusSuccess',
        defaultMessage: 'Sucesso',
    },
    statusFailed: {
        id: 'integrations.statusFailed',
        defaultMessage: 'Falha',
    },

    type: {
        id: 'integrations.type',
        defaultMessage: 'Tipo',
    },
    integrationDate: {
        id: 'integrations.integrationDate',
        defaultMessage: 'Data de Integração',
    },
    dataDescription: {
        id: 'integrations.dataDescription',
        defaultMessage: 'Descrição',
    },
    errorMessage: {
        id: 'integrations.errorMessage',
        defaultMessage: 'Mensagem de erro',
    },
    ibgeCode: {
        id: 'integrations.ibgeCode',
        defaultMessage: 'IBGE Code',
    },
    documentsLogs: {
        id: 'integrations.documentsLogs',
        defaultMessage: 'Documentos',
    },

    searchStartTimeDate: {
        id: 'integrations.searchStartTimeDate',
        defaultMessage: 'Data de Busca',
    },
    lastNumberReturned: {
        id: 'integrations.lastNumberReturned',
        defaultMessage: 'Último Número retornado',
    },
    lastNumber: {
        id: 'integrations.lastNumber',
        defaultMessage: 'Último Número buscado',
    },
    totalCountReturn: {
        id: 'integrations.totalCountReturn',
        defaultMessage: 'CTEs Retornados',
    },
    totalSummaryReturned: {
        id: 'integrations.TotalSummaryReturned',
        defaultMessage: 'Resumos retornados',
    },
    totalNFeReturn: {
        id: 'integrations.totalNFeReturn',
        defaultMessage: 'NFes Retornados',
    },
    tabNFSeNacional: {
        id: 'integrations.tabNFSeNacional',
        defaultMessage: 'NFS-e Nacional'
    },
    totalNFSeNacionalReturn: {
        id: 'integrations.totalNFSeNacionalReturn',
        defaultMessage: 'NFSEs Retornados',
    },
});

export default messages;
