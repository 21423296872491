import * as actionTypes from './actionTypes';
import axios from 'axios';

//NFSe
export const initIntegrationsNfseSearch = (filter, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.INTEGRATIONS_NFS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Integrations/NFS/ExecutedSearchs` + filter + search)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFS_GET_LIST_SUCCESS,
                    integrationsNfse: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};
export const initIntegrationsNfseSearchCount = (filter, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.INTEGRATIONS_NFS_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Integrations/NFS/ExecutedSearchs/Count` + filter + search)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFS_GET_COUNT_SUCCESS,
                    integrationsNfseCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

//Logs
export const initIntegrationsLogsSearch = (filter, value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INTEGRATIONS_LOGS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Integrations/DocumentLogs` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_LOGS_GET_LIST_SUCCESS,
                    integrationsLogs: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_LOGS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initIntegrationsLogsSearchCount = (filter, value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.INTEGRATIONS_LOGS_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Integrations/DocumentLogs/Count` + filter)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_LOGS_GET_COUNT_SUCCESS,
                    integrationsLogsCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_LOGS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

//CTE
export const initIntegrationsCteSearch = (filter, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.INTEGRATIONS_CTE_GET_LIST_REQUEST,
        });

        axios
            .get(`/Integrations/CTE/ExecutedSearchs` + filter + search)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_CTE_GET_LIST_SUCCESS,
                    integrationsCte: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_CTE_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initIntegrationsCteSearchCount = (filter, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.INTEGRATIONS_CTE_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Integrations/CTE/ExecutedSearchs/Count` + filter + search)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_CTE_GET_COUNT_SUCCESS,
                    integrationsCteCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_CTE_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

//NFe
export const initIntegrationsNfeSearch = (filter, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.INTEGRATIONS_NFE_GET_LIST_REQUEST,
        });

        axios
            .get(`/Integrations/NFE/ExecutedSearchs` + filter + search)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFE_GET_LIST_SUCCESS,
                    integrationsNfe: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFE_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initIntegrationsNfeSearchCount = (filter, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.INTEGRATIONS_NFE_GET_COUNT_REQUEST,
        });

        axios
            .get(`/Integrations/NFE/ExecutedSearchs/Count` + filter + search)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFE_GET_COUNT_SUCCESS,
                    integrationsNfeCount: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NFE_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const setTabIndexIntegration = tabIndex => {
    return dispatch => {
        dispatch({
            type: actionTypes.INTEGRATIONS_TAB_SET_SUCCESS,
            tabIndex,
        });
    };
};

// Nfse nacional
export const initIntegrationsNFSeNacionalSearch = (filter, value) => {
    return dispatch => {
        const search = value ? `&search=${value}` : '';
        dispatch({
            type: actionTypes.INTEGRATIONS_NATIONAL_NFSE_GET_LIST_REQUEST,
        });

        axios
            .get(`/Integrations/NFSeNacional/ExecutedSearchs` + filter + search)
            .then(response => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NATIONAL_NFSE_GET_LIST_SUCCESS,
                    integrationsNFSeNacional: response.data.result,
                    integrationsNFSeNacionalCount: response.data.count
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.INTEGRATIONS_NATIONAL_NFSE_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};