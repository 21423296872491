import React from 'react';
import messages from '../DocumentDetailsPage.intl';
import { Badge } from 'reactstrap';

export const columnsEvents = intl => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.eventDescription)}</span>
            </div>
        ),
        accessor: 'description',
        Cell: row => {
            return <div className=''>{row.value}</div>;
        },
        minWidth: 60,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.date)}</span>
            </div>
        ),
        accessor: 'date',
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);

            return (
                <div className='customer-container'>
                    <div className=''>
                        {date === 'Invalid Date' ? row.value : date}
                    </div>
                </div>
            );
        },
        minWidth: 60,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.protocolNumber)}</span>
            </div>
        ),
        accessor: 'protocolNumber',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        minWidth: 60,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.status)}</span>
            </div>
        ),

        Cell: row => {
            const currentStep = row.original.currentStep;
            let status;
            let rowText;
            let color = '';

            if (currentStep.status === 'success') {
                color += 'success';
                status = 'Sucesso';
            } else if (currentStep.status === 'pending') {
                color += 'warning';
                status = 'Pendente';
                rowText = currentStep.text;
            } else {
                color += 'danger';
                status = 'Erro';
                rowText = currentStep.text;
            }
            return (
                <div>
                    <Badge
                        style={{ fontSize: '1.0rem', color: '#ffffff' }}
                        color={color}
                        pill
                    >
                        {status}
                    </Badge>
                    <span style={{ marginLeft: 10 }}>{rowText}</span>
                </div>
            );
        },
        minWidth: 90,
    },
];

export const columnsLogs = intl => [
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.createdAt)}</span>
            </div>
        ),
        accessor: 'createdAt',
        Cell: row => {
            const date = new Date(row.value).toLocaleString(navigator.language);

            return (
                <div className='customer-container'>
                    <div className=''>
                        {date === 'Invalid Date' ? row.value : date}
                    </div>
                </div>
            );
        },
        minWidth: 20,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.user)}</span>
            </div>
        ),
        accessor: 'user',
        Cell: row => {
            return (
                <div className='customer-container'>
                    <div className=''>{row.value}</div>
                </div>
            );
        },
        minWidth: 30,
    },
    {
        Header: () => (
            <div className='header-table-title'>
                <span>{intl.formatMessage(messages.logDescription)}</span>
            </div>
        ),
        accessor: 'description',
        Cell: row => {
            return <div className=''>{row.value}</div>;
        },
        minWidth: 60,
    }
];
