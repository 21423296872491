import { defineMessages } from "react-intl";

import globalMessages from "../../utils/globalMessages";

const messages = defineMessages({
  ...globalMessages,

  title: {
    id: "suppliers.title",
    defaultMessage: "Fornecedores"
  },

  titleSupplier: {
    id: "suppliers.titleSupplier",
    defaultMessage: "Fornecedor"
  },

  subtitle: {
    id: "suppliers.subtitle",
    defaultMessage: " fornecedor(es) encontrado(s)"
  },

  companyName: {
    id: "detailsTab.companyName",
    defaultMessage: "Razão Social"
  },

  displayName: {
    id: "detailsTab.displayName",
    defaultMessage: "Nome Fantasia"
  },

  cnpj: {
    id: "suppliers.cnpj",
    defaultMessage: "CPF/CNPJ"
  },

  email: {
    id: "suppliers.email",
    defaultMessage: "E-mail"
  },

  city: {
    id: "suppliers.city",
    defaultMessage: "Cidade"
  },

  state: {
    id: "suppliers.state",
    defaultMessage: "Estado"
  },

  home: {
    id: "home.title",
    defaultMessage: "Início"
  }
});

export default messages;
