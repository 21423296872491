import React, { useContext, useEffect, useState } from 'react';
import messages from './ReportListPage.intl';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Tabs from '../../components/Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import GeneratedReportsList from './pages/GeneratedReportsList';
import ReportsList from './pages/ReportsList';

function ReportListPage(props) {
    const { intl } = props;
    const context = useContext(AuthContext);
    const history = useHistory();

    const hasReportCreate = context.hasClaim('report-create');
    const hasReportView = context.hasClaim('report-view');

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const index =
            new URLSearchParams(window.location.search).get('index') || 0;
        setActiveTab(Number(index));
    }, []);

    const handleGetTrProps = (state, rowInfo, column, instance) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: e => {},
        };
    };

    const handleDownloadFile = reportId => {
        window.open(`${window.API_URI}/Reports/${reportId}/Report`, 'Download');
    };

    const handleTabClick = (e, index) => {
        if (activeTab !== index) setActiveTab(index);

        history.push(
            `reports?sort=-createdAt&skip=0&take=10&page=0&index=${index}`,
        );
    };

    const reportTabs = [];

    //Have claim report-create
    hasReportCreate &&
        reportTabs.push({
            name: intl.formatMessage(messages.reportsTitle),
            component: (
                <ReportsList
                    activeTab={activeTab}
                    handleGetTrProps={handleGetTrProps}
                />
            ),
        });

    //Have claim report-view
    hasReportView &&
        reportTabs.push({
            name: intl.formatMessage(messages.generatedReportsTitle),
            component: (
                <GeneratedReportsList
                    activeTab={activeTab}
                    handleGetTrProps={handleGetTrProps}
                    handleDownloadFile={handleDownloadFile}
                />
            ),
        });

    return (
        <Container fluid>
            <Breadcrumb
                routes={[
                    {
                        path: '/home',
                        name: intl.formatMessage(messages.home),
                        active: false,
                    },
                    {
                        path: '/reports',
                        name: intl.formatMessage(messages.reportsTitle),
                        active: true,
                    },
                ]}
            />
            <section className='content-middle'>
                <Tabs
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    tabs={reportTabs}
                />
            </section>
        </Container>
    );
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ReportListPage),
);
