import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as actions from '../../store/actions/index';

import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import UserDetailsForm from '../../components/UserDetailsForm/UserDetailsForm';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './UserDetailsPage.intl';

import { AuthContext } from '../../context/AuthContext';

import ModalAddUserAccess from './components/ModalAddUserAccess';

class UserDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        showNewPasswordConfirmationModal: false,
        showNewAccessModal: false,
        showNewCompanyModal: false,
        selectedUserCompanies: [],
    };

    componentDidMount() {
        this.props.onInitUserDetails(this.props.match.params.id);

        if (this.context.claims.length === 0) return;

        if (this.context.hasClaim('role-level-contractor')) {
            const contractorId = this.props.session.contractorId;
            const displayName = this.props.session.contractor.displayName;
            this.props.onSetSelectedContractor({ contractorId, displayName });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            user,
            session,
            contractor,
            emailSent,
            userCompanies,
        } = this.props;

        if (prevProps.session !== session) {
            this.props.onInitCurrentSession();
        }

        if (prevProps.emailSent !== emailSent) {
            if (user?.userId === session?.userId && emailSent) {
                this.props.onLogout();
            }
        }

        if (prevProps.user !== user) {
            const selectedUserCompanies = userCompanies.map(r => {
                return {
                    label: `${r.companyName} - ${r.companyCNPJ}`,
                    value: r.companyId,
                    company: {
                        companyId: r.companyId,
                        companyCNPJ: r.companyCNPJ,
                    },
                };
            });

            this.setState({
                selectedUserCompanies,
            });
        }

        if (prevProps.contractor !== contractor) {
            const canViewCompany = this.context.hasClaim('company-view');
            const canInitCompanies = canViewCompany && contractor;
            canInitCompanies &&
                this.props.onInitCompanies(contractor.contractorId);
        }
    }

    handleSubmit = (event, values) => {
        if (this.props.loading) return;

        const userCompanies = this.state.selectedUserCompanies.map(r => {
            return r.company;
        });
        values.accesses = this.props?.accesses;
        values.companies = userCompanies;

        if (this.props.match.params.id === 'new') {
            this.props.onCreateUser(values);
        } else {
            this.props.onUpdateUser(this.props.user.userId, values);
        }
    };

    handleNewPasswordModalToogle = () => {
        this.setState({
            showNewPasswordConfirmationModal: !this.state
                .showNewPasswordConfirmationModal,
        });
    };

    handleSendNewPasswordButtonClick = () => {
        const { user } = this.props;
        this.props.onSendNewPassword(user);

        this.handleNewPasswordModalToogle();
    };

    handleRemoveAccessButtonClick = roleToDelete => {
        this.props.onRemoveUserAccess(roleToDelete);
    };

    handleNewAccessModalToogle = () => {
        this.setState({
            showNewAccessModal: !this.state.showNewAccessModal,
        });
    };

    handleCompanyContractorChange = selectedContractor => {
        if (selectedContractor) {
            this.props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });
        }
    };

    handleUserCompaniesSelectChange = e => {
        this.setState({
            selectedUserCompanies: e,
        });
    };

    render() {
        const { intl, loadingDetails, contractor } = this.props;
        const { contractorId } = contractor || {};

        const { selectedUserCompanies } = this.state;
        const userCompanies = this.props.companies.map(r => {
            return {
                label: `${r.name} - ${r.cnpjFilial}`,
                value: r.companyId,
                company: { companyCNPJ: r.cnpjFilial, companyId: r.companyId },
            };
        });

        const id = this.props.match.params.id;

        const canCreateUser =
            this.context.hasClaim('user-create') && id === 'new';
        const canDeleteUser =
            this.context.hasClaim('user-delete') && id !== 'new';
        const canUpdateUser =
            this.context.hasClaim('user-update') && id !== 'new';
        const canResetPassword =
            this.context.hasClaim('user-reset-password-request-create') &&
            id !== 'new';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/users',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/users/:id',
                            name: intl.formatMessage(messages.titleUser, {
                                entity: this.props.user && this.props.user.name,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='row'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleUser, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content roles-form'>
                    <Loading loading={loadingDetails} />
                    <UserDetailsForm
                        key={this.props.user}
                        user={this.props.user}
                        handleSubmit={this.handleSubmit}
                        handleToggle={this.handleToggleModal}
                        handleDelete={this.handleDelete}
                        accesses={this.props?.accesses || []}
                        canCreateUser={canCreateUser}
                        canDeleteUser={canDeleteUser}
                        canUpdateUser={canUpdateUser}
                        canResetPassword={canResetPassword}
                        contractorId={contractorId}
                        selectedUserCompanies={selectedUserCompanies}
                        userCompanies={userCompanies}
                        handleNewAccessButtonClick={
                            this.handleNewAccessModalToogle
                        }
                        handleNewPasswordButtonClick={
                            this.handleNewPasswordModalToogle
                        }
                        handleRemoveAccessButtonClick={
                            this.handleRemoveAccessButtonClick
                        }
                        handleCompanyContractorChange={
                            this.handleCompanyContractorChange
                        }
                        handleUserCompaniesSelectChange={
                            this.handleUserCompaniesSelectChange
                        }
                    />
                </section>
                <Modal
                    key='newPasswordConfirmationModal'
                    title={intl.formatMessage(
                        messages.newPasswordConfirmationModalTitle,
                    )}
                    isOpen={this.state.showNewPasswordConfirmationModal}
                    handleToggle={this.handleNewPasswordModalToogle}
                    type={modalTypes.WARNING}
                    hasFooter={true}
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    secondButtonClass='delete'
                    toggleFirstButton={this.handleSendNewPasswordButtonClick}
                    toggleSecondButton={this.handleNewPasswordModalToogle}
                >
                    {intl.formatMessage(
                        messages.newPasswordConfirmationModalText,
                    )}
                </Modal>
                <ModalAddUserAccess
                    showNewAccessModal={this.state.showNewAccessModal}
                    handleNewAccessModalToogle={this.handleNewAccessModalToogle}
                    contractorId={contractorId}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        loadingDetails: state.userReducer.loadingDetails,
        accesses: state.userReducer.accesses || [],
        userCompanies: state.userReducer.companies || [],
        companies: state.companyReducer.companies || [],
        session: state.authenticationReducer.session,
        emailSent: state.userReducer.emailSent,
        contractor: state.contractorReducer.contractor,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitUserDetails: userId => dispatch(actions.initUserDetails(userId)),
        onCreateUser: values => dispatch(actions.createUser(values)),
        onUpdateUser: (userId, values) =>
            dispatch(actions.updateUser(userId, values)),
        onRemoveUserAccess: removeAccess =>
            dispatch(actions.removeUserAccess(removeAccess)),
        onSetSelectedContractor: selectedContractor =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
        onInitCompanies: (
            contractorId,
            filter = '?skip=0&take=500&sort=-createdAt',
        ) => dispatch(actions.initCompanies(contractorId, filter)),
        onSendNewPassword: user => dispatch(actions.sendNewPassword(user)),
        onLogout: () => dispatch(actions.logout()),
        onInitCurrentSession: () => dispatch(actions.getCurrentSession()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(UserDetailsPage),
);
