import React from 'react';

import { injectIntl } from 'react-intl';
import messages from '../NfeItemDetailsForm.intl';

import { AvField } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';

const ICMSForm = props => {
    const { intl, nfeItem, isReadOnly } = props;
    const nfeICMS = nfeItem?.imposto?.icms?.icmsObject;

    return (
        <>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.orig)}
                            type='text'
                            name='origDescription'
                            id='origDescription'
                            value={
                                `${nfeICMS?.orig} - ${
                                    nfeICMS?.origDescription
                                }` || ''
                            }
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.IcmsCST)}
                            type='text'
                            name='cstDescription'
                            id='cstDescription'
                            value={
                                `${nfeICMS?.cst} - ${
                                    nfeICMS?.cstDescription
                                }` || ''
                            }
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.modBC)}
                            type='text'
                            name='modBCDescription'
                            id='modBCDescription'
                            value={
                                `${nfeICMS?.modBC} - ${
                                    nfeICMS?.modBCDescription
                                }` || ''
                            }
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.modBCST)}
                            type='text'
                            name='modBCSTDescription'
                            id='modBCSTDescription'
                            value={
                                `${nfeICMS?.modBCST} - ${
                                    nfeICMS?.modBCSTDescription
                                }` || ''
                            }
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.valueBC)}
                            type='text'
                            name='vBC'
                            id='vBC'
                            value={nfeICMS?.vBC || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pICMS)}
                            type='text'
                            name='pICMS'
                            id='pICMS'
                            value={nfeICMS?.pICMS || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vICMS)}
                            type='text'
                            name='vICMS'
                            id='vICMS'
                            value={nfeICMS?.vICMS || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pMVAST)}
                            type='text'
                            name='pMVAST'
                            id='pMVAST'
                            value={nfeICMS?.pMVAST || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pRedBCST)}
                            type='text'
                            name='pRedBCST'
                            id='pRedBCST'
                            value={nfeICMS?.pRedBCST || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vBCST)}
                            type='text'
                            name='vBCST'
                            id='vBCST'
                            value={nfeICMS?.vBCST || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pICMSST)}
                            type='text'
                            name='pICMSST'
                            id='pICMSST'
                            value={nfeICMS?.pICMSST || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vICMSST)}
                            type='text'
                            name='vICMSST'
                            id='vICMSST'
                            value={nfeICMS?.vICMSST || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vICMSDeson)}
                            type='text'
                            name='vICMSDeson'
                            id='vICMSDeson'
                            value={nfeICMS?.vICMSDeson || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pRedBC)}
                            type='text'
                            name='pRedBC'
                            id='pRedBC'
                            value={nfeICMS?.pRedBC || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.pDif)}
                            type='text'
                            name='pDif'
                            id='pDif'
                            value={nfeICMS?.pDif || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vICMSDif)}
                            type='text'
                            name='vICMSDif'
                            id='vICMSDif'
                            value={nfeICMS?.vICMSDif || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vICMSOp)}
                            type='text'
                            name='vICMSOp'
                            id='vICMSOp'
                            value={nfeICMS?.vICMSOp || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vBCSTRet)}
                            type='text'
                            name='vBCSTRet'
                            id='vBCSTRet'
                            value={nfeICMS?.vBCSTRet || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.vICMSSTRet)}
                            type='text'
                            name='vICMSSTRet'
                            id='vICMSSTRet'
                            value={nfeICMS?.vICMSSTRet || ''}
                            errorMessage=''
                            required
                            disabled={isReadOnly}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default injectIntl(ICMSForm);
