import React, { useEffect, useState, useContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, useHistory } from 'react-router-dom';

import * as actions from '../../../store/actions/index';
import messages from '../DocumentListPage.intl';
import { AuthContext } from '../../../context/AuthContext';

import Header from '../../../components/Header/Header';
import EvtTable from '../../../components/EvtTable/EvtTable';
import EvtAlternativeFilter from '../../../components/EvtAlternativeFilter/EvtAlternativeFilter';

import { columnsNfs, patternFilters } from '../components/Columns';

const NFSeList = props => {
    const {
        intl,
        documents,
        documentsCount,
        loadingNfsList,
        formatFilters,
        isLoading,
        handleNewDocumentButtonClick,
        handleGetTrProps,

        onInitDocumentsSearch,
        onInitDocumentsSearchCount,
        onInitDocumentsNFSeFilter,
        onInitDocumentsNFSeFilterCount,
        onInitDocumentsReport,
    } = props;

    const { hasClaim } = useContext(AuthContext);
    const history = useHistory();

    const documentFieldButtonEnabled = hasClaim('document-field-view-menu');
    const newDocumentButtonEnabled = hasClaim('document-create');
    const downloadButtonEnabled = hasClaim('document-download-csv');
    const mustHaveOrderId = hasClaim('document-order-create');
    const hasFull = hasClaim('role-level-full');
    const hasSupplier = hasClaim('role-level-supplier');

    const NFSeColumns = columnsNfs(documents, intl, hasFull, hasSupplier);

    const [query, setQuery] = useState('');
    const [filters, setFilters] = useState({
        filtersNFSe: [],
        filterValue: '',
    });

    useEffect(() => {
        const URL = window.location.search;

        if (URL.includes('&search=')) {
            const filtersURL = formatFilters(URL);
            const value = filtersURL.shift().value;
            setFilters(prev => ({ ...prev, filterValue: value }));
        } else if (URL.includes('filter')) {
            const value = formatFilters(URL);
            setFilters(prev => ({ ...prev, filtersNFSe: value }));
        }
    }, []);

    useEffect(() => {
        if (!!query) {
            reloadDocumentsNfsList();
        }
    }, [query]);

    const reloadDocumentsNfsList = () => {
        const { filterValue, filtersNFSe } = filters;

        const take = new URLSearchParams(query).get('take') || 10;
        const skip = new URLSearchParams(query).get('skip') || 0;
        const sort = new URLSearchParams(query).get('sort') || '-createdAt';

        if (filterValue) {
            onInitDocumentsSearch(filterValue, 'NFS', query);
            onInitDocumentsSearchCount(filterValue, 'NFS', query);
        } else {
            onInitDocumentsNFSeFilter(filtersNFSe, skip, take, sort);
            onInitDocumentsNFSeFilterCount(filtersNFSe);
        }

        if (query) {
            history.push('/documents' + query);
        }
    };

    const handleReloadButton = () => {
        reloadDocumentsNfsList();
    };

    const handleEvTableStateChange = query => {
        setQuery(query);
    };

    const handleFiltersNFSeChange = (filtersNFSe, value) => {
        setFilters({ filtersNFSe, filterValue: value });
    };

    return (
        <div>
            <header className='table-screen'>
                <section className='title'>
                    <Header title={intl.formatMessage(messages.title)} />
                </section>
                <section className='btns-topo'>
                    <EvtAlternativeFilter
                        properties={patternFilters(
                            intl,
                            () => {},
                            [],
                            'NFSE',
                            hasFull,
                        )}
                        handleFiltersChange={handleFiltersNFSeChange}
                        loading={loadingNfsList}
                    />
                    {documentFieldButtonEnabled && (
                        <button
                            className='new-btn small'
                            onClick={() =>
                                history.push('/documents/documentsconfigs')
                            }
                        >
                            <FontAwesomeIcon icon='cog' size='2x' />
                        </button>
                    )}
                    <button
                        className={`new-btn small ${
                            loadingNfsList ? 'loading' : ''
                        }`}
                        onClick={handleReloadButton}
                    >
                        <FontAwesomeIcon icon='sync' size='1x' />
                    </button>
                    {downloadButtonEnabled && (
                        <button
                            className={`new-btn small ${
                                isLoading ? 'loading' : ''
                            }`}
                            onClick={onInitDocumentsReport}
                        >
                            <FontAwesomeIcon icon='download' size='1x' />
                        </button>
                    )}
                    {newDocumentButtonEnabled && !mustHaveOrderId && (
                        <button
                            className='new-btn'
                            onClick={handleNewDocumentButtonClick}
                        >
                            {intl.formatMessage(messages.newButtonText, {
                                entity: intl.formatMessage(
                                    messages.titleInvoice,
                                ),
                            })}
                        </button>
                    )}
                </section>
            </header>
            <section>
                <EvtTable
                    filters={filters.filtersNFSe}
                    columns={NFSeColumns}
                    data={documents}
                    length={documentsCount}
                    pageSize={10}
                    handleStateChange={handleEvTableStateChange}
                    handleGetTrProps={handleGetTrProps}
                    loading={loadingNfsList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                    countHeaderText={intl.formatMessage(messages.subtitle, {
                        length: documentsCount,
                    })}
                    fetchPage
                    search
                />
            </section>
        </div>
    );
};

const mapStateToProps = state => ({
    documents: state.documentReducer.documents || [],
    documentsCount: state.documentReducer.documentsCount || 0,
    loadingNfsList: state.documentReducer.loadingNfsList,
    query: state.documentReducer.query || '',
});

const mapDispatchToProps = dispatch => ({
    onInitDocumentsSearchCount: (value, documentType, query) =>
        dispatch(actions.initDocumentsSearchCount(value, documentType, query)),
    onInitDocumentsSearch: (value, documentType, query) =>
        dispatch(actions.initDocumentsSearch(value, documentType, query)),
    onInitDocumentsNFSeFilterCount: filters =>
        dispatch(actions.initDocumentsNfseFilterCount(filters)),
    onInitDocumentsNFSeFilter: (filters, skip, take, sort) =>
        dispatch(actions.initDocumentsNfseFilter(filters, skip, take, sort)),
    onInitDocumentsReport: () => dispatch(actions.initDocumentsReport()),
});

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(NFSeList)),
);
